import Checkbox, {
  CheckboxProps,
} from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import {
  colors,
  defaultStyle,
  fontSize,
  padding,
} from "theme/defaultStyle";

interface Props extends CheckboxProps {
  label?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  checked: boolean | undefined;
  styledWrapper?: boolean;
  hidden?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  root: {
    color: colors.blue01,
    '&.Mui-checked': {
      color: colors.blue01,
    },
    '&.Mui-disabled': {
      color: colors.blue09,
    },
    "&:hover, &.Mui-checked:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  },
  icon: {
    color: colors.blue01,
    fill: colors.blue01,
    fontSize: "1.25rem",
    height: "1em",
    width: "1em",
    "input:disabled ~ &": {
      fill: colors.blue09,
    },
    borderRadius: defaultStyle.checkbox.borderRadius,
  },
  checkedIcon: {
    color: colors.blue01,
  },
  checkboxWrapper: {
    alignItems: "center",
    display: (props: any) => (props.hidden ? "none" : "flex"),
  },
  labelClass: {
    color: (props: any) => (props.disabled ? colors.grey11 : colors.grey08),
    fontSize: fontSize.large,
    paddingTop: padding.small2,
    whiteSpace: "nowrap",
  },
});

const CheckboxInput = (props: Props) => {
  const {
    label,
    onChange,
    name,
    checked,
    disabled,
    className,
    styledWrapper = true,
    hidden = false,
  } = props;
  const { root, icon, checkedIcon, labelClass, checkboxWrapper } =
    useStyles(props);

  const uncheckedSvg = <svg
    className={icon}
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxOutlineBlankIcon"
  >
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
  </svg>;

  const checkedSvg = <svg
    className={clsx(icon, checkedIcon)}
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxIcon"
  >
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
  </svg>;

  return (
    <div className={styledWrapper ? checkboxWrapper : ""}>
      <Checkbox
        id={name}
        className={clsx(root, className)}
        disabled={disabled}
        checked={!!checked}
        checkedIcon={checkedSvg}
        icon={uncheckedSvg}
        {...{ name, onChange, hidden }}
      />
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;
