import { axiosSecuredInstance } from "configurations/axiosConfig";
import { PaymentsFileExportCriteria } from "entities/ApiModel/PaymentsFileExportCriteria";
import { ReportAndPayResponse } from "entities/ApiModel/ReportAndPayResponse";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import {
  createHook,
  createStore,
} from "react-sweet-state";
import {
  DownloadFileType,
  PaymentsFileExportCriteriaType,
  ReportAndPayAction,
} from "utils/data/enum";
import {
  dateTimeAsFilename,
  downloadByBase64,
  getBase64DataType,
} from "utils/shared";

type State = {};
type Actions = typeof actions;

const actions = {
  reportPricing:
    (fileIds: number[], requestId: string, action: ReportAndPayAction) =>
      async (): Promise<ReportingResult> => {
        try {
          const { data } = await axiosSecuredInstance.post<ReportAndPayResponse>(
            `/reportandpay/ReportPricing?action=${action}&requestId=${requestId}`,
            fileIds
          );
          return {
            requestedFileIds: fileIds,
            pdfUrl: data.DocumentUrl,
            successfulFileIds: data.SuccessfulFileIds,
            failedFileIds: data.FailedFileIds,
          };
        } catch (error) {
          console.error(error);
          return {
            error: error,
          };
        }
      },
  undoReportPricing:
    (fileIds: number[] | number, requestId: string) =>
      async (): Promise<ReportingResult> => {
        try {
          await axiosSecuredInstance.post<ReportAndPayResponse>(
            `/reportandpay/UndoReportPricing?requestId=${requestId}`,
            fileIds
          );
          return {};
        } catch (error) {
          console.error(error);
          return {
            error: error,
          };
        }
      },
  exportFiles:
    (criteria: PaymentsFileExportCriteria, requestId: string) =>
      async (): Promise<ReportingResult> => {
        const getExportFilename = (): string => {
          let filename;

          switch (criteria.SearchCriteria?.criteriaType) {
            case PaymentsFileExportCriteriaType.ReportAndPayCriteria:
            case PaymentsFileExportCriteriaType.ReportAndPayKeyword:
              filename = "Report and Pay";
              break;

            case PaymentsFileExportCriteriaType.PendingPaymentsCriteria:
            case PaymentsFileExportCriteriaType.PendingPaymentsKeyword:
              filename = "Pending Payments";
              break;

            default:
              break;
          }
          filename = `${filename} Export ${dateTimeAsFilename()}.${DownloadFileType.Xlsx}`;

          return filename;
        };

        try {
          const { data } = await axiosSecuredInstance.post<string>(
            `/reportandpay/ExportFiles?requestId=${requestId}`,
            criteria
          );

          const filename = getExportFilename();
          downloadByBase64(
            `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
            filename
          );

          return {};
        } catch (error) {
          console.error(error);
          return {
            error: error,
          };
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {},
  actions,
  name: "payment",
});

const hook = createHook(Store);
export const usePayment = () => { return hook(); };
