import ErrorMessage from "controls/global/error-message";
import React from "react";
import { useController } from "react-hook-form";
// import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import NumberInputPricing from "./NumberInputPricing";

type Props = {
  name: string;
  value?: number;
  prefix?: string;
  placeholder?: string;
  disabled?: boolean;
  inputClassName?: string;
  errorClassName?: string;
  isZeroAllowed?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  styles?: object;
  label?: string;
  size?: "small" | "medium";
};

export default function NumberInputFieldPricing({
  name,
  value,
  prefix,
  placeholder,
  disabled,
  inputClassName,
  errorClassName,
  isZeroAllowed = false,
  onChange,
  onBlur,
  styles = {},
  label,
  size="medium"
}: Props) {
  const { control, nameString } = useFormWrapper();
  const { field, fieldState } = useController({
    control,
    name: nameString(name),
    defaultValue: value || 0,
  });
  // const shouldFieldBeDisabled = useFieldDisabler(name);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    field.onChange(parseFloat(event.target.value));
    onChange?.(event);
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    field.onBlur();
    onBlur?.(event);
  };

  const currentValue = parseFloat(field.value);
  return (
    <>
      <NumberInputPricing
        name={field.name}
        value={isNaN(currentValue) ? 0 : currentValue}
        disabled={disabled} // || shouldFieldBeDisabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={Boolean(fieldState.error)}
        className={inputClassName}
        styles={styles}
        isZeroAllowed={isZeroAllowed}
        prefix={prefix}
        placeholder={placeholder}
        label={label}
        size={size}
      />
      <ErrorMessage name={name} className={errorClassName || "errorMessage"} />
    </>
  );
}
