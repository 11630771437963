import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { AdditionalPartySearch } from "./AdditionalPartiesContext";

type State = {
  search: AdditionalPartySearch;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  reset:
    () =>
    ({ setState }: StoreApi) => {
      setState({
        search: {
          address: "",
          city: "",
          state: "",
          text: "",
          zip: "",
        },
      });
    },
  update:
    (prop: keyof AdditionalPartySearch, value: string) =>
    ({ getState, setState }: StoreApi) => {
      const { search } = getState();
      setState({ search: { ...search, [prop]: value } });
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    search: {},
  },
  actions,
  name: "AdditionalPartySearch",
});

const useAdditionalPartySearch = createHook(Store, {
  selector: (state: State) => state.search,
});

const useAdditionalPartySearchAction = createHook(Store, {
  selector: null,
});

export { useAdditionalPartySearch, useAdditionalPartySearchAction };
