import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { UIConstants } from "utils/data/enum";

type State = {
  open: boolean;
  title: string;
  onClose?: () => void;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  openAutomaticProgressDialog:
    (
      title: string = UIConstants.LOADING_IN_PROGRESS_MESSAGE,
      onCloseFn?: () => void
    ) =>
    ({ setState }: StoreApi) => {
      setState({ open: true, title, onClose: onCloseFn });
    },
  closeAutomaticProgressDialog:
    (delay?: number) =>
    ({ getState, setState }: StoreApi) => {
      const { onClose } = getState();
      onClose?.();

      setTimeout(() => {
        setState({ open: false, onClose: undefined });
      }, delay);
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    open: false,
    title: "",
  },
  actions,
  name: "AutomaticProgressDialog",
});

const getDialogStatus = (state: State) => ({
  open: state.open,
  title: state.title,
});

const useAutomaticProgressDialogState = createHook(Store, {
  selector: getDialogStatus,
});

const useAutomaticProgressDialogActions = createHook(Store, {
  selector: null,
});

export { useAutomaticProgressDialogState, useAutomaticProgressDialogActions };
