import { Typography } from "@material-ui/core";
import PageSection from "controls/global/page-section/PageSection";
import PdfViewer from "controls/global/pdf-viewer/PdfViewer";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { SelectionConfig } from "controls/global/stewart-table/StewartTable";
import { FileSearchCriteria } from "entities/ApiModel";
import { IColumn } from "entities/ApiModel/IColumn";
import { PaymentsFileExportCriteria } from "entities/ApiModel/PaymentsFileExportCriteria";
import { PdfDocument } from "entities/UIModel";
import { FilePaymentSearch } from "entities/UIModel/FilePaymentSearch";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import { PendingPayKeywordSearch } from "entities/UIModel/PendingPayKeywordSearch";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import {
  orderBy as _orderBy,
  debounce,
} from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CashClockIcon from "theme/icons/CashClockIcon";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useLookup } from "utils/context/LookupContext";
import { usePayment } from "utils/context/PaymentContext";
import { usePendingPay } from "utils/context/PendingPayContext";
import { useUser } from "utils/context/UserContext";
import useDidMountEffect from "utils/custom-hooks/useDidMountEffect";
import useTitle from "utils/custom-hooks/useTitle";
import {
  DateTypeCode,
  PaymentsFileExportCriteriaType,
  ReportingOptionType,
} from "utils/data/enum";
import { Order } from "utils/sorting";
import { v4 as uuidv4 } from "uuid";
import FileExportDialog from "./components/file-export-dialog";
import PendingPayGrid from "./components/pendingpay-grid/PendingPayGrid";
import PendingPayPageHeader from "./components/pendingpay-header/PendingPayPageHeader";

const SUB_HEADING_TEXT = "The files listed below have been reported and Stewart is awaiting payment. Once payment is received, the file will be removed from this list.";

const DefaultPage = 1;
const TotalRows = 100;
const NoRecordsMessage = "Loading...";
const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
const DefaultReportingOptions = [ReportingOptionType.ReportOnly, ReportingOptionType.AutoReport, ReportingOptionType.PayByCheck, ReportingOptionType.EPay];
const DefaultDateTypeCode = DateTypeCode.ReportedDate;
const defaultSortColumn = "-LastBilledDate";
const defaultOrderBy = "lastBilledDate";
const defaultOrder = "desc";
const date = new Date();
const DefaultDateRangeStart = new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() - (30 * 24 * 60 * 60 * 1000));

const PendingPayPage = () => {
  useTitle("Stewart Connect - Pending Payments");

  const [
    {
      error: requestError,
      progressPercent: loadingProgressPercent,
      refreshData,
      onlyShowSelectedFiles,
      isAdvSearchOpen,
      selectedItems,
    },
    {
      getPendingPayList,
      getPendingPayListLocal,
      setRequestTimeoutMs,
      cancelRequests,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
      getAdvancedSearchFiles,
      updateSelectedItem,
      updateSelectedItems,
      setOnlyShowSelectedFiles,
      setIsAdvSearchOpen,
      restoreSelectedItems,
    }
  ] = usePendingPay();
  const defaultPendingPayCriteriaSearch: PendingPayCriteriaSearch =
  {
    listCount: TotalRows,
    requestedPage: 1,
    reportOptionTypeCode: DefaultReportingOptions
      .map(value => value.toString())
      .join("|"),
    dateTypeCode: DefaultDateTypeCode,
    dateRangeStart: DefaultDateRangeStart,
  };

  const [, { exportFiles }] = usePayment();
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(DefaultPage);
  const [pendingPayList, setPendingPayList] = useState<PendingPayFile[]>([]);
  const [filters, setFilters] = useState<PendingPayCriteriaSearch>(defaultPendingPayCriteriaSearch);
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([NoRecordsMessage]);
  const [hiddenColumns, setHiddenColumns] = useState<(keyof PendingPayFile)[]>(getInitialColumnsDefintion(true));
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const bodyRef = useContext(LayoutBodyContext);
  const [orderBy, setOrderBy] = useState<keyof PendingPayFile | undefined>(defaultOrderBy);
  const [sortColumn, setSortColumn] = useState<string | undefined>(defaultSortColumn);
  const records = pendingPayList ?? [];
  const { generalConfig } = useConfigContext();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [exportedColumns, setExportedColumns] = useState<string[]>([]);

  const [,
    {
      getRowsPerPageSetting,
      setRowsPerPageSetting,
    }
  ] = useUser();
  const [, { getReportOptionTypes }] = useLookup();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const globalAccessAgencyID = globalAccessAgency?.[0]?.CompanyID;
  const globalAccessAgencyName = globalAccessAgency?.[0]?.CompanyName;

  const filteredPendingPayList = pendingPayList ?? [];

  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const timeoutMs = generalConfig.filesSearchTimeoutMs;
    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }
    if (timeoutMs) {
      setRequestTimeoutMs(timeoutMs);
    }
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs)
    , [userInputDebounceTimeMs]
  );

  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileSearchId: "FileSearchID",
      fileId: "ClientFileID",
      clientFileId: "ClientFileID",
      reportOptionTypeCode: "ReportOptionTypeCode",
      propertyTypeCode: "PropertyTypeCode",
      propertyAddress: "PropertyAddress",
      orderNumber: "OrderNumber",
      lastBilledBy: "LastBilledBy",
      lastBilledDate: "LastBilledDate",
      user: 'User',
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
    };
    return mappedColumnNames[name] ?? name;
  };

  const convertValue = (name: string, value: any) => {
    const fields = ["totalBilledAmountDue", "totalBilledActualFee", "totalBilledActualRiskRate", "orderNumber"];
    if (value !== null && fields.includes(name)) {
      return Number(value);
    }
    return value;
  };
  const getDatabaseSearchColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileSearchId: "fileSearchID",
      fileId: "clientFileID",
      reportOptionTypeCode: "reportOptionTypeCode",
      propertyTypeCode: "propertyTypeCode",
      propertyAddress: "propertyAddress",
      orderNumber: "orderNumber",
      lastBilledBy: "lastBilledBy",
      lastBilledDate: "lastBilledDate",
    };
    return mappedColumnNames[name] ?? name;
  };
  const getDefaultFilters = (advanced: boolean): PendingPayCriteriaSearch => {
    const dateType = DateTypeCode.ReportedDate;
    const reportOptionTypeCode = advanced ? undefined : DefaultReportingOptions
      .map(value => value.toString())
      .join("|");
    const dateRangeStart = advanced ? undefined : DefaultDateRangeStart;

    const defaultFilters: PendingPayCriteriaSearch = {
      dateTypeCode: dateType,
      listCount: TotalRows,
      requestedPage: 1,
      sortColumn: sortColumn,
      reportOptionTypeCode,
      dateRangeStart,
    };

    return defaultFilters;
  };

  const handleRequestSort = async (columnName: string, disableToggling: boolean = false) => {
    await cancelRequests();

    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof PendingPayFile);
    setSortColumn(sortBy);
  };

  const handleColumnResize = (column: keyof PendingPayFile, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", newWidth);
    }
  };

  const handleToggleAllRows = async () => {
    if (pendingPayList) {
      updateSelectedItems(pendingPayList);
      reloadLocalSearch();
    }
  };

  const handleToggleRow = (file: PendingPayFile) => {
    updateSelectedItem(file);
    reloadLocalSearch();
  };

  const selectionConfig: SelectionConfig<PendingPayFile> = {
    enabled: true,
    selectedRows: selectedItems?.map(s => s.fileId.toString()) ?? [],
    onToggleAllRows: handleToggleAllRows,
    onToggleRow: handleToggleRow,
    isRowSelected: (file: PendingPayFile) => selectedItems?.some(f => f.fileId == file.fileId),
  };

  const handleColumnsModified = (columns: IColumn[]) => {
    const exportedCols = columns.filter(c => !hiddenColumns.includes(c.field as keyof PendingPayFile))
      .map(c => c.field);

    setExportedColumns(exportedCols);
  };

  const handleUpdateHiddenColumns = (columns: (keyof PendingPayFile)[]) => {
    setHiddenColumns(columns);
  };

  const handleExport = async (allFiles: boolean, itemizeProducts: boolean) => {
    const requestId = uuidv4();

    let searchCriteria: FilePaymentSearch = {};
    if (allFiles) {
      searchCriteria = isAdvSearchOpen ? getAdvancedSearchCriteria() : getSearchCriteria();
      searchCriteria = {
        ...searchCriteria,
        criteriaType: isAdvSearchOpen
          ? PaymentsFileExportCriteriaType.PendingPaymentsKeyword
          : PaymentsFileExportCriteriaType.PendingPaymentsCriteria,
        ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      };
    }
    else {
      searchCriteria.criteriaType = PaymentsFileExportCriteriaType.PendingPaymentsCriteria;
    }

    const exportCriteria: PaymentsFileExportCriteria = {
      FileIds: allFiles
        ? []
        : selectedItems.map(s => s.fileId),
      ExportColumns: [
        "clientFileId",
        ...exportedColumns.filter(c => c !== "clientFileID" && c !== "totalBilledAmountDue"),
        "totalBilledAmountDue",
      ],
      ItemizeEachProduct: itemizeProducts,
      SearchCriteria: searchCriteria,
    };

    await exportFiles(exportCriteria, requestId);
    setOpenExportDialog(false);
  };

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof PendingPayFile,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof PendingPayFile),
    }));

  const reloadLocalSearch = () => {
    if (onlyShowSelectedFiles) {
      setPage(DefaultPage);
      performSearch();
    }
  };

  const handleOnlySelectedFilesChange = (value: boolean) => {
    setOnlyShowSelectedFiles(value);
  };

  const canSearch = (): boolean => {
    return settingsLoaded &&
      !partialFilterUpdate &&
      columns?.length > 0 &&
      (filters !== undefined && Object.values(filters).length > 0);
  };

  const getSearchCriteria = () => {
    let criteria: PendingPayCriteriaSearch = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      ...(!filters.agencyID && globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }

    for (const key in criteria) {
      const criteriaKey = key as keyof PendingPayCriteriaSearch;
      if (criteria[criteriaKey]) {
        criteria[criteriaKey] = convertValue(criteriaKey, criteria[criteriaKey]);
      }
    }

    return criteria;
  };

  const getAdvancedSearchCriteria = () => {
    let criteria: PendingPayKeywordSearch = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      ...(!filters.agencyID && globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }

    return criteria;
  };

  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    if (isAdvSearchOpen) {
      const keyword = (filters as PendingPayKeywordSearch).keyword;
      let searchFields: string[] = [];
      keyword && searchFields.push(keyword);
      globalAccessAgencyName && searchFields.push(globalAccessAgencyName);
      if (searchFields) {
        firstMessage = `No matches found for "${searchFields.join(", ")}"`;
      }
    }
    else {
      const keys: (keyof PendingPayCriteriaSearch)[] = [
        "clientFileId",
        "locationLegacyID",
        "orderNumber",
        "propertyAddress",
        "lastBilledBy",
        "totalBilledActualRiskRate",
        "totalBilledActualFee",
        "totalBilledAmountDue",
      ];
      const providedFields = keys
        .map(key => filters[key])
        .filter(value => !!value && value !== globalAccessAgencyID);

      if (!filters.agencyID && globalAccessAgencyName && !providedFields.includes(globalAccessAgencyName)) {
        providedFields.push(globalAccessAgencyName);
      }

      if (providedFields.length) {
        firstMessage = `No matches found for "${providedFields.join(", ")}"`;
      }
    }
    return [firstMessage, secondMessage];
  };

  const performSearch = async () => {
    let files: PendingPayFile[] | undefined = [];

    if (isAdvSearchOpen) {
      const criteria = getAdvancedSearchCriteria();
      files = await getAdvancedSearchFiles({ ...criteria });
    } else {
      const criteria = getSearchCriteria();
      if (onlyShowSelectedFiles) {
        files = await getPendingPayListLocal({ ...criteria });
      } else {
        files = await getPendingPayList({ ...criteria });
      }
    }

    if (files !== undefined) {
      setPendingPayList(files);

      let currentPage = 0;
      if (files.length > 0) {
        currentPage = files[0].currentPage;
      } else {
        setNoRecordsMessage(getNoRecordsMessage());
        currentPage = 1;
      }

      if (currentPage !== page) {
        setPage(currentPage);
      }
    }
  };

  const removeInvalidProperties = (object: { [key: string]: any; }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {
      if (object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen &&
          hiddenColumns.find(c => c === key || getDatabaseSearchColumnName(c) === key))
      ) {
        delete object[key];
        deleteCount++;
      }
    });

    return { object, deleteCount };
  };

  const handleFilterChange = async (name: keyof PendingPayCriteriaSearch, value: any) => {
    await cancelRequests();
    const formattedValue = (typeof value) === "string" ? value.replaceAll(",", "|") : value;
    const updatedFilters = (filters: PendingPayCriteriaSearch | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      }
      else if (!isAdvSearchOpen && name === "dateRangeStart") {
        handleRequestSort("openedTimestamp", true);
        dateType = DateTypeCode.Created;
      }
      else {
        dateType = filters?.dateTypeCode;
      }
      const nameKey = getDatabaseSearchColumnName(name);
      let adjustedFilters: PendingPayCriteriaSearch = {
        ...filters,
        [nameKey]: formattedValue,
        dateTypeCode: dateType,
      };
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setPage(1);
    setFiltersApplied(true);
    setFilters(updatedFilters);
  };

  const handleAdvancedFilterChange = (
    name: keyof PendingPayCriteriaSearch,
    value: any,
    shouldSearch: boolean
  ) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();

    const resetFilters = (filters: PendingPayKeywordSearch | undefined) => {
      setOrderBy(defaultOrderBy);
      setOrder(defaultOrder);
      return getDefaultFilters(open);
    };

    setFiltersApplied(false);
    setFilters(resetFilters);
    setSortColumn(undefined);
    setPartialFilterUpdate(false);
    setPage(1);
    setIsAdvSearchOpen(open);
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();

    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(1);
  };

  const handleShowPaymentSheetPdf = (pdfUrl: string) => {
    let pdfs: PdfDocument[] = [];
    if (pdfUrl) {
      pdfs.push({
        fileId: 0,
        productType: "",
        orderId: 0,
        documentId: 0,
        pdfUrl: pdfUrl,
        fileName: pdfUrl.split("?")[0].split("/")?.pop() ?? "",
        showHidden: 0,
      });
    }
    setPdfDocuments(pdfs);
    setOpenPdfViewer(pdfs.length > 0);
  };

  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };
    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    setPage(DefaultPage);
  }, [onlyShowSelectedFiles]);

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      await getReportOptionTypes();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || pendingPayList === undefined) {
      setFilters((filters: FileSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };

    getColumns();
    return () => {
      restoreSelectedItems();
    };
  }, []);

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    columns,
    filters,
    filtersApplied,
    page,
    partialFilterUpdate,
    refreshData,
    rowsPerPage,
    settingsLoaded,
    sortColumn,
  ]);

  useEffect(() => {
    if (requestError === "TIMEOUT") {
      setNoRecordsMessage([TimeoutMessage]);
      setPendingPayList([]);
    }
  }, [requestError]);


  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  useEffect(() => {
    bodyRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [rowsPerPage]);

  useEffect(() => {
    const exportedCols = _orderBy(columns.filter(c => !hiddenColumns.includes(c.field as keyof PendingPayFile)), "position")
      .map(c => c.field);

    setExportedColumns(exportedCols);
  }, [columns]);

  useEffect(() => {
    handleColumnsModified(columns);
  }, [hiddenColumns]);

  useDidMountEffect(() => {
    performSearch();
  }, [onlyShowSelectedFiles]);

  return (
    <>
      <PageSection
        icon={<CashClockIcon />}
        title="Pending Payments"
        isError={false}
        contentAlignment="beside-header"
      >
        <>
          <PendingPayPageHeader
            columns={visibleColumns}
            hiddenColumns={hiddenColumns}
            updateHiddenColumns={handleUpdateHiddenColumns}
            handleAdvancedFilterChange={handleAdvancedFilterChange}
            handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
            setOpenExportDialog={setOpenExportDialog}
          />
          <Typography className="subheading">
            {SUB_HEADING_TEXT}
          </Typography>
          <PendingPayGrid
            colsConfig={columns}
            rows={records}
            loadingProgressPercent={loadingProgressPercent}
            noRowsMessage={noRecordsMessage}
            page={page}
            hiddenColumns={hiddenColumns}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            currentFilter={filters}
            reportingOptions={DefaultReportingOptions}
            selectionConfig={selectionConfig}
            onFiltersChange={handleFilterChange}
            onOnlySelectedFilesChange={handleOnlySelectedFilesChange}
            onlyShowSelectedFiles={onlyShowSelectedFiles}
            onRowsPerPageChange={handleRowsPerPageChange}
            onShowPdf={handleShowPaymentSheetPdf}
            showActionRow={!isAdvSearchOpen}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onColumnResize={handleColumnResize}
            onColumnsModified={handleColumnsModified}
          />
          <FileExportDialog
            open={openExportDialog}
            onCancel={() => setOpenExportDialog(false)}
            onExport={handleExport}
            showSelectionPrompt={selectedItems?.some(s => s)}
            selectionPrompt="Would you like to include the selected file(s) only or all reported files?"
            showItemizedPrompt={true}
            itemizedPrompt="Would you like this export to display itemized products?"
          />
        </>
      </PageSection >
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfDocuments={pdfDocuments}
      />
      <ScrollToTopArrow refreshSize={filteredPendingPayList} />
    </>
  );
};

export default PendingPayPage;
