import Button from "@material-ui/core/Button";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";
import { v4 as uuidv4 } from "uuid";
import DefaultInfo from "./default-info";
import SignatureInfo from "./signature-info";

const DUPLICATE_MESSAGE =
  "A default signature for this Agency Location already exists. Please select a different location";

const WARNNING_MESSAGE =
  "This default effects all users at this agency. Are you sure you want to revise it?";

type Dialog = {
  open: boolean;
  message?: string;
  df?: any;
};

type Props = {
  saveRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const SignatureDefaultForm = ({ saveRef }: Props) => {
  const [duplicateDialog, setDuplicateDialog] = useState<Dialog>({
    open: false,
  });
  const [warningDialog, setWarningDialog] = useState<Dialog>({
    open: false,
  });
  const [{ defaults, initialDefault, agencyWideLevelCode }, { getDefaults, saveDefault, resetState }] =
    useSignatureDefaults();
  const [, { getSignatures }] = useSignatures();
  const {
    mode,
    cancelDialog,
    updateSaveEnabled,
  } = useSignatureDefaultDialogProvider();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      ...initialDefault,
      agency: initialDefault.agency.value,
    },
  });

  const { handleSubmit } = methods;

  const checkForDuplicate = (agency: string, state: string, location: string, level: string, signatureImageId: number) => {
    return defaults.some(d =>
      d.agencyID === agency &&
      d.stateCode === state &&
      d.locationUniqueIDs === location &&
      d.signatureLevelTypeCode === level &&
      d.signatureImageID === signatureImageId
    );
  };

  const submitForm = async (df: any) => {
    const signatureImageId = Number(df.signatureImage.value);
    let isDuplicate = checkForDuplicate(df.agency, df.state.value, df.location.value, df.level.value, signatureImageId);

    if (!isDuplicate) {
      const data = {
        key: df.key,
        existingUserAgencySignatureDefaultIDs: initialDefault.existingUserAgencySignatureDefaultIDs,
        agencyID: df.agency,
        agencyName: df.agency.text,
        stateCode: df.state.value,
        state: df.state.text,
        locationUniqueIDs: df.location.value,
        locationDisplayName: df.location.text,
        signatureLevelTypeCode: df.level.value,
        signatureLevelTypeName: df.level.text,
        signatureImageID: signatureImageId,
        signatureImageName: df.signatureImage.text,
        integrationKey: uuidv4(),
      };

      const status = await saveDefault({ ...data });
      isDuplicate = (status === 409);
    }

    if (isDuplicate) {
      setDuplicateDialog({
        open: true,
        message: DUPLICATE_MESSAGE,
      });
      return;
    }

    getSignatures();
    getDefaults();
    cancelDialog();
    resetState();
  };

  const preSubmitForm = async (df: any) => {
    updateSaveEnabled(false);
    if (df.level.value === agencyWideLevelCode && mode === "EDIT") {
      setWarningDialog({
        open: true,
        message: WARNNING_MESSAGE,
        df: df,
      });
      return;
    }
    await submitForm(df);
  };

  const handleYes = async (df: any) => {
    await submitForm(df);
    setWarningDialog({
      open: false,
      message: "",
      df: null,
    });
  };

  const handleNo = () => {
    updateSaveEnabled(true);
    setWarningDialog({
      open: false,
      message: "",
      df: null,
    });
  };

  const handleOk = () => {
    updateSaveEnabled(true);
    setDuplicateDialog({
      open: false,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <SignatureInfo />
          <DefaultInfo />
          <Button
            hidden
            innerRef={saveRef}
            onClick={handleSubmit(preSubmitForm)}
          />
        </form>
      </FormProvider>
      <ConfirmationDialog
        isOpen={duplicateDialog.open}
        confirmationMessage={duplicateDialog.message}
        singleActionButton={true}
        singleActionButtonText="Ok"
        onYes={handleOk}
      />
      <ConfirmationDialog
        isOpen={warningDialog.open}
        confirmationMessage={warningDialog.message}
        onYes={() => handleYes(warningDialog.df)}
        onNo={handleNo}
      />
    </>
  );
};

export default SignatureDefaultForm;
