import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinearProgressWithLabel from "controls/global/linear-progress-with-label";
import React from "react";
import { colors } from "theme/defaultStyle";
import { useAutomaticProgressDialogState } from "utils/context/AutomaticProgressDialogContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles"

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#F2F2F2",
  },
  barColorPrimary: {
    backgroundColor: `${colors.blue01}`,
  },
})(LinearProgressWithLabel);

const AutomaticProgressDialog = () => {
  const classesLoc = useDialogStyles({ type: "INFO", width: 580 });
  const [pctComplete, setPctComplete] = React.useState(0);
  const [show, setShow] = React.useState<boolean>(false);
  const [{ open, title }] = useAutomaticProgressDialogState();


  React.useEffect(() => {
    if (!show) {
      return;
    }

    const interval = setInterval(() => {
      if (pctComplete === 100) return;
      if (pctComplete < 80) {
        const newPctComplete = pctComplete + 20;
        setPctComplete(newPctComplete);
      }
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, [show, pctComplete, setPctComplete]);

  React.useEffect(() => {
    if (open) {
      setPctComplete(0);
      setShow(true);
      return;
    }

    if (!open) {
      setPctComplete(100);
      setTimeout(() => {
        setShow(false);
      }, 100)
    }
  }, [open])

  return (
    <Dialog open={show} maxWidth="md" classes={{ root: classesLoc.dialogRoot }}>
      <DialogTitle
        disableTypography={true}
        classes={{ root: classesLoc.dialogTitleRoot }}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classesLoc.dialogContentRoot }}>
        <StyledLinearProgress value={pctComplete} />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default AutomaticProgressDialog;
