import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type State = {
  open: boolean;
  title: string | undefined;
  requestId: string | undefined;
  onCloseAction?: (...args: unknown[]) => unknown;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;
type ProgressbarProps = {
  title: string;
  requestId: string;
  onCloseAction?: (...args: unknown[]) => unknown;
};

const actions = {
  openProgressbar:
    ({ title, requestId, onCloseAction }: ProgressbarProps) =>
    ({ setState }: StoreApi) => {
      setState({ open: true, title, requestId, onCloseAction });
    },
  closeProgresbar:
    () =>
    ({ setState, getState }: StoreApi) => {
      getState().onCloseAction?.();
      setState({ open: false, requestId: undefined, onCloseAction: undefined });
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    open: false,
    title: undefined,
    requestId: undefined,
  },
  actions,
  name: "progress bar",
});

const hook = createHook(Store);
export const useProgressbar = () => { return hook() };
