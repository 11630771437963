import { 
  Pricing,
  PricingProduct,
} from "entities/UIModel";
import { PricingType } from "utils/data/enum";
import { 
  convertToNumber,
  formatCurrency,
} from "utils/shared";
import useCreateFile from "./useCreateFile";
import useFormWrapper from "./useFormWrapper";
import usePricing from "./usePricing";

export default function usePricingCalculator() {
  const { getValues } = useFormWrapper();
  const {isFileLockedWithUpdateablePricingItem} = usePricing();
  const {isFileLocked} = useCreateFile();

  const getPremiumSubTotal = (isPricingSectionUpdated: boolean) => {
    let actualFeeTotalProduct = 0;
    let riskRateTotalProduct = 0;
    let agentRetentionTotalProduct = 0;
    let totalDueTotalProduct = 0;

    if (!isPricingSectionUpdated || isFileLocked) {
      const pricing: Pricing = getValues("pricing");

      if (!isFileLocked) {
        actualFeeTotalProduct = convertToNumber(pricing?.totalActualFee);
        riskRateTotalProduct = convertToNumber(pricing?.totalActualRiskRate);
        agentRetentionTotalProduct = convertToNumber(pricing?.totalActualRetention);
        totalDueTotalProduct = convertToNumber(pricing?.totalActualRemittance);
      }
      else {
        actualFeeTotalProduct = convertToNumber(pricing?.totalBilledActualFee);
        riskRateTotalProduct = convertToNumber(pricing?.totalBilledActualRiskRate);
        agentRetentionTotalProduct = convertToNumber(pricing?.totalBilledActualRetention);
        totalDueTotalProduct = convertToNumber(pricing?.totalBilledActualRemittance);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      pricingProducts
        ?.filter((p) => p.pricingType === PricingType.Product)
        ?.forEach((p) => {
          if ((!isFileLocked || (isFileLocked && p.isBilled === 1)) && p.isReadyToBeBilled === 1) {
            actualFeeTotalProduct += convertToNumber(p.actualFee);
            riskRateTotalProduct += convertToNumber(p.actualRiskRate);
            agentRetentionTotalProduct += convertToNumber(p.agentRetention);
            totalDueTotalProduct += convertToNumber(p.totalDue);
          }

          p.productItems
            ?.filter(
              (pi) => (!isFileLocked || (isFileLocked && pi.isBilled === 1)) && pi.isReadyToBeBilled === 1
            )
            .forEach((pi) => {
              actualFeeTotalProduct += convertToNumber(pi.actualFee);
              riskRateTotalProduct += convertToNumber(pi.actualRiskRate);
              agentRetentionTotalProduct += convertToNumber(pi.agentRetention);
              totalDueTotalProduct += convertToNumber(pi.totalDue);
            });
        });
    }

    return {
      actualFeeTotalProduct: formatCurrency(actualFeeTotalProduct),
      riskRateTotalProduct: formatCurrency(riskRateTotalProduct),
      agentRetentionTotalProduct: formatCurrency(agentRetentionTotalProduct),
      totalDueTotalProduct: formatCurrency(totalDueTotalProduct),
    };
  };

  const getPricingTotal = (isPricingSectionUpdated: boolean, hasFileLockedWithUpdateablePricingItem: boolean, displayRiskRateColumn: boolean) => {
    let actualFeeTotal = 0;
    let riskRateTotal = 0;
    let agentRetentionTotal = 0;
    let totalDueTotal = 0;

    if (!isPricingSectionUpdated ) {
      const pricing: Pricing = getValues("pricing");

      if (hasFileLockedWithUpdateablePricingItem) {
        if (displayRiskRateColumn) {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }
        else {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }

      } else {
        if (displayRiskRateColumn) {
          actualFeeTotal = convertToNumber(pricing?.totalActualFee);
          riskRateTotal = convertToNumber(pricing?.totalActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalAmountDue);
        } else {
          actualFeeTotal = convertToNumber(pricing?.totalActualCombinedFee);
          riskRateTotal = convertToNumber(pricing?.totalActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalAmountDue);
        }
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");
      // let actualFeeTotalTax = 0;
      pricingProducts
        // ?.filter((p) => p.pricingType === PricingType.Product)
        ?.forEach((p) => {
          if ((!isFileLocked || (isFileLocked && p.isBilled === 1)) && p.isReadyToBeBilled === 1) {
            actualFeeTotal += convertToNumber(p.actualFee);
            // actualFeeTotalTax += convertToNumber(p.actualPremiumTax);
            riskRateTotal += convertToNumber(p.actualRiskRate);
            agentRetentionTotal += convertToNumber(p.agentRetention);
            totalDueTotal += convertToNumber(p.totalDue);
          }

          p.productItems
            ?.filter(
              (pi) => (!isFileLocked || (isFileLocked && pi.isBilled === 1)) && pi.isReadyToBeBilled === 1
            )
            .forEach((pi) => {
              actualFeeTotal += convertToNumber(pi.actualFee);
              // actualFeeTotalTax += convertToNumber(pi.actualPremiumTax);
              riskRateTotal += convertToNumber(pi.actualRiskRate);
              agentRetentionTotal += convertToNumber(pi.agentRetention);
              totalDueTotal += convertToNumber(pi.totalDue);
            });
        });

      // actualFeeTotal = actualFeeTotal + actualFeeTotalTax;
      // riskRateTotal = riskRateTotal + actualFeeTotalTax;
      // totalDueTotal = totalDueTotal + actualFeeTotalTax;
    }

    return {
      actualFeeTotal : formatCurrency(actualFeeTotal),
      riskRateTotal : formatCurrency(riskRateTotal),
      agentRetentionTotal : formatCurrency(agentRetentionTotal),
      totalDueTotal : formatCurrency(totalDueTotal),
    };
  };

  const getPricingNewTotal = (isPricingSectionUpdated: boolean, displayRiskRateColumn: boolean) => {
    let newTotalActualFee = 0;
    let newTotalRiskRate = 0;
    let newTotalAgentRetention = 0;
    let newTotalTotalDue = 0;

    if (!isPricingSectionUpdated) {
      // New Total row is only visible for the locked file
      const pricing: Pricing = getValues("pricing");

      if (displayRiskRateColumn) {
        newTotalActualFee = convertToNumber(pricing?.totalActualFee);
        newTotalRiskRate = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalAgentRetention = convertToNumber(pricing?.totalActualRetention);
        newTotalTotalDue = convertToNumber(pricing?.totalAmountDue);
      }
      else {
        newTotalActualFee = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalRiskRate = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalAgentRetention = convertToNumber(pricing?.totalActualRetention);
        newTotalTotalDue = convertToNumber(pricing?.totalAmountDue);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      pricingProducts.forEach((p) => {
        if (isFileLockedWithUpdateablePricingItem(p, isFileLocked)) {
          newTotalActualFee += convertToNumber(p.actualFee);
          newTotalRiskRate += convertToNumber(p.actualRiskRate);
          newTotalAgentRetention += convertToNumber(p.agentRetention);
          newTotalTotalDue += convertToNumber(p.totalDue);
        }

        p.productItems
          ?.filter((pi) =>
            isFileLockedWithUpdateablePricingItem(pi, isFileLocked)
          )
          .forEach((pi) => {
            newTotalActualFee += convertToNumber(pi.actualFee);
            newTotalRiskRate += convertToNumber(pi.actualRiskRate);
            newTotalAgentRetention += convertToNumber(pi.agentRetention);
            newTotalTotalDue += convertToNumber(pi.totalDue);
          });
      });
    }

    return {
      newTotalActualDue : formatCurrency(newTotalActualFee),
      newTotalRiskRate : formatCurrency(newTotalRiskRate),
      newTotalAgentRetention : formatCurrency(newTotalAgentRetention),
      newTotalTotalDue : formatCurrency(newTotalTotalDue),
    };
  };

  const getPricingTaxTotal = (isPricingSectionUpdated: boolean) => {
    // Initialize
    let actualFeeTotalTax = 0;
    let riskRateTotalTax = 0;
    let agentRetentionTotalTax = 0;
    let totalDueTotalTax = 0;

    if (!isPricingSectionUpdated  || isFileLocked) {
      const pricing: Pricing = getValues("pricing");

      if (!isFileLocked) {
        actualFeeTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
        riskRateTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
        agentRetentionTotalTax = 0.00;
        totalDueTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
      }
      else {
        actualFeeTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
        riskRateTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
        agentRetentionTotalTax = 0.00;
        totalDueTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      if (isFileLocked) {
        pricingProducts
          ?.filter((p) => p.pricingType === PricingType.Product)
          ?.forEach((p) => {
            if (p.isBilled && p.isBilled === 1 && p.isReadyToBeBilled === 1) {
              actualFeeTotalTax += convertToNumber(p.actualPremiumTax);
            }

            p.productItems
              ?.filter((pi) => pi.isBilled === 1 && pi.isReadyToBeBilled === 1)
              ?.forEach((pi) => {
                actualFeeTotalTax += convertToNumber(pi.actualPremiumTax);
              });
          });

        riskRateTotalTax = actualFeeTotalTax;
        agentRetentionTotalTax = 0;
        totalDueTotalTax = actualFeeTotalTax;

      } else {
        pricingProducts
          ?.filter((p) => p.pricingType === PricingType.Tax)
          ?.forEach((p) => {
            if (p.isReadyToBeBilled === 1) {
              actualFeeTotalTax += convertToNumber(p.actualFee);
              riskRateTotalTax += convertToNumber(p.actualRiskRate);
              agentRetentionTotalTax += convertToNumber(p.agentRetention);
              totalDueTotalTax += convertToNumber(p.totalDue);
            }
          });
      }
    }

    return {
      actualFeeTotalTax : formatCurrency(actualFeeTotalTax),
      riskRateTotalTax : formatCurrency(riskRateTotalTax),
      agentRetentionTotalTax : formatCurrency(agentRetentionTotalTax),
      totalDueTotalTax : formatCurrency(totalDueTotalTax),
    };
  }

  return {
    getPremiumSubTotal,
    getPricingTaxTotal,
    getPricingTotal,
    getPricingNewTotal,
  };
}
