import {
  Box,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import ForeignAddress from "controls/global/foreign-address";
import FullAddress from "controls/global/full-address/FullAddress";
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import TextInputField from "controls/global/text-input-field";
import React, {
  useCallback,
} from "react";
import { useWatch } from "react-hook-form";
import { usePartySellerActions } from "utils/context/PartySellerContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import { PartyType } from "utils/data/enum";

const useStyles = makeStyles({
  firstName: {
    "& input.MuiInputBase-input": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
});

const SellerData = ({
  field,
  formPrefix,
  index,
  isFileDisabled,
  onDeleteSeller,
  partyTypes,
  suffixes,
  fileNameNumber,
  agency,
  primaryProperty,
  additionalParties,
  hasIssuedProducts,
  isPopulatingExistingFile,
}: {
  field: any;
  formPrefix: string;
  index: number;
  isFileDisabled: boolean;
  onDeleteSeller: any;
  partyTypes: SelectFieldOption[];
  suffixes: SelectFieldOption[];
  fileNameNumber: any;
  agency: any;
  primaryProperty: any;
  additionalParties: any;
  hasIssuedProducts: any;
  isPopulatingExistingFile: any;
}) => {
  const { firstName } = useStyles();
  const { setValue, getValues, register } = useFormWrapper();
  const [, { setSellers, updateZipCodeSeller }] = usePartySellerActions();

  const integrationKeyField = `${formPrefix}.${index}.integrationKey`;
  const filePartyKeyField = `${formPrefix}.${index}.filePartyKey`;
  const partyRoleTypeCodeField = `${formPrefix}.${index}.partyRoleTypeCode`;
  const isForeignAddressFieldName = `${formPrefix}.${index}.isForeignAddress`;
  const partyTypeCodeFieldName = `${formPrefix}.${index}.partyTypeCode`;

  const [partyType, isForeignAddress] = useWatch({
    name: [
      getNameString(partyTypeCodeFieldName),
      getNameString(isForeignAddressFieldName),
    ],
  });

  const getSellerDescription = (() => {
    const party = getValues(`${formPrefix}.${index}`);
    let fullName = "";
    if (!party) return fullName;

    if (partyType === PartyType.Individual) {
      const firstName = party.partyFirstName ? party.partyFirstName : "";
      const middleName = party.partyMiddleName
        ? ` ${party.partyMiddleName}`
        : "";
      const lastName = party.partyLastName ? party.partyLastName : "";
      const suffix = party.suffixType?.name ? ` ${party.suffixType?.name}` : "";
      fullName = `${firstName} ${middleName} ${lastName} ${suffix}`;
      if (fullName.trim()) {
        return fullName;
      }
    } else {
      fullName = party.companyName && ` ${party.companyName}`;
      return fullName;
    }
  })();

  const handleOnChange = (_: React.ChangeEvent<any>, index: number) => {
    setTimeout(
      () => setValue(`${formPrefix}.${index}.requirePartyValidation`, false),
      100
    );

    setValue(`${formPrefix}.${index}.companyName`, "");
    setValue(`${formPrefix}.${index}.partyFirstName`, "");
    setValue(`${formPrefix}.${index}.partyMiddleName`, "");
    setValue(`${formPrefix}.${index}.partyLastName`, "");
    setValue(`${formPrefix}.${index}.suffixType.code`, "");
  };

  const handleSuffixChange = (
    selectedOption: SelectFieldOption,
    index: number
  ) => {
    setValue(`${formPrefix}.${index}.suffixType`, selectedOption);
  };

  const updatePartySeller = useCallback(() => {
    setSellers(getValues("sellerParties"));
  }, [getValues, setSellers]);

  return (
    <Box key={field.integrationKey}>
      <CollapsableFormSection
        index={index}
        title={`Seller ${index + 1}`}
        label={getSellerDescription}
        showDelete={!isFileDisabled}
        onDelete={onDeleteSeller}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SelectField
              label="Party Type"
              name={`${formPrefix}.${index}.partyTypeCode`}
              options={partyTypes}
              onChange={handleOnChange}
              defaultValue={partyType || PartyType.Individual}
            />
          </Grid>
          <Box width={1}></Box>
          {partyType === PartyType.Individual ? (
            <>
              <Grid item xs={12} md={4} lg={3}>
                <TextInputField
                  label="First Name"
                  name={`${formPrefix}.${index}.partyFirstName`}
                  defaultValue={field.partyFirstName}
                  className={firstName}
                  onBlur={updatePartySeller}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <TextInputField
                  label="Middle"
                  name={`${formPrefix}.${index}.partyMiddleName`}
                  defaultValue={field.partyMiddleName}
                  onBlur={updatePartySeller}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInputField
                  label="Last Name"
                  name={`${formPrefix}.${index}.partyLastName`}
                  defaultValue={field.partyLastName}
                  onBlur={updatePartySeller}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <SelectField
                  label="Suffix"
                  name={`${formPrefix}.${index}.suffixType`}
                  options={[{ text: "Select", value: "" }, ...suffixes]}
                  onChange={(
                    _: React.ChangeEvent<{}>,
                    value: SelectFieldOption
                  ) => handleSuffixChange(value, index)}
                  defaultValue={field.suffixType}
                  allowsDelete={true}
                  dataTextField="name"
                  dataValueField="code"
                  onBlur={updatePartySeller}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={8} lg={9}>
                <TextInputField
                  label="Name"
                  name={`${formPrefix}.${index}.companyName`}
                  defaultValue={field.companyName}
                  onBlur={updatePartySeller}
                />
              </Grid>
            </>
          )}
          <FullAddress
            schema={`${formPrefix}.${index}`}
            isPropertySection={false}
            showPropertyCheckbox={true}
            showForeignAddressCheckbox={true}
            onBlur={updatePartySeller}
            verifyAddressHandler={(value: string) => {
              updateZipCodeSeller(index, value);
            }}
          />

          {isForeignAddress === 1 && (
            <ForeignAddress
              schema={`${formPrefix}.${index}`}
              onBlur={updatePartySeller}
            />
          )}
          <input
            type="hidden"
            {...register(`${integrationKeyField}` as const)}
          />
          <input type="hidden" {...register(`${filePartyKeyField}` as const)} />
          <input
            type="hidden"
            {...register(`${partyRoleTypeCodeField}` as const)}
          />
        </Grid>
      </CollapsableFormSection>
    </Box>
  );
};

export default SellerData;
