import React from "react";
import ClearButton from "controls/global/clear-btn/ClearButton";
import { useAdditionalPartySearchAction } from "utils/context/AdditionalPartySearchContext";
import { usePartyAdditionalPartyActions } from "utils/context/PartyAdditionalPartyContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  schema: string;
};

export default function ClearAdditionalParty({ schema }: Props) {
  const { setValue, getValues } = useFormWrapper();
  const [, { reset }] = useAdditionalPartySearchAction();
  const [, {setAdditionalParties}] = usePartyAdditionalPartyActions();

  const handleClick = () => {
    reset();
    
    setValue(`${schema}.addressOne`, "");
    setValue(`${schema}.addressTwo`, "");
    setValue(`${schema}.city`, "");
    setValue(`${schema}.state`, { code: "", abbr: "" });
    setValue(`${schema}.zipCode`, "");
    setValue(`${schema}.companyName`, "");
    setValue(`${schema}.individualAttorney`, "");
    setValue(`${schema}.favorite`, false);
    setValue(`${schema}.selectedAdditionalPartyID`, "");
    setValue(`${schema}.isDirty`, true);
    setValue(`${schema}.filePartyId`, 0);
    setValue(`${schema}.altReferenceID`, "");
    setValue(`${schema}.locationID`, "");
    
    setAdditionalParties(getValues("additionalParties"));
  };

  return <ClearButton onClick={handleClick} />;
}
