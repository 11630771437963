import {
  Typography,
} from "@material-ui/core";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ProgressbarDialog from "controls/global/dialogs/confirmation-dialog-with-progressbar/ProgressbarDialog";
import StewartDialog from "controls/global/dialogs/stewart-dialog/StewartDialog";
import PageSection from "controls/global/page-section/PageSection";
import PdfViewer from "controls/global/pdf-viewer/PdfViewer";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { SelectionConfig } from "controls/global/stewart-table/StewartTable";
import { FileSearchCriteria } from "entities/ApiModel";
import { IColumn } from "entities/ApiModel/IColumn";
import { PaymentsFileExportCriteria } from "entities/ApiModel/PaymentsFileExportCriteria";
import { PdfDocument } from "entities/UIModel";
import { FilePaymentSearch } from "entities/UIModel/FilePaymentSearch";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import { ReportAndPayKeywordSearch } from "entities/UIModel/ReportAndPayKeywordSearch";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import {
  orderBy as _orderBy,
  debounce,
} from "lodash";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useLookup } from "utils/context/LookupContext";
import { usePayment } from "utils/context/PaymentContext";
import { useReportPay } from "utils/context/ReportPayContext";
import { useUser } from "utils/context/UserContext";
import useDidMountEffect from "utils/custom-hooks/useDidMountEffect";
import useTitle from "utils/custom-hooks/useTitle";
import {
  DateTypeCode,
  FileStatusType,
  PaymentsFileExportCriteriaType,
} from "utils/data/enum";
import { Order } from "utils/sorting";
import { v4 as uuidv4 } from "uuid";
import FileExportDialog from "./components/file-export-dialog";
import ReportPayGrid from "./components/reportpay-grid/ReportPayGrid";
import ReportPayPageHeader from "./components/reportpay-header/ReportPayPageHeader";

const SUB_HEADING_TEXT = `The files listed below contain at least one jacket and are ready to
be reported to Stewart. Once reported, the file will close and
products cannot be revised or voided (with the exception of adding
additional endorsements).`;

const DefaultPage = 1;
const TotalRows = 100;
const NoRecordsMessage = "Loading...";
const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
const DefaultStatuses = [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending];
const defaultSortColumn = "-firstJacketIssueDate";
const defaultOrderBy = "firstJacketIssueDate";
const defaultOrder = "desc";

const ReportPayPage = () => {
  useTitle("Stewart Connect - Report & Pay");

  const [
    {
      error: requestError,
      progressPercent: loadingProgressPercent,
      refreshData,
      onlyShowSelectedFiles,
      isAdvSearchOpen,
      selectedItems,
    },
    {
      getReportPayList,
      getReportPayListLocal,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
      getAdvancedSearchFiles,
      cancelRequests,
      setRequestTimeoutMs,
      updateSelectedItem,
      updateSelectedItems,
      setOnlyShowSelectedFiles,
      setIsAdvSearchOpen,
      restoreSelectedItems,
    }
  ] = useReportPay();

  const defaultReportAndPayCriteriaSearch: ReportAndPayCriteriaSearch = {
    listCount: TotalRows,
    sortColumn: defaultSortColumn,
  };
  const [, { exportFiles }] = usePayment();
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(DefaultPage);
  const [reportPayList, setReportPayList] = useState<ReportPayFile[]>([]);
  const [filters, setFilters] = useState<ReportAndPayCriteriaSearch>(defaultReportAndPayCriteriaSearch);
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([NoRecordsMessage]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [hiddenColumns, setHiddenColumns] = useState<(keyof ReportPayFile)[]>(getInitialColumnsDefintion(true));
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof ReportPayFile | undefined>(defaultOrderBy);
  const [statuses, setStatuses] = useState<FileStatusType[]>(DefaultStatuses);
  const { generalConfig } = useConfigContext();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [exportedColumns, setExportedColumns] = useState<string[]>([]);

  const [,
    {
      getRowsPerPageSetting,
      setRowsPerPageSetting,
    }
  ] = useUser();
  const [, { getReportOptionTypes }] = useLookup();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const globalAccessAgencyID = globalAccessAgency?.[0]?.CompanyID;
  const globalAccessAgencyName = globalAccessAgency?.[0]?.CompanyName;

  const records = reportPayList ?? [];
  const AdvancedStatuses = [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending];

  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }

    if (timeoutMs) {
      setRequestTimeoutMs(timeoutMs);
    }
  };

  const getDefaultFilters = (advanced: boolean): ReportAndPayCriteriaSearch => {
    const dateType = advanced ? DateTypeCode.IssueDate : undefined;

    return {
      dateTypeCode: dateType,
      listCount: TotalRows,
      requestedPage: 1,
      sortColumn: defaultSortColumn,
    };
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs)
    , [userInputDebounceTimeMs]
  );

  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileSearchId: "FileSearchID",
      fileId: "ClientFileID",
      clientFileId: "ClientFileID",
      agencyId: "AgencyID",
      fileStatusTypeCode: "FileStatusTypeCode",
      transactionTypeCode: "TransactionTypeCode",
      propertyTypeCode: "PropertyTypeCode",
      propertyAddress: "PropertyAddress",
      paymentOrderNumber: "FileID",
      stateAbbr: "StateAbbr",
      openDate: "OpenDate",
      closingDate: "ClosingDate",
      productSerialNumbers: "ProductSerialNumbers",
      locationDisplayName: "LocationDisplayName",
      productNames: "ProductNames",
      user: "User",
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
    };
    return mappedColumnNames[name] ?? name;
  };

  const getDatabaseSearchColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileNumber: "clientFileID",
      productNames: "productName",
      openedTimestamp: "dateRangeStart",
      agentRetention: "totalActualRetention",
    };
    return mappedColumnNames[name] ?? name;
  };

  const handleRequestSort = async (columnName: string, disableToggling: boolean = false) => {
    await cancelRequests();

    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof ReportPayFile);
    setSortColumn(sortBy);
  };

  const handleColumnResize = (column: keyof ReportPayFile, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", newWidth);
    }
  };

  const canSearch = (): boolean => {
    return settingsLoaded &&
      !partialFilterUpdate &&
      columns?.length > 0 &&
      (filters !== undefined && Object.values(filters).length > 0);
  };

  const handleAdvancedFilterChange = (
    name: keyof ReportAndPayCriteriaSearch,
    value: any,
    shouldSearch: boolean
  ) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();

    const resetFilters = () => {
      setOrderBy(defaultOrderBy);
      setOrder(defaultOrder);
      return getDefaultFilters(open);
    };

    setFiltersApplied(false);
    setFilters(resetFilters);
    setSortColumn(undefined);
    setPartialFilterUpdate(false);
    setPage(1);
    setIsAdvSearchOpen(open);

    if (open) {
      setStatuses(AdvancedStatuses);
    }
    else {
      setStatuses(DefaultStatuses);
    }
  };

  const getSearchCriteria = () => {
    let criteria: ReportAndPayCriteriaSearch = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      ...(!filters.agencyID && globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }

    for (const key in criteria) {
      const criteriaKey = key as keyof ReportAndPayCriteriaSearch;
      if (criteria[criteriaKey]) {
        criteria[criteriaKey] = convertValue(criteriaKey, criteria[criteriaKey]);
      }
    }

    return criteria;
  };

  const getAdvancedSearchCriteria = () => {
    let criteria: ReportAndPayKeywordSearch = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }
    return criteria;
  };

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof ReportPayFile,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof ReportPayFile),
    }));

  const removeInvalidProperties = (object: { [key: string]: any; }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {
      if (object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen &&
          hiddenColumns.find(c => c === key || getDatabaseSearchColumnName(c) === key))) {
        delete object[key];
        deleteCount++;

      }
    });

    return { object, deleteCount };
  };

  const convertValue = (name: string, value: any) => {
    const fields = ["totalAmountDue", "totalActualFee", "totalActualRiskRate", "totalActualRetention"];
    if (value !== null && fields.includes(name)) {
      return Number(value);
    }
    return value;
  };

  const handleFilterChange = async (
    name: keyof ReportAndPayCriteriaSearch,
    value: any
  ) => {
    await cancelRequests();
    const formattedValue = (typeof value) === "string" ? value.replaceAll(",", "|") : value;
    const updatedFilters = (filters: ReportAndPayCriteriaSearch | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      }
      else if (!isAdvSearchOpen && name === "dateRangeStart") {
        handleRequestSort("openedTimestamp", true);
        dateType = DateTypeCode.Created;
      }
      else {
        dateType = filters?.dateTypeCode;
      }

      const nameKey = getDatabaseSearchColumnName(name);
      let adjustedFilters: ReportAndPayCriteriaSearch = {
        ...filters,
        [nameKey]: formattedValue,
        dateTypeCode: dateType,
      };
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setPage(DefaultPage);
    setFiltersApplied(true);
    setFilters(updatedFilters);
  };

  const reloadLocalSearch = () => {
    if (onlyShowSelectedFiles) {
      setPage(DefaultPage);
      performSearch();
    }
  };

  const handleToggleAllRows = async () => {
    if (reportPayList) {
      updateSelectedItems(reportPayList);
      reloadLocalSearch();
    }
  };

  const handleToggleRow = (file: ReportPayFile) => {
    updateSelectedItem(file);
    reloadLocalSearch();
  };

  const selectionConfig: SelectionConfig<ReportPayFile> = {
    enabled: true,
    selectedRows: selectedItems?.map(s => s.fileId.toString()) ?? [],
    onToggleAllRows: handleToggleAllRows,
    onToggleRow: handleToggleRow,
    isRowSelected: (file: ReportPayFile) => selectedItems?.some(f => f.fileId == file.fileId),
  };

  const handleOnlySelectedFilesChange = (value: boolean) => {
    setOnlyShowSelectedFiles(value);
  };

  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    if (isAdvSearchOpen) {
      const keyword = (filters as ReportAndPayKeywordSearch).keyword;
      let searchFields: string[] = [];
      keyword && searchFields.push(keyword);
      globalAccessAgencyName && searchFields.push(globalAccessAgencyName);
      if (searchFields) {
        firstMessage = `No matches found for "${searchFields.join(", ")}"`;
      }
    }
    else {
      const keys: (keyof ReportAndPayCriteriaSearch)[] = [
        "clientFileId",
        "propertyAddress",
        "buyerName",
        "agencyID",
        "locationDisplayName",
        "locationLegacyID",
        "agencyName",
        "sellerName",
        "lenderName",
        "productName",
        "stateAbbr",
        "propertyTypeCode",
        "transactionTypeCode",
        "totalActualRiskRate",
        "totalActualFee",
        "totalActualRetention",
        "totalAmountDue",
      ];
      const providedFields = keys
        .map(key => filters[key])
        .filter(value => !!value && value !== globalAccessAgencyID);

      if (!filters.agencyID && globalAccessAgencyName && !providedFields.includes(globalAccessAgencyName)) {
        providedFields.push(globalAccessAgencyName);
      }

      if (providedFields.length) {
        firstMessage = `No matches found for "${providedFields.join(", ")}"`;
      }
    }
    return [firstMessage, secondMessage];
  };

  const performSearch = async () => {
    let files: ReportPayFile[] | undefined = [];

    if (isAdvSearchOpen) {
      const criteria = getAdvancedSearchCriteria();
      files = await getAdvancedSearchFiles({ ...criteria });
    } else {
      const criteria = getSearchCriteria();
      if (onlyShowSelectedFiles) {
        files = await getReportPayListLocal({ ...criteria });
      } else {
        files = await getReportPayList({ ...criteria });
      }
    }

    if (files !== undefined) {
      setReportPayList(files);
      let currentPage = 0;

      if (files.length > 0) {
        currentPage = files[0].currentPage;
      } else {
        setNoRecordsMessage(getNoRecordsMessage());
        currentPage = 1;
      }

      if (currentPage !== page) {
        setPage(currentPage);
      }
    }
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();
    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(DefaultPage);
  };

  const handleShowPaymentSheetPdf = (pdfUrl: string) => {
    let pdfs: PdfDocument[] = [];
    if (pdfUrl) {
      pdfs.push({
        fileId: 0,
        productType: "",
        orderId: 0,
        documentId: 0,
        pdfUrl: pdfUrl,
        fileName: pdfUrl.split("?")[0].split("/")?.pop() ?? "",
        showHidden: 0,
      });
    }
    setPdfDocuments(pdfs);
    setOpenPdfViewer(pdfs.length > 0);
  };

  const handleColumnsModified = (columns: IColumn[]) => {
    const exportedCols = columns.filter(c => !hiddenColumns.includes(c.field as keyof ReportPayFile))
      .map(c => c.field);
    setExportedColumns(exportedCols);
  };

  const handleUpdateHiddenColumns = (columns: (keyof ReportPayFile)[]) => {
    setHiddenColumns(columns);
  };

  const handleExport = async (allFiles: boolean, itemizeProducts: boolean) => {
    const requestId = uuidv4();

    let searchCriteria: FilePaymentSearch = {};
    if (allFiles) {
      searchCriteria = isAdvSearchOpen ? getAdvancedSearchCriteria() : getSearchCriteria();
      searchCriteria = {
        ...searchCriteria,
        criteriaType: isAdvSearchOpen
          ? PaymentsFileExportCriteriaType.ReportAndPayKeyword
          : PaymentsFileExportCriteriaType.ReportAndPayCriteria,
        ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      };
    }
    else {
      searchCriteria.criteriaType = PaymentsFileExportCriteriaType.ReportAndPayCriteria;
    }

    const exportCriteria: PaymentsFileExportCriteria = {
      FileIds: allFiles
        ? []
        : selectedItems.map(s => s.fileId),
      ExportColumns: [
        "clientFileId",
        ...exportedColumns.filter(c => c !== "clientFileId" && c !== "totalAmountDue"),
        "totalAmountDue",
      ],
      ItemizeEachProduct: itemizeProducts,
      SearchCriteria: searchCriteria,
    };

    await exportFiles(exportCriteria, requestId);

    setOpenExportDialog(false);
  };

  useEffect(() => {
    const exportedCols = _orderBy(columns.filter(c => !hiddenColumns.includes(c.field as keyof ReportPayFile)), "position")
      .map(c => c.field);

    setExportedColumns(exportedCols);
  }, [columns]);

  useEffect(() => {
    setPage(DefaultPage);
  }, [onlyShowSelectedFiles]);

  useEffect(() => {
    if (requestError === "TIMEOUT") {
      setNoRecordsMessage([TimeoutMessage]);
      setReportPayList([]);
    }
  }, [requestError]);

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      await getReportOptionTypes();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || reportPayList === undefined) {
      setFilters((filters: FileSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };

    getColumns();
    return () => {
      restoreSelectedItems();
    };
  }, []);

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    columns,
    filters,
    filtersApplied,
    page,
    partialFilterUpdate,
    refreshData,
    rowsPerPage,
    settingsLoaded,
    sortColumn,
  ]);

  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    handleColumnsModified(columns);
  }, [hiddenColumns]);

  useDidMountEffect(() => {
    performSearch();
  }, [onlyShowSelectedFiles]);

  return (
    <>
      <PageSection
        icon={
          <MonetizationOnIcon />
        }
        title="Report & Pay"
        isError={false}
        contentAlignment="beside-header"
      >
        <>
          <ReportPayPageHeader
            columns={visibleColumns}
            hiddenColumns={hiddenColumns}
            updateHiddenColumns={handleUpdateHiddenColumns}
            handleAdvancedFilterChange={handleAdvancedFilterChange}
            handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
            setOpenExportDialog={setOpenExportDialog}
          />
          <Typography className="subheading">
            {SUB_HEADING_TEXT}
          </Typography>
          <ReportPayGrid
            colsConfig={columns}
            rows={records}
            loadingProgressPercent={loadingProgressPercent}
            noRowsMessage={noRecordsMessage}
            page={page}
            hiddenColumns={hiddenColumns}
            fileStatusTypes={statuses}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            currentFilter={filters}
            selectionConfig={selectionConfig}
            onFiltersChange={handleFilterChange}
            onOnlySelectedFilesChange={handleOnlySelectedFilesChange}
            onlyShowSelectedFiles={onlyShowSelectedFiles}
            onRowsPerPageChange={handleRowsPerPageChange}
            onShowPdf={handleShowPaymentSheetPdf}
            showActionRow={!isAdvSearchOpen}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onColumnResize={handleColumnResize}
            onColumnsModified={handleColumnsModified}
          />
          <FileExportDialog
            open={openExportDialog}
            onCancel={() => setOpenExportDialog(false)}
            onExport={handleExport}
            showSelectionPrompt={selectedItems?.some(s => s)}
            selectionPrompt="Would you like to include the selected file(s) only or all reportable files?"
            showItemizedPrompt={true}
            itemizedPrompt="Would you like this export to display itemized products?"
          />
        </>
      </PageSection >
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfDocuments={pdfDocuments}
      />
      <StewartDialog />
      <ProgressbarDialog />
      <ScrollToTopArrow refreshSize={records} />
    </>
  );
};

export default ReportPayPage;
