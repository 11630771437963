import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SCFileResponse } from "entities/UIModel/SCFileResponse";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
// import { getURIEncodedString } from "utils/shared";

type StoreApi = StoreActionApi<State>;
interface State {
  isFileAutocompleteLoading: boolean;
  error: string;
}

const getFiles = (getFilesResponse: any[]) => {
  if (!getFilesResponse || getFilesResponse.length <= 0) return [];

  const files: SCFileResponse[] = getFilesResponse.map((file) => ({
    agencyId: file.AgencyID,
    agencyName: file.AgencyName,
    clientFileId: file.ClientFileID,
    fileId: file.FileID,
    locationName: file.LocationName,
  }));

  return files;
};

const Store = createStore<State, any>({
  initialState: {
    isFileAutocompleteLoading: false,
    error: "",
  },
  actions: {
    getFiles:
      (prefix: string, companyId: string) =>
      async ({ setState }: StoreApi) => {
        try {
          if (prefix === "" || prefix.length < 3) return [];

          setState({ isFileAutocompleteLoading: true, error: "" });

          const { data } = await axiosSecuredInstance.get(
            `/files/GetFiles?clientFileId=${encodeURIComponent(prefix)}&companyId=${companyId}`
          );
          return getFiles(data);
        } catch (error:any) {
          setState({ error });
          return;
        }
      },
  },
  name: "fileAutocomplete",
});

const hook = createHook(Store);
export const useFileAutocomplete = () => { return hook() };
