import {
  Checkbox,
  CheckboxProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {
  borderRadius,
  colors,
} from "theme/defaultStyle";

const useStyles = makeStyles(() => ({
  root: {
    color: colors.blue01,
    '&.Mui-checked': {
      color: colors.blue01,
    },
    '&.Mui-disabled': {
      color: colors.blue09,
    },
    "&:hover, &.Mui-checked:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  },
  icon: {
    borderRadius: borderRadius.xsmall1,
    color: colors.blue01,
    fill: colors.blue01,
    fontSize: "1.25rem",
    height: "1em",
    width: "1em",
    "input:disabled ~ &": {
      fill: colors.blue09,
    },
  },
  checkedIcon: {
    color: colors.blue01,
  },
}));

const StewartCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();

  const uncheckedSvg = <svg
    className={classes.icon}
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxOutlineBlankIcon"
  >
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
  </svg>

  const checkedSvg = <svg
    className={clsx(classes.icon, classes.checkedIcon)}
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CheckBoxIcon"
  >
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
  </svg>;

  return (
    <Checkbox
      className={classes.root}
      checkedIcon={checkedSvg}
      icon={uncheckedSvg}
      {...props}
    />
  );
};

export default StewartCheckbox;
