import React, { useEffect } from "react";
import {
  Container,
  styled,
  Box
} from "@material-ui/core";
import FileHistory from "./FileHistory";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import FormSection from "controls/global/form-section";
import {
  useSideNavProvider,
  ActionType
} from "pages/file/SideNavProvider";
import {
  margin,
  padding,
  borderSize,
  gradients,
  colors,
  fontSize,
} from "theme/defaultStyle";

const StyledContainer = styled(Container)({
  border: borderSize.zero,
  margin: margin.zero,
  maxWidth: "100% !important",
  maxHeight: 630,
  overflowY: "auto",
  padding: padding.zero,
  "& .MuiContainer-root": {
    margin: margin.zero,
    padding: padding.zero,
  },
  "& .MuiBox-root": {
    margin: margin.zero,
    padding: padding.zero,
  },
});

const StyledIcon = styled(HistoryRoundedIcon)({
  color: colors.white,
  fontSize: `${fontSize.xlarge0} !important`,
});

const StyledWrapper = styled(Box)({
  borderRadius: "50%",
  background: gradients.maroonGradient01,
  display: "flex",
  justifyContent: "center",
  padding: padding.xsmall,
  paddingLeft: padding.zero,
});

const FileInfo = () => {
  const { getValues } = useFormWrapper();
  const activities = getValues("fileActivities");
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const fileHistoryRef = React.useRef<HTMLElement>();

  const shouldShowSection = activities.length;

  const fileHistory = (
    <StyledContainer key="fileActivities">
      <FileHistory records={activities} />
    </StyledContainer>
  );

  const WrapperHistoryRoundedIcon = () => {
    return (
      <StyledWrapper>
        <StyledIcon />
      </StyledWrapper>
    );
  };

  useEffect(() => {
    if (shouldShowSection) {
      if (sideNavState.items.get("File History")?.childRef?.current) return;
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "File History", childRef: fileHistoryRef },
      });
    } else {
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "File History", childRef: undefined },
      });
    }
  }, [shouldShowSection, sideNavState.items]);

  if (!shouldShowSection) return null;

  return (
    <FormSection
      navItemRef={fileHistoryRef}
      data-test="fileSection"
      icon={<WrapperHistoryRoundedIcon />}
      title="File History"
    >
      {fileHistory}
    </FormSection>
  );
};

export default FileInfo;
