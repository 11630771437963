import { TextField, withStyles } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import useHover from "utils/custom-hooks/useHover";
import LightTooltip from "../light-tooltip";
import { padding } from "theme/defaultStyle";

interface NumberFormatCustomProps {
  inputRef: any;
  onChange: any;
  name: string;
  decimalScale?: number;
  prefix?: string;
  className?: string;
  rest: any;
}

const NumberFormatCustom = ({
  inputRef,
  onChange,
  name,
  decimalScale,
  prefix,
  className,
  ...rest
}: NumberFormatCustomProps) => {
  return (
    <NumberFormat
      {...rest}
      className={className}
      data-testid="number-input"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      isNumericString
      name={name}
      prefix={prefix}
    />
  );
};

interface IProps {
  label?: string;
  name: any;
  value?: any;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  className?: any;
  hasError?: boolean;
  placeholder?: string;
  prefix?: string;
  style?: any;
  rest?: any;
  fullWidth?: boolean;
  size?: "small" | "medium";
}

const CssTextField = withStyles((theme) => ({
  root: {
    "& input.MuiInputBase-input":
    theme.custom.textField.inputOutlined,
    "& input.MuiInputBase-input.Mui-disabled":
      theme.custom.textField.inputOutlinedDisabled,
    "& .Mui-error .arrow": theme.custom.textField.arrowError,
  },
}))(TextField);

const NumberInput = ({
  name,
  onChange = () => {},
  onBlur = () => {},
  hasError = false,
  placeholder,
  prefix,
  className,
  style,
  rest,
  label,
  value,
  disabled,
  fullWidth = true,
  size = "medium",
}: IProps) => {
  const {
    htmlElement: { open, text },
    evalHover,
    elRef,
  } = useHover();

  return (
    <LightTooltip title={text} open={open}>
      <CssTextField
        {...rest}
        label={label}
        value={value}
        disabled={disabled}
        onMouseEnter={() => evalHover(true)}
        onMouseLeave={() => evalHover(false)}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth={fullWidth}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          inputComponent: NumberFormatCustom,
          id: name,
        }}
        inputRef={elRef}
        inputProps={{
          prefix: prefix,
          "aria-errormessage": hasError ? "rhfvalidationerror" : "",
          className: className,
          style: {
            ...style,
            paddingRight: padding.small2,
          },
        }}
        size={size}
        variant="outlined"
        error={hasError}
      />
    </LightTooltip>
  );
};

export default NumberInput;
