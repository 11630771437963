import {
  Box,
  Typography
} from "@material-ui/core";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import React from "react";

const Text = styled(Typography)({
  color: colors.black,
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
});

const useStyles = makeStyles({
  boxRoot: {
    textAlign: "center",
  },
  spacer: {
    paddingBottom: padding.small2,
  },
});
type Props = {
  mainText: string;
  helperText?: string;
};

export default function ReportDialogMessage({ mainText, helperText }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.boxRoot}>
      {mainText &&
          <Text
              className={classes.spacer}
              gutterBottom
              dangerouslySetInnerHTML={{ __html: mainText }}
          />
      }
      {helperText &&
          <Text
              dangerouslySetInnerHTML={{ __html: helperText }}
          />
      }
    </Box>
  );
}
