import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import PayByCheckButton from "controls/global/pay-by-check/PayByCheckButton";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import React from "react";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { usePendingPay } from "utils/context/PendingPayContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import { ReportAndPayAction } from "utils/data/enum";
import { formatCurrency } from "utils/shared";
import { v4 as uuidv4 } from "uuid";

type Props = {
  selectedItems: PendingPayFile[];
  disabled?: boolean;
  onShowPdf: (pdfUrl: string) => void;
  hidden?: boolean;
};

export default function PayByCheck({ selectedItems, disabled, onShowPdf, hidden }: Props) {
  const [, { openDialog }] = useDialog();
  const [, { setRefresh, clearSelectedItems }] = usePendingPay();
  const [, { reportPricing, }] = usePayment();
  const [, { openProgressbar }] = useProgressbar();

  const handleSubmitModal = async () => {
    const ids = selectedItems.map((f) => f.fileId);
    const requestId = uuidv4();

    openProgressbar({
      title: "Please wait while processing Pay by Check...",
      requestId,
      onCloseAction: () => {
        setRefresh();
        clearSelectedItems();
      },
    });
    const { pdfUrl } = await reportPricing(ids, requestId, ReportAndPayAction.PayByCheck);
    if (pdfUrl) {
      onShowPdf(pdfUrl);
    }
  };

  const getText = () => {
    const total = selectedItems.reduce((accumulator, current) => {
      return accumulator + (current.totalBilledAmountDue ?? 0);
    }, 0);

    const prefix = selectedItems.length > 1 ? "these" : "this";

    return `By clicking <strong>Submit</strong>, a payment sheet for ${prefix} ${selectedItems.length} file(s) in the amount of ${formatCurrency(total)} will be generated.`;
  };

  const handlePayByCheckClick = () => {
    openDialog({
      title: "Pay By Check",
      content: (
        <ReportContentMessage
          mainText={getText()}
        />
      ),
      onSubmitAction: handleSubmitModal,
    });
  };

  return (
    <PayByCheckButton
      disabled={disabled}
      hidden={hidden}
      onClick={handlePayByCheckClick}
    />
  );
}
