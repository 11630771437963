import CreateFilePage from "pages/file/CreateFilePage";
import HelpCenter from "pages/help-center/HelpCenter";
import HomePage from "pages/home/HomePage";
import ListFilePage from "pages/myFiles/ListFilePage";
import PendingPayPage from "pages/payments/PendingPayPage";
import PolicyRegisterReport from "pages/reports/PolicyRegisterReport";
import PrivateLayout from "./PrivateLayout";
import ProfileSettings from "pages/profile-settings/ProfileSettings";
import ReportPayPage from "pages/payments/ReportPayPage";
import Signatures from "pages/signatures/Signatures";
import StewartPreferred from "pages/stewartresources/components/StewartPreferred";
import StewartPreferredBusinessSolution from "pages/stewartresources/components/StewartPreferredBusinessSolution";
import StewartPreferredDigitalSolution from "pages/stewartresources/components/StewartPreferredDigitalSolution";
import StewartPreferredOfficeSolution from "pages/stewartresources/components/StewartPreferredOfficeSolution";
import StewartResources from "pages/stewartresources/components/StewartResources";
import {
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import { SiteErrorMessage } from "./components/SiteErrorMessage";
import { SiteErrorType } from "utils/data/enum";
import React from "react";
import SelectedHomePage from "./SelectedHomePage";

// import PrivateRoute from "./PrivateRoute";
// import React, { lazy } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";

// type RouteDefinition = {
//   exact: boolean;
//   loader: () => Promise<any>;
//   path: string;
// };

// type Props = {
//   routeDefinitions: RouteDefinition[];
// };

// const getLoadable = ({ loader }: RouteDefinition) => lazy(loader);

// const renderRoute = (
//   RouteComponent: Route | any,
//   routeDefinition: RouteDefinition,
//   index: number
// ) => (
//   <RouteComponent
//     component={getLoadable(routeDefinition)}
//     exact={routeDefinition.exact}
//     key={`menuroute_${index}`}
//     path={routeDefinition.path}
//   />
// );

// const renderRouteFrom = (routeDefinition: RouteDefinition, index: number) =>
//   renderRoute(PrivateRoute, routeDefinition, index);

// const renderRoutes = ({ routeDefinitions }: Props) =>
//   routeDefinitions.map(renderRouteFrom);

// const Router = (props: Props) => (
//   <BrowserRouter>
//     <Routes>{renderRoutes(props)}</Routes>
//   </BrowserRouter>
// );

// export default Router;

const createElement = (element: JSX.Element) => <PrivateLayout>{element}</PrivateLayout>;

const router = createBrowserRouter([
  {
    path: "/",
    element: createElement(<SelectedHomePage />),
  },
  {
    path: "/Home",
    element: createElement(<HomePage />),
  },
  {
    path: "/file",
    element: createElement(
      <CreateFilePage saveFileRef={undefined} saveFileChangeRef={undefined} />,
    ),
  },
  {
    path: "/file/:encodedFileId",
    element: <Navigate replace to="/Home" />,
  },
  {
    path: "/files",
    element: createElement(<ListFilePage />),
  },
  {
    path: "/reportpay",
    element: createElement(<ReportPayPage />),
  },
  {
    path: "/pendingpay",
    element: createElement(<PendingPayPage />),
  },
  {
    path: "/profile-settings",
    element: createElement(
      <ProfileSettings saveFileRef={undefined} saveFileChangeRef={undefined} />,
    ),
  },
  {
    path: "/help-center",
    element: createElement(<HelpCenter />),
  },
  {
    path: "/manage-signatures",
    element: createElement(<Signatures />),
  },
  {
    path: "reports",
    element: createElement(<PolicyRegisterReport />),
  },
  {
    path: "/resources",
    element: createElement(<StewartResources />),
  },
  {
    path: "/stewartpreferred",
    element: createElement(<StewartPreferred />),
  },
  {
    path: "/stewartPreferredDigitalSolution/:item",
    element: createElement(<StewartPreferredDigitalSolution />),
  },
  {
    path: "/stewartPreferredBusinessSolution/:item",
    element: createElement(<StewartPreferredBusinessSolution />),
  },
  {
    path: "/stewartPreferredOfficeSolution/:item",
    element: createElement(<StewartPreferredOfficeSolution />),
  },
  {
    path: "404",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NotFound} />),
  },
  {
    path: "*",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.NotFound} />),
  },
]);

export default router;
