import React from "react";
import { createRoot } from 'react-dom/client';

import { MsalProvider } from "@azure/msal-react";
import {
  loginRequest,
  msalInstance,
  passwordResetRequest,
} from "utils/msal/authConfig";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "configurations/globalConfig";
import "font-awesome/css/font-awesome.min.css";
import "theme/css/footer.css";
import "theme/css/header.css";
import "theme/css/mainmenu.css";
import "theme/css/page.css";
import "./fonts.css";

import App from "./App";

msalInstance().then(async (instance) => {
  const pwrq = await passwordResetRequest;
  const lgRqst = await loginRequest;

  lgRqst.state = document.location.href;


  //#region default login redirect bypass for cypress tests
  const bypassLoginRedirect = window.location.search.endsWith("bypass=1");
  if (bypassLoginRedirect) return;
  //endregion

  const container = document.getElementById("root");
  if (!container) return;
  const root = createRoot(container);

  root.render(
    // <React.StrictMode>
    <MsalProvider {...{ instance }}>
      <App passwordResetRequest={pwrq} loginRequest={lgRqst} />
    </MsalProvider>
    // </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
