import { TextField, withStyles } from "@material-ui/core";

const StewartInput = withStyles((theme) => ({
  root: (props: any) => {
    return {
      "& div.MuiInputBase-root": theme.custom.textField.inputOutlined,
      "& div.MuiInputBase-root.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
      "& input.MuiInputBase-input":
      theme.custom.textField.inputOutlined,
      "& input.MuiInputBase-input.Mui-disabled":
      theme.custom.textField.inputOutlinedDisabled,
      "& div.Mui-error>input": theme.custom.textField.selectError,
      "& label.MuiInputLabel-shrink.Mui-error": theme.custom.textField.colorError,
      "& .Mui-error .arrow": theme.custom.textField.arrowError,
    }
  },
}))(TextField);

export default StewartInput;
