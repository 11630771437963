import {
  Box,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import { PaginationItem } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  gaps,
  iconSize,
  padding,
} from "theme/defaultStyle";

interface Props {
  totalRows: number;
  totalPages?: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
}

const StyledPaginationContainer = styled(Box)({
  alignItems: 'center',
  display: "flex",
  flexFlow: "row nowrap",
  gap: gaps.small1,
});

const EntriesPerPage = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: gaps.small1,
  "& span": {
    width: "119px",
  },
});
const EntriesSelect = styled("div")({
  alignContent: "center",
  border: `${borderSize.xsmall} solid ${colors.grey14}`,
  borderRadius: borderRadius.small,
  display: "flex",
  height: '40px',
  padding: padding.small1,
  "& .MuiInput-root": {
    display: "flex",
    gap: gaps.small1,
    padding: padding.zero,
    "& .MuiSelect-select": {
      fontSize: fontSize.large,
      lineHeight: "150%",
    },
    "& .MuiSelect-icon": {
      color: colors.blue01,
      fontSize: iconSize.medium,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: colors.white,
    },
  },
});

const StyledPagination = styled(Pagination)({
  marginLeft: 'auto',
  "& .MuiPaginationItem-ellipsis": {
    color: colors.blue01,
  },
});

const StyledPaginationItem = styled(PaginationItem)({
  "&.MuiPaginationItem-root": {
    color: colors.blue01,
    fontSize: fontSize.medium,
    height: iconSize.large1,
    '&.Mui-selected': {
      backgroundColor: colors.blue01,
      color: colors.white,
    },
  },
});

const StewartTableFooterPaginator = ({
  totalRows,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  totalPages = 0,
}: Props) => {
  const entriesPerPageOptions = [10, 20, 30, 40, 50];
  const [pageCount, setPageCount] = useState(0);
  const [startEntries, setStartEntries] = useState("");
  const [endEntries, setEndEntries] = useState("");
  const [totalEntries, setTotalEntries] = useState("");
  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) =>
    onPageChange(page);

  useEffect(() => {
    let pages = 1;
    let nStart = 0;
    let nEnd = 0;
    let nTotal = 0;

    if (totalRows > 0 && rowsPerPage > 0) {
      pages = Math.ceil(totalRows / rowsPerPage);
      nTotal = totalRows;

      if (page > 0) {
        nStart = ((page - 1) * rowsPerPage) + 1;
        nEnd = Math.min((page * rowsPerPage), totalRows);
      }
    }

    setStartEntries(nStart.toLocaleString());
    setEndEntries(nEnd.toLocaleString());
    setTotalEntries(nTotal.toLocaleString());
    setPageCount(pages);
  }, [page, totalRows, rowsPerPage])

  return (
    <StyledPaginationContainer>
      <EntriesPerPage>
        <EntriesSelect>
          <Select
            disableUnderline={true}
            onChange={(e) => onRowsPerPageChange(e.target.value as number)}
            value={rowsPerPage}
          >
            {entriesPerPageOptions.map((num) => (
              <MenuItem
                key={num}
                selected={num === rowsPerPage}
                value={num}
              >
                {num}
              </MenuItem>
            ))}
          </Select>
        </EntriesSelect>
        <span>entries per page</span>
      </EntriesPerPage>
      <span>Showing {startEntries} to {endEntries} of {totalEntries} entries</span>
      <StyledPagination
        boundaryCount={2}
        count={pageCount}
        onChange={handlePageChange}
        page={page}
        renderItem={(item) => <StyledPaginationItem {...item} />}
      />
    </StyledPaginationContainer>
  );
};

export default StewartTableFooterPaginator;
