import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  AppBar,
  Box,
  Button,
  Link as MuiLink,
  styled,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Save from "@mui/icons-material/Save";
import clsx from "clsx";
import {
  MenuDefinition,
  menuDefinitions,
} from "pages/home/menu";
import React from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";
import theme from "theme/default";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import StewartLogo from "theme/images/stewart-connect-logo-white.svg";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useFiles } from "utils/context/FilesContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import {
  isCreateFilePage,
  isHomePage,
  isProfileSettingsPage,
  isManageSignaturesPage,
  isHelpCenterPage,  
} from "utils/routes/pathValidator";
import EditProfileLink from "./EditProfileLink";
import MessageBanner from "./MessageBanner";
import PaymentsHeaderDropDown from "./PaymentsHeaderDropDown";
import GlobalAccessAgency from "./GlobalAccessAgency";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

type HeaderProps = {
  isValidLogin: boolean;
  saveFileRef?: any;
  saveFileChangeRef?: any;
};

type NavProps = {
  isValidLogin: boolean;
  saveFileRef?: any;
  saveFileChangeRef?: any;
};

type TopNavBarProps = {
  saveFileRef?: any;
  saveFileChangeRef?: any;
  onNavigate: (url: string) => void;
};

const linkButtonHeightMin = "50px";
const linkButtonHeightMax = "156px";

const SaveIcon = styled(Save)((props) => ({
  [props.theme.breakpoints.up("lg")]: {
    marginRight: margin.xsmall,
  },
}));

const CustomAppBar = styled(AppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "initial",
  zIndex: zIndex.high3,
});

const StyledToolbar = styled(Toolbar)((props) => ({
  alignItems: "center",
  backgroundImage: gradients.blueGradient07,
  borderRadius: `0px 0px ${borderRadius.medium} ${borderRadius.medium}`,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  fontSize: `${fontSize.base} !important`,
  fontWeight: fontWeight.normal1,
  gap: gaps.large1,
  height: "75px",
  justifyContent: "center",
  padding: `${padding.small1} ${padding.xlarge1}`,
  "& > :first-child ": {
    marginRight: "auto",
  },
  "& > :last-child ": {
    marginLeft: "auto",
  },
  [props.theme.breakpoints.between("sm", 800)]: {
    gap: gaps.medium2,
  },
  [props.theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    height: "129px",
  },
}));

const StyledLogo = styled(MuiLink)({
  backgroundImage: `url(${StewartLogo}) !important`,
  backgroundRepeat: "no-repeat",
  flexShrink: 0,
  height: "35px",
  width: "160px",
  "&:hover": {
    cursor: "pointer",
  },
});

const NavItemsContainer = styled(Box)((props) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
  justifyContent: "center",
  padding: padding.zero,
  [props.theme.breakpoints.down("sm")]: {
    flex: "0 0 100%",
    height: linkButtonHeightMin,
    order: 2,
  },
}));

const StyledNavButton = styled(Button)((props) => ({
  alignItems: "center",
  background: gradients.blueGradient03,
  display: "flex",
  flexDirection: "row",
  height: linkButtonHeightMin,
  justifyContent: "center",
  minWidth: linkButtonHeightMin,
  padding: padding.zero,
  width: linkButtonHeightMin,
  "&.disabled": {
    background: gradients.blueGradient02,
    cursor: "default",
    opacity: 1,
  },
  "&:not(.disabled):hover": {
    background: gradients.maroonGradient01,
  },
  "&.active": {
    background: gradients.maroonGradient01,
  },
  [props.theme.breakpoints.up("lg")]: {
    width: linkButtonHeightMax,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& a": {
        borderRadius: borderRadius.small,
        color: colors.white,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal1,
        lineHeight: 1,
        padding: padding.zero,
        textDecoration: "none",
        textTransform: "initial",
        "&.disabledLink": {
          color: colors.whiteTransparent01,
          cursor: "default",
          lineHeight: 1,
          "& .hover-content": {
            display: "none",
          },
          "&:hover": {
            color: colors.whiteTransparent01,
          },
        },
        "&.disabledLink:hover ": {
          color: colors.whiteTransparent01,
          cursor: "default",
        },
        "&.disabledLink:hover .normal-content": {
          display: "none",
        },
        "&.disabledLink:hover .hover-content": {
          display: "flex",
        },
      },
      "& a .icon": {
        color: colors.white,
        fontSize: fontSize.xlarge2,
      },
      "& .disabledLink .icon": {
        color: colors.whiteTransparent01,
        fontSize: fontSize.xlarge2,
      },
      "& a div": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: gaps.xsmall2,
        justifyContent: "center",
        padding: padding.xsmall,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    saveButton: {
      background: "transparent",
      height: fontSize.xlarge2,
      maxWidth: "54px",
      "& a": {
        color: colors.white,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal1,
        lineHeight: 1,
        textDecoration: "none",
        textTransform: "initial",
      },
      "& a .icon": {
        color: colors.white,
        fontSize: fontSize.xlarge2,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
      [theme.breakpoints.down("md")]: {
        width: fontSize.xlarge2,
        minWidth: fontSize.xlarge2,
      },
    },
  }),
);

const ProfileSaveContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small3,
  justifyContent: "end",
});

const StyledDivider = styled("div")({
  backgroundColor: colors.white,
  height: "39px",
  width: "2px",
});

const renderMenuItem = (
  { menuId, menuName, url, disabled, icon, headerIcon }: MenuDefinition,
  onNavigate: Function,
  showButtonLabels: boolean,
  active: boolean,
  classes: any,
) => {
  const Icon = headerIcon ?? icon;
  const className = clsx(classes.root,
    active ? "active" : "",
    disabled ? "disabled" : ""
  );

  return (
    <StyledNavButton
      key={menuId}
      onClick={() => !disabled && onNavigate(url)}
      className={className}
      title={menuName}
    >
      {disabled ? (
        <Link
          onClick={(event) => event.preventDefault()}
          to="/"
          className="disabledLink"
        >
          <Box className="normal-content">
            {Icon && <Icon className="icon" />}
            {showButtonLabels && menuName}
          </Box>
          <Box className="hover-content">
            {Icon && <Icon className="icon" />}
            {showButtonLabels && "Coming Soon"}
          </Box>
        </Link>
      ) : (
        <Link
          to={url}
          onClick={(event) => event.preventDefault()}
          className={active ? "active" : ""}
        >
          <Box>
            {Icon && <Icon className="icon" />}
            {showButtonLabels && menuName}
          </Box>
        </Link>
      )}
    </StyledNavButton>
  );
};

const TopNavBar = ({ onNavigate }: TopNavBarProps) => {
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const classes = useStyles();
  const showButtonLabels = useMediaQuery(theme.breakpoints.up("lg"));

  const filteredItems = menuDefinitions.filter(
    (menu) => menu.isVisibleInHeader,
  );
  const renderedItems = filteredItems.map((item) => {
    if (item.menuName === "Payments") {
      item.disabled = !enableReportPay;
      if (enableReportPay) {
        return <PaymentsHeaderDropDown onNavigate={onNavigate} />;
      }
    }
    const active = window.location.pathname === item.url;
    return renderMenuItem(
      item,
      onNavigate,
      showButtonLabels,
      active,
      classes,
    );
  });
  return <NavItemsContainer>{renderedItems}</NavItemsContainer>;
};

const SaveFileNavIcon = ({ saveFileRef }: any) => {
  const { pathname } = useLocation();
  const [{ isSaving, isIssuedProductDirty, isReadOnly }] = useFiles();
  const disableBtn = isSaving || isIssuedProductDirty || isReadOnly;
  const showButtonLabel = useMediaQuery(theme.breakpoints.up("lg"));

  const StyledButton = styled(Button)({
    background: "transparent",
    height: fontSize.xlarge2,
    maxWidth: "64px",
    minWidth: iconSize.small1,
    padding: padding.zero,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: fontSize.xlarge2,
      width: fontSize.xlarge2,
    },
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "row",
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
      gap: gaps.xsmall2,
      minWidth: iconSize.small1,
      padding: padding.zero,
      "& a": {
        color: colors.white,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal1,
        lineHeight: 1,
        textDecoration: "none",
        textTransform: "initial",
        "& a .icon": {
          color: colors.white,
          fontSize: fontSize.xlarge2,
        },
      },
    },
  });

  const handleClick = () => {
    if (!disableBtn && saveFileRef.current) {
      saveFileRef.current.click();
    }
  };

  if (isReadOnly || (!isCreateFilePage(pathname) && !isProfileSettingsPage(pathname)))
    return null;

  return (
    <StyledButton onClick={handleClick}>
      <Link
        onClick={(event) => event.preventDefault()}
        to="/"
      >
        <SaveIcon className="icon" />
        {showButtonLabel && "Save"}
      </Link>
    </StyledButton>
  );
};

const Nav = ({ isValidLogin, saveFileRef, saveFileChangeRef }: NavProps) => {
  const { redirect } = useNavRedirect();
  const { pathname } = useLocation();  
  const [{ showChangeAgency }, { getSelectedAgency }] = useGlobalAccess();  
  const selectedAgency = getSelectedAgency();

  const handleNavigate = (url: string) => {
    redirect(url, saveFileChangeRef);
  };
  
  const showGAAgency = (!(isManageSignaturesPage(pathname) ||
                        isProfileSettingsPage(pathname) ||
                        isHelpCenterPage(pathname))) &&
                        showChangeAgency;  
  const renderTopNav = !!isValidLogin && !isHomePage(pathname);
  return (
    <CustomAppBar>
      <StyledToolbar>
        <StyledLogo
          aria-label="Stewart Title"
          title="Stewart Title"
          onClick={() => handleNavigate("/Home")}
        />
        <AuthenticatedTemplate>
          {renderTopNav && (
            <TopNavBar
              onNavigate={handleNavigate}
              {...{ saveFileChangeRef }}
            />
          )}
          <ProfileSaveContainer>
            {renderTopNav && (
              <>
                <SaveFileNavIcon {...{ saveFileRef }} />
                <StyledDivider />
              </>
            )}
            <EditProfileLink
              isValidLogin={isValidLogin}
              saveFileChangeRef={saveFileChangeRef}
            />
          </ProfileSaveContainer>
        </AuthenticatedTemplate>
      </StyledToolbar>
      <MessageBanner onNavigate={handleNavigate} />
      {showGAAgency ?
        <GlobalAccessAgency
          selectedAgency={selectedAgency?.length > 0 ? selectedAgency[0].CompanyName : ""}          
          saveFileChangeRef={saveFileChangeRef}
        />:
        null
      }
    </CustomAppBar>
  );
};

const Header = ({
  isValidLogin,
  saveFileRef,
  saveFileChangeRef,
}: HeaderProps) => {
  return <Nav {...{ isValidLogin, saveFileRef, saveFileChangeRef }} />;
};

export default Header;
