import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

interface IProps {
  name: string;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  errorMessage: {
    color: colors.red01,
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal1,
    paddingLeft: padding.medium1,
    position: "absolute",
  },
});

const ErrorMessage = ({ name, className, style }: IProps) => {
  const classes = useStyles();
  const { formState: { errors } } = useFormContext();

  const fieldError = get(errors, name);
  const getErrorMessage = (error: any) => error.message || error.id?.message || error.name?.message;

  if (!fieldError) return <></>;

  const errorMessage = getErrorMessage(fieldError);

  return (
    <Box
      className={className ? className : classes.errorMessage}
      style={style}
      title={errorMessage}
    >
      {errorMessage}
    </Box>
  );
};

export default ErrorMessage;
