import React, { ComponentProps, FC } from "react";
import { ConfigurationProvider } from "./ConfigurationProvider";
import { SignatureProvider } from "./SignatureProvider";
import WizardProvider from "./WizardProvider";

type Props = {
  children?: React.ReactNode
};


// the components will be combined from left to right.
export const combineComponents = (...components: FC<Props>[]): FC<Props> => {
  return components.reduce(
    (AccumulatedComponents: FC<Props>, CurrentComponent: FC<Props>) => {

      // eslint-disable-next-line react/display-name
      return ({ children }: ComponentProps<FC<Props>>) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

const providers = [WizardProvider, SignatureProvider, ConfigurationProvider];

const SignatureSectionProvider = combineComponents(...providers);

export default SignatureSectionProvider;
