import {
  Collapse,
  styled,
} from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import ActionButton from "controls/global/action-button";
import React, {
  useState,
} from "react";
import {
  colors,
  gaps,
  margin,
} from "theme/defaultStyle";
import {
  DateTypeCode,
  PageType,
} from "utils/data/enum";
import DatePickerSearch from "../datepicker-search/DatePickerSearch";
import DateTypeField from "./DateTypeField";
import KeywordSearchField from "./KeywordSearchField";
import OpenCheckbox from "./OpenCheckbox";

type Props<T> = {
  open: boolean;
  handleFilterChange: (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => void;
  page: PageType;
};
const StyledCollapse = styled(Collapse)({
  "&.MuiCollapse-hidden": {
    display: "none",
  },
});

const InnerContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gap: gaps.xsmall3,
  "& > :nth-child(1)": {
    flexGrow: 1,
    width: "unset",
  },
  "& > :nth-child(2)": {
    width: "172px",
  },
  "& > :nth-child(3)": {
    width: "314px",
  },
  "& > :nth-child(4)": {
    width: "unset",
  },
  "& > :nth-child(5)": {
    justifyContent: "flex-end",
  },
});

const DateRangeContainer = styled("div")({
  alignItems: "center",
  display: "flex",
});

const Dash = styled("div")({
  background: colors.grey11,
  height: "3px",
  margin: margin.small1,
  width: "20px",
});

const AdvancedSearch = <T extends object>({ open, handleFilterChange, page }: Props<T>) => {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateType, setDateType] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [dateFromError, setDateFromError] = useState<boolean>(false);
  const [dateToError, setDateToError] = useState<boolean>(false);
  const [dateReset, setDateReset] = useState<number>(0);

  const shouldSearch = (date: Date | null, searchIfNull: boolean = true) =>
    date ? !isNaN(date.getTime()) : searchIfNull;

  const handleFilter = (name: keyof T, date: Date | null) =>
    handleFilterChange(name, date ?? undefined, shouldSearch(date));

  const handleDateFromChange = (newDate: Date | null) => {
    setDateFrom(newDate);
    setDateToError(false);
    handleFilter("dateRangeStart" as keyof T, newDate);
  };

  const handleDateToChange = (newDate: Date | null) => {
    setDateTo(newDate);
    setDateFromError(false);
    handleFilter("dateRangeEnd" as keyof T, newDate);
  };

  const handleKeywordSearchChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    if (execSearch) {
      setDateFromError(false);
      setDateToError(false);
    }
    setKeyword(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleSetDateFromError = (error: boolean) => setDateFromError(error);
  const handleSetDateToError = (error: boolean) => setDateToError(error);

  const handleDateTypeChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    execSearch = shouldSearch(dateTo, false) || shouldSearch(dateFrom, false);
    setDateType(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleCheckboxChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    if (execSearch) {
      setDateFromError(false);
      setDateToError(false);
    }
    setChecked(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleReset = () => {
    setDateFrom(null);
    setDateTo(null);
    setDateReset(dateReset + 1);
    setKeyword("");
    setChecked(false);
    setDateType(
      page === PageType.MyFilesPage
        ? DateTypeCode.Created
        : (page === PageType.ReportPayPage
          ? DateTypeCode.IssueDate
          : DateTypeCode.ReportedDate));
    handleFilterChange("keyword" as keyof T, null, false);
    handleFilterChange("dateRangeStart" as keyof T, null, false);
    handleFilterChange("dateRangeEnd" as keyof T, null, false);
    handleFilterChange("userFilter" as keyof T, null, false);
    handleFilterChange("dateTypeCode" as keyof T, DateTypeCode.Created, true);
  };

  return (
    <StyledCollapse
      in={open}
      data-test="advancedSearchPanel"
    >
      <InnerContainer>
        <KeywordSearchField
          value={keyword}
          handleFilterChange={handleKeywordSearchChange}
        />
        <DateTypeField
          dateType={dateType}
          data-test="dateType"
          variant="outlined"
          handleDateTypeChange={handleDateTypeChange}
          page={page}
        />
        <DateRangeContainer>
          <DatePickerSearch
            data-test="dateFrom"
            value={dateFrom}
            maxDate={dateTo ?? new Date()}
            handleDateChange={handleDateFromChange}
            hasError={dateFromError}
            reset={dateReset}
            onError={handleSetDateFromError}
          />
          <Dash />
          <DatePickerSearch
            data-test="dateTo"
            value={dateTo}
            minDate={dateFrom ?? new Date()}
            handleDateChange={handleDateToChange}
            hasError={dateToError}
            reset={dateReset}
            onError={handleSetDateToError}
          />
        </DateRangeContainer>
        <OpenCheckbox
          {...{ handleCheckboxChange, checked }}
          data-test="openCheckbox"
        />
        <ActionButton
          variant="outlined"
          color="secondary"
          startIcon={<ClearIcon />}
          onClick={handleReset}
        >
          Clear
        </ActionButton>
      </InnerContainer>
    </StyledCollapse>
  );
};

export default AdvancedSearch;
