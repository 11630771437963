/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import LightTooltip from "controls/global/light-tooltip";
import useHover from "utils/custom-hooks/useHover";

type Props = {
  data?: SignatureConfigurationNode | null;
  onDataChanged: (data: SignatureConfigurationNode) => void;
};

interface NodeSelectionState {
  id: string;
  isSelected: boolean;
  isExpanded?: boolean;
  isExpandedByChild: boolean;
  isActuallyExpanded?: boolean;
}

const useStyles = makeStyles({
  root: {
    maxHeight: 650,
  },
  label: {
    color: colors.black,
    marginBottom: 0,
  },
  itemLabel: {
    "&:hover": {
      backgroundColor: colors.white,
    },
  },
});

interface CheckBoxProps {
  node: SignatureConfigurationNode;
  nodeSelectionState: NodeSelectionState;
  onNodeChecked: (node: SignatureConfigurationNode, checked: boolean) => void;
}

const CheckBox = ({
  node,
  nodeSelectionState,
  onNodeChecked,
}: CheckBoxProps) => {
  const classes = useStyles();

  const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const target = event.currentTarget as HTMLElement;
    const dataChecked = target.getAttribute("data-checked") === "true";
    onNodeChecked(node, !dataChecked);
  };

  const customStyles = {
    color: colors.blue01,
    "&.MuiCheckbox-root": {
      color: colors.blue01,
      padding: `0 ${padding.small1}`,
    },
    "&.Mui-disabled": {
      color: colors.blue09,
    },
  };

  const indeterminate =
    !nodeSelectionState.isSelected && nodeSelectionState.isExpandedByChild;

  return (
    <div onClick={handleChange} data-checked={nodeSelectionState.isSelected}>
      {nodeSelectionState.isSelected && (
        <Checkbox style={customStyles} checked={true} />
      )}
      {indeterminate && (
        <Checkbox style={customStyles} indeterminate={true} />
      )}
      {!nodeSelectionState.isSelected && !indeterminate && (
        <Checkbox style={customStyles} checked={false} />
      )}
    </div>
  );
};

interface CustomTreeItemProps {
  node: SignatureConfigurationNode;
  nodeSelectionStates: NodeSelectionState[];
  onToggleNodeCollapsed: (
    node: SignatureConfigurationNode,
    nodeSelectionState: NodeSelectionState
  ) => void;
  onNodeChecked: (node: SignatureConfigurationNode, checked: boolean) => void;
}

const CustomTreeItem = ({
  node,
  nodeSelectionStates,
  onToggleNodeCollapsed,
  onNodeChecked,
}: CustomTreeItemProps) => {
  const classes = useStyles();
  const nodeSelectionState = nodeSelectionStates.find(
    (nss) => nss.id === node.ID
  ) || {
    id: node.ID,
    isSelected: false,
    isExpanded: false,
    isExpandedByChild: false,
    isActuallyExpanded: false,
  };

  const handleOnClick = () => {
    onToggleNodeCollapsed(node, nodeSelectionState);
  };

  const {
    htmlElement: { open, text },
    evalHover,
    elRef,
  } = useHover();

  return (
    <TreeItem
      key={node.ID}
      nodeId={node.ID}
      classes={{ label: classes.itemLabel }}
      onClick={handleOnClick}
      label={
        <FormControlLabel
          classes={{ root: classes.label }}
          control={
            <CheckBox
              onNodeChecked={onNodeChecked}
              node={node}
              nodeSelectionState={nodeSelectionState}
            />
          }
          label={
            <LightTooltip title={text} open={open}>
              <Typography
                ref={elRef}
                onMouseEnter={() => evalHover(true)}
                onMouseLeave={() => evalHover(false)}
                style={{
                  fontSize: fontSize.large,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: 700,
                }}
              >
                {node.Name}
              </Typography>
            </LightTooltip>
          }
        />
      }
    >
      {Array.isArray(node.Children) && node.Children.length > 0
        ? node.Children.map((childNode) => (
            <CustomTreeItem
              key={childNode.ID}
              node={childNode}
              nodeSelectionStates={nodeSelectionStates}
              onToggleNodeCollapsed={onToggleNodeCollapsed}
              onNodeChecked={onNodeChecked}
            />
          ))
        : null}
    </TreeItem>
  );
};

export default function RecursiveTreeView({ data, onDataChanged }: Props) {
  const classes = useStyles();

  const [nodeSelectionStates, setNodeSelectionStates] = React.useState<
    NodeSelectionState[]
  >([]);

  React.useEffect(() => {
    const clearState = () => {
      setNodeSelectionStates([]);
      expanded.current = [];
      if (data) data.Expanded = false;
    };

    document.addEventListener("reset_data", clearState);

    const getNodeSelectionStates = (node: SignatureConfigurationNode) => {
      let retval: NodeSelectionState[] = [
        {
          id: node.ID,
          isSelected: node.Selected,
          isExpandedByChild: false,
          isExpanded: node.Expanded,
          isActuallyExpanded: undefined,
        },
      ];
      if (
        node.Children &&
        Array.isArray(node.Children) &&
        node.Children.length > 0
      ) {
        let childResults: NodeSelectionState[] = [];
        for (let index = 0; index < node.Children.length; index++) {
          const childNode = node.Children[index];
          const thisChildResults = getNodeSelectionStates(childNode);
          childResults.push(...thisChildResults);
        }

        const allChildrenSelected = childResults.every(
          (result) => result.isSelected
        );
        const someChildrenSelected = childResults.some(
          (result) => result.isSelected
        );
        retval[0].isSelected = allChildrenSelected;
        retval[0].isExpandedByChild = someChildrenSelected;
        retval.push(...childResults);
      }

      retval[0].isActuallyExpanded =
        Boolean(retval[0].isExpanded) ||
        (retval[0].isExpanded === undefined && retval[0].isExpandedByChild);

      return retval;
    };
    if (!data) {
      clearState();
      return;
    }
    const nodeSelectionState = getNodeSelectionStates(data);
    const expandedNodes = nodeSelectionState
      .filter((node) => Boolean(node.isActuallyExpanded))
      .map((node) => node.id);
    setNodeSelectionStates(nodeSelectionState);
    expanded.current = expandedNodes;

    return () => {
      document.removeEventListener("reset_data", clearState);
    };
  }, [data]);

  let expanded = React.useRef<string[]>([]);

  const checkChildNodes = (
    node: SignatureConfigurationNode,
    checked: boolean
  ) => {
    node.Children.forEach((childNode) => {
      childNode.Selected = checked;
      checkChildNodes(childNode, checked);
    });
  };

  const onNodeChecked = (
    node: SignatureConfigurationNode,
    checked: boolean
  ) => {
    if (node) {
      node.Selected = checked;
      checkChildNodes(node, checked);
      data && onDataChanged(data);
    }
  };

  const onToggleNodeCollapsed = (
    node: SignatureConfigurationNode,
    nodeSelectionState: NodeSelectionState
  ) => {
    if (node) {
      const isExpanded = !nodeSelectionState.isActuallyExpanded;
      node.Expanded = isExpanded;
      data && onDataChanged(data);
    }
  };

  return data && nodeSelectionStates.length > 0 ? (
    <TreeView
      defaultCollapseIcon={<RemoveIcon />}
      defaultExpandIcon={<AddIcon />}
      className={classes.root}
      expanded={expanded.current}
    >
      {data && (
        <CustomTreeItem
          node={data}
          nodeSelectionStates={nodeSelectionStates}
          onToggleNodeCollapsed={onToggleNodeCollapsed}
          onNodeChecked={onNodeChecked}
        />
      )}
    </TreeView>
  ) : null;
}
