//#region - Imports
import {
  Box,
  Grid,
  makeStyles,
} from "@material-ui/core";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import CurrencyInputField from "controls/global/currency-field";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import { IData } from "controls/global/editable-segment-control/EditableSegmentControl";
import ForeignAddress from "controls/global/foreign-address";
import FullAddress from "controls/global/full-address";
import LinkButton from "controls/global/link-button";
import { MultiSelectFieldOption } from "controls/global/multi-select-field/MultiSelectField";
import PdfViewer from "controls/global/pdf-viewer";
import TextInputField from "controls/global/text-input-field";
import { utcToZonedTime } from "date-fns-tz";
import * as UIModel from "entities/UIModel";
import {
  ActionResult,
  AgencyLocation,
  filePartyDTO,
  GeneralFileParty,
  PdfDocument,
} from "entities/UIModel";
import ProductOption from "entities/UIModel/company/ProductOption";
import { cloneDeep } from "lodash";
import * as ReviseCpl from "pages/file/utils/cpl";
import {
  getCorrelationId,
  isValidParty,
  shouldDefaultAddlParty,
} from "pages/file/utils/helper";
import {
  getDisplayNameForLender,
  getIfIsAttorneySelected,
  getIfIsPrintAttorneyOnly,
  getTabDescription,
} from "pages/file/utils/products/letter";
import { getTimeZoneOffset } from "pages/file/utils/profileSettings";
import { runValidation } from "pages/file/utils/yup/validator";
import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  Controller,
  useWatch,
} from "react-hook-form";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { useSuccessorLanguages } from "utils/context/CompanySuccessorLanguageContext";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import { useDocument } from "utils/context/DocumentContext";
import { useFilePage } from "utils/context/FilePageContext";
import { useInitialValuesFiles } from "utils/context/FilesContext";
import { usePartyAdditionalParties } from "utils/context/PartyAdditionalPartyContext";
import {
  usePartyBuyerBorrowerDefault,
  usePartyBuyerBorrowerNames,
  usePartyBuyerHasValidBuyer,
} from "utils/context/PartyBuyerBorrowerContext";
import {
  usePartyLenderDefault,
  usePartyLenderHasValidLender,
  usePartyLenderNames,
} from "utils/context/PartyLenderContext";
import {
  usePartySellerDefault,
  usePartySellerHasValidSeller,
  usePartySellerNames,
} from "utils/context/PartySellerContext";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useCurrentAddressData from "utils/custom-hooks/useCurrentAddressData";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useProductOption from "utils/custom-hooks/useProductOption";
import {
  OrderStatusType,
  PartyRoleType,
  PricingConfigKey,
  ProductType,
  UIConstants,
} from "utils/data/enum";
import { IAddressComponent } from "utils/interfaces/BaseAddress";
import {
  dateWithoutTime,
  formatDate,
  formatDateTime,
} from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import SuccessorLanguageField from "../../parties-section/SuccessorLanguageField";
import { ReviseAction } from "../jacket/JacketButtonSection";
import AdditionalPartiesField from "./AdditionalPartiesField";
import CoveredPartyField from "./CoveredPartyField";
import EffectiveDateField from "./EffectiveDateField";
import FormField from "./FormField";
import LetterButtonSection from "./LetterButtonSection";
import LetterSectionHeader from "./LetterSectionHeader";
import ProductAgencyLocationField from "./ProductAgencyLocationField";
import ProductStatus from "./ProductStatus";
import {
  padding,
} from "theme/defaultStyle";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";
//#endregion

interface ProductProps
  extends Omit<IAddressComponent, "additionalParties" | "property"> {
  productEntity: string;
  productType: ProductType;
  product: any;
  productIndex: number;
  issueButtonDisabled?: boolean;
  voidButtonDisabled?: boolean;
  submitReviseDisabled?: boolean;
  issueLetter: (integrationKey: string) => void;
  reviseLetter: (integrationKey: string, originalOrderID: number) => any;
  voidLetter?: (defaultVoidReason: string) => void;
  onDelete: (index: number) => void;
  cpls: UIModel.CPL[];
  aALProducts: UIModel.AAL[];
  loadCityList?: boolean;
  onEffectiveDateChange: (date: Date | null) => void;
  agencyLocation?: AgencyLocation;
  fileNameNumber: string;
  hasIssuedProducts: boolean;
  isPopulatingExistingFile: boolean;
}

const useStyles = makeStyles({
  checkboxIcon: {
    marginLeft: "-9px",
  },
});

const LetterProduct = ({
  productEntity,
  productType,
  product,
  productIndex,
  issueButtonDisabled,
  voidButtonDisabled,
  submitReviseDisabled,
  issueLetter,
  reviseLetter,
  voidLetter,
  onDelete,
  fileNameNumber,
  hasIssuedProducts,
  isPopulatingExistingFile,
  cpls,
  aALProducts,
  loadCityList = false,
  onEffectiveDateChange,
}: ProductProps) => {
  //#region - Context values
  //-- Party Context values
  const [buyerBorrowerNames] = usePartyBuyerBorrowerNames(productType);
  const [buyerBorrowerDefault] = usePartyBuyerBorrowerDefault(productType);
  const [buyerBorrowerHasValidBuyer] = usePartyBuyerHasValidBuyer(productType);

  const [sellerNames] = usePartySellerNames();
  const [sellerDefault] = usePartySellerDefault();
  const [sellerHasValidSeller] = usePartySellerHasValidSeller();

  const [lenderNames] = usePartyLenderNames();
  const [lenderDefault] = usePartyLenderDefault();
  const [lenderHasValidLender] = usePartyLenderHasValidLender();

  const [additionalParties] = usePartyAdditionalParties();

  //--Other context values
  const [{ companyStateLocations }] = useCompanyLocation();  

  //#endregion
  
  //#region - Reference
  const companyStateLocationsRef = React.useRef<CompanyStateLocation[]>([]);
  //#endregion

  //#region - useState
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [addlPartySelectOptions, setAddlPartySelectOptions] =
    useState<MultiSelectFieldOption[]>([]);
  const [internalIssueButtonDisabled, setInternalIssueButtonDisabled] =
    useState(true);
  const [isLenderParty, setIsLenderParty] = useState<boolean>(false);
  const shouldUpdateValue = useRef(true);
  const { getUniqueProductOptions, getProductOptionsByProductType } =
    useProductOption();

  const [reviseState, dispatch] = useReducer(
    ReviseCpl.reducer,
    ReviseCpl.initialState
  );

  const [productOptions, setProductOptions] = useState<ProductOption[]>([]);

  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const { isFileLocked } = useCreateFile();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();

  // Error Dialog
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(
    ""
  );
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);

  //#endregion

  //#region - form context
  const [{ initialValues }, { setIssuedProductDirty }] = useInitialValuesFiles();
  const [, { setRevisingMode }] = useFilePage();
  const [{ voidReasons }] = useVoidReasons();
  const { resetValidationRequiredFlags, setValidLocations } = useCreateFile();
  const shouldShowDeleteButtom = useFieldDisabler("AALDeleteButton");
  const disablePrintAttorneyOnly = useFieldDisabler(
    `${productEntity}.${productIndex}.printAttorneyOnly`
  );

  const [{ successorLanguages }] = useSuccessorLanguages();
  const [, { getDocumentUrl }] = useDocument();

  const {
    setValue,
    getValues,
    trigger,
    clearErrors,
    register,
    nameString,
    control,
  } = useFormWrapper();

  const [{ pricingConfigs }] = usePricingConfig();

  const tabTitle = `${productType.toUpperCase()} ${productIndex + 1}`;

  const showLiabilityAmount = pricingConfigs?.find((pc) => pc.configKey === PricingConfigKey.CPLLiability)?.configValue === "1";

  const watchedFields = useWatch({
    name: [
      nameString(`${productEntity}.${productIndex}.coveredParty`),
      nameString(`${productEntity}.${productIndex}.addlParties`),
      nameString(`${productEntity}.${productIndex}.addresseeNames`),
      nameString(`${productEntity}.${productIndex}.isForeignAddress`),
      nameString(`${productEntity}.${productIndex}.form`),
    ],
  });

  const coveredParty: string = watchedFields[0];
  const selectedAddlParties: string[] = watchedFields[1];
  const addresseeNames: string[] = watchedFields[2];
  const isForeignAddress = watchedFields[3];
  const currentForm = watchedFields[4];

  const gridCols = coveredParty.toUpperCase() === PartyRoleType.Seller ? 12 : 6;

  const addresseeNamesDataField = `${productEntity}.${productIndex}.addresseeNamesData`;
  const orderStatusTypeField = `${productEntity}.${productIndex}.orderStatusTypeCode`;

  const [{ profileSettings }] = useProfileSettingsCache();

  const isAddresseeResetButtonDisabled = useFieldDisabler(
    "LetterAddresseeResetButton"
  );
  const isBuyerResetButtonDisabled = useFieldDisabler("LetterBuyerResetButton");
  const isSellerResetButtonDisabled = useFieldDisabler(
    "LetterSellerResetButton"
  );

  //#endregion

  const { checkboxIcon } = useStyles();
  const { getCurrentAddreseeNamesData } = useCurrentAddressData();

  //#region - component's events
  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
  };
  //#endregion

  //#region - local methods and variables

  const getPartyNamesByCoveredParty = (coveredPartyType: string): string => {
    let partyNames: string = "";
    switch (coveredPartyType.toUpperCase()) {
      case PartyRoleType.BuyerBorrower:
        partyNames = buyerBorrowerNames;
        break;
      case PartyRoleType.Seller:
        partyNames = sellerNames;
        break;
      case PartyRoleType.Lender:
        partyNames = lenderNames;
        break;
      default:
        partyNames = "";
    }

    return partyNames;
  };

  const hasAnyValidParties = (coveredPartyType: string) => {
    let validParty = false;
    if (coveredParty) {
      switch (coveredPartyType.toUpperCase()) {
        case PartyRoleType.BuyerBorrower:
          validParty = buyerBorrowerHasValidBuyer;
          break;
        case PartyRoleType.Seller:
          validParty = sellerHasValidSeller;
          break;
        case PartyRoleType.Lender:
          validParty = lenderHasValidLender;
          break;
      }
    }

    return validParty;
  };

  const getPartyDefaultByCoveredParty = (coveredPartyType: string) => {
    let partyDefault: filePartyDTO | undefined;
    switch (coveredPartyType.toUpperCase()) {
      case PartyRoleType.BuyerBorrower:
        partyDefault = buyerBorrowerDefault;
        break;
      case PartyRoleType.Seller:
        partyDefault = sellerDefault;
        break;
      case PartyRoleType.Lender:
        partyDefault = lenderDefault;
        break;
    }

    return partyDefault;
  };


  const getDefaultTime = () => {
    if (profileSettings.timeZones.length > 0) {
      const offset = getTimeZoneOffset(profileSettings);
      return utcToZonedTime(new Date(), offset);
    }
  };

  const buyerBorrowers: GeneralFileParty[] = getValues("buyerBorrowerParties");
  const sellers: GeneralFileParty[] = getValues("sellerParties");
  const lenders: GeneralFileParty[] = getValues("lenderParties");

  const allParties = [...buyerBorrowers, ...sellers, ...lenders];

  const productIssueDateAsString = product?.issueDateTime
    ? formatDate(new Date(String(product?.issueDateTime)))
    : "";
  const issueDateAsStr = product?.issueDateTime
    ? formatDateTime(product?.issueDateTime!, false)
    : "";

  const voidedDate = product?.void?.dateTime
    ? formatDate(new Date(String(product?.void?.dateTime)))
    : undefined;

  const orderStatusTypeCode = product.orderStatusTypeCode;
  const isIssued =
    orderStatusTypeCode &&
      orderStatusTypeCode !== OrderStatusType.Pending &&
      orderStatusTypeCode !== OrderStatusType.Error
      ? true
      : false;

  const getDocumentId = () => {
    const currentProduct = getValues(`${productEntity}.${productIndex}`);
    const latestDocumentId =
      currentProduct.documents && currentProduct.documents.length > 0
        ? Number(currentProduct.documents[0].id)
        : 0;

    return latestDocumentId;
  };

  const getPdfDocument = (pdfUrl: string, latestDocumentId: number) => {
    const pdfFilename = `${fileNameNumber} ${productType} ${product?.coveredParty} ${product?.productReferenceID}`;

    let pdfDocument: PdfDocument = {
      fileId: product.fileID || 0,
      productType: productType,
      orderId: product.orderID || 0,
      documentId: latestDocumentId,
      pdfUrl: pdfUrl,
      fileName: pdfFilename,
      showHidden: 0,
    };

    return pdfDocument;
  };

  const isProductStatusVisible = !!(orderStatusTypeCode && orderStatusTypeCode !== OrderStatusType.Pending);

  const productStatusDisplay =
    orderStatusTypeCode && orderStatusTypeCode === OrderStatusType.Voided
      ? "Voided " + voidedDate + " - " + product?.void?.reason
      : product.orderStatusTypeName;

  const isFieldDisabled = () => isProductStatusVisible && !reviseState.revising;

  const handleOnIssueLetter = async () => {
    setNotDefault();
    issueLetter(product.integrationKey);
  };


  //Currently Cancel Revise does not work.  Need to update how previous state is stored to accomodate.
  const handleOnReviseLetter = async (action: ReviseAction) => {
    const { ACTIONS } = ReviseCpl;
    const currentProduct = getValues(`${productEntity}.${productIndex}`);

    switch (action) {
      case ReviseAction.OpenRevise: {
        const additionalPartyIntegrationKeys = getValues("additionalParties").map((value: any) => value.integrationKey);
        const additionalParty = currentProduct.addlParties;
        ReactDOM.flushSync(() => {
          if (additionalPartyIntegrationKeys?.length > 0 && Array.isArray(additionalParty)) {
            const defaultAdditionalParty = [];
            if ((additionalParty.length > 0 && !additionalPartyIntegrationKeys.includes(additionalParty[0])) || additionalParty.length === 0) {
              defaultAdditionalParty.push(additionalPartyIntegrationKeys[0]);
              setValue(`${productEntity}.${productIndex}.addlParties`, defaultAdditionalParty);
            }
          }
          setRevisingMode(true);
          dispatch({
            type: ACTIONS.START_REVISE,
            payload: cloneDeep(currentProduct),
            productIndex: productIndex,
          });

          setIssuedProductDirty(true);
          setValue(
            `${productEntity}.${productIndex}.effectiveDate`,
            dateWithoutTime(currentProduct.effectiveDate)
          );
          setValue(`${productEntity}.${productIndex}.isRevising`, true);

          const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
          data.hasAddresseeNameChanged = true;
          setValue(addresseeNamesDataField, data);
        });
        return;
      }
      case ReviseAction.SubmitRevise: {
        // clear  previous validaiton errors and reset validation required flags
        clearErrors();
        resetValidationRequiredFlags();

        if (productType === ProductType.Aal) {
          for (const [index, aal] of aALProducts.entries()) {
            if (
              reviseState &&
              reviseState.previousProduct &&
              aal.integrationKey === reviseState.previousProduct.integrationKey
            ) {
              setValue(`aALProducts.${index}.isValidationRequired`, true);
              await onEffectiveDateChange(getValues(`aALProducts.${index}.effectiveDate`));
              if (aal.pricingDetail) {
                setValue(
                  `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
                  true
                );
              }
            } else {
              setValue(`aALProducts.${index}.isValidationRequired`, false);
              if (aal.pricingDetail) {
                setValue(
                  `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
                  false
                );
              }
            }
          }

          cpls &&
            cpls?.forEach((cpl: UIModel.CPL, index: number) => {
              setValue(`cpls.${index}.isValidAddresseeSelected`, true);
              setValue(`cpls.${index}.isValidationRequired`, false);
              if (cpl.pricingDetail) {
                setValue(
                  `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
                  false
                );
              }
            });

          setValidLocations(companyStateLocationsRef.current);
          const values = getValues();
          const isValid = await runValidation({
            values,
            trigger,
            setValue,
            productType: ProductType.Aal,
          });
          if (!isValid) return;
        } else if (productType === ProductType.Cpl) {
          for (const [index, cpl] of cpls.entries()) {
            if (
              reviseState &&
              reviseState.previousProduct &&
              cpl.integrationKey === reviseState.previousProduct.integrationKey
            ) {
              setValue(`cpls.${index}.isValidationRequired`, true);
              await onEffectiveDateChange(getValues(`cpls.${index}.effectiveDate`));
              if (cpl.pricingDetail) {
                setValue(
                  `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
                  true
                );
              }
            } else {
              setValue(`cpls.${index}.isValidationRequired`, false);
              if (cpl.pricingDetail) {
                setValue(
                  `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
                  false
                );
              }
            }
          }

          aALProducts &&
            aALProducts?.forEach((aal: UIModel.AAL, index: number) => {
              setValue(`aALProducts.${index}.isValidAddresseeSelected`, true);
              setValue(`aALProducts.${index}.isValidationRequired`, false);
              if (aal.pricingDetail) {
                setValue(
                  `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
                  false
                );
              }
            });

          setValidLocations(companyStateLocationsRef.current);
          const values = getValues();
          const isValid = await runValidation({
            values,
            trigger,
            setValue,
            productType: ProductType.Cpl,
          });
          if (!isValid) return;
        }

        await reviseLetter(product.integrationKey, product.orderID);
        setValue(`${productEntity}.${productIndex}.isRevising`, false);
        setRevisingMode(false);

        if (submitReviseDisabled) {
          dispatch({ type: ACTIONS.SUBMIT_REVISE, productIndex: productIndex });
        } else {
          setIssuedProductDirty(false);
        }

        return;
      }
      case ReviseAction.CancelRevise: {
        setRevisingMode(false);
        setValue(`${productEntity}.${productIndex}.isRevising`, false);
        clearErrors(`${productEntity}.${productIndex}` as const);
        setValue(
          `${productEntity}.${productIndex}`,
          reviseState.previousProduct
        );
        setValue(`${productEntity}.${productIndex}.state`, {
          code: reviseState.previousProduct.state.code,
          abbr: reviseState.previousProduct.state.abbr,
        });

        const revertedAddresseeNamesData: IData =
          reviseState.previousProduct.addresseeNamesData;

        setValue(
          `${productEntity}.${productIndex}.addresseeNamesData`,
          revertedAddresseeNamesData
        );

        setValue(
          `${productEntity}.${productIndex}.addlParties`,
          reviseState.previousProduct.addlParties
        );

        dispatch({ type: ACTIONS.CANCEL_REVISE, productIndex: productIndex });
        setIssuedProductDirty(false);
        return;
      }
      default:
        break;
    }
  };

  const handleViewLetter = async () => {
    // get pdf url here
    const documentId = getDocumentId();
    if (documentId === 0) {
      openAutomaticProgressDialog(UIConstants.LOADING_IN_PROGRESS_MESSAGE);
    }

    const actionResult: ActionResult = await getDocumentUrl(
      product.fileID || 0,
      productType,
      product.orderID || 0,
      documentId,
      0
    );

    if (documentId === 0) {
      closeAutomaticProgressDialog();
    }

    if (actionResult && (actionResult.error || !actionResult.pdfUrl)) {
      setErrorMessage(UIConstants.VIEW_DOCUMENT_ERROR_MESSAGE);
      setCorrelationId(getCorrelationId(actionResult.error.response?.headers));
      setOpenErrorDialog(true);
      return;
    }

    if (actionResult && actionResult.pdfUrl && actionResult.documentId) {
      const currentProduct = getValues(`${productEntity}.${productIndex}`);
      if (currentProduct.documents && currentProduct.documents.length > 0) {
        const document = currentProduct.documents[0];
        document.id = actionResult.documentId;
        setValue(`${productEntity}.${productIndex}.documents.0`, document);
      } else {
        let documents: UIModel.Document[] = [{ integrationKey: uuidv4() }];
        documents[0].id = actionResult.documentId;
        setValue(`${productEntity}.${productIndex}.documents`, documents);
      }

      setPdfDocuments([
        getPdfDocument(actionResult.pdfUrl, actionResult.documentId),
      ]);
      setOpenPdfViewer(true);
    }
  };

  const handleVoidLetter = () => {
    let defaultVoidReason =
      voidReasons &&
        voidReasons[ProductType.Cpl] &&
        voidReasons[ProductType.Cpl].length > 0
        ? voidReasons[ProductType.Cpl][0].text
        : "";

    if (voidLetter) {
      voidLetter(defaultVoidReason);
    }
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const handleLoanNumberChanged = () => {
    const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    data.hasLoanNumberChanged = true;
    setValue(`${addresseeNamesDataField}` as const, data);
    setNotDefault();

  };

  const handleSuccessorLanguageChangedOnblur = () => {
    const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    data.hasSuccessorLanguageChanged = true;
    shouldUpdateValue.current = false;

  };
  const handleSuccessorLanguageChanged = () => {
    const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    data.hasSuccessorLanguageChanged = true;
    shouldUpdateValue.current = false;
    setValue(`${addresseeNamesDataField}` as const, data);
    setNotDefault();

  };

  const handleAttentionToChanged = () => {
    const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    data.hasAttentionToChanged = true;
    setValue(`${addresseeNamesDataField}` as const, data);
    setNotDefault();

  };

  const handleAddresseeNamesChanged = () => {
    const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    data.hasAddresseeNameChanged = true;
    setValue(`${addresseeNamesDataField}` as const, data);
    setNotDefault();

    const isDisabled = evalAddresseeResetDisabled();
    setAddresseeResetDisabled(isDisabled);
  };

  /**
   * This is deprecated as part of the performance updates related to fullAddress
   * So test thoroughly if things are working correctly here and accomodate any needed
   * changes
   */
  // const handleAddressOnChange = (
  //   changedPartyIndex: number,
  //   fieldName: string,
  //   newValue: any
  // ) => {
  //   const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
  //   //Address is always tied to the first party
  //   const coveredParties = getArrayOfCoveredParties(
  //     coveredParty as PartyRoleType,
  //     productType,
  //     lenderParties,
  //     buyerBorrowerParties,
  //     sellerParties
  //   );
  //   //Determine if the value is actually changed by comparing it to the associated party
  //   let matchingParty = coveredParties[0];
  //   if (matchingParty) {
  //     const partyFieldValue = getIn(matchingParty, fieldName);
  //     //if the new value matches the value on the party,short circuit
  //     //in the event that the address really is different, it wouldn't
  //     //matter because it will already have hasAddressChanged set to true
  //     if (partyFieldValue === newValue) return;
  //   }
  //   data.hasAddressChanged = true;
  //   setValue(`${addresseeNamesDataField}` as const, data);
  //   setNotDefault();
  // };

  const prepareFullAddress = () => {
    const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);

    const addressOneFieldName = `${productEntity}.${productIndex}.addressOne`;
    const addressTwoFieldName = `${productEntity}.${productIndex}.addressTwo`;
    const cityFieldName = `${productEntity}.${productIndex}.city`;
    const stateFieldName = `${productEntity}.${productIndex}.state`;
    const zipCodeFieldName = `${productEntity}.${productIndex}.zipCode`;
    const loanNumberFieldName = `${productEntity}.${productIndex}.loanNumber`;
    const successorFieldName = `${productEntity}.${productIndex}.successorLanguage`;
    const attentionToFieldName = `${productEntity}.${productIndex}.attentionTo`;
    const foreignCountryFieldName = `${productEntity}.${productIndex}.foreignCountry`;
    const foreignStateFieldName = `${productEntity}.${productIndex}.foreignState`;
    const isForeignAddressFieldName = `${productEntity}.${productIndex}.isForeignAddress`;

    const partyOfInterest = getPartyDefaultByCoveredParty(coveredParty);
    if (partyOfInterest) {

      if (!data.hasLoanNumberChanged)
        setValue(`${loanNumberFieldName}` as const, partyOfInterest.loanNumber);

      if (!data.hasSuccessorLanguageChanged && shouldUpdateValue.current)
        setValue(
          `${successorFieldName}` as const,
          partyOfInterest.successorLanguage
        );

      if (!data.hasAttentionToChanged)
        setValue(
          `${attentionToFieldName}` as const,
          partyOfInterest.attentionTo
        );

      if (data.hasAddressChanged) return;

      setValue(`${isForeignAddressFieldName}` as const, partyOfInterest.isForeignAddress);
      setValue(`${addressOneFieldName}` as const, partyOfInterest.addressOne);
      setValue(`${addressTwoFieldName}` as const, partyOfInterest.addressTwo);
      setValue(`${cityFieldName}` as const, partyOfInterest.city);
      setValue(`${foreignCountryFieldName}` as const, partyOfInterest.foreignCountry);
      setValue(`${foreignStateFieldName}` as const, partyOfInterest.foreignState);
      setValue(`${stateFieldName}` as const, partyOfInterest.state);
      setValue(`${zipCodeFieldName}` as const, partyOfInterest.zipCode);

      data.addressPartyId = partyOfInterest.integrationKey; //Setting the addressPartyId
      setValue(`${addresseeNamesDataField}` as const, data);
    } else {
      if (!data.hasAddressChanged) {
        setValue(`${addressOneFieldName}` as const, "");
        setValue(`${addressTwoFieldName}` as const, "");
        setValue(`${cityFieldName}` as const, "");
        setValue(`${stateFieldName}` as const, "");
        setValue(`${zipCodeFieldName}` as const, "");
      }

      if (!data.hasLoanNumberChanged)
        setValue(`${loanNumberFieldName}` as const, "");
      if (!data.hasSuccessorLanguageChanged)
        setValue(`${successorFieldName}` as const, "");
      if (!data.hasAttentionToChanged)
        setValue(`${attentionToFieldName}` as const, "");
    }
  };


  const prepareAddreseeNames = (isEnteringRevise: boolean = false) => {
    const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
    if (data.hasAddresseeNameChanged || isEnteringRevise) return;

    const partyNames: string = getPartyNamesByCoveredParty(coveredParty);
    setValue(
      `${productEntity}.${productIndex}.addresseeNames`,
      partyNames,
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const resetAddresseeNames = () => {
    const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
    data.addressPartyId = "";
    data.hasAddresseeNameChanged = false;
    setValue(`${productEntity}.${productIndex}.addresseeNamesData`, data, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    prepareAddreseeNames();
    const isDisabled = evalAddresseeResetDisabled();
    setAddresseeResetDisabled(isDisabled);
  };

  const resetBuyerBorrower = () => {
    let currentSegmentData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties) as IData;

    currentSegmentData.buyerBorrowerModified = false;
    setValue(addresseeNamesDataField, currentSegmentData);
    prepareBuyerBorrowerAndSeller();
    const isDisabled = evalBuyerResetDisabled();
    setBuyerResetDisabled(isDisabled);
    setNotDefault();
  };

  const resetSeller = () => {
    let currentSegmentData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties) as IData;

    currentSegmentData.sellerModified = false;
    setValue(addresseeNamesDataField, currentSegmentData);
    prepareBuyerBorrowerAndSeller();
    const isDisabled = evalSellerResetDisabled();
    setSellerResetDisabled(isDisabled);
  };

  const prepareBuyerBorrowerAndSeller = () => {
    const data: IData = getCurrentAddreseeNamesData(
      productEntity,
      productIndex,
      allParties
    );

    // Buyer/Borrower is always visible
    if (!data.buyerBorrowerModified) {
      setValue(
        `${productEntity}.${productIndex}.buyerBorrower`,
        getPartyNamesByCoveredParty(PartyRoleType.BuyerBorrower)
      );
    }

    // Seller is only visible for Seller covered party
    if (coveredParty.toUpperCase() === PartyRoleType.Seller) {
      if (!data.sellerModified) {
        setValue(
          `${productEntity}.${productIndex}.seller`,
          getPartyNamesByCoveredParty(PartyRoleType.Seller)
        );
      }
    }
  };

  const isAttorneySelected = getIfIsAttorneySelected(
    selectedAddlParties,
    additionalParties
  );

  const setNotDefault = () => {
    setValue(`${productEntity}.${productIndex}.isDefault`, false);
  };

  const onFullAddressChanged = () => {
    const data = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
    data.hasAddressChanged = true;
    setValue(`${addresseeNamesDataField}` as const, data);
    setNotDefault();

  };

  const setLocationNotDefault = () => {
    setNotDefault();
    setValue(`${productEntity}.${productIndex}.isLocationDefault`, false);
  };

  const setBuyerBorrowerModified = () => {
    let currentSegmentData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties) as IData;

    currentSegmentData.buyerBorrowerModified = true;
    setValue(addresseeNamesDataField, currentSegmentData);
    setNotDefault();

  };

  const setSellerModified = () => {
    let currentSegmentData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties) as IData;

    currentSegmentData.sellerModified = true;
    setValue(addresseeNamesDataField, currentSegmentData);
    setNotDefault();
  };

  const handleCoverPartyChange = () => {
    setNotDefault();

    const data: IData = getCurrentAddreseeNamesData(productEntity, productIndex, allParties);
    data.addressPartyId = "";
    data.hasAddressChanged = false;
    data.hasAddresseeNameChanged = false;
    data.hasLoanNumberChanged = false;
    data.hasSuccessorLanguageChanged = false;
    data.hasAttentionToChanged = false;
    setValue(`${productEntity}.${productIndex}.addresseeNamesData`, data);
  };

  const setIncludeAllBranches = (e: any, value: boolean) => {
    setNotDefault();
  };

  const showDelete = () => {
    if (shouldShowDeleteButtom) return false;
    if (product.orderStatusTypeCode === OrderStatusType.Pending) return true;
    return false;
  };

  const isPrintAttorneyOnlyAllowed = getIfIsPrintAttorneyOnly(
    getUniqueProductOptions(),
    productType
  );

  const evalAddresseeResetDisabled = () => {

    const validParties = hasAnyValidParties(coveredParty);
    const fieldDisabled = isFieldDisabled();

    /* NOTE:  Per IM Conversation with Maria, this is the rule set
     * No Party available = Reset Disabled, even if they type something in
     * Party available and Pending/Revising = Reset Enabled (even if they haven't typed anything)
     * Party Available and Issued/Voided = Reset Disabled
     */

    const shouldResetBeDisabled = fieldDisabled || !validParties;

    return shouldResetBeDisabled;
  };

  const evalBuyerResetDisabled = () => {
    const validParties = hasAnyValidParties(PartyRoleType.BuyerBorrower);
    const fieldDisabled = isFieldDisabled();

    const shouldResetBeDisabled = fieldDisabled || !validParties;

    return shouldResetBeDisabled;
  };

  const evalSellerResetDisabled = () => {
    const validParties = hasAnyValidParties(PartyRoleType.Seller);
    const fieldDisabled = isFieldDisabled();

    const shouldResetBeDisabled = fieldDisabled || !validParties;

    return shouldResetBeDisabled;
  };

  const [addresseeResetDisabled, setAddresseeResetDisabled] = React.useState(
    evalAddresseeResetDisabled()
  );

  const [buyerResetDisabled, setBuyerResetDisabled] = React.useState(
    evalBuyerResetDisabled()
  );

  const [sellerResetDisabled, setSellerResetDisabled] = React.useState(
    evalSellerResetDisabled()
  );

  //#endregion

  //#region - useEffects

  useEffect(() => {
    if (isFileLocked) {
      const { ACTIONS } = ReviseCpl;
      dispatch({ type: ACTIONS.CANCEL_REVISE, productIndex: productIndex });
      setValue(`${productEntity}.${productIndex}.isRevising`, false);
      setRevisingMode(false);
    }
  }, [isFileLocked]);

  // Prepare Additional Parties
  useEffect(() => {
    if (
      !additionalParties ||
      (additionalParties && additionalParties.length === 0)
    ) {
      setAddlPartySelectOptions([]);
      return;
    }

    //Check if there are orderParties assigned that no longer
    //exist in the additionalParties collection.
    const orphanedParties = [];
    const orderParties = product.orderParties ?? [];

    let addlParties = orderParties?.filter(
      (op: UIModel.OrderParty) =>
        op.partyRoleTypeCode === PartyRoleType.AdditionalParty
    );

    addlParties.forEach((ap: UIModel.OrderParty) => {
      const match = addlPartySelectOptions.find(
        (option) => option.value === ap.filePartyKey
      );
      if (!match) {
        orphanedParties.push(ap.filePartyKey);
      }
    });

    // Exit when no valid addl parties found if not in ISSUED state
    if (
      additionalParties &&
      additionalParties.length === 1 &&
      !isValidParty(additionalParties[0])
    ) {
      if (
        selectedAddlParties?.length > 0 &&
        orphanedParties.length === 0 &&
        !(
          product.orderStatusTypeCode === OrderStatusType.Issued ||
          product.orderStatusTypeCode === OrderStatusType.Voided ||
          product.orderStatusTypeCode === OrderStatusType.UndoVoidPending
        )
      ) {
        setValue(`${productEntity}.${productIndex}.addlParties`, []);
        return;
      }
    }

    if (productType === ProductType.Cpl) {
      let addlPartyOptions: MultiSelectFieldOption[] = additionalParties
        ?.filter((fileParty: GeneralFileParty) =>
          isValidParty(fileParty)
        )
        ?.map((fileParty: GeneralFileParty) => ({
          value: fileParty.filePartyKey ?? "",
          text: getDisplayNameForLender(fileParty),
          selected: false,
        }));

      // default to 1st additional party if any and Only it's not issued and it has not saved yet
      const shouldDefault = shouldDefaultAddlParty(
        initialValues,
        addlParties,
        productIndex,
        productType
      );

      const selectedAdditionalParies = selectedAddlParties ? addlPartyOptions.filter(ap => ap.value == selectedAddlParties[0]) : [];

      if (
        addlPartyOptions &&
        addlPartyOptions.length > 0 &&
        (!selectedAdditionalParies || selectedAdditionalParies.length === 0) &&
        product.orderStatusTypeCode !== OrderStatusType.Issued &&
        shouldDefault
      ) {
        addlPartyOptions[0].selected = true;
        setValue(`${productEntity}.${productIndex}.addlParties`, [
          addlPartyOptions[0].value,
        ]);
      }

      if (!addlPartyOptions || addlPartyOptions.length === 0) {
        if (
          product.orderStatusTypeCode === OrderStatusType.Pending ||
          product.orderStatusTypeCode === OrderStatusType.Error
        ) {
          if (!product.addlParties || product.addlParties.length > 0) {
            setValue(`${productEntity}.${productIndex}.addlParties`, []);
          }
        }
      }

      if (
        //Need to ensure that the Additional Party data is correct after issue, even if the parties have been deleted.
        product.orderStatusTypeCode === OrderStatusType.Issued ||
        product.orderStatusTypeCode === OrderStatusType.Voided ||
        product.orderStatusTypeCode === OrderStatusType.UndoVoidPending
      ) {
        const isRevising = reviseState.revising;
        if (!isRevising) {
          addlParties.forEach((ap: UIModel.OrderParty) => {
            const match = addlPartyOptions.find(
              (option) => option.value === ap.filePartyKey
            );
            if (!match) {
              addlPartyOptions.push({
                selected: true,
                text: getDisplayNameForLender(ap),
                value: ap.filePartyKey ?? "",
              });
            }
          });
        }
      }

      setAddlPartySelectOptions(addlPartyOptions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productType,
    additionalParties,
    product.addlParties,
    reviseState.revising,
  ]);

  // Prepare AddresseeNames
  useEffect(
    () => {
      if (!coveredParty) return;
      if (
        product.orderStatusTypeCode === OrderStatusType.Pending &&
        !reviseState.revising
      ) {
        prepareAddreseeNames();
        prepareBuyerBorrowerAndSeller();
        prepareFullAddress();
      }
      const isDisabled = evalAddresseeResetDisabled();
      setAddresseeResetDisabled(isDisabled);

      const isBuyerResetDisabled = evalBuyerResetDisabled();
      setBuyerResetDisabled(isBuyerResetDisabled);

      const isSellerResetDisabled = evalSellerResetDisabled();
      setSellerResetDisabled(isSellerResetDisabled);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      orderStatusTypeCode,
      coveredParty,
      buyerBorrowerNames,
      sellerNames,
      lenderNames,
      sellerDefault,
      buyerBorrowerDefault?.addressOne,
      buyerBorrowerDefault?.addressTwo,
      buyerBorrowerDefault?.city,
      buyerBorrowerDefault?.country,
      buyerBorrowerDefault?.foreignCountry,
      buyerBorrowerDefault?.integrationKey,
      buyerBorrowerDefault?.isForeignAddress,
      buyerBorrowerDefault?.zipCode,
      buyerBorrowerDefault?.state?.code,
      lenderDefault?.addressTwo,
      lenderDefault?.addressTwo,
      lenderDefault?.attentionTo,
      lenderDefault?.city,
      lenderDefault?.country,
      lenderDefault?.integrationKey,
      lenderDefault?.loanNumber,
      lenderDefault?.state?.code,
      lenderDefault?.successorLanguage,
      lenderDefault?.zipCode,
    ]
  );

  // Prepare CoveredParty
  useEffect(() => {
    if (!coveredParty) return;

    coveredParty.toUpperCase() === PartyRoleType.Lender
      ? setIsLenderParty(true)
      : setIsLenderParty(false);
  }, [coveredParty]);

  const [{ stateProductConfig }] = useCompanyProductOptions();
  useEffect(() => {
    const options = getProductOptionsByProductType(productType);
    setProductOptions(options);
  }, [stateProductConfig]);

  React.useEffect(() => {
    setInternalIssueButtonDisabled(!addresseeNames || !currentForm);
  }, [addresseeNames, currentForm]);

  // Uncheck Print Approved Only when Additional Party is not selected

  React.useEffect(() => {
    const isDisabled = evalAddresseeResetDisabled();
    setAddresseeResetDisabled(isDisabled);

    const isBuyerResetDisabled = evalBuyerResetDisabled();
    setBuyerResetDisabled(isBuyerResetDisabled);

    const isSellerResetDisabled = evalSellerResetDisabled();
    setSellerResetDisabled(isSellerResetDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviseState.revising]);

  // Set default effective date
  useEffect(() => {
    if (product && !product.effectiveDate && product.id === 0) {
      const profileDate = getDefaultTime();
      setValue(`${productEntity}.${productIndex}.effectiveDate`, profileDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (reviseState.revising) handleOnReviseLetter(ReviseAction.CancelRevise);
    };
  }, [reviseState.revising]);

  React.useEffect(() => {
    companyStateLocationsRef.current = companyStateLocations;
  }, [companyStateLocations]);  
  //#endregion

  //#region - layout
  return (
    <CollapsableFormSection
      title={tabTitle}
      index={productIndex}
      showDelete={showDelete()}
      onDelete={() => onDelete(productIndex)}
      label={getTabDescription(
        coveredParty,
        product?.orderStatusTypeCode,
        productIssueDateAsString,
        product?.void?.dateTime,
        product.orderStatusTypeName
      )}
      isProductIssuePending={
        product.orderStatusTypeCode === OrderStatusType.Pending
      }
    >
      <Box>
        <LetterSectionHeader productType={productType} />

        {isProductStatusVisible && (
          <ProductStatus
            issueDateName={`${productEntity}.${productIndex}.issueDateName`} // not the object's proerty name
            issueDate={issueDateAsStr}
            serialNumberName={`${productEntity}.${productIndex}.serialNumberName`} // not the object's proerty name
            serialNumber={product?.productReferenceID}
            statusName={`${productEntity}.${productIndex}.statusName`} // not the object's proerty name
            status={productStatusDisplay}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={2}>
            <EffectiveDateField
              disabled={isFieldDisabled()}
              issued={isIssued}
              name={`${productEntity}.${productIndex}.effectiveDate`}
              defaultValue={product.effectiveDate}
              onEffectiveDateChange={onEffectiveDateChange}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <CoveredPartyField
              {...{ productIndex, productType }}
              name={`${productEntity}.${productIndex}.coveredParty`}
              defaultValue={product.coveredParty}
              disabled={isFieldDisabled()}
              coveredParties={productOptions}
              productEntity={productEntity}
              isRevising={reviseState.revising}
              onChange={handleCoverPartyChange}
            />
          </Grid>
          {productType === ProductType.Cpl && (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="addresseeNames"
              style={{ position: "relative" }}
            >
              <TextInputField
                label="Addressee Name(s)"
                name={`${productEntity}.${productIndex}.addresseeNames`}
                onChange={handleAddresseeNamesChanged}
                disabled={isFieldDisabled()}
                actionButton={
                  <LinkButton
                    disabled={
                      addresseeResetDisabled || isAddresseeResetButtonDisabled
                    }
                    onClick={resetAddresseeNames}
                    buttonStyle="floatRight"
                    disabledTextField={isFieldDisabled()}
                    tabIndex="-1"
                  >
                    Reset
                  </LinkButton>
                }
              />
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={3}>
            <FormField
              name={`${productEntity}.${productIndex}.form`}
              schema={`${productEntity}.${productIndex}`}
              productType={productType}
              disabled={isFieldDisabled()}
              onChange={setNotDefault}
              isIssued={isIssued}
            />
          </Grid>

          {productType === ProductType.Aal && (
            <Grid item xs={12} md={6} lg={2}>
              <CurrencyInputField
                label="Loan Amount"
                name={`aALProducts.${productIndex}.pricingDetail.liability`}
                onChange={setNotDefault}
                allowNegative={false}
                disabled={isFieldDisabled()}
              />
            </Grid>
          )}
          {productType === ProductType.Cpl && (
            <Grid item xs={12} md={5} lg={2}>
              <Box display="flex" justifyContent={{ xs: "start", lg: "start" }} mt={1}>
                <CheckboxInputField
                  label="Include All Branches"
                  className={checkboxIcon}
                  name={`${productEntity}.${productIndex}.includeAllBranches`}
                  disabled={isFieldDisabled()}
                  onChange={setIncludeAllBranches}
                />
              </Box>
            </Grid>
          )}
          {productType === ProductType.Aal && (
            <Grid
              item
              xs={12}
              md={9}
              lg={9}
              className="addresseeNames"
              style={{ position: "relative" }}
            >
              <TextInputField
                label="Addressee Name(s)"
                name={`${productEntity}.${productIndex}.addresseeNames`}
                onChange={handleAddresseeNamesChanged}
                disabled={isFieldDisabled()}
                actionButton={
                  <LinkButton
                    disabled={
                      addresseeResetDisabled || isAddresseeResetButtonDisabled
                    }
                    onClick={resetAddresseeNames}
                    buttonStyle="floatRight"
                    disabledTextField={isFieldDisabled()}
                  >
                    Reset
                  </LinkButton>
                }
              />
            </Grid>
          )}
          <FullAddress
            schema={`${productEntity}.${productIndex}`}
            isPropertySection={false}
            isDisabledAddress={isFieldDisabled()}
            addressTwoLabelSuffix=" (optional)"
            showForeignAddressCheckbox={true}
            setNotDefault={onFullAddressChanged}
          />

          {isForeignAddress === 1 && (
            <ForeignAddress
              isDisabledAddress={isFieldDisabled()}
              schema={`${productEntity}.${productIndex}`}
              onChange={onFullAddressChanged}
            />
          )}
          {isLenderParty && (
            <>
              <Grid item xs={12} lg={3} xl={3}>
                <TextInputField
                  label="Loan Number (optional)"
                  name={`${productEntity}.${productIndex}.loanNumber`}
                  defaultValue={product.loanNumber}
                  onChange={handleLoanNumberChanged}
                  disabled={isFieldDisabled()}
                />
              </Grid>
              <Grid item xs={12} lg={6} xl={6}>
                <SuccessorLanguageField
                  name={`${productEntity}.${productIndex}.successorLanguage`}
                  defaultValue={product.successorLanguage}
                  succesorLanguages={successorLanguages}
                  isProductSection={true}
                  onChange={handleSuccessorLanguageChanged}
                  onBlur={handleSuccessorLanguageChangedOnblur}
                  disabled={isFieldDisabled()}
                />
              </Grid>
              <Grid item xs={12} lg={3} xl={3}>
                <TextInputField
                  label="Attention To (optional)"
                  name={`${productEntity}.${productIndex}.attentionTo`}
                  defaultValue={product.attentionTo}
                  onChange={handleAttentionToChanged}
                  disabled={isFieldDisabled()}
                />
              </Grid>
            </>
          )}
          {productType === ProductType.Cpl && (
            <Grid item xs={12} md={9} lg={6} className="addresseeNames">
              <AdditionalPartiesField
                label="Additional Party"
                name={`${productEntity}.${productIndex}.addlParties`}
                defaultValue={product.addlParties}
                disabled={isFieldDisabled()}
                options={addlPartySelectOptions}
                coveredParty={coveredParty}
                productIndex={productIndex}
                onTouched={setNotDefault}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={9}
            lg={6}
            className="addresseeNames"
            style={{ position: "relative" }}
          >
            <TextInputField
              label="Buyer/Borrower"
              name={`${productEntity}.${productIndex}.buyerBorrower`}
              onChange={setBuyerBorrowerModified}
              disabled={isFieldDisabled()}
              actionButton={
                <LinkButton
                  disabled={buyerResetDisabled || isBuyerResetButtonDisabled}
                  onClick={resetBuyerBorrower}
                  buttonStyle="floatRight"
                  tabIndex="-1"
                  disabledTextField={isFieldDisabled()}
                >
                  Reset
                </LinkButton>
              }
            />
          </Grid>

          {productType === ProductType.Cpl && isPrintAttorneyOnlyAllowed && (
            <Box width="100%">
              <Box flex={1}>
                <Controller
                  control={control}
                  name={nameString(
                    `${productEntity}.${productIndex}.printAttorneyOnly`
                  )}
                  defaultValue={false}
                  render={({ field: { name, value } }) => {
                    const onChange = (e: any, checked: boolean) => {
                      setValue(
                        `${productEntity}.${productIndex}.printAttorneyOnly`,
                        checked
                      );
                      setNotDefault();
                    };
                    return (
                      <CheckboxInput
                        label="Print Approved Attorney only"
                        disabled={
                          (isProductStatusVisible && !reviseState.revising) ||
                          !isAttorneySelected ||
                          disablePrintAttorneyOnly
                        }
                        {...{ name }}
                        onChange={onChange}
                        checked={value}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          )}

          {productType === ProductType.Cpl &&
            coveredParty?.toUpperCase() === PartyRoleType.Seller && (
              <Grid
                item
                xs={12}
                md={9}
                lg={6}
                className="addresseeNames"
                style={{ position: "relative" }}
              >
                <TextInputField
                  label="Seller"
                  name={`${productEntity}.${productIndex}.seller`}
                  onChange={setSellerModified}
                  disabled={isFieldDisabled()}
                  actionButton={
                    <LinkButton
                      disabled={
                        sellerResetDisabled || isSellerResetButtonDisabled
                      }
                      onClick={resetSeller}
                      buttonStyle="floatRight"
                      tabIndex="-1"
                      disabledTextField={isFieldDisabled()}
                    >
                      Reset
                    </LinkButton>
                  }
                />
              </Grid>
            )}

          {productType === ProductType.Aal && (
            <Grid item xs={12} md={9} lg={6}>
              <TextInputField
                label="Counsel (optional)"
                name={`${productEntity}.${productIndex}.counsel`}
                onChange={setNotDefault}
                disabled={isFieldDisabled()}
              />
            </Grid>
          )}
          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid item xs={12} sm={12} md={12} lg={showLiabilityAmount ? 9 : 12}>
              <ProductAgencyLocationField
                label="Agency Location"
                productType={productType}
                schema={`${productEntity}.${productIndex}`}
                name={`${productEntity}.${productIndex}.pricingDetail.locationDisplayName`}
                disabled={isFieldDisabled()}
                onChange={setLocationNotDefault}
                isRevising={reviseState.revising}
              />
            </Grid>
            {showLiabilityAmount && (
              <Grid item xs={12} sm={12} md={12} lg={3} style={{ paddingLeft: padding.medium1 }}>
                <CurrencyInputField
                  label="Liability Amount"
                  name={`${productEntity}.${productIndex}.pricingDetail.liability`}
                  disabled={isFieldDisabled()}
                  allowNegative={false}
                  onBlur={setNotDefault}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={gridCols} lg={gridCols} xl={gridCols}>
            <LetterButtonSection
              onIssueLetter={handleOnIssueLetter}
              onReviseLetter={handleOnReviseLetter}
              onViewLetter={handleViewLetter}
              onVoidLetter={handleVoidLetter}
              orderStatusTypeCode={orderStatusTypeCode}
              issueButtonDisabled={
                issueButtonDisabled || internalIssueButtonDisabled
              }
              revising={reviseState.revising}
              {...{
                voidButtonDisabled,
              }}
            />
          </Grid>
        </Grid>
        <PdfViewer
          onClose={handleClosePdfViewer}
          isOpen={openPdfViewer}
          {...{ pdfDocuments }}
        />
        <ErrorDialog
          isOpen={openErrorDialog}
          confirmationMessage={errorMessage}
          correlationId={correlationId}
          onYes={handleOnYesErrorDialog}
        />
        <input
          type="hidden"
          {...register(`${addresseeNamesDataField}` as const)}
          defaultValue={product.addresseeNamesData}
        />
        <input
          type="hidden"
          {...register(`${orderStatusTypeField}` as const)}
          defaultValue={product.orderStatusTypeCode}
        />
        <CheckboxInputField
          hidden={true}
          name={`${productEntity}.${productIndex}.isLocationDefault`}
        />
      </Box>
    </CollapsableFormSection>
  );
};

export default LetterProduct;
//#endregion

// export default React.memo(LetterProduct, ((prevProps: Readonly<PropsWithChildren<ProductProps>>, nextProps: Readonly<PropsWithChildren<ProductProps>>) => {

//   let result = prevProps.productIndex === nextProps.productIndex &&
//     prevProps.issueButtonDisabled === nextProps.issueButtonDisabled &&
//     prevProps.voidButtonDisabled === nextProps.voidButtonDisabled &&
//     prevProps.submitReviseDisabled === nextProps.submitReviseDisabled &&
//     prevProps.loadCityList === nextProps.loadCityList;

//   if (prevProps.aALProducts.length && nextProps.aALProducts.length) {
//     const prevAAL = prevProps.aALProducts[prevProps.productIndex];
//     const nextALL = nextProps.aALProducts[nextProps.productIndex];

//     result = result && prevAAL?.state?.abbr === nextALL?.state?.abbr;
//   }
//   return result;
// }));
