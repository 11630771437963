import { UserRequest as ApiUserRequest } from "entities/ApiModel/UserRequest";
import { UserRequest as UiUserRequest } from "entities/UIModel/UserRequest";
import { UserRequestDetail as ApiUserRequestDetail } from "entities/ApiModel/UserRequestDetail";
import { UserRequestDetail as UiUserRequestDetail } from "entities/UIModel/UserRequestDetail";

export const mapApiUserRequest = (uiData: UiUserRequest): ApiUserRequest => {
  const apiData: ApiUserRequest = {
    UserRequestID: uiData.userRequestID,
    RequestedUserID: uiData.requestedUserID,
    RequestedDate: uiData.requestedDate,
    AgencyID: uiData.agencyID,
    AgencyName: uiData.agencyName,
    LegacyID: uiData.legacyID,
    StateAbbr: uiData.stateAbbr,
    StateCode: uiData.stateCode,
    AdditionalInstruction: uiData.additionalInstruction,
    RequestedUserAuthorizationType: uiData.requestedUserAuthorizationType,
    RequestedUserName: uiData.requestedUserName,
    RequestedUserEmailAddress: uiData.requestedUserEmailAddress,
    RequestedUserFirstName: uiData.requestedUserFirstName,
    RequestedUserLastName: uiData.requestedUserLastName,
    RequestedUserDisplayName: uiData.requestedUserDisplayName,
    RequestedUserPhone: uiData.requestedUserPhone,
    UserRequestDetails: uiData.userRequestDetails.map((userDetail: UiUserRequestDetail) => {
      const apiUserDetail: ApiUserRequestDetail = {
        EmailAddress: userDetail.emailAddress,
        FirstName: userDetail.firstName,
        LastName: userDetail.lastName,
        UserTimeZone: userDetail.userTimeZone,
      };
      return apiUserDetail;
    }),
  };
  return apiData;
};
