import React from "react";
import { ProductType } from "utils/data/enum";

export const standaloneErrorHeader =
  "The following errors were encountered, please review and resubmit:";

export const endorsementErrorHeader =
  "The following Endorsement(s) were not added, please review the error and resubmit:";

export const errorMessageContent = (
  errorMessages: string[],
  productType: string
): React.ReactNode => {
  if (productType === ProductType.StandaloneEndorsement) {
    if (errorMessages.length > 0) {
      return (
        <>
          <div style={{ textAlign: "left" }}>{standaloneErrorHeader}</div>
          <ul style={{ textAlign: "left" }}>
            {errorMessages?.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </>
      );
    } else {
      return <span>{errorMessages[0]}</span>;
    }
  } else if (productType === ProductType.Endorsement) {
    return (
      <>
        <div style={{ textAlign: "left" }}>{endorsementErrorHeader}</div>
        <ul style={{ textAlign: "left" }}>
          {errorMessages?.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </>
    );
  } else {
    if (errorMessages.length > 0) {
      return (
        <ul style={{ textAlign: "left" }}>
          {errorMessages?.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      );
    } else {
      return <span>{errorMessages[0]}</span>;
    }
  }
};
