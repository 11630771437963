import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type State = {
  show: boolean;
  message: string;
  onClose?:() => void;
}

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  showFlashMessage:
    (message: string) =>
    ({ setState }: StoreApi) => {
      setState({ show: true, message });
    },
  closeFlashMessage:
    (onCloseFn?: () => void) =>
    ({ setState }: StoreApi) => {
      setState({ show: false, onClose: onCloseFn });
    },
};

const Store  = createStore<State, Actions>({
  initialState: {
    show: false,
    message: "",
  },
  actions,
  name: "flashMessage"

})

const getFlashMessageStatus = (state: State) => ({
  open: state.show,
  message: state.message
});

const useFlashMessageState = createHook(Store, {
  selector: getFlashMessageStatus,
});

const useFlashMessageActions = createHook(Store, {
  selector: null,
});

export {useFlashMessageActions, useFlashMessageState};