import OriginalPolicyDialog from "controls/global/dialogs/original-policy-dialog-container";
import {
  FilePricingDetail,
  Jacket,
  PricingConfig,
  PricingDataResult,
  PricingProduct,
  PricingProductItem,
  SCFile,
} from "entities/UIModel";
import { PricingOriginalPolicy } from "entities/UIModel/PricingOriginalPolicy";
import { useEndorsementErrorMessage } from "pages/file/EndorsementErrorMessageProvider";
import { getSumPricingTaxes } from "pages/file/utils/helper";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useInitialValuesFiles } from "utils/context/FilesContext";
import { useOrder } from "utils/context/OrderContext";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import { usePricingActions } from "utils/context/PricingContext";
import {
  getNameString,
  default as useFormWrapper,
} from "utils/custom-hooks/useFormWrapper";
import usePricing from "utils/custom-hooks/usePricing";
import {
  MapActionType,
  PricingConfigKey,
  PricingType,
  UIConstants,
} from "utils/data/enum";
import { convertSQLDBNullToUndefined } from "utils/shared";
import OriginalPolicyBody from "./OriginalPolicyBody";
import useCreateFile from "utils/custom-hooks/useCreateFile";

type Props = {
  isOpen: boolean;
  index?: number;
  disabled?: boolean;
  allowSave?: boolean;
  onClose: () => void;
  onSave: () => void;
};

function InsertIf(condition: boolean, ...propNames: string[]) {
  return condition ? propNames : [];
}

function PricingOriginalPolicyDialog({
  index,
  isOpen,
  onSave,
  onClose,
  disabled,
  allowSave,
}: Props) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { trigger, clearErrors, setValue, getValues } = useFormWrapper();
  const [{ initialValues }, { resetJacketPricingDetail }] = useInitialValuesFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();

  const [{ pricingConfigs }] = usePricingConfig();
  const { isFileLocked } = useCreateFile();
  const [, { processOPN }] = useOrder();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const { dispatch: endorsementErrorMessageDispatch } = useEndorsementErrorMessage();
  const {
    copyUpdatedInitialFilePricingDetails,
  } = usePricing();

  const basePathPolicy = `pricingProducts.${index}.originalPolicy`;
  const currentPricingProduct: PricingProduct | undefined = getValues(
    `pricingProducts.${index}`
  );

  const showReissueEdit = getValues("pricing.showReissueEdit");
  const showOPNLiabilityAmount = getValues("pricing.showOPNLiabilityAmount");
  const showOPNCoverageType = getValues("pricing.showOPNCoverageType");
  const showOPNFormType = getValues("pricing.showOPNFormType");
  const showOPNPolicyDate = getValues("pricing.showOPNPolicyDate");
  const showOPNPolicyNumber = getValues("pricing.showOPNPolicyNumber");

  const isIntegratedPricing: boolean = getValues("pricing.isIntegratedPricing");
  const isManualPricing = !isIntegratedPricing;
  const initialOriginalPolicy = useRef<PricingOriginalPolicy | undefined>(undefined);

  const updatePricingData = (updatedPricingData: PricingDataResult) => {
    if (updatedPricingData) {
      // -- Update Pricing Notification
      if (updatedPricingData.pricingConfig) {
        setValue(
          "pricing.errorMessage",
          updatedPricingData.pricingConfig.errorMessage
        );
        setValue(
          "pricing.infoMessage",
          updatedPricingData.pricingConfig.infoMessage
        );
        setValue("pricing.isCalculationRequired", false);
      }

      const currentFilePricingDetails: Array<FilePricingDetail> =
        getValues("filePricingDetails");

      // Update FilePricingDetails
      if (updatedPricingData.filePricingDetails && updatedPricingData.filePricingDetails.length > 0) {
        copyUpdatedInitialFilePricingDetails(updatedPricingData.filePricingDetails, currentFilePricingDetails);
      }

      // -- Update Pricing Amounts
      if (updatedPricingData.jackets && updatedPricingData.jackets.length > 0) {

        const currentPricingProducts: Array<PricingProduct> | undefined =
          getValues("pricingProducts");

        updatedPricingData.jackets.forEach((jacket) => {
          //#region Update filePrcingDetails

          // Update Parent level - based on Jacket's pricing details
          const fpdIndex = currentFilePricingDetails?.findIndex(
            (fpd) => fpd.id === jacket.pricingDetail?.filePricingDetailID
          );
          if (fpdIndex >= 0) {
            setValue(
              `filePricingDetails.${fpdIndex}.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );
            setValue(
              `filePricingDetails.${fpdIndex}.pricingTransactionTypeCode`,
              jacket.pricingDetail?.pricingTransactionTypeCode
            );
            setValue(
              `filePricingDetails.${fpdIndex}.pricingFeeTypeCode`,
              jacket.pricingDetail?.pricingFeeTypeCode
            );
            // setValue(
            //   `filePricingDetails.${fpdIndex}.isBilled`,
            //   jacket.pricingDetail?.isBilled || 0
            // );
            // setValue(
            //   `filePricingDetails.${fpdIndex}.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled || 0
            // );
            // setValue(
            //   `filePricingDetails.${fpdIndex}.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate || 0
            // );
            // don't remap isReportable -- because the middleware still set it to null, but the UI have set it on initial load
            //setValue(`filePricingDetails.${fpdIndex}.isReportable`,jacket.pricingDetail?.isReportable || 0 );

            setValue(
              `filePricingDetails.${fpdIndex}.actualFee`,
              jacket.pricingDetail?.actualFee || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.actualPremiumTax`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.actualRemittance`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.actualRetention`,
              jacket.pricingDetail?.actualRetention || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.actualRiskRate`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );

            setValue(
              `filePricingDetails.${fpdIndex}.calculatedFee`,
              jacket.pricingDetail?.calculatedFee || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.calculatedPremiumTax`,
              jacket.pricingDetail?.calculatedPremiumTax || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.calculatedRemittance`,
              jacket.pricingDetail?.calculatedRemittance || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.calculatedRetention`,
              jacket.pricingDetail?.calculatedRetention || 0.0
            );
            setValue(
              `filePricingDetails.${fpdIndex}.calculatedRiskRate`,
              jacket.pricingDetail?.calculatedRiskRate || 0.0
            );
          }

          // Update Children level - based on jacket endorsement's pricing details
          jacket.endorsements?.forEach((endorsement, edIndex) => {
            const fpdIndex = currentFilePricingDetails?.findIndex(
              (fpd) => fpd.id === endorsement.pricingDetail?.filePricingDetailID
            );
            if (fpdIndex >= 0) {
              setValue(
                `filePricingDetails.${fpdIndex}.altPricingReferenceID`,
                endorsement.pricingDetail?.altPricingReferenceID
              );
              // setValue(
              //   `filePricingDetails.${fpdIndex}.isBilled`,
              //   endorsement.pricingDetail?.isBilled || 0
              // );
              // setValue(
              //   `filePricingDetails.${fpdIndex}.isReadyToBeBilled`,
              //   endorsement.pricingDetail?.isReadyToBeBilled || 0
              // );
              // setValue(
              //   `filePricingDetails.${fpdIndex}.lastReportedDate`,
              //   endorsement.pricingDetail?.lastReportedDate || 0
              // );
              // don't remap isReportable -- because the middleware still set it to null, but the UI have set it on initial load
              //setValue(`filePricingDetails.${fpdIndex}.isReportable`,endorsement.pricingDetail?.isReportable || 0 );

              setValue(
                `filePricingDetails.${fpdIndex}.actualFee`,
                endorsement.pricingDetail?.actualFee || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.actualPremiumTax`,
                endorsement.pricingDetail?.actualPremiumTax || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.actualRemittance`,
                endorsement.pricingDetail?.actualRemittance || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.actualRetention`,
                endorsement.pricingDetail?.actualRetention || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.actualRiskRate`,
                endorsement.pricingDetail?.actualRiskRate || 0.0
              );

              setValue(
                `filePricingDetails.${fpdIndex}.calculatedFee`,
                endorsement.pricingDetail?.calculatedFee || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.calculatedPremiumTax`,
                endorsement.pricingDetail?.calculatedPremiumTax || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.calculatedRemittance`,
                endorsement.pricingDetail?.calculatedRemittance || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.calculatedRetention`,
                endorsement.pricingDetail?.calculatedRetention || 0.0
              );
              setValue(
                `filePricingDetails.${fpdIndex}.calculatedRiskRate`,
                endorsement.pricingDetail?.calculatedRiskRate || 0.0
              );
            }
          });

          //#endregion

          //#region Update pricingProduct
          const pricingIndex = currentPricingProducts?.findIndex(
            (pp) =>
              pp.pricingType === "Product" &&
              pp.filePricingDetailId ===
              jacket.pricingDetail?.filePricingDetailID
          );
          if (pricingIndex !== -1) {
            // Update Pricing Product and Initial Pricing Product

            setValue(
              `pricingProducts.${pricingIndex}.pricingRateTypeData`,
              jacket.pricingDetail?.pricingRateTypeData
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.pricingRateTypeData`,
              jacket.pricingDetail?.pricingRateTypeData
            );

            setValue(
              `pricingProducts.${pricingIndex}.pricingRateType`,
              jacket.pricingDetail?.pricingRateType
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.pricingRateType`,
              jacket.pricingDetail?.pricingRateType
            );

            // The latest flags in database
            // isReissue
            setValue(
              `pricingProducts.${pricingIndex}.isReissue`,
              jacket.pricingDetail?.isReissue || false
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.isReissue`,
              jacket.pricingDetail?.isReissue || false
            );

            // setValue(
            //   `pricingProducts.${pricingIndex}.isBilled`,
            //   jacket.pricingDetail?.isBilled || 0
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${pricingIndex}.isBilled`,
            //   jacket.pricingDetail?.isBilled || 0
            // );

            // setValue(
            //   `pricingProducts.${pricingIndex}.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled || 0
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${pricingIndex}.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled || 0
            // );
            // don't remap isReportable -- because the middleware still set it to null, but the UI have set it on initial load
            // setValue(
            //   `pricingProducts.${pricingIndex}.isReportable`,
            //   jacket.pricingDetail?.isReportable || 0
            // );
            // setValue(
            //   `pricingProducts.${pricingIndex}.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${pricingIndex}.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate
            // );

            setValue(
              `pricingProducts.${pricingIndex}.actualPremiumTax`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );
            setValue(
              `pricingProducts.${pricingIndex}.actualPremiumTaxInitial`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualPremiumTax`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualPremiumTaxInitial`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.calculatedPremiumTax`,
              jacket.pricingDetail?.calculatedPremiumTax || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.calculatedPremiumTax`,
              jacket.pricingDetail?.calculatedPremiumTax || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.actualFee`,
              jacket.pricingDetail?.actualFee || 0.0
            );
            setValue(
              `pricingProducts.${pricingIndex}.actualFeeInitial`,
              jacket.pricingDetail?.actualFee || 0.0
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualFee`,
              jacket.pricingDetail?.actualFee || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualFeeInitial`,
              jacket.pricingDetail?.actualFee || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.calculatedFee`,
              jacket.pricingDetail?.calculatedFee || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.calculatedFee`,
              jacket.pricingDetail?.calculatedFee || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.agentRetention`,
              jacket.pricingDetail?.actualRetention || 0.0
            );
            setValue(
              `pricingProducts.${pricingIndex}.agentRetentionInitial`,
              jacket.pricingDetail?.actualRetention || 0.0
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.agentRetention`,
              jacket.pricingDetail?.actualRetention || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.agentRetentionInitial`,
              jacket.pricingDetail?.actualRetention || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.calculatedRetention`,
              jacket.pricingDetail?.calculatedRetention || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.calculatedRetention`,
              jacket.pricingDetail?.calculatedRetention || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.totalDue`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );
            setValue(
              `pricingProducts.${pricingIndex}.totalDueInitial`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.totalDue`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.totalDueInitial`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.calculatedTotalDue`,
              jacket.pricingDetail?.calculatedRemittance || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.calculatedTotalDue`,
              jacket.pricingDetail?.calculatedRemittance || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.actualRiskRate`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );
            setValue(
              `pricingProducts.${pricingIndex}.actualRiskRateInitial`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );

            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualRiskRate`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.actualRiskRateInitial`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.calculatedRiskRate`,
              jacket.pricingDetail?.calculatedRiskRate || 0.0
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.calculatedRiskRate`,
              jacket.pricingDetail?.calculatedRiskRate || 0.0
            );

            setValue(
              `pricingProducts.${pricingIndex}.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );

            setValue(
              `pricingProducts.${pricingIndex}.pricingTransactionTypeCode`,
              jacket.pricingDetail?.pricingTransactionTypeCode
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.pricingTransactionTypeCode`,
              jacket.pricingDetail?.pricingTransactionTypeCode
            );

            setValue(
              `pricingProducts.${pricingIndex}.pricingFeeTypeCode`,
              jacket.pricingDetail?.pricingFeeTypeCode
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.pricingFeeTypeCode`,
              jacket.pricingDetail?.pricingFeeTypeCode
            );

            setValue(`pricingProducts.${pricingIndex}.isReissueUpdated`, false);
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.isReissueUpdated`,
              false
            );

            setValue(
              `pricingProducts.${pricingIndex}.lastErrorMessage`,
              jacket.pricingDetail?.lastErrorMessage
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.lastErrorMessage`,
              jacket.pricingDetail?.lastErrorMessage
            );

            // isManualFee
            setValue(
              `pricingProducts.${pricingIndex}.isManualFee`,
              jacket.pricingDetail?.isManualFee
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.isManualFee`,
              jacket.pricingDetail?.isManualFee
            );

            // isErrorFee
            setValue(
              `pricingProducts.${pricingIndex}.isFeeError`,
              jacket.pricingDetail?.isFeeError
            );
            setValue(
              `updatedInitialPricingProducts.${pricingIndex}.isFeeError`,
              jacket.pricingDetail?.isFeeError
            );


            // Update Pricing Product Items
            if (currentPricingProducts && currentPricingProducts.length > 0) {
              currentPricingProducts[pricingIndex!].productItems?.forEach(
                (pi: PricingProductItem, itemIndex: number) => {
                  const updatedPricingDetail = jacket.endorsements?.find(
                    (e) => e.filePricingDetailID === pi.filePricingDetailId
                  )?.pricingDetail;

                  // NOTE: items with error message codes (001,004,003,005,006) need to be manually updated
                  if (updatedPricingDetail) {
                    const errorMessage = updatedPricingDetail.lastErrorMessage;
                    if (errorMessage) {
                      setValue(`pricingProducts.${pricingIndex}.productItems.${itemIndex}.lastErrorMessage`, errorMessage);
                      const code = errorMessage.substring(0, 3);
                      const appendant = errorMessage.substring(errorMessage.length - 6);
                      if (code !== "002" && appendant !== "Orange") return;

                    }

                    // isManualFee
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isManualFee`,
                      updatedPricingDetail.isManualFee || 0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isManualFee`,
                      updatedPricingDetail.isManualFee || 0
                    );

                    // isErrorFee
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isFeeError`,
                      updatedPricingDetail.isFeeError || 0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isFeeError`,
                      updatedPricingDetail.isFeeError || 0
                    );

                    // setValue(
                    //   `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isBilled`,
                    //   updatedPricingDetail.isBilled || 0.0
                    // );
                    // setValue(
                    //   `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.isBilled`,
                    //   updatedPricingDetail.isBilled || 0.0
                    // );

                    // setValue(
                    //   `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isReadyToBeBilled`,
                    //   updatedPricingDetail.isReadyToBeBilled || 0.0
                    // );
                    // setValue(
                    //   `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.isReadyToBeBilled`,
                    //   updatedPricingDetail.isReadyToBeBilled || 0.0
                    // );

                    // setValue(
                    //   `pricingProducts.${pricingIndex}.productItems.${itemIndex}.isBilled`,
                    //   updatedPricingDetail.isBilled || 0.0
                    // );
                    // setValue(
                    //   `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.isBilled`,
                    //   updatedPricingDetail.isBilled || 0.0
                    // );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.actualFee`,
                      updatedPricingDetail.actualFee || 0.0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.actualFeeInitial`,
                      updatedPricingDetail.actualFee || 0.0
                    );

                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.actualFee`,
                      updatedPricingDetail.actualFee || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.actualFeeInitial`,
                      updatedPricingDetail.actualFee || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedFee`,
                      updatedPricingDetail.calculatedFee || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedFee`,
                      updatedPricingDetail.calculatedFee || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.agentRetention`,
                      updatedPricingDetail.actualRetention || 0.0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.agentRetentionInitial`,
                      updatedPricingDetail.actualRetention || 0.0
                    );

                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.agentRetention`,
                      updatedPricingDetail.actualRetention || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.agentRetentionInitial`,
                      updatedPricingDetail.actualRetention || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedRetention`,
                      updatedPricingDetail.calculatedRetention || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedRetention`,
                      updatedPricingDetail.calculatedRetention || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.totalDue`,
                      updatedPricingDetail.actualRemittance || 0.0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.totalDueInitial`,
                      updatedPricingDetail.actualRemittance || 0.0
                    );

                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.totalDue`,
                      updatedPricingDetail.actualRemittance || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.totalDueInitial`,
                      updatedPricingDetail.actualRemittance || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedTotalDue`,
                      updatedPricingDetail.calculatedRemittance || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedTotalDue`,
                      updatedPricingDetail.calculatedRemittance || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.actualRiskRate`,
                      updatedPricingDetail.actualRiskRate || 0.0
                    );
                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.actualRiskRateInitial`,
                      updatedPricingDetail.actualRiskRate || 0.0
                    );

                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.actualRiskRate`,
                      updatedPricingDetail.actualRiskRate || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.actualRiskRateInitial`,
                      updatedPricingDetail.actualRiskRate || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedRiskRate`,
                      updatedPricingDetail.calculatedRiskRate || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedRiskRate`,
                      updatedPricingDetail.calculatedRiskRate || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.actualPremiumTax`,
                      updatedPricingDetail.actualPremiumTax || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.actualPremiumTax`,
                      updatedPricingDetail.actualPremiumTax || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedPremiumTax`,
                      updatedPricingDetail.calculatedPremiumTax || 0.0
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.calculatedPremiumTax`,
                      updatedPricingDetail.calculatedPremiumTax || 0.0
                    );

                    setValue(
                      `pricingProducts.${pricingIndex}.productItems.${itemIndex}.altPricingReferenceID`,
                      updatedPricingDetail.altPricingReferenceID
                    );
                    setValue(
                      `updatedInitialPricingProducts.${pricingIndex}.productItems.${itemIndex}.altPricingReferenceID`,
                      updatedPricingDetail.altPricingReferenceID
                    );
                  }
                }
              );
            }
          }

          const taxPricingIndex = currentPricingProducts?.findIndex(
            (pp) =>
              pp.pricingType === "Tax" &&
              pp.filePricingDetailId ===
              jacket.pricingDetail?.filePricingDetailID
          );
          if (taxPricingIndex && taxPricingIndex !== -1) {
            // Update Pricing Product and Initial Pricing Product
            const { actualPremiumTaxSum, calculatedPremiumTaxSum } =
              getSumPricingTaxes(
                getValues(getNameString(`pricingProducts.${pricingIndex}`)),
                isFileLocked
              );

            // setValue(
            //   `pricingProducts.${taxPricingIndex}.isBilled`,
            //   jacket.pricingDetail?.isBilled || 0
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${taxPricingIndex}.isBilled`,
            //   jacket.pricingDetail?.isBilled || 0
            // );

            // setValue(
            //   `pricingProducts.${taxPricingIndex}.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled || 0
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${taxPricingIndex}.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled || 0
            // );
            // don't remap isReportable -- because the middleware still set it to null, but the UI have set it on initial load
            // setValue(
            //   `pricingProducts.${taxPricingIndex}.isReportable`,
            //   jacket.pricingDetail?.isReportable || 0
            // );
            // setValue(
            //   `pricingProducts.${taxPricingIndex}.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate
            // );
            // setValue(
            //   `updatedInitialPricingProducts.${taxPricingIndex}.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate
            // );

            setValue(
              `pricingProducts.${taxPricingIndex}.actualFee`,
              actualPremiumTaxSum
            );
            setValue(
              `pricingProducts.${taxPricingIndex}.actualFeeInitial`,
              actualPremiumTaxSum
            );

            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.actualFee`,
              actualPremiumTaxSum
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.actualFeeInitial`,
              actualPremiumTaxSum
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.calculatedFee`,
              calculatedPremiumTaxSum
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.calculatedFee`,
              calculatedPremiumTaxSum
            );

            setValue(`pricingProducts.${taxPricingIndex}.agentRetention`, 0);
            setValue(
              `pricingProducts.${taxPricingIndex}.agentRetentionInitial`,
              0
            );

            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.agentRetention`,
              0
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.agentRetentionInitial`,
              0
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.calculatedRetention`,
              0
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.calculatedRetention`,
              0
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.totalDue`,
              actualPremiumTaxSum
            );
            setValue(
              `pricingProducts.${taxPricingIndex}.totalDueInitial`,
              actualPremiumTaxSum
            );

            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.totalDue`,
              actualPremiumTaxSum
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.totalDueInitial`,
              actualPremiumTaxSum
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.calculatedTotalDue`,
              calculatedPremiumTaxSum
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.calculatedTotalDue`,
              calculatedPremiumTaxSum
            );

            setValue(`pricingProducts.${taxPricingIndex}.actualRiskRate`, 0);
            setValue(
              `pricingProducts.${taxPricingIndex}.actualRiskRateInitial`,
              0
            );

            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.actualRiskRate`,
              0
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.actualRiskRateInitial`,
              0
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.calculatedRiskRate`,
              0
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.calculatedRiskRate`,
              0
            );

            setValue(
              `pricingProducts.${taxPricingIndex}.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );
            setValue(
              `updatedInitialPricingProducts.${taxPricingIndex}.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );
          }
          //#endregion

          //#region Update Jackets Product (OPN and Pricing Details) & update initialValues.jacket
          const currentJackets: Jacket[] = getValues("jackets");
          const jacketIndex = currentJackets.findIndex(
            (currentJacket) =>
              currentJacket.integrationKey === jacket.integrationKey
          );
          if (jacketIndex >= 0) {
            // update OPN
            setValue(
              `jackets.${jacketIndex}.originalJacketDate`,
              jacket.originalJacketDate
            );
            setValue(
              `jackets.${jacketIndex}.originalJacketLiability`,
              convertSQLDBNullToUndefined(jacket.originalJacketLiability)
            );
            setValue(
              `jackets.${jacketIndex}.originalJacketNumber`,
              jacket.originalJacketNumber
            );
            setValue(
              `jackets.${jacketIndex}.originalJacketState`,
              jacket.originalJacketState
            );
            setValue(
              `jackets.${jacketIndex}.originalJacketType`,
              jacket.originalJacketType
            );
            setValue(
              `jackets.${jacketIndex}.originalJacketUnderwriterTypeCode`,
              jacket.originalJacketUnderwriterTypeCode
            );

            // update pricing details
            setValue(
              `jackets.${jacketIndex}.pricingDetail.actualFee`,
              jacket.pricingDetail?.actualFee || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.actualPremiumTax`,
              jacket.pricingDetail?.actualPremiumTax || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.actualRemittance`,
              jacket.pricingDetail?.actualRemittance || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.actualRetention`,
              jacket.pricingDetail?.actualRetention || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.actualRiskRate`,
              jacket.pricingDetail?.actualRiskRate || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.calculatedFee`,
              jacket.pricingDetail?.calculatedFee || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.calculatedPremiumTax`,
              jacket.pricingDetail?.calculatedPremiumTax || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.calculatedRemittance`,
              jacket.pricingDetail?.calculatedRemittance || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.calculatedRetention`,
              jacket.pricingDetail?.calculatedRetention || 0.0
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.calculatedRiskRate`,
              jacket.pricingDetail?.calculatedRiskRate || 0.0
            );

            setValue(
              `jackets.${jacketIndex}.pricingDetail.isReissue`,
              jacket.pricingDetail?.isReissue
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.isPrevPolicyLast10Yrs`,
              jacket.pricingDetail?.isPrevPolicyLast10Yrs
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.prevPolicyCoverageType`,
              jacket.pricingDetail?.prevPolicyCoverageType
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.coverageType`,
              jacket.pricingDetail?.coverageType
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.pricingRateType`,
              jacket.pricingDetail?.pricingRateType
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.altPricingReferenceID`,
              jacket.pricingDetail?.altPricingReferenceID
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.pricingTransactionTypeCode`,
              jacket.pricingDetail?.pricingTransactionTypeCode
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.pricingFeeTypeCode`,
              jacket.pricingDetail?.pricingFeeTypeCode
            );
            // setValue(
            //   `jackets.${jacketIndex}.pricingDetail.isBilled`,
            //   jacket.pricingDetail?.isBilled
            // );
            // setValue(
            //   `jackets.${jacketIndex}.pricingDetail.isReadyToBeBilled`,
            //   jacket.pricingDetail?.isReadyToBeBilled
            // );
            // setValue(
            //   `jackets.${jacketIndex}.pricingDetail.lastReportedDate`,
            //   jacket.pricingDetail?.lastReportedDate
            // );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.lastErrorMessage`,
              jacket.pricingDetail?.lastErrorMessage
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.isManualFee`,
              jacket.pricingDetail?.isManualFee
            );
            setValue(
              `jackets.${jacketIndex}.pricingDetail.isFeeError`,
              jacket.pricingDetail?.isFeeError
            );
          }

          resetJacketPricingDetail(jacket);
          //#endregion
        });
      }
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    setValue(`${basePathPolicy}.isValidationRequired`, true);
    const isValid = await trigger(getNameString(getFieldsToValidate()));
    if (!isValid) {
      setIsSaving(false);
      return;
    }

    const jackets: Jacket[] = getValues("jackets");
    const pricingProducts: PricingProduct[] = getValues(`pricingProducts`);
    let updatedJackets: Jacket[] = [];

    pricingProducts
      ?.filter(
        (pricingProduct) =>
          pricingProduct.pricingType === PricingType.Product &&
          ( pricingProduct?.filePricingDetailId === currentPricingProduct?.filePricingDetailId ||
            (currentPricingProduct?.altPricingReferenceID &&
              pricingProduct.altPricingReferenceID === currentPricingProduct?.altPricingReferenceID
            )
          )
      )
      ?.forEach((pricingProduct, index: number) => {
        const foundJacket = jackets.find(
          (jacket) =>
            jacket.filePricingDetailID === pricingProduct.filePricingDetailId
        );

        if (foundJacket) {
          const updatedJacket = Object.assign({}, foundJacket);
          updatedJacket.pricingDetail!.isPrevPolicyLast10Yrs =
            pricingProduct.originalPolicy?.previouslyIssued;
          updatedJacket.pricingDetail!.prevPolicyCoverageType =
            pricingProduct.originalPolicy?.coverageType;
          updatedJacket.pricingDetail!.pricingRateType =
            pricingProduct.pricingRateType;
          updatedJacket.pricingDetail!.pricingRateTypeData =
            pricingProduct.pricingRateTypeData;
          updatedJacket.pricingDetail!.pricingTransactionTypeCode =
            pricingProduct.pricingTransactionTypeCode;
          updatedJacket.pricingDetail!.pricingFeeTypeCode =
            pricingProduct.pricingFeeTypeCode;
          updatedJacket.pricingDetail!.isReissue = pricingProduct.isReissue;

          if (foundJacket.pricingDetail?.filePricingDetailID === currentPricingProduct?.filePricingDetailId) {
            updatedJacket.pricingDetail!.isReissue = true;
          }
          updatedJacket.inProcessOPN = {
            ...updatedJacket.inProcessOPN,
            originalJacketDate: pricingProduct.originalPolicy?.date,
            originalJacketLiability:
              pricingProduct.originalPolicy?.liabilityAmount,
            originalJacketNumber: pricingProduct.originalPolicy?.policyNumber,
            originalJacketType: pricingProduct.originalPolicy?.jacketType,
          };
          updatedJacket.pricingDetail!.actualFee = pricingProduct.actualFee;
          updatedJacket.pricingDetail!.calculatedFee = pricingProduct.calculatedFee;
          updatedJacket.pricingDetail!.actualRiskRate = pricingProduct.actualRiskRate;
          updatedJacket.pricingDetail!.calculatedRiskRate = pricingProduct.calculatedRiskRate;
          updatedJacket.pricingDetail!.actualRetention = pricingProduct.agentRetention;
          updatedJacket.pricingDetail!.calculatedRetention = pricingProduct.calculatedRetention;
          updatedJacket.pricingDetail!.actualPremiumTax = pricingProduct.actualPremiumTax;
          updatedJacket.pricingDetail!.calculatedPremiumTax = pricingProduct.calculatedPremiumTax;
          updatedJacket.pricingDetail!.actualRemittance = pricingProduct.totalDue;
          updatedJacket.pricingDetail!.calculatedRemittance = pricingProduct.calculatedTotalDue;

          // add other required pricing details
          updatedJacket.pricingDetail!.altPricingReferenceID =
            pricingProduct.altPricingReferenceID;
          if (!isManualPricing) {
            updatedJacket.pricingDetail!.coverageType =
              pricingProduct.coverageType;
            updatedJacket.pricingDetail!.coverageTypeData =
              pricingProduct.coverageTypeData;
          }

          updatedJacket.endorsements?.forEach((e) => {
            const currentPI = pricingProduct.productItems?.find(
              (pi) => pi.filePricingDetailId === e.filePricingDetailID
            );
            if (currentPI) {
              e.pricingDetail!.altPricingReferenceID =
                currentPI?.altPricingReferenceID;
            }
            const ppi = pricingProduct.productItems.find(ppi => ppi.filePricingDetailId === e.filePricingDetailID);
            if (ppi) {
              e.pricingDetail!.actualFee = ppi.actualFee;
              e.pricingDetail!.calculatedFee = ppi.calculatedFee;
              e.pricingDetail!.actualRiskRate = ppi.actualRiskRate;
              e.pricingDetail!.calculatedRiskRate = ppi.calculatedRiskRate;
              e.pricingDetail!.calculatedRetention = ppi.calculatedRetention;
              e.pricingDetail!.actualPremiumTax = ppi.actualPremiumTax;
              e.pricingDetail!.calculatedPremiumTax = ppi.calculatedPremiumTax;
              e.pricingDetail!.altPricingReferenceID = ppi.altPricingReferenceID;
              e.pricingDetail!.actualRetention = ppi.agentRetention;
              e.pricingDetail!.calculatedRetention = ppi.calculatedRetention;
              e.pricingDetail!.actualRemittance = ppi.totalDue;
              e.pricingDetail!.calculatedRemittance = ppi.calculatedTotalDue;
              e.pricingDetail!.lastErrorMessage = ppi.lastErrorMessage;
              e.pricingDetail!.isFeeError = ppi.isFeeError;
              e.pricingDetail!.isManualFee = ppi.isManualFee;
            }
          });

          updatedJackets.push(updatedJacket);
        }
      });

    const scfile = getValues() as SCFile;
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      scfile,
      undefined,
      MapActionType.SaveJacketOPN,
      undefined,
      undefined,
      undefined,
      updatedJackets
    );

    setPricingNotificationUpdated(false);
    openAutomaticProgressDialog(UIConstants.SAVE_IN_PROGRESS_MESSAGE);

    const updatedPricingData: PricingDataResult = await processOPN(
      apiSCFile,
      currentPricingProduct?.orderId || 0,
      isIntegratedPricing,
      scfile.filePricingDetails
    );

    endorsementErrorMessageDispatch({ type: "RESET" });

    endorsementErrorMessageDispatch({
      type: "INITIALIZE",
      products: updatedPricingData.pricingProducts
    });

    updatePricingData(updatedPricingData);
    setPricingNotificationUpdated(true);
    setValue(`${basePathPolicy}.isValidationRequired`, false);
    setValue("isFileDefault", true);

    onSave();
    setIsSaving(false);

    closeAutomaticProgressDialog();
  };

  const getFieldsToValidate = (): string[] => {
    const fieldsToValidate = [
      ...InsertIf(showOPNPolicyDate, `${basePathPolicy}.date`),
      ...InsertIf(showOPNCoverageType, `${basePathPolicy}.coverageType`),
      ...InsertIf(showOPNLiabilityAmount, `${basePathPolicy}.liabilityAmount`),
      ...InsertIf(showOPNPolicyNumber, `${basePathPolicy}.policyNumber`),
      ...InsertIf(showOPNFormType, `${basePathPolicy}.jacketType`),
    ];

    return fieldsToValidate;
  };

  const handleClose = () => {
    clearErrors(getNameString(getFieldsToValidate()));
    setValue(basePathPolicy, initialOriginalPolicy.current);
    setValue(`${basePathPolicy}.isValidationRequired`, false);
    initialOriginalPolicy.current = undefined;

    onClose();
  };

  const getTitle = () => {
    const windowTitle = pricingConfigs?.find(
      (config: PricingConfig) =>
        config.configKey === PricingConfigKey.OPNWindowTitle
    )?.configValue;
    return windowTitle ? windowTitle : "Original Policy";
  };

  useEffect(() => {
    if (isOpen && currentPricingProduct) {
      initialOriginalPolicy.current = { ...currentPricingProduct.originalPolicy }; //  currentPricingProduct.originalPolicy; // rlo 6/12/2023 - CANNOT use assign here because it will point to the same reference
    }
    return () => {
      initialOriginalPolicy.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen || !currentPricingProduct || !showReissueEdit) return null;

  return (
    <OriginalPolicyDialog
      title={getTitle()}
      isOpen={isOpen}
      onSave={handleSave}
      onClose={handleClose}
      disabled={disabled}
      allowSave={allowSave || !isSaving}
    >
      <OriginalPolicyBody
        basePath={basePathPolicy}
        product={currentPricingProduct}
        showPolicyNumber={showOPNPolicyNumber} //{isManualPricing}
        showFormType={showOPNFormType} // {isManualPricing}
        showCoverageType={showOPNCoverageType} //{showCoverageType}
        showLiabilityAmount={showOPNLiabilityAmount}
        showPolicyDate={showOPNPolicyDate} //{showPolicyDate}
        disabled={disabled}
      />
    </OriginalPolicyDialog>
  );
}

export default PricingOriginalPolicyDialog;
