import React from "react";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import { Box } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

interface Props {
  schema: string;
  onChange?: any;
  callbackIndex?: number;
  onBlur?: () => void;
  disabled?: boolean;
}

const TogglePropertyAddress = ({ schema, onChange, callbackIndex, onBlur, disabled }: Props) => {
  const { setValue, getValues, control } = useFormContext();

  const fieldName: string = `${schema}.usePropertyAddress`;
  const shouldFieldBeDisabled = useFieldDisabler(fieldName) || disabled;

  const addressOneFieldName = `${schema}.addressOne`;
  const addressTwoFieldName = `${schema}.addressTwo`;
  const cityFieldName = `${schema}.city`;
  const stateFieldName = `${schema}.state`;
  const zipCodeFieldName = `${schema}.zipCode`;

  const handleOnChange = (_: React.ChangeEvent<any>, checked: boolean) => {
    setValue(fieldName, checked ? 1 : 0);

    if (checked) {
      const properties = getValues("properties.0");
      setValue(`${addressOneFieldName}` as const, properties.addressOne);
      setValue(`${addressTwoFieldName}` as const, properties.addressTwo);
      setValue(`${cityFieldName}` as const, properties.city);
      setValue(`${stateFieldName}` as const, properties.state);
      setValue(`${zipCodeFieldName}` as const, properties.zipCode);

      onChange && onChange(callbackIndex, "addressOne", properties.addressOne);
      onChange && onChange(callbackIndex, "addressTwo", properties.addressTwo);
      onChange && onChange(callbackIndex, "city", properties.city);
      onChange && onChange(callbackIndex, "state", properties.state);
      onChange && onChange(callbackIndex, "zipCode", properties.zipCode);
    } else {
      setValue(`${addressOneFieldName}` as const, "");
      setValue(`${addressTwoFieldName}` as const, "");
      setValue(`${cityFieldName}` as const, "");
      setValue(`${stateFieldName}` as const, "");
      setValue(`${zipCodeFieldName}` as const, "");

      onChange && onChange(callbackIndex, "addressOne", "");
      onChange && onChange(callbackIndex, "addressTwo", "");
      onChange && onChange(callbackIndex, "city", "");
      onChange && onChange(callbackIndex, "state", "");
      onChange && onChange(callbackIndex, "zipCode", "");
    }
    onBlur && onBlur();
  };

  return (
    <Box mr={4}>
      <Controller
        control={control}
        name={`${fieldName}` as const}
        defaultValue={0}
        render={({ field: { name, value } }) => (
          <CheckboxInput
            name={name}
            checked={!!value}
            label="Use property address"
            onChange={handleOnChange}
            disabled={shouldFieldBeDisabled}
          />
        )}
      />
    </Box>
  );
};

export default TogglePropertyAddress;
