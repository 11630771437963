import { Box, makeStyles, MenuItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { MultiSelectContainer } from "./MultiSelectContainer";
import OverflowTooltip from "controls/global/overflow-tooltip";
import React, { useState } from "react";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import { defaultStyle, colors, fontSize } from "theme/defaultStyle";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";

export interface MultiSelectFieldOption {
  value: string;
  text: string;
  selected?: boolean;
}

type Props = {
  label: string;
  name: string;
  options: MultiSelectFieldOption[];
  value?: string[];
  isError?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const usePaperStyles = makeStyles((theme) => ({
  paper: theme.custom.common.paperAlt,
  emptymenu: {
    backgroundColor: "transparent",
    pointerEvents: "none",
    color: "gray",
  },
  menuitem: {
    width: "100%",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "1px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "#F2F2F2",
      color: colors.grey06,
    },
  },
  checkedIcon: {
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -5 30 30'%3E%3Cpolygon" +
        " points='23.8,4 18.9,0 9.1,11.7 4.1,7.6 0,12.3 9.7,20.4 ' fill='%23ADADAD'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  listItemText: {
    fontSize: fontSize.xlarge,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  error: {
    color: "red",
    position: "absolute",
  },
  checkbox: {
    "& .MuiIconButton-label > span": {
      border: `1px solid ${defaultStyle.checkbox.borderColor}`,
      borderRadius: defaultStyle.checkbox.borderRadius,
      "&::before": {
        marginTop: "-1px",
        marginLeft: "-1px",
      },
    },
  },
}));

const MultiSelectInputField = ({
  label,
  name,
  options,
  value: selectedItemsValue,
  isError = false,
  disabled = false,
  onChange,
}: Props) => {
  const classes = usePaperStyles();
  const [open, setOpen] = useState(false);

  const getRenderValue = (selected: any) => {
    if (options.length === 0 || !selected || selected.length === 0) return "";

    const fieldText = selected
      .map((selectedVal: string) => getSelectedValueName(selectedVal))
      .filter((x: string) => Boolean(x) || (x && x.trim() !== ""))
      .join(", ");

    return <OverflowTooltip tooltip={fieldText} text={fieldText} />;
  };

  const getSelectedValueName = (value: string): string | undefined => {
    return options.find((option) => option.value === value)?.text;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const selectedItems = event.target.value;
    if (selectedItems.length > 1) {
      setOpen(true);
      return;
    }

    onChange?.(event);
  };

  return (
    <div>
      <MultiSelectContainer
        name={name}
        label={label}
        disabled={disabled}
        select
        error={isError}
        fullWidth
        inputProps={{
          "aria-errormessage": isError ? "rhfvalidationerror" : "",
        }}
        variant="outlined"
        InputProps={{ disableUnderline: true }}
        SelectProps={{
          MenuProps: {
            classes: {
              paper: classes.paper,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          },
          multiple: true,
          value: selectedItemsValue,
          renderValue: getRenderValue,
        }}
        onChange={handleChange}
      >
        {options.length ? (
          options.map((opt) => (
            <MenuItem
              key={`multi-select-${opt.value}`}
              value={opt.value}
              style={{ background: "white", color: "black" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CheckboxInput
                  checked={
                    selectedItemsValue &&
                    selectedItemsValue.indexOf(opt.value) > -1
                  }
                  name={name}
                  className={classes.checkbox}
                />
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={opt.text}
                />
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem className={classes.emptymenu}>NO DATA FOUND</MenuItem>
        )}
      </MultiSelectContainer>
      <ErrorDialog
        isOpen={open}
        confirmationMessage="Only one additional party is allowed."
        onYes={() => setOpen(false)}
        onNo={() => setOpen(false)}
      />
    </div>
  );
};

export default MultiSelectInputField;
