import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/styles/makeStyles";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import React from "react";
import { defaultStyle } from "theme/defaultStyle";

type Props = {
  fullName: string;
  showColumn: boolean;
  cellCss: string;
  isShortForm: boolean;
  disabled: boolean;
  onChecked: (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const useStyles = makeStyles({
  checkbox: {
    "& .MuiIconButton-label > span": {
      border: `1px solid ${defaultStyle.checkbox.borderColor}`,
      borderRadius: defaultStyle.checkbox.borderRadius,
      "&::before": {
        marginTop: "-1px",
        marginLeft: "-1px",
      },
    },
  },
  maxWidth: {
    maxWidth: "19px",
  }
});

export default function EndorsementCheckboxCell({
  fullName,
  showColumn,
  cellCss,
  isShortForm,
  disabled,
  onChecked,
}: Props) {
  const classes = useStyles();

  if (!showColumn) return null;
  if (!isShortForm) {
    return <TableCell classes={{ sizeSmall: cellCss }} />;
  }

  return (
    <TableCell classes={{ sizeSmall: cellCss }} width="55px" align="center">
      <CheckboxInputField
        label=""
        name={fullName}
        onChange={onChecked}
        disabled={disabled}
        className={classes.checkbox}
        defaultValue={false}
        styledWrapper={false}
      />
    </TableCell>
  );
}
