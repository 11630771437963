import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { 
  gaps, 
  margin,
 } from "theme/defaultStyle";
import ActionButton from "controls/global/action-button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

const ActionButtonGroup = styled(Box)({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiSvgIcon-root": {
    height: "18px",
    marginLeft: margin.small,
    width: "16px",
  },
});

const AgencyFieldHeader = () => {
  const navigate = useNavigate();
  const [, { setUserSelectedAgency }] = useGlobalAccess();

  const navigateTo = () => {
    setUserSelectedAgency([]);
    navigate("/Home");
  };

  return (
    <ActionButtonGroup>
      <ActionButton
        variant="outlined"
        color="secondary"
        onClick={navigateTo}
      >
        Skip
        <ArrowForwardIcon />
      </ActionButton>
    </ActionButtonGroup>
  );
};

export default AgencyFieldHeader;
