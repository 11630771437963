import {
  Box,
} from "@material-ui/core";
import ProgressbarDialog from "controls/global/dialogs/confirmation-dialog-with-progressbar/ProgressbarDialog";
import StewartDialog from "controls/global/dialogs/stewart-dialog/StewartDialog";
import React from "react";
import { usePendingPay } from "utils/context/PendingPayContext";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import PendingPayFooterTotal from "../pendingpay-footer-total";
import PendingPayFooterAction from "../pendingpay-footer-action";

type FooterProps = {
  rows: PendingPayFile[];
  onlyShowSelectedFiles: boolean;
  page: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOnlySelectedFilesChange?: (value: boolean) => void;
};

const PendingPayFooter = ({
  rows,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: FooterProps) => {
  const [{ isAdvSearchOpen }] = usePendingPay();

  const noSelectedRows = !rows.length || isAdvSearchOpen;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PendingPayFooterAction
          disabled={noSelectedRows}
          onlyShowSelectedFiles={onlyShowSelectedFiles}
          onOnlySelectedFilesChange={(value: boolean) =>
            onOnlySelectedFilesChange?.(value)}
        />
        <PendingPayFooterTotal rows={rows} />
      </Box>
      <StewartDialog />
      <ProgressbarDialog />
    </>
  );
};

export default PendingPayFooter;
