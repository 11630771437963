import {
  Paper,
  styled,
  TableContainer,
} from "@material-ui/core";
import React from "react";
import {
  margin,
  padding,
} from "theme/defaultStyle";

import SignatureDefaultsContent from "./SignatureDefaultsContent";

const StyledPaper = styled(Paper)({
  backgroundColor: "transparent",
  boxShadow: "none",
  marginTop: margin.xsmall1,
  paddingBottom: padding.zero,
  paddingLeft: padding.xsmall,
  paddingRight: padding.xsmall,
  paddingTop: padding.zero,
});

const SignatureDefaultsTable = () => {
  return (
    <TableContainer component={StyledPaper}>
      <SignatureDefaultsContent />
    </TableContainer>
  );
};

export default SignatureDefaultsTable;
