import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import StewartDatePicker from "controls/global/stewart-datepicker";
import React from "react";
import { formatDate } from "utils/shared";

type Props = {
  defaultValue?: string; //ADD SUPPORT FOR REVISION
  onChange?: (value: string) => void;
  fullWidth?: boolean;
  size?: "small" | "medium";
};

export default function DateField({ defaultValue, onChange, size = "medium", fullWidth = true }: Props) {
  const [selectedDate, setDate] = React.useState<MaterialUiPickersDate>(null);
  const [inputValue, setInputValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!defaultValue) return;
    setDate(new Date(defaultValue));
  }, []);

  const handleChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    setDate(date);
    setInputValue(value ?? "");
    onChange?.(date ? formatDate(date || new Date()) : "");
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StewartDatePicker
        fullWidth={fullWidth}
        disableToolbar
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        placeholder="mm/dd/yyyy"
        inputValue={inputValue}
        value={selectedDate}
        InputProps={{
          disableUnderline: true,
        }}
        size={size}
        keyboardIcon={<CalendarTodayOutlinedIcon />}
        error={false}
        helperText={null}
        // style={{ width: "30%" }}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
}
