import React, {
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import StewartDatePicker from "controls/global/stewart-datepicker";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import {
  dateWithoutTime,
  addLeadingZerosToDate,
} from "utils/shared";
import {
  fontSize,
  padding,
} from "theme/defaultStyle";

const useStyles = makeStyles({
  datepicker: {
    "& div.MuiInputBase-root": {
      border: "1px solid transparent",
    },
    "& div.MuiInputBase-root:hover": {
      border: "1px solid transparent",
    },
    "& input.MuiInputBase-input": {
      fontSize: fontSize.large,
      padding: padding.small2,
    },
    "& .MuiInputAdornment-positionEnd": {
      margin: 0,
      "& .MuiIconButton-root": {
        padding: padding.small2,
      },
    },
  },
});

export interface Props {
  value?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  hasError?: boolean;
  header?: boolean; //TRUE: DISPLAYED AS A COLUMN FILTER
  handleDateChange: (newDate: Date | null) => void;
  onError?: (error: boolean) => void;
  size?: "small" | "medium";
  reset?: number;
}

const DatePickerSearch = ({
  value,
  minDate,
  maxDate,
  hasError,
  header,
  handleDateChange,
  onError,
  size = "medium",
  reset,
}: Props) => {
  const classes = useStyles();
  const [date, setDate] = useState<Date | null>(null);

  const isValidDate = (date: Date | null) =>
    date ? !isNaN(date?.getTime()) : false;

  const handleChange = (selectedDate: Date | null) => {
    const isValid = isValidDate(selectedDate);
    const updatedDate = selectedDate && isValid ? dateWithoutTime(selectedDate) : selectedDate;

    if (isValid && onError) onError(false);
    setDate(updatedDate);
    handleDateChange(updatedDate);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!date || !isNaN(date.getTime())) return;

    const input = event.target.value;
    const inputDate = addLeadingZerosToDate(input);

    if (!inputDate || !isValidDate(inputDate)) {
      onError && onError(true);
      setDate(null);
      handleDateChange(null);
      return;
    }

    onError && onError(false);
    setDate(inputDate);
    handleDateChange(inputDate);
  };

  useEffect(() => {
    setDate(value ? value : null);
  }, [reset]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StewartDatePicker
        disableToolbar
        fullWidth
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        placeholder="mm/dd/yyyy"
        className={header ? classes.datepicker : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={null}
        InputProps={{ disableUnderline: true }}
        keyboardIcon={<CalendarTodayOutlinedIcon />}
        value={date}
        size={size}
        error={hasError}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerSearch;
