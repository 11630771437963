import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ActualFeeNewTotal from "./new-totals-all/ActualFeeNewTotal";
import RiskRateNewTotal from "./new-totals-all/RiskRateNewTotal";
import AgentRetentionNewTotal from "./new-totals-all/AgentRetentionNewTotal";
import TotalDueNewTotal from "./new-totals-all/TotalDueNewTotal";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";

const StyledTableFooterCell = styled(TableCell)({
  fontWeight: 600,
  fontSize: "22px",
  paddingRight: "17px",
  color: "black",
  borderBottom: "none",
  textOverFlow: "ellipses",
  overflow: "hidden",
  borderTop: "1px solid rgba(0, 0, 0, 1)",
});

const useStyles = makeStyles({
  minNumberWidthTotal: {
    width: "250px !important",
    margin: "0px 5px 0px 0px",
    fontSize: "22px !important",
  },
  totalDueNoUnderline: {
    fontSize: "22px",
    // borderBottom: "5px solid #FFFFFF !important",
    paddingBottom: "5px",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  },
  newTotalRow: {
    backgroundColor: "white",
  },
});

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isPricingSectionUpdated: boolean;
};

export default function NewTotalAll({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isPricingSectionUpdated,
}: Props) {
  const classes = useStyles();
  const { getPricingNewTotal } = usePricingCalculator();
  const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);

  return (
    <TableRow className={classes.newTotalRow}>
      <StyledTableFooterCell style={{ paddingLeft: "0" }}>
        <div
          className={`${classes.totalDueNoUnderline} ${classes.minNumberWidthTotal}`}
        >
          New Totals
        </div>
      </StyledTableFooterCell>
      {displayFieldIcon && <StyledTableFooterCell />}
      {displayLiabilityColumn && <StyledTableFooterCell />}
      {displayRateTypeColumn && <StyledTableFooterCell />}
      {displayReissueColumn && <StyledTableFooterCell />}
      {displayTransCodeColumn && <StyledTableFooterCell />}
      <ActualFeeNewTotal hasIssuedProducts={hasIssuedProducts} newTotalActualDue={pricingNewTotal.newTotalActualDue} />
      {displayRiskRateColumn ? (
        <RiskRateNewTotal hasIssuedProducts={hasIssuedProducts} newTotalRiskRate={pricingNewTotal.newTotalRiskRate} />
      ) : null}
      <AgentRetentionNewTotal hasIssuedProducts={hasIssuedProducts} newTotalAgentRetention={pricingNewTotal.newTotalAgentRetention}  />
      <TotalDueNewTotal hasIssuedProducts={hasIssuedProducts} newTotalTotalDue={pricingNewTotal.newTotalTotalDue} />
    </TableRow>
  );
}
