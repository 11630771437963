import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import { Add } from "@mui/icons-material";
import React from "react";
import {
  boxShadows,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  onClick: React.MouseEventHandler;
  ref?: React.MutableRefObject<HTMLElement | null>;
  style?: React.CSSProperties;
  onKeyDown?: (event: KeyboardEvent) => void;
};

const StyledButtonLink = styled(Button)({
  background: "none",
  border: `${defaultStyle.control.borderSize} solid ${colors.blue01}`,
  boxShadow: boxShadows.boxShadow01,
  color: colors.blue01,
  cursor: "pointer",
  fontSize: fontSize.small1,
  margin: margin.zero,
  paddingBottom: padding.xsmall1,
  paddingLeft: padding.small2,
  paddingRight: padding.small2,
  paddingTop: padding.xsmall1,
  textTransform: "none",
  "&.Mui-disabled": {
    background: colors.grey10,
    border: `${defaultStyle.control.borderSize} solid ${colors.blue09}`,
    color: colors.blue09,
    opacity: "unset",
    "& .MuiButton-label": {
      "& .MuiSvgIcon-root": {
        color: colors.blue09,
      },
    },
  },
  "&:hover": {
    background: "none",
    color: colors.blue03,
  },
  "& .MuiButton-label": {
    alignItems: "center",
    display: "flex",
    fontWeight: fontWeight.normal2,
    gap: gaps.small1,
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: colors.blue01,
      fontSize: iconSize.small2,
      marginLeft: "-4px",  // Offset icon to help centering.
    },
  },
});

const AddLink = ({ onClick, disabled, children, onKeyDown }: Props) => {
  return (
    <StyledButtonLink
      onClick={onClick}
      disabled={disabled}
      onKeyDown={(evt: any) => {
        onKeyDown && onKeyDown(evt);
      }}
    >
      <Add />
      {children}
    </StyledButtonLink>
  )
}

export default AddLink;
