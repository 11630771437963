import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import capitalize from "@material-ui/core/utils/capitalize";
import DatePickerSearch from "controls/global/datepicker-search/DatePickerSearch";
import MultiLineCell from "controls/global/stewart-table/MultiLineCell";
import StewartTable, {
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { FileSearchCriteria } from "entities/ApiModel";
import { IColumn } from "entities/ApiModel/IColumn";
import { FilesRecord } from "entities/UIModel";
import React from "react";
import {
  colors,
  fontWeight,
  gaps,
} from "theme/defaultStyle";
import { getColumnConfig } from "utils/columnsGrid";
import { FileStatusType } from "utils/data/enum";
import {
  formatDate,
} from "utils/shared";
import { Order } from "utils/sorting";
import InputSearch from "../input-search";
import SelectStatus from "../select-status";

type Props = {
  colsConfig: IColumn[];
  rows: FilesRecord[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  noRowsMessage: string[];
  fileStatusTypes: FileStatusType[];
  page: number;
  hiddenColumns?: (keyof FilesRecord)[];
  onFiltersChange: (name: keyof FileSearchCriteria, value: any) => void;
  onPageChange: (page: number) => void;
  showActionRow: boolean;
  order: Order;
  orderBy?: keyof FilesRecord;
  onRequestSort?: (property: string, disableToggling?: boolean) => void;
  onColumnResize: (column: keyof FilesRecord, newWidth: number) => void;
  onRowsPerPageChange: (count: number) => void;
};

const TableWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& tr:hover td": {
    background: colors.grey10,
  }
});

const StyledLink = styled(Link)({
  fontWeight: fontWeight.bold2,
  cursor: "pointer",
  color: colors.blue01,
  textDecoration: "underline",
});

export default function MyFilesGrid({
  colsConfig,
  rows,
  rowsPerPage,
  loadingProgressPercent,
  noRowsMessage,
  fileStatusTypes,
  page,
  hiddenColumns,
  onPageChange,
  onFiltersChange,
  showActionRow,
  order,
  orderBy,
  onRequestSort,
  onColumnResize,
  onRowsPerPageChange,
}: Props) {

  const columns: StewartTableColumn<FilesRecord>[] = [
    {
      field: "fileNumber",
      actionComponent: () => (
        <InputSearch name="clientFileID" onChange={onFiltersChange} />
      ),
      valueGetter: ({ fileNumber, fileId }: FilesRecord) => (
        <TooltipCell title={fileNumber}>
          <StyledLink
            to={"/file"}
            state={{ fileID: fileId }}
          >
            {fileNumber}
          </StyledLink>
        </TooltipCell>
      ),
      ...getColumnConfig<FilesRecord>(colsConfig, "fileNumber"),
    },
    {
      field: "propertyAddress",
      actionComponent: () => (
        <InputSearch name="propertyAddress" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => (
        <TooltipCell title={row.propertyAddress} />
      ),
      ...getColumnConfig<FilesRecord>(colsConfig, "propertyAddress"),
    },
    {
      field: "buyerBorrower",
      actionComponent: () => (
        <InputSearch name="buyerName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => (
        <TooltipCell title={row.buyerBorrower} />
      ),
      ...getColumnConfig<FilesRecord>(colsConfig, "buyerBorrower"),
    },
    {
      field: "agencyID",
      actionComponent: () => (
        <InputSearch name="agencyID" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.agencyID} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "agencyID"),
    },
    {
      field: "locationDisplayName",
      actionComponent: () => (
        <InputSearch name="locationDisplayName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.locationDisplayName} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "locationDisplayName"),
    },
    {
      field: "legacyID",
      actionComponent: () => (
        <InputSearch name="legacyID" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <MultiLineCell names={row.legacyID} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "legacyID"),
    },
    {
      field: "agencyName",
      actionComponent: () => (
        <InputSearch name="agencyName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.agencyName} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "agencyName"),
    },
    {
      field: "seller",
      actionComponent: () => (
        <InputSearch name="sellerName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.seller} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "seller"),
    },
    {
      field: "lender",
      actionComponent: () => (
        <InputSearch name="lenderName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.lender} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "lender"),
    },
    {
      field: "status",
      actionComponent: () => (
        <SelectStatus
          key={`statuses-${fileStatusTypes}`}
          name="fileStatusTypeCode"
          defaultValue={fileStatusTypes}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: FilesRecord) => {
        const text =
          row.status === FileStatusType.ReportPending
            ? "REPORTED/PENDING"
            : row.status;
        return <TooltipCell title={capitalize(text)} />;
      },
      ...getColumnConfig<FilesRecord>(colsConfig, "status"),
    },
    {
      field: "productNames",
      actionComponent: () => (
        <InputSearch name="productName" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => (
        <MultiLineCell names={row.productNames} />
      ),
      ...getColumnConfig<FilesRecord>(colsConfig, "productNames"),
    },
    {
      field: "openedTimestamp",
      actionComponent: () => (
        <DatePickerSearch
          header
          handleDateChange={(date: Date | null) => {
            onFiltersChange("dateRangeStart", date);
          }
          }
        />
      ),
      valueGetter: (row: FilesRecord) => formatDate(row.opened),
      ...getColumnConfig<FilesRecord>(colsConfig, "openedTimestamp"),
    },
    {
      field: "stateAbbr",
      actionComponent: () => (
        <InputSearch name="stateAbbr" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.stateAbbr} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "stateAbbr"),
    },
    {
      field: "propertyTypeCode",
      actionComponent: () => (
        <InputSearch name="propertyTypeCode" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.propertyTypeCode} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "propertyTypeCode"),
    },
    {
      field: "transactionTypeCode",
      actionComponent: () => (
        <InputSearch name="transactionTypeCode" onChange={onFiltersChange} />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.transactionTypeCode} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "transactionTypeCode"),
    },
  ];

  return (
    <TableWrapper>
      <StewartTable
        cols={columns}
        rows={rows}
        rowsPerPage={rowsPerPage}
        loadingProgressPercent={loadingProgressPercent}
        noRowsMessage={noRowsMessage}
        page={page}
        hiddenColumns={hiddenColumns}
        showActionRow={showActionRow}
        useDbPagingSorting={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onColumnResize={onColumnResize}
      />
      <StewartTableFooterPaginator
        totalRows={rows[0]?.totalRows ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={rows[0]?.totalPages ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableWrapper>
  );
}
