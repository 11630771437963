import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors, fontSize, fontWeight, margin } from "theme/defaultStyle";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const useStyles = makeStyles({
  errorMessage: {
    marginLeft: "50px",
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    color: colors.red01,
  },
});

function ErrorMessage() {
  const classes = useStyles();

  return (
    <Box className={classes.errorMessage}>
      <ReportProblemIcon style={{ fontSize: 20, color: colors.red01, marginRight: margin.small }} />
      Please correct the error(s) below to continue.
    </Box>
  )
}

export default ErrorMessage;
