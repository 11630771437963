import React from 'react';

const ReleaseNoteIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 15H3V17H15V15ZM15 7H3V9H15V7ZM3 13H21V11H3V13ZM3 21H21V19H3V21ZM3 3V5H21V3H3Z"
          fill="url(#paint0_linear_115_3850)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_115_3850"
            x1="12"
            y1="3"
            x2="12"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9E2339" />
            <stop offset="1" stopColor="#620000" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default ReleaseNoteIcon;
