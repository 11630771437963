import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import SaveIcon from "@mui/icons-material/Save";
import ActionButton from "controls/global/action-button";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React,
{
  useRef,
} from "react";
import {
  gaps,
  margin,
} from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import SignatureDefaultForm from "../default-form/SignatureDefaultForm";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: gaps.small1,
  justifyContent: 'center',
  marginTop: margin.small,
});

const SignatureDefaultDialog = () => {
  const classes = useDialogStyles({ type: "INFO", height: 700 });
  const {
    mode,
    isOpen,
    saveEnabled,
    cancelDialog,
  } = useSignatureDefaultDialogProvider();
  const [, { resetState }] = useSignatureDefaults();
  const saveRef = useRef<HTMLButtonElement>(null);

  const handleCancel = () => {
    cancelDialog();
    resetState();
  };

  const handleSave = () => {
    saveRef && saveRef.current?.click();
  };

  return (
    <Dialog
      open={isOpen}
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        disableTypography
        classes={{ root: classes.dialogTitleRoot }}
      >
        {mode === "EDIT" ? "Edit Default Signature" : "Set Default Signature"}
        <DialogTitleCloseButton onClick={handleCancel}/>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot, }}>
        <SignatureDefaultForm saveRef={saveRef} />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot, }}>
        <ButtonsWrapper>
          <ActionButton
            variant="outlined"
            color="primary"
            disabled={!saveEnabled}
            onClick={handleSave}
            endIcon={<SaveIcon />}
          >
            Save default
          </ActionButton>
        </ButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureDefaultDialog;
