export const parseNumber = (x: any) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1], 10);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1], 10);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const getRoundedValue = (value?: string) => {
  if (!value) return;

  const sanitisedValue = Array.from(value).reduce((currentVal, char, index) => {
    if (
      ((char >= "0" && char <= "9") ||
        (char === "." && !currentVal.includes(".")) ||
        (char === "-" && index === 0)) &&
      (char === "." ? index !== 0 : true)
    ) {
      currentVal += char;
    }
    return currentVal;
  }, "");

  if (!sanitisedValue) {
    return sanitisedValue;
  }

  const integer = Math.trunc(Number(sanitisedValue));
  let decimalValue = value.split(".")[1];
  decimalValue = decimalValue && decimalValue.substring(0, 2);

  return decimalValue && parseNumber(decimalValue)
    ? parseNumber(`${integer}.${decimalValue}`)
    : sanitisedValue;
};

export const getImageType = (base64Data: string) => {
  return base64Data.substring("data:image/".length, base64Data.indexOf(";base64")).toUpperCase();
}