import {
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import React from "react";
import styled from "styled-components";
import theme from "theme/default";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";

interface IProps {
  isValidLogin: boolean;
  saveFileChangeRef?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      color: colors.white,
      display: "flex",
      gap: gaps.xsmall2,
      padding: "0",
      "& .username-container": {
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal1,
        textTransform: "initial",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    paper: {
      borderRadius: borderRadius.xlarge,
    },
    menuContainer: {
      background: colors.white,
      borderRadius: borderRadius.xlarge,
      minWidth: "160px",
      overflow: "inherit",
      top: "28px !important",
      zIndex: zIndex.high1,
      "&:after, &:before": {
        border: "solid transparent",
        bottom: "100%",
        content: `""`,
        height: "0",
        left: "50%",
        pointerEvents: "none",
        position: "absolute",
        width: "0",
      },
      "&:after": {
        borderBottomColor: colors.white,
        borderColor: "transparent",
        borderWidth: "10px",
        marginLeft: "-10px",
      },
      "&:before": {
        borderBottomColor: colors.white,
        borderColor: "transparent",
        borderWidth: "16px",
        marginLeft: "-16px",
      },
    },
    menuItem: {
      borderRadius: borderRadius.small,
      color: colors.blue01,
      margin: "auto",
      padding: padding.large1,
      width: "90%",
      "&:hover": {
        background: gradients.blueGradient03,
        color: colors.white,
      },
    },
  })
);

const StyledButton = styled(Button)({
  color: colors.white,
  minWidth: iconSize.small1,
  padding: padding.zero,
  "& .MuiButton-label": {
    color: colors.white,
    display: "flex",
    flexDirection: "row",
    gap: gaps.xsmall2,
    minWidth: iconSize.small1,
    padding: padding.zero,
    "& .username-container": {
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
      textTransform: "initial",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const EditProfileLink = ({ isValidLogin, saveFileChangeRef }: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { featureConfig: { enableSignatures, enableHelpCenter } } = useConfigContext();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { redirect } = useNavRedirect();

  const username = account && account.name ? account?.name.split(" ") : [];
  // TODO: Change this to use a named breakpoint instead of a number once the new layout breakpoints are determined.
  const showUserName = useMediaQuery(theme.breakpoints.up(1440));
  const isWideEnoughToUnconditionallyShowIcon = useMediaQuery(theme.breakpoints.up("lg"));
  const longUserName = account?.name ? account?.name.length > 18 : false;
  const showAccountIcon = isWideEnoughToUnconditionallyShowIcon || (!showUserName || (showUserName && !longUserName));
  const [, { clearUserSelectedAgency }] = useGlobalAccess();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(false);
    instance.logout();
    clearUserSelectedAgency();
  };

  const handleProfile = () => {
    redirect("/profile-settings", saveFileChangeRef);
    setOpen(false);
  };

  const handleSignature = () => {
    redirect("/manage-signatures", saveFileChangeRef);
    setOpen(false);
  };

  const handleHelpCenter = () => {
    redirect("/help-center", saveFileChangeRef);
    setOpen(false);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <StyledButton onClick={handleToggle} ref={anchorRef}>
        {showAccountIcon &&
          <AccountCircleIcon />}
        {showUserName &&
          <Box className="username-container">
            {username && username.length > 0 ? username[0] : ""}{" "}
            {username && username.length > 1 ? username[1] : ""}
          </Box>
        }
        {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </StyledButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuContainer}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {!!isValidLogin && (
                    <MenuItem
                      onClick={handleProfile}
                      className={classes.menuItem}
                    >
                      Profile Settings
                    </MenuItem>
                  )}
                  {enableSignatures && !!isValidLogin && (
                    <MenuItem
                      onClick={handleSignature}
                      className={classes.menuItem}
                    >
                      Manage Signatures
                    </MenuItem>
                  )}
                  {enableHelpCenter && !!isValidLogin && (
                    <MenuItem
                      onClick={handleHelpCenter}
                      className={classes.menuItem}
                    >
                      Help Center
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogout} className={classes.menuItem}>
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default EditProfileLink;
