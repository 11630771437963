import { Box } from "@material-ui/core";
import React from "react";

type Props = {
  children?: React.ReactNode;
  index: any;
  value: any;
  padding: string;
  classes?: any;
};

const TabPanel = ({ children, value, index, padding, classes, ...other }: Props) => (
  <div
    className={classes}
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index ? (
      <Box p={padding}>
        <Box>{children}</Box>
      </Box>
    ) : null}
  </div>
);

export default TabPanel;
