import React from "react";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.black,
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold1,
  maxWidth: "170px",
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
});

const useStyles = makeStyles({
  totalDueNoUnderline: {
    display: "inline-block",
    fontSize: fontSize.xlarge1,
    maxWidth: "100%",
    overflow: "hidden",
    paddingBottom: padding.xsmall2,
    textOverflow: "ellipsis",
  },
});

type Props = {
  hasIssuedProducts: boolean;
  newTotalRiskRate: string;
};

export default function RiskRateTotal({ hasIssuedProducts, newTotalRiskRate }: Props) {
  const classes = useStyles();

  return (
    <StyledTableFooterCell align="right">
      {hasIssuedProducts &&
        <TooltipCell
          className={`${classes.totalDueNoUnderline}`}
          title={newTotalRiskRate}
        />
      }
    </StyledTableFooterCell>
  );
}
