import { Box } from "@material-ui/core";
import { styled } from "@mui/material";
import React from "react";
import { gaps } from "theme/defaultStyle";
import { ReviseAction } from "../jacket/JacketButtonSection";
import LetterButtons from "./LetterButtons";

type Props = {
  onIssueLetter?: any;
  onViewLetter?: any;
  onVoidLetter?: any;
  orderStatusTypeCode?: string;
  issueButtonDisabled?: boolean;
  voidButtonDisabled?: boolean;
  revising: boolean;
  onReviseLetter: (action: ReviseAction) => void;
};

const ButtonsContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
  height: "100%",
  justifyContent: "flex-end",
});

const LetterButtonSection = ({
  onIssueLetter,
  onReviseLetter,
  onViewLetter,
  onVoidLetter,
  orderStatusTypeCode,
  issueButtonDisabled,
  voidButtonDisabled,
  revising,
}: Props) => {

  return (
    <ButtonsContainer>
      <LetterButtons
        {...{
          onIssueLetter,
          onReviseLetter,
          onViewLetter,
          onVoidLetter,
          orderStatusTypeCode,
          issueButtonDisabled,
          voidButtonDisabled,
          revising,
        }}
      />
    </ButtonsContainer>
  );
};

export default LetterButtonSection;
