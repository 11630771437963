import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { isEqual } from "lodash";
import React, {
  PropsWithChildren,
  ReactNode,
} from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingTable from "utils/custom-hooks/usePricingTable";
import { PricingConfigKey } from "utils/data/enum";

type HeaderProps = {
  hasIssuedProducts: boolean,
  displayLiabilityColumn: boolean,
  displayRateTypeColumn: boolean,
  displayReissueColumn: boolean,
  displayRiskRateColumn: boolean,
  displayTransCodeColumn: boolean,
  showTax: boolean,
  displayFieldIcon: boolean,
};

type CellProps = {
  children?: ReactNode,
  minWidth: string,
  isCurrency?: boolean,
};

const HeaderCell = ({ children, minWidth, isCurrency }: CellProps) => {
  return (
    <TableCell align={isCurrency ? "right" : "left"}>
      <div style={{ minWidth }}>
        {children}
      </div>
    </TableCell>
  );
};

function PricingTableHeader({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayRiskRateColumn,
  displayTransCodeColumn,
  showTax,
  displayFieldIcon,
}: HeaderProps) {

  const { getValues } = useFormWrapper();
  const { getColumnNameByConfigKey } = usePricingTable();
  const showReissueColumnLabel = getValues("pricing.showReissueColumnLabel")
  return (
    <TableHead>
      <TableRow>
        <HeaderCell minWidth="400px">
          Product
        </HeaderCell>
        {hasIssuedProducts &&
          <>
            {displayFieldIcon && (
              <HeaderCell minWidth="80px" />
            )}
            {displayLiabilityColumn && (
              <HeaderCell
                isCurrency
                minWidth="130px"
              >
                Liability Amount
              </HeaderCell>
            )}
            {displayRateTypeColumn && (
              <HeaderCell minWidth="75px">
                Rate Type
              </HeaderCell>
            )}
            {displayReissueColumn && (
              <HeaderCell minWidth="75px">
                {showReissueColumnLabel
                  ? getColumnNameByConfigKey(
                    PricingConfigKey.ReIssueLabelText,
                    "Reissue"
                  )
                  : ""}
              </HeaderCell>
            )}
            {displayTransCodeColumn && (
              <HeaderCell minWidth="90px">
                Trans Code
              </HeaderCell>
            )}
            <HeaderCell
              isCurrency
              minWidth="80px"
            >
              {showTax ? "Premium/Tax" : "Premium/Fee"}
            </HeaderCell>
            {displayRiskRateColumn && (
              <HeaderCell
                isCurrency
                minWidth="75px"
              >
                Risk Rate
              </HeaderCell>
            )}
            <HeaderCell
              isCurrency
              minWidth="130px"
            >
              Agent Retention
            </HeaderCell>
            <HeaderCell
              isCurrency
              minWidth="75px"
            >
              Total Due
            </HeaderCell>
          </>}
      </TableRow>{" "}
    </TableHead>
  );
}

export default React.memo(PricingTableHeader, ((prevProps: Readonly<PropsWithChildren<HeaderProps>>, nextProps: Readonly<PropsWithChildren<HeaderProps>>) => {
  const previous = {
    hasIssuedProducts: prevProps.hasIssuedProducts,
    displayLiabilityColumn: prevProps.displayLiabilityColumn,
    displayRateTypeColumn: prevProps.displayRateTypeColumn,
    displayReissueColumn: prevProps.displayReissueColumn,
    displayRiskRateColumn: prevProps.displayRiskRateColumn,
    displayTransCodeColumn: prevProps.displayTransCodeColumn,
    showTax: prevProps.showTax,
    displayFieldIcon: prevProps.displayFieldIcon,
  };

  const next = {
    hasIssuedProducts: nextProps.hasIssuedProducts,
    displayLiabilityColumn: nextProps.displayLiabilityColumn,
    displayRateTypeColumn: nextProps.displayRateTypeColumn,
    displayReissueColumn: nextProps.displayReissueColumn,
    displayRiskRateColumn: nextProps.displayRiskRateColumn,
    displayTransCodeColumn: nextProps.displayTransCodeColumn,
    showTax: nextProps.showTax,
    displayFieldIcon: nextProps.displayFieldIcon,
  };
  return isEqual(previous, next);
}));
