import AgencyIdField from "./AgencyIdField";
import ComboBoxInputField from "controls/global/combo-box-field/ComboBoxInputField";
import { Grid } from "@material-ui/core";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
  useState,
} from "react";

interface AgencyFormFieldProps {
  agencies: SelectFieldOption[];
  methods: any;
  currentAgencyId: string;
  isOpen: boolean;
  name: string;
}

export default function AgencyFormField({ agencies, methods, currentAgencyId, isOpen, name }: AgencyFormFieldProps) {
  const { setValue, getValues, } = methods;
  const [selectedAgency, setSelectedAgency] = useState<SelectFieldOption | null>(null);
  const agencyField = getValues("agency");

  const handleAgencyChange = (_: React.ChangeEvent<{}>, value: any) => {
    setSelectedAgency(value);
    if(value?.legacyID)setValue("agencyID", value.legacyID)
    else {
      setValue("agencyID", "");
    } 
  };
 
  const handleAgencyBlur = (e: any) => {
    setValue("agencyName", e.target.value);
  };

  useEffect(() => {
    const selectedAgency = agencyField;
    setSelectedAgency(selectedAgency);
  }, [agencies]);

  useEffect(() => {
    if (selectedAgency?.legacyID) {
      setValue("agencyCode", selectedAgency?.value);
      setValue("agencyName", selectedAgency?.text);
    }
  }, [selectedAgency]);

  return (
    <>
      <Grid
        item
        xs={7}
      >
        <ComboBoxInputField
          options={agencies}
          name={"agency"}
          label="Agency Name"
          onChange={handleAgencyChange}
          onBlur={handleAgencyBlur}
          placeholder="Agency Name"
          freeSolo
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AgencyIdField
          selectedAgency={selectedAgency}
          setValue={setValue}
        />
      </Grid>
    </>
  );
}
