import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import React from "react";
import {
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import ActualFeeTotal from "./totals-all/ActualFeeTotal";
import AgentRetentionTotal from "./totals-all/AgentRetentionTotal";
import RiskRateTotal from "./totals-all/RiskRateTotal";
import TotalDueTotal from "./totals-all/TotalDueTotal";

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  isPricingSectionUpdated: boolean;
};

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  fontWeight: fontWeight.bold2,
  overflow: "hidden",
  paddingRight: padding.large1,
  textOverFlow: "ellipses",
  "&:first-of-type": {
    paddingTop: `${padding.small2} !important`,
  },
});

const useStyles = makeStyles({
  minNumberWidthTotal: {
    fontSize: `${fontSize.large} !important`,
    margin: `0 ${margin.xsmall1} 0 0`,
    width: "250px !important",
  },
  normalPadding: {
    paddingTop: padding.small2,
  },
  bigPadding: {
    paddingBottom: padding.large1,
    paddingTop: padding.large1,
  },
  totalDueNoUnderline: {
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  totalDueWhiteUnderline: {
    display: "inline-block",
    fontStyle: "normal",
    fontWeight: fontWeight.bold2,
    lineHeight: "140%",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default function TotalAll({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  hasFileLockedWithUpdateablePricingItem,
  isPricingSectionUpdated,
}: Props) {
  const classes = useStyles();
  const footerCellClassname = hasFileLockedWithUpdateablePricingItem
    ? classes.normalPadding
    : classes.bigPadding;

  const totalClassname = hasFileLockedWithUpdateablePricingItem
    ? classes.totalDueNoUnderline
    : classes.totalDueWhiteUnderline;

  const { getPricingTotal } = usePricingCalculator();
  const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);

  return (
    <TableRow>
      <StyledTableFooterCell className={footerCellClassname}>
        <div
          className={totalClassname}
        >
          Totals
        </div>
      </StyledTableFooterCell>
      {displayFieldIcon && <StyledTableFooterCell className={footerCellClassname} />}
      {displayLiabilityColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayRateTypeColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayReissueColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayTransCodeColumn && <StyledTableFooterCell className={footerCellClassname} />}
      <ActualFeeTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} actualFeeTotal={pricingTotal.actualFeeTotal} />
      {displayRiskRateColumn ? (
        <RiskRateTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} riskRateTotal={pricingTotal.riskRateTotal} />
      ) : null}
      <AgentRetentionTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} agentRetentionTotal={pricingTotal.agentRetentionTotal} />
      <TotalDueTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} totalDueTotal={pricingTotal.totalDueTotal} />
    </TableRow>
  );
}
