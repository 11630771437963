import React from "react";
import Box from "@material-ui/core/Box";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useAdditionalPartiesFavorite } from "utils/context/AdditionalPartiesContext";
import { useInitialValuesFiles } from "utils/context/FilesContext";

type Props = {
  index: number;
};

export default function MakeFavorite({ index }: Props) {
  const schema = `additionalParties.${index}`;
  const { getValues } = useFormWrapper();
  const [parties] = useAdditionalPartiesFavorite();
  const [{ initialValues }] = useInitialValuesFiles();

  const [filePartyId, selectedPartyID] = getValues([
    `${schema}.filePartyId`,
    `${schema}.selectedAdditionalPartyID`,
  ]);

  const isLocked = getValues("isLocked");
  const shouldFieldBeDisabled = isLocked === 1;

  const disabled: boolean =
    !selectedPartyID ||
    (filePartyId > 0 && initialValues.additionalParties[index].favorite) ||
    (parties.find((p) => p.id === selectedPartyID)?.favorite ?? false);

  return (
    <Box mt={0.5}>
      <CheckboxInputField
        label="Make favorite"
        name={`${schema}.favorite`}
        disabled={disabled || shouldFieldBeDisabled}
      />
    </Box>
  );
}
