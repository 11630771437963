import React from 'react';

const useFocus = () => {

  const goElementFocus = (ref:  React.RefObject<HTMLButtonElement>, delay = 1000) => {
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, delay);
  }

  const setFocusInputElement = (elementId: string, delay = 200) => {
    setTimeout(()=> {
      document.getElementById(elementId)?.focus();
      if (!document.getElementById(elementId)) {
        document.getElementsByName(elementId)[0]?.focus();
      }
    }, delay)
  }

  const setFocusElement = (setFocus: (name: string) => void, name: string, delay = 100) => {
    setTimeout(() => {
      if (setFocus && name) {
        setFocus(name);
      }
    }, delay);
  }

  return { goElementFocus, setFocusElement, setFocusInputElement };
}

export default useFocus;
