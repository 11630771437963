import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@mui/icons-material";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import { useWizardProvider } from "pages/signatures/providers/manage-signature/WizardProvider";
import React, {
  useEffect,
  useState,
} from "react";
import { useSignatures } from "utils/context/SignaturesContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import { selectAllLocationNodes } from "utils/signature/treeView";
import NextButton from "./buttons/NextButton";
import SaveButton from "./buttons/SaveButton";
import BackButton from "./buttons/BackButton";

const SignatureDialog = () => {
  const [title, setTitle] = useState("");
  const classes = useDialogStyles({ type: "INFO" });
  const [{ cachedSignature }, { getSignatureConfiguration }] = useSignatures();
  const { state: { mode, open }, } = useSignatureProvider();
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();
  const {
    state: { activeStep },
    dispatch: wizardDispatch,
  } = useWizardProvider();
  const {
    dispatch: configurationDispatch,
  } = useConfigurationProvider();

  const reset = () => {
    wizardDispatch({ type: "RESET" });
    configurationDispatch({ type: "RESET" });
    signatureDispatch({ type: "CLOSE" });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      reset();
      event.preventDefault();
    }
  };

  useEffect(() => {
    const initTree = async () => {
      const data: SignatureConfigurationNode | undefined =
        mode === "ADD"
          ? await getSignatureConfiguration()
          : cachedSignature?.signatureConfiguration;

      if (mode === "ADD" && data) {
        selectAllLocationNodes(data);
        data.Expanded = false;
      }

      configurationDispatch({
        type: "INIT",
        data,
      });
    };

    if (activeStep.key === "CONFIG") {
      initTree();
    }
  }, [
    cachedSignature?.signatureConfiguration,
    cachedSignature?.signatureImageID,
    configurationDispatch,
    getSignatureConfiguration,
    mode,
    activeStep.key,
  ]);

  useEffect(() => {
    switch (mode) {
      case "ADD":
        setTitle("Add Signature");
        break;
      case "EDIT":
        setTitle("Edit Signature");
        break;
      default:
        break;
    }
  }, [mode]);

  return (
    <Dialog
      open={open}
      classes={{ root: classes.dialogRoot }}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle
        disableTypography
        classes={{ root: classes.dialogTitleRoot }}
      >
        <div className="action-title">
          <div></div>
          <span>{title}</span>
          <Close onClick={reset} />
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {activeStep.Component}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot }}>
        <>
          {activeStep.key !== "MENU" && 
            <BackButton
              activeStep={activeStep.key}
            />
          }
          <NextButton
            activeStep={activeStep.key}
          />
          <SaveButton resetProviders={reset} />
        </>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureDialog;
