import {
  styled,
  Typography,
} from "@material-ui/core";
import {
  Theme,
} from "@material-ui/core/styles";
import React, { ReactElement } from "react";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  titleIconOverflowDistance,
  zIndex,
} from "theme/defaultStyle";
import ErrorMessage from "./SectionErrorMessage";

type Props = {
  children: ReactElement;
  icon: ReactElement | Element;
  isError?: boolean;
  title: string;
  titleRef?: any;
  contentAlignment?: "below-header" | "beside-header";
};

type ContainerProps = {
  theme: Theme;
  largePadding: boolean;
};

const TopOverflowContainer = styled("div")({
  paddingBottom: padding.small,
  paddingLeft: padding.small,
  paddingRight: padding.small,
  paddingTop: titleIconOverflowDistance,
});

const HeaderContainer = styled("div")({
  position: "relative",
});

const Header = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: gaps.medium1,
  left: padding.xlarge21,
  paddingLeft: padding.zero,
  position: "absolute",
  top: `-${titleIconOverflowDistance}`,
  zIndex: zIndex.high0,
  "& .MuiTypography-root": {
    background: gradients.maroonGradient01,
    backgroundClip: "text",
    fontWeight: fontWeight.bold2,
    fontSize: fontSize.xlarge0,
    position: "relative",
    "text-fill-color": "transparent",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
});

const ContentContainer = styled("div")(({ theme, largePadding }: ContainerProps) => ({
  backgroundColor: theme.custom.container.outer.root.backgroundColor,
  borderRadius: theme.custom.container.outer.root.borderRadius,
  boxShadow: theme.custom.container.outer.root.boxShadow ?? "",
  margin: margin.zero,
  paddingBottom: theme.custom.container.outer.root.padding,
  paddingLeft: theme.custom.container.outer.root.padding,
  paddingRight: theme.custom.container.outer.root.padding,
  paddingTop: largePadding ? padding.xxlarge1 : padding.xlarge1,
}));

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .MuiTypography-root.subheading": {
    color: colors.grey11,
    fontSize: fontSize.base,
    margin: margin.zero,
    padding: padding.zero,
  },
});

const IconWrapper = styled("div")({
  background: gradients.maroonGradient01,
  borderRadius: borderRadius.xlarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "64px",
  height: "64px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});

const PageSection = ({
  children,
  icon,
  title,
  titleRef,
  isError = false,
  contentAlignment = "below-header",
}: Props) => {

  return (
    <TopOverflowContainer>
      <HeaderContainer ref={titleRef}>
        <Header >
          <IconWrapper>
            {icon}
          </IconWrapper>
          <Typography>
            {title}
          </Typography>
          {isError && <ErrorMessage />}
        </Header>
      </HeaderContainer>
      <ContentContainer
        largePadding={contentAlignment === "below-header"}>
        <Content>
          {children}
        </Content>
      </ContentContainer>
    </TopOverflowContainer>
  );
};

export default PageSection;
