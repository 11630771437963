import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  styled,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Home,
  Person,
} from "@mui/icons-material";
import CustomTooltip from "controls/global/custom-tooltip";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import Loading from "controls/global/loading";
import SelectField from "controls/global/select-field";
import TextInputField from "controls/global/text-input-field";
import HeaderRefs from "pages/file/components/file-form-layout/HeaderRefs";
import ProfileValidationSchema from "pages/file/utils/yup/schema/profileValidationSchema";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import theme from "theme/default";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import GradientIcon from "theme/icons/GradientIcon";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useLookup } from "utils/context/LookupContext";
import { useNavigation } from "utils/context/NavigationContext";
import { ProfileSettings, useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { userExternalAccounts } from "utils/context/UserExternalSystemAccountsContext";
import { useUserStates } from "utils/context/UserStatesContext";
import { UIConstants } from "utils/data/enum";
import classes from "./ProfileSettings.module.css";
import ComboBoxField from "controls/global/combo-box-field";
import { 
  useCompanyContext, 
  useCompanyContextActions,
} from "utils/context/CompanyContext";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import RadioButtonField from "./components/RadioButton";

const DEFAULT_MESSAGE = "Do you want to save your changes?";
interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  forceUpdate: (sausername: string) => void;
  profileSettingsTitleRef: any;
  propertyDefaultsTitleRef: any;
}

const FormField = styled("div")({
  flexGrow: 1,
});

const RadioButtonFormField = styled("div")({
  alignItems: "start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "& > label": {
    color: colors.grey08,
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold2,
  },
});

const Sections = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large3,
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.medium1,
});

const SectionTitle = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small2,
});

const SectionTitleText = styled(Box)({
  background: gradients.maroonGradient01,
  fontSize: fontSize.xlarge2,
  fontWeight: fontWeight.bold2,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const SectionPanel = styled(Box)({
  ...theme.custom.container.outer.root,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  justifyContent: "space-between",
  padding: padding.xlarge1,
});

const ProfileSettingsForm = ({
  saveFileRef,
  saveFileChangeRef,
  profileSettingsTitleRef,
  propertyDefaultsTitleRef,
  forceUpdate,
}: Props) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [saUserChanged, setSAUserChanged] = useState(false);
  const [currentSAUser, setCurrentSAUser] = useState("");
  const navigate = useNavigate();
  const [, { navigateToNextUrl }] = useNavigation();
  const [{ isLoading, profileSettings }, { onSubmit, getProfileSettings }] =
    useProfileSettingsCache();
  const [{ propertyTypes }, { getPropertyTypes }] = useLookup();
  const [{ userStates }, { getUserStates }] = useUserStates();
  const [{ userStewartAccounts }, { getUserStewartAccounts }] = userExternalAccounts();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const { generalConfig: { saDomain } } = useConfigContext();
  const [, { setShowChangeAgency, clearUserSelectedAgency }] = useGlobalAccess();

  const [{ agencies }] = useCompanyContext();
  const [,{ getAgencies }] = useCompanyContextActions();
  const [defaultAgencySelected, setDefaultAgencySelected] = useState(false);
  
  const methods = useForm({
    resolver: yupResolver(ProfileValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: profileSettings,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,    
    reset,
    setValue,
  } = methods;

  const getAllStates = () => {
    let statesWithDefaultOption = userStates;
    if (userStates.length > 1) {
      statesWithDefaultOption = [
        { text: "Select", value: "" },
        ...statesWithDefaultOption,
      ];
    }
    return statesWithDefaultOption.map((st: any) => {
      return {
        text: st.text,
        value: st.value,
        selected: st.value === profileSettings.userProfile.defaultPropertyState,
      };
    });
  };
  
  const onSkipAgencyChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {      
    setValue('userProfile.skipAgencySelection',value);
  };

  const onUseAddressSuggestionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {    
    setValue('userProfile.useAddressSuggestion',value);
  };

  const onDefaultAgencyChange = (event: React.ChangeEvent<{}>, item: SelectFieldOption)=>{
    const itemFound: SelectFieldOption|undefined = agencies.find((agency: any) => {
      return item.value === agency.value
    });
    if(itemFound){
      setDefaultAgencySelected(true);      
      setValue('userProfile.skipAgencySelection','Yes');      
      setValue('userProfile.defaultAgencyID', item.value);
    }else{
      setDefaultAgencySelected(false);
      setValue('userProfile.defaultAgencyID', '');
    }
  }

  const getAllTimeZones = () => {
    return profileSettings.timeZones.map((timeZone: any, index: number) => {
      return {
        text: timeZone.Display_Name,
        value: timeZone.Name,
        selected: timeZone.Name === profileSettings.userTimeZone,
      };
    });
  };

  const getAllAgencies = () => {
    return agencies.map((agency: any) => {
      return {
        text: agency.text, 
        value: agency.value,
      };
    });
  };

  const SubmitForm = async (data: any) => {
    openAutomaticProgressDialog(UIConstants.SAVE_PROFILE_IN_PROGRESS_MESSAGE);
    let primaryIsFound = false;
    const exAccounts = userStewartAccounts.map(sa => {
      const retval = sa.payload;
      const isPrimary = sa.text.toLowerCase() === currentSAUser.toLowerCase();
      const isChanged = isPrimary !== retval.IsPrimary;
      retval.IsPrimary = isPrimary ? 1 : 0;
      retval.IsDirty = isChanged;

      if (isPrimary) primaryIsFound = true;
      return retval;
    });

    if (!primaryIsFound) {
      const newRecord =
      {
        ExternalSystemTypeCode: "STEWARTACCESS",
        IsPrimary: 1,
        ExternalUserName: `${currentSAUser.split("@", 1)[0]}@${saDomain}`,
        UserID: userStewartAccounts[0].UserID,
        IsNew: true,
        Active: 1
      };
      exAccounts.push(newRecord);
    }

    data.ExternalSystemAccounts = exAccounts.sort((a, b) => {
      return a.IsPrimary === 0 ? -1 : 0;
    });

    await onSubmit(data);
    await getProfileSettings();
    getUserStewartAccounts();
    closeAutomaticProgressDialog();
    forceUpdate(profileSettings.stewartAccessUsername);
    updateGlobalAccessAgencySettings(true);
  };

  const checkIfIsDirty = () => {        
    const formValues:ProfileSettings = getValues();
    if (profileSettings?.displayName !== formValues.displayName ||
        profileSettings?.userTimeZone !== formValues.userTimeZone ||
        // profileSettings?.userProfile?.defaultAgencyID !== formValues?.userProfile?.defaultAgencyID ||
        profileSettings?.userProfile?.defaultPropertyState !== formValues?.userProfile?.defaultPropertyState ||
        profileSettings?.userProfile?.skipAgencySelection !== formValues?.userProfile?.skipAgencySelection ||
        profileSettings?.userProfile?.useAddressSuggestion !== formValues?.userProfile?.useAddressSuggestion
      ) {
      return true;
    }
    return false;
  } 

  const handleSaveChanges = () => {    
    if (checkIfIsDirty()) {
      setOpenConfirmationDialog(true);
    }
    else {
      navigateToNextUrl(navigate);
    }
  };

  const handleDialogYes = async () => {
    setOpenConfirmationDialog(false);
    if (Object.keys(errors).length === 0) {
      await SubmitForm(getValues());
      navigateToNextUrl(navigate);
    }
  };

  const handleDialogNo = () => {
    setOpenConfirmationDialog(false);
    navigateToNextUrl(navigate);
  };

  // NT 10/09/2023 - Hiding Default Agency control as it will be added back 
  // after requirements hashed out
  const updateGlobalAccessAgencySettings = useCallback((onSubmit: boolean) => {    
    if(profileSettings) {
      if(!profileSettings.isStaffUser ||
          profileSettings.userProfile?.skipAgencySelection === "Yes"){
          setShowChangeAgency(false);
          clearUserSelectedAgency();
      }
      else if(profileSettings.isStaffUser &&
              profileSettings.userProfile?.skipAgencySelection === "No"){
          setShowChangeAgency(true);
      }
    }        
    // const currentDefaultAgencyID = getValues('userProfile.defaultAgencyID');        
    // if(currentDefaultAgencyID && profileSettings?.isStaffUser) {
    //     const matchingAgency = agencies?.find((a) => a.value === profileSettings.userProfile?.defaultAgencyID);        
    //     setUserSelectedAgency([{
    //       CompanyID: profileSettings.userProfile?.defaultAgencyID,
    //       CompanyName: matchingAgency?.text,
    //       CompanyLegacyID: matchingAgency?.legacyID,
    //     }]);             
    // }
    // else if (!profileSettings?.isStaffUser || (!currentDefaultAgencyID && !selectedAgency)) {
    //   clearUserSelectedAgency();     
    // }
  }, 
  [profileSettings, setShowChangeAgency, clearUserSelectedAgency]);

  useEffect(() => {
    if (propertyTypes.length === 0) getPropertyTypes();
  }, [propertyTypes, getPropertyTypes]);

  useEffect(() => {
    if (currentSAUser === "") {
      setCurrentSAUser(profileSettings.stewartAccessUsername?.split("@", 1)[0]);
      setSAUserChanged(true);
    }

    if (currentSAUser && (userStates.length === 0 || saUserChanged)) {
      getUserStates(currentSAUser);
      setSAUserChanged(false);
    }
    // const currentSkipAgencySelection = getValues("userProfile.skipAgencySelection");   
    // if(profileSettings?.userProfile?.defaultAgencyID && currentSkipAgencySelection === "No") {
    //   setValue("userProfile.skipAgencySelection", "Yes");
    // }
  }, [
    profileSettings,
    userStates,
    saUserChanged,
    currentSAUser,
    getUserStates,        
  ]);

  useEffect(() => {
    reset(profileSettings);
  }, [profileSettings, reset]);

  useEffect(() => {
    updateGlobalAccessAgencySettings(false);
  }, 
  [   
    updateGlobalAccessAgencySettings
  ]); 

  useEffect(() => {
    if (agencies.length === 0) 
      getAgencies();    
  }, []);

  if (userStewartAccounts.length === 0) getUserStewartAccounts();
  
  return (
    <>
      {profileSettings.hasError && (
        <Alert className={classes.error} severity="error">
          {
            "Your request couldn't be processed due to a technical issue. Please try again."
          }
        </Alert>
      )}
      {isSubmitting || (isLoading && <Loading></Loading>)}

      <FormProvider {...methods}>
        <form className={classes.form} id="profileSettingsForm">
          <Sections>
            <Section>
              <SectionTitle ref={profileSettingsTitleRef}>
                <GradientIcon Icon={Person} />
                <SectionTitleText>Profile Settings</SectionTitleText>
              </SectionTitle>
              <SectionPanel>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextInputField label="Display Name" name="displayName" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <SelectField
                        label="User Time Zone"
                        name="userTimeZone"
                        options={getAllTimeZones()}
                      />
                  </Grid>
                </Grid>
                {
                  profileSettings.isStaffUser === 1 && (
                    <Grid container spacing={3}>                      
                      <FormField style={{ padding: padding.medium1, display: "None" }}>
                        <ComboBoxField
                          name='userProfile.defaultAgencyID'
                          label={"Default Agency"}
                          roundedCorner={true}
                          options={getAllAgencies()}
                          onChange={onDefaultAgencyChange}
                          disableClearable={false}
                        />
                      </FormField>                        
                      <RadioButtonFormField style={{ padding: padding.medium1 }}>
                        <label>
                          <span>Skip Agency Selection</span>
                        </label>                        
                        <RadioButtonField
                          name="userProfile.skipAgencySelection"
                          defaultValue={profileSettings?.userProfile?.skipAgencySelection}
                          disabled={defaultAgencySelected}
                          onChange={onSkipAgencyChange}
                        />                        
                      </RadioButtonFormField>
                    </Grid>
                  )
                }
              </SectionPanel>
            </Section>

            <Section>
              <SectionTitle ref={propertyDefaultsTitleRef}>
                <GradientIcon Icon={Home} />
                <SectionTitleText>Property Defaults</SectionTitleText>
              </SectionTitle>
              <SectionPanel>
                <FormField>
                  <SelectField
                    label="Property Type"
                    name="userProfile.defaultPropertyType"
                    options={propertyTypes}
                  />
                </FormField>
                <FormField>
                  <SelectField
                    label="Property State"
                    name="userProfile.defaultPropertyState"
                    options={getAllStates()}
                    allowsDelete={true}
                  />
                </FormField>
                <CustomTooltip
                  title={"A default Agency Location must be selected before a default Property State can be set."}
                  placement="top"
                  aria-label="agency-location-selection"
                  arrow
                >
                  <StyledInfoIcon />
                </CustomTooltip>                
                <RadioButtonFormField style={{ padding: padding.medium1 }}>
                  <label>
                    <span>Address Suggestion</span>
                  </label>                        
                  <RadioButtonField
                    name="userProfile.useAddressSuggestion"
                    defaultValue={profileSettings?.userProfile?.useAddressSuggestion}
                    disabled={false}
                    onChange={onUseAddressSuggestionChange}
                    labelYes="On"
                    labelNo="Off"
                  />                        
                </RadioButtonFormField>
              </SectionPanel>
            </Section>
          </Sections>
        </form>
      </FormProvider>
      <HeaderRefs
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
        handleSaveChanges={handleSaveChanges}
        handleSaveFile={handleSubmit(SubmitForm)}
      />
      <ConfirmationDialog
        confirmationMessage={DEFAULT_MESSAGE}
        isOpen={openConfirmationDialog}
        onYes={handleDialogYes}
        onNo={handleDialogNo}
      />
    </>
  );
};

export default ProfileSettingsForm;
