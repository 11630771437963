import {
  Box,
  styled,
  Typography,
} from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box/Box";
import StewartCheckbox from "controls/global/stewart-checkbox";
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  margin,
  padding,
} from "theme/defaultStyle";

interface Props {
  disabled: boolean;
  onlyShowSelectedFiles: boolean;
  onOnlySelectedFilesChange?: (value: boolean) => void;
}

interface StyledBoxProps extends BoxProps {
  isDisabled?: boolean;
}

const StyledBox = styled(Box)((props: StyledBoxProps) => ({
  alignItems: "center",
  display: "flex",
  marginRight: margin.medium2,
  paddingRight: padding.xsmall2,
  "& .label": {
    color: props.isDisabled ? colors.grey11 : colors.grey08,
    fontSize: fontSize.large,
  },
}));

const PendingPayFooterAction = ({
  disabled,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: Props) => {
  const [onlySelectedFilesChecked, setOnlySelectedFilesChecked] = useState<boolean>(
    onlyShowSelectedFiles
  );

  const handleOnlySelectedFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setOnlySelectedFilesChecked(isChecked);
    onOnlySelectedFilesChange?.(isChecked);
  };

  useEffect(() => {
    if (!disabled) return;
    setOnlySelectedFilesChecked(false);
    onOnlySelectedFilesChange?.(false);
  }, [disabled]);

  useEffect(() => {
    setOnlySelectedFilesChecked(onlyShowSelectedFiles);
  }, [onlyShowSelectedFiles]);

  return (
    <div>
      <StyledBox isDisabled={disabled}>
        <StewartCheckbox
          disabled={disabled}
          checked={onlySelectedFilesChecked}
          onChange={handleOnlySelectedFilesChange}
        />
        <Typography className="label">Only show selected files</Typography>
      </StyledBox>
    </div>
  );
};

export default PendingPayFooterAction;
