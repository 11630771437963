import {
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddLink from "controls/global/add-link";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { SCFile } from "entities/UIModel";
import { Endorsement } from "entities/UIModel/Endorsement";
import React, {
  useEffect,
  useState,
} from "react";
import { useFieldArray } from "react-hook-form";
import {
  colors,
  padding,
} from "theme/defaultStyle";
import { useFilesActions } from "utils/context/FilesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useVoidableLockedFile from "utils/custom-hooks/useVoidableLockedFile";
import {
  OrderStatusType,
  ProductType,
} from "utils/data/enum";
import { getDefaultEndorsementObject } from "utils/data/products";
import { v4 as uuidv4 } from "uuid";
import EndorsementGridRow from "./EndorsementGridRow";
import Void from "./Void";
import useCreateFile from "utils/custom-hooks/useCreateFile";

interface Props {
  name: any;
  minDate?: Date | null;
  disabled?: boolean;
  endorsementOptions: SelectFieldOption[];
  jacketEndorsements?: Endorsement[];
  issuedMode: boolean;
  editMode: boolean;
  parentProductType: string;
  parentOrderID: number;
  parentProductIndex: number;
  showHeader?: boolean;
  hasShortFormEndorsements?: boolean;
  isRevising?: boolean;
  isIssueable?: boolean;
  onEndorsementChange?: any;
  onVoidComplete?: any;
  isTransCodeRequired?: boolean;
}

const useStyles = makeStyles({
  headCell: {
    padding: `${padding.small2} ${padding.zero} ${padding.xsmall2} ${padding.small2}`,
  },
  headCellCenter: {
    padding: `${padding.small2} ${padding.zero} ${padding.xsmall2} ${padding.zero}`,
  },
});

const StyledTableContainer = styled(Paper)({
  overflowX: "auto",
  overflowY: "hidden",
  "& .MuiOutlinedInput-inputMarginDense": {
    paddingTop: padding.xsmall2,
    paddingBottom: padding.xsmall2,
  },
  "& .MuiAutocomplete-endAdornment": {
    top: 0
  }
});

const ButtonRow = styled("div")({
  dipsplay: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
});

const EndorsementGrid = ({
  name,
  minDate,
  disabled = false,
  endorsementOptions,
  issuedMode,
  editMode,
  parentProductType,
  parentOrderID,
  showHeader = true,
  hasShortFormEndorsements = true,
  isRevising = false,
  isIssueable = true,
  onEndorsementChange,
  onVoidComplete,
  parentProductIndex,
  isTransCodeRequired = false,
}: Props) => {
  const classes = useStyles();
  const [voidingEndorsement, setVoidingEndorsement] = useState<Endorsement | null>(null);
  const [voidRequestId, setVoidRequestId] = useState<string>("");
  const { nameString, setValue, getValues, watch } = useFormWrapper();
  const [, { deleteEndorsement }] = useFilesActions();
  const { shouldVoidableWhenLocked } = useVoidableLockedFile();
  const schema = `${name}.endorsements`;
  const { isReadOnly } = useCreateFile();

  const { fields, append, remove } = useFieldArray({
    name: nameString(schema),
    keyName: "fieldId",
  });

  const allEndorsements: Endorsement[] =
    watch(nameString(schema), fields) || [];

  const isAddButtonDisabled = () => {
    if(isReadOnly)
      return true;
    if (parentProductType === ProductType.StandaloneEndorsement && isIssueable)
      return false;
    if (isRevising || !isIssueable) return true;

    return disabled;
  };

  const handleOnRemove = (index: number) => {
    const jacketEndorsement = allEndorsements[index];
    const removeKey = jacketEndorsement?.endorsementID;

    if (removeKey) {
      deleteEndorsement(removeKey);
    }
    remove(index);
    onEndorsementChange && onEndorsementChange(parentProductIndex);
  };

  const isVoidable = (endorsement: Endorsement) =>
    endorsement.endorsementStatusTypeCode === OrderStatusType.Issued &&
    !editMode &&
    !isRevising &&
    shouldVoidableWhenLocked(endorsement);

  const showTransCode = () => {
    return (issuedMode && isTransCodeRequired) || false;
  };

  const addNewEndorsement = () => {
    const scFile = getValues() as SCFile;
    let isIssuedAfterJacket = "0"; // Initialized to 0 for pending Jackets
    if (scFile) {
      if (parentProductType === ProductType.Jacket) {
        if (
          scFile.jackets[parentProductIndex].orderStatusTypeCode ===
          OrderStatusType.Issued
        ) {
          isIssuedAfterJacket = "1";
        }
      } else if (parentProductType === ProductType.StandaloneEndorsement) {
        isIssuedAfterJacket = "1";
      }
    }

    if (!allEndorsements || allEndorsements.length === 0) {
      setValue(`${name}.endorsements`, [
        getDefaultEndorsementObject(isIssuedAfterJacket),
      ]);
    } else {
      append(getDefaultEndorsementObject(isIssuedAfterJacket));
    }
  };

  const handleRequestVoid = (endorsement: Endorsement) => {
    setVoidingEndorsement(endorsement);
  };

  const handleCancelVoid = () => {
    setVoidingEndorsement(null);
  };

  const handleVoidComplete = () => {
    setVoidingEndorsement(null);
    onVoidComplete && onVoidComplete();
  };

  useEffect(() => {
    if (voidingEndorsement === null) {
      setVoidRequestId("");
    }
    else {
      setVoidRequestId(uuidv4());
    }
  }, [voidingEndorsement]);

  // Do not display any grid if no endorsements are available
  if (
    parentProductType === ProductType.Jacket &&
    endorsementOptions.length === 0
  )
    return null;

  return (
    <>
      {allEndorsements.length > 0 ? (
        <>
          <StyledTableContainer
            elevation={0}
            style={{
              marginTop: showHeader ? 0 : 0,
              backgroundColor: disabled ? colors.grey03 : colors.white,
            }}
          >
            <Table size="small" style={{ minWidth: "1150px" }} className="endorsements-table">
              <TableHead
                style={{ height: 40, backgroundColor: colors.white }}
              >
                <TableRow selected={issuedMode}>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    style={{ minWidth: 340 }}
                    colSpan={issuedMode || !hasShortFormEndorsements ? 1 : 2}
                  >
                    Endorsements
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    hidden={!showTransCode()}
                    style={{ width: 125 }}
                  >
                    Trans Code
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    style={{ width: 170 }}
                  >
                    Effective Date
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    style={{ width: 210 }}
                    hidden={!issuedMode}
                  >
                    Issue Date
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    hidden={!issuedMode}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCellCenter }}
                    align="center"
                    hidden={!issuedMode}
                  >
                    Void
                  </TableCell>
                  <TableCell
                    classes={{ sizeSmall: classes.headCell }}
                    hidden={!editMode}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {allEndorsements.map((endorsement: any, index: number) => (
                  <EndorsementGridRow
                    key={`${index}-${endorsement.integrationKey}`}
                    name={schema}
                    showTransCode={showTransCode()}
                    onRequestVoid={handleRequestVoid}
                    {...{
                      index,
                      minDate,
                      endorsement,
                      endorsementOptions,
                      issuedMode,
                      voidable: isVoidable(endorsement),
                      handleOnRemove,
                      editMode,
                      isRevising,
                      parentProductType,
                      parentOrderID,
                      hasShortFormEndorsements,
                      disabled,
                      onEndorsementChange,
                      parentProductIndex,
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>
      ) : null}
      <ButtonRow>
        <AddLink
          onClick={addNewEndorsement}
          disabled={isAddButtonDisabled()}
        >
          Add Endorsement
        </AddLink>
      </ButtonRow>
      {voidRequestId && voidingEndorsement &&
        <Void
          isOpen={true}
          integrationKey={voidingEndorsement.integrationKey}
          endorsementName={voidingEndorsement.endorsementName}
          parentOrderID={parentOrderID}
          parentProductType={parentProductType}
          voidRequestId={voidRequestId}
          onCancel={handleCancelVoid}
          onVoidComplete={handleVoidComplete}
        />
      }
    </>
  );
};

export default EndorsementGrid;
