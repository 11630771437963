import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { AxiosRequestConfig, CancelTokenSource } from "axios";

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface AgencyLocation extends SelectFieldOption {
  id: string;
  disabled?:boolean;
}

interface State {
  agencies: Array<SelectFieldOption>;
  agencyLocations: Array<AgencyLocation>;
  error: string | null;
  isLoading: boolean;
  agencyStates: Array<SelectFieldOption>;
  isRetrievingStates: boolean; 
  isRetrievingAgencyLocations: boolean;
  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setLoading =
  () =>
  ({ setState }: StoreApi) => {
    setState({ isLoading: true });
  };

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const setDefaultState =
  () =>
  ({ setState }: StoreApi) => {    
    setState({ 
      agencyStates: [],
      agencies: [],
      agencyLocations: [],
    });
  };

const hasAny = (data: Array<any>) => !data || data.length <= 0;

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
  async ({ dispatch }: StoreApi) => {
    try {
      const { data } = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error:any) {
      dispatch(setError(error));
    }
  };

const   setAgencies =
  (agencies: Array<any>) =>
  ({ setState }: StoreApi) => {
    if (hasAny(agencies)) {
      setState({ isLoading: false, agencies: [] });
      return;
    }
    setState({
      isLoading: false,
      agencies: agencies.map((agency) => ({
        text: agency.AgencyName,
        value: agency.AgencyID,
        selected: agencies.length === 1,
      })),
    });
  };

const setAgencyLocations =
  (locations: Array<any>) =>
  ({ setState }: StoreApi) => {
    if (hasAny(locations)) {
      setState({ isRetrievingAgencyLocations: false, agencyLocations: [] });
      return;
    }

    setState({
      //isLoading: false,
      agencyLocations: locations.map((location) => ({
        text: location.LocationDisplayName,
        value: location.LocationDisplayName,
        id: location.LocationID,
        //id: location.AgencyID,    
        disabled: location.IsDisabled || false,    
        selected: locations.length === 1,
      })),
      isRetrievingAgencyLocations: false
    });
  };

const setAgencyStates =
  (data: Array<any>, isUpdate: boolean = false) =>
  ({ setState }: StoreApi) => {
    //console.log('setting states:', data, 'isUpdate: ', isUpdate);
    setState({
      agencyStates: data.map((state) => ({
        text: state.StateAbbr,
        value: state.StateCode,
      })),
      isRetrievingStates: isUpdate,
    });    
  };


const defaultState = {
  
  agencies: [],
  agencyLocations: [],
  error: null,
  isLoading: false,
  agencyStates: [],
  isRetrievingStates: false,
  isRetrievingAgencyLocations: false,
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getAgencies:
      () =>
      async ({ dispatch }: StoreApi) => {
        dispatch(setLoading());
        dispatch(getData("/report/GetAgencyListByUser", setAgencies));
      },
    getAgencyLocations:
      (agencyId: string, stateCode?: string) =>
      async ({ dispatch, setState }: StoreApi) => {
        
        if(!agencyId) return;
        let url = `/report/GetAgencyLocationsByAgencyIDState?agencyid=${agencyId}`;
        if(stateCode) url = url + `&propertyState=${stateCode}`
        
        //dispatch(setLoading());
        setState({isRetrievingAgencyLocations: true});
        dispatch(
          getData(
            url,
            setAgencyLocations
          )
        );
      },
    updateAgencyLocations:
    (locations: Array<any>) =>
    async ({ dispatch, setState }: StoreApi) => {
      dispatch(setAgencyLocations(locations));      
    },
    updateAgencyStates:
    (agencyStatesUpdated: Array<any>) =>
    async ({ dispatch, setState }: StoreApi) => {      
      if(!agencyStatesUpdated || (agencyStatesUpdated && agencyStatesUpdated.length === 0)) return;
      setState({isRetrievingStates: true});
      dispatch(setAgencyStates(agencyStatesUpdated, true));      
      setState({isRetrievingStates: false});
    },
    getAgencyStates:
      (agencyId?: string, locationDisplayName?:string) =>
      async ({ dispatch, setState }: StoreApi) => {
        let url = "";
        if(agencyId){
          url = `/report/GetStateListByAgencyLocation?agencyid=${agencyId}`
          if(locationDisplayName) url = url.concat(`&locationDisplayName=${encodeURIComponent(locationDisplayName || "")}`);
          
        }else url = "/lookup/getstatelist?countryCodeNum=840";
        
        setState({isRetrievingStates: true});          

        dispatch(getData(url, setAgencyStates));   

      },    
    resetLookups:
      () =>
      ({ dispatch }: StoreApi) => {
        dispatch(setDefaultState());
      },
  },
  name: "reportLookup",
});

const hook = createHook(Store);
export const useReportLookup = () => { return hook() };
