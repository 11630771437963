import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import React, {
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import theme from "theme/default";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";

type Props = {
  onNavigate: (url: string) => void;
};

const linkButtonHeightMin = "50px";
const linkButtonHeightMax = "156px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& a": {
        borderRadius: borderRadius.small,
        color: colors.white,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal1,
        lineHeight: 1,
        padding: padding.zero,
        textDecoration: "none",
        textTransform: "initial",
        "&.disabledLink": {
          lineHeight: 1,
          color: colors.white,
          cursor: "default",
          "& .hover-content": {
            display: "none",
          },
          "&:hover": {
            color: colors.white,
          },
        },
        "&.disabledLink:hover ": {
          color: colors.white,
          cursor: "default",
        },
        "&.disabledLink:hover .normal-content": {
          display: "none",
        },
        "&.disabledLink:hover .hover-content": {
          display: "inline-block",
        },
      },
      "& a .icon": {
        color: colors.white,
        fontSize: fontSize.xlarge2,
      },
      "& .disabledLink .icon": {
        color: colors.blue04,
        fontSize: fontSize.xlarge2,
      },

      "& a div": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: gaps.small1,
        justifyContent: "center",
      },

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    paper: {
      borderRadius: borderRadius.xlarge,
    },
    menuContainer: {
      background: colors.white,
      borderRadius: borderRadius.xlarge,
      minWidth: "160px",
      overflow: "inherit",
      top: "15px !important",
      "&:after, &:before": {
        border: "solid transparent",
        bottom: "100%",
        content: `""`,
        height: "0",
        left: "50%",
        pointerEvents: "none",
        position: "absolute",
        width: "0",
      },
      "&:after": {
        borderBottomColor: colors.white,
        borderColor: "rgba(255, 255, 255, 0)", //TODO: There is an open bug to correct this dropdown style (this is an odd way to be transparent)
        borderWidth: "10px", // Not a normal border, but used to create triangle.
        marginLeft: "-10px", // Not a normal margin, but used to create triangle.
      },
      "&:before": {
        borderBottomColor: colors.white,
        borderColor: "rgba(0, 117, 170, 0)", //TODO: There is an open bug to correct this dropdown style (this is an odd way to be transparent)
        borderWidth: "16px", // Not a normal border, but used to create triangle.
        marginLeft: "-16px", // Not a normal margin, but used to create triangle.
      },
    },
    menuItem: {
      borderRadius: borderRadius.small,
      margin: "auto",
      padding: padding.large1,
      color: colors.blue01,
      width: "90%",
      "&:hover": {
        background: gradients.blueGradient03,
        color: colors.white,
      },
    },
  })
);

const StyledNavButton = styled(Button)((props) => ({
  alignItems: "center",
  background: gradients.blueGradient03,
  display: "flex",
  flexDirection: "row",
  height: linkButtonHeightMin,
  justifyContent: "center",
  minWidth: linkButtonHeightMin,
  padding: padding.zero,
  width: linkButtonHeightMin,
  "&:hover": {
    background: gradients.maroonGradient01,
  },
  "&.active": {
    background: gradients.maroonGradient01,
  },
  [props.theme.breakpoints.up("lg")]: {
    width: linkButtonHeightMax,
  },
}));

const PaymentsHeaderDropDown = ({ onNavigate }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const prevOpen = React.useRef(open);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handlePayments = () => {
    onNavigate("/reportpay");
    setOpen(false);
  };

  const handlePendingPayments = () => {
    onNavigate("/pendingpay");
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const showButtonLabels = useMediaQuery(theme.breakpoints.up("lg"));

  if (!enableReportPay) {
    return <></>;
  }

  let className = classes.root;
  const paths = ["/reportpay", "/pendingpay"];
  if (paths.includes(window.location.pathname)) {
    className = `${className} active`;
  }

  return (
    <>
      <StyledNavButton onClick={handleToggle} ref={anchorRef} className={className}>
        <Link onClick={(event) => event.preventDefault()} to="/">
          <div>
            <LocalAtmIcon />
            {showButtonLabels &&
              <>
                Payments
                {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </>
            }
          </div>
        </Link>
      </StyledNavButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuContainer}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={handlePayments}
                    className={classes.menuItem}
                  >
                    Report & Pay
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handlePendingPayments}
                  >
                    Pending Payments
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    disabled={true}
                    hidden={true}
                  >
                    Save for Later
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PaymentsHeaderDropDown;
