import { styled } from "@material-ui/core";
import AddLink from "controls/global/add-link";
import { useSignatureDispatchProvider } from "pages/signatures/providers/manage-signature/SignatureProvider";
import React from "react";

const LinkContainer = styled("div")({
  marginRight: "auto",
});

export default function AddSignatureLink() {
  const { dispatch } = useSignatureDispatchProvider();
  const handleAddSignature = () => dispatch({ type: "OPEN", mode: "ADD" });

  return (
    <LinkContainer>
      <AddLink
        disabled={false}
        onClick={handleAddSignature}
      >
        Add Signature
      </AddLink>
    </LinkContainer>
  );
}
