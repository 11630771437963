import { styled } from "@material-ui/core";
import AddLink from "controls/global/add-link";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React from "react";

const LinkContainer = styled("div")({
  marginRight: "auto",
});

function AddSignatureLink() {
  const { showAdd } = useSignatureDefaultDialogProvider();

  return (
    <LinkContainer>
      <AddLink
        disabled={false}
        onClick={showAdd}
      >
        Add Default Signature
      </AddLink >
    </LinkContainer>
  );
}

export default AddSignatureLink;
