import {
  Box,
  Grid,
} from "@material-ui/core";
import React, {
  useEffect,
} from "react";
import { useWatch } from "react-hook-form";
import {
  useAgencyLocations,
  useAgencyLocationsActions,
} from "utils/context/AgencyLocationsContext";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import ViewPendingPayment from "../common/ViewPendingPayment";
import AgencyField from "./AgencyField";
import AgencyLocationField from "./AgencyLocationField";
import FileNameNumberField from "./FileNameNumberField";
// import FileStatusField from "./FileStatusField";
import HiddenFileItems from "./HiddenFileItems";
import TransactionTypeCodeField from "./TransactionTypeCodeField";

type Props = {
  onNavigate: (url: string) => void;
}

const FileData = ({ onNavigate }: Props) => {
  const { getValues } = useFormWrapper();
  const [{ agencyStates }, { updateAgencyStates }] = useAgencyStates();
  const [{ fileAgencyLocations, isRetrievingAgencyLocations }] =
    useAgencyLocations();
  const [, { updateAgencyLocations }] = useAgencyLocationsActions();

  const [fileId] = useWatch({
    name: ["id"],
  });

  useEffect(() => {
    if (isRetrievingAgencyLocations || fileId <= 0) return;
    const hasIssuedProducts = getValues("hasIssuedProducts");
    const currentAgencyLocation = getValues(`agencyLocationOriginal`);
    const isReadOnly = getValues("isReadOnly");    
    if (
      fileAgencyLocations &&
      fileAgencyLocations.length === 0 &&
      currentAgencyLocation?.name &&
      (hasIssuedProducts || isReadOnly)
    ) {
      let updatedAgencyLocationList: any[] = [];
      updatedAgencyLocationList.push({
        companyID: currentAgencyLocation.id,
        locationDisplayName: currentAgencyLocation.name,
        isDisabled: true,
        locationUniqueID: currentAgencyLocation.currentAgencyLocation
      });
      updateAgencyLocations(updatedAgencyLocationList);
    }
  }, [fileAgencyLocations, fileAgencyLocations.length]);

  useEffect(() => {
    const currentPropertyState = getValues(`properties.0.stateOriginal`);
    const hasIssuedProducts = getValues("hasIssuedProducts");
    if (
      agencyStates &&
      agencyStates.length >= 0 &&
      currentPropertyState?.code &&
      hasIssuedProducts
    ) {
      const findStateInTheList = agencyStates.filter(
        (al) => al.value === currentPropertyState.code
      );
      let updatedAgencyStatesList: any[] = [];
      if (findStateInTheList.length === 0) {
        updatedAgencyStatesList.push({
          stateAbbr: currentPropertyState.abbr,
          stateCode: currentPropertyState.code,
        });
        agencyStates.forEach((al) => {
          updatedAgencyStatesList.push({
            stateAbbr: al.text,
            stateCode: al.value,
          });
        });
        updateAgencyStates(updatedAgencyStatesList);
      }
    }
  }, [agencyStates, agencyStates.length]);

  return (
    <Box position="relative">
      <HiddenFileItems />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <FileNameNumberField data-test="fileNameNumber" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <AgencyField data-test="agency" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AgencyLocationField
            agencyLocations={fileAgencyLocations}
            data-test="agencyLocation"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TransactionTypeCodeField data-test="transactionTypeCode" />
        </Grid>
        {/* Rlo - 05/31/2023: temporary commented - please don't delete */}
        {/* <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <FileStatusField />
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <ViewPendingPayment onNavigate={onNavigate} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileData;
