import {
  Box,
  Button,
  styled,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import AdvancedSearch from "controls/global/advanced-search/AdvancedSearch";
import ColumnsConfig, {
  MenuColumnItem,
} from "controls/global/columns-config/ColumnsConfig";
import { FileSearchCriteria } from "entities/ApiModel/FileSearchCriteria";
import { FilesRecord } from "entities/UIModel";
import React, {
  useState,
} from "react";
import {
  colors,
  gaps,
  padding,
} from "theme/defaultStyle";
import { useMyFiles } from "utils/context/MyFilesContext";
import {
  PageType,
} from "utils/data/enum";

interface Props {
  columns: MenuColumnItem<FilesRecord>[];
  hiddenColumns: (keyof FilesRecord)[];
  updateHiddenColumns?: (columns: (keyof FilesRecord)[]) => void;
  handleAdvancedFilterChange: (
    name: keyof FileSearchCriteria,
    value: any,
    execSearch: boolean
  ) => void;
  handleAdvanceSearchPanelOpen: (open: boolean) => void;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const ActionButtonGroup = styled(Box)({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiButton-root": {
    color: colors.blue01,
    paddingBottom: padding.xsmall1,
    paddingLeft: padding.xsmall2,
    paddingRight: padding.xsmall2,
    paddingTop: padding.xsmall1,
    textTransform: "none",
    "&.Mui-disabled": {
      background: colors.white,
    },
    "& .MuiButton-label": {
      display: "flex",
      gap: gaps.small1,
    },
    "& .rotate-90": {
      transform: "rotate(90deg)"
    },
  },
});

const FilesPageHeader = ({
  columns,
  hiddenColumns,
  updateHiddenColumns,
  handleAdvancedFilterChange,
  handleAdvanceSearchPanelOpen,
}: Props) => {
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState<boolean>(false);
  const [, { setColumnDefinition }] = useMyFiles();

  const handleSearchPanel = (open: boolean) => {
    setAdvancedSearchOpen(open);
    handleAdvanceSearchPanelOpen(open);
  };

  const handleColumnChecked = (id: keyof FilesRecord, checked: boolean) => {
    if (checked) {
      updateHiddenColumns?.([...hiddenColumns, id]);
    } else {
      updateHiddenColumns?.([...hiddenColumns].filter((col) => col !== id));
    }

    setColumnDefinition([id], "hidden", checked);
  };

  const handleHideAllColumns = () => {
    if (hiddenColumns.length === columns.length) return;

    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.(fieldIds);
    setColumnDefinition(fieldIds, "hidden", true);
  };

  const handleShowAllColumns = () => {
    if (hiddenColumns.length === 0) return;

    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.([]);
    setColumnDefinition(fieldIds, "hidden", false);
  };

  return (
    <Container>
      <ActionButtonGroup>
        <Button
          onClick={() => handleSearchPanel(!advancedSearchOpen)}
          data-test="myFilesAdvanced"
        >
          <>
            Advanced
            <SearchIcon />
          </>
        </Button>
        <ColumnsConfig
          options={columns}
          onChecked={handleColumnChecked}
          onHideAll={handleHideAllColumns}
          onShowAll={handleShowAllColumns}
        />
      </ActionButtonGroup>
      <AdvancedSearch
        key={`adv_search_${advancedSearchOpen}`}
        open={advancedSearchOpen}
        handleFilterChange={advancedSearchOpen ? handleAdvancedFilterChange : () => { }}
        page={PageType.MyFilesPage}
      />
    </Container>
  );
};

export default FilesPageHeader;
