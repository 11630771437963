import { styled } from "@material-ui/core";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import DatePickerField from "controls/global/datepicker";
import { isValidDate } from "pages/file/utils/helper";
import React from "react";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  isNewFile: boolean;
  noIssuedProducts: boolean;
  isOverride: boolean;
  overrideReasonError: boolean;
  enableCalculate: (ignoreOverride?: boolean, enableOnly?: boolean) => void;
  isLocked: boolean;
};

const HELP_MESSAGE = "The Rate Effective Date is used when a state has a rate change, or when your agency's contractual split for premiums has changed. The premium calculations below may update based on the Rate Effective Date.";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
  "& .MuiInputBase-input": {
    paddingRight: padding.small2,
  },
  "& .MuiSvgIcon-root": {
    alignItems: "center",
    display: "flex",
    height: "100%",
  },
});

export default function RateEffectiveDateField({
  isNewFile,
  noIssuedProducts,
  isOverride,
  enableCalculate,
  isLocked,
}: Props) {
  const { getValues } = useFormWrapper();
  const isEffectiveDateAllowed: boolean = getValues(
    "pricing.isEffectiveDateAllowed"
  );
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");

  const handleRateEffectiveDateChange = (date: Date | null) => {
    if (date !== null && !isValidDate(date)) return;
    enableCalculate();
  };

  return (
    <Container>
      <DatePickerField
        disabled={
          !isIntegratedPricing ||
          noIssuedProducts ||
          isNewFile ||
          isLocked ||
          Boolean(isOverride) ||
          !isEffectiveDateAllowed
        }
        label="Rate Effective Date (optional)"
        name="pricing.rateEffectiveDate"
        validateOnBlur={false}
        defaultToToday={false}
        onChangeCallback={handleRateEffectiveDateChange}
      />
      <CustomTooltip
        title={HELP_MESSAGE}
        placement="top"
        aria-label="lender-contact-info"
        arrow
      >
        <StyledInfoIcon />
      </CustomTooltip>
    </Container>
  );
}
