import React from "react";
import { Grid } from "@material-ui/core";
import TextInput from "controls/global/text-input-field/TextInput";

type ProductDetailsProps = {
  issueDateName: string;
  issueDate: string;
  serialNumberName: string;
  serialNumber?: string;
  statusName: string;
  status?: string;
};

/**
 * Display product status information only.
 * All fields should be read only.
 * @issueDateName     only used to set the id for TextInputField component, not the object's proeprty name
 * @issueDate         the formatted product issue date
 * @serialNumberName  only used to set the id for TextInputField component, not the object's proeprty name
 * @serialNumber      product serial number
 * @statusName        only used to set the id for TextInputField component, not the object's proeprty name
 * @status            product order status type name
 * @returns
 */

const ProductStatus = (props: ProductDetailsProps) => {
  return (
    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
      <Grid item xs={12} md={4} lg={4}>
        <TextInput
          name={props.issueDateName}
          label="Issue Date"
          disabled
          value={props.issueDate}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextInput
          name={props.serialNumberName}
          label="Serial Number"
          disabled
          value={props.serialNumber}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextInput
          name={props.statusName}
          label="Status"
          disabled
          value={props.status}
        />
      </Grid>
    </Grid>
  );
};

export default ProductStatus;
