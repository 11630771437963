import { styled } from "@material-ui/core";
import CircularProgress,
{
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React,
{
  useEffect,
  useState,
} from 'react';

const ProgressContainer = styled("div")({
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.9)",
  borderRadius: "16px",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: "16px",
  position: "relative",
  "& .MuiCircularProgress-root": {
    color: "#015E88",
    height: "60px !important",
    width: "60px !important",
  },
});

const LabelContainer = styled("div")({
  alignItems: "center",
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  left: 0,
  position: "absolute",
  right: 0,
  top: 0,
  "& .MuiTypography-root": {
    fontSize: "16px",
  },
});

const CircularProgressWithLabel = (props: CircularProgressProps & { value?: number }) => {

  const [showLabel, setShowLabel] = useState(false);

  useEffect(() => {
    setShowLabel(props.value !== undefined && props.value >= 0);
  }, [props.value])

  return (
    <ProgressContainer>
      <CircularProgress variant={showLabel ? "determinate" : "indeterminate"} {...props} />
      {showLabel &&
        <LabelContainer>
          <Typography component="div" color="textSecondary">{`${Math.round(
            props.value ?? 0,
          )}%`}</Typography>
        </LabelContainer>
      }
    </ProgressContainer >
  );
}

export default CircularProgressWithLabel;
