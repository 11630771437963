import React from "react";
import TextInputField from "controls/global/text-input-field";

type Props = {
  name: string;
  disabled: boolean;
};

export default function AddressTwo({ name, disabled }: Props) {
  return (
    <TextInputField label="Address Line 2" name={name} disabled={disabled} />
  );
}
