import {
  makeStyles,
  styled,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import { FileActivity } from "entities/UIModel";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
} from "theme/defaultStyle";
import {
  formatCurrency,
  formatDateTime,
} from "utils/shared";
import TooltipCell from "controls/global/stewart-table/TooltipCell";

interface Props {
  record?: FileActivity;
}

const StyledNoRow = styled(TableRow)({
  backgroundColor: colors.white,
  verticalAlign: "top",
});

type StyledTableCellProps = {
  minWidth?: string;
} & TableCellProps;

const StyledTableCell = styled(TableCell)((props: StyledTableCellProps) => ({
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  minWidth: props.minWidth,
  whiteSpace: "pre-line",
}));

const emphasisStyles = makeStyles((theme) => ({
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic", display: "inline-block" },
  underline: { textDecorationLine: "underline" },
}));

const FileHistoryBodyRow = ({ record }: Props) => {
  if (!record)
    return (
      <StyledNoRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledNoRow>
    );

  const classes = emphasisStyles();
  const { date, userName, fileActivityID, description } = record;

  const formattedUser = () => userName.replaceAll("|", " ");

  const formatPricing = (recordDescription: string) => {
    const dollarSignPos = recordDescription.indexOf("$");

    if (dollarSignPos > 0) {
      const decimalPos = recordDescription.indexOf(".");

      if (decimalPos > 0) {
        const price = recordDescription.substring(
          dollarSignPos,
          decimalPos + 3
        );
        const formattedPrice = formatCurrency(Number(price.substring(1)));
        const newDescription = recordDescription.replace(price, formattedPrice);
        return newDescription;
      } else {
        return recordDescription;
      }
    } else {
      return recordDescription;
    }
  };

  const emphasizedText = (text1: string, text2: string) => {
    const primaryText = text1.split("|");
    const italics = <div className={classes.italic}>{text2}</div>;

    if (primaryText.length === 2) {
      return (
        <>
          <div>{primaryText[0]}</div>
          <div style={{ whiteSpace: "nowrap" }}>
            {primaryText[1]}
            {italics}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {primaryText[0]}
            {italics}
          </div>
        </>
      );
    }
  };

  const regularText = (text1: string) => {
    return <span>{text1}</span>;
  };

  const activityText = () => {
    const italicizeText = formatPricing(description).split("~");
    return italicizeText.length === 3
      ? emphasizedText(italicizeText[0], italicizeText[1])
      : regularText(italicizeText[0].replaceAll("|", " "));
  };

  return (
    <TableRow key={fileActivityID}>
      <StyledTableCell>{formatDateTime(date, false)}</StyledTableCell>
      <StyledTableCell>{formattedUser()}</StyledTableCell>
      <StyledTableCell minWidth="400px">
        <TooltipCell title={activityText()} />
      </StyledTableCell>
      <StyledTableCell>{record.originationSource}</StyledTableCell>
    </TableRow>
  );
};

export default FileHistoryBodyRow;
