import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { styled } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import { round } from "pages/file/utils/helper";

type Props = {
  value: number;
  min: number;
  max: number;
  step: number;
  onZoom: (zoom: number) => void;
};

const CustomSlider = styled(Slider)({
  width: 300,
  margin: "0 3px",
});

const iconSize = {
  width: 15,
  height: 15,
};

export default function ZoomImage({ value, min, max, step, onZoom }: Props) {
  const handleZoom = (_: React.ChangeEvent<{}>, value: number | number[]) => {
    if (Array.isArray(value)) return;
    onZoom(value);
  };

  const handleZoomOut = () => {
    if (value === min) return;

    onZoom(round(value - step));
  };

  const handleZoomIn = () => {
    if (value === max) return;
    onZoom(round(value + step));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconButton onClick={handleZoomOut} disabled={value === min}>
        <RemoveIcon style={iconSize} />
      </IconButton>
      <CustomSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleZoom}
        marks
      />
      <IconButton onClick={handleZoomIn} disabled={value === max}>
        <AddIcon style={iconSize} />
      </IconButton>
    </Box>
  );
}
