import { usePricingConfig } from "utils/context/PricingConfigContext";

import useProfileSettings from "./useProfileSettings";

function useConfig() {
  const [{ pricingConfigs }] = usePricingConfig();
  const { getCurrentDate } = useProfileSettings();

  function getPricingConfig(configKey: string) {
    const pricingConfig = pricingConfigs?.find(
      (c) => c.configKey === configKey
    );

    return pricingConfig;
  }

  function isPremiumVisible(isNewJacket: boolean, jacketIssueDate?: Date) {
    // CHECK IF JACKET IS NEW OR PENDING/FAILED
    if (isNewJacket || !jacketIssueDate)
      return isAfterPremiumEffectiveDate(getCurrentDate());

    // CHECK IF JACKET WAS ISSUED
    return isAfterPremiumEffectiveDate(jacketIssueDate);
  }

  function isAfterPremiumEffectiveDate(date: Date) {
    const premiumConfig = getPricingConfig("ShortFormPremiumEffectiveDate");
    if (!premiumConfig) return false;

    const premiumEffectiveDate = new Date(premiumConfig.configValue!);

    return (
      date.setHours(0, 0, 0, 0) > premiumEffectiveDate.setHours(0, 0, 0, 0)
    );
  }

  return {
    isPremiumVisible,
    getPricingConfig,
  };
}

export default useConfig;
