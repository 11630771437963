import React from 'react';
import { InputAdornment, styled } from '@material-ui/core';
import SearchIcon from "theme/icons/SearchIcon";
import { colors } from "theme/defaultStyle";

const StyledSearchIcon = styled(SearchIcon)({
    width: 20,
    height: 20,
    color: colors.grey06
})

const InputSearchAdornment = () => (
    <InputAdornment position="end" >
        <StyledSearchIcon />
    </InputAdornment>
);

export default InputSearchAdornment;