import {
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import StewartSelect from "controls/global/stewart-select";
import { DateType } from "entities/UIModel";
import React, {
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import {
  DateTypeCode,
  PageType,
} from 'utils/data/enum';

interface Props<T> {
  page: PageType;
  handleDateTypeChange: (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => void;
  dateType: string;
  variant?: any;
}

const usePaperStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiMenuItem-root:hover": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
  },
}));

const DateTypeField = <T extends object>({ page, handleDateTypeChange, dateType, ...rest }: Props<T>) => {
  const classes = usePaperStyles();
  const [dateTypes, setDateTypes] = useState<DateType[]>([]);

  const getDateTypes = (page: PageType.MyFilesPage | PageType.PendingPayPage | PageType.ReportPayPage): DateType[] => {
    const dateTypeMappings: { [key in DateTypeCode]: string } = {
      [DateTypeCode.Created]: "Opened",
      [DateTypeCode.Modified]: "Modified",
      [DateTypeCode.EffectiveDate]: "Effective Date",
      [DateTypeCode.IssueDate]: "Issue Date",
      [DateTypeCode.ReportedDate]: "Reported Date",
    };

    let dateTypes: DateTypeCode[] = [];
    switch (page) {
      case PageType.MyFilesPage:
        dateTypes = [DateTypeCode.Created, DateTypeCode.Modified];
        break;
      case PageType.ReportPayPage:
        dateTypes = [DateTypeCode.Created, DateTypeCode.Modified, DateTypeCode.EffectiveDate, DateTypeCode.IssueDate];
        break;
      case PageType.PendingPayPage:
        dateTypes = [DateTypeCode.Created, DateTypeCode.Modified, DateTypeCode.EffectiveDate, DateTypeCode.IssueDate, DateTypeCode.ReportedDate];
        break;
      default:
        dateTypes = Object.values(DateTypeCode);
        break;
    }
    const formattedDateTypes: DateType[] = dateTypes.map((code: DateTypeCode) => ({
      code,
      name: dateTypeMappings[code],
      selected: (page === PageType.MyFilesPage && code === DateTypeCode.Created) ||
        (page === PageType.ReportPayPage && code === DateTypeCode.IssueDate) ||
        (page === PageType.PendingPayPage && code === DateTypeCode.ReportedDate),
      subType: ""
    }));

    return formattedDateTypes;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleDateTypeChange("dateTypeCode" as keyof T, value, false);
  };

  useEffect(() => {
    const getAsync = () => {
      const data: DateType[] = getDateTypes(page);

      if (!data || data.length === 0) return;
      setDateTypes(
        data.sort((dateTypeFirst: DateType, dateTypesecond: DateType) =>
          dateTypeFirst.name.localeCompare(dateTypesecond.name)
        )
      );

      const modifiedDateType = data.find((t) => t.selected);
      if (!modifiedDateType) return;
      handleDateTypeChange("dateTypeCode" as keyof T, modifiedDateType.code, false);
    };

    getAsync();
  }, []);

  return (
    <StewartSelect
      select
      fullWidth
      label="Date Type"
      InputProps={{
        disableUnderline: true,
        style: { color: colors.black },
      }}
      onChange={handleChange}
      value={dateType}
      disabled={!dateTypes || dateTypes.length === 0}
      SelectProps={{
        MenuProps: {
          classes: {
            paper: classes.paper,
          },
          getContentAnchorEl: null,
        },
      }}
      {...rest}
    >
      {dateTypes.map((type) => (
        <MenuItem key={type.code} value={type.code}>
          {type.name}
        </MenuItem>
      ))}
    </StewartSelect>
  );
};

export default DateTypeField;
