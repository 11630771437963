import { axiosSecuredInstance } from "configurations/axiosConfig";
import { PricingConfig } from "entities/UIModel/PricingConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { PricingConfigKey } from "utils/data/enum";

interface State {
  pricingConfigs: Array<PricingConfig>;
  error: unknown | null;
}
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const initialState = {
  pricingConfigs: [],
  error: null,
};

const actions = {
  getPricingConfigs:
    (state: string, agencyId: string, agencyLocation: string) =>
    async ({ setState }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(
          `Configuration/pricingconfig?StateAbbr=${state}&AgencyID=${agencyId}&LocationID=${agencyLocation}`
        );

        const mapConfigs: Array<PricingConfig> = data.map((pc: any) => {
          return {
            integrationKey: pc.IntegrationKey,
            agencyID: pc.AgencyID,
            configDescription: pc.ConfigDescription,
            configGroup: pc.ConfigGroup,
            configKey: pc.ConfigKey,
            configValue: pc.ConfigValue,
            form: pc.Form,
            formType: pc.FormType,
            liabilityMaxInMillions: pc.LiabilityMaxInMillions,
            locationID: pc.LocationID,
            orderOriginationSource: pc.OrderOriginationSource,
            partitionYear: pc.PartitionYear,
            pricingConfigID: pc.PricingConfigId,
            product: pc.Product,
            propertyType: pc.PropertyType,
            rateType: pc.RateType,
            reIssue: pc.ReIssue,
            stateAbbr: pc.StateAbbr,
            transactionType: pc.TransactionType,
          };
        });

        const newPricingConfigs =
          mapConfigs && mapConfigs.length > 0 ? mapConfigs : [];

        const sortedPricingConfigs = newPricingConfigs?.sort(
          (a, b) => 
            (b.stateAbbr || "").localeCompare(a.stateAbbr || "") || // 1st order DESC
            (b.agencyID || "").localeCompare(a.agencyID || "") // 2nd order DESC
        );

        setState({ pricingConfigs: sortedPricingConfigs });

        // sort by State DESC, AgencyID DESC
        return sortedPricingConfigs
      } catch (error) {
        console.error('The "pricingConfig" context has errors.', error);
        return;
      }
    },
  resetPricingConfigs:
    () =>
    ({ setState }: StoreApi) => {
      setState({ pricingConfigs: [] });
    },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: "pricingConfig",
});

const hook = createHook(Store);
const usePricingConfig = () => {
  return hook();
};
const useLiabilityConfig = createHook(Store, {
  selector: (state: State) => {
    const config = state.pricingConfigs.find(
      (c) => c.configKey === PricingConfigKey.LiabilityLimit
    );

    return {
      form: config?.form,
      configValue: config?.configValue,
    };
  },
});

export { usePricingConfig, useLiabilityConfig };


