import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from '@mui/icons-material/Clear';
import ActionButton from "controls/global/action-button";
import LinearProgressWithLabel from "controls/global/linear-progress-with-label";
import React,
{
  useCallback,
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import {
  useIsActionInProgressFiles,
  useIsFileLoadingFiles,
} from "utils/context/FilesContext";
import {
  useProcessStatusTracking,
  useProcessStatusTrackingState,
} from "utils/context/ProcessStatusContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import { useInterval } from "utils/custom-hooks/useInterval";

const maxDelayCounter: number = 90; // Counter to reset Interval for polling

type Props = {
  title: string;
  confirmationMessage?: string;
  closeButtonText?: string;
  isOpen: boolean;
  onClose: any;
  autoClose: boolean;
  requestId: string;
  onCloseAction?: any;
  runProgress?: boolean;
  progressBaseline?: number;
};

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#F2F2F2",
  },
  barColorPrimary: {
    backgroundColor: `${colors.blue01}`,
  },
})(LinearProgressWithLabel);

const ConfirmationDialogWithProgressbar = ({
  closeButtonText,
  onClose,
  onCloseAction,
  requestId,
  isOpen,
  runProgress,
  autoClose,
  progressBaseline,
  title,
  confirmationMessage,
}: Props) => {
  const classes = useDialogStyles({ type: "INFO", width: 580 });
  const trackProcessStatusRef = React.useRef<boolean>(false);
  const [{ maxWait, pctComplete }] = useProcessStatusTrackingState();
  const [, { getProcessStatus, reset }] = useProcessStatusTracking();
  const [progressLineValue, setProgressLineValue] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const [{ isActionInProgress }] = useIsActionInProgressFiles();
  const isLoadingSomething = isFileLoading || isActionInProgress;

  useEffect(() => {
    if (isOpen) {
      trackProcessStatusRef.current = true;
    }
    return () => {
      trackProcessStatusRef.current = false;
      reset();
    };
  }, [isOpen, reset]);

  useInterval(
    async () => {
      await getProcessStatus(requestId, trackProcessStatusRef.current);
    },
    isOpen && runProgress && pctComplete < 100 && maxWait < maxDelayCounter
      ? 1000
      : null
  );

  const closeDialog = useCallback(() => {
    onClose();
    onCloseAction && onCloseAction();
  }, [onClose, onCloseAction]);

  const completedProcess = React.useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
    }, 3500);
    setTimeout(() => {
      autoClose ? closeDialog() : setShowCloseButton(true);
    }, 3000);
  }, [autoClose, closeDialog]);

  useEffect(() => {
    if (runProgress) {
      if (
        //isCompleted
        !isLoadingSomething &&
        !isClosing &&
        (pctComplete === 100 || maxWait >= maxDelayCounter)
      ) {
        completedProcess();
      }
    } else {
      setShowCloseButton(false);
    }
  }, [
    completedProcess,
    isClosing,
    isLoadingSomething,
    maxWait,
    pctComplete,
    runProgress,
  ]);

  React.useEffect(function updateProgressLineValue() {
    const calculatedProgress =
      progressBaseline !== undefined && pctComplete < progressBaseline
        ? progressBaseline
        : pctComplete;
    if (isOpen) {
      setProgressLineValue(calculatedProgress);
    }
    else {
      setProgressLineValue(0)
    }
  }, [isOpen, pctComplete, runProgress, progressBaseline]);

  return (
    <Dialog open={isOpen} maxWidth="md" classes={{ root: classes.dialogRoot }}>
      <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitleRoot }}>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {confirmationMessage}
        <StyledLinearProgress value={progressLineValue} />
      </DialogContent>
      {autoClose || !showCloseButton ? (<DialogActions />) : (
        <DialogActions
          classes={{
            root: classes.dialogActionRoot,
          }}
        >
          <ActionButton

            variant="outlined"
            color="primary"
            onClick={closeDialog}
            startIcon={<ClearIcon />}
          >
            {closeButtonText ?? "Close"}
          </ActionButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialogWithProgressbar;
