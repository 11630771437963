import React from "react";
import { Radio, RadioProps } from "@material-ui/core";
import { colors } from "theme/defaultStyle";

const StewartRadio = (props: RadioProps) => {

  const customStyles = {
    color: colors.blue01,
    '&.Mui-checked': {
      color: colors.blue01,
    },
    '&.Mui-disabled': {
      color: colors.blue09,
    },
  }

  return (
    <Radio style={customStyles} {...props} />
  );
};

export default StewartRadio;
