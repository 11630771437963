import {
  styled,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  padding,
} from "theme/defaultStyle";

interface HeadCell {
  id: string;
  label: string;
  width: string;
}

const headCells: HeadCell[] = [
  { id: "name", label: "NAME", width: "237px" },
  { id: "image", label: "IMAGE", width: "279px" },
  { id: "edit", label: "EDIT", width: "54px" },
  { id: "remove", label: "DELETE", width: "74px" },
];

const StyledTableRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    color: colors.grey08,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal2,
    letterSpacing: letterSpacing.medium2,
    lineHeight: lineHeight.large,
    paddingBottom: padding.zero,
    paddingLeft: padding.small1,
    paddingRight: padding.small1,
    paddingTop: padding.zero,
  },
});

const SignaturesTableHeader = () => {
  return (
    <>
      <colgroup>
        {headCells.map((cell) => (
          <col
            key={cell.id}
            width={cell.width}
          />
        ))}
      </colgroup>
      <TableHead>
        <StyledTableRow>
          {headCells.map((cell) => (
            <TableCell key={cell.id}>
              {cell.label}
            </TableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    </>
  );
}

export default SignaturesTableHeader;
