import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import React, {
  useEffect,
} from "react";
import {
  borderRadius,
  boxShadows,
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { MetaTab } from "utils/context/StewartAccessContext";
import TabPanel from "./TabPanel";

interface Props {
  children?: Array<React.ReactNode>;
  tabTitle: Array<string>;
  metaTabs?: Array<MetaTab>;
  padding?: string;
  tabsWithErrors?: Array<string>;
  onKeyDown?: (event: KeyboardEvent) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  navigatorContainer: {
    background: "transparent",
    padding: padding.zero,
    "& .MuiTabs-root": {
      minHeight: 48
    }
  },
  tabs: {
    borderRadius: `${borderRadius.large} ${borderRadius.large} 0 0`,
    boxShadow: boxShadows.boxShadow04,
    height: 48,
    marginBottom: margin.zero,
    marginLeft: margin.zero,
    marginRight: margin.zero,
    marginTop: margin.xsmall1,
    width: "fit-content",
    "& .MuiButtonBase-root.MuiTab-root": {
      "& p": {
        color: colors.blue05,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      opacity: 1,
    },
    "& button.MuiTab-labelIcon": {
      minHeight: 45
    },
    "& .MuiButtonBase-root.MuiTab-root:first-child": {
      borderRadius: `${borderRadius.large} 0 0 0`,
    },
    "& .MuiButtonBase-root.MuiTab-root:last-child": {
      borderRadius: `0 ${borderRadius.large} 0  0`,
    },
    "& .MuiButtonBase-root.MuiTab-root:only-child": {
      borderRadius: `${borderRadius.large} ${borderRadius.large} 0 0`,
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      backgroundColor: colors.white,
      textDecoration: "none",
      "& p": {
        color: colors.blue01,
      },
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },
    "& .MuiSvgIcon-root": {
      color: colors.red01,
      marginRight: margin.xsmall,
    },
  },
  tab: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: colors.grey10,
    },
  },
  indicator: {
    backgroundColor: colors.blue01,
  },
  tabPanel: {
    backgroundColor: colors.white,
    borderRadius: `0 ${borderRadius.xlarge} ${borderRadius.xlarge} ${borderRadius.xlarge}`,
    boxShadow: boxShadows.boxShadow05,
    margin: margin.zero,
  },
}));


const TabNavigator = ({
  children,
  tabTitle,
  padding = margin.xlarge1,
  tabsWithErrors = [],
  onKeyDown,
}: Props) => {
  const { indicator, navigatorContainer, tab, tabPanel, tabs } =
    useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const setToZero = () => setValue(0);
    document.addEventListener("documentReset", setToZero);
    return () => {
      document.removeEventListener("documentReset", setToZero);
    };
  });

  useEffect(() => {
    if (children && children.length > 0 && value > children.length - 1) {
      setValue(0);
    }
  }, [value, children]);

  const tabValue = (tabTitle.length - 1) < value ? 0 : value;
  const iconStyles = { fontSize: 20, color: colors.red01 };

  return (
    <Container maxWidth="lg" className={navigatorContainer}>
      <Tabs
        {...{ value: tabValue }}
        onChange={handleChange}
        classes={{
          flexContainer: tabs,
          indicator,
        }}
      >
        {tabTitle.map((title, index) => {
          const hasErrors = tabsWithErrors.indexOf(title) >= 0;

          return (
            <Tab
              className={tab}
              id={title}
              key={`${index}-${title}`}
              icon={hasErrors ? <ReportProblemIcon style={iconStyles} /> : undefined}
              onKeyDown={(evt: any) => {
                onKeyDown?.(evt);
              }}
              label={<Typography>{title}</Typography>}
            />
          );
        })}
      </Tabs>
      {children ? (
        children.map((child, index) => (
          <Box key={index}>
            <TabPanel padding={padding} {...{ index, value }} classes={tabPanel}>
              {child}
            </TabPanel>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Container>
  );
};

export default TabNavigator;
