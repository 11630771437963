import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from '@mui/icons-material/Clear';
import ActionButton from "controls/global/action-button/ActionButton";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import { useSignatureDispatchProvider } from "pages/signatures/providers/manage-signature/SignatureProvider";
import {
  StepKeys,
  useWizardProvider,
} from "pages/signatures/providers/manage-signature/WizardProvider";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";

type Props = {
  activeStep: StepKeys;
};

export default function BackButton({ activeStep }: Props) {
  const { dispatch: configurationDispatch } = useConfigurationProvider();
  const { dispatch: wizardDispatch } = useWizardProvider();
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();
  const [icon, setIcon] = useState<ReactNode>(<ClearIcon />)
  const [text, setText] = useState<string>("Cancel")
  const handleBack = () => {
    if (activeStep === "MENU") {
      configurationDispatch({ type: "RESET" });
      signatureDispatch({ type: "CLOSE" });
      wizardDispatch({ type: "RESET" });
    }
    else {
      wizardDispatch({ type: "BACK" });
    }
  };

  useEffect(() => {
    if (activeStep === "MENU") {
      setIcon(<ClearIcon />);
      setText("Cancel")
    }
    else {
      setIcon(<ArrowBackIcon />);
      setText("Back")
    }

  }, [activeStep]);

  return (
    <ActionButton
      variant="outlined"
      color="secondary"
      onClick={handleBack}
      startIcon={icon}
    >
      {text}
    </ActionButton>
  );
}
