import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { colors } from "theme/defaultStyle";

const Container = styled.div`
  align-items: center;
  background: ${colors.white};
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 100%;
  height: 100vh;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  left: 0px;
`;

const Loading = () => (
  <Container>
    <CircularProgress color="primary" />
  </Container>
);

export default Loading;
