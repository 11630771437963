import {
  Box,
  makeStyles,
  styled,
  Typography,
} from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography/Typography";
import StewartCheckbox from "controls/global/stewart-checkbox";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";

const useStyles = makeStyles({
  flexColumn: {
    display: "flex",
    flexDirection: "row",
  },
});

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "15px",
  paddingRight: "5px",
});

interface LabelTypographyProps extends TypographyProps {
  isDisabled?: boolean;
}

const Label = styled(Typography)((props: LabelTypographyProps) => ({
  fontSize: fontSize.large,
  color: props.isDisabled ? colors.grey11 : colors.grey08,
}));

interface Props {
  disabled: boolean;
  onlyShowSelectedFiles: boolean;
  onOnlySelectedFilesChange?: (value: boolean) => void;
}

const ReportPayFooterAction = ({
  disabled,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: Props) => {
  const classes = useStyles();
  const [onlySelectedFilesChecked, setOnlySelectedFilesChecked] = useState<boolean>(onlyShowSelectedFiles);

  const handleOnlySelectedFilesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setOnlySelectedFilesChecked(isChecked);
    onOnlySelectedFilesChange && onOnlySelectedFilesChange(isChecked);
  };

  useEffect(() => {
    if (!disabled) return;
    setOnlySelectedFilesChecked(false);
    onOnlySelectedFilesChange?.(false);
  }, [disabled]);

  useEffect(() => {
    setOnlySelectedFilesChecked(onlyShowSelectedFiles);
  }, [onlyShowSelectedFiles]);

  return (
    <Box className={classes.flexColumn}>
      <StyledBox>
        <StewartCheckbox
          disabled={disabled}
          checked={onlySelectedFilesChecked}
          onChange={handleOnlySelectedFilesChange}
        />
        <Label isDisabled={disabled}>Only show selected files</Label>
      </StyledBox>
      <StyledBox>* Excluded from Auto-reporting</StyledBox>
    </Box>
  );
};

export default ReportPayFooterAction;
