import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import LightTooltip from "controls/global/light-tooltip";
import StewartInput from "controls/global/stewart-input";
import React from "react";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from "react-number-format";
import useHover from "utils/custom-hooks/useHover";

function NumberFormatCustom(
  props: NumberFormatProps | InputBaseComponentProps | any //FIXME: any should be removed
) {
  const { inputRef, onChange, prefix, ...other } = props;
  const handleOnChange = (values: NumberFormatValues) => {
    onChange?.({
      target: {
        name: props.name,
        value: parseFloat(values.value),
      },
    });
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleOnChange}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      prefix={prefix}
      defaultValue=""
    />
  );
}

type Props = {
  name: string;
  value: number;
  prefix?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  isZeroAllowed?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  styles: object;
  label?: string;
  size?: "small" | "medium";
};

export default function NumberInputPricing({
  name,
  value,
  prefix = "$",
  placeholder = "$0.00",
  disabled,
  error,
  className,
  isZeroAllowed = false,
  onChange,
  onBlur,
  styles,
  label,
  size = "medium",
}: Props) {
  const {
    htmlElement: { open, text },
    evalHover,
    elRef,
  } = useHover();

  return (
    <LightTooltip
      title={text}
      interactive
      disableFocusListener
      open={open}
      arrow
    >
      <StewartInput
        id={`ID|${name}`}
        placeholder={placeholder}
        name={name}
        value={!isZeroAllowed && value === 0 ? "" : value}
        disabled={disabled}
        onMouseOver={() => evalHover(true)}
        onMouseLeave={() => evalHover(false)}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        size={size}
        label={label}
        variant="outlined"
        InputProps={{
          inputComponent: NumberFormatCustom,
          disableUnderline: true,
        }}
        inputRef={elRef}
        inputProps={{
          "aria-errormessage": error ? "rhfvalidationerror" : "",
          prefix,
          className,
          style: {
            ...styles,
            paddingRight: "10px",
          },
        }}
      />
    </LightTooltip>
  );
}
