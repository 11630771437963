import {
  Box,
  debounce,
  styled,
} from "@material-ui/core";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { DeleteCriteria } from "entities/ApiModel";
import {
  PricingConfig,
  Property,
  SCFile,
} from "entities/UIModel";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useCompanyContext } from "utils/context/CompanyContext";
import { useCompanyCountyActions } from "utils/context/CompanyCountiesContext";
import { useCompanyMunicipal } from "utils/context/CompanyMunicipalContext";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { useCompanyRemittance } from "utils/context/CompanyRemittanceContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import { useFiles } from "utils/context/FilesContext";
import { useModalContext } from "utils/context/ModalContext";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFocus from "utils/custom-hooks/useFocus";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusType,
  PricingConfigKey,
} from "utils/data/enum";
import { getDefaultPropertyObject } from "utils/data/property";
import { v4 as uuidv4 } from "uuid";
import PropertyData from "./PropertyData";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const PropertyCollection = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<number>(-1);
  const [isPropertyDisabled, setIsPropertyDisabled] = useState(false);
  const [isStatesDisabled, setIsStatesDisabled] = useState(false);
  const [taxDocument, setTaxDocument] = useState("");
  const [showTaxCode, setShowTaxCode] = useState(false);
  const stateCodeRef = useRef(null);
  const stateAbbrRef = useRef(null);
  const agencyIdRef = useRef(null);
  const agencyLocationIdRef = useRef(null);
  const taxDocumentRef = useRef<string | null>(null);
  const [{ isModalOpen, modalMessage }, { onModalAccept, onModalCancel }] =
    useModalContext();
  const isFileDisabled = useFieldDisabler("AddPropertyButton");

  const { fields, append, remove } = useFieldArray({
    name: "properties",
    keyName: "fieldId",
  });

  const { setValue, getValues, nameString, setFocus } = useFormWrapper();

  const [
    fileNameNumber,
    agency,
    primaryProperty,
    additionalParties,
    hasIssuedProducts,
    isPopulatingExistingFile,
    agencyLocation,
  ] = useWatch({
    name: [
      "fileNameNumber",
      "agency",
      nameString(`properties.0`),
      "additionalParties",
      "hasIssuedProducts",
      "isPopulatingExistingFile",
      "agencyLocation",
    ],
  });

  const [state] = useFilePropertyState();

  const [, { getMunicipal }] = useCompanyMunicipal();
  const [, { getRemittance }] = useCompanyRemittance();
  const [, { getCounties, resetCounties }] = useCompanyCountyActions();
  const [{ agencies }] = useCompanyContext();
  const [, { deleteProperty, deleteFileData, resetProductAndAdditionalParty }] =
    useFiles();
  const [, { getPricingConfigs, resetPricingConfigs }] = usePricingConfig();
  const [, { resetCompanyProductOptions, getCompanyProductOptions }] = useCompanyProductOptions();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const { goElementFocus, setFocusElement } = useFocus();

  const properties = getValues("properties") as Array<Property>;

  const addPropertyRef = useRef<HTMLButtonElement>(null);

  const resetPropertyData = () => {
    setShowTaxCode(false);
  };

  const openDeleteDialog = (deleteProdIndex: number) => {
    setDeleteItemIndex(deleteProdIndex);
    setDialogOpen(true);
  };

  const deleteItem = () => {
    if (properties && deleteItemIndex >= 0) {
      const propertyCount = properties.length;
      if (properties.length === 1) {
        resetCounties();
        resetCompanyProductOptions();
      }
      remove(deleteItemIndex);
      // Check if the product is saved already
      // If yes, delete from db
      if (properties.length - 1 >= deleteItemIndex) {
        const deletePropertyId = properties[deleteItemIndex].id || 0;
        if (deleteItemIndex === 0 && propertyCount === 1) {
          deletePendingFileData(deletePropertyId);
        } else if (deletePropertyId > 0) {
          deleteProperty(properties[deleteItemIndex].fileID, deletePropertyId);
        }
      }
    }
    setDeleteItemIndex(-1);
    setDialogOpen(false);

    const propertiesCount = getValues('properties').length;
    if (propertiesCount === deleteItemIndex) {
      if (propertiesCount === 1) {
        setFocusElement(setFocus, nameString(`properties.0.addressOne`));
      } else {
        goElementFocus(addPropertyRef);
      }
    } else {
      setFocusElement(setFocus, nameString(`properties.${deleteItemIndex}.addressOne`));
    }
  };

  const deletePendingFileData = (propertyID: number) => {
    const scfile = getValues() as SCFile;

    let sOrderIds = "";
    let sFilePartyIds = "";
    // delete products from database
    scfile.jackets
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    scfile.cpls
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    scfile.aALProducts &&
      scfile.aALProducts
        ?.filter(
          (p) =>
            (p.orderID || 0) > 0 &&
            p.orderStatusTypeCode === OrderStatusType.Pending
        )
        ?.forEach((p) => {
          sOrderIds += `${p.orderID},`;
        });

    scfile.standaloneEndorsements
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    // delete additional parties from database
    scfile.additionalParties
      ?.filter((party) => (party.filePartyId || 0) > 0)
      ?.forEach((party) => {
        sFilePartyIds += `${party.filePartyId},`;
      });

    if (sOrderIds.length > 1)
      sOrderIds = sOrderIds.substring(0, sOrderIds.length - 1);
    if (sFilePartyIds.length > 1)
      sFilePartyIds = sFilePartyIds.substring(0, sFilePartyIds.length - 1);

    // delete file data
    const deleteCriteria: DeleteCriteria = {
      FileID: scfile.id || 0,
      PropertyID: propertyID,
      OrderIDs: sOrderIds,
      FilePartyIDs: sFilePartyIds,
    };
    if (
      deleteCriteria &&
      deleteCriteria.FileID > 0 &&
      ((deleteCriteria.PropertyID && deleteCriteria.PropertyID > 0) ||
        deleteCriteria.OrderIDs ||
        deleteCriteria.FilePartyIDs)
    ) {
      deleteFileData(deleteCriteria);
    }

    resetProductAndAdditionalParty(scfile);

    setValue(`hasNonDefaultLetterProduct`, false);
    setValue(`hasNonDefaultJacket`, false);
    setValue(`hasNonDefaultSAE`, false);
  };

  const closeDeleteDialog = () => {
    setDialogOpen(false);
    setFocusElement(setFocus, nameString(`properties.${deleteItemIndex}.addressOne`));
    setDeleteItemIndex(-1);
  };

  const addNewProperty = () => {
    const newProperty = getDefaultPropertyObject(true);
    newProperty.state = primaryProperty.state;

    newProperty.integrationKey = uuidv4();
    newProperty.isDefault = false;
    newProperty.isDirty = true;
    append(newProperty);

    setValue(`properties.0.isDefault`, false);
    setValue(`properties.0.isDirty`, true);
  };

  const handleOnClear = () => {
    stateCodeRef.current = null;
    stateAbbrRef.current = null;
    taxDocumentRef.current = null;
  };

  //#region - useEffects

  useEffect(() => {
    document.addEventListener("documentReset", resetPropertyData);
    return () => {
      document.removeEventListener("documentReset", resetPropertyData);
    };
  });

  useEffect(() => {
    setIsStatesDisabled(Boolean(hasIssuedProducts));
    const enabled = Boolean(
      fileNameNumber && agency?.id && agencyLocation?.name
    );
    setIsPropertyDisabled(!enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency?.id, fileNameNumber, hasIssuedProducts, agencyLocation?.name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceStateChange = useCallback(
    debounce(
      async (
        abbr: string,
        agencyId: any,
        agencyLocationId: any,
        activeContractID: any
      ) => {
        // if (!state || state.code === "" || !abbr) return;
        if (
          !stateCodeRef.current &&
          !stateAbbrRef.current &&
          !agencyIdRef.current &&
          !agencyLocationIdRef.current
        )
          return;

        getMunicipal(abbr);

        getCompanyProductOptions(agencyId, abbr);

        openAutomaticProgressDialog();
        await getCounties(agencyId, abbr, activeContractID);
        closeAutomaticProgressDialog();

        if (abbr && agencyId && agencyLocationId) {
          const pricingConfigs: Array<PricingConfig> | undefined =
            await getPricingConfigs(abbr, agencyId, agencyLocationId);

          // rlo 1/17/2022 - rereviewed on 2/08/2022 and it's good - Notes: when getPricingConfigs is done - hasIssuedProduct might be already updated
          const currentHasIssuedProducts = getValues("hasIssuedProducts");
          if (pricingConfigs && !currentHasIssuedProducts) {            
            // rlo 7/23/2023 - pricingConfigs is sorted by StateAbbr DESC, AgencyID DESC
            // Implemented this rules:
            // 1. If State & AencyID are matched -- use it
            // 2. If State is matched and AgencyID is not configured (null) -- use it
            // 3. If no matched set to false (Manual Pricing)
            const isIntegratedPricingConfigured =
              pricingConfigs?.find(
                (c) => c.configKey === PricingConfigKey.IsIntegratedPricing &&
                  (
                    (c.stateAbbr === abbr && c.agencyID === agencyId) ||
                    (c.stateAbbr === abbr && !c.agencyID)
                  )
              )?.configValue === "1";
            setValue("pricing.isIntegratedPricing", isIntegratedPricingConfigured);
          }

          // Show taxCode
          const showTaxCodeConfig = pricingConfigs?.find(
            (c: PricingConfig) => c.configKey === PricingConfigKey.TaxCode
          );
          if (showTaxCodeConfig && showTaxCodeConfig.configValue === "Allow") {
            setShowTaxCode(true);
          } else {
            setShowTaxCode(false);
          }

          // Get tax document
          const taxDocument = pricingConfigs?.find(
            (c: PricingConfig) => c.configKey === PricingConfigKey.TaxDocument
          )?.configValue;
          if (taxDocument) {
            setTaxDocument(taxDocument);
            taxDocumentRef.current = taxDocument;
          }

          // Show CoInsurance
          const showCoInsurance = pricingConfigs?.find(
            (c: PricingConfig) => c.configKey === PricingConfigKey.CoInsurance
          );
          if (showCoInsurance && showCoInsurance.configValue === "Allow") {
            setValue("pricing.showCoInsurance", true);
          } else {
            setValue("pricing.showCoInsurance", false);
          }
        }
      },
      50
    ),
    [
      agency,
      state,
      getMunicipal,
      getCounties,
      getCompanyProductOptions,
    ]
  );

  useEffect(() => {
    if (agency?.id && state?.code) {
      getRemittance(agency.id, state.code);
    }
  }, [agency?.id, state?.code, getRemittance])

  useEffect(() => {
    let currentState = getValues(`properties.0.state`);

    if (!currentState || currentState.code === "") {
      stateCodeRef.current = null;
      taxDocumentRef.current = null;
      return;
    }
    const { abbr, code } = currentState;

    if (
      code &&
      agency.id &&
      (stateCodeRef.current !== code || agencyIdRef.current !== agency.id)
    ) {
      stateCodeRef.current = code;
      stateAbbrRef.current = abbr;
      agencyIdRef.current = agency.id;
      agencyLocationIdRef.current = agencyLocation.id;
      let activeContractID = agency.activeContractID;
      if (agency.id && !activeContractID) {
        const matchingAgency = agencies?.find((a: any) => a?.value === agency.id && a?.text === agency.name)
        activeContractID = matchingAgency?.activeContractID;
      }
      debounceStateChange(
        abbr,
        agency.id,
        agencyLocation.id,
        activeContractID
      );
      return;
    }
  }, [
    agency.id,
    agency.activeContractID,
    agencyLocation.id,
    primaryProperty,
    debounceStateChange,
    isPopulatingExistingFile,
  ]);

  useEffect(() => {
    if (properties && properties.length > 1) {
      properties.forEach((p, i) => {
        if (i > 0) setValue(`properties.${i}.state`, properties[0].state);
      });
    }
  }, [properties]);

  useEffect(() => {
    if (properties && properties.length === 0) {
      const newProperty = getDefaultPropertyObject();
      newProperty.integrationKey = uuidv4();
      append(newProperty);
      setShowTaxCode(false);
    }
  }, [append, properties]);

  useEffect(() => {
    return () => {
      resetPricingConfigs();
    };
  }, []);

  //#endregion

  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => {
          return (
            <PropertyData
              key={field.fieldId}
              propertyIndex={index}
              onDelete={() => openDeleteDialog(index)}
              fileNameNumber={fileNameNumber}
              agency={getValues(`agency`)}
              agencyLocation={getValues(`agencyLocation`)}
              additionalParties={additionalParties}
              hasIssuedProducts={hasIssuedProducts}
              isPopulatingExistingFile={isPopulatingExistingFile}
              isPropertyDisabled={isPropertyDisabled}
              isStatesDisabled={isStatesDisabled}
              handleOnClear={handleOnClear}
              deleteFileData={deletePendingFileData}
              taxDocument={taxDocument}
              showTaxCode={showTaxCode}
              setShowTaxCode={setShowTaxCode}
            />
          );
        })}
        <Box>

          <AddLink
            ref={addPropertyRef}
            disabled={isPropertyDisabled || isFileDisabled}
            onClick={addNewProperty}
          >
            Add Property
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        confirmationMessage="Are you sure you want to delete this property?"
        onYes={() => deleteItem()}
        onNo={() => closeDeleteDialog()}
      />
      <ConfirmationDialog
        isOpen={isModalOpen}
        confirmationMessage={modalMessage}
        onYes={onModalAccept}
        onNo={onModalCancel}
        singleActionButtonText={"Submit"}
        buttonNoText={"Cancel"}
      />
    </>
  );
};

export default PropertyCollection;
