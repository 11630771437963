import { Table } from "@material-ui/core";
import { FileActivity } from "entities/UIModel";
import React, {
  useState,
} from "react";
import { Order } from "utils/sorting";
import FileHistoryBody from "./FileHistoryBody";
import FileHistoryHead from "./FileHistoryHead";

interface Props {
  records?: FileActivity[];
}

const FileHistory = ({ records = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof FileActivity>("date");

  const handleRequestSort = (property: keyof FileActivity) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table className="history-table">
      <FileHistoryHead
        {...{ order, orderBy, onRequestSort: handleRequestSort }}
      />
      <FileHistoryBody {...{ order, orderBy, records }} />
    </Table>
  );
};

export default React.memo(FileHistory);
