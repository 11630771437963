import Box from "@material-ui/core/Box";
import LightTooltip from "controls/global/light-tooltip";
import React, {
  ReactNode,
  useRef,
  useState
} from "react";
import { isEllipsisShown } from "utils/shared";

interface Props {
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  children?: ReactNode;
  className?: string | undefined;
  sticky?: boolean;
  alwaysShowOnHover?: boolean;
}
interface TextProps {
  children: ReactNode;
}

const Text = React.forwardRef(
  ({ children }: TextProps, ref: React.LegacyRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </div>
    );
  }
);
Text.displayName = "Text";

const TooltipCell = ({ title, children, className, alwaysShowOnHover = false }: Props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const handleOpenTooltip = (open: boolean) => {
    const show = open &&
      (alwaysShowOnHover || isEllipsisShown(inputElementRef.current));

    setOpenTooltip(show);
  };

  if (!title) return null;
  return (
    <Box
      onMouseEnter={() => handleOpenTooltip(true)}
      onMouseLeave={() => handleOpenTooltip(false)}
      className={className}
    >
      <LightTooltip
        arrow
        title={title}
        open={openTooltip}>
        <Text ref={inputElementRef}>{children ?? title}</Text>
      </LightTooltip>
    </Box>
  );
};

export default TooltipCell;
