// import { isEqual } from "lodash";
import { GeneralFileParty } from "entities/UIModel";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { cloneDeep } from 'lodash';

type State = {
  additionalParties: GeneralFileParty[];
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setAdditionalParties:
    (parAdditionalParties: GeneralFileParty[]) =>
    ({ setState }: StoreApi) => {
      setState({
        additionalParties: cloneDeep(parAdditionalParties),
      });
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    additionalParties: [],
  },
  actions,
  name: "PartyAdditionalParty",
});

const getPartyAdditionalParties = (state: State) => state.additionalParties;


const usePartyAdditionalPartyActions = createHook(Store, {
  selector: null,
});

const usePartyAdditionalParties = createHook(Store, {
  selector: getPartyAdditionalParties,
});

export {
  usePartyAdditionalPartyActions,
  usePartyAdditionalParties,
};
