import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinearProgressWithLabel from "controls/global/linear-progress-with-label";
import { colors } from "theme/defaultStyle";
import {
  useProcessStatusTracking,
  useProcessStatusTrackingState,
} from "utils/context/ProcessStatusContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles"

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#F2F2F2",
  },
  barColorPrimary: {
    backgroundColor: `${colors.blue01}`,
  },
})(LinearProgressWithLabel);

const CONFIG = {
  TIMEOUT_SEC: 30, //Counter to reset Interval for polling
  MAX_COMPLETED: 100,
};

const ProgressbarDialog = () => {
  const classes = useDialogStyles({ type: "INFO", width: 580 });
  const [{ open, requestId, title }, { closeProgresbar }] = useProgressbar();
  const [{ pctComplete, maxWait }] = useProcessStatusTrackingState();
  const [, { getProcessStatus, reset }] = useProcessStatusTracking();

  useEffect(() => {
    if (!open || !requestId) return;

    const interval = setInterval(() => {
      getProcessStatus(requestId);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, requestId]);

  useEffect(() => {
    if (
      open &&
      (pctComplete === CONFIG.MAX_COMPLETED || maxWait >= CONFIG.TIMEOUT_SEC)
    ) {
      setTimeout(() => {
        closeProgresbar();
        reset();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWait, pctComplete]);

  if (!open) return null;

  return (
    <Dialog open={open} maxWidth="md" classes={{ root: classes.dialogRoot }}>
      <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitleRoot }}>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot, }}>
        <StyledLinearProgress value={pctComplete} />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default ProgressbarDialog;
