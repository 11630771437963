import { createHook, createStore, StoreActionApi, defaultRegistry } from "react-sweet-state";
import { ActionResult, PricingDataResult, SCFile } from 'entities/UIModel';
import * as ApiModel from 'entities/ApiModel';
import { axiosSecuredInstance } from 'configurations/axiosConfig';
import { setCalculatedSCFile } from 'pages/file/utils/fileHelper';
import { Store as FileStore } from './FilesContext';
import * as withSCFile from "pages/file/utils/fromApi/scFileCreator";
import { OrderStatusType } from "utils/data/enum";

type State = {};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const FileStoreInstance = defaultRegistry.getStore(FileStore);

const actions = {
  calculateItems: (
    currentScFile: SCFile,
    scFile: ApiModel.SCFile,
    provideRemittance: boolean = true,
    provideTax: boolean = true
  ) => async ({ setState }: StoreApi): Promise<ActionResult> => {
    try {
      // rlo 3/30/2022
      // Calculate taxes for all updated products
      // Api will return the same payload (scfile) with the updated taxes
      const { data } = await axiosSecuredInstance.post(
        `/order/CalculateItems?provideRemittance=${provideRemittance}&provideTax=${provideTax}&isHazardous=false`,
        scFile
      );
      const currentFile = setCalculatedSCFile(currentScFile, data);

      FileStoreInstance.actions.setFileLoading(false);
      FileStoreInstance.actions.setCalculatedPricingProducts(currentFile.pricingProducts);

      return {
        scfile: currentFile,
        error: null,
      };
    } catch (error) {
      FileStoreInstance.actions.setErrorFiles(error);

      return {
        error,
      };
    }
  },
  calculatePricing:
    (currentScfile: SCFile,
     scfile: ApiModel.SCFile,
     requestId: string,
     initialValues: SCFile,
    ) =>
      async (): Promise<ActionResult> => {
        try {
          FileStoreInstance.actions.setFileLoading(true);
          FileStoreInstance.actions.setErrorFiles(null);

          // Robun 10/30/2021: backend return the same scfile payload from UI with updated pricing
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/Calculate?requestId=${requestId}`,
            scfile
          );
          const currentFile = setCalculatedSCFile(currentScfile, data);
          FileStoreInstance.actions.setFileLoading(false);
          FileStoreInstance.actions.setCalculatedPricingProducts(currentFile.pricingProducts);
          //FileStoreInstance.actions.setIsActionInProgress(false) -- don't need it anymore

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          FileStoreInstance.actions.setErrorFiles(error);
          FileStoreInstance.actions.setIsActionInProgress(false)

          return {
            scfile: initialValues,
            error,
          };
        }
      },
  calculatePremiumsWithPolicyType:
  // orderIds: jacket's orderId list separated by comma
    (currentScfile: SCFile, scfile: ApiModel.SCFile, receiptId: string) =>
      async ({ dispatch }: StoreApi): Promise<ActionResult> => {
        let pricingDataResult: PricingDataResult = {
          pricingConfig: undefined,
          productPolicyTypes: [] as any,
          error: undefined,
        };
        try {
          //  11/02/2021: backend return the same scfile payload from UI with updated pricing configuration and policy types on each product (only jacket for now)
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/Calculate?requestId=${receiptId}&updatePolicyTypes=true`,
            scfile
          );

          if (data) {
            const result = data as ApiModel.SCFile;
            if (result?.FilePricing) {
              pricingDataResult.pricingConfig = withSCFile.createFilePricing(
                result.FilePricing
              );
            }

            pricingDataResult.productPolicyTypes = [] as any;
            result?.JacketProducts?.filter(
              (j) => j.OrderStatusTypeCode === OrderStatusType.Issued
            )?.forEach((j) => {
              const productPolicyType: any = {
                transactionTypeCode: scfile.TransactionTypeCode,
                propertyTypeCode:
                  scfile.Properties && scfile.Properties.length > 0
                    ? scfile.Properties[0].PropertyTypeCode
                    : "",
                filePricingDetailId: j.FilePricingDetailID,
                productId: j.ProductID,
                orderId: j.OrderID,
                rateTypes: j.RateTypes?.map((type) => {
                  return {
                    text: type,
                    value: type,
                  };
                }),
                pricingRateType: j.PricingDetail?.PricingRateType,
                pricingRateTypeData: j.PricingDetail?.PricingRateTypeData,
                pricingTransactionTypeCode:
                j.PricingDetail?.PricingTransactionTypeCode,
                pricingFeeTypeCode: j.PricingDetail?.PricingFeeTypeCode,
                coverageType: j.PricingDetail?.CoverageType,
                coverageTypeData: j.PricingDetail?.CoverageTypeData,
                lastErrorMessage: j.PricingDetail?.LastErrorMessage,
              };

              pricingDataResult.productPolicyTypes!.push(productPolicyType);
            });

            const currentFile = setCalculatedSCFile(currentScfile, data);
            FileStoreInstance.actions.setFileLoading(false);
            FileStoreInstance.actions.setCalculatedPricingProducts(currentFile.pricingProducts);

            return {
              headers: headers,
              scfile: currentFile,
              pricingDataResult: pricingDataResult,
            };
          }

          return pricingDataResult;
        } catch (error) {
          FileStoreInstance.actions.setErrorFiles(error);
          pricingDataResult.error = error;
          return {
            scfile: currentScfile,
            error,
            pricingDataResult: pricingDataResult,
          };
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {},
  actions,
  name: "PricingCalculator",
});

const hook = createHook(Store);
export const usePricingCalculatorContext = () => {
  return hook();
};
