import { Box } from "@material-ui/core";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import React from "react";
import { useReportPay } from "utils/context/ReportPayContext";
import ReportPayFooterAction from "../reportpay-footer-action";
import ReportPayFooterTotal from "../reportpay-footer-total";

type FooterProps = {
  rows: ReportPayFile[];
  onlyShowSelectedFiles: boolean;
  page: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOnlySelectedFilesChange?: (value: boolean) => void;
};

const ReportPayFooter = ({
  rows,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: FooterProps) => {
  const [{ isAdvSearchOpen }] = useReportPay();
  const handleSelectedFilesChange = (value: boolean) =>
    onOnlySelectedFilesChange?.(value);

  const noSelectedRows = !rows.length || isAdvSearchOpen;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ReportPayFooterAction
          disabled={noSelectedRows}
          onlyShowSelectedFiles={onlyShowSelectedFiles}
          onOnlySelectedFilesChange={handleSelectedFilesChange}
        />
        <ReportPayFooterTotal rows={rows} />
      </Box>
    </>
  );
};

export default ReportPayFooter;
