import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { colors } from "theme/defaultStyle";

const useStyles = makeStyles({
  error: {
    color: colors.red02,
    fontSize: 18,
  },
  errorBox: {
    marginTop: 10,
    marginBottom: 10,
  },
});

type Props = {
  message?: string;
};

export default function ErrorBox({ message }: Props) {
  const classes = useStyles();

  if (!message) return null;
  return (
    <Box className={classes.errorBox}>
      <Typography className={classes.error}>{message}</Typography>
    </Box>
  );
}
