import Box from "@material-ui/core/Box";
import ActionButton from "controls/global/action-button";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import EyeIcon from "theme/icons/EyeIcon";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import useCreateFile from "utils/custom-hooks/useCreateFile";

type Props = {
  onNavigate: (url: string) => void;
};

function ViewPendingPayment({ onNavigate }: Props) {
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const { isFileLocked } = useCreateFile();
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleNavigate = () => {
    onNavigate("/pendingpay");
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  if (!isFileLocked || !enableReportPay) return null;
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      m={1.5}
      ml={0}
      mr={0}
    >
      <ActionButton
        variant="outlined"
        color="secondary"
        onClick={handleNavigate}
        startIcon={
          <EyeIcon
            htmlColor={colors.blue01}
            style={{ width: 20, height: 20, marginLeft: 10 }}
          />
        }
      >
        View Pending Payments
      </ActionButton>
    </Box>
  );
}

export default ViewPendingPayment;