import { createHook, createStore, StoreActionApi } from 'react-sweet-state';
import { AxiosRequestConfig } from 'axios';
import { axiosSecuredInstance } from 'configurations/axiosConfig';

type StoreApi = StoreActionApi<State>;

interface State {
  error: string | null;
  userStewartAccounts: Array<any>;
}

const initialState: State = {
  error: null,
  userStewartAccounts: [],
}

const setError = (error: string | null) => ({ setState }: StoreApi) => {
  setState({ error });
};

const setUserStewartAccounts = (data: Array<any>) => ({ setState }: StoreApi) => {
  setState({
    userStewartAccounts: data.map((state) => {
      const userName: string = state.ExternalUserName.split('@', 1)[0];

      return ({
        text: userName,
        value: state.ExternalUserName,
        payload: state
      });
    }),
  });
};

const getData = (
  url: string,
  action: (param: Array<any>) => ({ setState }: StoreApi) => any,
  config?: AxiosRequestConfig) =>
  async ({ dispatch }: StoreApi) => {
    try {
      const { data } = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const Store = createStore<State, any>({
  initialState: { ...initialState },
  actions: {
    getUserStewartAccounts: () => async ({ dispatch }: StoreApi) => {
      await dispatch(getData(`/user/GetExternalSystemAccountByUserIdSystem?externalSystemTypeCode=STEWARTACCESS`, setUserStewartAccounts));
    },
  },
  name: "userExternalAccounts",
});


const hook = createHook(Store);
export const userExternalAccounts = () => {
  return hook()
};
