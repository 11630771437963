import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ActionButton from "controls/global/action-button";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles"
import CheckIcon from '@mui/icons-material/Check';
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type Props = {
  isOpen: boolean;
  endorsementName: string;
  onSubmit: () => void;
  onCancel: () => void;
};

const VoidEndorsementForm = ({
  isOpen,
  endorsementName,
  onSubmit,
  onCancel,
}: Props) => {
  const classes = useDialogStyles({ type: "INFO" });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth="md"
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        Void Endorsement
        <DialogTitleCloseButton onClick={onCancel}/>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Typography gutterBottom>
          The following endorsement will be voided:
        </Typography>
        <Typography gutterBottom>
          {endorsementName}
        </Typography>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActionRoot,
        }}
      >
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default VoidEndorsementForm;
