import { Description } from "@mui/icons-material";
import FormSection from "controls/global/form-section";
import {
  ActionType,
  useSideNavProvider,
} from "pages/file/SideNavProvider";
import React, {
  useEffect,
  useRef,
} from "react";
import { useFormState } from "react-hook-form";
import GradientIcon from "theme/icons/GradientIcon";
import FileData from "./FileData";
import { useFiles } from "utils/context/FilesContext";

type Props = {
  onNavigate: (url: string) => void;
}

function FileSection({ onNavigate }: Props) {
  const { errors, touchedFields } = useFormState();
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const fileRef = useRef<HTMLElement>();
  const [{isReadOnly}] = useFiles();

  const hasError = () =>
    (errors.fileNameNumber && touchedFields.fileNameNumber) ||
    (errors.agency !== undefined && touchedFields.agency !== undefined) ||
    (errors.agencyLocation !== undefined &&
      touchedFields.agencyLocation !== undefined);

  useEffect(() => {
    if (sideNavState.items.get("File")?.childRef?.current) return;
    sideNavAction({
      type: ActionType.UPDATE,
      item: { name: "File", childRef: fileRef },
    });
  }, [sideNavState, fileRef]);

  return (
    <FormSection
      navItemRef={fileRef}
      data-test="fileSection"
      icon={<GradientIcon Icon={Description} />}
      isError={hasError()}
      title={"File" + (isReadOnly ? " - Read Only" : "")}
    >
      <FileData onNavigate={onNavigate} />
    </FormSection>
  );
}

export default FileSection;
