import React, { useEffect, useState } from "react";

import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import StewartDatePicker from "controls/global/stewart-datepicker";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { DateFnsTzUtils } from "./DateFnsTzUtils";
import { MuiPickersTzUtilsProvider } from "./MuiPickersTzUtilsProvider";
import { utcToZonedTime } from "date-fns-tz";
import { getTimeZoneOffset } from "pages/file/utils/profileSettings";

interface props {
  name: any;
  label?: string;
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  defaultToToday?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  isShrinkable?: boolean;
  fieldRef?: any;
  setError?: (val: boolean) => void;
  onChangeCallback?: (date: Date | null, shouldDirty?: boolean) => void;
  onFocusCallback?: () => void;
  onBlurCallback?: (date: Date | null) => void;
  size?: "small" | "medium";
  noBorder?: boolean;
}

const DatePicker = ({
  name,
  label,
  value,
  minDate,
  maxDate,
  hasError = false,
  disabled = false,
  defaultToToday = false,
  isShrinkable = true,
  fieldRef,
  onChangeCallback,
  onFocusCallback,
  onBlurCallback,
  size = "medium",
  noBorder,
}: props) => {
  const [shrinkable, setShrinkable] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const [timeZoneOffset, setTimeZoneOffset] = useState("+00:00");

  const [{ profileSettings }] = useProfileSettingsCache();

  const handleOnChange = (date: Date | null) => {
    setDate(date);
    onChangeCallback && onChangeCallback(date);
  };

  const handleOnAccept = (date: Date | null) => {
    onBlurCallback && onBlurCallback(date);
  };

  const handleOnBlur = () => {
    onBlurCallback && onBlurCallback(date);
    setFocused(false);
  };

  const handleOnFocus = () => {
    setFocused(true);
    setShrinkable(true);
    onFocusCallback && onFocusCallback();
  };

  useEffect(() => {
    setDate(value ?? null);
  }, [hasError, value]);

  useEffect(() => {
    setShrinkable(!!value || focused || Boolean(hasError));
  }, [value, hasError, focused]);

  useEffect(() => {
    if (profileSettings.timeZones.length > 0 && defaultToToday && !value) {
      const offset = getTimeZoneOffset(profileSettings);
      setTimeZoneOffset(offset);

      const zonedTime = utcToZonedTime(new Date(), offset);
      setDate(zonedTime);

      onChangeCallback && onChangeCallback(zonedTime, false);
    }
  }, [value, defaultToToday, profileSettings, onChangeCallback]);

  return (
    <MuiPickersTzUtilsProvider utils={DateFnsTzUtils} timeZone={timeZoneOffset}>
      <StewartDatePicker
        id={name}
        error={hasError}
        fullWidth
        disableToolbar
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<CalendarTodayOutlinedIcon />}
        placeholder={!value ? "mm/dd/yyyy" : ""}
        InputProps={{
          disableUnderline: true,
        }}
        inputRef={fieldRef}
        inputProps={{
          className: "date-picker-inline-custom",
          "aria-errormessage": hasError ? "rhfvalidationerror" : "",
        }}
        InputLabelProps={{
          shrink: shrinkable || hasError,
        }}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onAccept={handleOnAccept}
        helperText={null}
        value={date}
        disabled={disabled}
        size={size}
        noBorder={noBorder}
        {...{ name, label, minDate, maxDate }}
      />
    </MuiPickersTzUtilsProvider>
  );
};

export default DatePicker;
