import {
  Box,
} from "@material-ui/core";
import {
  styled,
} from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import {
  borderRadius,
  boxShadows,
  colors,
  filters,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import useTitle from "utils/custom-hooks/useTitle";
import { menuDefinitions } from "./menu";

const HomeMenu = styled("div")({
  backgroundColor: colors.white,
  borderRadius: borderRadius.xlarge,
  boxShadow: boxShadows.boxShadow06,
  display: "flex",
  flexDirection: "column",
  margin: `${padding.large2} auto 0 auto`,
  maxWidth: "950px",
  padding: padding.xlarge3,
});


const MenuItemContainer = styled("div")((props) => ({
  display: "grid",
  gridGap: gaps.large4,
  gridTemplateColumns: "repeat(3, 1fr)",
  height: "100%",
  padding: padding.zero,
  width: "100%",
  [props.theme.breakpoints.down(900)]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [props.theme.breakpoints.down(580)]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

const MenuItem = styled("div")({
  height: "250px",
  margin: margin.zero,
  padding: padding.zero,
  textAlign: "center",
  "&.disabled-menu-item *": {
    color: colors.blue11,
  },
  "& a": {
    alignItems: "center",
    color: colors.blue01,
    display: "flex",
    flexDirection: "column",
    fontSize: fontSize.xlarge5,
    fontWeight: fontWeight.bold2,
    height: "100%",
    justifyContent: "center",
    padding: padding.large1,
    textDecoration: "none",
    "&.disabledLink": {
      cursor: "default",
      "& .hover-content": {
        display: "none",
      }
    },
    "&.disabledLink:hover .normal-content": {
      display: "none",
    },
    "&.disabledLink:hover .hover-content": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& a .icon": {
    color: colors.blue01,
    fontSize: iconSize.xlarge,
  },
  "&:hover:not(.disabled-menu-item)": {
    backgroundColor: colors.blue08,
    borderRadius: borderRadius.small,
    filter: filters.filter01
  },
});

const TitleMenu = styled("p")({
  lineHeight: lineHeight.xlarge
});

const MenuItems = () => {
  const { featureConfig: { enableReportPay } } = useConfigContext();
  return (
    <>
      {menuDefinitions.map(
        ({ centerMenuId, disabled, menuName, url, type, icon: Icon }) => {
          const menuDisabled =
            type === "REPORTPAY" ? !enableReportPay : disabled;
          return (
            <MenuItem
              key={centerMenuId}
              className={menuDisabled ? "disabled-menu-item" : ""}>
              {menuDisabled ? (
                <Link
                  onClick={(event) => event.preventDefault()}
                  to="/"
                  className="disabledLink"
                >
                  <Box>
                    <Box className="normal-content">
                      {Icon && <Icon className="icon" />}
                      <p className="title">{menuName}</p>
                    </Box>
                    <Box className="hover-content">
                      {Icon && <Icon className="icon" />}
                      <p className="title">Coming Soon</p>
                    </Box>
                  </Box>
                </Link>
              ) : (
                <Link to={url}>
                  <Box>
                    {Icon && <Icon className="icon" />}
                    <TitleMenu className="title">{menuName}</TitleMenu>
                  </Box>
                </Link>
              )}
            </MenuItem>
          );
        }
      )}
    </>
  );
};

const HomePage = () => {
  useTitle("Stewart Connect - Home");

  return (
    <HomeMenu id="homeMenu">
      <MenuItemContainer>
        <MenuItems />
      </MenuItemContainer>
    </HomeMenu>
  );
};

export default HomePage;
