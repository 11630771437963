import React from "react";

import MultiSelectComponent from "controls/global/multi-select-field/MultiSelectComponent";
import { AgencyLocation } from "utils/context/AgencyLocationsContext";

interface Props {
  name: string;
  label: string;
  reportAgencyLocations: AgencyLocation[];
  disabled?: boolean;
}

const ReportAgencyLocationField = ({
  name,
  label,
  reportAgencyLocations,
  disabled,
}: Props) => {
  const agencyLocationsWithOutEmptyText = reportAgencyLocations.filter(
    (values) => values.text !== ""
  );

  return (
    <MultiSelectComponent
      name={name}
      label={label}
      options={agencyLocationsWithOutEmptyText}
      disabled={disabled}
    />
  );
};

export default ReportAgencyLocationField;
