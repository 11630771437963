import {
  Box,
  Grid,
  Link,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";

const useStyles = makeStyles({
  container: {
    paddingBottom: padding.xlarge1,
    paddingLeft: padding.zero,
    paddingRight: padding.zero,
    paddingTop: padding.xlarge1,
    "& > div": {
      width: "100%",
    },
  },
  footerLink: {
    color: colors.blue01,
    textDecoration: "underline",
  },
  footerText: {
    fontSize: fontSize.small,
  },
});

const getCurrentYear = () => new Date().getFullYear();

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item>
          <Link
            href="http://www.stewart.com/en/privacy.html"
            rel="noreferrer"
            target="_blank"
            className={classes.footerLink}
          >
            Privacy Policy
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="http://www.stewart.com/en/terms-of-use.html"
            rel="noreferrer"
            target="_blank"
            className={classes.footerLink}
          >
            Terms of Use
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="http://www.stewart.com/en/protecting-customer-information.html#st-mainParsys_generictextcomponent"
            rel="noreferrer"
            target="_blank"
            className={classes.footerLink}
          >
            Protecting Customer Information
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Box className={classes.footerText}>
          © {getCurrentYear()} Stewart Title Guaranty Company. All Rights
          Reserved. Trademarks are the property of their respective owners.
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
