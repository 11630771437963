import React from "react";
import {
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import styled from "styled-components";
import {
  colors,
  defaultStyle,
  fontSize,
  margin,
  lineHeight,
  padding,
} from "theme/defaultStyle";

interface Props {
  messages: string[];
  colSpan?: number;
}

const StyledTableBody = styled(TableBody)({
  padding: padding.xlarge1
});

const StyledTableCell = styled(TableCell)({
  borderLeft: defaultStyle.table.border,
  borderRight: defaultStyle.table.border,
  color: colors.grey11,
  fontSize: fontSize.xlarge2,
  lineHeight: lineHeight.large,
});

const StyledMessageContainer = styled.div({
  marginTop: margin.small1,
});

const StyledSecondMessage = styled.span({
  color: colors.grey09,
  fontSize: fontSize.large,
});

const StewartTableBodyEmpty = ({ messages, colSpan }: Props) => {
  const [firstMessage, secondMessage, ...remainingMessages] = messages;

  return (
    <StyledTableBody>
      <TableRow>
        <StyledTableCell colSpan={colSpan} className="emptyCell">
          {firstMessage}
          {secondMessage && (
            <StyledMessageContainer>
              <StyledSecondMessage>{secondMessage}</StyledSecondMessage>
            </StyledMessageContainer>
          )}
          {remainingMessages.map((message, index) => (
            <StyledMessageContainer key={index}>{message}</StyledMessageContainer>
          ))}
        </StyledTableCell>
      </TableRow>
    </StyledTableBody>
  );
};

export default StewartTableBodyEmpty;
