import {
  styled,
  Tooltip,
  TooltipProps,
} from "@material-ui/core";
import SelectField from "controls/global/select-field";
import { JacketSignature } from "entities/UIModel";
import { cloneDeep } from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import {
  JacketSignatureFieldOption,
  useJacketSignatures,
} from "utils/context/JacketSignaturesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  INT_MIN,
} from "utils/shared";

type SignatureSelectedField = {
  signatureImageName: string;
  signatureImageID?: number;
  default: number;
};

interface Props {
  schema: string;
  name: string;
  disabled?: boolean;
  isProcessing?: boolean;
  onChange?: () => void;
}

const TOOLTIP_MESSAGE = "Select a signature from the dropdown to print the signature image on the jacket.";

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: gaps.small1,
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    maxWidth: "80vw",
  },
});

const SignatureField = ({
  schema,
  name,
  disabled,
  isProcessing = false,
  onChange,
  ...rest
}: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const [{ signatures }, { getSignatures }] = useJacketSignatures();
  const [signatureOptions, setSignatureOptions] = useState<SignatureSelectedField[]>([]);
  const [defaultValue, setDefaultValue] = useState<number | undefined>(undefined);
  const [initialSignatureImageName] = useState<string>(getValues(`${schema}.jacketSignatures.0.signatureImageName`));
  const [jacketSignature, setJacketSignature] = useState<JacketSignature | undefined>(undefined);

  const [
    productID,
    agencyID,
    stateCode,
    locationUniqueID,
    jacket,
  ] = useWatch({
    name: [
      `${schema}.id`,
      "agency.id",
      "properties.0.state.code",
      `${schema}.pricingDetail.locationUniqueID`,
      `${schema}`,
    ]
  });

  const isLookupSignatureImageName = (signatureImageName?: string): boolean => {
    if (signatureImageName === "Select") {
      return true;
    }
    else {
      const signatureIndex = signatureImageName?.replace("Signature - ", "") ?? "";
      return signatureIndex.length > 0 && Number.isInteger(Number(signatureIndex));
    }
  };

  const isNoSignatureImageName = (signatureImageName?: string): boolean => {
    const isNO = (signatureImageName === undefined ||
      signatureImageName === "No Signature" ||
      !jacketSignature || !jacketSignature?.signatureImageData);
    return isNO;
  };

  const isDirty = (newName: string): boolean => {
    const isSame = newName === initialSignatureImageName ||
      (newName === "Select" && isLookupSignatureImageName(initialSignatureImageName));
    return !isSame;
  };

  const handleOnChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (isProcessing) return;

    const imageName = value?.signatureImageName ?? "";
    let imageID = 0;
    if (initialSignatureImageName == imageName) {
      imageID = -1;
    }
    else {
      imageID = Number(value?.signatureImageID) ?? 0;
    }

    updateJacketSignature(imageName, imageID);
    setDefaultValue(0);
    onChange && onChange();
  };

  const updateJacketSignature = (imageName: string, imageID?: number) => {
    if (isProcessing || !jacketSignature) return;

    const signature: JacketSignature = {
      integrationKey: jacketSignature?.integrationKey || "00000000-0000-0000-0000-000000000000",
      signatureImageID: imageID,
      signatureImageData: new Date().toString(),
      signatureImageName: imageName,
      isDirty: isDirty(imageName),
      jacketSignatureID: jacketSignature?.jacketSignatureID,
    };

    setValue(`${schema}.jacketSignatures.0`, signature);
  };

  const getSignatureOptions = () => {
    if (isProcessing || !jacketSignature) return;

    let options: JacketSignatureFieldOption[] = cloneDeep(signatures) ?? [];
    let defaultSignature: JacketSignatureFieldOption | undefined = undefined;
    const isLookupSignature = isLookupSignatureImageName(initialSignatureImageName as string);
    const isBlankSignature = initialSignatureImageName === "Blank";
    const isNoSignature = isNoSignatureImageName(jacketSignature?.signatureImageName);

    // If issued, ignore defaults and use saved.
    if (productID !== 0) {
      options.forEach(o => o.default = 0);

      if (isNoSignature) {
        options.unshift({
          signatureImageName: "No Signature",
          signatureImageID: -1,
          default: 1,
        });
      }
      else {
        if (isLookupSignature) {
          options.unshift({
            signatureImageName: "Signature",
            signatureImageID: -1,
            default: 1,
          });
        }
        else {
          defaultSignature = options.find(s => s.signatureImageName === jacketSignature?.signatureImageName);
          if (defaultSignature) {
            defaultSignature.signatureImageID = -1;
            defaultSignature.default = 1;
          }
          else if (!isBlankSignature && !isLookupSignature) {
            // Add jacket signature that no longer exists in signatures, but was on jacket.
            options.unshift({
              signatureImageName: jacketSignature?.signatureImageName || "",
              signatureImageID: -1,
              default: 1,
            });
          }
        }
      }
    }

    // Add Select option which allows setting no signature, if it doesn't exist.
    options.unshift({
      signatureImageName: "Blank",
      signatureImageID: INT_MIN,
      default: isBlankSignature ? 1 : 0,
    });

    // Add Select option which allows setting no signature.
    // NOTE: 0 must be used in this instance in order to display the option correctly; during the mapping process 0 is converted to INT_MAX.
    options.unshift({
      signatureImageName: "Select",
      signatureImageID: 0,
      default: 0,
    });

    const selectedImage = options.find(s => s.default) || options[0];
    const selectedImageID = selectedImage?.signatureImageID;
    const selectedImageName = selectedImage?.signatureImageName ?? "";

    updateJacketSignature(selectedImageName, selectedImageID);
    setDefaultValue(selectedImageID);
    setSignatureOptions(options);
  };

  useEffect(() => {
    if (isProcessing) return;

    const js = jacket.jacketSignatures?.[0] || {};
    setJacketSignature(js);
  }, [jacket?.jacketSignatures]);

  useEffect(() => {
    getSignatureOptions();
  }, [signatures]);

  useEffect(() => {
    if (agencyID && stateCode && locationUniqueID) {
      getSignatures(agencyID, stateCode, locationUniqueID);
    }
  }, [agencyID, stateCode, locationUniqueID]);

  return (
    <Container>
      <SelectField
        name={name}
        label="Signature"
        options={signatureOptions}
        onChange={handleOnChange}
        disabled={disabled}
        defaultValue={defaultValue}
        autosetValue={false}
        optionsValueField="signatureImageID"
        optionsTextField="signatureImageName"
        dataTextField="signatureImageName"
        dataValueField="signatureImageID"
      />
      <StyledTooltip
        title={TOOLTIP_MESSAGE}
        placement="top-end"
        aria-label="jacket-signature-image-info"
        arrow
      >
        <StyledInfoIcon />
      </StyledTooltip>
    </Container>
  );
};

export default SignatureField;
