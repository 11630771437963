import SelectField from "controls/global/select-field";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFormContext,
  useWatch
} from "react-hook-form";
import styled from "styled-components";
import { margin } from "theme/defaultStyle";
import { useLookup } from "utils/context/LookupContext";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";

interface SelectOption {
  value: string;
  text: string;
}

const FormField = styled.div`
  margin-top: ${margin.xlarge};
`;

const FIELD_NAME = "level";

const LevelField = () => {
  const [levelOptions, setLevelOptions] = useState<SelectOption[]>([]);
  const { updateSaveEnabled } = useSignatureDefaultDialogProvider();
  const [{ agencyWideLevelCode }] = useSignatureDefaults();
  const [signatureImage, locationField, levelField] = useWatch({
    name: ["signatureImage", "location", "level"],
  });
  const [{ signatureLevelTypes }] = useLookup();
  const { setValue } = useFormContext();

  useEffect(() => {
    setLevelOptions(signatureLevelTypes.map(t => ({
      value: t.code,
      text: t.name
    })));
  }, [signatureLevelTypes]);

  useEffect(() => {
    if (!levelField.value) {
      const agencyWideOption = levelOptions.find(o => o.value === agencyWideLevelCode);
      if (agencyWideOption) {
        setValue(FIELD_NAME, {
          text: agencyWideOption.text,
          value: agencyWideOption.value,
        });
      }
    }
  }, [levelOptions]);

  useEffect(() => {
    if (levelField.value && signatureImage.value)
      updateSaveEnabled(true);
  }, [signatureLevelTypes, LevelField, signatureImage]);

  return (
    <FormField>
      <SelectField
        label="Level"
        name={FIELD_NAME}
        options={levelOptions}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={!locationField?.value}
      />
    </FormField>
  );
};

export default LevelField;
