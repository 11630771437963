import { createHook, createStore, StoreActionApi } from 'react-sweet-state';
import { AxiosRequestConfig } from 'axios';
import { SelectFieldOption } from 'controls/global/select-field/SelectInput';
import { axiosSecuredInstance } from 'configurations/axiosConfig';
import { isEqual } from 'lodash';

type StoreApi = StoreActionApi<State>;

interface State {
  error: string | null;
  userStates: Array<SelectFieldOption>;
}

const initialState: State = {
  error: null,
  userStates: [],
}

const setError = (error: string | null) => ({setState}: StoreApi) => {
  setState({error});
};

const setUserStates = (data: Array<any>) => ({ setState, getState }: StoreApi) => {
  const states = getState().userStates;

  if (isEqual(states, data)) return;
  setState({
    userStates: data.map((state) => ({
      text: state.StateAbbr,
      value: state.StateCode,
    })),
  });
};

const getData = (
  url: string,
  action: (param: Array<any>) => ({setState}: StoreApi) => any,
  config?: AxiosRequestConfig) =>
  async ({dispatch}: StoreApi) => {
    try {
      const {data} = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const Store = createStore<State, any>({
  initialState: {...initialState},
  actions: {
    getUserStates: (saUserName: string) => async ({dispatch}: StoreApi) => {
      await dispatch(getData(`/user/GetStateListByUser?stewartAccessUserName=${saUserName}`, setUserStates));
    },
  },
  name: "userStates",
});


const hook = createHook(Store);
export const useUserStates = () => {
  return hook()
};
