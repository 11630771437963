import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ActionButton from "controls/global/action-button";
import { TextInput } from "controls/global/text-input-field";
import VoidReasonsField from "../common/VoidReasonsField";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import CheckIcon from '@mui/icons-material/Check';
import { padding } from "theme/defaultStyle";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type Props = {
  productType: string;
  formType?: string;
  form?: string;
  serialNumber: string;
  effectiveDate?: string;
  productIndex: number;
  isOpen: boolean;
  onSubmit: any;
  onCancel: any;
  submitButtonDisabled: boolean;
};

const gridStyle = {
  paddingTop: padding.small2,
  paddingRight: padding.small2,
};

const voidGridStyle = {
  paddingTop: padding.xlarge,
  paddingRight: "20%",
  paddingLeft: "20%",
};

const VoidJacketForm = ({
  productType,
  formType,
  form,
  serialNumber,
  effectiveDate,
  productIndex,
  isOpen,
  onSubmit,
  onCancel,
  submitButtonDisabled,
}: Props) => {
  const classes = useDialogStyles({ type: "INFO" });
  const [{ voidReasons }] = useVoidReasons();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullScreen={fullScreen}
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        Void Jacket
        <DialogTitleCloseButton onClick={() => onCancel(false)}/>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} style={gridStyle}>
            <TextInput
              label="Type"
              name={formType}
              disabled={true}
              value={formType}
            />
          </Grid>

          <Grid item xs={12} md={6} style={gridStyle}>
            <TextInput
              label="Policy Number"
              name={serialNumber}
              disabled={true}
              value={serialNumber}
            />
          </Grid>

          <Grid item xs={12} md={3} style={gridStyle}>
            <TextInput
              label="Effective Date"
              name={effectiveDate}
              disabled={true}
              value={effectiveDate}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ ...gridStyle, paddingTop: "20px" }}
          >
            <TextInput label="Form" name={form} disabled={true} value={form} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={voidGridStyle}>
            <VoidReasonsField
              voidReasons={voidReasons[productType]}
              productIndex={productIndex}
              productType={productType}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActionRoot,
        }}
      >
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default VoidJacketForm;
