import DateFnsUtils from "@date-io/date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  makeStyles,
  MenuItem,
  RadioGroup,
  Select,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRowData
} from "@material-ui/data-grid";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ActionButton from "controls/global/action-button";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog/ConfirmationDialog";
import OverflowTooltip from "controls/global/overflow-tooltip";
import Radio from "controls/global/radio-button/Radio";
import {
  AnswerOption,
  Question,
  UserInput,
} from "entities/UIModel";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import CheckIcon from '@mui/icons-material/Check';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type QuestionAnswerFunction = (
  uniqueIdentifier: string,
  questionsAnswersID: number,
  questionID: string,
  value: string
) => void;

interface QADialogProps {
  isOpen: boolean;
  isQASubmitButtonDisabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const useStyles = makeStyles({
  menuItem: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  currencyField: {
    textAlign: "right",
  },
  headerStyle: {
    fontWeight: "bold",
  },
  radioButton: {
    marginBottom: margin.zero,
  },
});

const useDataGridStyles = makeStyles({
  root: {
    border: "none",
    "& .MuiDataGrid-colCellTitleContainer": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      textTransform: "uppercase",
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "none",
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    "& .MuiDataGrid-row .MuiSvgIcon-root.MuiSelect-icon": {
      color: colors.blue01,
    },
    "& .MuiDataGrid-row": {
      "& .MuiDataGrid-cell": {
        border: `${borderSize.xsmall} solid ${colors.grey09}`,
        borderStyle: "solid none none none",
      },
      "& .MuiDataGrid-cell:first-child": {
        borderLeftStyle: "solid"
      },
      "& .MuiDataGrid-cell:last-child": {
        borderRightStyle: "solid",
        minWidth: "40px !important",
      },
    },
    "& .MuiDataGrid-row:first-child": {
      "& .MuiDataGrid-cell:first-child": {
        borderTopLeftRadius: borderRadius.small,
      },
      "& .MuiDataGrid-cell:last-child": {
        borderTopRightRadius: borderRadius.small,
      }
    },
    "& .MuiDataGrid-row:last-child": {
      "& .MuiDataGrid-cell:first-child": {
        borderBottomLeftRadius: borderRadius.small,
      },
      "& .MuiDataGrid-cell:last-child": {
        borderBottomRightRadius: borderRadius.small,
      },
      "& .MuiDataGrid-cell": {
        borderStyle: "solid none solid none",
      },
    },
  },
});

//#region Cell Renderers
const renderRadioButton = (
  params: GridCellParams,
  value: Question,
  setQuestionAnswer: QuestionAnswerFunction,
  className: string
) => {
  const defaultOption = value.answers?.find((option) => option.default)?.answer;
  const defaultValue = value.answerValue ?? defaultOption ?? "";

  return (
    <RadioGroup
      row
      value={defaultValue}
      onChange={(e, value) => {
        setQuestionAnswer(
          params.row.id,
          params.row.qaId,
          params.row.questionId,
          value
        );
      }}
    >
      {value.answers?.map((option: AnswerOption) => {
        return (
          <FormControlLabel
            control={<Radio />}
            className={className}
            value={option.answer}
            label={option.answer}
            key={option.answer}
          />
        );
      })}
    </RadioGroup>
  );
};

const renderDropdown = (
  params: GridCellParams,
  value: Question,
  setQuestionAnswer: QuestionAnswerFunction,
  className: string
) => {
  const defaultOption = value.answers?.find((option) => option.default)?.answer;
  const defaultValue = value.answerValue ?? defaultOption ?? "";

  return (
    <Select
      variant="standard"
      disableUnderline
      fullWidth
      value={defaultValue}
      onFocus={() => params.row}
      onChange={(e, child: any) => {
        e.stopPropagation();
        setQuestionAnswer(
          params.row.id,
          params.row.qaId,
          params.row.questionId,
          child?.props.value
        );
      }}
    >
      {value.answers?.map((option: AnswerOption) => (
        <MenuItem
          value={option.answer}
          className={className}
          selected={option.default}
          key={option.answer}
        >
          {option.answer}
        </MenuItem>
      ))}
    </Select>
  );
};

const renderDate = (
  params: GridCellParams,
  value: Question,
  setQuestionAnswer: QuestionAnswerFunction
) => {
  function handleDateChange(uniqueIdentifier: string, qaId: number, rowId: string, date: Date | null) {
    if (date instanceof Date && isFinite(date.valueOf()))
      setQuestionAnswer(
        uniqueIdentifier,
        qaId,
        rowId,
        date.toISOString()
      );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        disableToolbar
        autoOk
        variant="inline"
        format="MM/dd/yyyy"
        value={value.answerValue}
        InputProps={{ disableUnderline: true }}
        onChange={(date) =>
          handleDateChange(
            params.row.id,
            params.row.qaId,
            params.row.questionId,
            date
          )
        }
      />
    </MuiPickersUtilsProvider>
  );
};

const renderCurrency = (
  params: GridCellParams,
  value: Question,
  setQuestionAnswer: QuestionAnswerFunction,
  inputClass?: string
) => {
  return (
    <Input
      fullWidth
      value={value.answerValue}
      type="number"
      onFocus={() => (params.cellMode = "edit")}
      disableUnderline
      style={{}}
      classes={{
        input: inputClass,
      }}
      onChange={(e: any) =>
        setQuestionAnswer(
          params.row.id,
          params.row.qaId,
          params.row.questionId,
          e.target.value
        )
      }
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
    />
  );
};

const renderNumber = (
  params: GridCellParams,
  value: Question,
  setQuestionAnswer: QuestionAnswerFunction,
  inputClass?: string
) => {
  return (
    <Input
      fullWidth
      value={value.answerValue}
      type="number"
      onFocus={() => (params.cellMode = "edit")}
      disableUnderline
      classes={{ input: inputClass }}
      onChange={(e: any) =>
        setQuestionAnswer(
          params.row.id,
          params.row.qaId,
          params.row.questionId,
          e.target.value
        )
      }
    />
  );
};

const renderHeader = (params: GridColumnHeaderParams, className?: string) => (
  <div className={className}>{params.colDef.headerName}</div>
);
//#endregion

const QADialog = ({
  isOpen,
  isQASubmitButtonDisabled,
  onClose,
  onSubmit,
}: QADialogProps) => {
  const classes = useStyles();
  const gridClasses = useDataGridStyles();
  const dialogClasses = useDialogStyles({ type: "INFO", width: 650 });
  const [data, setData] = useState<UserInput[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [errorDialogContent, setErrorDialogContent] = useState<React.ReactNode>(null);
  const { setValue, getValues } = useFormWrapper();

  const internalSubmit = () => {
    const noAnsweredQuestions = data.flatMap((input) =>
      input.questions.filter((question) => !question.answerValue)
    );

    if (noAnsweredQuestions.length > 0) {
      const questionNoDefaultAnswer = noAnsweredQuestions
        .filter(
          (question) =>
            question.answers?.filter((answer) => answer.default).length === 0
        )
        .map((q) => (
          <li key={q.questionID}>{`${q.questionText} requires an answer`}</li>
        ));

      if (questionNoDefaultAnswer.length > 0) {
        setErrorDialogContent(
          <ul style={{ fontSize: "11px", textAlign: "left" }}>
            {questionNoDefaultAnswer}
          </ul>
        );
        return;
      }
    }

    const combinedQNAs = getValues("combinedQNAs") as UserInput[] | undefined;
    combinedQNAs?.forEach((input, parentIndex) => {
      const userData = data.filter(
        (d) => d.questionsAnswersID === input.questionsAnswersID
      )[0];

      input.questions.forEach((x, childIndex) => {
        const questionToUpdate = userData.questions.filter(
          (q) => q.uniqueIdentifier === x.uniqueIdentifier
        )[0];

        if (!questionToUpdate.answerValue)
          questionToUpdate.answerValue =
            questionToUpdate.answers?.filter((a) => a.default)[0]?.answer ?? "";

        setValue(
          `combinedQNAs.${parentIndex}.questions.${childIndex}.answerValue`,
          questionToUpdate.answerValue
        );
      });
    });

    onSubmit();
  };

  const setQuestionAnswer = (
    uniqueIdentifier: string,
    questionsAnswersID: number,
    questionID: string,
    value: string
  ) => {
    const newData = [...data];
    const userInput = newData.find(
      (ui) => ui?.questionsAnswersID === questionsAnswersID
    );
    const question = userInput?.questions.find(
      (q) => q.uniqueIdentifier === uniqueIdentifier
    );

    if (question) {
      question.answerValue = value;
      setData(newData);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "question",
      headerName: "Input",
      renderHeader: renderHeader,
      width: 280,
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: function Render(params: GridCellParams) {
        const value = params.value as string;
        return <OverflowTooltip tooltip={value} text={value} />;
      },
    },
    {
      field: "answer",
      headerName: "Selection",
      renderHeader: renderHeader,
      width: 280,
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: function Render(params: GridCellParams) {
        const value = params.value as Question;
        switch (value.inputMethod) {
          case "Dropdown":
            return renderDropdown(
              params,
              value,
              setQuestionAnswer,
              classes.menuItem
            );
          case "RadioButton":
            return renderRadioButton(
              params,
              value,
              setQuestionAnswer,
              classes.radioButton
            );
          case "Date":
            return renderDate(params, value, setQuestionAnswer);
          case "Number":
            return renderNumber(
              params,
              value,
              setQuestionAnswer,
              classes.currencyField
            );
          case "Currency":
            return renderCurrency(
              params,
              value,
              setQuestionAnswer,
              classes.currencyField
            );
          default:
            return <div>{value.answerValue}</div>;
        }
      },
    },
  ];

  useEffect(() => {
    if (!isOpen) return;

    const additionalQuestions: UserInput[] | undefined =
      getValues("combinedQNAs");

    if (!additionalQuestions || additionalQuestions.length === 0) {
      setData([]);
      return;
    }

    const uiWithUnansweredQuestions = additionalQuestions.filter((ui) =>
      ui.questions.filter((question) => !question.answerValue)
    );

    setData(uiWithUnansweredQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const rowData = data.flatMap((userInput) => {
      return userInput.questions.map((question) => {
        return {
          id: question.uniqueIdentifier,
          qaId: userInput.questionsAnswersID,
          questionId: question.questionID,
          question: question.questionText,
          answer: question,
        };
      });
    });
    setRows(rowData);
  }, [data]);

  return (
    <>
      <Dialog open={isOpen} classes={{ root: dialogClasses.dialogRoot }} maxWidth="md" fullWidth >
        <DialogTitle disableTypography={true} classes={{ root: dialogClasses.dialogTitleRoot }}>
          Additional Information Needed
          <DialogTitleCloseButton onClick={onClose}/>
        </DialogTitle>
        <DialogContent classes={{ root: dialogClasses.dialogContentRoot }} >
          <DataGrid
            disableSelectionOnClick
            disableColumnSelector
            className={gridClasses.root}
            rows={rows}
            columns={columns}
            rowHeight={40}
            headerHeight={35}
            autoHeight={true}
            hideFooter={true}
            showColumnRightBorder={false}
          />
        </DialogContent>
        <DialogActions classes={{ root: dialogClasses.dialogActionRoot }} >
          <ActionButton

            variant="outlined"
            color="primary"
            onClick={internalSubmit}
            disabled={isQASubmitButtonDisabled}
            startIcon={<CheckIcon />}
          >
            Submit
          </ActionButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        singleActionButton
        singleActionButtonText="OK"
        isOpen={!!errorDialogContent}
        confirmationMessage={errorDialogContent}
        onYes={() => setErrorDialogContent(null)}
      />
    </>
  );
};

export default QADialog;
