import ProductOption from "entities/UIModel/company/ProductOption";
import React from "react";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { ProductType, RuleCondition } from "utils/data/enum";
import { isEqual } from "lodash";
import { StateProductConfig } from "entities/UIModel/company/StateProductConfig";

export function toProductOption(options: ProductOption[]) {
  return options.map((po) => ({
    text: po.formType,
    value: po.formType,
    selected: po.default === 1,
  }));
}

export default function useProductOption() {
  const productsRef = React.useRef<StateProductConfig | undefined>();
  const [{ stateProductConfig }] = useCompanyProductOptions();

  if (!isEqual(productsRef.current, stateProductConfig)) {
    productsRef.current = stateProductConfig;
  }

  const getUniqueProductOptions = (): ProductOption[] => {
    let productOptions: ProductOption[] = [];

    const uniqueProductNames = [
      ...new Set(
        stateProductConfig.productOptions.map(
          (productOption) => productOption.product
        )
      ),
    ];
    uniqueProductNames?.forEach((productName) => {
      const productOption = stateProductConfig.productOptions.find(
        (po) => po.product === productName
      );
      if (productOption) {
        productOptions.push(productOption);
      }
    });

    return productOptions;
  };

  const getLetterProduct = () => {
    const uniqueProductOptions = getUniqueProductOptions();
    const letterProduct = uniqueProductOptions?.find(
      (po) =>
        po.product.toUpperCase() === ProductType.Aal ||
        po.product.toUpperCase() === ProductType.Cpl
    );

    return letterProduct;
  };

  const hasAdditionalParty = () => {
    let hasAddlParty: boolean = false;

    const letterProduct = getLetterProduct();

    if (letterProduct) {
      if (
        letterProduct.approvedAttorney === RuleCondition.IsAllowed ||
        letterProduct.secondaryAgency === RuleCondition.IsAllowed ||
        letterProduct.settlementCompany === RuleCondition.IsAllowed
      ) {
        hasAddlParty = true;
      }
    }

    return hasAddlParty;
  };

  const getProductOptionsByProductType = React.useCallback(
    (productType: string) => {
      if (!productsRef.current) return [];

      return productsRef.current.productOptions.filter(
        (po) => po.product.toUpperCase() === productType
      );
    },
    []
  );

  const checkTransCodeRequired = React.useCallback(
    (formType: string): boolean => {
      if (!productsRef.current) return false;

      const product = productsRef.current.productOptions.find(
        (p) =>
          p.product.toUpperCase() === ProductType.Jacket &&
          p.formType === formType
      );
      if (!product) return false;

      return product.transCode
        ? product.transCode === RuleCondition.IsRequired
        : false;
    },
    []
  );


  return {
    getUniqueProductOptions,
    getLetterProduct,
    hasAdditionalParty,
    getProductOptionsByProductType,
    checkTransCodeRequired
  };
}
