import Button from "@material-ui/core/Button";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";

const DefaultStyle = withStyles(() => ({
  root: {
    background: "none",
    color: colors.blue01,
    cursor: "pointer",
    fontSize: fontSize.large,
    margin: margin.zero,
    padding: padding.zero,
    textTransform: "none",
    "&$disabled": {
      background: "none",
      color: colors.blue01,
      opacity: 0.5,
    },
    "&:hover": {
      background: "none",
      color: colors.blue05,
    },
  },
  disabled: {},
}))(Button);

const FloatRightStyle = withStyles((props) => ({
  root: {
    background: "none",
    color: colors.blue01,
    cursor: "pointer",
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal1,
    margin: margin.zero,
    padding: padding.zero,
    position: "absolute",
    right: "10px",
    textTransform: "none",
    top: "-1px",
    "&$disabled": {
      background: "none",
      color: colors.blue09,
      opacity: 1,
    },
    "&:hover": {
      background: "none",
      color: colors.blue05,
      cursor: "pointer",
    },
    "& .rectangleRightButton": {
      paddingLeft: padding.xsmall1,
      paddingRight: padding.xsmall1,
      paddingTop: padding.xsmall,
      position: "relative",
      "& div": {
        position: "relative",
        zIndex: zIndex.positiveMin,
      },
      "&::before": {
        backgroundColor: colors.white,
        content: "''",
        height: '2px',
        left: 0,
        position: 'absolute',
        right: 0,
        top: '50%',
        zIndex: zIndex.zero,
      },
      "&.disabled::before": {
        backgroundColor: colors.grey10,
      },
    }
  },
  disabled: {},
}))(Button);

interface IProps {
  children: React.ReactElement | string;
  buttonStyle?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  style?: React.CSSProperties | undefined;
  tabIndex?: string;
  onMouseEnter?: any;
  onMouseLeave?:any;
  disabledTextField?: boolean;
}

const LinkButton = (props: IProps) => {
  const { children, buttonStyle = "default", onClick, disabled, style, tabIndex, onMouseEnter, onMouseLeave, disabledTextField } = props;
  switch (buttonStyle) {
    case "floatRight":
      return <FloatRightStyle
        tabIndex={tabIndex}
        style={style}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={`rectangleRightButton ${disabledTextField ? 'disabled' : ''}`}>
          <div>
            {children}
          </div>
        </div>
      </FloatRightStyle>;
    default:
      return <DefaultStyle
        tabIndex={tabIndex}
        style={style}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </DefaultStyle>;
  }
};

export default LinkButton;
