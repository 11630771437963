import {
  Box,
  Container,
  Typography
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core/styles";
import React, { ReactElement } from "react";
import {
  fontSize,
  fontWeight,
  gaps,
  gradients
} from "theme/defaultStyle";
import ErrorMessage from "./SectionErrorMessage";

type Props = {
  children: ReactElement;
  icon: ReactElement | Element;
  isError?: boolean;
  isNavigator?: boolean;
  subHeader?: string;
  title: string;
  navItemRef?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inner: {
      padding: 0,
    },
    innerContainer: theme.custom.container.outer.root,
    outer: {
      display: "flex",
      flexDirection: "column",
      gap: gaps.medium1,
    },
    subheader: theme.custom.common.title.info,
    title: theme.custom.container.outer.title,
    titleText: {
      background: gradients.maroonGradient01,
      fontSize: fontSize.xlarge2,
      fontWeight: fontWeight.bold2,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  })
);

const FormSection = ({
  children,
  icon,
  title,
  isNavigator = false,
  isError = false,
  subHeader,
  navItemRef,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Box
      {...rest}
      className={classes.outer}
      paddingBottom={4}
      ref={navItemRef}
    >
      <Container
        maxWidth="lg"
        className={classes.inner}>
        <Box className={classes.title}
        >
          {icon}
          <Typography className={classes.titleText}>{title}</Typography>
          {isError && <ErrorMessage />}
        </Box>
      </Container>
      {subHeader && (
        <p>
          <div
            className={classes.subheader}
            style={{ marginLeft: 50, marginRight: 50 }}
          >
            {subHeader}
          </div>
        </p>
      )}
      {isNavigator ? (
        <>{children}</>
      ) : (
        <Container maxWidth="lg" className={classes.innerContainer}>
          {children}
        </Container>
      )}
    </Box>
  );
};

export default FormSection;
