import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import PayByEPayButton from "controls/global/pay-by-epay/PayByEPayButton";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import React from "react";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { usePendingPay } from "utils/context/PendingPayContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import { ReportAndPayAction } from "utils/data/enum";
import { formatCurrency } from "utils/shared";
import { v4 as uuidv4 } from "uuid";

type Props = {
  selectedItems: PendingPayFile[];
  disabled?: boolean;
  hidden?: boolean;
};

const PAY_BY_EPAY_TEXT = (filesSelected: number, amount: string) =>
  `By clicking <strong>Submit</strong>, you will be prompted to complete the electronic payment in the amount of ${amount}`;

export default function EPay({ selectedItems, disabled, hidden }: Props) {
  const [, { openDialog }] = useDialog();
  const [, { setRefresh }] = usePendingPay();
  const [, { reportPricing, }] = usePayment();
  const [, { openProgressbar }] = useProgressbar();

  const handleSubmitModal = async () => {
    const ids = selectedItems.map((f) => f.fileId);
    const requestId = uuidv4();

    openProgressbar({
      title: "Please wait while processing ePay...",
      requestId,
      onCloseAction: () => {
        setRefresh();
      },
    });
    // TODO: Use new enum when EPay is added.
    await reportPricing(ids, requestId, ReportAndPayAction.PayByCheck);
  };

  const getText = () => {
    const total = selectedItems.reduce((accumulator, current) => {
      return accumulator + (current.totalBilledAmountDue ?? 0);
    }, 0);

    return PAY_BY_EPAY_TEXT(selectedItems.length, formatCurrency(total));
  };

  const handlePayClick = () => {
    openDialog({
      title: "ePay",
      content: (
        <ReportContentMessage
          mainText={getText()}
        />
      ),
      onSubmitAction: handleSubmitModal,
    });
  };

  return (
    <PayByEPayButton
      disabled={disabled}
      onClick={handlePayClick}
      hidden={hidden}
    />
  );
}
