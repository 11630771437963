import IconButton from "@material-ui/core/IconButton";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { BlockOutlined } from "@material-ui/icons";
import React from "react";
import { colors } from "theme/defaultStyle";

type Props = {
  disabled: boolean;
  hidden: boolean;
  isIssued: boolean;
  onRequestVoid: () => void;
};

const useStyles = makeStyles({
  cell: {
    width: 60,
    padding: "0px !important",
    borderRight: `1px solid ${colors.grey04}`,
  },
  disabledVoidButton: {
    opacity: 0.5,
  },
});

const StyledBlockIcon = styled(BlockOutlined)({
  transform: "scaleX(-1)",
});

export default function EndorsementVoidCell({
  disabled,
  hidden,
  isIssued,
  onRequestVoid,
}: Props) {
  const classes = useStyles();

  if (!isIssued) {
    return (
      <TableCell
        classes={{ sizeSmall: classes.cell }}
        hidden={hidden}
        align="center"
      />
    );
  }

  return (
    <>
      <TableCell
        classes={{ sizeSmall: classes.cell }}
        hidden={hidden}
        align="center"
      >
        <IconButton
          classes={{
            disabled: classes.disabledVoidButton,
          }}
          disabled={disabled}
          aria-label="void-endorsement"
          onClick={onRequestVoid}
        >
          <StyledBlockIcon htmlColor={colors.blue01} />
        </IconButton>
      </TableCell>
    </>
  );
}
