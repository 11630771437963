import { CancelTokenSource } from 'axios';
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import {
  FileSearch,
  FileSearchCriteria,
} from "entities/ApiModel";
import {
  Column,
  IColumn,
} from "entities/ApiModel/IColumn";
import { FilesRecord } from "entities/UIModel";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";
import { LocalStorageKeys } from "utils/data/enum";
import {
  startNotifying,
  stopNotifying,
} from "utils/services/ContextProgressNotifierService";
import { v4 as uuidv4 } from "uuid";

interface State {
  progressPercent?: number;
  error?: string;
  axiosCancelToken?: CancelTokenSource;
  requestTimeoutMs: number;
}

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const startProgressNotififer =
  (criteria: FileSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: FileSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const getSearchType = (criteria?: FileSearchCriteria) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "files-long" : "files-short";
};

const setFilesRecordList =
  (filesSearchResponse: FileSearch[]) =>
    (): FilesRecord[] => {
      if (!filesSearchResponse || filesSearchResponse.length === 0) {
        return [];
      }

      const records: FilesRecord[] = filesSearchResponse.map((file) => {
        const jsOpenDate = file.OpenDate ? new Date(file.OpenDateTime) : null;
        return {
          fileSearchId: file.FileSearchID,
          fileNumber: file.ClientFileID || "",
          agencyId: file.AgencyID || "",
          fileId: file.FileID || 0,
          propertyAddress: file.PropertyAddress || "",
          buyerBorrower: file.BuyerNames || "",
          seller: file.SellerNames || "",
          lender: file.LenderNames || "",
          status: file.FileStatusTypeCode,
          opened: jsOpenDate,
          openedTimestamp: jsOpenDate?.getTime() ?? 0,
          uniqueIdentifier: uuidv4(),
          productNames: file.ProductNames || "",
          agencyID: file.AgencyID || "",
          locationDisplayName: file.LocationDisplayName || "",
          legacyID: file.LegacyID ?? "",
          agencyName: file.AgencyName || "",
          stateAbbr: file.StateAbbr || "",
          propertyTypeCode: file.PropertyTypeCode || "",
          transactionTypeCode: file.TransactionTypeCode || "",
          rowsPerPage: file.RowsPerPage || 0,
          currentRow: file.CurrentRow || 0,
          currentPage: file.CurrentPage || 0,
          totalPages: file.TotalPages || 0,
          totalRows: file.TotalRows || 0,
        };
      });

      return records;
    };

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof FilesRecord);

      localStorage.setItem(LocalStorageKeys.COLUMNS_FILES, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_FILES_HIDDEN, JSON.stringify(gridHiddenColumns));

      return { gridColumns, gridHiddenColumns };
    };

const actions = {
  setRequestTimeoutMs:
    (requestTimeoutMs: number) =>
      async ({ setState }: StoreApi) => {
        setState({ requestTimeoutMs });
      },
  cancelRequests:
    () =>
      async ({ dispatch, getState }: StoreApi) => {
        dispatch(stopProgressNotififer());
        const token = getState().axiosCancelToken;
        if (token) {
          token.cancel("MyFilesContext was requested to be cancelled");
        }
      },
  getFileList:
    (criteria: FileSearchCriteria) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("MyFilesContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/files/search",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));

          return dispatch(setFilesRecordList(data));
        } catch (error: any) {
          setState({ error: error.message });
          dispatch(stopProgressNotififer());
        }
      },
  getAdvancedSearchFiles:
    (criteria: FileSearchCriteria) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("MyFilesContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/files/AdvancedSearch",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));

          return dispatch(setFilesRecordList(data));
        } catch (error: any) {
          setState({ error: error.message });
          dispatch(stopProgressNotififer());
        }
      },
  getInitialColumnsDefintion:
    (hidden: boolean) =>
      () => {
        const key = hidden
          ? LocalStorageKeys.COLUMNS_FILES_HIDDEN
          : LocalStorageKeys.COLUMNS_FILES;
        const existingColumns = localStorage.getItem(key) || "[]";

        return JSON.parse(existingColumns);
      },
  getColumnsDefinition:
    () =>
      async ({ dispatch }: StoreApi) => {
        const { data } = await axiosSecuredInstance.get(
          "/UISettings/grids/columnSettings/files"
        );

        return dispatch(setColumns(data));
      },
  setColumnDefinition:
    (
      fields: (keyof FilesRecord)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
      async ({ dispatch }: StoreApi) => {
        const data: IColumn[] = fields.map((field) => ({
          field,
          name: "", //NOT REQUIRED,
          [propName]: value,
        }));

        try {
          const response = await axiosSecuredInstance.post("/UISettings/grids/columnSettings/files", data);
          if (response.data) {
            dispatch(setColumns(response.data));
          }
        } catch (error) {
          console.error(error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    progressPercent: -1,
    requestTimeoutMs: 45000,
  },
  actions,
  name: "myFiles",
});

const hook = createHook(Store);
export const useMyFiles = () => { return hook(); };
