import React from "react";
import { Snackbar, SnackbarProps, withStyles, Box } from "@material-ui/core";
import { defaultStyle, colors, fontSize, margin } from "theme/defaultStyle";
import { useFlashMessageState } from "utils/context/FlashMessageContext";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const { control } = defaultStyle;

const SnackbarStyled = withStyles(() => ({
  root: {
    "& div.MuiSnackbarContent-root": {
      color: colors.white,
      backgroundColor: colors.red01,
      borderRadius: control.borderRadius,
      fontSize: fontSize.base
    },
  },
}))(Snackbar);

const iconStyles = { fontSize: 20, color: colors.white, marginRight: margin.medium };

const FlashMessage = (props: SnackbarProps) => {
  const [{ open, message }] = useFlashMessageState();


  return (
    <SnackbarStyled {...props}
      open={open}
      message={<Box><ReportProblemIcon style={iconStyles} />{message}</Box>}
    />
  );
}

export default FlashMessage;