import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import { margin } from "theme/defaultStyle";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";

const useStyles = makeStyles({
  minNumberWidth: {
    margin: `0 ${margin.xsmall1} 0 0`,
    width: "250px !important",
  },
});

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isPricingSectionUpdated: boolean;
};

export default function TotalPremium({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isPricingSectionUpdated,
}: Props) {
  const classes = useStyles();
  const {
    getPremiumSubTotal,
  } = usePricingCalculator();
  const premiumSubTotal = getPremiumSubTotal(isPricingSectionUpdated);

  return (
    <TableRow>
      <TableCell style={{ paddingLeft: 10 }}>
        <div className={classes.minNumberWidth}>Premium Subtotal</div>
      </TableCell>
      {displayFieldIcon && <TableCell />}
      {displayLiabilityColumn && <TableCell />}
      {displayRateTypeColumn && <TableCell />}
      {displayReissueColumn && <TableCell />}
      {displayTransCodeColumn && <TableCell />}
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.actualFeeTotalProduct} />
        }
      </TableCell>
      {displayRiskRateColumn && (
        <TableCell
          align="right"
          style={{ maxWidth: "170px" }}
        >
          {hasIssuedProducts &&
            <TooltipCell title={premiumSubTotal.riskRateTotalProduct} />
          }
        </TableCell>
      )}
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.agentRetentionTotalProduct} />
        }
      </TableCell>
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.totalDueTotalProduct} />
        }
      </TableCell>
    </TableRow>
  );
}
