import React from "react";
import { Controller } from "react-hook-form";
import { 
    gaps,
  margin, 
  padding,
 } from "theme/defaultStyle";
import { 
  Box,
  FormControlLabel, 
  Radio, 
  RadioGroup,
  styled, 
} from "@material-ui/core";

interface Props {
    name: string;
    defaultValue: any;
    disabled?: boolean;
    onChange?: ( event: React.ChangeEvent<HTMLInputElement>, sValue: string) => void;
    labelYes?: string;
    labelNo?: string;
  }

  const StyledRadioGroup = styled(RadioGroup)({
    display: "flex",
    flexDirection: "row",
    gap: gaps.large1,
    "& div": {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      "& label": {
        margin: 0,
      },
      "& .MuiRadio-root": {
        marginLeft: `-${padding.small1}`, // The margin/padding pair allows pulse graphic to not be clipped.
        paddingLeft: padding.small1,
        paddingRight: padding.small1,
      }
    },
  });

function RadioButtonField({
  name, 
  defaultValue, 
  disabled = false, 
  onChange,
  labelYes = "Yes",
  labelNo = "No",
}: Props) {
  const StyledBox = styled(Box)({
    padding: padding.zero,
    margin: margin.zero,
    "& .MuiRadio-root": {
      marginTop: "-6px",
      marginBottom: "-8px",
    },
  });

  const handleOnChangeEvent = ( event: React.ChangeEvent<HTMLInputElement>, sValue: string) => {    
    onChange && onChange(event, sValue);
  };

  return (
    <StyledBox>
      <Controller
        name={name}
        defaultValue={defaultValue}        
        render={({ field: {name, value, onChange} }) => { 
        const handleOnChange = (
            event: React.ChangeEvent<HTMLInputElement>,
            sValue: string
            ) => {            
              handleOnChangeEvent(event, sValue);
            };
        return (
          <StyledRadioGroup {...{name, value, defaultValue}} onChange = {(e, p) => handleOnChange(e, p)}>            
            <FormControlLabel
                value={labelYes}
                control={<Radio color="primary" />}
                label={labelYes}
                disabled={disabled}                
                />
                <FormControlLabel
                value={labelNo}
                control={<Radio color="primary" />}
                label={labelNo}
                disabled={disabled}
            />
          </StyledRadioGroup>
        )}}
      />
    </StyledBox>
  );
}

export default RadioButtonField;
