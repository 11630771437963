import React from "react";
import { cloneDeep } from "lodash";
import { List } from "linq-typescript";
import { IData, ISegment } from "controls/global/editable-segment-control/EditableSegmentControl";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getDisplayName } from "pages/file/utils/products/letter";
import * as UIModel from "entities/UIModel";

function useCurrentAddressData() {
  const { getValues } = useFormWrapper();

  const getCurrentAddreseeNamesData = React.useCallback((
    productEntity: string,
    productIndex: number,
    parties: UIModel.GeneralFileParty[],
  ) => {
    const addresseeNamesDataField = `${productEntity}.${productIndex}.addresseeNamesData`;
    let currentValue = getValues(addresseeNamesDataField);

    if (typeof currentValue !== "object") {
      currentValue = undefined;
    }

    if (currentValue?.deselectedOptions?.length) {
      const filteredDeseletectedOptions = [] as string[];
      currentValue?.deselectedOptions.forEach((deselectedSegment: string) => {
        const foundParty = parties.find(
          (party: UIModel.GeneralFileParty) => party.integrationKey === deselectedSegment
        );
        if (foundParty && getDisplayName(foundParty) !== "") {
          filteredDeseletectedOptions.push(foundParty.integrationKey);
        }
      });
      currentValue.deselectedOptions = filteredDeseletectedOptions;
    }

    const defaultData: IData = {
      segments: new List<ISegment>(),
      deselectedOptions: [],
    };

    return !currentValue ? defaultData : cloneDeep(currentValue);
  }, [getValues]);


  return { getCurrentAddreseeNamesData };
}

export default useCurrentAddressData;
