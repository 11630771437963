import { AxiosRequestConfig } from "axios";
import { CancelTokenSource } from "axios";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { StoreActionApi } from "react-sweet-state";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface AgencyLocation extends SelectFieldOption {
  id: string;
  disabled?: boolean;
}

interface State {
  error: string | null;
  isLoading: boolean;
  agencyStates: Array<SelectFieldOption>;
  isRetrievingStates: boolean;
  isRetrievingAgencyLocations: boolean;
  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const setDefaultState =
  () =>
  ({ setState }: StoreApi) => {
    setState({
      agencyStates: [],
    });
  };

const getData =
  (url: string, action: (param: Array<any>) => ({ setState }: StoreApi) => any, config?: AxiosRequestConfig) =>
  async ({ dispatch }: StoreApi) => {
    try {
      const { data } = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const setAgencyStates =
  (data: Array<any>, isUpdate: boolean = false) =>
  ({ setState }: StoreApi) => {
    setState({
      agencyStates: data.map((state) => ({
        text: state.StateAbbr,
        value: state.StateCode,
      })),
      isRetrievingStates: isUpdate,
    });
  };

const defaultState = {
  agencies: [],
  agencyLocations: [],
  error: null,
  isLoading: false,
  agencyStates: [],
  isRetrievingStates: false,
  isRetrievingAgencyLocations: false,
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getAgencyStates:
      () =>
      async ({ dispatch, setState }: StoreApi) => {
        let url = "/lookup/getstatelist?countryCodeNum=840";
        setState({ isRetrievingStates: true });

        dispatch(getData(url, setAgencyStates));
      },
    resetLookups:
      () =>
      ({ dispatch }: StoreApi) => {
        dispatch(setDefaultState());
      },
  },
  name: "registrationLookup",
});

const hook = createHook(Store);
export const useRegistrationLookup = () => {
  return hook();
};
