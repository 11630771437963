import { styled } from "@material-ui/core";
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import TextInputField from "controls/global/text-input-field";
import { buildTransCodeKey } from "pages/file/utils/products/jacket";
import React, {
  useState,
} from "react";
import { useTransCodeContext } from "utils/context/CompanyTransCodeContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { ProductType } from "utils/data/enum";

type Props = {
  schema: string;
  disabled: boolean;
  formType: string;
  isTransCodeRequired: boolean | undefined;
};

const Container = styled("div")({
  flex: 1,
  maxWidth: "500px",
  minWidth: "120px",
  width: "unset",
});

const JacketTransCode = ({
  schema,
  disabled,
  formType,
  isTransCodeRequired,
}: Props) => {
  const [transCodes, setTransCodes] = useState<SelectFieldOption[]>([]);
  const { getValues, setValue } = useFormWrapper();
  const [, { getTransCodes }] = useTransCodeContext();

  const fieldName = `${schema}.pricingDetail.transCodeKey`;
  const transCodePropName = `${schema}.pricingDetail.transCode`;
  const transCodeDescPropName = `${schema}.pricingDetail.transCodeDescription`;
  const currentStateAbbr: string = getValues("properties.0.state.abbr");

  const handleOnChange = (
    _: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    selectedValue: string,
    desc?: string,
    initValue?: string
  ) => {
    if (!selectedValue) return;

    setValue(fieldName, selectedValue);
    setValue(transCodePropName, initValue);
    setValue(transCodeDescPropName, desc);
  };

  React.useEffect(() => {
    async function getTransCodesAsync() {
      const data = await getTransCodes(
        formType,
        currentStateAbbr,
        ProductType.Jacket
      );
      const codes: SelectFieldOption[] =
        data?.map((t) => {
          const key = buildTransCodeKey(t.transCode, t.transCodeDescription);
          return {
            text: key,
            value: key,
            desc: t.transCodeDescription,
            originalValue: t.transCode,
          };
        }) || [];

      if (codes.length === 0) {
        setTransCodes([]);
        return;
      }

      if (codes.length === 1) {
        setValue(fieldName, codes[0].value);
        setValue(transCodePropName, codes[0].originalValue);
        setValue(transCodeDescPropName, codes[0].desc);
      }

      setTransCodes(codes);
    }

    if (!formType || disabled || !isTransCodeRequired) return;
    getTransCodesAsync();
  }, [
    currentStateAbbr,
    disabled,
    fieldName,
    getTransCodes,
    isTransCodeRequired,
    formType,
    setValue,
    transCodeDescPropName,
    transCodePropName,
  ]);

  if (!isTransCodeRequired) return null;

  return (
    <Container>
      {disabled
        ? <TextInputField label="Trans Code" name={fieldName} disabled />
        : <SelectField
          label="Trans Code"
          name={fieldName}
          options={transCodes}
          optionsDescField="text"
          disabled={disabled}
          onChange={handleOnChange}
          autosetValue={false}
        />
      }
    </Container>
  );
};

export default JacketTransCode;
