import { axiosSecuredInstance } from "configurations/axiosConfig";
import { JacketFormDetail } from "entities/UIModel";
import Form from "entities/UIModel/company/Form";
import { createHook, createStore } from "react-sweet-state";
import { JacketFormType, ProductType } from "utils/data/enum";
import { formatDateToJSONString } from "utils/shared";
import { v4 as uuidv4 } from "uuid";

const getFormDetailCachedKey = (formType: string, formId: string) => {
  return `FormDetailCachedKey-${formType}~${formId}`;
};

const mapFormDetails = (data: any, formId: string, formType: string) => {
  const mappedFormDetails: JacketFormDetail[] = data.map((fd: any) => {
    const jacketFormDetail: JacketFormDetail = {
      integrationKey: uuidv4(),
      formID: formId,
      formDetailID: fd.detailID,
      formDetailName: fd.detailName,
      formDetailType: fd.dataType,
      formDetailLimit: fd.dataLengthLimit,
      isRequired: fd.isRequired && fd.isRequired === "Y" ? true : false,
      isDefault: fd.default && fd.default === 1 ? true : false,
    };

    return jacketFormDetail;
  });

  return mappedFormDetails;
};

type State = {};
type Actions = typeof actions;

const actions = {
  getForms:
    (
      companyId: string,
      state: string,
      productType: ProductType,
      formType: JacketFormType | string,
      defaultDate: Date | undefined
    ) =>
    async () => {
      try {
        const { data } = await axiosSecuredInstance.get<any>(
          `/Company/GetForms?CompanyID=${companyId}&State=${state}&productTypeCode=${productType}&formType=${formType}${defaultDate ? `&defaultDate=${formatDateToJSONString(defaultDate, true)}`:"" }`
        );

        const companyForms: Form[] = data.map((f: any) => {
          const form: Form = {
            formID: f.formID,
            formName: f.formName,
            formPrefix: f.formPolicyPrefix,
            agencyExclusive: f.agencyExclusive,
            formVersion: f.formVersion,
            formTemplateName: f.formTemplateName,
            opaIdentifier: f.opaIdentifier,
            default: f.default,
            liabilityAmount: f.liabilityAmount,
            formType: f.formType,
            showJacketDetails: f.showJacketDetails,
          };

          return form;
        });

        return companyForms;
      } catch (error) {
        console.error("ERROR: CompanyContext.", error);
      }
    },
  getJacketFormDetails:
    (formId: string, formType: JacketFormType | string) => async () => {
      const cachedKey = getFormDetailCachedKey(formType, formId);
      const cachedData = sessionStorage.getItem(cachedKey);

      if (cachedData) {
        return mapFormDetails(JSON.parse(cachedData), formId, formType);
      }
      try {
        const { data } = await axiosSecuredInstance.get<any>(
          `/Company/GetJacketFormDetails?FormDetailFormID=${formId}&JacketFormType=${formType}`
        );
        sessionStorage.setItem(cachedKey, JSON.stringify(data));
        return mapFormDetails(data, formId, formType);
      } catch (error) {
        console.error("ERROR: CompanyContext.", error);
      }
    },
};

const Store = createStore<State, Actions>({
  initialState: {},
  actions,
  name: "companyForm",
});

const hook = createHook(Store);
export const useCompanyForm = () => { return hook() };
