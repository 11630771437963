import React, { useState } from "react";
import Styled from "styled-components";

import DocsIcon from "theme/icons/DocsIcon";
import LightTooltip from "controls/global/light-tooltip";
import { margin } from "theme/defaultStyle";

interface Props {
  taxDocument: string;
}

const PointerDiv = Styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: ${margin.medium};
`;

function TaxDocumentLink({ taxDocument }: Props) {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleOpenTooltip = (open: boolean) => {
    setOpenTooltip(open);
  };

  return (
    <div
      onMouseEnter={() => handleOpenTooltip(true)}
      onMouseLeave={() => handleOpenTooltip(false)}
    >
      <LightTooltip
        title="View Tax Codes"
        arrow
        open={openTooltip}
        placement="top-end"
      >
        <PointerDiv>
          <a href={taxDocument} target="_blank" rel="noreferrer">
            <DocsIcon />
          </a>
        </PointerDiv>
      </LightTooltip>
    </div>
  );
}

export default TaxDocumentLink;
