import React from "react";
import { TriggerConfig, useFormContext } from "react-hook-form";

export const getNameString = (fieldNames?: string | readonly string[]): any => {
  if (!fieldNames) return "";
  if (typeof fieldNames === "string") return `${fieldNames}` as const;
  if (Array.isArray(fieldNames)) return fieldNames;
};

const useFormWrapper = () => {
  const { setValue, getValues, trigger, watch, ...context } = useFormContext();

  const setValueOverride = React.useCallback(
    (
      fieldName: string,
      value: any,
      options?:
        | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
            shouldTouch: boolean;
          }>
        | undefined
    ) => {
      setValue(`${fieldName}` as const, value, options);
    },
    []
  );

  const getValuesOverride = React.useCallback(
    (fieldNames?: string | readonly string[]) => {
      if (!fieldNames) return getValues();
      if (typeof fieldNames === "string")
        return getValues(`${fieldNames}` as const);
      if (Array.isArray(fieldNames)) return getValues(fieldNames);
    },
    []
  );

  const triggerOverride = (
    fieldNames?: string | readonly string[],
    options?: TriggerConfig
  ) => {
    if (!fieldNames) return trigger(undefined, options);
    if (typeof fieldNames === "string")
      return trigger(`${fieldNames}` as const, options);
    if (Array.isArray(fieldNames)) return trigger(fieldNames, options);
  };

  const watchOverride = (
    fieldNames?: string | readonly string[],
    defaultValue?: any
  ) => {
    if (!fieldNames) return watch();
    if (typeof fieldNames === "string")
      return watch(`${fieldNames}` as const, defaultValue);
    if (Array.isArray(fieldNames)) return trigger(fieldNames);
  };

  const getNameString = (fieldNames?: string | readonly string[]): any => {
    if (!fieldNames) return "";
    if (typeof fieldNames === "string") return `${fieldNames}` as const;
    if (Array.isArray(fieldNames)) return fieldNames;
  };

  return {
    ...context,
    setValue: setValueOverride,
    getValues: getValuesOverride,
    trigger: triggerOverride,
    watch: watchOverride,
    nameString: getNameString,
  };
};

export default useFormWrapper;
