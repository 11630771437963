import React from "react";
import ActionButton from "../action-button";
import CreditCardIcon from '@mui/icons-material/CreditCard';

type Props = {
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

export default function PayByEPayButton({ disabled, hidden, onClick }: Props) {
  return (
    <ActionButton
      hidden={hidden}
      variant="outlined"
      color="primary"
      startIcon={< CreditCardIcon/>}
      disabled={disabled}
      onClick={onClick}
      name="ePay"
    >
      ePay
    </ActionButton>
  );
}
