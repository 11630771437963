import React from "react";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import GlobalAccessHome from "pages/globalAccess/GlobalAccessHome";
import HomePage from "pages/home/HomePage";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

const SelectedHomePage: React.FC = () => {
  const [{ companyLocationStates }] = useGlobalAccess();
  const [{ profileSettings }] = useProfileSettingsCache();

  return <>{
            companyLocationStates.length <= 1 ||
            (!profileSettings.isStaffUser ||
              profileSettings.userProfile?.skipAgencySelection === "Yes"
            ) ? <HomePage /> : <GlobalAccessHome />}</>;
};

export default SelectedHomePage;
