import { TextField, withStyles } from "@material-ui/core";
import { maskZipCode, normalizeValue } from "pages/file/utils/helper";
import React, { useEffect, useState } from "react";

interface IProps {
  name: string;
  value: string;
  label?: string;
  disabled?: boolean;
  hasError?: boolean;
  fieldRef?: React.Ref<any>;
  onChange?: (value: string) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  size?: "small" | "medium";
}

const CustomFormControl = withStyles((theme) => ({
  root:(props: any) => {
    return {
      "& input.MuiInputBase-input":
      theme.custom.textField.inputOutlined,
      "& input.MuiInputBase-input.Mui-disabled":
      theme.custom.textField.inputOutlinedDisabled,
      width: theme.custom.textInputZip.width,
    }
  },
}))(TextField);

const TextInputZip = ({
  label,
  name,
  value,
  disabled = false,
  onChange,
  onBlur,
  hasError = false,
  fieldRef,
  size= "medium",
}: IProps) => {
  const [shrinkable, setShrinkable] = useState<boolean>(false);

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    const currentValue = normalizeValue(e.target.value);
    setShrinkable(currentValue !== "");
    onChange && onChange(currentValue);
  };

  const handleOnBlur = (event: React.ChangeEvent<any>) => {
    if (!event.target.value && !hasError) setShrinkable(false);
    onBlur && onBlur(event);
  };

  useEffect(() => {
    setShrinkable(hasError || Boolean(value && value !== ""));
  }, [hasError, onChange, value]);

  return (
    <CustomFormControl
      value={maskZipCode(value)}
      disabled={disabled}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={() => setShrinkable(true)}
      label={label}
      InputLabelProps={{ shrink: shrinkable }}
      InputProps={{ disableUnderline: true }}
      inputProps={{
        maxLength: 10,
        "aria-errormessage": hasError ? "rhfvalidationerror" : "",
      }}
      error={hasError}
      name={name}
      size={size}
      variant="outlined"
      inputRef={fieldRef}
    />
  );
};

export default TextInputZip;
