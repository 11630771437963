import { CompanyLocationState } from "entities/ApiModel/CompanyLocationState";
import { CompanyStates } from "entities/ApiModel/CompanyStates";
import { SessionStorageKeys } from "utils/data/enum";
import { StoreActionApi } from "react-sweet-state";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";

interface State {
  companyLocationStates: CompanyLocationState[];
  stateList: CompanyStates[];
  globalAccessLoaded: boolean;
  selectedAgency: CompanyLocationState[] | [];
  showChangeAgency: boolean;
}

type StoreApi = StoreActionApi<State>;

const setCompanyLocationStates =
  (companyLocationStates: CompanyLocationState[]) =>
  ({ setState }: StoreApi) => {
    setState({
      companyLocationStates,
      globalAccessLoaded: true,
      showChangeAgency: companyLocationStates?.length > 1,
    });
  };

const setStateList =
  (stateList: CompanyStates[]) =>
  ({ setState }: StoreApi) => {
    setState({ stateList, globalAccessLoaded: true });
  };
const setUserSelectedAgency =
  (selectedAgency: CompanyLocationState[]) =>
  ({ setState }: StoreApi) => {
    setState({ selectedAgency });
    sessionStorage.setItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY, JSON.stringify(selectedAgency));
  };

const getCompanyLocationStateListByUser =
  () =>
  async ({ dispatch }: StoreApi) => {
    try {
      const response = await axiosSecuredInstance.get("/user/GetCompanyLocationStateListByUser");
      const companyLocationStates = response.data;
      dispatch(setCompanyLocationStates(companyLocationStates));
    } catch (error) {
      console.error("Error fetching Company Location State List:", error);
    }
  };

const getStateListByUser =
  () =>
  async ({ dispatch }: StoreApi) => {
    try {
      const response = await axiosSecuredInstance.get("user/GetStateListByUser");
      const stateList = response.data;
      dispatch(setStateList(stateList));
    } catch (error) {
      console.error("Error fetching State List:", error);
    }
  };
const getSelectedAgency =
  () =>
  ({ getState }: StoreApi) => {
    return getState().selectedAgency;
  };

const setShowChangeAgency =
  (showOrHide: boolean) =>
  async ({ setState }: StoreApi) => {
    setState({ showChangeAgency: showOrHide });
  };

const clearUserSelectedAgency =
  () =>
  ({ setState }: StoreApi) => {
    setState({ selectedAgency: [] });
    sessionStorage.removeItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY);
  };

const actions = {
  getCompanyLocationStateListByUser,
  getStateListByUser,
  getSelectedAgency,
  setUserSelectedAgency,
  setShowChangeAgency,
  clearUserSelectedAgency,
};

const CompanyLocationStateStore = createStore<State, typeof actions>({
  initialState: {
    companyLocationStates: [],
    stateList: [],
    globalAccessLoaded: false,
    selectedAgency: JSON.parse(sessionStorage.getItem(SessionStorageKeys.GLOBAL_ACCESS_SELECTED_AGENCY) || "[]"),
    showChangeAgency: false,
  },
  actions,
  name: "companyLocationStateStore",
});

const hook = createHook(CompanyLocationStateStore);
export const useGlobalAccess = () => {
  return hook();
};

export default CompanyLocationStateStore;
