import React,
{
  useEffect,
  useState,
} from "react";
import {
  Box,
  styled,
} from "@material-ui/core";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { GeneralFileParty } from "entities/UIModel/GeneralFileParty";
import { useFieldArray } from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import { usePartyAdditionalPartyActions } from "utils/context/PartyAdditionalPartyContext";
import { useParty } from "utils/context/PartyContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getDefaultAdditionalPartyObject } from "utils/data/parties";
import { v4 as uuidv4 } from "uuid";
import AdditionalParty from "./AdditionalParty";
import useFocus from "utils/custom-hooks/useFocus";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const AdditionalPartiesSection = () => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [partyIndex, setPartyIndex] = useState<number>(0);
  const [deletePartyIndex, setDeletePartyIndex] = useState(-1);

  const { getValues, setValue, watch } = useFormWrapper();
  const [, { deleteParty }] = useParty();
  const [, { setAdditionalParties }] = usePartyAdditionalPartyActions();

  const { fields, append, remove } = useFieldArray({
    name: "additionalParties",
    keyName: "fieldId",
  });
  const watchedFields: GeneralFileParty[] = watch("additionalParties", fields);

  const isAddAdditionalPartyDisabled = useFieldDisabler(
    "AddAdditionalPartyButton"
  );
  const { setFocusInputElement } = useFocus();

  useEffect(() => {
    if (watchedFields.length > 0) {
      setPartyIndex(watchedFields.length - 1);
    } else {
      const defaultAdditionalPartyObject = getDefaultAdditionalPartyObject();
      append(defaultAdditionalPartyObject);

      // update PartyAdditionalParty context
      setAdditionalParties(getValues("additionalParties"));

    }
  }, [watchedFields, append]);

  const deleteAdditionalParty = (index: number) => {
    setOpenConfirmationDialog(true);
    setDeletePartyIndex(index);
  };

  const handleAddItem = (e: any) => {
    e.preventDefault();
    setPartyIndex(partyIndex + 1);

    let fileParty = getDefaultAdditionalPartyObject();
    let filePartyKey = uuidv4();
    fileParty.filePartyKey = filePartyKey;
    fileParty.integrationKey = filePartyKey;
    fileParty.isDefault = false;
    fileParty.isDirty = true;
    append(fileParty);

    //once you append a new party, the first is no longer default
    setValue(`additionalParties.0.isDefault`, false);
    setValue(`additionalParties.0.isDirty`, true);

    // set focus to last Additional Parties
    const newItemIndex = fields.length;
    setFocusInputElement(`additionalParties.${newItemIndex}.partyTypeCode`);
  };

  const handleYes = () => {
    if (deletePartyIndex !== -1) {
      const currentParty = watchedFields[deletePartyIndex];
      if (
        currentParty.filePartyId &&
        currentParty.filePartyId > 0 &&
        currentParty.integrationKey
      ) {
        const [fileNameNumber, agencyId] = getValues([
          "fileNameNumber",
          "agency.id",
        ]);
        deleteParty(currentParty.integrationKey, agencyId, fileNameNumber);
      }
      if (partyIndex >= 0) {
        remove(deletePartyIndex);
        setPartyIndex(partyIndex - 1);

        // update PartyAdditionalParty context
        setAdditionalParties(getValues("additionalParties"));
      }

      setDeletePartyIndex(-1);
    }
    setOpenConfirmationDialog(false);
  };

  const handleNo = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <StyledGroup>
        {watchedFields.map((field: any, index: number) => (
          <AdditionalParty
            key={field.fieldId}
            index={index}
            deleteParty={deleteAdditionalParty}
            additionalParty={field}
          />
        ))}

        <Box>
          <AddLink
            disabled={isAddAdditionalPartyDisabled}
            onClick={handleAddItem}
          >
            Add Additional Party
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialog
        confirmationMessage="Are you sure you want to delete this party?"
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleNo}
      />
    </>
  );
};
export default AdditionalPartiesSection;
