import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import SuccessorLanguage from "entities/UIModel/company/SuccessorLanguage";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type State = { successorLanguages: SelectFieldOption[] };
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const actions = {
  getSuccessorLanguages:
    () =>
    async ({ setState }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get<SuccessorLanguage[]>(
          `/Company/GetSuccessorLanguages`
        );

        const mappedData = data.map((successorLanguage) => ({
          value: successorLanguage.successorLanguage,
          text: successorLanguage.successorLanguage,
        }));

        setState({
          successorLanguages: mappedData,
        });
      } catch (error) {
        console.error("ERROR: CompanyContext.", error);
      }
    },
};

const Store = createStore<State, Actions>({
  initialState: { successorLanguages: [] },
  actions,
  name: "companySuccessorLanguages",
});

const hook = createHook(Store);
export const useSuccessorLanguages = () => { return hook() };
