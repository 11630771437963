import { styled } from "@material-ui/core";
import SideNav from "controls/global/side-nav";
import React, {
  useRef,
  useState,
} from "react";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import useTitle from "utils/custom-hooks/useTitle";
import ScrollToTopArrow from "../../controls/global/scroll-to-top-arrow/ScrollToTopArrow";
import ProfileSettingsForm from "./ProfileSettingsForm";

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
}

const Container = styled('div')((props) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: gaps.large1,
  position: "relative",
  [props.theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  "& > :first-child": {
    flexGrow: 0,
    flexShrink: 0,
  },
  "& > :last-child": {
    flex: "auto",
    overflowY: "auto",
    paddingBottom: padding.small1,
    paddingLeft: padding.small1,
    paddingRight: padding.small1,
  },
}));

const Content = styled('main')({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ProfileSettings = ({ saveFileRef, saveFileChangeRef }: Props) => {
  useTitle("Stewart Connect - Profile Settings");

  const [scrollToTop, setScrollToTop] = useState(false);
  const profileSettingsRef = useRef<HTMLElement>();
  const propertyDefaultsRef = useRef<HTMLElement>();
  const navItems = [
    { name: "Profile Settings", childRef: profileSettingsRef },
    { name: "Property Defaults", childRef: propertyDefaultsRef },
  ];

  const onScrollToTop = () => {
    setScrollToTop(!scrollToTop);
  };

  //When we get to the point where we need to reload components after the save
  //this functionality will do it.  Just add sastate={sastate} to the child coomponents
  //and use that in a useEffect to reload the data in that component.
  //const [sastate, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    //  return updateState({});
  }, []);

  return (
    <Container>
      <SideNav scrollToTop={scrollToTop} navItems={navItems} />
      <Content>
        <ProfileSettingsForm
          saveFileRef={saveFileRef}
          saveFileChangeRef={saveFileChangeRef}
          forceUpdate={forceUpdate}
          profileSettingsTitleRef={profileSettingsRef}
          propertyDefaultsTitleRef={propertyDefaultsRef}
        />
        <ScrollToTopArrow onScrollToTop={onScrollToTop} />
      </Content>
    </Container>
  );
};

export default ProfileSettings;
