import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type State = {
  isIssuedProductsCountUpdated: boolean;
  isPricingInProgress: boolean;
  isPricingRecalculateRequired: boolean;
  isPricingCalculated: boolean;
  pricingNotificationUpdated: boolean;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setIssuedProductsCountUpdated:
    (isIssuedProductsCountUpdated: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isIssuedProductsCountUpdated: isIssuedProductsCountUpdated });
    },
  setIsPricingInProgress:
    (isInProgress: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isPricingInProgress: isInProgress });
    },
  setIsPricingCalculated:
    (isCalculated: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isPricingCalculated: isCalculated });
    },
  setPricingNotificationUpdated:
    (isUpdated: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ pricingNotificationUpdated: isUpdated });
    },
  setPricingRecalculateRequired:
    (isUpdated: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isPricingRecalculateRequired: isUpdated });
    },  
};

const Store = createStore<State, Actions>({
  initialState: {
    isIssuedProductsCountUpdated: false,
    isPricingInProgress: false,
    isPricingRecalculateRequired: false,
    isPricingCalculated: false,
    pricingNotificationUpdated: false,
  },
  actions,
  name: "Pricing",
});

const getIssuedProductsCountUpdated = (state: State) => ({
  isIssuedProductsCountUpdated: state.isIssuedProductsCountUpdated,
});

const getIsPricingInProgress = (state: State) => ({
  isPricingInProgress: state.isPricingInProgress,
});

const getPricingStates = (state: State) => ({
  isPricingRecalculateRequired: state.isPricingRecalculateRequired,
  isPricingCalculated: state.isPricingCalculated,
  pricingNotificationUpdated: state.pricingNotificationUpdated,
})

const useIssuedProductsCountUpdated = createHook(Store, {
  selector: getIssuedProductsCountUpdated,
})

const usePricingInProgress = createHook(Store, {
  selector: getIsPricingInProgress,
})

const usePricingStates = createHook(Store, {
  selector: getPricingStates,
})

const usePricingActions = createHook(Store, {
  selector: null,
})

export {usePricingActions, usePricingStates, usePricingInProgress, useIssuedProductsCountUpdated};


