import React from "react";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import ErrorMessage from "../error-message";
import { SelectFieldOption } from "../select-field/SelectInput";
import ComboBoxInput from "./ComboBoxInput";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

interface Props {
  label: string;
  name: any;
  defaultValue?: any;
  placeholder?: string;
  options: SelectFieldOption[];
  freeSolo?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  errorClass?: string;
  onBlur?: (_: React.ChangeEvent<{}>) => void;
  onChange?: (_: React.ChangeEvent<{}>, value: SelectFieldOption) => void;
  roundedCorner?: boolean;
  size?: "small" | "medium";
  noBorder?: boolean;//.MuiOutlinedInput-notchedOutline
  controlTooltipOnMouseEvents?: boolean;
}

// Notes: the ComboBoxInputField form field must be an object with {id:,value:} OR {code:,value:}
const getValue = (value: any) =>
  typeof value === "object" ? value?.id || value?.code : value;

const ComboBoxInputField = (props: Props) => {
  const {
    errorClass,
    name,
    defaultValue,
    onBlur,
    onChange,
    roundedCorner,
    disabled,
    noBorder = false,
    controlTooltipOnMouseEvents = false,
    ...rest
  } = props;
  const { control, setValue, getValues } = useFormContext();

  const handleOnChange = (e: any, selectedOption: SelectFieldOption) => {
    const currentValue = getValues(name);
    const newValue = selectedOption.value;

    if (currentValue?.name !== newValue) {
      setValue(name, newValue);
    }

    onChange && onChange(e, selectedOption);
  };

  const handleOnBlur = (e: any) => {
    const currentValue = getValues(name);
    const newValue = e.target.value;

    if (currentValue?.name !== newValue) {
      setValue(name, newValue);
    }    
    onBlur && onBlur(e);
  };
  const shouldFieldBeDisabled = useFieldDisabler(name);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { name, value }, fieldState: { error } }) => {
          return (
            <ComboBoxInput
              name={name}
              value={getValue(value)}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              disabled={disabled || shouldFieldBeDisabled}
              roundedCorner={roundedCorner}
              hasError={Boolean(error)}
              size={props.size}
              noBorder={noBorder}
              controlTooltipOnMouseEvents={controlTooltipOnMouseEvents}
              {...rest}
            />
          );
        }}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default ComboBoxInputField;
