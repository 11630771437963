import {
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationType } from "utils/data/enum";
import React from "react";

const useStyles = makeStyles({
  error: {
    color: colors.red01,
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    marginLeft: margin.small2,
  },
  info: {
    color: colors.grey13,
    marginLeft: margin.small2,
    fontWeight: fontWeight.normal1,
  },
});

type Props = {
  message: string | undefined;
  notificationType: NotificationType;
  show: boolean;
};

export default function PricingTableHeaderNotification({
  message,
  notificationType,
  show,
}: Props) {
  const classes = useStyles();

  if (!show) return null;

  return (
    <label
      className={
        notificationType === NotificationType.Error
          ? classes.error
          : classes.info
      }
    >
      {message}
    </label>
  );
}
