import { Box } from "@material-ui/core";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import { GeneralFileParty } from "entities/UIModel";
import { getAdditionalPartyDescription } from "pages/file/utils/parties/additionalparties";
import React from "react";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import AdditionalPartyGrid from "./AdditionalPartyGrid";

interface IAdditionalPartyProps {
  index: number;
  additionalParty: GeneralFileParty;
  deleteParty?: (index: number) => void;
}

const AdditionalParty = ({
  index,
  additionalParty,
  deleteParty,
}: IAdditionalPartyProps) => {
  const { register } = useFormWrapper();

  const isFileDisabled = useFieldDisabler("BuyerBorrowerClearButton");

  const tabTitle = `Additional Party ${index + 1}`;
  const integrationKeyField = `additionalParties.${index}.integrationKey`;
  const filePartyKeyField = `additionalParties.${index}.filePartyKey`;
  const partyRoleTypeCodeField = `additionalParties.${index}.partyRoleTypeCode`;

  const handleDeleteParty = () => {
    deleteParty && deleteParty(index);
  };

  return (
    <Box key={`additional-party-${index}`}>
      <CollapsableFormSection
        title={tabTitle}
        index={index}
        label={getAdditionalPartyDescription(additionalParty)}
        showDelete={
          (index > 0 || Boolean(additionalParty?.partyTypeCode)) &&
          !isFileDisabled
        }
        showDivider={index > 0 || Boolean(additionalParty?.partyTypeCode)}
        onDelete={handleDeleteParty}
      >
        <>
          <AdditionalPartyGrid index={index} />
          <input
            type="hidden"
            {...register(`${integrationKeyField}` as const)}
          />
          <input type="hidden" {...register(`${filePartyKeyField}` as const)} />
          <input
            type="hidden"
            {...register(`${partyRoleTypeCodeField}` as const)}
          />
        </>
      </CollapsableFormSection>
    </Box>
  );
};

export default AdditionalParty;
