import { createHook, createStore, StoreActionApi } from 'react-sweet-state';
import { AxiosRequestConfig } from 'axios';
import { SelectFieldOption } from 'controls/global/select-field/SelectInput';
import { axiosSecuredInstance } from 'configurations/axiosConfig';

type StoreApi = StoreActionApi<State>;

interface State {
  error: string | null;
  agencyStates: Array<SelectFieldOption>;
  isRetrievingStates: boolean;
}

const initialState: State = {
  error: null,
  agencyStates: [],
  isRetrievingStates: false,
}

const setError = (error: string | null) => ({setState}: StoreApi) => {
  setState({error});
};

const hasAny = (data: Array<any>) => !data || data.length <= 0;

const setAgencyStates = (data: Array<any>, isUpdate: boolean = false) =>
  ({setState}: StoreApi) => {
    let items: any[] = [];
    if (!hasAny(data)) {
      items = data.map((state) => ({
        text: state.stateAbbr,
        value: state.stateCode,
      }));
      items.sort((a, b) => a.text.localeCompare(b.text));
    }
    setState({
      agencyStates: items,
      isRetrievingStates: isUpdate,
    });
  };

const getData = (
  url: string,
  action: (param: Array<any>) => ({setState}: StoreApi) => any,
  config?: AxiosRequestConfig) =>
  async ({dispatch}: StoreApi) => {
    try {
      const {data} = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const Store = createStore<State, any>({
  initialState: { ...initialState },
  actions: {
    updateAgencyStates:
      (agencyStatesUpdated: Array<any>) =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isRetrievingStates: true });
        dispatch(setAgencyStates(agencyStatesUpdated, true));
        setState({ isRetrievingStates: false });
      },
    getAgencyStates:
      (agencyId?: string, locationDisplayName?: string) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        let url = "";
        if (agencyId) {
          url = `/company/GetStatesByCompanyIDLocation?companyID=${agencyId}`;
          if (locationDisplayName)
            url = url.concat(
              `&locationDisplayName=${encodeURIComponent(
                locationDisplayName || ""
              )}`
            );
          if (!getState().isRetrievingStates) {
            setState({ isRetrievingStates: true });
            await dispatch(getData(url, setAgencyStates));
          }
        } else {
          setState({
            agencyStates: [],
            isRetrievingStates: false,
          });
        }
        //else url = "/lookup/getstatelist?countryCodeNum=840";
      },
  },
  name: "agencyStates",
});


const hook = createHook(Store);
export const useAgencyStates = () => {
  return hook()
};
