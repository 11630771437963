import React from "react";
import { Grid } from "@material-ui/core";

import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import Field from "./Field";

type Props = {
  schema: string;
  isDisabledAddress?: boolean;
  onChange?: any;
  onBlur?:() => void;
};

function ForeignAddress({ schema, isDisabledAddress = false, onChange, onBlur}: Props) {
  const { setValue, nameString } = useFormWrapper();

  const stateFieldName = nameString(`${schema}.foreignState`);
  const cityFieldName = nameString(`${schema}.city`);
  const countryFieldName = nameString(`${schema}.foreignCountry`);
  const addressOneFieldName = nameString(`${schema}.addressOne`);
  const addressTwoFieldName = nameString(`${schema}.addressTwo`);

  const setNotDefault = () => setValue(`${schema}.isDefault`, false);

  const getFieldName = (targetName: string) => {
    let fieldName = targetName.split(".");
    if (fieldName && fieldName.length > 0)
      return fieldName[fieldName.length - 1];
    return targetName;
  };

  const onBlurHandler = (e: any) => {
    if (e && e.target) {
      const fieldName = getFieldName(e.target.name);
      onChange && onChange(undefined, fieldName, e.target.value);
      onBlur && onBlur();
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    setNotDefault();
    onChange && onChange();
  }

  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <Field
          name={addressOneFieldName}
          label="Address Line 1"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Field
          name={addressTwoFieldName}
          label="Address Line 2"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={cityFieldName}
              label="City"
              isDisabled={isDisabledAddress}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={stateFieldName}
              label="State"
              isDisabled={isDisabledAddress}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={countryFieldName}
              label="Country"
              isDisabled={isDisabledAddress}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default ForeignAddress;
