import { axiosSecuredInstance } from "configurations/axiosConfig";
import { AutocompleteRequest, AutocompleteResponse } from "entities/UIModel";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";

interface State {
  isAutocompleteLoading: boolean;
  error: string | null;
}
type StoreApi = StoreActionApi<State>;

const setAutocompleteLoading =
  () =>
  ({ setState }: StoreApi) => {
    setState({ isAutocompleteLoading: true });
  };

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({
      isAutocompleteLoading: false,
      error,
    });
  };

const getSuggestions =
  (data: any) =>
  ({ setState }: StoreApi) => {
    if (!data || data.length === 0) {
      setState({ isAutocompleteLoading: false });
      return [];
    }
    let suggestions = Array<AutocompleteResponse>(0);
    data.forEach((s: any) =>
      suggestions.push({
        Text: s.Text,
        Street: s.Street,
        Secondary: s.Secondary,
        City: s.City,
        State: s.State,
        ZipCode: s.ZipCode,
        Selected: s.Selected,
      })
    );

    setState({ isAutocompleteLoading: false });
    return suggestions;
  };

const Store = createStore<State, any>({
  initialState: {
    isAutocompleteLoading: false,
    error: null,
  },
  actions: {
    autocomplete:
      (autocompleteRequest: AutocompleteRequest) =>
      async ({ dispatch }: StoreApi) => {
        try {
          if (autocompleteRequest.search === "") return [];

          dispatch(setAutocompleteLoading());

          if (autocompleteRequest?.search) {
            autocompleteRequest.search = encodeURIComponent(
              autocompleteRequest.search
            );
          }

          if (autocompleteRequest?.selected) {
            autocompleteRequest.selected = encodeURIComponent(
              autocompleteRequest.selected
            );
          }

          const { data } = await axiosSecuredInstance.get(
            "/smartystreets/autocomplete",
            {
              params: autocompleteRequest,
            }
          );

          return dispatch(getSuggestions(data));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
  },
  name: "smartystreets",
});

const hook = createHook(Store, {
  selector: null,
});
export const useSmartyStreets = () => {
  return hook();
};
