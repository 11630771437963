import Button from "@material-ui/core/Button";
import { SCFile } from "entities/UIModel";
import { runValidation } from "pages/file/utils/yup/validator";
import React from "react";
import { useFiles } from "utils/context/FilesContext";
import { usePricingInProgress } from "utils/context/PricingContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { MapActionType, UIConstants } from "utils/data/enum";

type Props = {
  innerRef: React.MutableRefObject<HTMLButtonElement>;
  onSave: (values: SCFile, cleanFormValues: boolean) => Promise<boolean>;
};

export default function SaveFile({ innerRef, onSave }: Props) {
  const isPricingInProgressRef = React.useRef<boolean>(false);

  const { clearErrors, getValues, trigger, setValue } = useFormWrapper();

  const [{isPricingInProgress}] = usePricingInProgress();
  const [
    ,
    { resetInvokingUrl },
  ] = useFiles();

  // rlo 5/20/2022 - isPricingInProgressRef is required to be used within setInterval, because isPricingInProgress is not updated within setInterval
  React.useEffect(() => {
    isPricingInProgressRef.current = isPricingInProgress; 
  }, [isPricingInProgress]);
  
  const handleClick = () => {
    // Wait until Pricing is progress in complete.
    const timerStart = Date.now();
    const interval = setInterval(() => {
      const ellapsedMiliseconds = Date.now() - timerStart;
      if (!isPricingInProgressRef.current) {
        submitSaveFile();
        clearInterval(interval);
        return;
      }

      if (
        ellapsedMiliseconds >
        UIConstants.WAITING_PROCESS_IN_PROGRESS_MAX_MILISECONDS
      ) {
        clearInterval(interval);
      }
    }, UIConstants.CHECK_PROCESS_IN_PROGRESS_INTERVAL_MILISECONDS);
  };

  const submitSaveFile = async () => {
    clearErrors();
    const values: SCFile = getValues();
    const isFormValid = await runValidation({
      values,
      trigger,
      setValue,
      productAction: MapActionType.SaveFile,
    });

    if (!isFormValid) return;
    resetInvokingUrl();
    onSave(values, false);
  };

  return <Button innerRef={innerRef} hidden onClick={handleClick} />;
}
