import React from "react";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { useCompanyForm } from "utils/context/CompanyFormContext";
import { JacketFormType, ProductType } from "utils/data/enum";
import useFormWrapper from "./useFormWrapper";
import { isEqual } from "lodash";
import useEffectiveDateValidation from "./useEffectiveDateValidation";
import { isValidDate } from "pages/file/utils/helper";

export interface SelectFieldOptionForms extends SelectFieldOption {
  formID: string;
  formVersion: number | string;
  formTemplateName: string;
  formPrefix: string;
  formType: string;
  agencyExclusive: string;
  liabilityAmount: number | null;
}

export default function useForms(
  productType: ProductType,
  formType: JacketFormType | string,
  defaultDate: Date | undefined,
  isFieldDisabled: boolean | undefined,
) {
  const [options, setOptions] = React.useState<SelectFieldOptionForms[]>([]);
  const optionsRef = React.useRef<SelectFieldOptionForms[]>([]);

  const [, { getForms }] = useCompanyForm();
  const { validateEffectiveDate } = useEffectiveDateValidation();
  const { getValues } = useFormWrapper();
  const [companyId, stateId, isReadOnly] = getValues([
    "agency.id",
    "properties.0.state.abbr",
    "isReadOnly"
  ]);

  React.useEffect(() => {
    async function getFormsAsync() {

      let product = "";
      switch (productType) {
        case ProductType.Aal:
          product = "aALProducts";
          break;
        case ProductType.Cpl:
          product = "cpls";
          break;
        case ProductType.Jacket:
          product = "jackets";
          break;
      }

      const validationMessage = await validateEffectiveDate(product, undefined, defaultDate, false, false);
        if (validationMessage && !isReadOnly) {
            setOptions([]);
            return;
        }

      const companyForms = await getForms(
        companyId,
        stateId,
        productType,
        formType,
        defaultDate
      );

      if (!companyForms || companyForms.length === 0 ) {
        setOptions([]);
        return;
      }

      const options = companyForms.map((form) => {
        const option: SelectFieldOptionForms = {
          text: form.formName,
          value: form.formName,
          formID: form.formID,
          formVersion: form.formVersion,
          formTemplateName: form.formTemplateName,
          formPrefix: form.formPrefix,
          formType: form.formType,
          opaIdentifier: form.opaIdentifier,
          agencyExclusive: form.agencyExclusive,
          liabilityAmount: form.liabilityAmount,
          selected: form.default === 1,
        };

        return option;
      });
      setOptions(options);
    }

    if (!companyId || !stateId || !formType || !defaultDate || !isValidDate(defaultDate) || isFieldDisabled) {
       setOptions([]);
       return;
    }
    getFormsAsync();

  }, [companyId, stateId, formType, productType, defaultDate, isFieldDisabled, getForms]);

  if (!isEqual(optionsRef.current, options)) {
    optionsRef.current = options;
  }

  return { options: optionsRef.current };
}
