import { GeneralFileParty } from "entities/UIModel/GeneralFileParty";
import { PartyRoleType, PartyType } from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

export function getDefaultBuyerBorrowerObject() {
  const iKey: string = uuidv4();
  return {
    isDefault: true,
    isDirty: false,
    partyRoleTypeCode: PartyRoleType.BuyerBorrower,
    partyTypeCode: PartyType.Individual,
    addressOne: "",
    addressTwo: "",
    city: "",
    state: { code: "", abbr: "" },
    zipCode: "",
    suffixType: { code: "", name: "" },
    isExcludedOnCPL: false,
    isExcludedOnAAL: false,
    partyFirstName: "",
    partyMiddleName: "",
    partyLastName: "",
    companyName: "",
    integrationKey: iKey,
    filePartyKey: iKey,
    selectedAddressee: [],
    isForeignAddress: 0,
    foreignCountry: "",
    foreignState: "",
  };
}

export function getDefaultSellerObject() {
  const iKey: string = uuidv4();
  return {
    isDefault: true,
    isDirty: false,
    partyRoleTypeCode: PartyRoleType.Seller,
    partyTypeCode: PartyType.Individual,
    addressOne: "",
    addressTwo: "",
    city: "",
    state: { code: "", abbr: "" },
    zipCode: "",
    suffixType: { code: "", name: "" },
    isExcludedOnCPL: false,
    isExcludedOnAAL: false,
    partyFirstName: "",
    partyMiddleName: "",
    partyLastName: "",
    companyName: "",
    integrationKey: iKey,
    filePartyKey: iKey,
    selectedAddressee: [],
    isForeignAddress: 0,
    foreignCountry: "",
    foreignState: "",
    foreignAddressOne: "",
    foreignAddressTwo: "",
    foreignCity: "",
  };
}

export function getDefaultLenderObject() {
  const iKey: string = uuidv4();
  return {
    isDefault: true,
    isDirty: false,
    partyRoleTypeCode: PartyRoleType.Lender,
    partyTypeCode: PartyType.BusTrustEstate,
    addressOne: "",
    addressTwo: "",
    city: "",
    state: { code: "", abbr: "", required: false },
    zipCode: "",
    suffixType: { code: "", name: "" },
    isExcludedOnCPL: false,
    isExcludedOnAAL: false,
    partyFirstName: "",
    partyMiddleName: "",
    partyLastName: "",
    companyName: "",
    individualAttorney: "",
    saveToContacts: false,
    favorite: false,
    loanNumber: "",
    successorLanguage: "",
    attentionTo: "",
    integrationKey: iKey,
    filePartyKey: iKey,
    selectedAddressee: [],
  };
}

export function getDefaultAdditionalPartyObject(): GeneralFileParty {
  const iKey: string = uuidv4();
  return {
    isDefault: true,
    isDirty: false,
    addressOne: "",
    addressTwo: "",
    city: "",
    companyName: "",
    integrationKey: iKey,
    filePartyKey: iKey,
    individualAttorney: "",
    isExcludedOnCPL: false,
    isExcludedOnAAL: false,
    partyFirstName: "",
    partyLastName: "",
    partyMiddleName: "",
    partyRoleTypeCode: PartyRoleType.AdditionalParty,
    partyTypeCode: "",
    state: { code: "", abbr: "" },
    suffixType: { code: "", name: "" },
    zipCode: "",
    selectedAdditionalPartyID: "",
    favorite: false,
  };
}
