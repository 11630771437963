import Radio from "controls/global/radio-button/Radio";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { fontSize } from "theme/defaultStyle";
import { RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
  radio: {
    marginBottom: 0,
    "& .MuiTypography-root": {
      fontSize: fontSize.large
    },
  },
});

type Props = {
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

export default function RadioGroupField({
  defaultValue = "N",
  disabled,
  onChange,
}: Props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string>(defaultValue);

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <RadioGroup row value={selected} onChange={handleChange}>
      <FormControlLabel
        label="Yes"
        value="Y"
        disabled={disabled}
        control={<Radio />}
        className={classes.radio}
      />
      <FormControlLabel
        label="No"
        value="N"
        disabled={disabled}
        control={<Radio />}
        className={classes.radio}
      />
    </RadioGroup>
  );
}
