import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";

const useStyles = makeStyles({
  errorMessage: {
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    color: colors.red01,
    paddingTop: padding.large1,
  },
});

function ErrorMessage() {
  const classes = useStyles();

  return (
    <Box className={classes.errorMessage}>
      <ReportProblemIcon
        style={{ fontSize: 20, color: colors.red01, marginRight: margin.small }}
      />
      Please correct the error(s) below to continue.
    </Box>
  );
}

export default ErrorMessage;
