import React, {
    useRef,
    useState,
} from "react";
import {
    Box,
    IconButton,
    styled,
    TextField,
} from "@material-ui/core";
import {
    fontSize,
    margin,
    padding,
} from "theme/defaultStyle";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    name: keyof PendingPayCriteriaSearch;
    filterValue?: any;
    onChange: (name: keyof PendingPayCriteriaSearch, value: string | null) => void;
    inputType?: string;
    label?: string;
}

const StyledInputSearchBox = styled(Box)({
    height: "42px",
    left: padding.small2,
    marginBottom: margin.medium,
    paddingRight: padding.large1,
    position: "relative",
});

const StyledInputSearch = styled(TextField)({
    "& .MuiFormLabel-root": {
        fontSize: fontSize.large,
    },
    "& button.MuiIconButton-root": {
        padding: padding.zero,
    }
});

const InputSearchPendingpay = ({ name, filterValue, onChange, inputType = "text", label = "Search" }: Props) => {
    const [value, setValue] = useState<string>("");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const clearInput = () => {
        setValue("");
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
            const event = new Event("input", { bubbles: true });
            Object.defineProperty(event, "target", {
                value: searchInputRef.current,
                enumerable: true,
            });
            searchInputRef.current.dispatchEvent(event);
            const changeEvent = {
                target: searchInputRef.current,
            } as React.ChangeEvent<HTMLInputElement>;
            handleInputChange(changeEvent);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as keyof PendingPayCriteriaSearch;
        const currentValue = event.target.value.trim() ? event.target.value.trim() : null;
        if (value !== event.target.value.trim()) {
            onChange(name, currentValue);
        }
        setValue(currentValue || "");
    };

    return (
        <StyledInputSearchBox>
            <StyledInputSearch
                {...{ name }}
                id={name}
                onChange={handleInputChange}
                fullWidth
                label={label}
                defaultValue={filterValue || ""}
                inputRef={searchInputRef}
                InputProps={{
                    endAdornment: !!value && (
                        <IconButton onClick={clearInput}>
                            <CloseIcon />
                        </IconButton>
                    ),
                }}
                inputProps={{ type: inputType }}
                InputLabelProps={{
                    shrink: !!value
                }}
            />
        </StyledInputSearchBox>
    );
};

export default InputSearchPendingpay;
