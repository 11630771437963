import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { colors } from "theme/defaultStyle";
import { ProductType } from "utils/data/enum";

type Props = {
  productType: string;
};

const useStyles = makeStyles({
  infoBox: {
    color: colors.grey13,
    marginTop: "0px",
    marginBottom: "20px",
  },
  info: {
    fontSize: "16px !important",
    fontWeight: 400,
  },
});

const LetterSectionHeader = ({ productType }: Props) => {
  const classes = useStyles();
  return (
    <>
      {productType === ProductType.Cpl && (
        <Box className={classes.infoBox}>
          <span>
            <Typography className={classes.info}>
              Edits made below will not update in the Parties section.
            </Typography>
          </span>
        </Box>
      )}

      {productType === ProductType.Aal && (
        <Box className={classes.infoBox}>
          <span>
            <Typography className={classes.info}>
              Should you have any questions, please contact us at{" "}
              <a href="mailto:NYAgentLetter@Stewart.com">
                NYAgentLetter@Stewart.com
              </a>{" "}
              or (212) 922-0050.
            </Typography>
            <Typography className={classes.info}>
              Closing Protection Letters are not permitted in New York State per
              NY Insurance Dept. Letter 18.
            </Typography>
          </span>
        </Box>
      )}
    </>
  );
};

export default LetterSectionHeader;
