import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import SelectField from "controls/global/select-field/SelectField";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import TextInputField from "controls/global/text-input-field";
import { useFieldArray } from "react-hook-form";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import {
  Grid, 
  IconButton,
  styled,
  } from "@material-ui/core";
import { 
  colors, 
  margin,
 } from "theme/defaultStyle";

type Props = {
  index: number;
  key: any;
  control: any;
};

const StyledIconButton = styled(IconButton)({
  "&&.disabled": {
    opacity: "0.5",
  },
  marginLeft: margin.medium2,
});

const StyledDeleteIcon = styled(DeleteIcon)({
  height: "32px",
  width: "32px",
  color: colors.blue01,
});

const HELP_MESSAGE =
  "This email will be used to log into Stewart Connect and any login related communication will be sent to this email address.";

const UserInputGrid: React.FC<Props> = ({ index, control }: Props) => {
  const { remove } = useFieldArray({ name: "users" });
  const [{ profileSettings }] = useProfileSettingsCache();

  const isFirstRow = index === 0;

  const getAllTimeZones = () => {
    return profileSettings.timeZones.map((timeZone: any, index: number) => {
      return {
        text: timeZone.Display_Name,
        value: timeZone.Name,
        selected: timeZone.Name === profileSettings.userTimeZone,
      };
    });
  };

  const StyledToolTip = styled(CustomTooltip)({
    marginLeft: margin.xlarge,
    marginTop: margin.medium2,
  });

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={2}
      >
        <TextInputField
          name={`users[${index}].firstName`}
          placeholder="First Name"
          label="First Name"
          style={{ position: "relative" }}
          inputProps={{
            maxLength: 50,
            minLength: 1,
          }}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <TextInputField
          name={`users[${index}].lastName`}
          placeholder="Last Name"
          label="Last Name"
          className="registerForm"
          style={{ position: "relative" }}
          inputProps={{
            maxLength: 100,
            minLength: 1,
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <TextInputField
          name={`users[${index}].email`}
          placeholder="Email"
          label="Email"
          style={{ position: "relative" }}
          inputProps={{
            maxLength: 254,
            minLength: 1,
          }}
        />
      </Grid>
      <Grid
        item
        xs={1}
      >
        <StyledToolTip
          title={HELP_MESSAGE}
          aria-label="info"
          arrow
        >
          <StyledInfoIcon />
        </StyledToolTip>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <SelectField
          name={`users[${index}].timeZone`}
          options={getAllTimeZones()}
          label="Time Zone"
          errorClassName="styledErrorMessage"
        />
      </Grid>
      <Grid
        item
        xs={1}
      >
        <StyledIconButton
          disabled={isFirstRow}
          className={isFirstRow ? "disabled" : ""}
          aria-label="delete"
          onClick={() => remove(index)}
        >
          <StyledDeleteIcon />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};

export default UserInputGrid;
