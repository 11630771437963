import { makeStyles } from "@material-ui/core";
import DatePickerField from "controls/global/datepicker";
import React from "react";
import { useFormState } from "react-hook-form";
import { colors } from "theme/defaultStyle";
import Tooltip from "../ErrorTooltip";
import { getIn } from "utils/object";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  pathToValidate: string;
  fullName: string;
  disabled: boolean;
};


const useStyles = makeStyles({
  effectiveDate: {
    "& .MuiInputBase-root.MuiInput-root": {
      background: "white",
      border: "none",
      "&:hover": {
        border: "none",
      },
      "& input.date-picker-inline-custom": {
        background: "none",
      },
    },
    "& .MuiInputBase-root.MuiInput-root.Mui-error": {
      border: `1px solid ${colors.red02} !important`,
      "&:hover": {
        border: "none",
      },
    },
    "& .MuiInputBase-root.MuiInput-root.Mui-disabled": {
      background: "none",
      border: "none",
      cursor: "not-allowed",
      "& input.date-picker-inline-custom": {
        cursor: "not-allowed",
      },
    },
    "& .MuiInputBase-root.MuiInput-root input[aria-invalid=true]": {
      color: colors.red02,
    },
    "& input.MuiInputBase-inputAdornedEnd": {
      paddingRight: "0px !important",
    },
    "& div.MuiInputAdornment-positionEnd": {
      marginLeft: "0px !important",
    },
  },
  effectiveDateErrorMessageContent: {
    color: colors.red02,
    backgroundColor: colors.white,
    padding: 3,
    fontSize: 11,
    margin: 0,
  },
});

export default function EndorsementEffectiveDateCell({
  pathToValidate,
  fullName,
  disabled,
}: Props) {
  const [effectiveDateFocused, setEffectiveDateFocused] = React.useState(false);

  const classes = useStyles({"disabled": disabled});
  const formState = useFormState();
  const { trigger } = useFormWrapper();

  const errorMessage = getIn(formState.errors, fullName)?.message || "";
  const hasEffectiveDateError =
    effectiveDateFocused && errorMessage !== "Required" && errorMessage !== "";

  return (
    <Tooltip
      content={
        <div
          className={classes.effectiveDateErrorMessageContent}
          dangerouslySetInnerHTML={{
            __html: errorMessage,
          }}
        />
      }
      arrow={true}
      placement="left"
      open={hasEffectiveDateError}
    >
      <div
        className={classes.effectiveDate}
      >
        <DatePickerField
          label=""
          name={fullName}
          onFocusCallback={() => setEffectiveDateFocused(true)}
          onBlurCallback={() => {
            if (hasEffectiveDateError) trigger(pathToValidate);
            return setEffectiveDateFocused(false);
          }}
          size="small"
          noBorder={true}
          disabled={disabled}
          showError={false}
          validateOnBlur={false}
        />
      </div>
    </Tooltip>
  );
}
