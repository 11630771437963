import React from "react";
import { Grid } from "@material-ui/core";

import ForeignAddressCheckbox from "controls/global/foreign-address/ForeignAddressCheckbox";
import TogglePropertyAddress from "./TogglePropertyAddress";

type NewProps = {
  schema: string;
  showPropertyCheckbox: boolean;
  showForeignAddressCheckbox: boolean;
  callbackIndex?: number;
  isDisabledAddress?: boolean;
  onBlurHandler?: any;
  onForeignAddressChange?: any;
};

function AddressCheckboxes({
  schema,
  showPropertyCheckbox,
  showForeignAddressCheckbox,
  callbackIndex,
  isDisabledAddress = false,
  onForeignAddressChange,
  onBlurHandler,
}: NewProps) {
  return (
    <Grid container>
      {showPropertyCheckbox && (
        <TogglePropertyAddress
          schema={schema}
          callbackIndex={callbackIndex}
          onBlur={onBlurHandler}
          disabled={isDisabledAddress}
        />
      )}
      {showForeignAddressCheckbox && (
        <ForeignAddressCheckbox
          disabled={isDisabledAddress}
          schema={schema}
          onForeignAddressChange={onForeignAddressChange}
          onBlur={onBlurHandler}
        />
      )}
    </Grid>
  );
}

export default AddressCheckboxes;
