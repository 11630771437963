import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import getCroppedImgV2, { createImage } from "utils/signature/cropping";
import ZoomImage from "./ZoomImage";
import ActionButton from "../action-button/ActionButton";
import { IMG_MAX_HEIGHT, IMG_MAX_WIDTH } from "utils/signature/imageFile";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import DialogTitleCloseButton from "../dialog-close-btn/DialogTitleCloseButton";

type Props = {
  open: boolean;
  imageUrl: string;
  onCrop: (image: string) => void;
  onCancel: () => void;
};

const initialValues = {
  STEP: 0.2,
  ZOOM: 1,
  CROP: { x: 0, y: 0 },
  ASPECT: 3 / 1,
};

export default function CropImage({ open, imageUrl, onCrop, onCancel }: Props) {
  const classes = useDialogStyles({ type: "INFO" });
  const [crop, setCrop] = useState<Point>(initialValues.CROP);
  const [zoom, setZoom] = useState<number>(initialValues.ZOOM);
  const [croppedAreaPixels, setCropperAreaPixels] = useState<Area>();
  const [aspect, setAspect] = useState<number>(initialValues.ASPECT);

  const handleCropCompleted = (_: Area, croppedAreaPixels: Area) =>
    setCropperAreaPixels(croppedAreaPixels);

  const handleZoomChange = (zoom: number) => setZoom(zoom);

  const handleCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImgV2(imageUrl, croppedAreaPixels);
      if (!croppedImage) throw new Error(`Unable to crop your image`);

      onCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setCrop(initialValues.CROP);
    setZoom(initialValues.ZOOM);
    setAspect(initialValues.ASPECT);
  }, [imageUrl]);

  useEffect(() => {
    if (!imageUrl) return;
    const getAspect = async () => {
      try {
        const { width, height } = await createImage(imageUrl);
        if (width <= IMG_MAX_WIDTH && height <= IMG_MAX_HEIGHT) {
          setAspect(width / height);
        }
      } catch (error) {
        console.error("There was an error loading the selected image. ", error);
      }
    };

    getAspect();
  }, [imageUrl]);

  return (
    <Box>
      <Dialog open={open} maxWidth="md" classes={{ root: classes.dialogRoot }}>
        <DialogTitle
          disableTypography
          classes={{ root: classes.dialogTitleRoot }}
        >
          Image Upload
          <DialogTitleCloseButton onClick={onCancel}/>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Box position="relative" width={500} height={300}>
            <Cropper
              showGrid={false}
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              restrictPosition={false}
              onCropChange={setCrop}
              onZoomChange={(zoom: number) =>
                setZoom(zoom - initialValues.STEP)
              }
              onCropComplete={handleCropCompleted}
              zoomWithScroll={false}
            />
          </Box>
          <ZoomImage
            value={zoom}
            onZoom={handleZoomChange}
            step={initialValues.STEP}
            min={initialValues.STEP} // 0.2
            max={initialValues.STEP * 9} // 1.8
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionRoot }}>
          <ActionButton
            color="primary"
            variant="outlined"
            onClick={handleCroppedImage}

          >
            Ok
          </ActionButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
