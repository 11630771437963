import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CustomTooltip from "controls/global/custom-tooltip";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  padding
} from "theme/defaultStyle";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import {
  JacketFormType,
  PricingType,
  ProductType,
} from "utils/data/enum";

const StyledTableBodyCell = styled(TableCell)({
  fontWeight: fontWeight.normal1,
  fontSize: fontSize.large,
  padding: padding.zero,
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  "&:first-child": {
    paddingLeft: padding.zero,
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const useStyles = makeStyles({
  editBox: {
    color: colors.blue01,
    "& .fa.fa-edit": {
      fontSize: "18pt",
    },
  },
});

type Props = {
  index: number;
  schema: string;
  isOverride: boolean;
  isFileLocked: boolean;
  isPricingUpdateable: boolean; // for future used if needed
  handleReissueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleIsReissueClick: (index: number) => void;
};

export default function PricingProductReissueCell({
  index,
  schema,
  isOverride,
  isFileLocked,
  handleReissueChange,
  handleIsReissueClick,
}: Props) {
  const classes = useStyles();
  const { getValues } = useFormWrapper();
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");

  const ToolTip = () => {
    return (
      <CustomTooltip
        title="Please enter original policy information."
        placement="top"
        aria-label="lender-contact-info"
        arrow
      >
        <Icon className="fa fa-edit" fontSize="small" />
      </CustomTooltip>
    );
  };

  const showReissueCheckbox = getValues("pricing.showReissueCheckbox"); // used to turn off because by default it's on
  const showReissueEdit = getValues("pricing.showReissueEdit"); // used to turn off because by default it's on

  const disableReissueCheckbox = () => {
    return (isIntegratedPricing && Boolean(isOverride)) || isFileLocked;
  };
  const disableReissueEdit = () => {
    const isReIssueChecked = getValues(getNameString(`pricingProducts.${index}.isReissue`));
    return (isIntegratedPricing && Boolean(isOverride)) ||
      isFileLocked ||
      (showReissueCheckbox && !isReIssueChecked);
  };

  const renderReissueCheckBox = () => {
    if (showReissueCheckbox) {
      return (
        <CheckboxInputField
          disabled={disableReissueCheckbox()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleReissueChange(event, index);
          }}
          name={`pricingProducts.${index}.isReissue`}
        />
      );
    }
    return null;
  };

  const renderReissueEdit = () => {
    if (showReissueCheckbox) {
      return (
        <Box>
          <IconButton
            className={classes.editBox}
            hidden={!showReissueEdit}
            disabled={disableReissueEdit()}
            onClick={() => handleIsReissueClick(index)}
          >
            {ToolTip()}
          </IconButton>
        </Box>
      );
    }
    if (showReissueEdit) {
      return (
        <Box>
          <IconButton
            className={classes.editBox}
            onClick={() => handleIsReissueClick(index)}
            disabled={disableReissueEdit()}
          >
            {ToolTip()}
          </IconButton>
        </Box >
      );
    }
    return null;
  };

  const reissueGroupVisible = () => {
    const pricingType = getValues(getNameString(`${schema}.pricingType`));
    if (pricingType !== PricingType.Product) return false;

    // Always show reissue, for manual pricing i.e. isIntegratedPricing = False
    if (!isIntegratedPricing) {
      const productType = getValues(getNameString(`${schema}.productType`));
      const formType = getValues(getNameString(`${schema}.formType`));

      return productType === ProductType.Jacket && (formType === JacketFormType.Loan || formType === JacketFormType.Owners);
    }

    const rateType = getValues(getNameString(`${schema}.pricingRateType`));
    const pricingRateTypeData = getValues(
      getNameString(`${schema}.pricingRateTypeData`)
    );

    const rateTypeData = pricingRateTypeData
      ? JSON.parse(pricingRateTypeData)
      : undefined;

    const result = rateTypeData?.find(
      (rtd: { Alias: string; }) => rtd.Alias === rateType
    );

    if (result === undefined) return false;
    return result.IsReissue && result.IsReissue.toLowerCase() === "true";
  };

  return (
    <StyledTableBodyCell align="center">
      {reissueGroupVisible() && (
        <Box display="flex" justifyContent="center" alignItems="center">
          {renderReissueCheckBox()}
          {renderReissueEdit()}
        </Box>
      )}
    </StyledTableBodyCell>
  );
}
