export enum UIConstants {
  JACKET_FORMDETAIL_BORROWER_NAME_MAX_LENGTH = 255,
  CHECK_PROCESS_IN_PROGRESS_INTERVAL_MILISECONDS = 250, // 0.25 second
  WAITING_PROCESS_IN_PROGRESS_MAX_MILISECONDS = 30000, // 30 seconds
  FLASH_MESSAGE_AUTO_HIDDEN_DURATION = 3000, // 3 seconds
  LOADING_IN_PROGRESS_MESSAGE = "Please wait while your data is being prepared",
  LOADING_RECALC_PREMIUM_MESSAGE = "Please wait while we recalculate your premiums",
  SAVE_IN_PROGRESS_MESSAGE = "Please wait while saving your file",
  SAVE_FILE_STATUS_MESSAGE = "Please wait while updating your File Status...",
  SAVE_PROFILE_IN_PROGRESS_MESSAGE = "Please wait while saving your profile",
  UNDO_REPORT_REQUEST_SUBMITTING_MESSAGE = "Please wait while submitting your request...",
  UNDO_REPORT_ERROR_MESSAGE = "There was a problem with the Undo Report button. Please try again or contact the Agency Support Center for assistance.",
  VIEW_DOCUMENT_ERROR_MESSAGE = "There was a problem displaying your document. Please try again or contact the Agency Support Center for assistance.",
  EFFECTIVE_DATE_NOT_WITHIN_30_DAYS_ERROR_MESSAGE = "The Effective Date must be within 30 days.",
  EFFECTIVE_DATE_NOT_VALID_ERROR_MESSAGE = "The effective date is not valid based on the Agency's contract date.",
  FORM_NOT_VALID_FOR_SELECTED_EFFECTIVE_DATE = "The Form is not valid for the selected Effective Date.  Please Void the Jacket and Issue a New One.",
}

export enum DOMEventType {
  FILE_JUST_LOADED = "fileJustLoaded",
  APP_RELOAD_REQUIRED = "appReloadRequired",
}

export enum LocalStorageKeys {
  COLUMNS_FILES = "columns-files",
  COLUMNS_FILES_HIDDEN = "columns-files-hidden",
  COLUMNS_REPORT_PAY = "columns-report-pay",
  COLUMNS_REPORT_PAY_HIDDEN = "columns-report-pay-hidden",
  COLUMNS_PENDING_PAY = "columns-pending-pay",
  COLUMNS_PENDING_PAY_HIDDEN = "columns-pending-pay-hidden",
}

export enum SessionStorageKeys {
  APP_VERSION = "app-version",
  APP_VERSION_PENDING = "app-version-pending",
  APP_RELOAD_REQUIRED = "app-reload-required",
  FEATURE_CONFIG = "feature-config",
  GENERAL_CONFIG = "general-config",
  GLOBAL_ACCESS_SELECTED_AGENCY = "selectedAgency"
}

export enum CreateFileProviderActionType {
  INIT = "INIT",
  UPDATE_PRICING_IN_PROGRESS = "UPDATE_PRICING_IN_PROGRESS",
}

export enum ProductType {
  AllProducts = "",
  Aal = "AAL",
  Cpl = "CPL",
  Jacket = "JACKET",
  Endorsement = "ENDORSEMENT", // not part of table lookup
  StandaloneEndorsement = "SAENDO",
}

export enum JacketFormType {
  Binder = "Binder",
  Commitment = "Commitment",
  Guarantee = "Guarantee",
  Loan = "Loan",
  Owners = "Owners",
}

export enum JacketFormDetailName {
  "AddendumAttached" = "Addendum Attached",
  "BorrowerNamesAndVesting" = "Borrower Names and Vesting",
  "MortgageAmount" = "Mortgage Amount",
}

export enum OrderStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  UndoVoidPending = "UNDOVOIDPEND",
  Voided = "VOIDED",
}

export enum EndorsementStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  Submitted = "SUBMITTED",
  Voided = "VOIDED",
}

export enum OrderStatusTypeName {
  Pending = "Pending",
  Issued = "Issued",
}

export enum FileStatusType {
  Open = "OPEN",
  OpenedInError = "OPENEDINERROR",
  PaymentPending = "PAYMENTPENDING",
  Paid = "PAID",
  Cancelled = "CANCELLED",
  Inactive = "INACTIVE",
  ReportPending = "REPORTEDPENDING",
}

export enum ReportingOptionType {
  PayByCheck = "PAYBYCHECK",
  EPay = "EPAY",
  ReportOnly = "REPORTONLY",
  AutoReport = "AUTOREPORT",
}

export enum MapActionType {
  SaveFile = "SaveFile",
  ProductAction = "ProductAction",
  RetrievePricingData = "RetrievePricingData",
  SaveJacketOPN = "SaveJacketOPN",
  CheckFormDirty = "CheckFormDirty",
  SaveFileStatus = "SaveFileStatus",
}

export enum ProductAction {
  Issue = "Issue",
  Revise = "Revise",
  Ppe = "Ppe",
  ReportPricing = "ReportPricing",
  UndoReport = "UndoReport",
  ReportAndPayByCheck = "ReportAndPayByCheck",
  CalculatePricing = "CalculatePricing",
  CalculateItem = "CalculateItem",
  CalculateItems = "CalculateItems",
  Void = "Void",
  None = "None",
}

export enum PartyType {
  Individual = "INDIVIDUAL",
  BusTrustEstate = "BUSTRUSTESTATE",
  Attorneys = "ATTORNEY",
  SettlementCompany = "SETTLEMENTCOMPANY",
  SecondaryAgency = "SECONDARYAGENCY",
}

export enum SearchPartyType {
  Attorney = "ApprovedAttorney",
  SettlementCompany = "SettlementCompany",
  SecondaryAgency = "SecondaryAgency",
}

export enum PartyRoleType {
  BuyerBorrower = "BUYER/BORROWER",
  Seller = "SELLER",
  Lender = "LENDER",
  AdditionalParty = "ADDITIONALPARTY",
}

export enum PartyRoleTypeName {
  BuyerBorrower = "Buyer/Borrower",
  Seller = "Seller",
  Lender = "Lender",
  AdditionalParty = "ADDITIONAL PARTY",
}

export enum ProductTypeName {
  CPL = "CPL",
  AAL = "AAL",
  JACKET = "Jacket",
  STANDALONE_ENDORSEMENT = "Standalone Endorsement",
}

export enum SequenceType {
  First = "FIRST",
  Second = "SECOND",
}

export enum IssueType {
  All = "All",
  Single = "Single",
}

export enum PricingColumnName {
  Product = "Product",
  ActualFee = "ActualFee",
  RiskRate = "ActualRiskRate",
  AgentRetention = "AgentRetention",
  TotalDue = "TotalDue",
}

export enum Country {
  USA = "840",
}

export enum RemittanceSplitType {
  Flat = "FLAT %",
  CentPerThousand = "CENTS PER THOUSAND",
  Staggered = "STAGGERED",
}

export enum DateTypeCode {
  Created = "CREATED_DATE",
  Modified = "MODIFIED_DATE",
  EffectiveDate = "EFFECTIVE_DATE",
  IssueDate = "ISSUE_DATE",
  ReportedDate = "BILLED_DATE",
}

export enum CacheKey {
  MsalInstance = "STEWART_MSAL_INSTANCE",
}

export enum PricingType {
  All = "All",
  Product = "Product",
  ProductItem = "ProductItem",
  Tax = "Tax",
}

export enum NotificationType {
  Info = "Info",
  Error = "Error",
}

export enum PricingConfigKey {
  IsIntegratedPricing = "IsIntegratedPricing",
  DefaultErrorMessage = "DefaultErrorMessage",
  RiskRate = "RiskRate",
  ReIssue = "ReIssue",
  ReIssueEdit = "Pricing:ReIssue:Edit",
  SimultaneousRate = "SimultaneousRate",
  InfoMsgRateType = "InfoMsg:RateType",
  InfoMsgReIssue = "InfoMsg:ReIssue",
  ThresholdMessage = "ThresholdMessage",
  TaxCode = "Tax",
  TaxDocument = "TaxCodesDocument",
  LiabilityLimit = "LiabilityLimit",
  LiabilityLabel = "OPN:Liability:Label",
  OPNLiability = "OPN:Liability",
  OPNCoverageType = "OPN:CoverageType",
  OPNCoverageTypeLabel = "OPN:CoverageType:Label",
  OPNPolicyDate = "OPN:PolicyDate",
  OPNPolicyDateLabel = "OPN:PolicyDate:Label",
  OPNPrevPolicyLast10Yrs = "OPN:PrevPolicyLast10Yrs",
  OPNWindowTitle = "OPN:Window:Title",
  TransCode = "TransCode",
  Leasehold = "Leasehold",
  ReIssueLabel = "Pricing:ReIssue:Label",
  ReIssueLabelText = "Pricing:ReIssue:LabelText",
  CheckBox = "Pricing:ReIssue:Checkbox",
  CoInsurance = "CoInsurance",
  CallStrafeForRemittance = "CallStrafeForRemittance",
  JacketFormDefault = "DefaultForm",
  JacketTransCodeDefault = "DefaultTransCode",
  MultipleLoans = "MultipleLoans",
  CPLLiability = "CPL:Liability",
}

export enum TaxCodeSearchBy {
  City = "city",
  County = "county",
  Default = "DEFAULT",
}

export enum TaxCodePremium {
  Default = "9998",
}

export enum VoidReason {
  CreatedInError = "Created in Error",
  EnteredInError = "Entered in Error",
}

export enum ReportAndPayAction {
  ReportOnly = "REPORTONLY",
  ReportAndPayByCheck = "REPORTANDPAYBYCHECK",
  PayByCheck = "PAYBYCHECK",
}

export enum ReportType {
  ReportOnly = "REPORTONLY",
  ReportPayCheck = "PAYBYCHECK",
  ReportPayEPay = "EPAY",
  AutoReport = "AUTOREPORT",
}

export enum PaymentsFileExportCriteriaType {
  ReportAndPayCriteria = "ReportAndPayCriteriaSearch",
  ReportAndPayKeyword = "ReportAndPayKeywordSearch",
  PendingPaymentsCriteria = "PendingPaymentsCriteriaSearch",
  PendingPaymentsKeyword = "PendingPaymentsKeywordSearch",
}

export enum RuleCondition {
  IsRequired = "IsRequired",
  IsAllowed = "IsAllowed",
  IsNotAllowed = "IsNotAllowed",
}

export enum OverrideReasonType {
  CoInsurance = "COINSURANCE",
}

export enum CompanyContractDateStatus {
  Active = "Active",
  Amended = "Amended",
  Cancelled = "Cancelled",
}

export enum PageType {
  MyFilesPage = "MYFILESPAGE",
  ReportPayPage = "REPORTPAYPAGE",
  PendingPayPage = "PENDINGPAYPAGE",
}

export enum SiteErrorType {
  BadAccount = "BADACCOUNT",
  NetworkError = "NETWORK",
  NotFound = "NOTFOUND",
}

export enum UserRequestAuthorizationType {
  Authorized = "AUTHORIZED",
  ProvidedAuthorization = "PROVIDED_AUTHORIZATION",
  NotAuthorized = "NOT_AUTHORIZED",
}

export enum DownloadFileType {
  Mp4 = "mp4",
  Pdf = "pdf",
  Xlsx = "xlsx",
}

export enum AgencyStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}