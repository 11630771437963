/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  makeStyles,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grow from "@material-ui/core/Grow";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/core/styles";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import StewartCheckbox from "controls/global/stewart-checkbox";
import { orderBy } from "lodash";
import React from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";

const usePaperStyles = makeStyles((theme) => ({
  popper: {
    '&[x-placement*="bottom"] $arrow': {
      left: 0,
      marginTop: "-0.71em",
      marginLeft: margin.xsmall,
      marginRight: margin.xsmall,
      top: 1,
      "&::before": {
        transformOrigin: "0 100%"
      },
    },
    zIndex: zIndex.high2,
  },
  arrow: {
    boxSizing: "border-box",
    color: theme.palette.background.paper,
    height: "1.29em",
    overflow: "hidden",
    position: "absolute",
    width: "1.8em",
    "&::before": {
      border: `${borderSize.xsmall} solid ${colors.grey09}`,
      boxShadow: theme.shadows[1],
      content: '""',
      display: "block",
      height: "100%",
      backgroundColor: "currentColor",
      margin: "auto",
      transform: "rotate(45deg)",
      width: "100%",
    }
  },
  paper: {
    border: `${borderSize.xsmall} solid ${colors.grey09}`,
    marginTop: margin.small1,
    overflow: "hidden",
    "&.MuiPaper-rounded": {
      borderRadius: borderRadius.xlarge,
    },    
    "& .MuiList-root": {
      maxHeight: "min(600px, calc(100vh - 300px))",
      minHeight: "100px",
      overflowY: "scroll",
    },    
    "& .MuiListItem-root": {
      paddingTop: padding.zero,
      paddingBottom: padding.zero,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: padding.small1,
      paddingLeft: padding.large1,
      paddingRight: padding.large1,
      paddingTop: padding.small1,
    }
  },
}));

const StyledIconText = styled(Typography)({
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal1,
});

const CustomLink = styled(Link)({
  cursor: "pointer",
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  textDecoration: "none !important",
});

export type MenuColumnItem<T> = {
  field: keyof T;
  name: string;
  checked: boolean;
};

type Props<T> = {
  options: MenuColumnItem<T>[] | undefined;
  onChecked: (field: keyof T, checked: boolean) => void;
  onHideAll?: () => void;
  onShowAll?: () => void;
};

export default function ColumnsConfig<T>({
  options,
  onChecked,
  onHideAll,
  onShowAll,
}: Props<T>) {
  const classes = usePaperStyles();
  const [columns, setColumns] = React.useState<
    MenuColumnItem<T>[] | undefined
  >();
  const [colMenuOpen, setColMenuOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<any>();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const sortedColumns = orderBy(options, "name");
    setColumns(sortedColumns);
  }, [options]);

  if (!columns) return null;
  return (
    <>
      <Button
        onClick={() => setColMenuOpen(!colMenuOpen)}
        data-test="myFilesColumns"
        ref={anchorRef}
      >
        <>
          Columns
          <ViewWeekOutlinedIcon />
        </>
      </Button>


      <Popper
        open={colMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.popper}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <>
              <span className={classes.arrow} ref={setArrowRef} />
              <Paper className={classes.paper} elevation={3}>
                <ClickAwayListener onClickAway={() => setColMenuOpen(false)}>
                  <MenuList
                    autoFocusItem={colMenuOpen}
                    id="menu-list-grow"
                  // onKeyDown={handleListKeyDown}
                  >
                    {columns.map(({ field, name, checked }) => (
                      <MenuItem key={String(field)}>
                        <FormControlLabel
                          control={
                            <StewartCheckbox
                              checked={checked}
                              onChange={() => onChecked(field, checked)}
                              name={String(field)}
                            />
                          }
                          label={name}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
                <div className="buttons">
                  {onHideAll ? (
                    <CustomLink onClick={onHideAll}>Hide All</CustomLink>
                  ) : null}
                  {onShowAll ? (
                    <CustomLink onClick={onShowAll}>Show All</CustomLink>
                  ) : null}
                </div>
              </Paper>
            </>
          </Grow>
        )}
      </Popper>
    </>
  );
}
