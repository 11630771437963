import AALProductCollection from "pages/file/components/product-section/aal/AALProductCollection";
import CPLProductCollection from "pages/file/components/product-section/cpl/CPLProductCollection";
//import CPLProductCollection from "pages/file/components/product-section/cpl/CPLProductCollectionTest";
import JacketProductCollection from "pages/file/components/product-section/jacket/JacketProductCollection";
import StandAloneCollection from "pages/file/components/product-section/standalone/StandAloneCollection";

const tabMapping = {
  AALProductCollection,
  CPLProductCollection,
  JacketProductCollection,
  StandAloneCollection,
};

export { tabMapping };
