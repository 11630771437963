import SelectField from "controls/global/select-field";
import { Signature } from "entities/UIModel/Signature";
import React, {
  useEffect,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";

const FormField = styled.div`
  margin-top: ${margin.xlarge1};
`;

const FieldTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: ${fontSize.xlarge0};
  font-weight: ${fontWeight.normal1};
  padding: ${padding.zero} ${padding.small};
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NameFieldWrapper = styled.div`
  width: 210px;
`;

const SignatureImageWrapper = styled.div`
  width: calc(100% - 230px);
`;

const ImageInnerWrraper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 55px;
`;

const SignatureImage = styled.img`
  max-width: 125px;
  width: 100%;
`;

const FieldLabel = styled.div`
  color: ${colors.grey08};
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal2};
  margin-bottom: ${margin.small1};
  padding-left: ${padding.small1};
`;

const StyledSelectField = styled(SelectField)({
  "& .MuiInputLabel-shrink": {
    display: "none"
  },
  "& legend": {
    display: "none"
  }
});

const FIELD_NAME = "signatureImage";

const SignatureInfo = () => {
  const [{ signatures }] = useSignatures();
  const { setValue } = useFormContext();
  const [, { getAgencyLocationsByImage }] = useSignatureDefaults();
  const [imageField] = useWatch({
    name: ["signatureImage"],
  });

  const mappedSignatures =
    signatures?.map((signature: Signature) => ({
      text: signature.signatureImageName,
      value: signature.signatureImageID,
    })) || [];

  const getSignatureImageSource = () => {
    const dataSoure = signatures?.find(
      (signature: Signature) =>
        signature.signatureImageID === Number(imageField?.value)
    )?.signatureImageData;
    return dataSoure;
  }

  useEffect(() => {
    if (imageField?.value) {
      getAgencyLocationsByImage(imageField.value);
    }
  }, [imageField]);

  useEffect(() => {
    if (mappedSignatures.length === 1) {
      setValue(FIELD_NAME, {
        text: mappedSignatures[0].text,
        value: mappedSignatures[0].value,
      });
    }
  }, [mappedSignatures]);

  return (
    <>
      <FieldTitle>Choose a signature.</FieldTitle>
      <FormField>
        <InnerWrapper>
          <NameFieldWrapper>
            <FieldLabel>NAME</FieldLabel>
            <StyledSelectField
              label="Choose Signature"
              name={FIELD_NAME}
              options={mappedSignatures}
              optionsValueField="value"
              optionsTextField="text"
              dataValueField="value"
              dataTextField="text"
              disabled={mappedSignatures.length === 0}
            />
          </NameFieldWrapper>
          <SignatureImageWrapper>
            <FieldLabel>IMAGE</FieldLabel>
            <ImageInnerWrraper>
              <SignatureImage
                src={getSignatureImageSource()}
                alt={imageField.text}
              />
            </ImageInnerWrraper>
          </SignatureImageWrapper>
        </InnerWrapper>
      </FormField>
    </>
  );
};

export default SignatureInfo;
