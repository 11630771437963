import React from "react";
import Box from "@material-ui/core/Box";
import ActionButton from "../action-button";
import ClearIcon from '@mui/icons-material/Clear';
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

type Props = {
  onClick: React.MouseEventHandler;
  disabled?: boolean | undefined;
};

export default function ClearButton({ onClick, disabled }: Props) {
  const isFileDisabled = useFieldDisabler("ClearButton");
  return (
    <Box m={2} mr={0} ml={1} mt={1} display="flex" justifyContent="flex-end">
      <ActionButton
        variant="outlined"
        color="secondary"
        onClick={onClick}
        startIcon={<ClearIcon />}
        disabled={disabled || isFileDisabled}
      >
        Clear
      </ActionButton>
    </Box>
  );
}
