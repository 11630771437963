import { withStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "theme/defaultStyle";

const StewartDatePicker = withStyles((theme) => ({
  root: (props: any) => {
    let noBorderProps = {};
    if (props.noBorder) {
      noBorderProps = {
        borderColor: "transparent",
      };
    }
    return {
      "& .MuiOutlinedInput-root": {
        ...theme.custom.textField.inputOutlined,
      },
      "& div.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
        noBorderProps,
      "& .MuiOutlinedInput-root.Mui-disabled":
        theme.custom.textField.inputOutlinedDisabled,
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
      "& div.MuiInputAdornment-root button.MuiButtonBase-root": {
        color: colors.blue01,
        padding: 0,
        position: "relative",
        left: -12,
      },
      "& div.MuiInputAdornment-root button.MuiButtonBase-root:hover": {
        color: colors.blue03,
      },
      "& div.MuiInputAdornment-root button.MuiButtonBase-root.Mui-disabled": {
        color: colors.blue04,
      },
      "& div.Mui-error": { borderColor: `${colors.red01} !important` },
      "& div.Mui-error input": { color: colors.red01 },
      "& div.Mui-error path": { fill: colors.red01 },
    };
  },
}))(KeyboardDatePicker);

export default StewartDatePicker;
