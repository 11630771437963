import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import React from "react";
import styled from "styled-components";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  margin,
} from "theme/defaultStyle";
import { FORMATS } from "./FileExportDialog";

const TITLE = "Choose document format(s):";

type Props = {
  formats: FORMATS;
  setFormats: React.Dispatch<React.SetStateAction<FORMATS>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.grey08};
  font-family: "Roboto";
  font-size: ${fontSize.xlarge0};
  font-weight: ${fontWeight.normal1};
  margin-bottom: ${margin.small2};
`;

const Container = styled.div`
  display: flex;
  gap: ${gaps.large2};
  align-items: center;
  justify-content: center;
`;

function FileFormatOptions({ formats, setFormats, setDisabled }: Props) {

  const onChangeHandle =
    (format: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFormats = { ...formats, [format]: event.target.checked };
      setFormats(newFormats);

      if (!newFormats["EXCEL"] && !newFormats["PDF"]) setDisabled(true);
      else setDisabled(false);
    };

  return (
    <>
      <Title>
        <span>{TITLE}</span>
      </Title>
      <Container>
        <CheckboxInput
          checked={formats["EXCEL"]}
          disabled={false}
          onChange={onChangeHandle("EXCEL")}
          label="Excel"
        />
        <CheckboxInput
          checked={formats["PDF"]}
          disabled={true}
          onChange={onChangeHandle("PDF")}
          label="PDF"
        />
      </Container>
    </>
  );
}

export default FileFormatOptions;
