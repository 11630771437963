import {
  Box,
  Card,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@material-ui/core";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import EmbedViewer, {
  EmbedViewerRefType,
} from "controls/global/embed-viewer/EmbedViewer";
import Loading from "controls/global/loading";
import VideoPlayer from "controls/global/video-player";
import { VideoPlayerRefType } from "controls/global/video-player/VideoPlayer";
import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  padding,
} from "theme/defaultStyle";
import PdfIconBigRed from "theme/icons/PdfIconBigRed";
import QRCIcon from "theme/icons/QRCIcon";
import VideoCamIcon from "theme/icons/VideoCamIcon";
import VideoIcon from "theme/icons/VideoIcon";
import { getBase64DataType } from "utils/shared";
import cssClasses from "../../HelpCenter.module.css";
import {
  HELP_CENTER_TRAINING_MATERIAL_API_PATH,
} from "../../utils";

type QuickReferenceCardType = {
  searchTags: string;
};

export type QuickReferenceCardDisplayPlaceType = {
  DisplayPlaceId: number;
  DisplayPlaceCode: string;
  DisplayPlaceDescription: string;
};

export type QuickReferenceCardDto = {
  FileName: string;
  QuickReferenceCardId: string;
  Tags: string;
  DisplayPlace: string;
  PathFile: string;
  FileType: string;
  FileBase64: string;
};

const CPL_STRING_CODE: string = "CPL_AAL";
const CPL_STRING_TITLE: string = "CPLs/AALs";

const CustomizedTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#015E88",
  },

  "& .MuiTabs-scrollButtons": {
    backgroundColor: "white",
  },
});

const CustomizedTab = styled(Tab)({
  backgroundColor: "#F5F5F5",
  color: "#015E88",
  fontSize: "14px",
  opacity: 1,

  "&.Mui-selected": {
    backgroundColor: "white",
  },

  "&:last-child": {
    borderTopRightRadius: "4px",
  },

  "&:first-of-type": {
    borderTopLeftRadius: "4px",
  },
});

const DownloadFileText = styled(Typography)({
  "&": {
    color: colors.blue01,
    fontFamily: fontFamily.primary,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.bold1,
    lineHeight: lineHeight.large,
    overflowWrap: "anywhere",
    padding: `${padding.xsmall}${padding.zero}`,
    textDecoration: "none",
    textAlign: "left",
  }
});
const QuickReferenceCard = ({ searchTags }: QuickReferenceCardType) => {
  const [quickReferenceCards, setQuickReferenceCards] = useState<
    QuickReferenceCardDto[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<string>(CPL_STRING_CODE);
  const [selectedFilterTitle, setSelectedFilterTitle] =
    useState<string>(CPL_STRING_TITLE);
  const [quickReferenceCardDisplayPlaces, setQuickReferenceCardDisplayPlaces] =
    useState<QuickReferenceCardDisplayPlaceType[]>([]);
  const [tabValue, setTabValue] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showEmbedViewerRef = useRef<EmbedViewerRefType>(null);
  const showVideoPlayerRef = useRef<VideoPlayerRefType>(null);

  async function getQuickReferenceCardsList(): Promise<
    QuickReferenceCardDto[]
  > {
    const { data } = await axiosSecuredInstance.get(
      HELP_CENTER_TRAINING_MATERIAL_API_PATH
    );
    return data;
  }

  async function getDisplayPlace(): Promise<
    QuickReferenceCardDisplayPlaceType[]
  > {
    const { data } = await axiosSecuredInstance.get(
      `${HELP_CENTER_TRAINING_MATERIAL_API_PATH}/display-place`
    );
    return data;
  }

  async function getQRCItem(
    QuickReferenceCardId: string
  ): Promise<QuickReferenceCardDto> {
    const { data } = await axiosSecuredInstance.get(
      `${HELP_CENTER_TRAINING_MATERIAL_API_PATH}/${QuickReferenceCardId}`
    );
    return data;
  }

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    setTabValue(newValue);
  }

  function refreshPage() {
    getQuickReferenceCardsList().then((result) => {
      setQuickReferenceCards(result || []);
    });
  }

  useEffect(() => {
    getDisplayPlace().then((result: QuickReferenceCardDisplayPlaceType[]) => {
      setQuickReferenceCardDisplayPlaces(result);
      setTabValue(
        result?.find?.((r) => r.DisplayPlaceCode === CPL_STRING_CODE)
          ?.DisplayPlaceId || 0
      );
    });
    refreshPage();
  }, []);

  function filterCardsByFilter(filter: string) {
    if (selectedFilter !== filter) {
      setSelectedFilter(filter);
      refreshPage();
    }
  }

  function getFilteredItems() {
    return quickReferenceCards.filter(
      (qrc) =>
        qrc.DisplayPlace === selectedFilter &&
        (!searchTags ||
          qrc.Tags.toLowerCase().includes(searchTags.trim().toLowerCase()))
    );
  }

  function showContent(quickReferenceCardId: string, fileName: string) {
    setIsLoading(true);
    getQRCItem(quickReferenceCardId)
      .then(({ FileBase64, FileType }: QuickReferenceCardDto) => {
        FileType.toLowerCase() === "mp4" ? showVideoPlayerRef.current?.open(
          fileName,
          `data:${getBase64DataType(FileType)};base64,${FileBase64}`
        )
          : showEmbedViewerRef.current?.open(
            fileName,
            `data:${getBase64DataType(FileType)};base64,${FileBase64}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getLinkForOpenItem(
    quickReferenceCardId: string,
    fileName: string,
    innerComponent: ReactNode
  ): ReactNode {
    return (
      <a
        className={cssClasses.downloadFile}
        href="#"
        onClick={() => {
          showContent(quickReferenceCardId, fileName);
        }}
      >
        {innerComponent}
      </a>
    );
  }

  return (<Box>
    <Box className={cssClasses.headerContainer}>
      <Box className={cssClasses.header}>
        <QRCIcon />
        <Typography className={cssClasses.titleQuickReferenceCard} variant="h6">Training Materials</Typography>
      </Box>
    </Box>
    <Box className={cssClasses.headerFiltersContainer}>
      <CustomizedTabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {quickReferenceCardDisplayPlaces?.map?.(
          ({
            DisplayPlaceCode,
            DisplayPlaceDescription,
            DisplayPlaceId,
          }: QuickReferenceCardDisplayPlaceType) => {
            return (
              <CustomizedTab
                key={DisplayPlaceCode}
                label={DisplayPlaceDescription}
                value={DisplayPlaceId}
                onClick={() => {
                  filterCardsByFilter(DisplayPlaceCode);
                  setSelectedFilterTitle(DisplayPlaceDescription);
                }}
              />
            );
          }
        )}
      </CustomizedTabs>
    </Box>

    <Box className={cssClasses.files}>
      <Typography className={cssClasses.fileHeader} variant={"h6"}>
        {selectedFilterTitle === CPL_STRING_TITLE
          ? "Closing Protection Letters(CPLs) / Agent Authorization Letters(AALs)"
          : selectedFilterTitle}
      </Typography>
      <Box className={cssClasses.mainContainer}>
        {getFilteredItems().length === 0 && !!searchTags ? (
          <>
            <Typography className={cssClasses.noKeywordMatch}>
              {`No matches found for keyword '${searchTags.trim()}'`}
            </Typography>
            <Typography className={cssClasses.adjustKeywords}>
              {" "}
              Please adjust your keyword search and try again.
            </Typography>
          </>
        ) : (
          getFilteredItems().map(
            ({
              QuickReferenceCardId,
              FileName,
              PathFile,
              FileType,
            }: QuickReferenceCardDto) => {
              return (
                <Box
                  key={QuickReferenceCardId}
                  className={cssClasses.fileItem}
                >
                  <Box>
                    <Box className={cssClasses.wrapperCardItem}>
                      <Card className={cssClasses.mainCard}>
                        {FileType === "MP4" && (
                          <video className={cssClasses.videoThumbnail}>
                            <source src={PathFile} type="video/mp4" />
                            <track src="" kind="captions" />
                          </video>
                        )}

                        <Box className={cssClasses.cardItem}>
                          {FileType === "PDF" ? (
                            getLinkForOpenItem(
                              QuickReferenceCardId,
                              FileName,
                              <PdfIconBigRed />
                            )
                          ) : (
                            <div className={cssClasses.videoIcon}>
                              {getLinkForOpenItem(
                                QuickReferenceCardId,
                                FileName,
                                <VideoIcon />
                              )}
                            </div>
                          )}
                        </Box>
                      </Card>
                    </Box>
                    <Box className={cssClasses.wrapperButtonItem}>
                      {getLinkForOpenItem(
                        QuickReferenceCardId,
                        FileName,
                        <DownloadFileText className={cssClasses.downloadFile}>
                          {FileName}
                          <span className={cssClasses.downloadFileVideoIcon}>
                            {FileType === "MP4" && <VideoCamIcon />}
                          </span>
                        </DownloadFileText>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            }
          )
        )}
        {getFilteredItems().length === 0 && !searchTags && (
          <Box
            className={[
              cssClasses.emptySection,
              cssClasses.emptySectionQRC,
            ].join(" ")}
          >
            <Typography className={cssClasses.emptySectionLabel}>
              No Records
            </Typography>
          </Box>
        )}
      </Box>

      {isLoading && (
        <Box>
          <Loading />
        </Box>
      )}

      <EmbedViewer ref={showEmbedViewerRef} />
      <VideoPlayer ref={showVideoPlayerRef} />
    </Box>
  </Box>
  );
};

export default QuickReferenceCard;
