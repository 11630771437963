import ErrorDialog from "controls/global/dialogs/error-dialog";
import { SCFile } from "entities/UIModel";
import { ActionResult } from "entities/UIModel/ActionResult";
import { getCorrelationId } from "pages/file/utils/helper";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import { MapActionType, UIConstants } from "utils/data/enum";
import SaveChanges from "./SaveChanges";
import SaveFile from "./SaveFile";

interface Props {
  saveFileRef: React.MutableRefObject<HTMLButtonElement>;
  saveFileChangeRef: React.MutableRefObject<HTMLButtonElement>;
}

const SaveActions = ({ saveFileRef, saveFileChangeRef }: Props) => {
  const [errorDialog, errorDialogDispatch] = React.useReducer(
    (s: any, a: any) => ({ ...s, ...a }),
    {
      open: false,
      message: "",
      correlationId: "",
    }
  );

  const [{ initialValues }, { saveFile }] = useFiles();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();

  const performSave = async (
    formValues: SCFile,
    isCleaningForm: boolean = false
  ) => {
    openAutomaticProgressDialog(UIConstants.SAVE_IN_PROGRESS_MESSAGE);

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );

    const result: ActionResult = await saveFile(fileToSave, isCleaningForm);
    closeAutomaticProgressDialog();

    return handleError(result);
  };

  const handleError = (actionResult: ActionResult): boolean => {
    if (
      actionResult.error &&
      actionResult.error.response &&
      actionResult.error.response.status !== 200
    ) {
      const errorMessage =
        actionResult.error.response.data &&
          actionResult.error.response.data.errorMessage
          ? actionResult.error.response.data.errorMessage
          : actionResult.error.response.statusText;

      const correlationId = getCorrelationId(actionResult.error.response.headers);

      errorDialogDispatch({ open: true, message: errorMessage, correlationId: correlationId });
      return false;
    }

    if (actionResult.apiResponse) {
      const apiSCFile = actionResult.apiResponse;
      if (apiSCFile.ErrorMessage) {
        errorDialogDispatch({ open: true, message: apiSCFile.ErrorMessage });
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <SaveFile innerRef={saveFileRef} onSave={performSave} />
      <SaveChanges innerRef={saveFileChangeRef} onSave={performSave} />
      <ErrorDialog
        isOpen={errorDialog.open}
        confirmationMessage={errorDialog.message}
        correlationId={errorDialog.correlationId}
        onYes={() => errorDialogDispatch({ open: false, message: "", correlationId: "" })}
      />
    </>
  );
};

export default SaveActions;
