import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import React, {
  useEffect,
  useState,
} from "react";
import {
  Controller,
  useWatch,
} from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useLeasehold from "utils/custom-hooks/useLeasehold";

type Props = {
  schema: string;
  formType: string;
  disabled: boolean;
  classes: any;
};

function JacketLeasehold({ schema, formType, disabled, classes }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const { showLeasehold } = useLeasehold();
  const { control, getValues, setValue, nameString } = useFormWrapper();

  const fieldName = nameString(`${schema}.pricingDetail.isLeasehold`);
  const defaultValue = getValues(fieldName);

  const [state] = useWatch({
    name: ["properties.0.state.abbr"],
  });

  const onChange = (e: any, checked: boolean) => {
    setValue(fieldName, checked);
  };

  useEffect(() => {
    const show = showLeasehold(formType, state);
    setShow(show);
  }, [formType, state]);

  if (!show) return null;

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
      render={({ field: { name, value } }) => {
        return (
          <CheckboxInput
            label="Leasehold"
            disabled={disabled}
            {...{ name }}
            onChange={onChange}
            checked={value}
          />
        );
      }}
    />
  );
}

export default JacketLeasehold;
