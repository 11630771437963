import React from "react";
import { Box, ListItemText, MenuItem, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import OverflowTooltip from "controls/global/overflow-tooltip";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import { MultiSelectContainer } from "./MultiSelectContainer";
import ErrorMessage from "controls/global/error-message/ErrorMessage";
import { colors, fontSize } from "theme/defaultStyle";
export interface MultiSelectFieldOption {
  value: string;
  text: string;
  selected?: boolean;
}

type Props = {
  label: string;
  name: string;
  options: any[];
  disabled?: boolean;
};

const usePaperStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiMenuItem-root:hover": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
  },
  listItemText: {
    fontSize: fontSize.large,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "black",
  },
}));

const ALL_KEY = "All";
const MultiSelectComponent = ({
  label,
  name,
  options,
  disabled = false,
}: Props) => {
  const classes = usePaperStyles();
  const { control, clearErrors } = useFormWrapper();

  const getRenderValue = (selected: any) => {
    const selectedItem = selected.join(", ");
    return <OverflowTooltip text={selectedItem} tooltip={selectedItem} />;
  };

  const handleChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    clearErrors(`${name}` as const);

    const selectedValues = ev.target.value;
    if (selectedValues.indexOf(ALL_KEY) > -1) {
      onChange([ALL_KEY]);
      return;
    }

    onChange(ev.target.value);
  };

  return (
    <>
      <Controller
        control={control}
        name={`${name}` as const}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => {
          return (
            <MultiSelectContainer
              select
              fullWidth
              label={label}
              disabled={disabled}
              error={Boolean(error?.message)}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              SelectProps={{
                MenuProps: {
                  classes: {
                    paper: classes.paper,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
                multiple: true,
                value: field.value,
                renderValue: getRenderValue,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => handleChange(event, field.onChange)}
            >
              {options.map((option: any, index: number) => (
                <MenuItem
                  key={index}
                  value={option.text}
                  disabled={
                    field.value.includes(ALL_KEY) && option.text !== ALL_KEY
                  }
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CheckboxInput
                      name={name}
                      checked={field.value.indexOf(option.text) > -1}
                    />
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={option.text}
                    />
                  </Box>
                </MenuItem>
              ))}
            </MultiSelectContainer>
          );
        }}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default MultiSelectComponent;
