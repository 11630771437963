import { makeStyles } from "@material-ui/core/styles";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import CurrencyInputField from "controls/global/currency-field";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import PdfViewer from "controls/global/pdf-viewer";
import {
  ActionResult,
  Document,
  Endorsement,
  GeneralFileParty,
  Jacket,
  JacketFormDetail,
  JacketSignature,
  PdfDocument,
  PricingProduct,
  SCFile,
} from "entities/UIModel";
import {
  cloneDeep,
  get,
  isEqual,
} from "lodash";
import {
  calculateRemittance,
  getCorrelationId,
  getErrorMessageByProductAction,
} from "pages/file/utils/helper";
import { isFormTypeValidForSimultaneous } from "pages/file/utils/products/jacket";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React, {
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { useWatch } from "react-hook-form";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useCompanyRemittance } from "utils/context/CompanyRemittanceContext";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useDocument } from "utils/context/DocumentContext";
import {
  RevisionValues,
  useFilePage,
} from "utils/context/FilePageContext";
import { useFiles } from "utils/context/FilesContext";
import { usePricingActions } from "utils/context/PricingContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import useConfig from "utils/custom-hooks/useConfig";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormDetail from "utils/custom-hooks/useFormDetail";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import useForms from "utils/custom-hooks/useForms";
import {
  JacketFormDetailName,
  JacketFormType,
  MapActionType,
  OrderStatusType,
  OrderStatusTypeName,
  ProductAction,
  ProductType,
  UIConstants,
} from "utils/data/enum";
import {
  capitalize,
  convertToNumber,
  formatDate,
  formatDateTime,
  hasValue,
} from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import EffectiveDateField from "../common/EffectiveDateField";
import ProductAgencyLocationField from "../common/ProductAgencyLocationField";
import SerialNumberSectionField from "../common/SerialNumberSectionField";
import SignatureField from "../common/SignatureField";
import JacketButtonSection, { ReviseAction } from "./JacketButtonSection";
import JacketFormDetails from "./JacketFormDetails";
import JacketForms from "./JacketForms";
import JacketInsuredNames from "./JacketInsuredNames";
import JacketLeasehold from "./JacketLeasehold";
import JacketPremium from "./JacketPremium";
import JacketSimultaneous from "./JacketSimultaneous";
import JacketTransCode from "./JacketTransCode";
import JacketTypes from "./JacketTypes";
import VoidJacketForm from "./VoidJacketForm";
import CombineEndorsementGrid from "./endorsements/CombineEndorsementGrid";

interface JacketProductProps {
  productIndex: number;
  endorsementEditMode: boolean;
  disableEndorsementSubmit: boolean;
  issueButtonDisabled?: boolean;
  submitReviseDisabled?: boolean;
  forceRevisionMode?: boolean;
  issueJacket: (integrationKey: string, index: number) => Promise<boolean>;
  reviseJacket: (integrationKey: string) => void;
  deleteJacket: (index: number) => void;
  onSimultaneousChanged: (index: number, checked: boolean) => void;
  handleEndorsementGridCancel: () => void;
  handleEndorsementGridSubmit: () => void;
  handleRemittanceCalculation: (
    value: string,
    index: number,
    feeType: string
  ) => void;
  setDisableAddJacket: (isDisabled: boolean, integrationKey?: string) => void;
  onEffectiveDateChange: (date: Date | null) => void;
  isPdfViewerOpen?: boolean;
  isRevisionMode: boolean;
  orderParties: GeneralFileParty[];
}

type JacketProductState = {
  openPdfViewer: boolean;
  openJacketIssueDialog: boolean;
  issueJacketRequestId: string;
  runJacketProgress: boolean;
  statusMessage: string;
  progressDialogAction: string;
  progressDialogProduct: string;
  openVoidCPLDialog: boolean;
  voidButtonDisabled: boolean;
  voidSubmitButtonDisabled: boolean;
  // Error Dialog
  errorMessage: string | React.ReactNode;
  correlationId: string;
  openErrorDialog: boolean;
};

const initialJacketProductState: JacketProductState = {
  openPdfViewer: false,
  openJacketIssueDialog: false,
  issueJacketRequestId: "",
  runJacketProgress: false,
  statusMessage: "",
  progressDialogAction: "",
  progressDialogProduct: "",
  openVoidCPLDialog: false,
  voidButtonDisabled: false,
  voidSubmitButtonDisabled: false,
  errorMessage: "",
  correlationId: "",
  openErrorDialog: false,
};

const useStyles = makeStyles({
  hide: {
    display: "none",
  },
  unsetWidth: {
    width: "unset",
    "& div:not(.MuiFormControl-root)": {
      position: "relative",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    gap: gaps.large1,
  },
  endorsementGrid: {
    display: "flex",
    flexDirection: "column",
    gap: gaps.large1,
    width: "100%",
    "&:empty": {
      display: "none",
    },
  },
  dateTypeForm: {
    "& .errorMessage": {
      maxWidth: "80vw",
      paddingRight: padding.medium1,
    },
    "& > :not(.dateValidation)": {
      width: "unset",
    },
  },
  agencySignatureButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: gaps.large1,
    justifyContent: "space-between",
    width: "100%",
    "& > :empty": {
      display: "none",
    }
  },
  agencySignature: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    gap: gaps.large1,
    "& > :nth-child(1)": {
      flexBasis: "350px",
      flexGrow: 3,
      flexShrink: 0,
      minWidth: "300px",
      width: "auto",
    },
    "& > :nth-child(2)": {
      flexBasis: "300px",
      flexGrow: 1,
      flexShrink: 0,
      minWidth: "300px",
      width: "auto",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    gap: gaps.small1,
    justifyContent: "flex-end",
  },
  fullWidth: {
    width: "100%",
  },
});

const JacketProduct = ({
  isPdfViewerOpen,
  productIndex,
  endorsementEditMode,
  disableEndorsementSubmit,
  issueJacket,
  reviseJacket,
  deleteJacket,
  onSimultaneousChanged,
  handleEndorsementGridCancel,
  handleEndorsementGridSubmit,
  handleRemittanceCalculation,
  issueButtonDisabled = false,
  submitReviseDisabled = false,
  setDisableAddJacket,
  onEffectiveDateChange,
  isRevisionMode = false,
  orderParties,
}: JacketProductProps) => {
  const classes = useStyles();
  const [jacketProductState, setJacketProductState] = useState<JacketProductState>({ ...initialJacketProductState });

  const { setValue, getValues, nameString, clearErrors, register, formState: { errors } } = useFormWrapper();
  const [{ agencyStateRemittance }] = useCompanyRemittance();
  const [{ voidReasons }] = useVoidReasons();
  const [, { getDocumentUrl }] = useDocument();

  const { resetField } = useFormWrapper();
  const shouldShowDeleteButtom = useFieldDisabler("JACKETDeleteButton");
  const [, { setRevisingMode }] = useFilePage();
  const { isFileLocked, reloadFile } = useCreateFile();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const { featureConfig: { enableSignatures } } = useConfigContext();

  // View PDF document
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  // persist local variable
  let currentActionResult = useRef<ActionResult>({});
  let currentJacketIntegrationKey = useRef("");
  let currentProductAction = useRef("");
  const isRevisingModeRef = useRef<boolean>(false);
  const isProcessingReviseRef = useRef<boolean>(false);
  const originalValuesRef = useRef<RevisionValues | any>(null);

  const [
    currentJacketOrderStatusTypeCode,
    currentJacketEffectiveDate,
    currentJacketFormType,
    currentJacketForm,
    currentJacketRevisionMode,
  ] = useWatch({
    name: [
      nameString(`jackets.${productIndex}.orderStatusTypeCode`),
      nameString(`jackets.${productIndex}.effectiveDate`),
      nameString(`jackets.${productIndex}.formType`),
      nameString(`jackets.${productIndex}.form`),
      nameString(`jackets.${productIndex}.revisionMode`),
    ],
  });


  const jackets = getValues("jackets");
  const currentJacket: Jacket = getValues(`jackets.${productIndex}`);
  const currentInsuredNames = getValues(`jackets.${productIndex}.insuredNames`);
  const currentInsuredNamesData = getValues(`jackets.${productIndex}.insuredNamesData`);
  const currentJacketFormID = getValues(`jackets.${productIndex}.formID`);

  const isIntegratedPricing = getValues(`pricing.isIntegratedPricing`);


  const [voidDateTime, voidReason, orderStatusTypeName, orderStatusTypeCode] =
    getValues([
      `jackets.${productIndex}.void.dateTime`,
      `jackets.${productIndex}.void.reason`,
      `jackets.${productIndex}.orderStatusTypeName`,
      `jackets.${productIndex}.orderStatusTypeCode`,
    ]);

  const [{ initialValues, isReadOnly }, { voidProduct, setIssuedProductDirty, resetPricingFields }] =
    useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const { isPremiumVisible } = useConfig();

  const { options } = useForms(
    ProductType.Jacket,
    currentJacketFormType,
    currentJacketEffectiveDate,
    undefined
  );
  const { setFormInfo } = useCreateFile();
  const { apiFormDetails } = useFormDetail(
    currentJacketFormID,
    currentJacketFormType
  );

  const checkPremiumFieldVisibility = () => {
    const visible = isPremiumVisible(
      currentJacket?.identityKey === 0,
      currentJacket?.issueDateTime
    );

    if (!visible && !currentJacket?.excludePremium)
      setValue(`jackets.${productIndex}.excludePremium`, true);

    return visible;
  };

  // Hidden fields
  const orderStatusTypeCodeField = `jackets.${productIndex}.orderStatusTypeCode`;

  const getIssuedDate = () => {
    const currentIssueDate = getValues(`jackets.${productIndex}.issueDateTimeStr`);

    return currentIssueDate ? formatDateTime(new Date(currentIssueDate), false) : "";
  };

  const tabTitle = `Jacket ${productIndex + 1}`;

  const disableDOMIssueJacketButtons = () => {
    document
      .querySelectorAll("button[name*='issueJacket-']:not([disabled]")
      ?.forEach((e: any) => {
        e.disabled = true;
        e.classList.add("Mui-disabled");
      });
  };

  const handleOnIssueJacket = async () => {
    isProcessingReviseRef.current = true;
    // Robun 10/26/2021 - notes: immediately disable issue jacket buttons for a short time, and then allow the React to take the control back for re-render the buttons
    disableDOMIssueJacketButtons();
    const jacket = getValues(`jackets.${productIndex}`);
    await updateManualPricing(jacket, productIndex, ProductAction.Issue);
    setTimeout(() => {
      flushSync(async () => {
        const isValid = await issueJacket(jacket.integrationKey, productIndex);
        if (isProcessingReviseRef.current && enableSignatures) {
          setTimeout(() => {
            if (isValid) {
              resetField(`jackets.${productIndex}.jacketSignatures`);
            }
            isProcessingReviseRef.current = false;
          }, 100);
        }
      });
    }, 50);
  };

  const getDocumentId = () => {
    const jacket = getValues(`jackets.${productIndex}`);
    return jacket?.documents && jacket?.documents.length > 0
      ? Number(jacket.documents[0].id)
      : 0;
  };

  const documentId = getDocumentId();

  const getPdfFilename = () =>
    `${getValues("fileNameNumber")} Jacket ${currentJacketFormType} ${currentJacket?.productReferenceID
    }`;

  const isSerialNumberSectionVisible =
    orderStatusTypeCode &&
      orderStatusTypeCode !== OrderStatusType.Pending &&
      orderStatusTypeCode !== OrderStatusType.Error
      ? true
      : false;

  const dtEffectiveDate = formatDate(
    new Date(String(currentJacketEffectiveDate))
  );

  const getTabDescription = () => {
    const form = currentJacket?.formType;
    const dtEffectiveDate = getIssuedDate();
    if (form && currentJacketForm && currentJacket?.form !== "") {
      let statusDate =
        orderStatusTypeCode === OrderStatusType.Voided
          ? formatDate(new Date(String(voidDateTime)))
          : formatDate(new Date(dtEffectiveDate));

      const jacketStatus = orderStatusTypeName
        ? orderStatusTypeName
        : OrderStatusTypeName.Pending;
      if (orderStatusTypeCode === OrderStatusType.Pending) {
        return ` ${currentJacketForm} - ${jacketStatus}`;
      }
      return ` ${currentJacketForm} - ${jacketStatus} ${statusDate}`;
    }
  };

  const isDisabled = () => {
    if (!isRevisingModeRef.current && isRevisionMode) return true;

    return (
      (isSerialNumberSectionVisible && !isRevisingModeRef.current) ||
      currentJacketRevisionMode ||
      false
    );
  };

  const isTypeAndFormSelected = currentJacketFormType && currentJacketForm;

  const revisionModeActive = currentJacketRevisionMode
    ? currentJacketRevisionMode
    : isRevisionMode || false;

  const effectiveDateErrorMessage = get(errors, `jackets.${productIndex}.effectiveDate.message`);
  const isFormDisabled = !(currentJacketEffectiveDate && currentJacketFormType && !effectiveDateErrorMessage);

  const isIssuable = !(currentJacketEffectiveDate && currentJacketFormType && currentJacketForm && !effectiveDateErrorMessage);

  const showExpandedIssue = currentJacketFormType === "Loan" || currentJacketFormType === "Owners";

  const showPremium = checkPremiumFieldVisibility() &&
    currentJacketFormType === JacketFormType.Loan &&
    currentJacketForm !== "" &&
    apiFormDetails?.some((x) =>
      x.formDetailName?.includes("Premium")
    );

  const voidedDate = voidDateTime
    ? formatDate(new Date(String(voidDateTime)))
    : undefined;

  //REFERENCE!!  Instead of pulling getValues in multiple places
  //we pull it one time at the top of the document (after the useWatch)
  //and pull out the values we would be using.
  const productStatusDisplay =
    orderStatusTypeCode && orderStatusTypeCode === OrderStatusType.Voided
      ? "Voided " + voidedDate + " - " + voidReason
      : capitalize(orderStatusTypeName ? orderStatusTypeName : "");

  const displayIssuedModeGrid =
    currentJacket && currentJacket?.endorsements && currentJacket?.endorsements.length > 0
      ? currentJacket?.orderStatusTypeCode !== OrderStatusType.Pending
      : false;

  const getPdfDocument = (pdfUrl: string, latestDocumentId: number) => {
    let pdfDocument: PdfDocument = {
      fileId: currentJacket?.fileID || 0,
      productType: ProductType.Jacket,
      orderId: currentJacket?.orderID || 0,
      documentId: latestDocumentId,
      pdfUrl: pdfUrl,
      fileName: getPdfFilename(),
      showHidden: 0,
    };

    return pdfDocument;
  };

  const handleOnViewJacket = async () => {
    // get pdf url here
    const documentId = getDocumentId();
    if (documentId === 0) {
      openAutomaticProgressDialog(UIConstants.LOADING_IN_PROGRESS_MESSAGE);
    }
    const actionResult: ActionResult = await getDocumentUrl(
      currentJacket?.fileID || 0,
      ProductType.Jacket,
      currentJacket?.orderID || 0,
      documentId,
      0
    );

    if (documentId === 0) {
      closeAutomaticProgressDialog();
    }

    if (actionResult && (actionResult.error || !actionResult.pdfUrl)) {
      setJacketProductState(prevState => ({
        ...prevState,
        errorMessage: UIConstants.VIEW_DOCUMENT_ERROR_MESSAGE,
        correlationId: getCorrelationId(actionResult.error.response?.headers),
        openErrorDialog: true,
      }));
      return;
    }

    if (actionResult && actionResult.pdfUrl && actionResult.documentId) {
      const currentJacket: Jacket = getValues(`jackets.${productIndex}`);

      if (currentJacket?.documents && currentJacket?.documents.length > 0) {
        const document = currentJacket?.documents[0];
        document.id = actionResult.documentId;
        setValue(`jackets.${productIndex}.documents.0`, document);
      } else {
        let documents: Document[] = [{ integrationKey: uuidv4() }];
        documents[0].id = actionResult.documentId;
        setValue(`jackets.${productIndex}.documents`, documents);
      }

      setPdfDocuments([
        getPdfDocument(actionResult.pdfUrl, actionResult.documentId),
      ]);
      setJacketProductState(prevState => ({ ...prevState, openPdfViewer: true }));
    }
  };
  const handleClosePdfViewer = () => {
    setJacketProductState(prevState => ({ ...prevState, openPdfViewer: false }));
  };

  const handleOnVoidJacket = () => {
    // Disable void button
    // Launch VoidCPLForm
    setBypassDefaultLoader(true);
    setJacketProductState(prevState => ({ ...prevState, openVoidCPLDialog: true, voidButtonDisabled: true }));
  };

  const handleOnReviseJacket = async (action: ReviseAction) => {
    isProcessingReviseRef.current = (action === ReviseAction.SubmitRevise);

    await onReviseAction(action);

    if (isProcessingReviseRef.current && enableSignatures) {
      setTimeout(() => {
        resetField(`jackets.${productIndex}.jacketSignatures`);
        isProcessingReviseRef.current = false;
      }, 100);
    }
  };

  const hasError = () => {
    // *** check http response status code !=200 error ***
    if (currentActionResult.current.error) return true;

    // *** check error message within scfile where with http response code of 200 ***

    // check file level and product level
    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;

      // check file level error
      if (
        scfile &&
        scfile.errorMessage &&
        (currentProductAction.current !== ProductAction.Void ||
          (currentProductAction.current === ProductAction.Void &&
            scfile.errorMessage.startsWith(`${ProductAction.Void}:`)))
      ) {
        return true;
      }

      // check product level error
      const errorJacket = scfile.jackets?.find(
        (jacket) =>
          jacket.integrationKey === currentJacketIntegrationKey.current &&
          jacket.errorMessage &&
          (currentProductAction.current !== ProductAction.Void ||
            (currentProductAction.current === ProductAction.Void &&
              jacket.errorMessage.startsWith(
                `${ProductAction.Void}:`
              )))
      );
      if (errorJacket) return true;
    }

    return false;
  };

  const handleError = () => {
    // *** check  http response status code !=200 error ***
    if (
      currentActionResult &&
      currentActionResult.current.error &&
      currentActionResult.current.error.response &&
      currentActionResult.current.error.response.status !== 200
    ) {
      const errorMessage =
        currentActionResult.current.error.response.data &&
          currentActionResult.current.error.response.data.errorMessage
          ? currentActionResult.current.error.response.data.errorMessage
          : currentActionResult.current.error.response.statusText;
      setJacketProductState(prevState => ({ ...prevState, errorMessage: errorMessage, openErrorDialog: true }));
      return;
    }

    // *** check error message within scfile where with http response code of 200 ***

    // Check file and product level error
    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;
      let errorMessage = "";

      // Check for File level error
      if (
        scfile &&
        scfile.errorMessage &&
        (currentProductAction.current !== ProductAction.Void ||
          (currentProductAction.current === ProductAction.Void &&
            scfile.errorMessage.startsWith(`${ProductAction.Void}:`)))
      ) {
        errorMessage += getErrorMessageByProductAction(
          currentProductAction.current,
          scfile.errorMessage
        );
      }

      // check product level error
      const errorJacket = scfile.jackets?.find(
        (jacket) =>
          jacket.integrationKey === currentJacketIntegrationKey.current &&
          jacket.errorMessage &&
          (currentProductAction.current !== ProductAction.Void ||
            (currentProductAction.current === ProductAction.Void &&
              jacket.errorMessage.startsWith(`${ProductAction.Void}:`)))
      );
      if (errorJacket && errorJacket.errorMessage) {
        if (errorMessage.length > 0) errorMessage += " ";
        errorMessage += getErrorMessageByProductAction(
          currentProductAction.current,
          errorJacket.errorMessage
        );
      }

      if (errorMessage.length > 0) {
        setJacketProductState(prevState => ({ ...prevState, errorMessage: errorMessage, openErrorDialog: true }));
        return;
      }
    }
  };

  const reloadFileWithProgress = async () => {
    openAutomaticProgressDialog();
    await reloadFile()
    resetPricingInitialValues();
    closeAutomaticProgressDialog();
  };

  const handleEndorsementVoidComplete = async () => {
    await reloadFileWithProgress();
  };

  const handleCloseVoidForm = async () => {
    // Enable back submit button
    setJacketProductState(prevState => ({
      ...prevState,
      voidSubmitButtonDisabled: false,
      openJacketIssueDialog: false,
      issueJacketRequestId: "",
      runJacketProgress: false,
    }));
    //setOpenVoidDialog(false);
    setTimeout(() => setBypassDefaultLoader(false), 1000);

    if (hasError()) {
      handleError();
      handleVoidCancel();
    } else {
      setJacketProductState(prevState => ({ ...prevState, openVoidCPLDialog: false }));
    }
  };

  const resetPricingInitialValues = () => {
    const pricingProducts = getValues("pricingProducts") as Array<PricingProduct>;
    const noIssuedProducts = !pricingProducts.length;
    if (noIssuedProducts) {
      setValue("pricing.isSimultaneousRate", false);
      setValue("pricing.isOverride", false);
      setValue("pricing.overrideReason", "");
      setValue("pricing.rateEffectiveDate", undefined);
      resetPricingFields({
        isSimultaneousRate: false,
        isOverride: false,
        overrideReason: "",
        rateEffectiveDate: undefined
      });
    }
  };

  const handleVoidCancel = () => {
    setValue(`jackets[${productIndex}].void.reason`, "");
    setJacketProductState(prevState => ({ ...prevState, openVoidCPLDialog: false, voidButtonDisabled: false }));
    setTimeout(() => setBypassDefaultLoader(false), 1000);
  };

  const showDelete = () => {
    if (shouldShowDeleteButtom) return false;
    if (currentJacketOrderStatusTypeCode === OrderStatusType.Pending)
      return true;
    return false;
  };

  const handleVoidSubmit = async () => {
    // Disable submit button
    setJacketProductState(prevState => ({ ...prevState, voidSubmitButtonDisabled: true }));

    let defaultVoidReason =
      voidReasons &&
        voidReasons[ProductType.Jacket] &&
        voidReasons[ProductType.Jacket].length > 0
        ? voidReasons[ProductType.Jacket][0].text
        : "";

    const voidJacket = getValues(`jackets.${productIndex}`);

    if (voidJacket.void && !voidJacket.void.reason)
      voidJacket.void.reason = defaultVoidReason;

    const convertedFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues(),
      undefined,
      MapActionType.ProductAction,
      ProductAction.Void,
      ProductType.Jacket,
      [voidJacket.integrationKey]
    );

    let receiptId = uuidv4();
    setJacketProductState(prevState => ({
      ...prevState,
      progressDialogProduct: ProductType.Jacket,
      progressDialogAction: ProductAction.Void,
      statusMessage: "Please wait while voiding your Jacket...",
      issueJacketRequestId: receiptId,
      openJacketIssueDialog: true,
      runJacketProgress: true,
    }));

    setPricingNotificationUpdated(false);
    currentJacketIntegrationKey.current = voidJacket.integrationKey;
    currentProductAction.current = ProductAction.Void;
    currentActionResult.current = await voidProduct(
      convertedFile,
      receiptId,
      ProductType.Jacket,
      voidJacket.integrationKey
    );
    setPricingNotificationUpdated(true);
  };

  const handleOnYesErrorDialog = () => {
    setJacketProductState(prevState => ({ ...prevState, errorMessage: "", openErrorDialog: true }));
  };

  const handleLiabilityAmountBlur = (value: string | null) => {
    if (value === null) return;

    const currentValue = convertToNumber(value);
    if (currentJacket?.pricingDetail?.liability === currentValue) return;
    handleRemittanceCalculation(value, productIndex, "Liability");

    if (
      currentJacketOrderStatusTypeCode === OrderStatusType.Pending &&
      currentJacket?.formDetails &&
      currentJacket?.formDetails.length > 0
    ) {
      const mortgageAmount = currentJacket?.formDetails?.find(
        (fd) => fd.formDetailName === JacketFormDetailName.MortgageAmount
      );
      const mortgageAmountIndex = currentJacket?.formDetails?.findIndex(
        (fd) => fd.formDetailName === JacketFormDetailName.MortgageAmount
      );
      if (mortgageAmount && !mortgageAmount.formDetailValue) {
        mortgageAmount.formDetailValue = value;
        setValue(
          `jackets.${productIndex}.formDetails.${mortgageAmountIndex}`,
          mortgageAmount
        );
      }
    }
  };

  const onReviseAction = async (reviseAction: ReviseAction) => {
    const JACKET = `jackets.${productIndex}`;
    switch (reviseAction) {
      case ReviseAction.OpenRevise: {
        const currentJacketFormValues: Jacket = getValues(JACKET);
        const originals: RevisionValues = {
          effectiveDate: currentJacketFormValues.effectiveDate,
          transCode: currentJacketFormValues.pricingDetail?.transCode,
          transCodeKey: currentJacketFormValues.pricingDetail?.transCodeKey,
          transCodeDescription:
            currentJacketFormValues.pricingDetail?.transCodeDescription,
          insuredNames: currentJacketFormValues.insuredNames,
          insuredNamesData: currentJacketFormValues.insuredNamesData,
          liability: currentJacketFormValues.pricingDetail?.liability,
          isSimultaneous: currentJacketFormValues.pricingDetail?.isSimultaneous,
          isLeasehold: currentJacketFormValues.pricingDetail?.isLeasehold,
          isReissue: currentJacketFormValues.pricingDetail?.isReissue,
          originalJacketDate: currentJacketFormValues.originalJacketDate,
          originalJacketNumber: currentJacketFormValues.originalJacketNumber,
          originalJacketType: currentJacketFormValues.originalJacketType,
          originalJacketState: currentJacketFormValues.originalJacketState,
          originalJacketLiability:
            currentJacketFormValues.originalJacketLiability,
          originalJacketUnderwriterTypeCode:
            currentJacketFormValues.originalJacketUnderwriterTypeCode,
          premium: currentJacketFormValues.newPremiumAmount,
          endorsements: currentJacketFormValues.endorsements,
          formDetails: currentJacketFormValues.formDetailsCopy,
          jacketSignatures: currentJacketFormValues.jacketSignatures,
          locationDisplayName: currentJacketFormValues.locationDisplayName,
        };
        setRevisingMode(true);
        isRevisingModeRef.current = true;
        originalValuesRef.current = cloneDeep(originals);
        setIssuedProductDirty(true);
        setDisableAddJacket(true, currentJacket?.integrationKey);
        const data = cloneDeep(currentJacket?.insuredNamesData);
        if (data) {
          data.hasInsuredNamesChanged = true;
          setValue(`jackets.${productIndex}.insuredNamesData`, data);
        }
        break;
      }
      case ReviseAction.CancelRevise: {
        const originalValues = originalValuesRef.current;
        const JACKET = `jackets.${productIndex}`;
        setValue(`${JACKET}.effectiveDate`, originalValues.effectiveDate);
        setValue(`${JACKET}.originalJacketDate`, originalValues.originalJacketDate);
        setValue(`${JACKET}.originalJacketNumber`, originalValues.originalJacketNumber);
        setValue(`${JACKET}.originalJacketType`, originalValues.originalJacketType);
        setValue(`${JACKET}.originalJacketLiability`, originalValues.originalJacketLiability);
        setValue(`${JACKET}.originalJacketState`, originalValues.originalJacketState);
        setValue(`${JACKET}.originalJacketUnderwriterTypeCode`, originalValues.originalJacketUnderwriterTypeCode);
        setValue(`${JACKET}.newPremiumAmount`, originalValues.premium);
        setValue(`${JACKET}.insuredNames`, originalValues.insuredNames);
        const originalInsuredNamesData = originalValues.insuredNamesData || {};
        setValue(`${JACKET}.insuredNamesData.hasInsuredNamesChanged`, originalInsuredNamesData.hasInsuredNamesChanged);
        originalValues.endorsements?.forEach((e: Endorsement, i: number) => { setValue(`${JACKET}.endorsements.${i}.effectiveDate`, e.effectiveDate); });
        originalValues.jacketSignatures?.forEach((s: JacketSignature, i: number) => {
          setValue(`${JACKET}.jacketSignatures.${i}`, { ...s });
        });
        originalValues.formDetails?.forEach((fd: JacketFormDetail, i: number) => {
          const newValue = { ...fd, formDetailValue: fd.formDetailValue || "" };
          setValue(`${JACKET}.formDetails.${i}`, newValue);
        });
        const pricingDetail = currentJacket.pricingDetail;
        if (pricingDetail) {
          setValue(`${JACKET}.pricingDetail.liability`, originalValues.liability || 0);
          setValue(`${JACKET}.pricingDetail.transCode`, originalValues.transCode || "");
          setValue(`${JACKET}.pricingDetail.transCodeKey`, originalValues.transCodeKey || "");
          setValue(`${JACKET}.pricingDetail.transCodeDescription`, originalValues.transCodeDescription || "");
          setValue(`${JACKET}.pricingDetail.isSimultaneous`, Boolean(originalValues.isSimultaneous));
          setValue(`${JACKET}.pricingDetail.isLeasehold`, pricingDetail?.isLeasehold || false);
          setValue(`${JACKET}.pricingDetail.isReissue`, pricingDetail?.isReissue || false);
          setValue(`${JACKET}.pricingDetail.actualFee`, pricingDetail.actualFee);
          setValue(`${JACKET}.pricingDetail.locationDisplayName`, originalValues.locationDisplayName);
        }
        setIssuedProductDirty(false);
        setDisableAddJacket(false);
        setRevisingMode(false);
        isRevisingModeRef.current = false;
        clearErrors(nameString(JACKET));
        break;
      }
      case ReviseAction.SubmitRevise:
        try {
          await updateManualPricing(
            currentJacket,
            productIndex,
            ProductAction.Revise
          );
          setFormInfo(JACKET, options.find((o) => o.value === currentJacketForm));
          await reviseJacket(currentJacket?.integrationKey);
          setIssuedProductDirty(false);
          setRevisingMode(false);
          isRevisingModeRef.current = false;
        } catch (e) {
          //setRevisingMode(false);
          //isRevisingModeRef.current = false;
          setJacketProductState(prevState => ({
            ...prevState,
            revising: true,
          }));
          setIssuedProductDirty(true);
          setDisableAddJacket(true, currentJacket?.integrationKey);
        }
        break;
    }
  };

  const isRevisable = () => {
    return currentJacketOrderStatusTypeCode === "ISSUED";
  };

  const setNotDefault = () => {
    const defaulted: boolean = getValues(`jackets.${productIndex}.isDefault`);
    if (!defaulted) return;

    setValue(`jackets.${productIndex}.isDefault`, false);
  };

  const setLocationNotDefault = () => {
    setNotDefault();
    setValue(`jackets.${productIndex}.isLocationDefault`, false);
    //When Agency Location is changed, the server needs to see that the endorsements should
    //also be updated with the new agency location
    currentJacket?.endorsements?.forEach((endorsement, index) => {
      setValue(`jackets.${productIndex}.endorsements.${index}.isDirty`, true);
      setValue(
        `jackets.${productIndex}.endorsements.${index}.productAction`,
        ProductAction.Revise
      );
    });
  };

  const handleJacketSignatureChange = () => {
    if (currentJacket?.jacketSignatures?.length) {
      setValue(
        `jackets.${productIndex}.jacketSignatures.0.productAction`,
        ProductAction.Revise
      );
    }
  };

  const updateManualPricing = async (
    jacket: Jacket,
    index: number,
    action: string
  ) => {
    if (
      isIntegratedPricing ||
      jacket.newPremiumAmount === 0 ||
      apiFormDetails.length === 0
    )
      return;

    let actualFee = convertToNumber(jacket.newPremiumAmount);
    let remittance = calculateRemittance(
      ProductType.Jacket,
      convertToNumber(jacket.pricingDetail?.liability),
      actualFee,
      agencyStateRemittance?.remitSplitType,
      convertToNumber(agencyStateRemittance?.remitFlatPct),
      convertToNumber(agencyStateRemittance?.remitPerThousand),
      convertToNumber(agencyStateRemittance?.minRemitPerThousand),
      convertToNumber(agencyStateRemittance?.cplRemittancePct)
    );

    let totalDue = 0;
    if (hasValue(remittance)) {
      totalDue = remittance || 0;
    }

    if (action === ProductAction.Issue) {
      setValue(
        getNameString(`jackets.${index}.pricingDetail.actualFee`),
        actualFee,
        {
          shouldDirty: true,
        }
      );

      setValue(
        getNameString(`jackets.${index}.pricingDetail.actualRemittance`),
        totalDue,
        {
          shouldDirty: true,
        }
      );

      setValue(
        getNameString(`jackets.${index}.pricingDetail.calculatedRemittance`),
        totalDue
      );

      let agentRetention = actualFee - totalDue;

      setValue(
        getNameString(`jackets.${index}.pricingDetail.actualRetention`),
        agentRetention,
        {
          shouldDirty: true,
        }
      );

      setValue(
        getNameString(`jackets.${index}.pricingDetail.calculatedRetention`),
        agentRetention
      );
    }
    if (action === ProductAction.Revise) {
      let agentRetention = actualFee - totalDue;

      if (jacket && jacket.pricingDetail) {
        jacket.pricingDetail.actualFee = actualFee;
        jacket.pricingDetail.actualRemittance = totalDue;
        jacket.pricingDetail.calculatedRemittance = totalDue;

        jacket.pricingDetail.actualRetention = agentRetention;
        jacket.pricingDetail.calculatedRetention = agentRetention;
      }

      const pricingProducts = getValues("pricingProducts");
      const pricingProductIndex = pricingProducts?.findIndex(
        (pp: any) =>
          pp.filePricingDetailId === jacket.pricingDetail?.filePricingDetailID
      );
      const productName = `pricingProducts.${pricingProductIndex}`;

      setValue(getNameString(`${productName}.actualFee`), actualFee, {
        shouldDirty: true,
      });
      setValue(getNameString(`${productName}.actualFeeUpdated`), true);

      setValue(getNameString(`${productName}.totalDue`), totalDue, {
        shouldDirty: true,
      });

      setValue(getNameString(`${productName}.totalDueInitial`), totalDue, {
        shouldDirty: true,
      });

      setValue(getNameString(`${productName}.calculatedTotalDue`), totalDue);

      setValue(getNameString(`${productName}.agentRetention`), agentRetention, {
        shouldDirty: true,
      });

      setValue(
        getNameString(`${productName}.agentRetentionInitial`),
        agentRetention,
        { shouldDirty: true }
      );

      setValue(
        getNameString(`${productName}.calculatedRetention`),
        agentRetention
      );
    }
  };

  const issuedDate = getIssuedDate();
  const tabDescription = getTabDescription();

  useEffect(() => {
    if (isFileLocked) {
      setDisableAddJacket(false);
      setRevisingMode(false);
    }
  }, [isFileLocked]);

  useEffect(() => {
    if (!isPdfViewerOpen) {
      setJacketProductState(prevState => ({ ...prevState, revising: false }));
    }
  }, [isPdfViewerOpen]);

  // show pdf immediately after issued
  useEffect(() => {
    if (
      jacketProductState.progressDialogProduct === ProductType.Jacket &&
      jacketProductState.progressDialogAction === ProductAction.Issue &&
      jacketProductState.openJacketIssueDialog === false &&
      documentId > 0
    ) {
      setJacketProductState(prevState => ({
        ...prevState,
        openPdfViewer: true,
        progressDialogAction: ProductAction.None,
        progressDialogProduct: "",
      }));
    }
  }, [
    documentId,
    jacketProductState.progressDialogProduct,
    jacketProductState.progressDialogAction,
    jacketProductState.openJacketIssueDialog,
  ]);

  useEffect(() => {
    setIssuedProductDirty(
      currentJacketOrderStatusTypeCode === OrderStatusType.Issued &&
      endorsementEditMode
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endorsementEditMode, currentJacketOrderStatusTypeCode]);

  useEffect(() => {
    if (jackets.length > 0 && currentJacket?.pricingDetail) {
      setValue(
        `jackets.${productIndex}.pricingDetail.isReissue`,
        !!currentJacket.pricingDetail?.isReissue
      );
    }
  }, [jackets.length]);

  useEffect(() => {
    return () => {
      if (isRevisingModeRef.current) {
        onReviseAction(ReviseAction.CancelRevise);
      }
    };
  }, []);

  return (
    <CollapsableFormSection
      index={productIndex}
      showDelete={showDelete()}
      onDelete={() => deleteJacket(productIndex)}
      label={tabDescription}
      title={tabTitle}
      isProductIssuePending={
        currentJacketOrderStatusTypeCode === OrderStatusType.Pending
      }
    >
      <>
        {/* Note to future me:  This dialog really needs to be at the top level since it applies to  */}
        {/* one or all of the jackets being issued */}
        {jacketProductState.openJacketIssueDialog && <ConfirmationDialogWithProgressbar
          autoClose
          title={jacketProductState.statusMessage}
          closeButtonText="Done! Click to Continue"
          isOpen={jacketProductState.openJacketIssueDialog}
          onClose={handleCloseVoidForm}
          requestId={jacketProductState.issueJacketRequestId}
          runProgress={jacketProductState.runJacketProgress}
        />}
        {isSerialNumberSectionVisible && (
          <SerialNumberSectionField
            issueDate={issuedDate}
            productIndex={productIndex}
            serialNumber={currentJacket?.productReferenceID}
            status={productStatusDisplay}
            productType={ProductType.Jacket}
          />
        )}
        <div className={`${classes.flexRow} ${classes.dateTypeForm}`}>
          <div>
            <EffectiveDateField
              name={`jackets.${productIndex}.effectiveDate`}
              issued={isSerialNumberSectionVisible}
              disabled={isDisabled()}
              onEffectiveDateChange={onEffectiveDateChange}
              defaultToToday={false}
              defaultValue={undefined}
            />
          </div>
          <JacketTypes
            schema={`jackets.${productIndex}`}
            disabled={isSerialNumberSectionVisible || revisionModeActive}
            isTransCodeRequired={currentJacket?.isTransCodeRequired}
          />
          <JacketForms
            schema={`jackets.${productIndex}`}
            issued={isSerialNumberSectionVisible}
            disabled={isFormDisabled}
            onChange={setNotDefault}
          />
          {isTypeAndFormSelected && showExpandedIssue && (
            <JacketSimultaneous
              show={isFormTypeValidForSimultaneous(currentJacketFormType)}
              index={productIndex}
              disabled={isDisabled()}
              revising={isRevisingModeRef.current}
              onChange={onSimultaneousChanged}
              defaultValue={currentJacket?.pricingDetail?.isSimultaneous}
            />
          )}
        </div>
        {isTypeAndFormSelected && (
          <>
            <div className={classes.flexRow}>
              <JacketTransCode
                schema={`jackets.${productIndex}`}
                disabled={isDisabled()}
                formType={currentJacket?.formType}
                isTransCodeRequired={currentJacket?.isTransCodeRequired}
              />
              <JacketInsuredNames
                schema={`jackets.${productIndex}`}
                disabled={isDisabled()}
                orderParties={orderParties}
                insuredNames={currentInsuredNames}
                insuredNamesData={currentInsuredNamesData}
                orderStatusTypeCode={orderStatusTypeCode}
                formType={currentJacketFormType}
                jacket={currentJacket}
              />
              <div className={classes.unsetWidth}>
                <CurrencyInputField
                  label="Liability Amount"
                  name={`jackets.${productIndex}.pricingDetail.liability`}
                  disabled={isDisabled()}
                  allowNegative={false}
                  onBlur={handleLiabilityAmountBlur}
                />
              </div>
              {showPremium &&
                <JacketPremium
                  jacketIndex={productIndex}
                  disabled={isDisabled() || isReadOnly}
                />
              }
              <JacketLeasehold
                schema={`jackets.${productIndex}`}
                formType={currentJacketFormType}
                disabled={isDisabled()}
                classes={classes}
              />
              {/* TODO: Remove this section after business confirms this should for sure be completely removed */}
              {/* {showExpandedIssue && (
                <div
                  id={`divjackets${productIndex}Reissue`}
                  className={`${isIntegratedPricing ? classes.hide : ""}`}
                >
                  <JacketReissue
                    productIndex={productIndex}
                    isReissue={Boolean(currentJacket?.pricingDetail?.isReissue)}
                    isIntegratedPricing={isIntegratedPricing}
                    disabled={isDisabled()}
                    isSerialNumberSectionVisible={isSerialNumberSectionVisible}
                    revising={isRevisingModeRef.current}
                  />
                </div>
              )}*/}
            </div>
            <JacketFormDetails
              name={`jackets.${productIndex}.formDetails`}
              jacket={currentJacket}
              isRevising={isRevisingModeRef.current}
              // buyerBorrowerParties={buyerBorrowerParties}
              lvFormDetails={apiFormDetails}
            />
            {currentJacket && currentJacketForm ? (
              <div className={classes.endorsementGrid}>
                <CombineEndorsementGrid
                  jacketIndex={productIndex}
                  jacket={currentJacket}
                  revisionModeActive={revisionModeActive}
                  displayIssuedModeGrid={displayIssuedModeGrid}
                  isRevisionMode={isRevisionMode}
                  endorsementEditMode={endorsementEditMode}
                  revising={isRevisingModeRef.current}
                  onVoidComplete={handleEndorsementVoidComplete}
                />
              </div>
            ) : null}
            <div className={classes.agencySignatureButtons}>
              <div className={`${classes.agencySignature} ${enableSignatures ? classes.fullWidth : ""}`}>
                <ProductAgencyLocationField
                  label="Agency Location"
                  productType={ProductType.Jacket}
                  schema={`jackets.${productIndex}`}
                  name={`jackets.${productIndex}.pricingDetail.locationDisplayName`}
                  disabled={isDisabled()}
                  onChange={setLocationNotDefault}
                  isRevising={isRevisingModeRef.current}
                />
                {enableSignatures && <SignatureField
                  schema={`jackets.${productIndex}`}
                  name={`jackets.${productIndex}.jacketSignatures.0`}
                  disabled={isDisabled()}
                  onChange={handleJacketSignatureChange}
                  isProcessing={isProcessingReviseRef.current}
                />}
              </div>
              <div className={classes.buttons}>
                <JacketButtonSection
                  onIssueJacket={handleOnIssueJacket}
                  onViewJacket={handleOnViewJacket}
                  onVoidJacket={handleOnVoidJacket}
                  onReviseAction={handleOnReviseJacket}
                  orderStatusTypeCode={orderStatusTypeCode}
                  isDisabled={isIssuable || isFileLocked}
                  isRevisable={isRevisable()}
                  revising={isRevisingModeRef.current}
                  issueButtonDisabled={revisionModeActive || issueButtonDisabled}
                  submitReviseDisabled={revisionModeActive || submitReviseDisabled}
                  voidButtonDisabled={revisionModeActive || jacketProductState.voidButtonDisabled}
                  {...{
                    handleEndorsementGridCancel,
                    handleEndorsementGridSubmit,
                    endorsementEditMode,
                    disableEndorsementSubmit,
                    productIndex,
                  }}
                />
              </div>
            </div>
          </>
        )}
        <PdfViewer
          isOpen={jacketProductState.openPdfViewer}
          onClose={handleClosePdfViewer}
          pdfDocuments={pdfDocuments} // getPdfDocuments()}
        />
        <VoidJacketForm
          productType={ProductType.Jacket}
          form={currentJacketForm}
          formType={currentJacketFormType}
          serialNumber={currentJacket?.productReferenceID || ""}
          effectiveDate={dtEffectiveDate}
          productIndex={productIndex}
          isOpen={jacketProductState.openVoidCPLDialog}
          onSubmit={handleVoidSubmit}
          onCancel={handleVoidCancel}
          submitButtonDisabled={jacketProductState.voidSubmitButtonDisabled}
        />
        <ErrorDialog
          isOpen={jacketProductState.openErrorDialog}
          confirmationMessage={jacketProductState.errorMessage}
          correlationId={jacketProductState.correlationId}
          onYes={handleOnYesErrorDialog}
        />
        <input
          type="hidden"
          {...register(`${orderStatusTypeCodeField}` as const)}
          defaultValue={currentJacketOrderStatusTypeCode}
        />
      </>
    </CollapsableFormSection >
  );
};

export default React.memo(JacketProduct, ((prevProps: Readonly<PropsWithChildren<JacketProductProps>>, nextProps: Readonly<PropsWithChildren<JacketProductProps>>) => {
  const previous = {
    productIndex: prevProps.productIndex,
    endorsementEditMode: prevProps.endorsementEditMode,
    disableEndorsementSubmit: prevProps.disableEndorsementSubmit,
    issueButtonDisabled: prevProps.issueButtonDisabled,
    submitReviseDisabled: prevProps.submitReviseDisabled,
    forceRevisionMode: prevProps.forceRevisionMode,
    isPdfViewerOpen: prevProps.isPdfViewerOpen,
    isRevisionMode: prevProps.isRevisionMode,
    orderParties: prevProps.orderParties,
  };
  const next = {
    productIndex: nextProps.productIndex,
    endorsementEditMode: nextProps.endorsementEditMode,
    disableEndorsementSubmit: nextProps.disableEndorsementSubmit,
    issueButtonDisabled: nextProps.issueButtonDisabled,
    submitReviseDisabled: nextProps.submitReviseDisabled,
    forceRevisionMode: nextProps.forceRevisionMode,
    isPdfViewerOpen: nextProps.isPdfViewerOpen,
    isRevisionMode: nextProps.isRevisionMode,
    orderParties: nextProps.orderParties,
  };
  return isEqual(previous, next);
}));
