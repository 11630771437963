import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, { 
  useEffect, 
  useState,
 } from "react";
import { useRegistrationLookup } from "utils/context/RegistrationContext";

interface StateFormFieldProps {
  isOpen: boolean;
  setSelectedStateObject: any;
  defaultPropertyState: any;
  methods: any;
}

export default function StateFormField({
  isOpen,
  setSelectedStateObject,
  defaultPropertyState,
  methods,
}: StateFormFieldProps) {
  const [{ agencyStates }, { getAgencyStates }] = useRegistrationLookup();
  const [statesWithDefaultOption, setStatesWithDefaultOption] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const { setValue } = methods;

  const handleAgencyChange = (_: React.ChangeEvent<{}>, value: SelectFieldOption | null) => {
    setSelectedState(value);
  };

  useEffect(() => {
    if (isOpen && agencyStates.length === 0) {
      getAgencyStates();
    }
  }, [isOpen, agencyStates, getAgencyStates]);

  useEffect(() => {
    setStatesWithDefaultOption(agencyStates);
  }, [agencyStates]);

  useEffect(() => {
    const selectedStateObject = statesWithDefaultOption.find((obj) => obj.value === selectedState);
    setSelectedStateObject(selectedStateObject);
  }, [handleAgencyChange]);

  useEffect(() => {
    if (defaultPropertyState) {
      setValue("state", defaultPropertyState);

      setSelectedState(defaultPropertyState);
    }
  }, []);

  return (
    <>
      <SelectField
        label="State"
        name={"state"}
        options={statesWithDefaultOption}
        onChange={handleAgencyChange}
      />
    </>
  );
}
