import { axiosSecuredInstance } from "configurations/axiosConfig";
import TransCode from "entities/UIModel/company/TransCode";
import { createHook, createStore } from "react-sweet-state";
import { JacketFormType, ProductType } from "utils/data/enum";

type State = {};
type Actions = typeof actions;

const actions = {
  getTransCodes:
    (formType: JacketFormType | string, stateCode: string, type: ProductType) =>
    async () => {
      try {
        const { data } = await axiosSecuredInstance.get<TransCode[]>(
          `/Company/getTransCodes?JacketFormType=${formType}&State=${stateCode}&TransCodeType=${type}`
        );

        return data;
      } catch (error) {
        console.error(error);
      }
    },
};

const Store = createStore<State, Actions>({
  initialState: {},
  actions,
  name: "transCodeContenxt",
});

const hook = createHook(Store);
export const useTransCodeContext = () => { return hook() };
