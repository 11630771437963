import DateFnsUtils from "@date-io/date-fns";
import { format } from 'date-fns-tz';

export class DateFnsTzUtils extends DateFnsUtils {
  timeZone: string;

  constructor({ timeZone, ...props }: any) {
    super(props);
    this.timeZone = timeZone;
  }

  format(date: string | number | Date, formatString: string) {
    return format(date, formatString, {
      timeZone: this.timeZone,
      locale: this.locale,
    });
  }
}
