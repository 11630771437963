import { Home } from "@mui/icons-material";
import FormSection from "controls/global/form-section";
import React,
{
  PropsWithChildren,
  ReactElement
} from "react";
import GradientIcon from "theme/icons/GradientIcon";
import {
  useSideNavProvider,
  ActionType
} from "pages/file/SideNavProvider";

type Props = {
  title: string;
  children: ReactElement;
  isError?: boolean;
};

const PropertyFormSection = ({
  title,
  children,
  isError,
}: Props): ReactElement => {
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const propertiesRef = React.useRef<HTMLElement>();

  React.useEffect(() => {
    if (sideNavState.items.get("Properties")?.childRef?.current) return;
    sideNavAction({
      type: ActionType.UPDATE,
      item: { name: "Properties", childRef: propertiesRef },
    });
  }, [sideNavState.items]);

  return (
    <FormSection
      navItemRef={propertiesRef}
      icon={<GradientIcon Icon={Home} />}
      isError={isError}
      title={title}
    >
      {children}
    </FormSection>
  );
};

export default React.memo(
  PropertyFormSection,
  (
    prevProps: Readonly<PropsWithChildren<Props>>,
    nextProps: Readonly<PropsWithChildren<Props>>
  ) => {
    return prevProps.isError === nextProps.isError;
  }
);
