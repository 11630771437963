import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import PayByCheckButton from "controls/global/pay-by-check/PayByCheckButton";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import React from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import {
  ReportAndPayAction,
  ReportType,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

interface Props {
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
  onReportingComplete: (reportingResult: ReportingResult) => void;
}

export default function PricingPayByCheck({
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
  onReportingComplete,
}: Props) {
  const [, { openDialog }] = useDialog();
  const [, { openProgressbar, closeProgresbar }] = useProgressbar();
  const [, { setPercentageComplete }] = useProcessStatusTracking();
  const { setValue, getValues } = useFormWrapper();
  const { isFileLocked, reloadFile } = useCreateFile();
  const { getPricingTotal, getPricingNewTotal } = usePricingCalculator();
  const [, { reportPricing }] = usePayment();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();

  let totalDueTotal = "";
  if (isFileLocked) {
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);
    totalDueTotal = pricingNewTotal.newTotalTotalDue;
  } else {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    totalDueTotal = pricingTotal.totalDueTotal;
  }

  const handlePayByCheckClick = () => {
    openDialog({
      title: "Pay By Check",
      content: (
        <ReportContentMessage
          mainText={`By clicking <strong>Submit</strong>, a payment sheet for this file in the amount of ${totalDueTotal} will be generated`}
        />
      ),
      onSubmitAction: onDialogSubmit,
    });
  };

  const onDialogSubmit = async () => {
    const requestId = uuidv4();
    const fileId = getValues("id");
    openProgressbar({
      requestId,
      title: "Please wait while processing Pay by Check...",
      onCloseAction: async () => {
        openAutomaticProgressDialog();
        await reloadFile(fileId);
        closeAutomaticProgressDialog();
      },
    });

    setValue("pricing.reportOptionTypeCode", ReportType.ReportPayCheck);

    const result = await reportPricing([fileId], requestId, ReportAndPayAction.PayByCheck);
    setPercentageComplete(requestId);
    closeProgresbar();
    onReportingComplete(result);
  };

  return (
    <PayByCheckButton
      disabled={false}
      onClick={handlePayByCheckClick}
    />
  );
}
