import { axiosAnonymousInstance } from "configurations/axiosConfig";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

interface FeatureConfiguration {
  enableSignatures: boolean;
  enableReportPay: boolean;
  enableHelpCenter: boolean;
  enableSpyMode: boolean;
  inMaintenanceMode: boolean;
  maintenanceMessage: string;
}

interface GeneralConfiguration {
  saDomain: string;
  userInputDebounceTimeMs: number;
  filesSearchTimeoutMs: number;
  filesSearchDefaultLookupDays: number;
}

interface State {
  featureConfig: FeatureConfiguration;
  generalConfig: GeneralConfiguration;
}

type Props = {
  children?: ReactNode;
};

const initialContext: State = {
  featureConfig: {
    enableSignatures: true,
    enableReportPay: true,
    enableHelpCenter: false,
    enableSpyMode: false,
    inMaintenanceMode: false,
    maintenanceMessage: "",
  },
  generalConfig: {
    saDomain: "",
    userInputDebounceTimeMs: 750,
    filesSearchTimeoutMs: 90000,
    filesSearchDefaultLookupDays: 10,
  },
};

const getFeatureConfig = async () => {
  const { data } = await axiosAnonymousInstance.get("/configuration/features");
  const retval: FeatureConfiguration = {
    enableSignatures: data.Signatures.Enabled,
    enableReportPay: data.ReportPay.Enabled,
    enableHelpCenter: data.HelpCenterEnabled,
    enableSpyMode: data.SpyMode,
    inMaintenanceMode: data.InMaintenanceMode,
    maintenanceMessage: data.MaintenanceMessage,
  };
  return retval;
};

const getGeneralConfig = async () => {
  const { data } = await axiosAnonymousInstance.get("/configuration/generalConfig");
  const retval: GeneralConfiguration = {
    saDomain: data.SADomain,
    userInputDebounceTimeMs: Number(data.UserInputDebounceTimeMs),
    filesSearchTimeoutMs: Number(data.FilesSearchTimeoutMs),
    filesSearchDefaultLookupDays: Number(data.FilesSearchDefaultLookupDays),
  };
  return retval;
};

const ConfigContext = createContext<State>(initialContext);

const ConfigContextProvider = ({ children }: Props) => {
  const [state, setState] = useState<State>(initialContext);

  useEffect(() => {
    const fetchConfigs = async () => {
      const featureConfigData = await getFeatureConfig();
      const generalConfigData = await getGeneralConfig();

      setState({
        featureConfig: featureConfigData,
        generalConfig: generalConfigData,
      });
    };

    fetchConfigs();
  }, []);

  const contextValue = useMemo(() => state, [state]);

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
  const context = React.useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfigContext must be used within a <ConfigContextProvider>.");
  }
  return context;
};

export default ConfigContextProvider;
