import { styled } from "@material-ui/core";
import SelectField from "controls/global/select-field";
import TextInputField from "controls/global/text-input-field";
import { getTransCodeKey } from "pages/file/utils/products/jacket";
import React, {
  PropsWithChildren,
  useEffect,
} from "react";
import { useEndorsementActions } from "utils/context/CompanyEndorsementContext";
import useConfig from "utils/custom-hooks/useConfig";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useForms from "utils/custom-hooks/useForms";
import {
  PricingConfigKey,
  ProductType,
} from "utils/data/enum";

type Props = {
  schema: string;
  issued: boolean;
  disabled: boolean;
  onChange?: (selectedForm: string) => void;
};

const StyledSelectField = styled(SelectField)({
  flexGrow: 1,
});

const StyledTextInputField = styled(TextInputField)({
  flexGrow: 1,
});

const JacketForms = ({ schema, issued, disabled, onChange }: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const { clearFormInfo, setFormInfo, clearJacketFormData } = useCreateFile();
  const { getPricingConfig } = useConfig();
  const [
    currentFormType,
    currentFormId,
    companyId,
    stateAbbr,
    currentFormValue,
    currentEffectiveDate,
  ] = getValues([
    `${schema}.formType`,
    `${schema}.formID`,
    "agency.id",
    "properties.0.state.abbr",
    `${schema}.form`,
    `${schema}.effectiveDate`,
  ]);

  const { options } = useForms(
    ProductType.Jacket,
    currentFormType,
    currentEffectiveDate,
    issued || disabled
  );

  const [, { getEndorsements }] = useEndorsementActions();

  const handleOnChange = (_: any, value: any) => {
    setValue(`${schema}.form`, value);
    setValue(`${schema}.formDetails`, []);
    setValue(`${schema}.pricingDetail.actualFee`, 0.0);
    setValue(`${schema}.endorsements`, []);

    const jacketForm = options.filter((o) => o.value === value)[0];
    getEndorsements(
      companyId,
      jacketForm.formType,
      stateAbbr,
      jacketForm.formID
    );
    setTransCode(jacketForm.formType, value);
    onChange?.(value);
  };

  const setTransCode = (selectedType: string, selectedForm: string) => {
    let defaultTransCode: string = "";
    const transCodeConfig = getPricingConfig(
      PricingConfigKey.JacketTransCodeDefault
    );

    if (transCodeConfig && transCodeConfig.configValue) {
      const { form, formType, configValue } = transCodeConfig;
      const match: boolean = formType === selectedType && form === selectedForm;

      if (match) {
        defaultTransCode = configValue;
      }
    }

    if (defaultTransCode) {
      const { code, description } = getTransCodeKey(defaultTransCode);
      setValue(`${schema}.pricingDetail.transCodeKey`, defaultTransCode);
      setValue(`${schema}.pricingDetail.transCode`, code);
      setValue(`${schema}.pricingDetail.transCodeDescription`, description);
      return;
    }

    setValue(`${schema}.pricingDetail.transCodeKey`, "");
    setValue(`${schema}.pricingDetail.transCode`, "");
    setValue(`${schema}.pricingDetail.transCodeDescription`, "");
  };

  // Default the form IF IT ONLY has one form (option)
  useEffect(() => {
    if (options.length !== 1) return;

    const { value } = options[0];
    const matchingOption = options.filter((o) => o.value === value)[0];
    if (matchingOption) {
      getEndorsements(companyId, matchingOption.formType, stateAbbr, matchingOption.formID);
    }
    setValue(`${schema}.form`, value);
    setFormInfo(schema, matchingOption);
  }, [companyId, getEndorsements, options, schema, setValue, stateAbbr]);

  // Get endorsements
  useEffect(() => {
    if (!currentFormType) return;
    getEndorsements(companyId, currentFormType, stateAbbr, currentFormId);
  }, [currentFormType, currentFormId]); // rlo 3/24/2023 -- FormID might be updated during the Revise, so it needs to get endorsements again


  useEffect(() => {
    if (!currentFormValue || options?.length === 0) {
      return;
    }

    const matchingOption = options?.find((op) => op.value === currentFormValue);
    setFormInfo(schema, matchingOption);

    if (!matchingOption) {
      if (!issued) {
        setValue(`${schema}.form`, "");
        clearFormInfo(schema);
        clearJacketFormData(schema, currentFormType);
      }
    }

  }, [currentFormValue, disabled, options, schema, setFormInfo]);

  useEffect(() => {
    if (disabled) {
      const formValue = getValues(`${schema}.form`);
      if (!issued && formValue) {
        setValue(`${schema}.form`, "");
        clearFormInfo(schema);
        clearJacketFormData(schema, currentFormType);
      }
    }
  }, [disabled]);

  // Return as TextBox for Issued Jacket
  return issued
    ? <StyledTextInputField
      label="Form"
      name={`${schema}.form`}
      disabled
    />
    : <StyledSelectField
      label="Form"
      name={`${schema}.form`}
      options={options}
      disabled={disabled}
      onChange={handleOnChange}
      autosetValue={false}
    />;
};

export default React.memo(JacketForms, ((prevProps: Readonly<PropsWithChildren<Props>>, nextProps: Readonly<PropsWithChildren<Props>>) => {
  return prevProps.schema === nextProps.schema &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.issued === nextProps.issued;
}));
