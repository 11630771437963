import React from "react";
import Styled from "styled-components";
import {
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import AgencyField from "./AgencyField";
import AgencyLocationField from "./AgencyLocationField";
import AgencyStateField from "./AgencyStateField";
import LevelField from "./LevelField";

const FieldTitle = Styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: ${fontWeight.normal1};
  font-size: ${fontSize.xlarge0};
  margin-top: ${margin.xlarge1};
  padding: ${padding.zero} ${padding.small} 
`;

const DefaultInfoWrapper = Styled.div`
  padding: ${padding.zero} ${padding.small} 
`;

const DefaultInfo = () => {
  return (
    <DefaultInfoWrapper>
      <FieldTitle>Choose where you’d like the signature to default.</FieldTitle>
      <AgencyField />
      <AgencyStateField />
      <AgencyLocationField />
      <LevelField />
    </DefaultInfoWrapper>
  );
};

export default DefaultInfo;
