import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "theme/defaultStyle";

const StewartSelect = withStyles((theme) => ({
  root: {
    "& div.MuiSelect-root": theme.custom.textField.inputOutlined,
    "& div.MuiOutlinedInput-root path": { fill: colors.blue01 },
    "& div.MuiSelect-root.Mui-disabled":
      theme.custom.textField.inputOutlinedDisabled,
    "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
    "& div.Mui-error path": { fill: colors.red01 },
  },
}))(TextField);

export default StewartSelect;
