import { LocalAtm } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import HelpIcon from "@mui/icons-material/Help";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TopicIcon from "@mui/icons-material/Topic";
import { ReactElement } from "react";
import BallotIcon from "@mui/icons-material/Ballot";
import ComingSoonIcon from "theme/images/icons/rollover/coming-soon-sm-red.svg";
import CreateFileIconHover from "theme/images/icons/rollover/createfile-shopping-bag-sm-red.svg";
import FilesIconHover from "theme/images/icons/rollover/files-folders-sm-red.svg";
import HelpCenterIconHover from "theme/images/icons/rollover/question-circle-lg-red.svg";
import ReportsIcon from "theme/images/icons/base/reports-file-alt-Connect-sm-gray.svg";
import { ensure } from "utils/validations";

enum menuNames {
  lblMenuComingSoon = "Coming soon",
  lblMenuCreateFile = "Create File",
  lblMenuFiles = "Files",
  lblMenuHelpCenter = "Help Center",
  lblMenuHome = "Home",
  lblMenuOrderProducts = "Order Products",
  lblMenuPayments = "Payments",
  lblMenuReports = "Reports",
  lblMenuStewartResources = "Stewart Resources",
}

export type MenuDefinition = {
  centerMenuId: string;
  menuId: string;
  menuName: string;
  url: string;
  disabled: boolean;
  rolloverIconUrl: string;
  isVisibleInHeader: boolean;
  type?: "REPORTPAY";
  icon?: ReactElement | any;
  headerIcon?: ReactElement | any;
};

const getUrlFromMenuName = (menuName: string) =>
  `/${menuName.toLocaleLowerCase().replace(" ", "-")}`;

const menuDefinitions: Array<MenuDefinition> = [
  {
    centerMenuId: "centerMenuOrderProducts",
    menuId: "menuOrderProducts",
    menuName: menuNames.lblMenuCreateFile,
    url: "/file",
    disabled: false,
    rolloverIconUrl: CreateFileIconHover,
    isVisibleInHeader: true,
    icon: NoteAddIcon,
  },
  {
    centerMenuId: "centerMenuMyFiles",
    menuId: "menuMyFiles",
    menuName: menuNames.lblMenuFiles,
    url: getUrlFromMenuName(menuNames.lblMenuFiles),
    disabled: false,
    rolloverIconUrl: FilesIconHover,
    isVisibleInHeader: true,
    icon: DescriptionIcon,
    headerIcon: FolderIcon,
  },
  {
    centerMenuId: "centerMenuHelpCenter",
    menuId: "menuHelpCenter",
    menuName: menuNames.lblMenuHelpCenter,
    url: "/help-center",
    disabled: false,
    rolloverIconUrl: HelpCenterIconHover,
    isVisibleInHeader: false,
    icon: HelpIcon,
  },
  {
    centerMenuId: "centerMenuReports",
    menuId: "menuReports",
    menuName: menuNames.lblMenuReports,
    url: getUrlFromMenuName(menuNames.lblMenuReports),
    disabled: false,
    rolloverIconUrl: ReportsIcon,
    isVisibleInHeader: true,
    icon: TopicIcon,
  },
  {
    centerMenuId: "centerMenuMyPayments",
    menuId: "menuMyPayments",
    menuName: menuNames.lblMenuPayments,
    url: "/reportpay",
    disabled: true,
    rolloverIconUrl: ComingSoonIcon,
    isVisibleInHeader: true,
    type: "REPORTPAY",
    icon: MonetizationOnIcon,
    headerIcon: LocalAtm,
  },
  {
    centerMenuId: "centerStewartResources",
    menuId: "menuStewartResources",
    menuName: menuNames.lblMenuStewartResources,
    url: "/resources",
    disabled: false,
    rolloverIconUrl: "ComingSoonIcon",
    isVisibleInHeader: true,
    icon: BallotIcon,
  },
];

const getUrlByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName,
    ),
  ).url;

const getMenuIdByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName,
    ),
  ).menuId;

const getCenterMenuIdByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName,
    ),
  ).centerMenuId;

export {
  getCenterMenuIdByMenuName,
  getMenuIdByMenuName,
  getUrlByMenuName,
  menuDefinitions,
  menuNames,
};
