import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { ContractDate } from "entities/ApiModel/ContractDate";
import { Agency } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  agencies: Array<SelectFieldOption>;
  axiosCancelToken?: CancelTokenSource;
  isCompanyLoading: boolean;
  error: boolean;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const setAgencies =
  (data: any) =>
    ({ setState }: StoreApi) => {
      let agencies: Array<SelectFieldOption> = [];

      if (data && data.length > 0) {
        agencies = data.map((a: any) => {
          const agency: SelectFieldOption = {
            text: a.companyName,
            value: a.companyID,
            activeContractID: a.activeContractID,
            legacyID: a.legacyID,
          };
          return agency;
        });
        agencies = agencies.sort((a, b) =>
          a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
        );
      }

      setState({
        agencies: agencies,
        isCompanyLoading: false,
      });
    };

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

const formatDate = (date: Date) => {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join("/");
};

const actions = {
  getComapanyContractEffectiveDates:
    (companyID: string, productType: string) => async () => {
      const cachedKey = `CompanyContractEffectiveDatesCachedKey-${companyID}~${productType}`;
      const cachedData = sessionStorage.getItem(cachedKey);

      if (cachedData) {
        return <ContractDate[]>JSON.parse(cachedData);
      }
      else {
        try {
          const { data } = await axiosSecuredInstance.get<ContractDate[]>(
            `Company/GetCompanyContractEffectiveDates?CompanyID=${companyID}&ProductTypeCode=${productType}&EffectiveDate=${formatDate(new Date())}`
          );
          sessionStorage.setItem(cachedKey, JSON.stringify(data));
          return data;
        } catch (error) {
          console.error(error);
        }
      }
    },
  getAgencies:
    (agencyField?: Agency) =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        setState({ isCompanyLoading: true, error: false });

        try {
          if (agencyField && agencyField.id && agencyField.name) {
            dispatch(
              setAgencies([
                {
                  companyName: agencyField.name,
                  companyID: agencyField.id,
                  activeContractID: agencyField.activeContractID,
                },
              ])
            );
            return;
          }

          const token = getState().axiosCancelToken;
          if (token)
            token.cancel("GetCompanyListByUser was cancelled due to new request");

          const newCancelToken = cancelToken.source();
          setState({ axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.get(
            `/Company/GetCompanyListByUser`,
            { cancelToken: newCancelToken.token }
          );

          dispatch(setAgencies(data));
        } catch (error) {
          console.error("ERROR: useCompanyContext.", error);
          setState({
            agencies: [],
            error: true,
            isCompanyLoading: false,
          });
        }
      },
  resetAgencies:
    () =>
      async ({ dispatch }: StoreApi) => {        
        return dispatch(setAgencies([]));
      },

  updateAgencies:
    (companies: Array<any>) =>
      async ({ dispatch }: StoreApi) => {
        dispatch(setAgencies(companies));
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    agencies: [],
    isCompanyLoading: false,
    error: false,
  },
  actions,
  name: "CompanyContext",
});

const hook = createHook(Store);
const useCompanyContext = () => {
  return hook();
};
const useCompanyContextActions = createHook(Store, {
  selector: null,
});

export { useCompanyContext, useCompanyContextActions };
