import React from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { capitalize, formatDate } from "utils/shared";
import LightTooltip from "controls/global/light-tooltip";
import { colors } from "theme/defaultStyle";

type Props = {
  hidden: boolean;
  endorsementID: number | undefined;
  statusTypeCode: string | undefined;
  voidDate: Date | null | undefined;
};

const useStyles = makeStyles({
  cell: {
    width: 80,
    padding: "0px !important",
    borderRight: `1px solid ${colors.grey04}`,
  },
});

const StyleCellText = styled(Typography)({
  fontSize: "18px",
  color: "#626262",
  padding: "10px 0px 5px 10px",
});

export default function EndorsementStatusCell({
  hidden,
  endorsementID,
  statusTypeCode,
  voidDate,
}: Props) {
  const classes = useStyles();

  const status =
    !endorsementID || endorsementID === 0 ? "" : capitalize(statusTypeCode);

  if (voidDate) {
    return (
      <LightTooltip
        title={`Voided ${formatDate(voidDate)}`}
        placement="top"
        arrow
      >
        <TableCell classes={{ sizeSmall: classes.cell }} hidden={hidden}>
          <StyleCellText>{status}</StyleCellText>
        </TableCell>
      </LightTooltip>
    );
  }

  return (
    <TableCell classes={{ sizeSmall: classes.cell }} hidden={hidden}>
      <StyleCellText>{status}</StyleCellText>
    </TableCell>
  );
}
