import React, { ReactNode } from "react";
import { NotificationType } from "utils/data/enum";

const ERROR = "#dc3545";
const WARNING = "#ff991f";

type Props = {
  children?: ReactNode;
};

type ActionTypes = "INITIALIZE" | "CHANGE" | "UPDATE" | "RESET";

type Item = {
  id: number;
  value: number;
  code: string | undefined;
};

type ItemValue = {
  value: number;
  code: string;
  updated: boolean;
  enableField: boolean;
  resetField: boolean;
  displayFieldIcon: boolean;
  fieldIconColor: string;
  outlineField: boolean;
  outlineColor: string;
};

type State = {
  hasMessageError: boolean;
  codes: Map<string, string>;
  currentCode: string;
  items: Map<number, ItemValue>;
  type: NotificationType;
  message: string;
  messageIconColor: string;
  displayMessage: boolean;
  displayFieldIcon: boolean;
  calculatedFeeErrorCodes: string[];
};

type Action = {
  type: ActionTypes;
  item?: Item;
  products?: any[];
};

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const DEFAULT = {
  hasMessageError: false,
  currentCode: "",
  codes: new Map<string, string>(),
  items: new Map<number, ItemValue>(),
  type: NotificationType.Info,
  message: "",
  displayMessage: false,
  displayFieldIcon: false,
  messageIconColor: "",
  calculatedFeeErrorCodes: ["001", "003", "004"], // set Calculated fees = Actual values for these error codes
};

const DEFAULT_ITEM_VALUE = {
  value: 0,
  code: "",
  updated: false,
  enableField: false,
  resetField: false,
  displayFieldIcon: false,
  fieldIconColor: "",
  outlineField: false,
  outlineColor: "",
};

const ErrorMessageContext = React.createContext<Context | undefined>(undefined);

const errorMessageSplitter = (string: string = "") => {
  return {
    code: string.substring(0, 3),
    message: string.substring(6, string.length),
    appendant: string.substring(string.length - 6),
  };
};

const isDirty = (items: Map<number, ItemValue>, code = "000") => {
  for (const value of items.values()) {
    if (!value.updated && value.code === code) return true;
  }
  return false;
};

const getCodes = (items: any[]) => {
  const codes = new Map<string, string>();
  for (let item of items) {
    const { code, message } = errorMessageSplitter(item.lastErrorMessage);
    if (message.includes(" - Orange"))
      codes.set(code, message.replace(" - Orange", ""));
    else codes.set(code, message);
  }
  return codes;
};

const getInitialFields = (items: any[]) => {
  const hashTable = new Map<number, ItemValue>();

  items.forEach((item: any) => {
    const { code, appendant } = errorMessageSplitter(item.lastErrorMessage);
    let itemValue;
    switch (code) {
      case "001": {
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: true,
          resetField: true,
          displayFieldIcon: true,
          outlineField: true,
          fieldIconColor: ERROR,
          outlineColor: ERROR,
        };
        break;
      }
      case "002": {
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: false,
          resetField: false,
          displayFieldIcon: true,
          fieldIconColor: WARNING,
          outlineField: false,
          outlineColor: "",
        };
        break;
      }
      case "003": {
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: true,
          resetField: true,
        };
        break;
      }
      case "004": {
        if (appendant === "Orange") {
          itemValue = {
            ...DEFAULT_ITEM_VALUE,
            code,
            updated: true,
            enableField: true,
            resetField: false,
            displayFieldIcon: true,
            outlineField: true,
            fieldIconColor: WARNING,
            outlineColor: WARNING,
          };
          break;
        }
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: true,
          resetField: true,
          displayFieldIcon: true,
          outlineField: true,
          fieldIconColor: ERROR,
          outlineColor: ERROR,
        };
        break;
      }
      case "005": {
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: true,
          resetField: false,
        };
        break;
      }
      case "006": {
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
          code,
          enableField: true,
          resetField: true,
          displayFieldIcon: true,
          outlineField: true,
          fieldIconColor: ERROR,
          outlineColor: ERROR,
        };
        break;
      }
      default:
        itemValue = {
          ...DEFAULT_ITEM_VALUE,
        };
    }
    hashTable.set(item.productItemId, itemValue);
  });

  return hashTable;
};

const getInitialMessage = (initialState: State) => {
  const { codes, items } = initialState;

  if (codes.has("001")) {
    initialState = {
      ...initialState,
      currentCode: "001",
      message: codes.get("001") || "",
      type: NotificationType.Error,
      displayMessage: true,
      messageIconColor: ERROR,
      displayFieldIcon: true,
    };
    return initialState;
  }

  if (codes.has("004")) {
    initialState = {
      ...initialState,
      currentCode: "004",
      message: codes.get("004") || "",
      type: NotificationType.Error,
      displayMessage: true,
      messageIconColor: ERROR,
      displayFieldIcon: true,
    };

    if (!isDirty(items, "004")) {
      initialState = {
        ...initialState,
        type: NotificationType.Info,
        messageIconColor: WARNING,
      };
    }

    return initialState;
  }

  if (codes.has("002")) {
    initialState = {
      ...initialState,
      currentCode: "002",
      message: codes.get("002") || "",
      type: NotificationType.Info,
      displayMessage: true,
      messageIconColor: WARNING,
      displayFieldIcon: true,
    };

    return initialState;
  }

  if (codes.has("006")) {
    initialState = {
      ...initialState,
      currentCode: "006",
      message: codes.get("006") || "",
      type: NotificationType.Error,
      displayMessage: true,
      messageIconColor: ERROR,
      displayFieldIcon: true,
    };
    return initialState;
  }

  initialState = {
    ...initialState,
    currentCode: "003",
    message: "",
    type: NotificationType.Info,
    displayMessage: false,
    messageIconColor: "",
    displayFieldIcon: false,
  };

  return initialState;
};

const getUpdatedMessage = (currentState: State, itemCode: any) => {
  const { codes, currentCode, items } = currentState;

  if (currentCode !== itemCode) return;

  if (!isDirty(items, currentCode)) {
    // When currentCode is 001
    if (currentCode === "001") {
      // When having 004 code
      if (codes.has("004")) {
        return {
          type: isDirty(items, "004")
            ? NotificationType.Error
            : NotificationType.Info,
          currentCode: "004",
          message: codes.get("004") || "",
          displayMessage: true,
          messageIconColor: isDirty(items, "004") ? ERROR : WARNING,
        };
      }

      // When having 002 code
      if (codes.has("002")) {
        return {
          type: NotificationType.Info,
          currentCode: "002",
          message: codes.get("002") || "",
          displayMessage: true,
          messageIconColor: WARNING,
        };
      }

      return {
        type: NotificationType.Info,
        message: "",
        displayMessage: false,
        messageIconColor: "",
      };
    }

    // When currentCode is 004
    if (currentCode === "004") {
      return {
        type: NotificationType.Info,
        messageIconColor: WARNING,
      };
    }

    // When currentCode is 006
    if (currentCode === "006") {
      return {
        type: NotificationType.Info,
        message: "",
        displayMessage: false,
        messageIconColor: "",
      };
    }

    // When currentCode is 002 or 003
    return currentState;
  }

  return currentState;
};

const getUpdatedFields = (currentState: State, id: number) => {
  const currentItemValue = currentState.items.get(id);
  let updatedValue: ItemValue;
  if (currentItemValue) {
    switch (currentItemValue.code) {
      case "001": {
        updatedValue = {
          ...currentItemValue,
          displayFieldIcon: false,
          outlineField: false,
          fieldIconColor: "",
          outlineColor: "",
        };
        break;
      }
      case "002":
      case "003": {
        updatedValue = {
          ...currentItemValue,
        };
        break;
      }
      case "004": {
        updatedValue = {
          ...currentItemValue,
          displayFieldIcon: true,
          outlineField: true,
          fieldIconColor: WARNING,
          outlineColor: WARNING,
        };
        break;
      }
      case "006": {
        updatedValue = {
          ...currentItemValue,
          displayFieldIcon: false,
          outlineField: false,
          fieldIconColor: "",
          outlineColor: "",
        };
        break;
      }
      default:
        updatedValue = {
          ...currentItemValue,
        };
    }
    currentState.items.set(id, updatedValue);
  }
  return currentState.items;
};

const updater = (currentState: State, item: Item) => {
  return {
    ...currentState,
    items: getUpdatedFields(currentState, item.id),
    ...getUpdatedMessage(currentState, item.code),
  };
};

const changer = (currentState: State, item: Item) => {
  const currentItemValue = currentState.items.get(item.id);
  if (currentItemValue)
    currentState.items.set(item.id, {
      ...currentItemValue,
      updated: true,
      value: item.value,
    });
  return currentState;
};

const initializer = (currentState: State, products: any) => {
  if (!products || products.length === 0) return currentState;

  const errorItems: any = [];
  products.forEach((product: any) => {
    if (product.productItems) {
      product.productItems.forEach((item: any) => {
        if (item.lastErrorMessage) errorItems.push(item);
      });
    }
  });

  if (errorItems.length === 0) return currentState;

  return getInitialMessage({
    ...currentState,
    codes: getCodes(errorItems),
    items: getInitialFields(errorItems),
    hasMessageError: true,
  });
};

function EndorsementErrorMessageReducer(state: State, action: Action): State {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...initializer(state, action.products),
      };
    case "CHANGE": {
      if (action.item)
        return {
          ...changer(state, action.item),
        };
      return {
        ...state,
      };
    }
    case "UPDATE": {
      if (action.item)
        return {
          ...updater(state, action.item),
        };
      return {
        ...state,
      };
    }
    case "RESET":
      return {
        ...DEFAULT,
      };
    default:
      throw new Error(`Not supported action type: ${action.type}`);
  }
}

function EndorsementErrorMessageProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(EndorsementErrorMessageReducer, {
    ...DEFAULT,
  });
  return (
    <ErrorMessageContext.Provider value={{ state, dispatch }}>
      {children}
    </ErrorMessageContext.Provider>
  );
}

function useEndorsementErrorMessage() {
  const context = React.useContext(ErrorMessageContext);
  if (!context) {
    throw new Error(
      "useEndorsementErrorMessage must be used within a <EndorsementErrorMessageProvider>"
    );
  }

  return context;
}

export { EndorsementErrorMessageProvider, useEndorsementErrorMessage };
