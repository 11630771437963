import { styled } from "@material-ui/core";
import SelectField from "controls/global/select-field";
import {
  getTransCodeKey,
} from "pages/file/utils/products/jacket";
import React from "react";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useProductOption, {
  toProductOption,
} from "utils/custom-hooks/useProductOption";
import {
  PricingConfigKey,
  ProductType,
} from "utils/data/enum";

type Props = {
  schema: string;
  disabled?: boolean;
  isTransCodeRequired?: boolean;
  onChange?: (formType: string) => void;
};

const StyledSelectField = styled(SelectField)({
  minWidth: "150px",
});

const JacketTypes = ({
  schema,
  isTransCodeRequired = false,
  disabled = false,
  onChange,
}: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const { clearJacketFormData } = useCreateFile();
  const { getProductOptionsByProductType, checkTransCodeRequired } =
    useProductOption();
  const [{ pricingConfigs }] = usePricingConfig();
  const productOptions = getProductOptionsByProductType(ProductType.Jacket);

  const handleFormTypeSelect = (_: React.ChangeEvent<any>, value: any) => {
    clearJacketFormData(schema, value);

    setValue(`${schema}.formType`, value);
    setValue(`${schema}.isTransCodeRequired`, checkTransCodeRequired(value));

    checkDefaultFormConfig(value);
    onChange && onChange(value);
  };

  const checkDefaultFormConfig = (formType: string) => {
    const defaultFormTypeConfig = pricingConfigs.find(
      (c) =>
        c.configKey === PricingConfigKey.JacketFormDefault &&
        c.formType === formType
    );

    if (!defaultFormTypeConfig || !defaultFormTypeConfig.configValue) {
      setValue(`${schema}.form`, "");
      return;
    }

    setValue(`${schema}.form`, defaultFormTypeConfig.configValue);

    const defaultTransCodeConfig = pricingConfigs.find(
      (c) =>
        c.configKey === PricingConfigKey.JacketTransCodeDefault &&
        c.formType === formType &&
        c.form === defaultFormTypeConfig.configValue
    );
    if (!defaultTransCodeConfig || !defaultTransCodeConfig.configValue) return;

    const { code, description } = getTransCodeKey(
      defaultTransCodeConfig.configValue
    );
    setValue(
      `${schema}.pricingDetail.transCodeKey`,
      defaultTransCodeConfig.configValue
    );
    setValue(`${schema}.pricingDetail.transCode`, code);
    setValue(`${schema}.pricingDetail.transCodeDescription`, description);
  };

  React.useEffect(() => {
    const currentFormType = getValues(`${schema}.formType`);
    if (!currentFormType) return;

    const isRequired = checkTransCodeRequired(currentFormType);
    if (isTransCodeRequired === isRequired) return;

    setValue(`${schema}.isTransCodeRequired`, isRequired);
  }, [
    checkTransCodeRequired,
    getValues,
    isTransCodeRequired,
    schema,
    setValue,
  ]);

  return (
    <StyledSelectField
      label="Type"
      name={`${schema}.formType`}
      options={toProductOption(productOptions)}
      onChange={handleFormTypeSelect}
      disabled={disabled}
      autosetValue={false}
    />
  );
};

export default JacketTypes;
