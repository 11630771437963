import BallotIcon from "./BallotIcon";
import PageSection from "controls/global/page-section";
import React from "react";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import useTitle from "utils/custom-hooks/useTitle";
import { 
  Box, 
  Grid, 
  styled, 
  Typography ,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";

const StyledBoxContainer = styled(Box)({
  position: "relative",
});

const HeadingContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  marginBottom: margin.xsmall,
});

const ListPrimaryHeading = styled(Typography)({
  color: colors.blue05,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.bold2,
  paddingLeft: padding.small2,
});

const ListSecondaryHeading = styled(Typography)({
  color: colors.grey11,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  paddingLeft: padding.small2,
});

const StewartResources = () => {
  useTitle("Stewart Connect -Resources");

  return (
    <StyledBoxContainer>
      <PageSection
        contentAlignment="below-header"
        icon={<BallotIcon />}
        title="Stewart Resources"
      >
        <Grid container>
          <Grid item xs={12}>
            <Link to="/stewartpreferred">
              <HeadingContainer>
                <ListPrimaryHeading>Stewart Preferred</ListPrimaryHeading>
              </HeadingContainer>
            </Link>
            <ListSecondaryHeading>
              Our Stewart Preferred program provides Stewart Trusted Providers
              products and services at nationally negotiated rates from a
              variety of vendors. From business solutions such as background
              checks and market intelligence, to check stock and office
              supplies, to digital solutions such as DocuSign and NotaryCam,
              we&apos;ve got programs to help your agency save.
            </ListSecondaryHeading>
          </Grid>
        </Grid>
      </PageSection>
      <ScrollToTopArrow />
    </StyledBoxContainer>
  );
};

export default StewartResources;
