import React from "react";
import {
  Paper,
  styled,
  Table,
  TableContainer,
} from "@material-ui/core";
import { Signature } from "entities/UIModel/Signature";
import SignaturesTableHeader from "./SignaturesTableHeader";
import SignaturesTableBody from "./SignaturesTableBody";
import {
  margin,
  padding,
} from "theme/defaultStyle";

interface Props {
  records?: Signature[];
}

const StyledPaper = styled(Paper)({
  backgroundColor: "transparent",
  boxShadow: "none",
  marginTop: margin.xsmall1,
  overflowX: "auto",
  paddingBottom: padding.zero,
  paddingLeft: padding.xsmall,
  paddingRight: padding.xsmall,
  paddingTop: padding.zero,
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  maxWidth: "644px",
  tableLayout: "fixed",
  "& .MuiTableCell-root": {
    wordWrap: "break-word",
  },
});

const SignaturesTable = ({ records = [] }: Props) => {
  return (
    <TableContainer component={StyledPaper}>
      <StyledTable>
        <SignaturesTableHeader />
        <SignaturesTableBody records={records} />
      </StyledTable>
    </TableContainer>
  );
};

export default SignaturesTable;
