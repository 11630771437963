import {
  Box,
  styled,
} from "@material-ui/core";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import QADialog from "controls/global/dialogs/qa-dialog";
import PdfViewer from "controls/global/pdf-viewer";
import {
  ActionResult,
  Endorsement,
  GeneralFileParty,
  Jacket,
  PdfDocument,
  SCFile,
} from "entities/UIModel";
import {
  calculateRemittance,
  getCorrelationId,
  isIssuableEndorsement,
  isValidDate,
} from "pages/file/utils/helper";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { useFieldArray } from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import { useFiles } from "utils/context/FilesContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import {
  EndorsementStatusType,
  JacketFormType,
  MapActionType,
  OrderStatusType,
  ProductAction,
  ProductType,
  ProductTypeName,
  UIConstants,
} from "utils/data/enum";
import { getDefaultJacketObject } from "utils/data/products";
import { convertToNumber, hasValue } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import JacketProduct from "./JacketProduct";
import useFocus from "utils/custom-hooks/useFocus";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import {
  isFormTypeValidForSimultaneous,
  isValidPairForSimultaneous,
  //setDirtyInQnA,
} from "pages/file/utils/products/jacket";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import { useCompanyRemittance } from "utils/context/CompanyRemittanceContext";
import useEffectiveDateValidation from "utils/custom-hooks/useEffectiveDateValidation";
import { useDocument } from "utils/context/DocumentContext";
import { useTranslation } from "utils/context/TranslationContext";
import { usePricingActions } from "utils/context/PricingContext";
import { useCompanyForm } from "utils/context/CompanyFormContext";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

interface IFormTypeMap {
  Loan: string;
  Owners: string;
}

const formTypeMap: IFormTypeMap = {
  Loan: "Owners",
  Owners: "Loan",
};

type CurrentActionParam = {
  Issue?: {
    integrationKey?: string[];
  };
  Revise?: {
    integrationKey?: string;
  };
  Ppe?: {
    product?: Jacket;
    integrationKey?: string;
    orderId?: number;
  };
};

const JacketProductCollection = () => {
  //#region state declaration
  const [statusMessage, setStatusMessage] = useState("");
  const [openJacketIssueDialog, setOpenJacketIssueDialog] = useState(false);
  const [disableAddJacket, setDisableJacket] = useState(false);
  const [issueJacketRequestId, setIssueJacketRequestId] = useState("");
  const [jacketProgressBaseline, setJacketProgressBaseline] = useState<number>();
  const [runJacketProgress, setRunJacketProgress] = useState(false);
  const [isQAOpen, setIsQAOpen] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteProductIndex, setDeleteProductIndex] = useState<number>(-1);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>("");
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const [issueDisabled, setIssueDisabled] = useState(false);
  const [forceRevisionForIntegrationKey, setForceRevisionForIntegrationKey] = useState("");
  const [isQASubmitButtonDisabled, setIsQASubmitButtonDisabled] = useState(false);
  const [isPpeSubmitButtonDisabled, setIsPpeSubmitButtonDisabled] = useState(false);
  const [submitReviseDisabled, setSubmitReviseDisabled] = useState(false);
  //#endregion

  //#region persist local variables
  let currentAction = useRef(ProductAction.None);
  let currentActionParameter = useRef<CurrentActionParam>({});
  let currentActionResult = useRef<ActionResult>({});
  const companyStateLocationsRef = React.useRef<CompanyStateLocation[]>([]);
  //#endregion

  //#region hook declaration
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const { getValues, setValue, trigger, clearErrors, nameString, setError } =
    useFormWrapper();
  const [{ companyStateLocations }] = useCompanyLocation();    
  const { resetValidationRequiredFlags, setValidLocations, reloadFile } = useCreateFile();

  const { fields, append, remove } = useFieldArray({
    name: "jackets",
    keyName: "fieldId",
  });

  const jackets = getValues("jackets") as Array<Jacket>;

  const [
    { initialValues },
    { issueJacket, revise, submitEndorsements, deleteOrder },
  ] = useFiles();

  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [, { getDocumentUrl }] = useDocument();
  const [{ translations }] = useTranslation();
  const [{ agencyStateRemittance }] = useCompanyRemittance();
  const isFileDisabled = useFieldDisabler("AddJACKETButton");
  const { validateEffectiveDate } = useEffectiveDateValidation();
  const { setFocusInputElement } = useFocus();
  const [, { getForms }] = useCompanyForm();
  const [, { getVoidReasons }] = useVoidReasons();
  //#endregion

  //#region HANDLERS
  const hasError = () => {
    // check file level error
    if (
      currentActionResult.current.error ||
      currentActionResult.current.scfile?.errorMessage
    )
      return true;

    // check product level error
    let isError = false;
    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;

      switch (currentAction.current) {
        case ProductAction.Issue: {
          if (
            currentActionParameter.current.Issue?.integrationKey &&
            currentActionParameter.current.Issue?.integrationKey?.length > 0
          ) {
            const integrationKeys: string[] =
              currentActionParameter.current.Issue.integrationKey;
            let jacket = scfile.jackets?.find(
              (jacket: any) =>
                integrationKeys.indexOf(jacket.integrationKey) >= 0 &&
                jacket.errorMessage &&
                jacket.errorMessage.length > 0
            );

            if (jacket) isError = true;
          }

          break;
        }
        case ProductAction.Revise: {
          if (currentActionParameter.current.Revise?.integrationKey) {
            const jacket = scfile.jackets?.find(
              (jacket: any) =>
                jacket.integrationKey ===
                currentActionParameter.current.Revise?.integrationKey &&
                jacket.errorMessage &&
                jacket.errorMessage.length > 0
            );

            if (jacket) isError = true;
          }

          break;
        }
        case ProductAction.Ppe: {
          if (
            currentActionParameter.current.Ppe?.product &&
            currentActionParameter.current.Ppe?.orderId &&
            currentActionParameter.current.Ppe?.integrationKey
          ) {
            const jacket = scfile.jackets?.find(
              (jacket: any) =>
                jacket.orderID === currentActionParameter.current.Ppe?.orderId
            );
            if (jacket) {
              currentActionParameter.current.Ppe?.product?.endorsements
                ?.filter((e) => isIssuableEndorsement(e))
                ?.forEach((e) => {
                  const errorEndorsement = jacket.endorsements?.find(
                    (endorsement) =>
                      endorsement.integrationKey === e.integrationKey &&
                      endorsement.errorMessage
                  );

                  if (errorEndorsement) isError = true;
                });
              // const endorsement = jacket.endorsements?.find(
              //   (endorsement: any) =>
              //     endorsement.errorMessage &&
              //     endorsement.errorMessage.length > 0
              // );

              // if (endorsement) isError = true;
            }
          }

          break;
        }
      }
    }

    return isError;
  };

  const handleError = () => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.current.error &&
      currentActionResult.current.error.response &&
      currentActionResult.current.error.response.status !== 200
    ) {
      const errorMessage =
        currentActionResult.current.error.response.data &&
          currentActionResult.current.error.response.data.errorMessage
          ? currentActionResult.current.error.response.data.errorMessage
          : currentActionResult.current.error.response.statusText;
      setErrorMessage(errorMessage);
      setCorrelationId(getCorrelationId(currentActionResult.current.error.response.headers));
      setOpenErrorDialog(true);
      return;
    }

    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;

      // Check for File level error
      if (scfile && scfile.errorMessage) {
        setErrorMessage(scfile.errorMessage);
        setCorrelationId(getCorrelationId(currentActionResult.current.headers));
        setOpenErrorDialog(true);
        return;
      }

      // Check for Product level error
      let errorHeader = "";
      let errorMessages: string[] = [];
      switch (currentAction.current) {
        case ProductAction.Issue: {
          if (
            currentActionParameter.current.Issue?.integrationKey &&
            currentActionParameter.current.Issue?.integrationKey?.length > 0
          ) {
            const integrationKeys: string[] =
              currentActionParameter.current.Issue.integrationKey;
            let filteredJackets = scfile.jackets?.filter(
              (jacket: Jacket) =>
                integrationKeys.indexOf(jacket.integrationKey) >= 0 &&
                jacket.errorMessage &&
                jacket.errorMessage.length > 0
            );

            filteredJackets?.forEach((jacket: Jacket) => {
              errorMessages.push(`${jacket.form}; ${jacket.errorMessage!}`);
            });
          }

          break;
        }
        case ProductAction.Revise: {
          if (currentActionParameter.current.Revise?.integrationKey) {
            const jacket = scfile.jackets?.find(
              (jacket: Jacket) =>
                jacket.integrationKey ===
                currentActionParameter.current.Revise?.integrationKey &&
                jacket.errorMessage &&
                jacket.errorMessage.length > 0
            );

            if (jacket) {
              errorMessages.push(`${jacket.form}; ${jacket.errorMessage!}`);
            }
          }

          break;
        }
        case ProductAction.Ppe: {
          if (
            currentActionParameter.current.Ppe?.product &&
            currentActionParameter.current.Ppe?.orderId &&
            currentActionParameter.current.Ppe?.integrationKey
          ) {
            const jacket = scfile.jackets?.find(
              (jacket: Jacket) =>
                jacket.orderID === currentActionParameter.current.Ppe?.orderId
            );
            if (jacket) {
              currentActionParameter.current.Ppe?.product?.endorsements
                ?.filter((e) => isIssuableEndorsement(e))
                ?.forEach((e) => {
                  const errorEndorsement = jacket.endorsements?.find(
                    (endorsement) =>
                      endorsement.integrationKey === e.integrationKey &&
                      endorsement.errorMessage
                  );

                  if (errorEndorsement) {
                    errorMessages.push(
                      `${errorEndorsement.endorsementName
                      }; ${errorEndorsement.errorMessage!}`
                    );
                  }
                });

              // const endorsements = jacket.endorsements?.filter(
              //   (endorsement: any) =>
              //     endorsement.errorMessage &&
              //     endorsement.errorMessage.length > 0
              // );
              // endorsements?.forEach((endorsement: Endorsement) => {
              //   errorMessages.push(
              //     `${endorsement.endorsementName}; ${endorsement.errorMessage!}`
              //   );
              // });

              if (errorMessages.length > 0) {
                errorHeader = `The following Endorsement(s) were not added, please review the error and resubmit:`;
              }
            }
          }

          break;
        }
      }

      if (errorMessages.length > 0) {
        setErrorMessage(errorMessageContent(errorMessages, errorHeader));
        setCorrelationId(getCorrelationId(currentActionResult.current.headers));
        setOpenErrorDialog(true);
        return;
      }
    }
  };

  const errorMessageContent = (
    errorMessages: string[],
    errorHeader: string
  ): React.ReactNode => {
    if (errorHeader.length > 0) {
      return (
        <>
          <div style={{ textAlign: "left" }}>{errorHeader}</div>
          <ul style={{ textAlign: "left" }}>
            {errorMessages?.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </>
      );
    } else {
      if (errorMessages.length > 1) {
        return (
          <ul style={{ textAlign: "left" }}>
            {errorMessages?.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        );
      } else {
        return <span>{errorMessages[0]}</span>;
      }
    }
  };

  const onIssueDialogClosed = () => {
    setOpenJacketIssueDialog(false);
    setRunJacketProgress(false);
    setIssueJacketRequestId("");

    // Enable back the Issue and Submit button
    setSubmitReviseDisabled(false);
    setIsQASubmitButtonDisabled(false);
    setIsPpeSubmitButtonDisabled(false);

    setTimeout(() => setBypassDefaultLoader(false), 1000);

    // Check for error
    if (hasError()) {
      setIssueDisabled(false);
      handleError();
    } else {
      if (currentActionResult.current.scfile) {
        const scfile = currentActionResult.current.scfile as SCFile;

        switch (currentAction.current) {
          case ProductAction.Issue: {
            if (
              currentActionParameter.current.Issue?.integrationKey &&
              currentActionParameter.current.Issue?.integrationKey?.length > 0
            ) {
              validateResponse(
                scfile,
                currentActionParameter.current.Issue?.integrationKey
              );
            }
            break;
          }
          case ProductAction.Revise: {
            if (currentActionParameter.current.Revise?.integrationKey) {
              setForceRevisionForIntegrationKey("");
              setSubmitReviseDisabled(false);
              setDisableJacket(false);

              validateResponse(scfile, [
                currentActionParameter.current.Revise.integrationKey,
              ]);
            }
            break;
          }
          case ProductAction.Ppe: {
            validateResponse(scfile);
            break;
          }
          default: {
            setIssueDisabled(false);
          }
        }
      }
    }
  };

  const setPartiesValidation = (
    existingJacket: boolean,
    jacket: Jacket,
    buyerBorrowerParties?: GeneralFileParty[],
    sellerParties?: GeneralFileParty[],
    lenderParties?: GeneralFileParty[],
  ) => {
    if (!existingJacket) {
      buyerBorrowerParties?.forEach((_, index) =>
        setValue(`buyerBorrowerParties.${index}.requirePartyValidation`, false)
      );
    } else {
      if (
        jacket.formType === JacketFormType.Owners &&
        Boolean(jacket.insuredNames)
      ) {
        buyerBorrowerParties?.forEach((_, index) =>
          setValue(
            `buyerBorrowerParties.${index}.requirePartyValidation`,
            false
          )
        );
      }

      sellerParties?.forEach((_, index) =>
        setValue(`sellerParties.${index}.requirePartyValidation`, false)
      );
      lenderParties?.forEach((_, index) =>
        setValue(`lenderParties.${index}.requirePartyValidation`, false)
      );
    }
  };

  const setJacketValidation = async (
    integrationKeys?: string[],
    productAction: string = ""
  ) => {
    if (!integrationKeys || integrationKeys.length === 0) return;

    // clear  previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    const jackets = getValues("jackets") as Jacket[];
    const buyerBorrowerParties = getValues("buyerBorrowerParties");
    const sellerParties = getValues("sellerParties");
    const lenderParties = getValues("lenderParties");

    for (const [index, jacket] of jackets.entries()) {
      const isCurrentJacket =
        integrationKeys.indexOf(jacket.integrationKey) > -1;

      //JACKET
      setValue(`jackets.${index}.isValidationRequired`, isCurrentJacket);
      setPartiesValidation(isCurrentJacket, jacket, buyerBorrowerParties, sellerParties, lenderParties);

      //-- Validate againts contract date, this is needed due to the user may click on Issue button without losing the focus from EffectiveDate field
      if (isCurrentJacket) {
        if (productAction === ProductAction.Revise && jacket.effectiveDate) {
          await validateJacketForm(index, jacket.formType, jacket.effectiveDate, jacket.formID);
        }
        await validateEffectiveDate("jackets", index, jacket.effectiveDate, false);
      }

      //INSURED NAMES
      if (jacket.insuredNames) {
        buyerBorrowerParties.forEach((buyerBorrower: any, index: number) => {
          // if (!jacket.insuredNames.includes(buyerBorrower.integrationKey)) {
          //   setValue(
          //     `buyerBorrowerParties.${index}.requirePartyValidation`,
          //     false
          //   );
          // }
          //TODO: Fix this validation
        });
      }

      //PRICING DETAILS
      if (jacket.pricingDetail) {
        setValue(
          `jackets.${index}.pricingDetail.isValidationRequired`,
          isCurrentJacket
        );
        // rlo-03/09/2022 - On Revise - Currently Product's agency location can not be changed
        if (productAction === ProductAction.Issue || productAction === ProductAction.Revise) {
          setValue(
            `jackets.${index}.pricingDetail.isValidatingLocationRequired`,
            isCurrentJacket
          );
        } else {
          setValue(
            `jackets.${index}.pricingDetail.isValidatingLocationRequired`,
            false
          );
        }
      }

      //ENDORSEMENTS
      jacket.endorsements?.forEach((e: Endorsement, i: number) =>
        setValue(
          `jackets.${index}.endorsements.${i}.isValidationRequired`,
          isCurrentJacket &&
          e.endorsementStatusTypeCode &&
          ((productAction === ProductAction.Issue &&
            e.endorsementStatusTypeCode === OrderStatusType.Pending) ||
            (productAction === ProductAction.Revise &&
              e.endorsementStatusTypeCode === OrderStatusType.Issued))
        )
      );
    }
  };

  const validateJacketForm = async (index: number, formType: string, effectiveDate: Date | undefined, formID: string | undefined) => {
    const [companyId, stateId] = getValues([
      "agency.id",
      "properties.0.state.abbr",
    ]);

    // Get Forms for formType & EffectiveDate selected
    const companyForms = await getForms(
      companyId,
      stateId,
      ProductType.Jacket,
      formType,
      effectiveDate
    );

    let msg = "";

    if (companyForms && companyForms?.length > 0) {
      const matchingForm = companyForms.find((cf) => cf.formID === formID);
      if (!matchingForm) {
        msg = UIConstants.FORM_NOT_VALID_FOR_SELECTED_EFFECTIVE_DATE;
      }
    }

    if (msg) {
      setError(
        getNameString(`jackets.${index}.form`),
        {
          type: "focus",
          message: msg,
        },
        { shouldFocus: true }
      );
    }
    setValue(`jackets.${index}.formValidationMsg`, msg);
    return msg;
  };

  const handleCompletedQnA = () => {
    async function handleReissue() {
      switch (currentAction.current) {
        case ProductAction.Issue: {
          // setJacketProgressBaseline(50);
          await issueJackets(
            currentActionParameter.current.Issue?.integrationKey
          );
          // setJacketProgressBaseline(0);
          break;
        }
        case ProductAction.Revise: {
          setJacketProgressBaseline(50);
          await onReviseJacket(
            currentActionParameter.current.Revise?.integrationKey || ""
          );
          setJacketProgressBaseline(0);
          break;
        }
        case ProductAction.Ppe: {
          setJacketProgressBaseline(50);
          handleEndorsementGridSubmit(
            currentActionParameter.current.Ppe?.product,
            currentActionParameter.current.Ppe?.integrationKey || "",
            currentActionParameter.current.Ppe?.orderId,
            true
          );
          setJacketProgressBaseline(0);
          break;
        }
      }
    }

    setIsQASubmitButtonDisabled(true);
    setIsQAOpen(false);
    handleReissue();
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
  };

  const handleRemittanceCalculation = (
    value: string,
    index: number,
    feeType: string
  ) => {
    const jacket = getValues(`jackets.${index}`);
    const newPrice = convertToNumber(value);
    const existingPremium: number = jacket.pricingDetail?.actualFee || 0.0;
    const existingLiability: number = jacket.pricingDetail?.liability || 0.0;
    let premium: number = 0.0;
    let liabilityAmount: number = 0.0;

    if (feeType === "Liability") {
      if (existingPremium >= 0) {
        premium = existingPremium;
        liabilityAmount = newPrice;
      }
    } else if (feeType === "Premium") {
      setValue(`jackets.${index}.pricingDetail.actualFee`, newPrice);
      if (existingLiability >= 0) {
        premium = newPrice;
        liabilityAmount = existingLiability;
      }
    }

    if (agencyStateRemittance && liabilityAmount > 0 && premium > 0) {
      // calculate remittance
      let remittance = calculateRemittance(
        ProductType.Jacket,
        convertToNumber(liabilityAmount),
        premium,
        agencyStateRemittance.remitSplitType,
        convertToNumber(agencyStateRemittance.remitFlatPct),
        convertToNumber(agencyStateRemittance.remitPerThousand),
        convertToNumber(agencyStateRemittance.minRemitPerThousand)
      );

      if (hasValue(remittance)) {
        setValue(
          `jackets.${index}.pricingDetail.calculatedRemittance`,
          remittance
        );
        setValue(`jackets.${index}.pricingDetail.actualRemittance`, remittance);
      }
    }
  };

  const addNewJacket = () => {
    let jacketKey = uuidv4();
    const newJacket = getDefaultJacketObject(true);
    newJacket.integrationKey = jacketKey;
    newJacket.isDefault = false;
    newJacket.isDirty = true;
    append(newJacket);
    // set 1st jackets as dirty
    setValue(`jackets.0.isDefault`, false);
    setValue(`jackets.0.isDirty`, true);

    // set focus to last Jacket
    const newJacketIndex = jackets.length;
    setFocusInputElement(`jackets.${newJacketIndex}.effectiveDate`);
  };

  const deleteJacket = (index: number) => {
    setOpenConfirmationDialog(true);
    setDeleteProductIndex(index);
  };

  const handleYes = () => {
    if (deleteProductIndex >= 0) {
      const jacketOrderId = getValues(`jackets.${deleteProductIndex}.orderID`);
      if (jacketOrderId && jacketOrderId > 0) deleteOrder(jacketOrderId);
      // uncheckSimultaneousIfExist(deleteProductIndex); // rlo 5/27/2021 -- old functionality
      remove(deleteProductIndex);
      setDeleteProductIndex(-1);
    }
    setOpenConfirmationDialog(false);
  };

  const handleNo = () => {
    setOpenConfirmationDialog(false);
    setDeleteProductIndex(-1);
  };

  // rlo 5/27/2021 - old Simultaneous Jacket -- please don't delete it yet, it's intentionaly left it here for now
  // const uncheckSimultaneousIfExist = (currentIndex: number) => {
  //   const jackets = getValues("jackets");
  //   const previousJacket = jackets[currentIndex - 1];
  //   const indexToUpdate = shouldUncheckSimultaneousJacket(previousJacket)
  //     ? currentIndex - 1
  //     : currentIndex + 1;

  //   if (
  //     jackets[indexToUpdate] &&
  //     jackets[indexToUpdate].orderStatusTypeCode !== OrderStatusType.Issued &&
  //     jackets[indexToUpdate].orderStatusTypeCode !== OrderStatusType.Voided
  //   ) {
  //     setValue(`jackets.${indexToUpdate}.pricingDetail.isSimultaneous`, false);
  //   }
  // };

  // rlo 5/27/2021 - old Simultaneous Jacket -- please don't delete it yet, it's intentionaly left it here for now
  // const checkSimultaneousIfExist = (currentIndex: number) => {
  //   const jackets = getValues("jackets");
  //   const currentJacket = jackets[currentIndex];
  //   const previousJacket = jackets[currentIndex - 1];
  //   const nextJacket = jackets[currentIndex + 1];
  //   const indexToUpdate =
  //     isAllowedJacketType(currentJacket, previousJacket) &&
  //     isJacketValidForSimultaneous(previousJacket)
  //       ? currentIndex - 1
  //       : isAllowedJacketType(currentJacket, nextJacket) &&
  //         isJacketValidForSimultaneous(nextJacket)
  //       ? currentIndex + 1
  //       : undefined;

  //   if (!indexToUpdate && indexToUpdate !== 0) return false;

  //   setValue(`jackets.${indexToUpdate}.pricingDetail.isSimultaneous`, true);
  //   return true;
  // };

  // rlo 5/27/2021 - old Simultaneous Jacket -- please don't delete it yet, it's intentionaly left it here for now
  // const isConsecutiveJacketIssuedOrVoided = (currentIndex: number) => {
  //   const nextJacket = getValues(`jackets.${currentIndex + 1}`);
  //   return (
  //     nextJacket &&
  //     (nextJacket.orderStatusTypeCode === OrderStatusType.Issued ||
  //       nextJacket.orderStatusTypeCode === OrderStatusType.Voided)
  //   );
  // };

  const isSimultaneousAllowed = (currentIndex: number) => {
    // Rules: ONLY allow for Jacket1, Jacket1 is pending, Jacket1 is a loan/owner, and the file only have a single jacket
    const jackets: Jacket[] = getValues("jackets");
    const jacketCount = jackets ? jackets.length : 0;
    const currentJacket = jacketCount > 0 ? jackets[currentIndex] : undefined;
    return currentIndex === 0 &&
      currentJacket?.orderStatusTypeCode === OrderStatusType.Pending &&
      isFormTypeValidForSimultaneous(currentJacket?.formType) &&
      jacketCount === 1;
  };

  const isValidJacketForSimultaneous = (jacket: Jacket): boolean => {
    if (
      jacket.isDeleted ||
      (jacket.id === 0 && jacket.orderStatusTypeCode !== OrderStatusType.Pending)
    ) {
      return false;
    } 
  
    let isValid: boolean = false;  
    if (
      jacket.effectiveDate &&
      !isNaN(new Date(jacket.effectiveDate) as any) &&
      jacket.effectiveDate !== new Date("01/01/01") &&
      (Boolean(jacket.insuredNames) || jacket.form || jacket.formType)
      //jacket.coverageType
    ) {
      isValid = true;
    }
  
    return isValid;
  };
  
  const onSimultaneousChanged = (index: number, checked: boolean) => {
    if (!checked || !isSimultaneousAllowed(index)) return;

    const currentJacket = getValues(`jackets.${index}`);
    const newJacket = getDefaultJacketObject(true);
    newJacket.effectiveDate = currentJacket.effectiveDate;
    newJacket.formType =
      formTypeMap[currentJacket.formType as keyof IFormTypeMap];

    if (newJacket.pricingDetail) {
      newJacket.pricingDetail.isSimultaneous = true;
    }

    append(newJacket);
  };

  const setDisableAddJacket = (
    isAddJacketDisabled: boolean,
    integrationKey?: string
  ) => {
    setDisableJacket(isAddJacketDisabled);
    const jackets = getValues("jackets");
    jackets.map((j: Jacket) => {
      if (isAddJacketDisabled && j.integrationKey === integrationKey) {
        j.revisionMode = false;
      } else {
        j.revisionMode = isAddJacketDisabled;
      }

      return j;
    });
  };

  const getCurrentProduct = (index: number) => {
    const productCollection: Jacket[] | undefined = getValues("jackets");
    if (productCollection && productCollection.length > 0)
      return productCollection[index];
    else return null;
  };

  const isEndorsementEditMode = (index: number) => {
    const currentProduct = getCurrentProduct(index);
    if (!currentProduct) return false;

    return (
      !!currentProduct.endorsements &&
      currentProduct.endorsements.length > 0 &&
      currentProduct.endorsements.filter(
        (e: Endorsement) =>
          !e.endorsementID ||
          // e.endorsementName &&   --- notes: rlo 8/23/2021 --i don't know why this is added -- it was not in the original code
          (e.endorsementID > 0 &&
            e.endorsementStatusTypeCode &&
            e.endorsementStatusTypeCode === OrderStatusType.Pending)
      ).length > 0
    );
  };

  // Set to false ONLY if there are any new entered endorsements
  const isEndorsementSubmitDisabled = (index: number) => {
    const currentProduct = getCurrentProduct(index);
    let isDisabled = true;
    let newEndorsements = currentProduct?.endorsements?.filter(
      (e) =>
        (!e.endorsementStatusTypeCode ||
          (e.endorsementStatusTypeCode !== EndorsementStatusType.Issued &&
            e.endorsementStatusTypeCode !== EndorsementStatusType.Voided)) &&
        (e.endorsementName || e.effectiveDate)
    );

    if (newEndorsements && newEndorsements.length > 0) isDisabled = false;
    return isDisabled;
  };

  const handleEndorsementGridCancel = (index: number, product: Jacket) => {
    clearErrors(nameString(`jackets.${index}`));
    const existingEndorsements = product.endorsements?.filter(
      (e) => !!e.endorsementID
    );
    setValue(`jackets.${index}.endorsements`, existingEndorsements);
  };

  const handleEffectiveDateChange = async (index: number, newDate: Date | null) => {
    // setTimeout is currently needed to handle timing issue. Investigate if this is necessary in react18.
    setTimeout(async () => {
      if (newDate === null || isValidDate(newDate)) {
        const msg = await validateEffectiveDate("jackets", index, newDate);
        if (newDate === null || msg === "") {
          setValue(`jackets.${index}.isDefault`, false);
          const { endorsements: jacketEndorsements }: Jacket = getValues(
            `jackets.${index}`
          );
          if (!jacketEndorsements) return;
          jacketEndorsements.forEach((endorsement: Endorsement, idx: number) => {
            if (!isPendingEndorsement(endorsement)) return;
            setValue(`jackets.${index}.endorsements.${idx}.effectiveDate`, newDate);
          });
        }
      }
    });
  };

  const isPendingEndorsement = (e: Endorsement) => {
    const isPending =
      !e.endorsementStatusTypeCode ||
      e.endorsementStatusTypeCode === OrderStatusType.Pending;
    const isPendingNonShortForm = !e.isSFE && e.endorsementName && isPending;
    const isPendingShortForm = e.isSFE && e.isSelected && isPending;

    return isPendingNonShortForm || isPendingShortForm;
  };

  const handleCloseQnA = () => {
    setIssueDisabled(false);
    const combinedQNAs = getValues("combinedQNAs");
    setValue("combinedQNAs", (combinedQNAs.length = 0));
    setIsQAOpen(false);
    setTimeout(() => setBypassDefaultLoader(false), 1000);
  };
  //#endregion

  //#region MAIN_ACTIONS
  //-- ISSUE --//
  const issueSingleJacket = async (integrationKey: string, index: number): Promise<boolean> => {
    setIssueDisabled(true);
    const jackets: Jacket[] = getValues("jackets");
    const jacket = jackets.filter(
      (jacket: any) => jacket.integrationKey === integrationKey
    )[0];
    let isValid = true;
    if (!jacket.pricingDetail?.isSimultaneous) {
      isValid = await issueJackets([integrationKey]);
    } else {
      // Check for Simultaneous jacket

      // rlo - 5/27/2022 - New Simultaneous Jacket
      let nextSimultaneousJacket = "";
      if (index <= 1 && jackets.length > 1) {
        // Simulataneous only applies to jacket 1 & jacket 2
        const isValidPair = isValidPairForSimultaneous(jackets);
        for (let i = 0; i <= 1; i++) {
          if (
            jackets[i].integrationKey !== integrationKey &&
            jackets[i].pricingDetail?.isSimultaneous &&
            jackets[i].orderStatusTypeCode === OrderStatusType.Pending &&
            isValidPair &&
            isValidJacketForSimultaneous(jackets[i])
          ) {
            nextSimultaneousJacket = jackets[i].integrationKey;
          }
        }
      }
      if (nextSimultaneousJacket) {
        isValid = await issueJackets([integrationKey, nextSimultaneousJacket]);
      } else {
        isValid = await issueJackets([integrationKey]);
      }
    }
    return isValid;
  };

  const issueJackets = async (integrationKey?: string[]): Promise<boolean> => {
    // save current action information
    currentAction.current = ProductAction.Issue;
    currentActionParameter.current = {
      Issue: { ...{ integrationKey } },
      Revise: {},
      Ppe: {},
    };

    await setJacketValidation(integrationKey, ProductAction.Issue);
    setValidLocations(companyStateLocationsRef.current);
    const isValid = await runValidation({
      values: getValues(),
      trigger,
      setValue,
      productType: ProductType.Jacket,
    });

    if (!isValid) {
      setIssueDisabled(false);
      return false;
    }

    let receiptId = uuidv4();
    setPdfDocuments([]);
    setIssueJacketRequestId(receiptId);
    setBypassDefaultLoader(true);
    setStatusMessage("Please wait while issuing your Jacket...");
    setTimeout(() => {
      setOpenJacketIssueDialog(true);
    }, 100);
    setRunJacketProgress(true);

    if (!integrationKey || integrationKey?.length === 0) {
      const jackets = getValues("jackets");
      integrationKey = jackets
        .filter(
          (jacket: Jacket) =>
            jacket.orderStatusTypeCode !== OrderStatusType.Issued
        )
        .map((jacket: Jacket) => jacket.integrationKey);
    }

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues(),
      undefined,
      MapActionType.ProductAction,
      ProductAction.Issue,
      ProductType.Jacket,
      integrationKey || [],
      undefined,
      translations
    );

    const deletedJackets = apiSCFile.JacketProducts?.filter((j) => j.IsDeleted);
    if (deletedJackets && deletedJackets.length > 0) {
      deletedJackets.forEach((j) => {
        const index = apiSCFile.JacketProducts?.indexOf(j);
        index && apiSCFile.JacketProducts?.splice(index, 1);
      });
    }

    setBypassDefaultLoader(true);
    setIsQAOpen(false);

    setPricingNotificationUpdated(false);
    currentActionResult.current = (await issueJacket(
      apiSCFile,
      receiptId
    )) as ActionResult;
    setPricingNotificationUpdated(true);
    return true;
  };

  //-- REVISE --//
  const onReviseJacket = async (integrationKey: string) => {
    await setJacketValidation([integrationKey], ProductAction.Revise);
    setValidLocations(companyStateLocationsRef.current);
    const isValid = await runValidation({
      values: getValues(),
      trigger,
      setValue,
      productType: ProductType.Jacket,
    });
    if (!isValid) throw new Error("Validation failed");

    setSubmitReviseDisabled(true);
    setPdfDocuments([]);

    // Save current action
    currentAction.current = ProductAction.Revise;
    currentActionParameter.current = {
      Revise: { ...{ integrationKey } },
      Issue: {},
      Ppe: {},
    };

    setDisableJacket(true);
    let receiptId = uuidv4();
    setIssueJacketRequestId(receiptId);
    setStatusMessage("Please wait while we revise your Jacket...");
    setBypassDefaultLoader(true);
    setOpenJacketIssueDialog(true);
    setRunJacketProgress(true);
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues(),
      undefined,
      MapActionType.ProductAction,
      ProductAction.Revise,
      ProductType.Jacket,
      [integrationKey],
      undefined,
      translations
    );

    setBypassDefaultLoader(true);
    setIsQAOpen(false);

    setPricingNotificationUpdated(false);
    currentActionResult.current = (await revise(
      apiSCFile,
      ProductType.Jacket,
      receiptId,
      integrationKey
    )) as ActionResult;

    if (hasError()) {
      setForceRevisionForIntegrationKey(integrationKey);
      throw new Error("Jacket revision failed");
    }
    else {
      await reloadFile(undefined, setInfoMessage);
    }
    setPricingNotificationUpdated(true);
  };

  const setInfoMessage = (message?: string) => {
    setPricingNotificationUpdated(false);
    setValue("pricing.infoMessage", currentActionResult?.current?.scfile?.pricing?.infoMessage);
    setPricingNotificationUpdated(true);
  }

  //-- SUBMIT ENDORSEMENTS --//
  const handleEndorsementGridSubmit = async (
    product?: Jacket,
    integrationKey?: string,
    orderId?: number,
    isInQnA?: boolean
  ) => {
    // clear  previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    // let jacketInQnA;
    const jackets = getValues("jackets");
    jackets.forEach((j: Jacket, jIndex: number) => {
      if (j.integrationKey === integrationKey) {
        setValue(`jackets.${jIndex}.isValidationRequired`, true);
        //if (isInQnA) jacketInQnA = j;
        if (j.endorsements && j.endorsements.length > 0)
          j.endorsements.forEach((je: Endorsement, jeIndex: number) => {
            setValue(
              `jackets.${jIndex}.endorsements.${jeIndex}.isValidationRequired`,
              !je.endorsementNumber ? true : false
            );
          });
      } else {
        setValue(`jackets.${jIndex}.isValidationRequired`, false);
        if (j.endorsements && j.endorsements.length > 0)
          j.endorsements.forEach((je, index) => {
            setValue(
              `jackets.${jIndex}.endorsements.${index}.isValidationRequired`,
              false
            );
          });
      }
    });

    setValidLocations(companyStateLocationsRef.current);
    const isValid = await runValidation({
      values: getValues(),
      trigger,
      setValue,
      productType: ProductType.Jacket,
    });

    if (!isValid) return;

    // Disable PPE submit button
    setIsPpeSubmitButtonDisabled(true);

    // Save current action
    currentAction.current = ProductAction.Ppe;
    currentActionParameter.current = {
      Ppe: { ...{ product, integrationKey, orderId } },
      Issue: {},
      Revise: {},
    };

    let receiptId = uuidv4();
    setIssueJacketRequestId(receiptId);
    setStatusMessage("Please wait while submitting your Endorsements...");
    setBypassDefaultLoader(true);
    setOpenJacketIssueDialog(true);
    setRunJacketProgress(true);

    let apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues(),
      undefined,
      MapActionType.ProductAction,
      ProductAction.Issue,
      ProductType.Endorsement,
      [integrationKey!]
    );

    // It causes isDirty to be set to true for all issued endorsments. -- rlo 9/28/2022
    //if (jacketInQnA) apiSCFile = setDirtyInQnA(apiSCFile, jacketInQnA);

    currentActionResult.current = (await submitEndorsements(
      apiSCFile,
      receiptId,
      orderId
    )) as ActionResult;
  };

  const validateResponse = (scFile: SCFile, productKeys: string[] = []) => {
    const hasAdditionalQuestions = verifyAdditionalQuestions(scFile);

    // Keep the initital value of isSimultaneousRate during Q&A
    //if (hasAdditionalQuestions && initialValues.pricing.isSimultaneousRate) {
    //  setValue("pricing.isSimultaneousRate", true);
    //}

    if (!hasAdditionalQuestions) {
      const showPdfIfAny = async () => {
        await verifyAndShowPdfDocuments(scFile, productKeys);
        setIssueDisabled(false);
      };
      showPdfIfAny();
    }
  };

  const verifyAdditionalQuestions = (file: SCFile): boolean => {
    const additionalQuestions = file.combinedQNAs;
    if (!additionalQuestions || additionalQuestions.length === 0) return false;

    const uiWithUnansweredQuestions = additionalQuestions.filter(
      (data) =>
        data.questions?.filter((question) => question && !question.answerValue)
          .length !== 0
    );

    if (uiWithUnansweredQuestions.length === 0) {
      setIsQAOpen(false);
      return false;
    }

    setOpenJacketIssueDialog(false);
    setRunJacketProgress(false);
    setBypassDefaultLoader(true);
    setIsQAOpen(true);
    return true;
  };

  const verifyAndShowPdfDocuments = async (
    file: SCFile,
    productKeys: string[]
  ) => {
    if (productKeys.length > 0) {
      let pdfDocuments: Array<PdfDocument> = [];

      const jackets = file.jackets?.filter(
        (jacket) =>
          productKeys.includes(jacket.integrationKey) &&
          jacket.documents &&
          jacket.documents.length > 0
      );

      // forEach does not support async call
      for (const jacket of jackets) {
        const documentId =
          jacket.documents && jacket.documents.length > 0
            ? Number(jacket.documents[0].id)
            : 0;

        if (documentId > 0) {
          // get pdf url here
          const actionResult: ActionResult = await getDocumentUrl(
            jacket.fileID || 0,
            ProductType.Jacket,
            jacket.orderID || 0,
            documentId,
            0
          );

          if (actionResult && actionResult.pdfUrl) {
            const pdfFilename = `${file.fileNameNumber} Jacket ${jacket?.formType} ${jacket?.productReferenceID}`;
            pdfDocuments.push({
              fileId: jacket.fileID || 0,
              productType: ProductType.Jacket,
              orderId: jacket.orderID || 0,
              documentId: documentId,
              pdfUrl: actionResult.pdfUrl,
              fileName: pdfFilename,
              showHidden: 0,
            });
          }
        }
      }

      if (pdfDocuments.length > 0) {
        setPdfDocuments(pdfDocuments);
        setOpenPdfViewer(true);
      }
    }
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey && document?.getElementById(ProductTypeName.STANDALONE_ENDORSEMENT)) {
      evt.preventDefault();
      document?.getElementById(ProductTypeName.STANDALONE_ENDORSEMENT)?.focus();
    }
  };
  //#endregion

  useEffect(() => {
    if (jackets.length === 0) {
      const newJacket = getDefaultJacketObject();
      newJacket.integrationKey = uuidv4();
      append(newJacket);
      setValue(`jackets.0`, newJacket);
    }
  }, [append, jackets?.length]);

  useEffect(() => {
    getVoidReasons(ProductType.Jacket);
  }, []);

  React.useEffect(() => {
    companyStateLocationsRef.current = companyStateLocations;
  }, [companyStateLocations]);  

  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => {
          const orderParties = getValues(`jackets.${index}.orderParties`);
          return (
            <JacketProduct
              isPdfViewerOpen={openPdfViewer}
              key={field.fieldId}
              productIndex={index}
              isRevisionMode={disableAddJacket}
              orderParties={orderParties}
              onSimultaneousChanged={onSimultaneousChanged}
              forceRevisionMode={
                forceRevisionForIntegrationKey === field.integrationKey
              }
              deleteJacket={async () => {
                await deleteJacket(index);
              }}
              reviseJacket={async (integrationKey: string) =>
                await onReviseJacket(integrationKey).catch((e) => {
                  throw e;
                })
              }
              issueJacket={async (integrationKey: string) => {
                return await issueSingleJacket(integrationKey, index);
              }}
              issueButtonDisabled={issueDisabled || isFileDisabled}
              submitReviseDisabled={submitReviseDisabled}
              endorsementEditMode={isEndorsementEditMode(index)}
              disableEndorsementSubmit={
                isEndorsementSubmitDisabled(index) || isPpeSubmitButtonDisabled
              }
              handleEndorsementGridCancel={() =>
                handleEndorsementGridCancel(index, field)
              }
              handleEndorsementGridSubmit={() =>
                handleEndorsementGridSubmit(
                  field,
                  field.integrationKey,
                  field.orderID
                )
              }
              handleRemittanceCalculation={handleRemittanceCalculation}
              setDisableAddJacket={setDisableAddJacket}
              onEffectiveDateChange={(date: Date | null) =>
                handleEffectiveDateChange(index, date)
              }
            />
          );
        })}
        <Box>

          <AddLink
            onClick={addNewJacket}
            disabled={disableAddJacket || isFileDisabled}
            onKeyDown={handleKeyDown}
          >
            Add Jacket
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialog
        confirmationMessage="Are you sure you want to delete this pending Jacket?"
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleNo}
      />
      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title={statusMessage}
        closeButtonText="Done! Click to Continue"
        isOpen={openJacketIssueDialog}
        onClose={onIssueDialogClosed}
        requestId={issueJacketRequestId}
        runProgress={runJacketProgress}
        progressBaseline={jacketProgressBaseline}
      />
      <QADialog
        isOpen={isQAOpen}
        isQASubmitButtonDisabled={isQASubmitButtonDisabled}
        onClose={handleCloseQnA}
        onSubmit={handleCompletedQnA}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={handleClosePdfViewer}
        pdfDocuments={pdfDocuments}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleErrorDialogClose}
      />
    </>
  );
};

export default JacketProductCollection;
