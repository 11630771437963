import {
  Box,
  Button,
  Container,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiDivider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Remove
} from "@mui/icons-material";
import React, {
  ReactElement,
  useState,
} from "react";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";

type Props = {
  index: number;
  children: ReactElement;
  title: string;
  label?: string;
  isLoading?: boolean;
  showDivider?: boolean;
  isProductIssuePending?: boolean;
  showDelete?: boolean;
  onDelete?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.custom.container.inner.root,
      minWidth: "100%",
      display: "flex",
      flexDirection: "column",
      gap: gaps.large1,
      "& .MuiCollapse-hidden": {
        display: "none",
      },
    },
    blueIcon: {
      color: colors.blue03,
      ".noneditableproduct &": {
        color: colors.blue04,
      },
    },
    closeButton: {
      minWidth: iconSize.medium,
      width: iconSize.medium,
      height: iconSize.medium,
      padding: padding.zero,
    },
    darkIcon: {
      color: colors.grey08,
      ".noneditableproduct &": {
        color: colors.blue04,
      },
    },
  })
);

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    gap: gaps.large1,
    width: "100%",
    "&:not(:last-child)": {
      borderBottom: borderSize.zero,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: margin.zero,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    margin: margin.zero,
    minHeight: 10,
    padding: padding.zero,
    width: "100%",
    "&$expanded": {
      minHeight: 10,
    },
  },
  content: {
    margin: margin.zero,
    width: "100%",
    "&$expanded": {
      margin: margin.zero,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordianHeader = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiSvgIcon-root": {
      height: iconSize.medium,
      width: iconSize.medium,
    },
  }
})(Box);

const AccordionDetails = withStyles(() => ({
  root: {
    marginBottom: margin.zero,
    padding: padding.zero,
  },
}))(MuiAccordionDetails);

const Divider = withStyles(() => ({
  root: {
    backgroundColor: colors.grey09,
    margin: margin.zero,
  },
}))(MuiDivider);

const TitleExpandCollapseBox = withStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: gaps.small1,
  },
}))(Box);

const HeaderPrimaryText = withStyles(() => ({
  root: {
    color: colors.blue03,
    display: "flex",
    flexDirection: "row",
    fontSize: fontSize.xlarge0,
    fontWeight: fontWeight.normal2,
    gap: gaps.small1,
    overflow: "visible",
    // paddingLeft: "0.3em",
    whiteSpace: "nowrap",
    "& .MuiSvgIcon-root": {
      width: iconSize.medium,
      height: iconSize.medium,
    },
    "& .MuiTypography-root ": {
      padding: padding.zero,
    },
  },
}))(Typography);

const HeaderSecondaryText = withStyles(() => ({
  root: {
    alignItems: "center",
    color: colors.grey07,
    display: "inline-block",
    fontSize: fontSize.large,
    fontWeight: "normal",
    maxWidth: 900,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))(Typography);

const CollapsableFormSection = ({
  index,
  children,
  title,
  isLoading,
  label,
  showDelete,
  showDivider = true,
  isProductIssuePending = true,
  onDelete,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const classes = useStyles();

  const handleHeaderKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCollapseToggle();
    }
  };

  const handleCollapseToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDelete = (event?: React.MouseEvent<HTMLElement> | undefined) => {
    event?.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={isExpanded}
        className={isProductIssuePending ? "" : "noneditableproduct"}
      >
        <AccordionSummary
          aria-controls={`panel${index}-content`}
          id={"panelHeader-".concat(title.replace("/", "").replace(" ", ""))}
          onKeyDown={handleHeaderKeyDown}
          tabIndex={0} // Needed for keydown event.
        >
          <AccordianHeader onClick={handleCollapseToggle}>
            <TitleExpandCollapseBox id={title.replace("/", "").replace(" ", "")} >
              <HeaderPrimaryText title={title} >
                {title}
              </HeaderPrimaryText>

              {label?.trim() && !isExpanded && (
                <>
                  <Remove className={classes.darkIcon} />

                  <HeaderSecondaryText title={label} >
                    {label}
                  </HeaderSecondaryText>
                </>
              )}

              {isExpanded ?
                <ExpandLess className={classes.blueIcon} />
                :
                <ExpandMore className={classes.blueIcon} />
              }

            </TitleExpandCollapseBox>
            {showDelete && (
              <Button
                className={classes.closeButton}
                onClick={handleDelete}
              >
                <Close className={classes.blueIcon} />
              </Button>
            )}
          </AccordianHeader>
        </AccordionSummary>
        <AccordionDetails>
          <Container
            maxWidth="lg"
            className={classes.root}
          >
            {children}
          </Container>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </div >
  );
};

export default CollapsableFormSection;
