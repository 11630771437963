import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import React, {
  ReactNode,
} from "react";
import theme from "theme/default";
import {
  margin,
  padding,
} from "theme/defaultStyle";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";

interface OriginalPolicyProps {
  isOpen: boolean;
  title?: string;
  disabled?: boolean;
  allowSave?: boolean;
  children: ReactNode;
  onClose: () => void;
  onSave: () => void;
}

const StyledDialog = styled(Dialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
      width: "330px",
    },
  }
});

const StyledDialogContent = styled(DialogContent)({
  margin: margin.zero,
  paddingTop: padding.xlarge21,
});

const OriginalPolicyDialog = ({
  isOpen,
  title = "Original Policy",
  disabled = false,
  allowSave = true,
  children,
  onSave,
  onClose,
}: OriginalPolicyProps) => {
  const classes = useDialogStyles({ type: "INFO" });

  return (
    <StyledDialog
      open={isOpen}
      maxWidth="md"
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        disableTypography={true}
        classes={{ root: classes.dialogTitleRoot }}
      >
        {title}
        <DialogTitleCloseButton onClick={onClose}/>
      </DialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot, }}>
        {disabled ? (
          <ActionButton
            autoFocus
            variant="outlined"
            color="primary"
            onClick={() => { onClose?.(); }}
            startIcon={<ClearIcon />}
          >
            Close
          </ActionButton>
        ) : (
          <>
            <ActionButton
              autoFocus
              variant="outlined"
              color="primary"
              style={{ minWidth: "100px" }}
              onClick={onSave}
              disabled={!allowSave}
              startIcon={<CheckIcon />}
            >
              Save
            </ActionButton>
          </>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default OriginalPolicyDialog;
