import React from "react";
import SelectField from "../select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { useCompanyMunicipal } from "utils/context/CompanyMunicipalContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import { useWatch } from "react-hook-form";
import { TaxCodePremium, TaxCodeSearchBy } from "utils/data/enum";
import usePricing from "utils/custom-hooks/usePricing";
import { usePricingActions } from "utils/context/PricingContext";
import { useIsFileLoadingFiles } from "utils/context/FilesContext";
import useDidMountEffect from "utils/custom-hooks/useDidMountEffect";

type Props = {
  name: any;
  idFieldName: string;
  cityFieldName: string;
  countyFieldName: string;
  taxCodes: SelectFieldOption[];
  disabled?: boolean;
  onBlur?: any;
  labelPrefix?: string;
  setNotDefault: () => void;
};

const TaxCodeField = ({
  taxCodes,
  name,
  idFieldName,
  cityFieldName,
  countyFieldName,
  disabled = false,
  onBlur,
  labelPrefix = "",
  setNotDefault,
}: Props) => {
  const [{ municipal }] = useCompanyMunicipal();
  const { calculateTaxes } = usePricing();
  const [, { setPricingRecalculateRequired }] = usePricingActions();
  const [id, city, countyName] = useWatch({
    name: [
      getNameString(idFieldName),
      getNameString(cityFieldName),
      getNameString(countyFieldName),
    ],
  });
  const { setValue, getValues } = useFormWrapper();

  const getTaxCodeByCityOrCounty = (searchName: string, searchBy: string) => {
    if (searchBy === TaxCodeSearchBy.Default) {
      return {
        text: TaxCodePremium.Default,
        value: TaxCodePremium.Default,
      };
    }

    if (!searchName) return;

    if (municipal && municipal.taxCodes && municipal.taxCodes.length > 0) {
      // If MuncipleCode is already selected for a City, then do no search for County Change
      // SearchBy City must satisfy IsMunicipality = Yes, IsCounty = No
      // SearchBy Count must satisfy no matching taxCode for City & IsMunicipality = No, IsCount = Yes
      const currentTaxCode = getValues(name);

      let matchingTaxCode: any = undefined;
      if (searchBy === TaxCodeSearchBy.City) {
        matchingTaxCode = municipal.taxCodes.find(
          (t) =>
            t.searchName === searchName.toUpperCase() &&
            t.isMunicipality === "Y" &&
            t.isCounty === "N"
        );
      } else if (searchBy === TaxCodeSearchBy.County) {
        if (
          city &&
          currentTaxCode &&
          currentTaxCode.text &&
          !currentTaxCode.text.includes(TaxCodePremium.Default)
        )
          return;

        matchingTaxCode = municipal.taxCodes.find(
          (t) =>
            t.searchName === searchName.toUpperCase() &&
            t.isMunicipality === "N" &&
            t.isCounty === "Y"
        );
      }

      if (!matchingTaxCode)
        matchingTaxCode = municipal.taxCodes.find(
          (t) => t.municipalCode === TaxCodePremium.Default
        );

      return matchingTaxCode
        ? {
            text: matchingTaxCode.displayName,
            value: matchingTaxCode.municipalCode,
          }
        : { text: "", value: "" };
    }
  };

  const handlePremiumTaxCodeChange = async (_: any, value: any) => {
    const isOverride: boolean = getValues("pricing.isOverride");
    const isIntegratedPricing = getValues("pricing.isIntegratedPricing");
    const hasIssuedProducts = getValues("hasIssuedProducts");
    setNotDefault();

    if (
      name === "properties.0.taxCode" &&
      isIntegratedPricing &&
      hasIssuedProducts &&
      value &&
      isOverride
    ) {
      setPricingRecalculateRequired(true);
      calculateTaxes();
    }
  };

  const handleCalculateTaxes = (newValue: string, fieldName: string) => {
    const hasIssuedProducts = getValues("hasIssuedProducts");
    const taxCode = getTaxCodeByCityOrCounty(newValue, fieldName);

    if (taxCode) setValue(name, taxCode);

    if (hasIssuedProducts && name === "properties.0.taxCode") {
      setPricingRecalculateRequired(true);
      const old1stPropertyTaxCode = getValues(`properties.0.taxCode`);
      const isOverride: boolean = getValues("pricing.isOverride");

      if (
        isOverride &&
        old1stPropertyTaxCode &&
        taxCode &&
        old1stPropertyTaxCode.value !== taxCode.value
      ) {
        calculateTaxes();
      }
    }
  };
  const [{ isFileLoading }] = useIsFileLoadingFiles();

  // Skip the first render. Only runs when the dependencies change
  useDidMountEffect(() => {
    if (isFileLoading) return;
    handleCalculateTaxes(countyName, TaxCodeSearchBy.County);
  }, [countyName]);

   // Skip the first render. Only runs when the dependencies change
  useDidMountEffect(() => {
    if (isFileLoading) return;
    handleCalculateTaxes(city, TaxCodeSearchBy.City);
  }, [city]);

  React.useEffect(() => {
    if (isFileLoading) return;
    if (municipal && municipal.taxCodes && municipal.taxCodes.length > 0) {
      let taxCodeRetrieved: any = null;
      const id = getValues(idFieldName); //TODO: This is a workaround using getValues, it don't happens in React 18 so it should removed it (US: 116585)
      if (id === 0) {
        if (city) {
          taxCodeRetrieved = getTaxCodeByCityOrCounty(
            city,
            TaxCodeSearchBy.City
          );
        } else if (countyName) {
          taxCodeRetrieved = getTaxCodeByCityOrCounty(
            countyName,
            TaxCodeSearchBy.County
          );
        } else {
          taxCodeRetrieved = getTaxCodeByCityOrCounty(
            "",
            TaxCodeSearchBy.Default
          );
        }

        if (taxCodeRetrieved) setValue(name, taxCodeRetrieved);
      }
    }
  }, [municipal.taxCodes, id, city, countyName]);

  return (
    <SelectField
      label={`${labelPrefix}Premium Tax Code`}
      options={taxCodes}
      name={name}
      dataTextField="text"
      dataValueField="value"
      disabled={disabled}
      onBlur={onBlur}
      onChange={handlePremiumTaxCodeChange}
    />
  );
};

export default TaxCodeField;
