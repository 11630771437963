import React from "react";
import Box from "@mui/material/Box";
import TextInputField from "controls/global/text-input-field";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";

const StyledBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
});

const AgencyField: React.FC = () => {
  const { setValue } = useFormContext();

  return (
    <StyledBox>
      <TextInputField
        placeholder="Search Criteria (e.g. agency name, agency ID, location address)"
        onChange={(e) => setValue("agency", e.target.value)}
        name="agency"
      />
    </StyledBox>
  );
};

export default AgencyField;
