import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import {
  Column,
  IColumn,
} from "entities/ApiModel/IColumn";
import { ReportAndPaySearchResult } from "entities/ApiModel/ReportAndPaySearchResult";
import { ReportAndPayKeywordSearch } from "entities/UIModel/ReportAndPayKeywordSearch";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import {
  groupBy,
  sortBy,
} from "lodash";
import { getReportPayByCriteria } from "pages/payments/utils/ReportPayTable";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";
import { LocalStorageKeys } from "utils/data/enum";
import {
  startNotifying,
  stopNotifying,
} from "utils/services/ContextProgressNotifierService";

interface State {
  progressPercent?: number;
  refreshData: boolean;
  error?: string;
  axiosCancelToken?: CancelTokenSource;
  requestTimeoutMs: number;
  selectedItems: ReportPayFile[];
  onlyShowSelectedFiles: boolean;
  isAdvSearchOpen: boolean;
}
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const startProgressNotififer =
  (criteria: ReportAndPayCriteriaSearch) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: ReportAndPayCriteriaSearch) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const getSearchType = (criteria?: ReportAndPayCriteriaSearch) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "reportpay-long" : "reportpay-short";
};

const mapReportPayFromApiToUIModel = (reportPayListResponse: ReportAndPaySearchResult[]) => {
  const grouped = Object.values(groupBy(reportPayListResponse, f => f.FileID));
  const sorted = sortBy(grouped, g => reportPayListResponse.indexOf(g[0]));
  const mapped = sorted
    .map(g => {
      const firstItem = g[0];
      const group: ReportPayFile = {
        uniqueIdentifier: firstItem.FileID.toString(),
        selected: false, // index === 1  || index === 3 || index === 8 ? true:false,
        fileSearchId: firstItem.FileSearchID,
        fileId: firstItem.FileID,
        clientFileId: firstItem.ClientFileID || "",
        agencyID: firstItem.AgencyID || "",
        isExcludedFromAutoReport: firstItem.IsExcludedFromAutoReport ?? 0,
        locationDisplayNames: firstItem.LocationDisplayNames || "",
        locationLegacyIDs: firstItem.LocationLegacyIDs ?? "",
        agencyName: firstItem.AgencyName || "",
        totalActualRetention: firstItem.TotalActualRetention ?? 0,
        fileStatusTypeCode: firstItem.FileStatusTypeCode || "",
        transactionTypeCode: firstItem.TransactionTypeCode || "",
        propertyTypeCodes: firstItem.PropertyTypeCodes || "",
        propertyAddresses: firstItem.PropertyAddresses || "",
        stateAbbr: firstItem.StateAbbr || "",
        buyerNames: firstItem.BuyerNames || "",
        sellerNames: firstItem.SellerNames || "",
        lenderNames: firstItem.LenderNames || "",
        openDate: firstItem.OpenDate ? new Date(firstItem.OpenDate) : null,
        productSerialNumbers: "",
        totalActualRiskRate: firstItem.TotalActualRiskRate ?? null,
        productNames: firstItem.ProductNames || "",
        firstJacketEffectiveDate: firstItem.FirstJacketEffectiveDate ? new Date(firstItem.FirstJacketEffectiveDate.substring(0, 19)) : null,
        firstJacketIssueDate: firstItem.FirstJacketIssueDate ? new Date(firstItem.FirstJacketIssueDate.substring(0, 19)) : null,
        totalActualFee: firstItem.TotalActualFee ?? null,
        totalAmountDue: firstItem.TotalAmountDue ?? null,
        originationUser: "",
        currentPage: firstItem.CurrentPage || 0,
        totalPages: firstItem.TotalPages || 0,
        totalRows: firstItem.TotalRows || 0,
        rowsPerPage: firstItem.RowsPerPage || 0,
        details: g.map((file) => ({
          product: file.Product || "",
          muniCnty: file.MuniCnty || "",
          transCode: file.TransCode || "",
          liability: file.Liability || 0,
          productIssueDate: file.ProductIssueDate ? new Date(file.ProductIssueDate) : null,
          productEffectiveDate: file.ProductEffectiveDate ? new Date(file.ProductEffectiveDate) : null,
          actualFee: file.ActualFee || 0,
          taxRiskRate: file.TaxRiskRate || 0,
          actualRetention: file.ActualRetention || 0,
          amountDue: file.AmountDue || 0,
        }))
      };
      return group;
    });

  return mapped;
};

const setReportPayList =
  (reportPayListResponse: ReportAndPaySearchResult[]) =>
    (): ReportPayFile[] => {
      if (!reportPayListResponse || reportPayListResponse.length === 0) {
        return [];
      }

      return mapReportPayFromApiToUIModel(reportPayListResponse);
    };

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof ReportPayFile);

      localStorage.setItem(LocalStorageKeys.COLUMNS_REPORT_PAY, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_REPORT_PAY_HIDDEN, JSON.stringify(gridHiddenColumns));

      return { gridColumns, gridHiddenColumns };
    };

const actions = {
  setRequestTimeoutMs:
    (requestTimeoutMs: number) =>
      async ({ setState }: StoreApi) => {
        setState({ requestTimeoutMs });
      },
  cancelRequests:
    () =>
      async ({ dispatch, getState }: StoreApi) => {
        dispatch(stopProgressNotififer());
        const token = getState().axiosCancelToken;
        if (token) {
          token.cancel("ReportPayContext was requested to be cancelled");
        }
      },
  getReportPayListLocal: (criteria: ReportAndPayCriteriaSearch) =>
    async ({ getState }: StoreApi) => {
      const response = getState().selectedItems;
      const filteredReportPayList = response
        ? response.filter(
          (reportPayFile) =>
            getReportPayByCriteria(reportPayFile, criteria))
        : [];
      if (filteredReportPayList.length > 0) {
        const size = criteria.listCount || 10;
        filteredReportPayList[0].totalRows = filteredReportPayList.length;
        filteredReportPayList[0].totalPages = filteredReportPayList.length / size;
        filteredReportPayList[0].currentPage = criteria.requestedPage || 1;
      }
      return filteredReportPayList;
    },
  getReportPayList:
    (criteria: ReportAndPayCriteriaSearch) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("ReportPayContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/reportandpaybycriteria",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));

          return dispatch(setReportPayList(data));
        } catch (error: any) {
          setState({ error: error.message });
          dispatch(stopProgressNotififer());
        }
      },
  getAllReportPayList:
    (criteria: ReportAndPayCriteriaSearch) =>
      async () => {
        try {
          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/reportandpaybycriteria",
            criteria,
            {
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          return mapReportPayFromApiToUIModel(data);
        } catch (error: any) {
          return [];
        }
      },
  getAdvancedSearchFiles:
    (criteria: ReportAndPayKeywordSearch) =>
      async ({ dispatch, setState, getState }: StoreApi) => {
        try {
          dispatch(startProgressNotififer(criteria));

          const token = getState().axiosCancelToken;
          if (token) {
            token.cancel("ReportPayContext was cancelled due to new request");
          }

          const newCancelToken = cancelToken.source();
          setState({ error: undefined, axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.post(
            "/reportandpay/reportandpaybykeyword",
            criteria,
            {
              cancelToken: newCancelToken.token,
              timeout: getState().requestTimeoutMs,
              timeoutErrorMessage: "TIMEOUT",
            }
          );
          dispatch(stopProgressNotififer(criteria));

          return dispatch(setReportPayList(data));
        } catch (error: any) {
          setState({ error: error.message });
          dispatch(stopProgressNotififer());
        }
      },
  refresh:
    () =>
      ({ setState, getState }: StoreApi) =>
        setState({ refreshData: !getState().refreshData }),
  getInitialColumnsDefintion:
    (hidden: boolean) =>
      () => {
        const key = hidden
          ? LocalStorageKeys.COLUMNS_REPORT_PAY_HIDDEN
          : LocalStorageKeys.COLUMNS_REPORT_PAY;
        const existingColumns = localStorage.getItem(key) || "[]";

        return JSON.parse(existingColumns);
      },
  getColumnsDefinition:
    () =>
      async ({ dispatch }: StoreApi) => {
        const { data } = await axiosSecuredInstance.get(
          "/UISettings/grids/columnSettings/reportpay"
        );

        return dispatch(setColumns(data));
      },
  setColumnDefinition:
    (
      fields: (keyof ReportPayFile)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
      async ({ dispatch }: StoreApi) => {
        const data: IColumn[] = fields.map((field) => ({
          field,
          name: "", //NOT REQUIRED,
          [propName]: value,
        }));

        try {
          const response = await axiosSecuredInstance.post("/UISettings/grids/columnSettings/reportpay", data);
          if (response.data) {
            dispatch(setColumns(response.data));
          }
        } catch (error) {
          console.error(error);
        }
      },
  setOnlyShowSelectedFiles: (value: boolean) => ({ setState }: StoreApi) => {
    setState({ onlyShowSelectedFiles: value });
  },
  updateSelectedItem: (item: ReportPayFile) => ({ setState, getState }: StoreApi) => {
    const currentItems = getState().selectedItems || [];
    const fileId = item.fileId;

    let updatedItems = currentItems.filter(f => f.fileId !== fileId);
    if (updatedItems.length === currentItems.length) {
      updatedItems.push(item);
    }

    let updateShowSelectedFiles = getState().onlyShowSelectedFiles;
    if (updateShowSelectedFiles && (updatedItems.length === currentItems.length)) {
      updateShowSelectedFiles = false;
    }

    setState({
      selectedItems: updatedItems,
      onlyShowSelectedFiles: updateShowSelectedFiles,
    });
  },
  updateSelectedItems: (items: ReportPayFile[]) => ({ setState, getState }: StoreApi) => {
    const currentItems = getState().selectedItems || [];
    const newItems = items.filter(item => !currentItems.find(obj => obj.fileId === item.fileId));

    let updatedItems: ReportPayFile[] = [];
    if (newItems.length > 0) {
      updatedItems = currentItems.concat(newItems);
    }

    let updateShowSelectedFiles = getState().onlyShowSelectedFiles;
    if (updateShowSelectedFiles && (updatedItems.length === currentItems.length)) {
      updateShowSelectedFiles = false;
    }

    setState({
      selectedItems: updatedItems,
      onlyShowSelectedFiles: updateShowSelectedFiles,
    });
  },
  clearSelectedItems: () => ({ setState }: StoreApi) => {
    setState({ selectedItems: [] });
  },
  restoreSelectedItems: () => ({ setState }: StoreApi) => {
    setState({
      selectedItems: [],
      onlyShowSelectedFiles: false,
      isAdvSearchOpen: false
    });
  },
  setIsAdvSearchOpen: (value: boolean) => ({ setState }: StoreApi) => {
    setState({ isAdvSearchOpen: value });
  },
};

const Store = createStore<State, Actions>({
  initialState: {
    progressPercent: -1,
    refreshData: false,
    requestTimeoutMs: 45000,
    selectedItems: [],
    onlyShowSelectedFiles: false,
    isAdvSearchOpen: false,
  },
  actions,
  name: "reportpay",
});

const hook = createHook(Store);
export const useReportPay = () => { return hook(); };
