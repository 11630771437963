import {
  styled,
} from "@material-ui/core";
import LightTooltip from "controls/global/light-tooltip";
import LinkButton from "controls/global/link-button";
import TextInputField from "controls/global/text-input-field";
import {
  GeneralFileParty,
  InsuredNamesData,
  Jacket,
} from "entities/UIModel";
import { isValidParty } from "pages/file/utils/helper";
import { getDisplayName } from "pages/file/utils/products/letter";
import React, {
  useState,
} from "react";
import {
  usePartyBuyerBorrowerNames,
  usePartyBuyerHasValidBuyer,
} from "utils/context/PartyBuyerBorrowerContext";
import {
  usePartyLenderHasValidLender,
  usePartyLenderNames,
} from "utils/context/PartyLenderContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  JacketFormType,
  OrderStatusType,
  ProductType,
} from "utils/data/enum";

type Props = {
  schema: string;
  disabled: boolean;
  orderParties: GeneralFileParty[];
  insuredNames: string | undefined;
  insuredNamesData: InsuredNamesData | undefined;
  orderStatusTypeCode: string;
  formType: string | undefined;
  jacket: Jacket;
};

const TooltipButtonContainer = styled("div")({
  flexGrow: 2,
  minWidth: "350px",
  position: "relative",
  width: "unset",
  "& .tooltip-button": {
    height: "10px",
    position: "absolute",
    right: "10px",
    top: "0",
    width: "80px",
  },
  " & .MuiButtonBase-root": {
    top: "-13px",
  },
});

const JacketInsuredNames = ({
  schema,
  disabled,
  orderParties = new Array<GeneralFileParty>(),
  insuredNames,
  insuredNamesData = {},
  orderStatusTypeCode,
  formType,
  jacket,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isInsuredResetButtonDisabled = useFieldDisabler("JACKETInsuredNameResetButton");

  //#region - Party Context values
  const [buyerBorrowerNames] = usePartyBuyerBorrowerNames(ProductType.Jacket);
  const [buyerBorrowerHasValidBuyer] = usePartyBuyerHasValidBuyer(ProductType.Jacket);

  const [lenderNames] = usePartyLenderNames();
  const [lenderHasValidLender] = usePartyLenderHasValidLender();

  const calculateInsuredNames = (parties: GeneralFileParty[]) => {
    const validParties = parties.filter((party) => isValidParty(party));
    const names = validParties.map((party) => getDisplayName(party));

    return names.join(", ");
  };

  const evalInsuredNamesResetDisabled = () => {
    const hasAnyValidParties = () => {
      let validParty = false;
      if (formType) {
        if (formType === JacketFormType.Owners) {
          validParty = buyerBorrowerHasValidBuyer;
        } else {
          validParty = lenderHasValidLender;
        }
      }

      return validParty;

    };

    const validParties = hasAnyValidParties();
    const fieldDisabled = disabled;

    /* NOTE:  Per IM Conversation with Maria, this is the rule set
     * No Party available = Reset Disabled, even if they type something in
     * Party available and Pending/Revising = Reset Enabled (even if they haven't typed anything)
     * Party Available and Issued/Voided = Reset Disabled
     */
    const shouldResetBeDisabled = fieldDisabled || !validParties;

    return shouldResetBeDisabled;
  };

  const [insuredNamesResetDisabled, setInsuredNamesResetDisabled] =
    React.useState(() => evalInsuredNamesResetDisabled());

  const { setValue } = useFormWrapper();

  const setInsuredNames = (names: string | undefined) => {
    if (jacket) {
      setValue(`${schema}.insuredNames`, names);
    }
  };

  const setInsuredNamesData = (data: InsuredNamesData | undefined) => {
    if (jacket) {
      setValue(`${schema}.insuredNamesData`, data);
    }
  };

  const populateInsuredNames = () => {

    if (insuredNamesData.hasInsuredNamesChanged || !formType || (orderStatusTypeCode === OrderStatusType.Issued)) return;

    let newInsuredNames = "";
    if (formType === JacketFormType.Owners) newInsuredNames = buyerBorrowerNames;
    else newInsuredNames = lenderNames;

    setInsuredNames(newInsuredNames);

  };

  const handleInsuredNamedChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    insuredNamesData.hasInsuredNamesChanged = true;
    setInsuredNamesData(insuredNamesData);
  };

  const resetInsuredNames = () => {
    insuredNamesData.hasInsuredNamesChanged = false;
    setInsuredNamesData(insuredNamesData);

    populateInsuredNames();
  };

  const openTooltip = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled) return;
    setIsTooltipOpen(true);
  };

  const closeTooltip = () => {
    setIsTooltipOpen(false);
  };

  React.useEffect(() => {
    if (insuredNamesData.hasInsuredNamesChanged === true) return;

    // Populate existing orderParties
    if (
      orderStatusTypeCode !== OrderStatusType.Pending &&
      orderStatusTypeCode !== OrderStatusType.Error
    ) {
      if (insuredNames === null || insuredNames === "") {
        setInsuredNames(calculateInsuredNames(orderParties || []));
      } else populateInsuredNames();
    } else {
      populateInsuredNames();
    }
  }, [
    schema,
    buyerBorrowerNames,
    lenderNames,
    orderStatusTypeCode,
    formType,
  ]);

  React.useEffect(() => {
    const isDisabled = evalInsuredNamesResetDisabled();
    setInsuredNamesResetDisabled(isDisabled);
  }, [
    insuredNamesData,
    insuredNames,
    formType,
    lenderNames,
    buyerBorrowerNames,
  ]);

  return (
    <TooltipButtonContainer>
      <TextInputField
        label="Insured Name(s)"
        name={`${schema}.insuredNames`}
        onChange={handleInsuredNamedChanged}
        disabled={disabled}
        actionButton={
          <LightTooltip title="Reset Insured Name(s)" open={isTooltipOpen} arrow>
            <div
              data-disabled={disabled}
              className={"tooltip-button"}
            >
              <LinkButton
                disabled={insuredNamesResetDisabled || isInsuredResetButtonDisabled}
                onClick={resetInsuredNames}
                buttonStyle="floatRight"
                tabIndex="-1"
                disabledTextField={disabled}
                onMouseEnter={openTooltip}
                onMouseLeave={closeTooltip}
              >
                <span className="MuiButton-label">Reset</span>
              </LinkButton>
            </div>
          </LightTooltip>
        }
      />
    </TooltipButtonContainer>
  );
};
export default JacketInsuredNames;
