import {
  Box,
  styled,
} from "@material-ui/core";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import { useLookup } from "utils/context/LookupContext";
import { usePartyBuyerBorrowerActions } from "utils/context/PartyBuyerBorrowerContext";
import { useParty } from "utils/context/PartyContext";
import { usePartyTypes } from "utils/context/PartyTypesContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useProductOption from "utils/custom-hooks/useProductOption";
import {
  ProductType,
  PartyRoleTypeName,
} from "utils/data/enum";
import { getDefaultBuyerBorrowerObject } from "utils/data/parties";
import { v4 as uuidv4 } from "uuid";
import BuyerBorrowerData from "./BuyerBorrowerData";
import useFocus from "utils/custom-hooks/useFocus";

const formPrefix = "buyerBorrowerParties";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const BuyerBorrowerCollection = () => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [partyIndex, setPartyIndex] = useState<number>(0);
  const [deletePartyIndex, setDeletePartyIndex] = useState(-1);
  const { setValue, getValues } = useFormWrapper();
  const [, { setBuyerBorrowers }] = usePartyBuyerBorrowerActions();
  const { setFocusInputElement } = useFocus();

  const watchedFields = useWatch({
    name: [
      "fileNameNumber",
      "agency",
      "properties.0",
      "additionalParties",
      "hasIssuedProducts",
      "isPopulatingExistingFile",
    ],
  });
  const fileNameNumber = watchedFields[0];
  const agency = watchedFields[1];
  const primaryProperty = watchedFields[2];
  const additionalParties = watchedFields[3];
  const hasIssuedProducts = watchedFields[4];
  const isPopulatingExistingFile = watchedFields[5];
  const { fields, append, remove } = useFieldArray({
    name: formPrefix,
    keyName: "fieldId",
  });
  const isFileDisabled = useFieldDisabler("BuyerBorrowerClearButton");

  const { getUniqueProductOptions } = useProductOption();

  const [, { deleteParty }] = useParty();
  const [{ suffixes }] = useLookup();
  const [{ basicPartyTypes }, { getPartyTypes }] = usePartyTypes();

  const getLetterProductType = () => {
    const uniqueProductOptions = getUniqueProductOptions();
    if (uniqueProductOptions.length > 0) {
      const hasCplProduct = uniqueProductOptions.find(
        (po) => po.product.toUpperCase() === ProductType.Cpl
      );
      if (hasCplProduct) return ProductType.Cpl.toString();
      else return ProductType.Aal.toString();
    }
    return undefined;
  };

  const letterProductType: string | undefined = getLetterProductType();

  const handleAddItem = (e: any) => {
    e.preventDefault();
    setPartyIndex(partyIndex + 1);
    let filePartyKey = uuidv4();
    const fileParty = getDefaultBuyerBorrowerObject();
    fileParty.filePartyKey = filePartyKey;
    fileParty.integrationKey = filePartyKey;
    fileParty.isDefault = false;
    fileParty.isDirty = true;
    append(fileParty);

    //once you append a new party, the first is no longer default
    setValue(`${formPrefix}.0.isDefault`, false);
    setValue(`${formPrefix}.0.isDirty`, true);

    // set focus to last Buyer Borrower
    const newItemIndex = fields.length;
    setFocusInputElement(`buyerBorrowerParties.${newItemIndex}.partyTypeCode`);
  };

  const deleteBuyerBorrower = (index: number) => {
    setOpenConfirmationDialog(true);
    setDeletePartyIndex(index);
  };

  const handleYes = (e: any) => {
    if (deletePartyIndex !== -1) {
      const currentParty = fields[deletePartyIndex] as any;

      // Delete only if fileParty is in db
      if (
        currentParty.filePartyId &&
        currentParty.filePartyId > 0 &&
        currentParty.integrationKey
      )
        deleteParty(currentParty.integrationKey, agency.id, fileNameNumber);

      if (partyIndex >= 0) {
        remove(deletePartyIndex);
        setPartyIndex(partyIndex - 1);

        // Update PartyBuyerBorrowerContext
        setBuyerBorrowers(getValues("buyerBorrowerParties"));
      }

      setDeletePartyIndex(-1);
    }
    setOpenConfirmationDialog(false);
  };

  const handleNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      evt.preventDefault();
      document?.getElementById(PartyRoleTypeName.Seller)?.focus();
    }
  }

  useEffect(() => {
    if (basicPartyTypes.length === 0) getPartyTypes();
    if (fields.length > 0) {
      setPartyIndex(fields.length - 1);
    } else {
      const buyerBurrower = getDefaultBuyerBorrowerObject();
      append(buyerBurrower);

      // Update PartyBuyerBorrowerContext
      setBuyerBorrowers(getValues("buyerBorrowerParties"));

    }
  }, [getPartyTypes, fields, setPartyIndex, append]);

  return (
    <>
      <StyledGroup >
        {fields.map((field: any, index: number) => {
          const handleDeleteBuyerBorrower = () => {
            deleteBuyerBorrower(index);
          };

          return (
            <BuyerBorrowerData
              key={field.fieldId}
              formPrefix={formPrefix}
              field={field}
              index={index}
              isFileDisabled={isFileDisabled}
              onDeleteBuyerBorrower={handleDeleteBuyerBorrower}
              partyTypes={basicPartyTypes}
              suffixes={suffixes}
              letterProductType={letterProductType}
              fileNameNumber={fileNameNumber}
              agency={agency}
              primaryProperty={primaryProperty}
              additionalParties={additionalParties}
              hasIssuedProducts={hasIssuedProducts}
              isPopulatingExistingFile={isPopulatingExistingFile}
            ></BuyerBorrowerData>
          );
        })}

        <Box>
          <AddLink
            disabled={isFileDisabled}
            onClick={handleAddItem}
            onKeyDown={handleKeyDown}
          >
            Add Buyer/Borrower
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialog
        confirmationMessage="Are you sure you want to delete this party?"
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleNo}
      />
    </>
  );
};

export default BuyerBorrowerCollection;
