import React from "react";
import { useLookup } from "utils/context/LookupContext";
import SelectField from "controls/global/select-field";
import { getNameString } from "utils/custom-hooks/useFormWrapper";

interface Props {
  isDisabled?: boolean;
  schema?: string;
  onBlur?: any;
  onChange?: (e: any, item: any) => void;
}

const PropertyTypeField = ({
  isDisabled = false,
  schema,
  onBlur,
  onChange,
}: Props) => {
  const [{ propertyTypes }, { getPropertyTypes }] = useLookup();
  const propertyTypeFieldName = getNameString(`${schema}.propertyType`);

  React.useEffect(() => {
    if (propertyTypes.length === 0) getPropertyTypes();
  }, [propertyTypes, getPropertyTypes]);

  return (
    <SelectField
      label="Property Type"
      options={propertyTypes}
      name={propertyTypeFieldName}
      disabled={isDisabled}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default PropertyTypeField;
