import React, {
  ReactElement,
} from "react";
import {
  Box,
  styled,
} from "@material-ui/core";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  letterSpacing,
} from "theme/defaultStyle";

const StyledDiv = styled(Box)({
  alignItems: "center",
  color: colors.grey08,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  fontFamily: 'Roboto',
  fontSize: fontSize.xlarge0,
  fontStyle: "normal",
  fontWeight: fontWeight.normal1,
  gap: gaps.large1,
  justifyContent: "center",
  letterSpacing: letterSpacing.medium1,
  lineHeight: "160%",
  maxHeight: "100%",
  textAlign: "center",
  "& .agency": {
    fontWeight: fontWeight.bold2,
  },
  "& .contact": {
    display: "flex",
    flexDirection: "column",
    "& .email a": {
      color: colors.blue01,
      fontWeight: fontWeight.normal1,
      textDecoration: "underline",
    },
    "& .phone a": {
      color: colors.grey08,
      textDecorationLine: "none",
    },
  },
});

const AgencySupportCenter = (): ReactElement => {

  return (
    <>
      <StyledDiv>
        <div className="contact">
          <div className="agency">
            Agency Support Center
          </div>
          <div className="phone">
            <a href="tel:(844) 835-1200">
              (844) 835-1200
            </a>
          </div>
          <div className="email">
            <a href="mailto:CustomerCare@Stewart.com">
              CustomerCare@Stewart.com
            </a>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};

export default AgencySupportCenter;
