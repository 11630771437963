import {
  Box,
  styled,
  Typography,
} from "@material-ui/core";
import StewartCheckbox from "controls/global/stewart-checkbox";
import React from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";

interface Props<T> {
  handleCheckboxChange: (
    name: keyof T,
    value: any,
    execSearch: boolean,
  ) => void;
  checked: boolean,
}

const StyledBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  height: "100%",
});

const Label = styled(Typography)({
  color: colors.grey08,
  fontSize: fontSize.large,
});

const OpenCheckbox = <T extends object>({ handleCheckboxChange, checked, ...rest }: Props<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    handleCheckboxChange("userFilter" as keyof T, isChecked ? 1 : 0, true);
  };

  return (
    <StyledBox {...rest}>
      <StewartCheckbox
        {...{ checked }}
        onChange={handleChange}
        style={{ marginLeft: '-5px' }}
      />
      <Label>{"Only show files I've opened"}</Label>
    </StyledBox>
  );
};

export default OpenCheckbox;
