import { axiosSecuredInstance } from "configurations/axiosConfig";
import * as ApiModel from "entities/ApiModel";
import {
  PricingDataResult,
  FilePricingDetail
} from "entities/UIModel";
import { PricingCalculatedItem } from "entities/UIModel/PricingCalculatedItem";
import * as withSCFile from "pages/file/utils/fromApi/scFileCreator";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
interface State {
  isOrderSaving: boolean;
  isSaveDone: boolean;
}
type StoreApi = StoreActionApi<State>;

const initialState: State = {
  isOrderSaving: false,
  isSaveDone: false,
};

const actions = {
  processOPN:
    (scfile: ApiModel.SCFile, orderId: number, isIntegratedPricing: boolean, currentFilePricingDetailsUI: FilePricingDetail[]) =>
    async ({ setState }: StoreApi): Promise<PricingDataResult> => {
      let pricingDataResult: PricingDataResult = {
        pricingConfig: undefined,
        productPolicyTypes: [] as any,
        error: undefined,
        filePricingDetails: undefined,
      };

      try {
        setState({ isOrderSaving: true, isSaveDone: false });
        // 11/16/2021: the api returns the same scfile payload from UI with updated jacket's pricing details
        const { data: result } =
          await axiosSecuredInstance.post<ApiModel.SCFile>(
            `/order/ProcessOPN?orderId=${orderId}`,
            scfile
          );

        if (result) {
          if (result.FilePricing) {
            pricingDataResult.pricingConfig = withSCFile.createFilePricing(
              result.FilePricing
            );
          }
          if (result?.JacketProducts && result.JacketProducts.length > 0) {
            pricingDataResult.jackets = withSCFile.createJackets(result.JacketProducts);

            pricingDataResult.filePricingDetails = withSCFile.updateJacketFilePricingDetails(
              currentFilePricingDetailsUI,
              result.JacketProducts
            );

          }
        }
        pricingDataResult.pricingProducts = withSCFile.createPricingProducts(
          result.IsLocked === 1,
          // result.FilePricing?.IsIntegratedPricing || false,
          result.FilePricing?.ShowTax || false,
          result.JacketProducts,
          result.CPLProducts,
          result.AALProducts,
          result.StandaloneEndorsementProducts
        )
        setState({ isOrderSaving: false, isSaveDone: true });
        return pricingDataResult;
      } catch (error) {
        console.error(error);
        pricingDataResult.error = error;
        setState({ isOrderSaving: false, isSaveDone: false });
        return pricingDataResult;
      }
    },
  calculateItem:
    (
      scFile: ApiModel.SCFile,
      orderId: number,
      productType: string,
      effectiveDate: string = "",
      premium: number,
      hasRemittance: boolean = true,
      hasTax: boolean = true
    ) =>
    async () => {
      const { data } = await axiosSecuredInstance.post<PricingCalculatedItem>(
        `/order/CalculateItem?orderId=${orderId}&productType=${productType}&effectiveDate=${effectiveDate}&premium=${premium}&provideRemittance=${hasRemittance}&provideTax=${hasTax}&isHazardous=false`,
        scFile
      );
      return data;
    },
};

const Store = createStore<State, typeof actions>({
  initialState,
  actions,
  name: "order",
});

const hook = createHook(Store);
export const useOrder = () => {
  return hook();
};
