import { orderBy as _orderBy } from "lodash";
import {
  TableCell,
  TableRow,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import DatePickerSearPendingPay from "controls/global/datepicker-search/DatePickerSearch";
import MultiLineCell from "controls/global/stewart-table/MultiLineCell";
import StewartTable, {
  DetailsViewConfig,
  SelectionConfig,
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { IColumn } from "entities/ApiModel/IColumn";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import { getColumnConfig } from "utils/columnsGrid";
import { usePendingPay } from "utils/context/PendingPayContext";
import useReportOptionType from "utils/custom-hooks/useReportOptionType";
import { ReportingOptionType } from "utils/data/enum";
import {
  formatCurrency,
  formatDate,
  formatPaymentSheetOrderNumber,
} from "utils/shared";
import { Order } from "utils/sorting";
import InputSearchPendingpay from "../input-search/InputSearchPendingpay";
import PaymentDetailsGrid from "../payment-details-grid/PaymentDetailsGrid";
import ReportingOption from "../reporting-option";
import EPay from "./EPay";
import PayByCheck from "./PayByCheck";
import PendingPayFooter from "./PendingPayFooter";
import UndoReport from "./UndoReport";

type Props = {
  colsConfig: IColumn[];
  rows: PendingPayFile[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  noRowsMessage: string[];
  onlyShowSelectedFiles: boolean;
  page: number;
  hiddenColumns?: (keyof PendingPayFile)[];
  reportingOptions?: ReportingOptionType[];
  currentFilter?: PendingPayCriteriaSearch;
  onPageChange: (page: number) => void;
  onFiltersChange: (name: keyof PendingPayCriteriaSearch, value: any) => void;
  selectionConfig?: SelectionConfig<PendingPayFile>;
  onOnlySelectedFilesChange?: (value: boolean) => void;
  onRowsPerPageChange: (count: number) => void;
  showActionRow: boolean;
  onRequestSort?: (property: string, disableToggling?: boolean) => void;
  onColumnResize: (column: keyof PendingPayFile, newWidth: number) => void;
  onColumnsModified?: (columns: IColumn[]) => void;
  onShowPdf: (pdfUrl: string) => void;
  order: Order;
  orderBy?: keyof PendingPayFile;
};

const StyledLink = styled(Link)({
  color: colors.blue01,
  cursor: "pointer",
  fontWeight: fontWeight.bold2,
  textDecoration: "underline",
});

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
        border: 0,
        borderBottom: `1px solid ${colors.grey15}`,
        borderRadius: 0,
        "&:focus-within": {
          borderBottom: `2px solid ${colors.blue01}`,
        },
        "& fieldset": {
          border: 0,
        },
      },
    },
    "& tbody": {
      "& tr:hover td": {
        background: colors.grey10,
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey08}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero
        }
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  }
});

const StyledTableButtons = styled(Box)({
  display: "flex",
  gap: gaps.small2,
  justifyContent: "end",
});

const CurrencyBox = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

const useStyles = makeStyles({
  rightColumn: {
    "& span": {
      display: "flex",
      justifyContent: "end",
      paddingRight: padding.small2,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      textAlign: "end",
    }
  }
});

export default function PendingPayGrid({
  colsConfig,
  rows,
  rowsPerPage,
  loadingProgressPercent,
  noRowsMessage,
  onlyShowSelectedFiles,
  page,
  hiddenColumns,
  currentFilter,
  reportingOptions,
  selectionConfig,
  showActionRow,
  onPageChange,
  onFiltersChange,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
  onOnlySelectedFilesChange,
  onRowsPerPageChange,
  onShowPdf,
  order,
  orderBy,
}: Props) {
  const styles = useStyles();
  const [{ selectedItems }] = usePendingPay();
  const { ePayVisible, payByCheckVisible } = useReportOptionType();
  const noSelectedRows = !selectedItems?.length;
  const [isLoading, setIsLoading] = useState(false);

  const detailsViewConfig: DetailsViewConfig<PendingPayFile> = {
    enabled: true,
    renderRowDetails: (file) =>
      <PaymentDetailsGrid file={file} />
  };

  const inputSearchLabel = onlyShowSelectedFiles ? "Filter" : "Search";

  const columns: StewartTableColumn<PendingPayFile>[] = _orderBy([
    {
      field: "clientFileID",
      left: 98,
      actionComponent: () => (
        <InputSearchPendingpay
          name="clientFileId"
          label={inputSearchLabel}
          filterValue={currentFilter?.clientFileId}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: ({ clientFileID, fileId }: PendingPayFile) => (
        <TooltipCell title={clientFileID}>
          <StyledLink
            to={"/file"}
            state={{ fileID: fileId }}
          >
            {clientFileID}
          </StyledLink>
        </TooltipCell>
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "clientFileID"),
    },
    {
      field: "orderNumber",
      actionComponent: () => (
        <InputSearchPendingpay
          name="orderNumber"
          label={inputSearchLabel}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <TooltipCell
          alwaysShowOnHover
          title={row.clientFileIDList}
        >
          {formatPaymentSheetOrderNumber(row.orderNumber)}
        </TooltipCell>
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "orderNumber"),
    },
    {
      field: "propertyAddresses",
      actionComponent: () => (
        <InputSearchPendingpay
          name="propertyAddress"
          label={inputSearchLabel}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => <MultiLineCell names={row.propertyAddresses} />,
      ...getColumnConfig<PendingPayFile>(colsConfig, "propertyAddresses"),
    },
    {
      field: "lastBilledDate",
      actionComponent: () => (
        <DatePickerSearPendingPay
          header
          handleDateChange={(date: Date | null) => {
            onFiltersChange("lastBilledDate", date);
          }}
          size="small"
        />
      ),
      valueGetter: (row: PendingPayFile) => formatDate(row.lastBilledDate),
      ...getColumnConfig<PendingPayFile>(colsConfig, "lastBilledDate"),
    },
    {
      field: "reportOptionTypeName",
      actionComponent: () => (
        <ReportingOption
          name="reportOptionTypeCode"
          defaultValue={reportingOptions ?? []}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <TooltipCell title={row.reportOptionTypeName} />
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "reportOptionTypeName"),
    },
    {
      field: "lastBilledBy",
      actionComponent: () => (
        <InputSearchPendingpay
          name="lastBilledBy"
          label={inputSearchLabel}
          filterValue={currentFilter?.lastBilledBy}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <TooltipCell title={row.lastBilledBy} />
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "lastBilledBy"),
    },
    {
      field: "locationLegacyIDs",
      actionComponent: () => (
        <InputSearchPendingpay name="locationLegacyID" onChange={onFiltersChange} />
      ),
      valueGetter: (row: PendingPayFile) => <MultiLineCell names={row.locationLegacyIDs} />,
      ...getColumnConfig<PendingPayFile>(colsConfig, "locationLegacyIDs"),
    },
    {
      field: "totalBilledActualRiskRate",
      classes: styles.rightColumn,
      actionComponent: () => (
        <InputSearchPendingpay
          name="totalBilledActualRiskRate"
          label={inputSearchLabel}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalBilledActualRiskRate, true, false)}
        </CurrencyBox>
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledActualRiskRate"),
    },
    {
      field: "totalBilledActualFee",
      classes: styles.rightColumn,
      actionComponent: () => (
        <InputSearchPendingpay
          name="totalBilledActualFee"
          label={inputSearchLabel}
          inputType="number"
          filterValue={currentFilter?.totalBilledActualFee}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalBilledActualFee, true, false)}
        </CurrencyBox>
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledActualFee"),
    },
    {
      field: "totalBilledAmountDue",
      classes: styles.rightColumn,
      actionComponent: () => (
        <InputSearchPendingpay
          name="totalBilledAmountDue"
          label={inputSearchLabel}
          inputType="number"
          filterValue={currentFilter?.totalBilledAmountDue}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: PendingPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalBilledAmountDue, true, true)}
        </CurrencyBox>
      ),
      ...getColumnConfig<PendingPayFile>(colsConfig, "totalBilledAmountDue"),
    },
  ], "position");

  // Add 1 to column span for expand/select row header.
  const totalColSpan = columns.length - (hiddenColumns?.length ?? 0) + 1;

  useEffect(() => {
    setIsLoading(loadingProgressPercent !== undefined && loadingProgressPercent >= 0);
  }, [loadingProgressPercent]);

  return (
    <StyledTableContainer width={1}>
      <StewartTable
        cols={columns}
        rows={rows}
        rowsPerPage={rowsPerPage}
        loadingProgressPercent={loadingProgressPercent}
        noRowsMessage={noRowsMessage}
        page={page}
        hiddenColumns={hiddenColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onColumnResize={onColumnResize}
        onColumnsModified={onColumnsModified}
        useDbPagingSorting={!onlyShowSelectedFiles}
        detailsViewConfig={detailsViewConfig}
        selectionConfig={selectionConfig}
        showActionRow={showActionRow}
        footerComponent={<TableRow>
          <TableCell colSpan={totalColSpan}>
            <PendingPayFooter
              rows={selectedItems}
              onlyShowSelectedFiles={onlyShowSelectedFiles}
              page={page}
              isLoading={isLoading}
              onPageChange={onPageChange}
              onOnlySelectedFilesChange={onOnlySelectedFilesChange}
            />
          </TableCell>
        </TableRow>}
      />
      <StewartTableFooterPaginator
        totalRows={rows[0]?.totalRows ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={rows[0]?.totalPages ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <StyledTableButtons>
        <UndoReport
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
        />
        <EPay
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
          hidden={!ePayVisible}
        />
        <PayByCheck
          selectedItems={selectedItems}
          disabled={noSelectedRows || isLoading}
          onShowPdf={onShowPdf}
          hidden={!payByCheckVisible}
        />
      </StyledTableButtons>
    </StyledTableContainer>
  );
}
