import { axiosSecuredInstance } from "configurations/axiosConfig";
import * as ApiModel from "entities/ApiModel";
import {
  ActionResult,
  Agency,
  Jacket,
  Pricing,
  PricingProduct,
  SCFile,
} from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import {
  DOMEventType,
  ProductAction,
  ProductType,
} from "utils/data/enum";
import {
  getFormikAals,
  getFormikAdditionalParties,
  getFormikCpls,
  getFormikInitialValues,
  getFormikJackets,
  getFormikStandaloneEndorsements,
} from "utils/data/formikInitialValues";
import {
  hasErrorOnAllProductsAction,
  hasErrorOnSingleProductAction,
} from "pages/file/utils/helper";
import { setSCFile } from "pages/file/utils/fileHelper";
import { convertSQLDBNullToUndefined } from "../shared";

interface State {
  initialValues: SCFile;
  isFileLoading: boolean;
  isSaving: boolean;
  isActionInProgress: boolean;
  error: any;
  uniqueFileId: number;
  isIssuedProductDirty: boolean;
  calculatedPricingProducts: PricingProduct[] | null;
  isReadOnly: boolean;
  invokingUrl: string;
  invokingAction: string;
}

type StoreApi = StoreActionApi<State>;

const setLoading =
  () =>
  ({ setState }: StoreApi) => {
    setState({ error: null, isFileLoading: true });
  };

const setSaving =
  () =>
  ({ setState }: StoreApi) => {
    setState({ isSaving: true });
  };

const setError =
  (error: any) =>
  ({ setState }: StoreApi) => {
    setState({
      error,
      isSaving: false,
      isFileLoading: false,
    });
  };

const setFile =
  (
    fileFromMiddleware: ApiModel.SCFile,
    isPopulatingExistingFile: boolean = false,
    hasError: boolean = false,
    resetInitialValues: boolean = true
  ) =>
  ({ setState }: StoreApi) => {
    const initialValuesFromApi = setSCFile(
      fileFromMiddleware,
      isPopulatingExistingFile
    );

    setState({
      isFileLoading: false,
      isReadOnly: initialValuesFromApi.isReadOnly,
      // isPricingRecalculateRequired: false,
    });

    if (!hasError) {
      if (resetInitialValues) {
        setState({
          initialValues: initialValuesFromApi,
          calculatedPricingProducts: null,
        });

        document.dispatchEvent(new Event(DOMEventType.FILE_JUST_LOADED));
      }
    }

    return initialValuesFromApi;
  };

const Store = createStore<State, any>({
  initialState: {
    initialValues: getFormikInitialValues(),
    isFileLoading: false,
    isSaving: false,
    isActionInProgress: false,
    error: null,
    uniqueFileId: 0,
    isIssuedProductDirty: false,
    // isPricingRecalculateRequired: false,
    // pricingNotificationUpdated: false,
    calculatedPricingProducts: null,
    isReadOnly: false,
    invokingUrl: "/",
    invokingAction: "",
  },
  actions: {
    setErrorFiles:
      (error: any) =>
      ({ dispatch }: StoreApi) => {
        dispatch(setError(error));
      },
    setCalculatedPricingProducts:
      (value: PricingProduct[] | null) =>
      ({ setState }: StoreApi) => {
        setState({ calculatedPricingProducts: value });
      },
    setFileLoading:
      (isFileLoading: boolean) =>
      async ({ setState }: StoreApi) => {
        setState({ isFileLoading: isFileLoading });
      },
    saveFile:
      (scfile: ApiModel.SCFile, isCleaningForm: boolean) =>
      async ({ setState, dispatch }: StoreApi): Promise<ActionResult> => {
        try {
          setState({ error: null });
          dispatch(setSaving());
          const { data, headers } = await axiosSecuredInstance.put(
            "/files/savefile",
            scfile
          );
          if (!isCleaningForm) {
            dispatch(setFile(data));
          }
          setState({ isSaving: false });
          return {
            headers: headers,
            apiResponse: data,
          };
        } catch (error) {
          dispatch(setError(error));
          setState({ isSaving: false });
          // robun's notes: for some reason the dispatch(setError) wont's finish udpating context soon enough
          return {
            error: error,
          };
        }
      },
    getFileById:
      (fileId: number | string) =>
      async ({ dispatch }: StoreApi) => {
        try {
          dispatch(setLoading());
          const { data } = await axiosSecuredInstance.get(`/files/${fileId}`);
          return dispatch(setFile(data, true));
        } catch (error) {
          dispatch(setError(error));
          return error;
        }
      },
    deleteProperty:
      (fileId: number, propertyId: number) =>
      async ({ dispatch }: StoreApi) => {
        try {
          await axiosSecuredInstance.delete(
            `/files/fileproperty?fileId=${fileId}&propertyId=${propertyId}`
          );
        } catch (error) {
          dispatch(setError(error));
          return error;
        }
      },
    deleteOrder:
      (orderId: number) =>
      async ({ dispatch }: StoreApi) => {
        try {
          await axiosSecuredInstance.delete(`/order/DeleteOrder/${orderId}`);
        } catch (error) {
          dispatch(setError(error));
          return error;
        }
      },
    deleteFileData:
      (deleteCriteria: ApiModel.DeleteCriteria) =>
      async ({ dispatch }: StoreApi) => {
        try {
          await axiosSecuredInstance.post(`/files/filedata`, deleteCriteria);
        } catch (error) {
          dispatch(setError(error));
          return error;
        }
      },
    issueCpl:
      (scfile: ApiModel.SCFile, requestId: string) =>
      async ({
        dispatch,
        setState,
        getState,
      }: StoreApi): Promise<ActionResult> => {
        try {
          setState({ isActionInProgress: true, error: null });
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${ProductType.Cpl}&ProductAction=${ProductAction.Issue}&requestId=${requestId}`,
            scfile
          );
          const currentFile = dispatch(setFile(data));

          setState({ isActionInProgress: false });

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          setState({ isActionInProgress: false });
          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    issueAAL:
      (scfile: ApiModel.SCFile, requestId: string) =>
      async ({
        dispatch,
        setState,
        getState,
      }: StoreApi): Promise<ActionResult> => {
        try {
          setState({ isActionInProgress: true, error: null });
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${ProductType.Aal}&ProductAction=${ProductAction.Issue}&requestId=${requestId}`,
            scfile
          );
          const currentFile = dispatch(setFile(data));

          setState({ isActionInProgress: false });

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          setState({ isActionInProgress: false });
          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    voidProduct:
      (
        scfile: ApiModel.SCFile,
        requestId: string,
        productType: string,
        integrationKey: string,
        parentProductType: string | null,
        parentOrderID?: number
      ) =>
      async ({ dispatch, getState }: StoreApi): Promise<ActionResult> => {
        try {
          if (!parentOrderID) parentOrderID = 0;
          //if(!parentProductType) parentProductType = null;
          let voidUrl = `/order/product?ProductType=${productType}&ProductAction=${ProductAction.Void}&requestId=${requestId}`;
          if (parentProductType) {
            voidUrl =
              voidUrl +
              `&parentProductType=${parentProductType}&parentOrderID=${parentOrderID}`;
          }
          const { data, headers } = await axiosSecuredInstance.post(voidUrl, scfile);
          const hasError = hasErrorOnSingleProductAction(
            data,
            ProductAction.Void,
            productType,
            integrationKey,
            parentProductType || "",
            parentOrderID
          );
          const currentFile = dispatch(setFile(data, false, hasError));

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));

          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    voidAllProducts:
      (
        scfile: ApiModel.SCFile,
        requestId: string,
        productType: string,
        integrationKeys: string[]
      ) =>
      async ({ dispatch, getState }: StoreApi): Promise<ActionResult> => {
        try {
          dispatch(setLoading());
          let voidUrl = `/order/product?ProductType=${productType}&ProductAction=${ProductAction.Void}&requestId=${requestId}`;
          const { data, headers } = await axiosSecuredInstance.post(voidUrl, scfile);
          const hasError = hasErrorOnAllProductsAction(data, integrationKeys);
          const currentFile = dispatch(setFile(data, false, hasError));

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));

          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    issueJacket:
      (scfile: ApiModel.SCFile, requestId: string) =>
      async ({
        setState,
        getState,
        dispatch,
      }: StoreApi): Promise<ActionResult> => {
        try {
          setState({
            isActionInProgress: true,
            error: null,
          });
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${ProductType.Jacket}&ProductAction=${ProductAction.Issue}&requestId=${requestId}`,
            scfile
          );
          const currentFile = dispatch(setFile(data));

          setState({ isActionInProgress: false });

          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          setState({ isActionInProgress: false });

          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    revise:
      (
        scfile: ApiModel.SCFile,
        productType: ProductType,
        requestId: string,
        integrationKey: string
      ) =>
      async ({
        dispatch,
        setState,
        getState,
      }: StoreApi): Promise<ActionResult> => {
        try {
          dispatch(setLoading());
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${productType}&ProductAction=${ProductAction.Revise}&requestId=${requestId}`,
            scfile
          );

          const currentFile = dispatch(setFile(data, false));
          setState({ isActionInProgress: false });
          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          setState({ isActionInProgress: false });
          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    submitEndorsements:
      (scfile: SCFile, requestId: string, orderId?: number) =>
      async ({ dispatch, getState }: StoreApi): Promise<ActionResult> => {
        try {
          dispatch(setLoading());

          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${ProductType.Endorsement}&ProductAction=${ProductAction.Issue}&requestId=${requestId}&parentProductType=${ProductType.Jacket}&parentOrderId=${orderId}`,
            scfile
          );

          const currentFile = dispatch(setFile(data));
          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    submitStandaloneEndorsement:
      (
        scfile: ApiModel.SCFile,
        productType: string,
        requestId: string,
        orderId?: number
      ) =>
      async ({
        dispatch,
        getState,
        setState,
      }: StoreApi): Promise<ActionResult> => {
        try {
          dispatch(setLoading());
          const { data, headers } = await axiosSecuredInstance.post(
            `/order/product?ProductType=${productType}&ProductAction=${ProductAction.Issue}&requestId=${requestId}&parentProductType=${ProductType.StandaloneEndorsement}&parentOrderId=${orderId}`,
            scfile
          );

          const currentFile = dispatch(setFile(data));
          setState({ isActionInProgress: false });
          return {
            headers: headers,
            scfile: currentFile,
          };
        } catch (error) {
          dispatch(setError(error));
          return {
            scfile: getState().initialValues,
            error,
          };
        }
      },
    deleteEndorsement:
      (endorsementID: number) =>
      async ({ dispatch }: StoreApi) => {
        try {
          await axiosSecuredInstance.delete(
            `/order/Endorsement?endorsementId=${endorsementID}`
          );
        } catch (error) {
          dispatch(setError(error));
          return error;
        }
      },
    resetInitialValues:
      () =>
      ({ setState }: StoreApi) => {
        const initialValues = getFormikInitialValues();
        setState({
          initialValues: initialValues,
          calculatedPricingProducts: null,
          isReadOnly: false,
        });
      },
    resetNonFileSections:
      (
        fileId: number,
        fileNumber: string,
        agency: Agency,
        isFromModal: boolean
      ) =>
      async ({ setState, getState }: StoreApi) => {
        const initialValues = getState().initialValues;

        initialValues.id = fileId;
        initialValues.agency = agency;

        if (fileId === 0) initialValues.identityKey = 0;

        if (fileNumber && fileNumber !== "")
          initialValues.fileNameNumber = fileNumber;

        setState({
          initialValues: initialValues,
        });
      },
    resetProductAndAdditionalParty:
      (currentScFile: SCFile) =>
      async ({ setState }: StoreApi) => {
        const newInitialValues = currentScFile;

        // update product and additional party sections
        newInitialValues.additionalParties = getFormikAdditionalParties();
        newInitialValues.jackets = getFormikJackets();
        newInitialValues.cpls = getFormikCpls();
        newInitialValues.aALProducts = getFormikAals();
        newInitialValues.standaloneEndorsements =
          getFormikStandaloneEndorsements();

        // update initialValues
        setState({
          initialValues: newInitialValues,
        });
      },
    setIssuedProductDirty:
      (disabled: boolean) =>
      ({ setState }: StoreApi) => {
        setState({ isIssuedProductDirty: disabled });
      },
    setInvokingUrl:
      (url: string, action: string) =>
      ({ setState }: StoreApi) => {
        setState(
          { 
            invokingUrl: url,
            invokingAction: action
          });
      },
    resetInvokingUrl:
      () =>
      ({ setState }: StoreApi) => {
        setState(
          { 
            invokingUrl: "",
            invokingAction: ""
          });
      },  
    resetPricingFields: (fields: Partial<Pricing>) => ({ setState, getState }: StoreApi) => {
      const initialValues = getState().initialValues;

      initialValues.pricing = { ...initialValues.pricing, ...fields };

      setState({ initialValues })
    },
    resetJacketPricingDetail: (currentJacket: Jacket) => ({ setState, getState }: StoreApi) => {
      const initialValues = getState().initialValues;

      const filePricingDetailID = currentJacket.pricingDetail?.filePricingDetailID

      const index = initialValues.jackets?.findIndex(j => j.filePricingDetailID === filePricingDetailID);

      if (index >= 0) {
        let jacket = initialValues.jackets[index];
        if (jacket.pricingDetail) {
          jacket.pricingDetail.isReissue = currentJacket?.pricingDetail?.isReissue;
          jacket.originalJacketDate = new Date(currentJacket.originalJacketDate ?? 0);
          jacket.originalJacketLiability = convertSQLDBNullToUndefined(currentJacket.originalJacketLiability);
          jacket.originalJacketNumber = currentJacket.originalJacketNumber;
        }
      }

      setState({ initialValues });
    },
  },
  name: "files",
});

const useInitialValuesFiles = createHook(Store, {
  selector: (state: State) => ({ initialValues: state.initialValues }),
});

const useInitialValuesSingleProp = createHook(Store, {
  selector: (state: State, arg: keyof SCFile) => ({
    initialValuesProp: state.initialValues[arg],
  }),
});

const useIsFileLoadingFiles = createHook(Store, {
  selector: (state: State) => ({ isFileLoading: state.isFileLoading }),
});

const useIsActionInProgressFiles = createHook(Store, {
  selector: (state: State) => ({
    isActionInProgress: state.isActionInProgress,
  }),
});

const useFilesActions = createHook(Store, { selector: null });

const hook = createHook(Store);
const useFiles = () => {
  return hook();
};

export {
  useInitialValuesFiles,
  useIsFileLoadingFiles,
  useIsActionInProgressFiles,
  useFiles,
  useInitialValuesSingleProp,
  useFilesActions,
  Store,
};
