import React from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import { usePricingActions } from "utils/context/PricingContext";
import { EndorsementErrorMessageProvider } from "./EndorsementErrorMessageProvider";
import { SideNavProvider } from "./SideNavProvider";
import NewFileForm from "./components/file-form-layout/CreateFile";

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
}

const CreateFilePage = ({ saveFileRef, saveFileChangeRef }: Props) => {
  const [, { getFileById }] = useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const navigate = useNavigate();
  const location = useLocation();
  const fileID = location?.state?.fileID as string ?? undefined;

  React.useEffect(() => {
    if (!fileID) return;

    const getFileAsync = async () => {
      openAutomaticProgressDialog();
      setPricingNotificationUpdated(false);
      setTimeout(async () => {
        const response = await getFileById(btoa(fileID));
        if (!response || response instanceof Error) {
          navigate("/404", { replace: true });
        }
      }, 500);
      setPricingNotificationUpdated(true);
      //DELAY UNTIL REACT-HOOK-FORM IS FULLY LOADED.
      closeAutomaticProgressDialog(300);
    };

    getFileAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileID, getFileById]);

  return (
    <EndorsementErrorMessageProvider>
      <SideNavProvider>
        <NewFileForm
          saveFileRef={saveFileRef}
          saveFileChangeRef={saveFileChangeRef}
        />
      </SideNavProvider>
    </EndorsementErrorMessageProvider>
  );
};

export default CreateFilePage;
