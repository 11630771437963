import React from "react";
import TextInputField from "controls/global/text-input-field";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "controls/global/error-message";
import { getNameString } from "utils/custom-hooks/useFormWrapper";

interface Props {
  isDisabled?: boolean;
  schema?: string;
  onBlur?: any;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const LegalDescriptionField = ({
  isDisabled,
  schema,
  onBlur,
  onChange,
}: Props) => {
  const { control } = useFormContext();
  const legalDescriptionFieldName = getNameString(`${schema}.legalDescription`);

  return (
    <>
      <Controller
        control={control}
        name={legalDescriptionFieldName}
        render={({ field: { name, value } }) => (
          <TextInputField
            label="APN, Folio, Parcel ID, etc."
            name={name}
            disabled={isDisabled}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <ErrorMessage name={legalDescriptionFieldName} />
    </>
  );
};

export default LegalDescriptionField;
