import { makeStyles, styled, TableCell, Typography } from "@material-ui/core";
import { Endorsement } from "entities/UIModel";
import React from "react";
import { EndorsementStatusType } from "utils/data/enum";
import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { colors } from "theme/defaultStyle";

type Props = {
  endorsement: Endorsement;
  fullName: string;
  cellCss: string;
  disabled: boolean;
  issuedMode: boolean;
  options: SelectFieldOption[];
  handleEndorsementChange: (
    _: React.ChangeEvent<{}>,
    selectedOptionValue: SelectFieldOption
  ) => void;
};

const StyleCellText = styled(Typography)({
  fontSize: "18px",
  padding: "10px 0px 5px 10px",
});

const useStyles = makeStyles((theme) => ({
  selectEndorsment: {
    "& .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot": {
      border: "none",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot.Mui-focused":
      {
        border: `1px solid ${theme.palette.primary.main}`,
      },
  },
  disabled: {
    color: colors.grey07,
  },
}));

export default function EndorsementNameCell({
  endorsement: {
    isSFE,
    endorsementName,
    endorsementID,
    endorsementStatusTypeCode,
  },
  fullName,
  cellCss,
  disabled,
  issuedMode,
  options,
  handleEndorsementChange,
}: Props) {
  const classes = useStyles();

  const isCellDisabled: boolean =
    disabled ||
    Boolean(
      endorsementID &&
        endorsementStatusTypeCode !== EndorsementStatusType.Pending &&
        issuedMode
    );

  if (isSFE) {
    return (
      <TableCell classes={{ sizeSmall: cellCss }} style={{ minWidth: 340 }}>
        <StyleCellText className={isCellDisabled ? classes.disabled : ""}>
          {endorsementName}
        </StyleCellText>
      </TableCell>
    );
  }

  return (
    <TableCell
      classes={{ sizeSmall: cellCss }}
      className={classes.selectEndorsment}
      style={{ minWidth: 340 }}
    >
      <>
        <ComboBoxField
          label=""
          size="small"
          noBorder
          placeholder="Select or Type Endorsement"
          name={fullName}
          defaultValue={endorsementName}
          options={options}
          onChange={handleEndorsementChange}
          disabled={isCellDisabled}
        />
      </>
    </TableCell>
  );
}
