import {
  Box,
  styled,
} from "@material-ui/core";
import { Description } from "@mui/icons-material";
import CalculateIcon from "@mui/icons-material/Calculate";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import ActionButton from "controls/global/action-button";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import ViewPendingPayment from "pages/file/components/common/ViewPendingPayment";
import React from "react";
import {
  gaps,
  margin,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useDialog } from "utils/context/DialogContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricing from "utils/custom-hooks/usePricing";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import { FileStatusType } from "utils/data/enum";
import PricingDetails from "./PricingsDetails";
import PricingPayByCheck from "./pricing-buttons/PricingPayByCheck";
import PricingReportAndPayByCheck from "./pricing-buttons/PricingReportAndPayByCheck";
import PricingReportOnly from "./pricing-buttons/PricingReportOnly";

const REPORT_HELPER_TEXT =
  "By clicking <strong>Submit</strong>, the file will be reported and closed.";

const UNDOREPORT_HELPER_TEXT = "By clicking <strong>Submit</strong>, the file will be unreported.";

type Props = {
  onReport: (...args: unknown[]) => unknown;
  onUndoReport?: (...args: unknown[]) => unknown;
  onCalculate?: React.MouseEventHandler<HTMLElement>;
  onNavigate: (url: string) => void;
  onReportingComplete: (reportingResult: ReportingResult) => void;
  reportDisabled: boolean;
  calculateDisabled: boolean;
  integratedPricing: boolean;
  isReivising: boolean;
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
};

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: gaps.small1,
  justifyContent: "flex-end",
  "& > *": {
    margin: margin.zero,
  },
});

const PricingButton = ({
  onReport,
  onUndoReport,
  onCalculate,
  onNavigate,
  onReportingComplete,
  reportDisabled,
  calculateDisabled,
  integratedPricing,
  isReivising,
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
}: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const { showReportButton, showCalculateButton } = usePricing();
  const { isFileLocked, isReadOnly } = useCreateFile();
  const [, { openDialog }] = useDialog();
  const { getPricingTotal, getPricingNewTotal } = usePricingCalculator();

  let totalDueTotal = "";
  if (isFileLocked) {
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);
    totalDueTotal = pricingNewTotal.newTotalTotalDue;
  } else {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    totalDueTotal = pricingTotal.totalDueTotal;
  }

  const showPayByCheck = enableReportPay && getValues("statusTypeCode") === FileStatusType.ReportPending;
  
  const showExcludeAutoReport = (showReportButton(isFileLocked, isReadOnly) && !(reportDisabled || isReivising || isReadOnly));

  const handleReportClick = () => {
    openDialog({
      title: "Report File",
      content: (
        <ReportContentMessage
          mainText={`Are you ready to report this file for ${totalDueTotal}?`}
          helperText={REPORT_HELPER_TEXT}
        />
      ),
      onSubmitAction: onReport,
    });
  };

  const handleUndoReportClick = () => {
    openDialog({
      title: "Undo Report",
      content: (
        <ReportContentMessage
          mainText={`Are you sure you want to reopen this file?`}
          helperText={UNDOREPORT_HELPER_TEXT}
        />
      ),
      onSubmitAction: onUndoReport,
    });
  };

  const handleExcludeAutoReport = (e: any, value: boolean) => {
    setValue("pricing.isExcludedFromAutoReport", value);
  };

  return (
    <Container>
      {showExcludeAutoReport && (
        <Box flex={2} marginLeft={3}>
          <CheckboxInputField
            label="Exclude from Auto-report"
            name="pricing.isExcludedFromAutoReport"
            onChange={handleExcludeAutoReport}
          />
        </Box>
      )}
      {!showExcludeAutoReport &&
        <Box flex={2}>
          <PricingDetails />
        </Box>
      }
      {showCalculateButton(integratedPricing, isFileLocked, isReadOnly) && (
        <ActionButton
          color="secondary"
          variant="outlined"
          startIcon={<CalculateIcon />}
          onClick={onCalculate}
          disabled={calculateDisabled || isReivising || isReadOnly}
        >
          Calculate
        </ActionButton>
      )}
      {isFileLocked && (
        <>
          <ViewPendingPayment onNavigate={onNavigate} />
          {!isReadOnly &&
            <ActionButton
              color="secondary"
              variant="outlined"
              startIcon={<RestorePageIcon />}
              onClick={handleUndoReportClick}
            >
              Undo Report
            </ActionButton>
          }
        </>
      )}
      {showReportButton(isFileLocked, isReadOnly) && (
        enableReportPay ? <PricingReportOnly
          disabled={reportDisabled || isReivising || isReadOnly}
          isPricingSectionUpdated={isPricingSectionUpdated}
          hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem}
          displayRiskRateColumn={displayRiskRateColumn}
          onReportingComplete={onReportingComplete}
        /> : (
          <ActionButton
            color="primary"
            variant="outlined"
            startIcon={<Description />}
            onClick={handleReportClick}
            disabled={reportDisabled || isReivising || isReadOnly}
            name="Report"
          >
            Report
          </ActionButton>
        )
      )}
      {showReportButton(isFileLocked, isReadOnly) && enableReportPay && (
        <PricingReportAndPayByCheck
          disabled={reportDisabled || isReivising}
          isPricingSectionUpdated={isPricingSectionUpdated}
          hasFileLockedWithUpdateablePricingItem={
            hasFileLockedWithUpdateablePricingItem
          }
          displayRiskRateColumn={displayRiskRateColumn}
          onReportingComplete={onReportingComplete}
        />
      )}
      {showPayByCheck &&
        <PricingPayByCheck
          isPricingSectionUpdated={isPricingSectionUpdated}
          hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem}
          displayRiskRateColumn={displayRiskRateColumn}
          onReportingComplete={onReportingComplete}
        />
      }
    </Container>
  );
};

export default PricingButton;
