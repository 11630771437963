import React from "react";

import { Grid } from "@material-ui/core";
import { useAdditionalPartiesActions } from "utils/context/AdditionalPartiesContext";
import State from "./address/State";
import AddressTwo from "./address/AddressTwo";
import { PartyType } from "utils/data/enum";
import PartyTypeCode from "./PartyTypeCode";
import ClearAdditionalParty from "./ClearAdditionalParty";
import MakeFavorite from "./MakeFavorite";
import AddressOne from "./address/AddressOne";
import City from "./address/City";
import ZipCode from "./address/ZipCode";
import CompanyName from "./CompanyName";
import IndividualAttorney from "./IndividualAttorney";
import { useAdditionalPartySearch } from "utils/context/AdditionalPartySearchContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  index: number;
};

export default function AdditionalPartyGrid({ index }: Props) {
  const schema = `additionalParties.${index}`;
  const { getValues } = useFormWrapper();
  const [searchState] = useAdditionalPartySearch();
  const [, { getAdditionalParties }] = useAdditionalPartiesActions();
  const [propertyState, selectedAdditionalPartyID, partyTypeCode] = getValues([
    "properties.0.state.abbr",
    `${schema}.selectedAdditionalPartyID`,
    `${schema}.partyTypeCode`,
  ]);
  const isAttorneyType = partyTypeCode === PartyType.Attorneys;
  const displayFullGrid = partyTypeCode !== "" ?? false;
  const disabled = selectedAdditionalPartyID !== "";

  React.useEffect(() => {
    if (disabled) return;

    getAdditionalParties(partyTypeCode, propertyState, {
      address: searchState.address,
      city: searchState.city,
      state: searchState.state,
      text: searchState.text,
      zip: searchState.zip,
    });
  }, [
    disabled,
    getAdditionalParties,
    partyTypeCode,
    propertyState,
    searchState,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <PartyTypeCode schema={schema} disabled={disabled} />
      </Grid>
      {displayFullGrid ? (
        <>
          <Grid item md={isAttorneyType ? 3 : 6}>
            <CompanyName schema={schema} disabled={disabled} />
          </Grid>
          {isAttorneyType ? (
            <Grid item md={3}>
              <IndividualAttorney schema={schema} disabled={disabled} />
            </Grid>
          ) : null}
          <Grid item md={2}>
            <MakeFavorite index={index} />
          </Grid>
          <Grid item md={1}>
            <ClearAdditionalParty schema={schema} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <AddressOne schema={schema} disabled={disabled} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <AddressTwo name={`${schema}.addressTwo`} disabled={disabled} />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <City schema={schema} disabled={disabled} />
          </Grid>
          <Grid item xs={12} md={3} lg={1}>
            <State name={`${schema}.state`} disabled={disabled} />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <ZipCode schema={schema} disabled={disabled} />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
