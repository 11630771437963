import React from "react";
import BlockIcon from "@material-ui/icons/Block";
import styled from "styled-components";

const StyledBlockIcon = styled(BlockIcon)`
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  stroke: inherit;
  stroke-width: 2;
  transform: scaleX(-1);
`;

const BlockBoldIcon = () => <StyledBlockIcon />;

export default BlockBoldIcon;
