import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { colors } from "theme/defaultStyle";

export const MultiSelectContainer = withStyles((theme) => ({
  root: (props: any) => {
    return {
      "& div.MuiSelect-select": theme.custom.textField.inputOutlined,
      "& div.MuiOutlinedInput-root path": { fill: colors.blue01 },
      "& div.MuiOutlinedInput-input.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
      "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
      "& div.Mui-error path": { fill: colors.red01 },
    };
  },
}))(TextField);
