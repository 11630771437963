import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";

export interface ProcessTrackingReceipt {
  id: string;
  requestId: string;
  step: number;
  pctComplete: number;
  message: string;
  status: string;
}

type State = {
  processStatus: ProcessTrackingReceipt;
  error: string | null;
  maxWait: number | 0;
  bypassDefaultLoader: boolean;
  loadingProgressMessage: string | null;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const initialState = {
  error: null,
  processStatus: {
    id: "",
    requestId: "",
    message: "",
    status: "",
    pctComplete: 0,
    step: 0,
  },
  maxWait: 0,
  bypassDefaultLoader: false,
  loadingProgressMessage: "",
};

const getProcessStatus =
  (requestId: string, trackProcessStatus: boolean) =>
  async ({ setState, getState, dispatch }: StoreApi) => {
    try {
      if (!trackProcessStatus) return;

      setState({ maxWait: getState().maxWait + 1 });

      const { data } = await axiosSecuredInstance.get(
        `/processStatus/${requestId}`
      );

      if (!data) {
        const processStatus: ProcessTrackingReceipt = {
          id: "error",
          requestId: requestId,
          step: 0,
          pctComplete: 100,
          message: "An error occurred",
          status: "ERROR",
        };
        setState({ processStatus });
        return;
      }

      const processStatus: ProcessTrackingReceipt = {
        id: data.Id,
        requestId: data.RequestId,
        step: data.Step,
        pctComplete: data.PctComplete,
        message: data.Message,
        status: data.Status,
      };

      setState({ processStatus });
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const actions = {
  getProcessStatus:
    (requestId: string, trackProcessStatus: boolean = true) =>
    async ({ dispatch }: StoreApi) => {
      dispatch(getProcessStatus(requestId, trackProcessStatus));
    },
  setBypassDefaultLoader:
    (bypassDefaultLoader: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ bypassDefaultLoader });
    },
  reset:
    () =>
    ({ setState }: StoreApi) => {
      setState({ ...initialState });
    },
  setPercentageComplete:
    (requestId: string) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        processStatus: {
          ...getState().processStatus,
          pctComplete: 100,
          message: requestId,
        },
      });
     },
};

const Store = createStore<State, Actions>({
  initialState: { ...initialState },
  actions,
  name: "process status",
});

const getProgressTrackingState = (state: State) => ({
  pctComplete: state.processStatus.pctComplete,
  maxWait: state.maxWait,
});

const useProcessStatusTracking = createHook(Store, {
  selector: null,
});

const useProcessStatusTrackingState = createHook(Store, {
  selector: getProgressTrackingState,
});

export { useProcessStatusTracking, useProcessStatusTrackingState };
