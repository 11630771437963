import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& div.MuiPaper-root": {
      maxWidth: "none",
      overflow: "hidden",
    },
  },
  appBarParentRoot: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontSize: "14pt",
    fontWeight: 600,
    lineHeight: 1.6,
    marginBottom: "0px",
  },
  pdfContainer: {
    width: "100%",
    height: "100%",
    display: "block",
    position: "relative",
  },
  diwWrapper: {
    width: "100%",
    height: "100%",
  },
  videoPlayer: {
    width: "50vw",
    marginBottom: "-6px",
  },
}));

type VideoPlayerProps = {};

export type VideoPlayerRefType = {
  open: (title: string, src: string) => Promise<void>;
};

const VideoPlayer = (props: VideoPlayerProps, ref: Ref<VideoPlayerRefType>) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [titleSource, setTitleSource] = useState<string>();
  const [source, setSource] = useState<string>();

  useImperativeHandle(ref, () => ({
    open: async (title, src) => {
      setTitleSource(title);
      setSource(src);
      setOpen(true);
    },
  }));

  function onClose(): void {
    setOpen(false);
    setTitleSource("");
    setSource("");
  }

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      classes={{ root: classes.dialogRoot }}
    >
      <div className={classes.appBarParentRoot}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title}>{titleSource}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.pdfContainer}>
        <div
          onContextMenu={(e) => e.preventDefault()}
          className={classes.diwWrapper}
        >
          <video
            className={classes.videoPlayer}
            controls
            controlsList="nodownload"
          >
            <source src={source} type="video/mp4" />
            <track src="" kind="captions" />
          </video>
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef<VideoPlayerRefType, VideoPlayerProps>(VideoPlayer);
