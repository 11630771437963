import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ActionButton from "controls/global/action-button";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import {
  DialogType,
  useDialogStyles,
} from "utils/custom-hooks/useDialogStyles";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type Props = {
  singleActionButton?: boolean;
  singleActionButtonText?: string;
  confirmationMessage: string | ReactNode;
  isOpen: boolean;
  onYes: any;
  disableYes?: boolean;
  onNo?: any;
  disableNo?: boolean;
  buttonNoText?: string;
  cancelActionButton?: boolean;
  noActionButton?: boolean;
  onCancel?: any;
  type?: DialogType;
  width?: number;
};

const ConfirmationDialog = ({
  singleActionButton,
  singleActionButtonText,
  confirmationMessage,
  isOpen,
  onYes,
  disableYes = false,
  onNo,
  disableNo = false,
  buttonNoText,
  cancelActionButton,
  noActionButton = !singleActionButton,
  onCancel,
  type = "WARNING",
  width = 500,
}: Props) => {
  const classes = useDialogStyles({ type: type, width: width });
  const [open, setOpen] = useState(false);
  const icons: any = {
    "ERROR": ReportProblemIcon,
    "WARNING": ErrorOutlinedIcon,
  };

  const AlertIcon = icons[type];

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} classes={{ root: classes.dialogRoot }}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <AlertIcon style={{ fontSize: 40, color: colors.white }} />
        { cancelActionButton && (
          <DialogTitleCloseButton onClick={onCancel}/>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {confirmationMessage}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot }}>
        {!singleActionButton && (
          <>
            {noActionButton && (
              <ActionButton
                variant="outlined"
                color="secondary"
                onClick={onNo}
                disabled={disableNo}
                startIcon={<ClearIcon />}
              >
                {buttonNoText || "No"}
              </ActionButton>
            )}
          </>
        )}
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onYes}
          disabled={disableYes}
          startIcon={<CheckIcon />}
        >
          {singleActionButtonText || "Yes"}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
