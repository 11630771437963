import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TypeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      data-testid="TypographyIcon"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M486.9,4c-2.9,17.7-4.4,36.1-4.7,55l-0.7,60.1l-3.6,3.6h-22.7l-4-3.2c-1.2-30.7-2.5-48.7-4-54c-2.9-9.6-10.6-15.2-23-16.9
		c-4.8-0.7-27.3-1.1-67.6-1.1h-48.2c-0.5,8.4-0.7,41.7-0.7,100v204.3c0,57.1,1.6,94.2,4.7,111.5c1.2,7,5.3,11.4,12.2,13.3
		c3.6,1,17.6,2.2,42.1,3.6l3.6,3.6V508l-3.6,3.6c-23.7-1.4-60.7-2.2-110.8-2.2c-51.3,0-88.3,0.7-110.8,2.2l-3.2-3.2v-24.5l3.6-3.6
		c24.5-1.4,38.5-2.6,42.1-3.6c7-1.9,11-6.4,12.2-13.3c3.1-17.3,4.7-54.4,4.7-111.5V147.5c0-59.2-0.4-92.6-1.1-100H152
		c-42.9,0-68,1.3-75.2,4c-3.8,1.4-6.5,3.2-7.9,5.4c-2.2,3.8-4,11.5-5.4,23c-1.7,12.7-2.8,25.8-3.2,39.2l-3.6,3.6H34.1l-4-3.6
		l-0.7-61.5C29.1,40.4,27.7,22.5,25.1,4l3.2-3.6C42.4,2.8,94,4,183,4h145.7c82,0,133.6-1.2,154.7-3.6L486.9,4z"
      />
    </SvgIcon>
  );
};

export default TypeIcon;
