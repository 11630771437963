import React, {
  ReactNode,
} from "react";
import {
  Box,
  styled,
  Typography,
} from "@material-ui/core";
import SignatureName from "../signature-name/SignatureName";
import {
  fontSize,
  fontWeight,
  gaps,
  margin,
} from "theme/defaultStyle";

type TitleProps = {
  text: string;
  justifyContent: string;
};

type Props = {
  title: string;
  showSignatureName?: boolean;
  justifyContent?: string;
  width?: number;
  children: ReactNode;
};

type BodyInnerProps = {
  width: number;
}

const BodyInner = styled("div")(({ width }: BodyInnerProps) => ({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  width: width,
}));

const DisplayTitle = ({ text, justifyContent }: TitleProps) => {
  return (
    <Box
      style={{ marginTop: `-${margin.small}` }}
    >
      <Typography
        style={{
          fontWeight: fontWeight.normal1,
          fontSize: fontSize.xlarge2,
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default function SignatureDialogBody({
  title,
  showSignatureName = false,
  justifyContent = "space-between",
  width = 500,
  children,
}: Props) {

  return (
    <BodyInner width={width}>
      <DisplayTitle text={title} justifyContent={justifyContent} />
      {showSignatureName ? <SignatureName /> : null}
      {children}
    </BodyInner>
  );
}
