import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type StoreApi = StoreActionApi<State>;

interface State {
  abbr: string;
  code: string;
}

const initialState: State = {
  abbr: "",
  code: "",
};

const Store = createStore<State, any>({
  initialState: { ...initialState },
  actions: {
    setFilePropertyState:
      (abbr: string, code: string) =>
      async ({ setState, getState }: StoreApi) => {
        const currentState = getState();
        if (abbr === currentState.abbr && code === currentState.code) return;

        setState({ abbr, code });
      },
    resetPropertyState: () => ({ setState }: StoreApi) => {
      setState({ ...initialState });
    },
  },
  name: "agencyStates",
});

const hook = createHook(Store);
const abbrHook = createHook(Store, { selector: (state: State) => state.abbr });
const codeHook = createHook(Store, { selector: (state: State) => state.code });
export const useFilePropertyState = () => {
  return hook();
};
export const useFilePropertyStateAbbr = () => {
  return abbrHook();
};
export const useFilePropertyStateCode = () => {
  return codeHook();
};
