import { styled } from "@material-ui/core";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import SideNav from "controls/global/side-nav";
import React, {
  useRef,
  useState,
} from "react";
import { gaps } from "theme/defaultStyle";
import { useSignatures } from "utils/context/SignaturesContext";
import useTitle from "utils/custom-hooks/useTitle";
import ManageSignatures from "./components/manage-signature/ManageSignatures";
import SignatureDefaults from "./components/signature-defaults/SignatureDefaults";


const Container = styled("div")((props) => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "row",
  gap: gaps.large1,
  position: "relative",
  [props.theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Content = styled('main')({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large3,
});

const Signatures = () => {
  useTitle("Stewart Connect - Manage Signatures");

  const [scrollToTop, setScrollToTop] = useState(false);
  const manageSignaturesTitleRef = useRef<HTMLElement>();
  const defaultSignaturesTitleRef = useRef<HTMLElement>();
  const [{ isLoading }] = useSignatures();

  const navItems = [
    { name: "Manage Signatures", childRef: manageSignaturesTitleRef },
    { name: "Default Signatures", childRef: defaultSignaturesTitleRef },
  ];

  const onScrollToTop = () => {
    setScrollToTop(!scrollToTop);
  };

  return (
    <Container>
      <SideNav
        navItems={navItems}
        scrollToTop={scrollToTop}
        includeIconOverflowPadding
      />
      <Content>
        <ManageSignatures titleRef={manageSignaturesTitleRef} />
        <SignatureDefaults titleRef={defaultSignaturesTitleRef} />
        <ScrollToTopArrow
          onScrollToTop={onScrollToTop}
          refreshSize={isLoading}
        />
      </Content>
    </Container>
  );
};

export default Signatures;
