import ReportAndPayConfirmationDialog, {
  FileMapItem,
} from "controls/global/dialogs/report-and-pay-confirmation-dialog/ReportAndPayConfirmationDialog";
import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import ReportingButton from "controls/global/reporting-button/ReportingButton";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import React, {
  useRef,
  useState,
} from "react";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import { ReportAndPayAction } from "utils/data/enum";
import {
  formatCurrency,
  getReportAndPayActionText,
} from "utils/shared";
import { v4 as uuidv4 } from "uuid";

type ReportPayOnlyProps = {
  action: ReportAndPayAction.ReportOnly | ReportAndPayAction.ReportAndPayByCheck;
  selectedItems: ReportPayFile[];
  disabled?: boolean;
  onCloseAction?: () => void;
  onShowPdf?: (pdfUrl: string) => void;
  hidden?: boolean;
};

const REPORT_ONLY_HELPER_TEXT =
  "By clicking <strong>Submit</strong>, the file(s) will be reported and closed, and can be found in the Pending Payments section.";
const REPORT_AND_PAY_HELPER_TEXT =
  "By clicking <strong>Submit</strong>, a payment sheet will be generated and these files will be reported and closed.";


export default function ReportPay({ action, selectedItems, disabled, onCloseAction, onShowPdf, hidden }: ReportPayOnlyProps) {
  const [, { openDialog }] = useDialog();
  const [, { reportPricing }] = usePayment();
  const [, { openProgressbar }] = useProgressbar();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const reportingResultRef = useRef<ReportingResult | undefined>(undefined);
  const fileMapRef = useRef<FileMapItem[]>([]);

  const actionText = getReportAndPayActionText(action);

  const handleSubmitModal = async () => {
    const ids = selectedItems.map((f) => f.fileId);

    const fileMap = selectedItems.map((f) => (
      {
        fileId: f.fileId,
        fileName: f.clientFileId,
      }
    ));
    fileMapRef.current = fileMap;

    const requestId = uuidv4();

    openProgressbar({
      title: "Please wait while reporting your premiums...",
      requestId,
      onCloseAction: handleReportComplete,
    });

    const result = await reportPricing(ids, requestId, action);
    reportingResultRef.current = result;
  };

  const handleReportComplete = () => {
    onCloseAction?.();

    const reportingResult = reportingResultRef.current;
    if (reportingResult) {
      if ((reportingResult.failedFileIds && reportingResult.failedFileIds.length)
        || !reportingResult.pdfUrl) {
        setShowConfirmationDialog(true);
      }
      else if (reportingResult.pdfUrl) {
        onShowPdf?.(reportingResult.pdfUrl);
      }
    }
  };

  const handleReportingDialogContinue = () => {
    setShowConfirmationDialog(false);
    if (reportingResultRef.current?.pdfUrl) {
      onShowPdf?.(reportingResultRef.current.pdfUrl);
    }
  };

  const getMainText = () => {
    const total = selectedItems.reduce((accumulator, current) => {
      return accumulator + (current.totalAmountDue ?? 0);
    }, 0);

    const prefix = selectedItems.length > 1 ? "these" : "this";

    return `Are you ready to report ${prefix} ${selectedItems.length} file(s) for ${formatCurrency(total)}?`;
  };

  const getHelperText = () => {
    if (action === ReportAndPayAction.ReportOnly) {
      return REPORT_ONLY_HELPER_TEXT;
    }
    else {
      return REPORT_AND_PAY_HELPER_TEXT;
    }
  };

  const handleClick = () => {
    openDialog({
      title: actionText,
      content: (
        <ReportContentMessage
          mainText={getMainText()}
          helperText={getHelperText()}
        />
      ),
      onSubmitAction: handleSubmitModal,
    });
  };

  return (
    <>
      <ReportingButton
        action={action}
        disabled={disabled}
        onClick={handleClick}
        hidden={hidden}
      />
      <ReportAndPayConfirmationDialog
        isOpen={showConfirmationDialog}
        onContinue={handleReportingDialogContinue}
        reportingResult={reportingResultRef.current}
        fileMap={fileMapRef.current}
      />
    </>
  );
}
