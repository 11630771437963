import {
  Box,
  styled,
} from "@material-ui/core";
import React from "react";
import {
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import PdfViewer from "controls/global/pdf-viewer";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import { usePricingActions } from "utils/context/PricingContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useEffectiveDateValidation from "utils/custom-hooks/useEffectiveDateValidation";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePDFDocumentsAAL from "utils/custom-hooks/usePDFDocumentsAAL";
import {
  MapActionType,
  ProductAction,
  ProductType,
  ProductTypeName,
} from "utils/data/enum";
import { getDefaultAalObject } from "utils/data/products";
import { formatDate } from "utils/shared";
import LetterProduct from "pages/file/components/product-section/common/LetterProduct";
import VoidLetterForm from "pages/file/components/product-section/common/VoidLetterForm";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import * as UIModel from "entities/UIModel";
import useAALErrorHandler from "utils/custom-hooks/useAALErrorHandler";
import { gaps } from "theme/defaultStyle";
import useFocus from "utils/custom-hooks/useFocus";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const AALProductCollection = () => {
  const [openVoidAALDialog, setOpenVoidAALDialog] = React.useState<boolean>(false);
  const [voidAalButtonDisabled, setVoidAalButtonDisabled] = React.useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = React.useState<UIModel.AAL>(getDefaultAalObject());
  const [currentDefaultVoidReason, setCurrentDefaultVoidReason] = React.useState<string>("");
  const [currentProductIndex, setCurrentProductIndex] = React.useState<number>(0);
  const [submitVoidButtonDisabled, setSubmitVoidButtonDisabled] = React.useState<boolean>(false);
  const [issueAALButtonDisabled, setIssueAALButtonDisabled] = React.useState<boolean>(false);
  const [submitReviseDisabled, setSubmitReviseDisabled] = React.useState<boolean>(false);
  const [openProgressDialog, setOpenProgressDialog] = React.useState<boolean>(false);
  const [progressMessage, setProgressMessage] = React.useState<string>("");
  const [progressRequestId, setProgressRequestId] = React.useState<string>("");
  const [runProgress, setRunProgress] = React.useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const indexToDeleteRef = React.useRef<number | undefined>();
  const currentActionResult = React.useRef<UIModel.ActionResult>({});
  const currentProductIntegrationKey = React.useRef<string>("");
  const currentProductOriginalOrderID = React.useRef<number>(0);
  const currentProductAction = React.useRef<string>("");
  const companyStateLocationsRef = React.useRef<CompanyStateLocation[]>([]);

  const { setFocusInputElement } = useFocus();
  const [{ companyStateLocations }] = useCompanyLocation();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const [{ initialValues }, { issueAAL, voidProduct, deleteOrder, revise },] = useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const { resetValidationRequiredFlags, setValidLocations, reloadFile } = useCreateFile();
  const { validateEffectiveDate } = useEffectiveDateValidation();
  const { getPDFDocuments, openPdfViewer, setOpenPdfViewer, pdfDocuments } = usePDFDocumentsAAL();
  const { getValues, setValue, trigger, clearErrors } = useFormWrapper();
  const { errorMessage, correlationId,  setErrorMessage, setCorrelationId, openErrorDialog, setOpenErrorDialog, checkError, handleError, clearError } = useAALErrorHandler();
  const { fields, append, remove } = useFieldArray({ name: "aALProducts", keyName: "fieldId" });
  const [fileNameNumber, hasIssuedProducts, isPopulatingExistingFile] = useWatch({
    name: ["fileNameNumber", "hasIssuedProducts", "isPopulatingExistingFile"]
  });
  const isFileDisabled = useFieldDisabler("AddAALButton");

  const aals = getValues("aALProducts") as Array<UIModel.AAL>;

  const issueAal = async (product: UIModel.AAL, integrationKey: string) => {
    currentProductIntegrationKey.current = integrationKey;
    currentProductAction.current = ProductAction.Issue;
    currentActionResult.current = {};

    // clear old action error
    clearError();

    // clear  previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    const aals = getValues("aALProducts") as Array<UIModel.AAL>;
    for (const [index, aal] of aals.entries()) {
      if (aal.integrationKey === product.integrationKey) {
        setValue(`aALProducts.${index}.isValidationRequired`, true);
        //-- Validate againts contract date, this is needed due to the user may click on Issue button without losing the focus from EffectiveDate field
        await validateEffectiveDate("aALProducts", index, aal.effectiveDate, false);

        if (aal.pricingDetail) {
          setValue(
            `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
            true
          );
        }
        if (aal.state?.code || aal.state?.abbr) {
          setValue(`aALProducts.${index}.state.required`, false);
        } else {
          setValue(`aALProducts.${index}.state`, {
            code: "",
            abbr: "",
            required: true,
          });
        }
      } else {
        setValue(`aALProducts.${index}.isValidationRequired`, false);
        if (aal.pricingDetail) {
          setValue(
            `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
            false
          );
        }
      }
    }

    setValidLocations(companyStateLocationsRef.current);
    const isValid = await runValidation({
      values: getValues(),
      trigger,
      setValue,
      productType: ProductType.Aal,
    });

    if (!isValid) return;

    // Disaable Issue button
    setIssueAALButtonDisabled(true);

    // Issue aal
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues() as UIModel.SCFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Issue,
      ProductType.Aal,
      [product.integrationKey]
    );

    // -- show progress dialog
    let progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setProgressMessage("Please wait while issuing your AAL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    // -- calling issue aal
    const submit = async () => {
      setPricingNotificationUpdated(false);
      currentActionResult.current = await issueAAL(
        apiSCFile,
        progressRequestId
      );
      setPricingNotificationUpdated(true);
    };

    await submit();
  };

  const handleReviseAal = async (
    integrationKey: string,
    originalOrderID: number
  ) => {
    currentProductIntegrationKey.current = integrationKey;
    currentProductOriginalOrderID.current = originalOrderID;
    currentProductAction.current = ProductAction.Revise;
    currentActionResult.current = {};

    clearError();

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues() as UIModel.SCFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Revise,
      ProductType.Aal,
      [integrationKey]
    );

    const requestId = uuidv4();
    setProgressRequestId(requestId);
    setProgressMessage("Please wait while we revise your AAL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    setPricingNotificationUpdated(false);
    currentActionResult.current = await revise(
      apiSCFile,
      ProductType.Aal,
      requestId,
      integrationKey
    );
    setPricingNotificationUpdated(true);

    const hasError = checkError(
      currentActionResult.current,
      currentProductIntegrationKey.current,
      currentProductAction.current
    );
    if (hasError) {
      setSubmitReviseDisabled(false);
      return
    }

    await reloadFile();
    setSubmitReviseDisabled(true);

  };

  const handleVoidAal = async (
    product: UIModel.AAL,
    productIndex: number,
    defaultVoidReason: string
  ) => {
    setVoidAalButtonDisabled(true);

    // set void form parameter here
    setCurrentProduct(product);
    setCurrentDefaultVoidReason(defaultVoidReason);
    setCurrentProductIndex(productIndex);

    setOpenVoidAALDialog(true);
  };

  const handleVoidSubmit = async (
    product: UIModel.AAL,
    defaultVoidReason: string
  ) => {
    // Disaable submit button on VoidLetterForm
    setSubmitVoidButtonDisabled(true);

    // Store current action parameters
    currentProductIntegrationKey.current = product.integrationKey;
    currentProductAction.current = ProductAction.Void;

    // initialiaze
    clearError();
    currentActionResult.current = {};

    if (product.void && !product.void.reason)
      product.void.reason = defaultVoidReason;

    // Void AAL
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues() as UIModel.SCFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Void,
      ProductType.Aal,
      [product.integrationKey]
    );

    let progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setProgressMessage("Please wait while voiding your AAL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    // -- calling issue aal api
    const submit = async () => {
      setPricingNotificationUpdated(false);
      currentActionResult.current = await voidProduct(
        apiSCFile,
        progressRequestId,
        ProductType.Aal,
        product.integrationKey
      );
      setPricingNotificationUpdated(true);
    };

    await submit();
  };

  const handleEffectiveDateChange = async (index: number, newDate: Date | null) => {
    // setTimeout is currently needed to handle timing issue. Investigate if this is necessary in react18.
    setTimeout(async () => {
      await validateEffectiveDate("aALProducts", index, newDate);
    });
  };

  const handleVoidCancel = () => {
    setValue(`aALProducts[${currentProductIndex}].void.reason`, "");
    setOpenVoidAALDialog(false);
    setVoidAalButtonDisabled(false);
  };

  const handleCloseVoidAal = () => {
    setOpenVoidAALDialog(false);
    setVoidAalButtonDisabled(false);
  };

  // events triggered by components
  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
  };

  const handleOnCloseProgressDialog = async () => {
    setOpenProgressDialog(false);
    setRunProgress(false);
    setProgressRequestId("");

    // Enable back the Submit button
    setIssueAALButtonDisabled(false);
    setSubmitVoidButtonDisabled(false);

    // check for error
    const hasError = checkError(
      currentActionResult.current,
      currentProductIntegrationKey.current,
      currentProductAction.current
    );

    if (hasError) {
      handleError(currentActionResult.current, currentProductIntegrationKey.current, currentProductAction.current);
      return;
    }

    //open the document(s) for the issued Aals
    if (
      currentProductAction.current === ProductAction.Issue ||
      currentProductAction.current === ProductAction.Revise
    ) {
      getPDFDocuments(
        currentActionResult.current,
        currentProductIntegrationKey.current,
        currentProductAction.current,
        currentProductOriginalOrderID.current
      );

    }
  };

  const addNewAal = () => {
    const newAal = getDefaultAalObject(true);
    newAal.integrationKey = uuidv4();
    const newItemIndex = aals.length;
    append(newAal);
    setValue(`aALProducts.${newItemIndex}.addresseeNamesData`, null);

    // set 1st aal as dirty
    setValue(`aALProducts.0.isDefault`, false);
    setValue(`aALProducts.0.isDirty`, true);

    // set focus to last AAL
    setFocusInputElement(`aALProducts.${newItemIndex}.effectiveDate`);
  };

  const openDeleteDialog = (deleteProdIndex: number) => {
    indexToDeleteRef.current = deleteProdIndex;
    setDialogOpen(true);
  };

  const deleteItem = async () => {
    if (indexToDeleteRef.current === undefined) return;
    const deleteItemIndex = indexToDeleteRef.current;

    if (aals) {
      remove(deleteItemIndex);
      // Check if the product is saved already
      // If yes, delete from db
      if (aals.length - 1 >= deleteItemIndex) {
        const deleteOrderId = aals[deleteItemIndex].orderID;
        if ((deleteOrderId || 0) > 0) {
          setDialogOpen(false);
          openAutomaticProgressDialog();

          await deleteOrder(aals[deleteItemIndex].orderID);
          await reloadFile();
          closeAutomaticProgressDialog();
        }
      }
      // rlo 10/14/2022 - this causes not working correctly right after delete
      // setValue(`aALProducts.${deleteItemIndex}.addresseeNamesData`, null);
    }

    indexToDeleteRef.current = undefined;
    setDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    indexToDeleteRef.current = undefined;
    setDialogOpen(false);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey && document?.getElementById(ProductTypeName.JACKET)) {
      evt.preventDefault();
      document?.getElementById(ProductTypeName.JACKET)?.focus();
    }
  }

  React.useEffect(() => {
    companyStateLocationsRef.current = companyStateLocations;
  }, [companyStateLocations]);

  React.useEffect(() => {
    if (aals.length > 0) return;
    const newAal = getDefaultAalObject();
    newAal.integrationKey = uuidv4();
    append(newAal);
    setValue(`aALProducts.0`, newAal);
    setValue(`aALProducts.0.addresseeNamesData`, null);

  }, [aals.length, append, setValue]);

  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => {
          return (
            <LetterProduct
              key={field.fieldId}
              productEntity="aALProducts"
              productType={ProductType.Aal}
              product={field}
              onDelete={openDeleteDialog}
              productIndex={index}
              issueButtonDisabled={issueAALButtonDisabled || isFileDisabled}
              voidButtonDisabled={voidAalButtonDisabled}
              submitReviseDisabled={submitReviseDisabled}
              issueLetter={async (integrationKey: string) => {
                await issueAal(field, integrationKey);
              }}
              reviseLetter={async (
                integrationKey: string,
                originalOrderID: number
              ) => {
                await handleReviseAal(integrationKey, originalOrderID);
              }}
              voidLetter={async (defaultVoidReason: string) => {
                await handleVoidAal(field, index, defaultVoidReason);
              }}
              fileNameNumber={fileNameNumber}
              hasIssuedProducts={hasIssuedProducts}
              isPopulatingExistingFile={isPopulatingExistingFile}
              cpls={[]}
              aALProducts={aals}
              onEffectiveDateChange={(date: Date | null) =>
                handleEffectiveDateChange(index, date)
              }
            />
          );
        })}

        <Box>
          <AddLink
            disabled={isFileDisabled}
            onClick={addNewAal}
            onKeyDown={handleKeyDown}
          >
            Add AAL
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        confirmationMessage="Are you sure you want to delete this pending AAL?"
        onYes={() => deleteItem()}
        onNo={() => closeDeleteDialog()}
      />
      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title={progressMessage}
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={handleOnCloseProgressDialog}
        requestId={progressRequestId}
        runProgress={runProgress}
        onCloseAction={handleCloseVoidAal}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        {...{ pdfDocuments }}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
      <VoidLetterForm
        productType={ProductType.Aal}
        coveredParty={currentProduct.coveredParty}
        serialNumber={currentProduct.productReferenceID || ""}
        effectiveDate={formatDate(
          new Date(String(currentProduct.effectiveDate))
        )}
        productIndex={currentProductIndex}
        isOpen={openVoidAALDialog}
        onSubmit={(e: any) =>
          handleVoidSubmit(currentProduct, currentDefaultVoidReason)
        }
        onCancel={handleVoidCancel}
        submitButtonDisabled={submitVoidButtonDisabled}
        defaultVoidReason={currentDefaultVoidReason}
      />
    </>
  );
};

export default AALProductCollection;
