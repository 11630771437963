import React from 'react';

const PdfIconBigRed = ({width, height}: any) => (
  <svg width={width ?? "40"} height={height ?? "51"} viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 0.5H24.31C25.194 0.500177 26.0417 0.829533 26.6667 1.41563L39.0233 13C39.6485 13.5859 39.9998 14.3806 40 15.2094V44.25C40 45.9076 39.2976 47.4973 38.0474 48.6694C36.7971 49.8415 35.1014 50.5 33.3333 50.5H6.66667C4.89856 50.5 3.20286 49.8415 1.95262 48.6694C0.702379 47.4973 0 45.9076 0 44.25V6.75C0 5.0924 0.702379 3.50269 1.95262 2.33058C3.20286 1.15848 4.89856 0.5 6.66667 0.5V0.5ZM25 5.1875V11.4375C25 12.2663 25.3512 13.0612 25.9763 13.6472C26.6014 14.2333 27.4493 14.5625 28.3333 14.5625H35L25 5.1875ZM7.21667 43.2125C7.51667 43.775 7.98333 44.2844 8.67667 44.5219C9.36667 44.7562 10.05 44.6469 10.61 44.4281C11.67 44.0219 12.7267 43.0656 13.6967 41.9719C14.8067 40.7188 15.9733 39.075 17.1 37.2531C19.2751 36.6487 21.5025 36.2242 23.7567 35.9844C24.7567 37.1812 25.79 38.2125 26.79 38.9531C27.7233 39.6406 28.8 40.2125 29.9033 40.2562C30.5043 40.2841 31.0993 40.1332 31.6033 39.825C32.12 39.5094 32.5033 39.0531 32.7833 38.525C33.0833 37.9594 33.2667 37.3687 33.2433 36.7656C33.2222 36.171 32.9873 35.6005 32.5767 35.1469C31.8233 34.3031 30.59 33.8969 29.3767 33.6937C27.9048 33.48 26.4114 33.4276 24.9267 33.5375C23.6729 31.8758 22.5796 30.1126 21.66 28.2687C22.4933 26.2062 23.1167 24.2563 23.3933 22.6625C23.5133 21.9813 23.5767 21.3313 23.5533 20.7437C23.5492 20.1606 23.4045 19.5858 23.13 19.0625C22.9719 18.7737 22.7471 18.5215 22.4721 18.3242C22.1971 18.1269 21.8787 17.9895 21.54 17.9219C20.8667 17.7875 20.1733 17.9219 19.5367 18.1625C18.28 18.6313 17.6167 19.6313 17.3667 20.7344C17.1233 21.7969 17.2333 23.0344 17.52 24.2844C17.8133 25.5531 18.3133 26.9344 18.9533 28.3313C17.9296 30.7181 16.7473 33.0425 15.4133 35.2906C13.6955 35.7973 12.0396 36.473 10.4733 37.3063C9.24 37.9937 8.14333 38.8062 7.48333 39.7656C6.78333 40.7844 6.56667 41.9969 7.21667 43.2125Z"
      fill="url(#paint0_linear_50_2265)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_50_2265"
        x1="20"
        y1="0.5"
        x2="20"
        y2="50.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E2339" />
        <stop offset="1" stopColor="#620000" />
      </linearGradient>
    </defs>
  </svg>
);

export default PdfIconBigRed;
