import ActionButton from 'controls/global/action-button';
import AgencyField from './AgencyField';
import AgencyFieldHeader from './AgencyFieldHeader';
import ClearIcon from '@mui/icons-material/Clear';
import GlobalAccessGrid from './GlobalAccessGrid';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PageSection from 'controls/global/page-section';
import ScrollToTopArrow from 'controls/global/scroll-to-top-arrow';
import SearchIcon from 'theme/icons/SearchIcon';
import StateDropdown from './StateDropdown';
import styled from 'styled-components';
import { mapApiToUi } from './mapApitoUi';
import {
  gaps,
  margin,
  padding,
} from 'theme/defaultStyle';
import { useGlobalAccess } from 'utils/context/GlobalAccessContext';
import React, {
  useEffect,
  useState,
} from "react";
import {
  useForm,
  FormProvider,
} from "react-hook-form";
import {
  Box,
  Typography,
} from "@material-ui/core";
import { CompanyLocationState } from 'entities/ApiModel/CompanyLocationState';
import { useProfileSettingsCache } from 'utils/context/ProfileSettingsContext';

const StyledBoxContainer = styled(Box)({
  position: "relative",
  "& .MuiOutlinedInput-input": {
    marginBottom: margin.xsmall0,
    marginLeft: margin.small2,
    marginTop: margin.small,
    padding: `${padding.small} ${padding.small2}`,
  },
});

const StyledActionButton = styled(ActionButton)({
  height: "38px",
  width: "96px",
});

const SearchRow = styled("div")({
  display: "flex",
  gap: gaps.large1,
  "& > :nth-child(1)": {
    flex: "0 0 auto",
    width: "80px",
  },
  "& > :nth-child(2)": {
    flex: "1",
  },
  "& > :nth-child(3)": {
    flex: "0 0 auto",
  },
  "& > div": {
    display: "flex",
    gap: gaps.small3,
  },
});

const GlobalAccessHome = () => {
  const INITIAL_VALUES = {
    agency: "",
    state: "All",
  };

  const methods = useForm({ defaultValues: INITIAL_VALUES });
  const { reset, getValues } = methods;
  const [{ companyLocationStates }, { setShowChangeAgency }] = useGlobalAccess();
  const [{ profileSettings }] = useProfileSettingsCache();
  const [noRecordMessage, setNoRecordsMessage] = useState<string[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any>([]);
  const [isClear, setIsClear] = useState(false);
  const [page, setPage] = useState(1);

  const activeCompanies = companyLocationStates.filter((company: CompanyLocationState) => company.Status === "Active");  
  const clearAgencyInput = () => {
    reset(INITIAL_VALUES);
    setNoRecordsMessage([""]);
    setIsClear(true);
    setPage(1);
  };

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPage(1);
    event.preventDefault();
    const userTypedValue = getValues("agency").trim();
    const userTypedValueLowerCase = userTypedValue.toLowerCase();
    const userSelectedValue = getValues("state");
    let filteredCompanies = [...activeCompanies];

    if (userSelectedValue !== "All") {
      filteredCompanies = filteredCompanies.filter((company) => company.TerritoryStates?.includes(userSelectedValue));
    }

    if (userTypedValueLowerCase !== "") {
      filteredCompanies = filteredCompanies.filter((company) =>
        Object.values(company).some((value) => value?.toLowerCase().includes(userTypedValueLowerCase)),
      );
    }

    if (filteredCompanies.length === 0) {
      setNoRecordsMessage([
        `No matches found for ${userTypedValue}`,
        "Please adjust your search criteria and search again.",
      ]);
    } else {
      setNoRecordsMessage([""]);
    }
    setFilteredCompanies(mapApiToUi(filteredCompanies));
  };

  useEffect(() => {
    setFilteredCompanies(mapApiToUi([...activeCompanies]));
    if (isClear) {
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    setShowChangeAgency(
        activeCompanies?.length > 1 &&
        profileSettings?.userAgencyCount > 1 &&
        Boolean(profileSettings?.isStaffUser) &&
        profileSettings?.userProfile?.skipAgencySelection === "No",
    );
  }, [activeCompanies, profileSettings, setShowChangeAgency]);

  return (
    <StyledBoxContainer>
      <FormProvider {...methods}>
        <form noValidate>
          <PageSection
            icon={<InsertChartIcon />}
            title="Select an Agency"
            contentAlignment="beside-header"
          >
            <>
              <AgencyFieldHeader />
              <Typography className="subheading">Enter search criteria below to find an agency.</Typography>
              <SearchRow>
                <StateDropdown />
                <AgencyField />
                <div>
                  <StyledActionButton
                    variant="outlined"
                    color="secondary"
                    startIcon={<ClearIcon />}
                    onClick={clearAgencyInput}
                  >
                    Clear
                  </StyledActionButton>
                  <StyledActionButton
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                    type="submit"
                  >
                    Search
                  </StyledActionButton>
                </div>
              </SearchRow>
              <Typography className="subheading">{"Select an agency below to proceed."}</Typography>
              <GlobalAccessGrid
                filteredCompanies={filteredCompanies}
                noRecordMessage={noRecordMessage}
                setNoRecordsMessage={setNoRecordsMessage}
                page={page}
                setPage={setPage}
              />
            </>
          </PageSection>
        </form>
      </FormProvider>
      <ScrollToTopArrow />
    </StyledBoxContainer>
  );
};

export default GlobalAccessHome;
