import React from "react";
import StewartRadio from "controls/global/radio-button/Radio";
import { Controller } from "react-hook-form";
import { UserRequestAuthorizationType } from "utils/data/enum";
import { 
  margin, 
  padding,
 } from "theme/defaultStyle";
import { 
  Box,
  FormControlLabel, 
  RadioGroup,
  styled, 
} from "@material-ui/core";

function RadioButtonField() {
  const StyledBox = styled(Box)({
    padding: padding.zero,
    margin: margin.zero,
    "& .MuiRadio-root": {
      marginTop: "-6px",
      marginBottom: "-8px",
    },
  });

  return (
    <StyledBox>
      <Controller
        name="radioSelection"
        defaultValue="authorized"
        render={({ field }) => (
          <RadioGroup {...field}>
            <FormControlLabel
              value={UserRequestAuthorizationType.Authorized}
              control={<StewartRadio />}
              label="I am authorized to change our Stewart Connect users"
            />
            <FormControlLabel
              value={UserRequestAuthorizationType.ProvidedAuthorization}
              control={<StewartRadio />}
              label="I have been provided authorization to change our Stewart Connect users"
            />
            <FormControlLabel
              value={UserRequestAuthorizationType.NotAuthorized}
              control={<StewartRadio />}
              label="I have not been authorized"
            />
          </RadioGroup>
        )}
      />
    </StyledBox>
  );
}

export default RadioButtonField;
