import Button from "@material-ui/core/Button";
import React from "react";

interface Props {
  saveFileRef?: React.MutableRefObject<HTMLButtonElement>;
  saveFileChangeRef?: React.MutableRefObject<HTMLButtonElement>;
  handleSaveFile: () => void;
  handleSaveChanges: () => void;
}

const HeaderRefs = ({
  saveFileRef,
  saveFileChangeRef,
  handleSaveFile,
  handleSaveChanges,
}: Props) => {
  return (
    <>
      <Button innerRef={saveFileRef} hidden onClick={handleSaveFile} />
      <Button innerRef={saveFileChangeRef} hidden onClick={handleSaveChanges} />
    </>
  );
};

export default HeaderRefs;
