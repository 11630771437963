import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import React from "react";
import { hasSelectedNodes } from "utils/signature/treeView";
// import { getExpandedNodes, getSelectedNodes } from "utils/signature/treeView";
import { SignatureMode } from "./SignatureProvider";

const resetDataEvent = new Event("reset_data");

type State = {
  nodes?: SignatureConfigurationNode | null;
  hasSelectedNodes: boolean;
};

type ActionTypes = "INIT" | "DATA_CHANGED" | "RESET";
type Action = {
  type: ActionTypes;
  mode?: SignatureMode;
  data?: SignatureConfigurationNode;
  nodes?: string[];
};

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const ConfigurationContext = React.createContext<Context | undefined>(
  undefined
);
ConfigurationContext.displayName = "ConfigurationContext";

const configurationReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        nodes: action.data,
        hasSelectedNodes: hasSelectedNodes(action.data),
      };
    case "DATA_CHANGED":
      return {
        ...state,
        nodes: action.data,
        hasSelectedNodes: hasSelectedNodes(action.data),
      };
    case "RESET":
      document.dispatchEvent(resetDataEvent);
      return {
        nodes: undefined,
        hasSelectedNodes: false,
      };
  }
};

const ConfigurationProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(configurationReducer, {
    hasSelectedNodes: false,
  });
  return (
    <ConfigurationContext.Provider {...props} value={{ state, dispatch }} />
  );
};

const useConfigurationProvider = () => {
  const context = React.useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      "useConfigurationProvider must be used within a <ConfigurationProvider>"
    );
  }

  return context;
};

export { ConfigurationProvider, useConfigurationProvider };
