import { Typography } from "@material-ui/core";
import AutocompleteField from "controls/global/custom-autocomplete/AutocompleteField";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { SCFileResponse } from "entities/UIModel";
import { isSCFileResponse } from "pages/file/utils/autocomplete";
import React,
{
  ChangeEvent,
  useEffect,
  useRef,
} from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDuplicatedFileCheck } from "utils/context/DuplicatedFileCheckContext";
import { useFileAutocomplete } from "utils/context/FileAutocompleteContext";
import { useFiles } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  REGEX_NONE_ASCII,
} from "utils/shared";

const FileNameNumberFieldName = "fileNameNumber";
const FileNameNumberField = ({ ...rest }) => {
  const fileNameValueRef = useRef();
  const agencyIdValueRef = useRef();

  const navigate = useNavigate();
  const [{ selectedAgency }] = useGlobalAccess();  

  const { setValue, getValues, setError, clearErrors } = useFormWrapper();
  const watchFields = useWatch({ name: ["id", "agency", "fileNameNumber"] });
  const idField = watchFields[0];
  const agencyField = watchFields[1];
  const fileNameNumber = watchFields[2];

  const [, { resetNonFileSections, resetInitialValues }] = useFiles();
  const [, { getFiles }] = useFileAutocomplete();  
  const [
    {
      isDuplicatedFile,
      showAlertDuplicatedFile,
      prevFileNameValueState,
      uniqueFileId,
    },
    {
      getUniqueFileByAgencyClientFileId,
      setIsDuplicatedFile,
      setShowAlertDuplicatedFile,
    },
  ] = useDuplicatedFileCheck();

  const getAgencySelected = () => {
    if(selectedAgency?.length > 0){
      return selectedAgency[0].CompanyID;
    }    
  };

  useEffect(() => {
    checkValidationFileName();
  }, [isDuplicatedFile]);

  useEffect(() => {
    fileNameValueRef.current = undefined;
  }, [prevFileNameValueState]);

  const checkValidationFileName = () => {
    clearErrors(FileNameNumberFieldName);
    if (isDuplicatedFile) {
      setError(
        FileNameNumberFieldName,
        {
          type: "isDuplicatedFilename",
        },
        { shouldFocus: true }
      );
    }
  };

  const onGetFiles = (newInputValue: string): Promise<SCFileResponse[]> => {
    if (!newInputValue) return new Promise<SCFileResponse[]>(() => []);
    return getFiles(newInputValue, agencyField?.id);
  };

  const onValueChanged = (newValue: SCFileResponse | string) => {
    if (typeof newValue !== "string") {
      const { fileId } = newValue;
      resetInitialValues();
      navigate("/file", {state: {fileID: fileId}});
      return;
    }

    setValue(FileNameNumberFieldName, newValue);
    setValue("isFileDefault", false);
  };

  const handleYes = async (e: any) => {
    setShowAlertDuplicatedFile(false);
    resetInitialValues();
    fileNameValueRef.current = undefined;
    agencyIdValueRef.current = undefined;
    navigate("/file", {state: {fileID: uniqueFileId}});
  };

  const handleNo = (e: any) => {
    resetNonFileSections(0, getValues(FileNameNumberFieldName), {
      id: "",
      name: "",
    });
    setShowAlertDuplicatedFile(false);
    setIsDuplicatedFile(true);
  };

  const onBlurHandler = async (e: any) => {
    const fileValue = e.target.value;    
    if (!fileValue) {
      fileNameValueRef.current = undefined;
      agencyIdValueRef.current = undefined;
      return;
    }
    const defaultedAgency = getAgencySelected();    
    if (!agencyField || agencyField.id === "" || !defaultedAgency) {
      return;
    }
    
    const prevFileNameValue = fileNameValueRef.current;
    const prevAgencyIdValue = agencyIdValueRef.current;
    // Avoid calls to the API when values are the same between onBlur events
    if (
      prevFileNameValue === fileValue &&
      prevAgencyIdValue === agencyField.id
    ) {
      return;
    }

    fileNameValueRef.current = fileValue;
    agencyIdValueRef.current = agencyField.id ?? defaultedAgency;
    
    const fileId = await getUniqueFileByAgencyClientFileId(
      agencyField.id,
      e.target.value
    );

    if (fileId === 0 || !fileId) {
      setIsDuplicatedFile(false);
    }
    
    if (fileId > 0) {
      setShowAlertDuplicatedFile(true);
    } else if ((idField || 0) > 0)
      resetNonFileSections(0, e.target.value, { id: "", name: "" });
  };

  const onFocusHandler = (evt: ChangeEvent<any>) => {
    if (fileNameNumber) {
      fileNameValueRef.current = fileNameNumber;
    }

    if (agencyField && agencyField.id) {
      agencyIdValueRef.current = agencyField.id;
    }
  };

  const getOptionLabel = (option: SCFileResponse | string) =>
    isSCFileResponse(option) ? option.clientFileId : option;

  const getOptionSelected = (option: SCFileResponse, value: SCFileResponse) =>
    option.fileId === value.fileId;

  const renderOption = (option: SCFileResponse) => (
    <Typography
      data-test="fileNameNumber_MenuItem"
      data-fileid={option.fileId}
    >{`${option.clientFileId} - ${option.agencyName}`}</Typography>
  );

  const onTypeInputHandler = (inputText: string) => {
    const validValue = inputText.replace(REGEX_NONE_ASCII, "");
    const currentValue: string = getValues(FileNameNumberFieldName);
    if (currentValue === validValue) {
      setValue(FileNameNumberFieldName, inputText);
    }
    if (inputText !== validValue) {
      setTimeout(() => {
        setValue(FileNameNumberFieldName, validValue);
      }, 10);
    }
  };

  return (
    <>
      <AutocompleteField
        name={FileNameNumberFieldName}
        label="File Name/Number"
        minLengthBeforeSearch={3}
        onGetOptions={onGetFiles}
        onValueChanged={onValueChanged}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onBlur={onBlurHandler}
        onFocus={(e) => {
          onFocusHandler(e);
        }}
        maxLength={100}
        disabled={Boolean((idField || 0) > 0)}
        onTypeInput={onTypeInputHandler}
        resetDefaultValue={FileNameNumberFieldName}
        {...rest}
      />
      {showAlertDuplicatedFile && (
        <ConfirmationDialog
          confirmationMessage="The file already exists for your selected agency. Would you like to open the existing file?"
          isOpen={showAlertDuplicatedFile}
          onYes={handleYes}
          onNo={handleNo}
        />
      )}
    </>
  );
};

export default FileNameNumberField;
