import {
  ActionResult,
  SCFile,
} from "entities/UIModel";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React,
{
  useRef,
  useState,
} from "react";
import { useFiles } from "utils/context/FilesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  MapActionType,
  ProductAction,
  ProductType,
} from "utils/data/enum";
import VoidEndorsementForm from "../VoidEndorsementForm";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar/ConfirmationDialogWithProgressbar";
import { usePricingActions } from "utils/context/PricingContext";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import { getCorrelationId } from "pages/file/utils/helper";

type Props = {
  isOpen: boolean;
  integrationKey: string;
  endorsementName: string;
  parentOrderID: number;
  parentProductType: string;
  voidRequestId: string;
  onCancel: () => void;
  onVoidComplete: () => void;
};

const errorDialogInitState = {
  open: false,
  errorMessage: "",
  correlationId: "",
};

export default function Void({
  isOpen,
  integrationKey,
  endorsementName,
  parentOrderID,
  parentProductType,
  voidRequestId,
  onCancel,
  onVoidComplete
}: Props) {
  const { getValues } = useFormWrapper();
  const [openProgressDialog, setOpenProgressDialog] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = React.useState(errorDialogInitState);

  const [{ initialValues }, { voidProduct }] = useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();

  // persist local variable
  let currentActionResult = useRef<ActionResult>({});
  let currentEndorsementIntegrationKey = useRef("");
  let currentParentProductType = useRef("");
  let currentParentOrderID = useRef(0);

  const handleVoidSubmit = async () => {
    const currentValues: SCFile = getValues();

    const convertedFile = mapUiSCFileToApiSCFile(
      initialValues,
      currentValues,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Void,
      ProductType.Endorsement,
      [integrationKey || ""]
    );

    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setProgressPercentage(true);

    setPricingNotificationUpdated(false);

    currentEndorsementIntegrationKey.current = integrationKey || "";
    currentParentProductType.current = parentProductType;
    currentParentOrderID.current = parentOrderID;
    currentActionResult.current = await voidProduct(
      convertedFile,
      voidRequestId,
      ProductType.Endorsement,
      integrationKey || "",
      parentProductType,
      parentOrderID
    );

    setPricingNotificationUpdated(true);

    setTimeout(() => setBypassDefaultLoader(true), 1000);

    if (hasError()) {
      handleError();
    }
    else {
      onVoidComplete();
    }
  };

  const hasError = () => {
    // check bad request error and file level error
    if (
      currentActionResult.current.error ||
      currentActionResult.current.scfile?.errorMessage
    )
      return true;

    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;

      // check file level error
      if (scfile?.errorMessage) return true;

      // check endorsement level error
      if (currentParentProductType.current === ProductType.Jacket) {
        const parent = scfile.jackets?.find(
          (jacket) => jacket.orderID === currentParentOrderID.current
        );
        if (parent) {
          if (parent.errorMessage) return true;

          const errorEndorsement = parent.endorsements?.find(
            (e) =>
              e.integrationKey === currentEndorsementIntegrationKey.current &&
              e.errorMessage
          );
          if (errorEndorsement) return true;
        }
      }

      if (
        currentParentProductType.current === ProductType.StandaloneEndorsement
      ) {
        const parent = scfile.standaloneEndorsements?.find(
          (standalone) => standalone.orderID === currentParentOrderID.current
        );
        if (parent) {
          if (parent.errorMessage) return true;

          const errorEndorsement = parent.endorsements?.find(
            (e) =>
              e.integrationKey === currentEndorsementIntegrationKey.current &&
              e.errorMessage
          );
          if (errorEndorsement) return true;
        }
      }
    }

    return false;
  };

  const handleError = () => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.current.error &&
      currentActionResult.current.error.response &&
      currentActionResult.current.error.response.status !== 200
    ) {
      const errorMessage =
        currentActionResult.current.error.response.data &&
          currentActionResult.current.error.response.data.errorMessage
          ? currentActionResult.current.error.response.data.errorMessage
          : currentActionResult.current.error.response.statusText;

      setErrorDialog({
        open: true,
        errorMessage: errorMessage,
        correlationId: getCorrelationId(currentActionResult.current.error.response?.headers)
      });
      return;
    }

    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as SCFile;
      let errorMessage = "";

      // Check for File level error
      if (scfile && scfile.errorMessage) {
        errorMessage += scfile.errorMessage;
      }

      // Check for Endorsement level error
      if (currentParentProductType.current === ProductType.Jacket) {
        const parent = scfile.jackets?.find(
          (jacket) => jacket.orderID === currentParentOrderID.current
        );
        if (parent) {
          if (parent.errorMessage) {
            if (errorMessage.length > 0) errorMessage += " ";
            errorMessage += parent.errorMessage;
          }

          const errorEndorsement = parent.endorsements?.find(
            (e) =>
              e.integrationKey === currentEndorsementIntegrationKey.current &&
              e.errorMessage
          );
          if (errorEndorsement) {
            if (errorMessage.length > 0) errorMessage += " ";
            errorMessage += errorEndorsement.errorMessage;
          }
        }
      } else if (
        currentParentProductType.current === ProductType.StandaloneEndorsement
      ) {
        const parent = scfile.standaloneEndorsements?.find(
          (standalone) => standalone.orderID === currentParentOrderID.current
        );
        if (parent) {
          if (parent.errorMessage) {
            if (errorMessage.length > 0) errorMessage += " ";
            errorMessage += parent.errorMessage;
            return;
          }

          const errorEndorsement = parent.endorsements?.find(
            (e) =>
              e.integrationKey === currentEndorsementIntegrationKey.current &&
              e.errorMessage
          );
          if (errorEndorsement) {
            if (errorMessage.length > 0) errorMessage += " ";
            errorMessage += errorEndorsement.errorMessage;
          }
        }
      }

      if (errorMessage.length > 0) {
        setErrorDialog({
          open: true,
          errorMessage: errorMessage,
          correlationId: getCorrelationId(currentActionResult.current?.headers)
        });
        return;
      }
    }
  };

  return (
    <>
      <VoidEndorsementForm
        isOpen={isOpen}
        endorsementName={endorsementName}
        onSubmit={handleVoidSubmit}
        onCancel={onCancel}
      />
      {openProgressDialog && <ConfirmationDialogWithProgressbar
        autoClose={true}
        title="Please wait while voiding endorsement"
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={onCancel}
        requestId={voidRequestId}
        runProgress={progressPercentage}
      />}
      <ErrorDialog
        isOpen={errorDialog.open}
        confirmationMessage={errorDialog.errorMessage}
        correlationId={errorDialog.correlationId}
        onYes={() => setErrorDialog(errorDialogInitState)}
      />
    </>
  );
}
