import { PricingConfig, PricingProduct } from "entities/UIModel";
import {
  JacketFormType,
  PricingConfigKey,
  PricingType,
  ProductType,
} from "utils/data/enum";
import useFormWrapper from "./useFormWrapper";
import React from "react";
import useConfig from "./useConfig";
import { isEqual } from "lodash";

type PricingColumns = {
  showLiabilityColumn: boolean;
  showRateTypeColumn: boolean;
  showReissueColumn: boolean;
  showTransCodeColumn: boolean;
  showRiskRateColumn: boolean;
};

export default function usePricingTable(
  pricingProducts?: PricingProduct[] | undefined
) {
  const { getValues } = useFormWrapper();
  const { getPricingConfig } = useConfig();

  const [columns, setColumns] = React.useState<PricingColumns>({
    showLiabilityColumn: false,
    showRateTypeColumn: false,
    showReissueColumn: false,
    showRiskRateColumn: false,
    showTransCodeColumn: false,
  });

  const pricingProductsRef = React.useRef<PricingProduct[] | undefined>(
    pricingProducts
  );

  if (!isEqual(pricingProductsRef.current, pricingProducts)) {
    pricingProductsRef.current = pricingProducts;
  }

  const isIntegratedPricing: boolean =
    getValues("pricing.isIntegratedPricing") || false;

  // rlo 5/28/2022 - Need this method because the passed in pricing object never get updated.
  // showRateTypes was originally get called from PricingProductItem by passing pricing object properties.
  const hasRateTypes = (index: number) => {
    const pricingProduct: PricingProduct = getValues(
      `pricingProducts.${index}`
    );
    return showRateType(
      pricingProduct?.pricingType,
      pricingProduct?.pricingRateTypeData,
      pricingProduct?.formType
    );
  };

  const showRateType = React.useCallback(
    (
      pricingType: string,
      pricingRateTypeData: string | undefined,
      formType: string | undefined | null
    ): boolean => {
      if (
        pricingType === PricingType.Tax ||
        formType === JacketFormType.Commitment ||
        formType === JacketFormType.Guarantee
      ) {
        return false; // See bug #40562 & US 27292
      }

      const rateTypes: boolean =
        isIntegratedPricing &&
        pricingRateTypeData !== undefined &&
        pricingRateTypeData !== null &&
        pricingRateTypeData.length > 0;

      return rateTypes;
    },
    [isIntegratedPricing]
  );

  const getIsReissue = (
    pricingRateType: string | undefined,
    pricingRateTypeData: string | undefined
  ) => {
    const rateTypeData = pricingRateTypeData
      ? JSON.parse(pricingRateTypeData)
      : undefined;

    if (rateTypeData) {
      const result = rateTypeData.find(
        (rtd: { Alias: string; }) => rtd.Alias === pricingRateType
      );

      if (result !== undefined) {
        return result.IsReissue && result.IsReissue.toLowerCase() === "true";
      }
    }

    return false;
  };

  const showReissue = (
    pricingType: string,
    pricingRateType: string | undefined,
    pricingRateTypeData: string | undefined
  ): boolean => {
    if (pricingType !== PricingType.Product) return false;

    const rateTypeData = pricingRateTypeData
      ? JSON.parse(pricingRateTypeData)
      : undefined;

    if (rateTypeData) {
      const result = rateTypeData.find(
        (rtd: { Alias: string; }) => rtd.Alias === pricingRateType
      );

      if (result !== undefined) {
        return result.IsReissue && result.IsReissue.toLowerCase() === "true";
      }
    }

    return false;
  };

  const showTransCode = React.useCallback(
    (config: PricingConfig | undefined): boolean => {
      const display: boolean =
        (config?.configValue !== undefined || config?.configValue !== "") &&
        Number(config?.configValue) === 1;

      return display || false;
    },
    []
  );

  const showLiability = React.useCallback(
    (pricingType: string, productType: string): boolean => {
      return (
        pricingType === PricingType.Product &&
        productType === ProductType.Jacket
      );
    },
    []
  );

  const showLiabilityColumn = React.useCallback(
    (): boolean =>
      pricingProductsRef.current
        ? pricingProductsRef.current.some(({ pricingType, productType }) =>
          showLiability(pricingType, productType)
        )
        : false,
    [showLiability]
  );

  const displayRateTypeColumn = React.useCallback(
    (): boolean =>
      pricingProductsRef.current
        ? pricingProductsRef.current.some(
          ({ pricingType, pricingRateTypeData, formType }) =>
            showRateType(pricingType, pricingRateTypeData, formType)
        )
        : false,
    [showRateType]
  );

  const getColumnNameByConfigKey = (
    key: PricingConfigKey,
    defaultName: string
  ) => {
    const name = getPricingConfig(key)?.configValue;
    return name ? name : defaultName;
  };

  React.useEffect(() => {
    const transCodePricingConfig = getPricingConfig(PricingConfigKey.TransCode);
    const newColumns = {
      showLiabilityColumn: showLiabilityColumn(),
      showRateTypeColumn: displayRateTypeColumn(),
      showReissueColumn:  Boolean(getValues("pricing.showReissue")),
      showRiskRateColumn: Boolean(getValues("pricing.showRiskRate")),
      showTransCodeColumn: showTransCode(transCodePricingConfig),
    };

    if (isEqual(columns, newColumns)) return;

    setColumns(newColumns);
  }, [
    columns,
    displayRateTypeColumn,
    getPricingConfig,
    getValues,
    showLiabilityColumn,
    showTransCode,
  ]);

  return {
    columns,
    showLiability,
    hasRateTypes,
    showRateType,
    showReissue,
    getColumnNameByConfigKey,
    getIsReissue,
  };
}
