import SelectField from "controls/global/select-field";
import { getProductLocations } from "pages/file/utils/helper";
import React, {
  useEffect,
  useState,
} from "react";
import { AgencyLocation } from "utils/context/AgencyLocationsContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import { useProductAgenycLocations } from "utils/context/ProductAgencyLocationsContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

interface Props {
  productType: string;
  schema: string;
  name: string;
  label: string;
  disabled?: boolean;
  isRevising?: boolean;
  onChange?: () => void;
}

const ProductAgencyLocationField = ({
  productType,
  schema,
  name,
  label,
  disabled,
  isRevising = false,
  onChange,
  ...rest
}: Props) => {
  const [productAgencyLocations, setProductAgencyLocations] = useState<AgencyLocation[]>([]);
  const { setValue, getValues } = useFormWrapper();
  const { setProductLocationInfo, getCompanyStateLocation } = useCreateFile();
  const [{ agencyProductLocations },] = useProductAgenycLocations();
  const [state] = useFilePropertyState();

  const fileAgencyLocation = getValues("agencyLocation");
  const productLocationID = getValues(`${schema}.pricingDetail.locationID`);
  const orderStatusTypeCode = getValues(`${schema}.orderStatusTypeCode`);  
  const handleOnChange = (e: React.ChangeEvent<{}>, value: any) => {
    onChange && onChange();
  };

  // Get product locations based on file location, except the disable location
  // Product locations will include the existing issued/voided product's location
  useEffect(() => {            
      const productLocations = getProductLocations(agencyProductLocations, productLocationID, getValues(name), orderStatusTypeCode, getValues("isReadOnly"));      
      setProductAgencyLocations(productLocations);    
  }, [agencyProductLocations]);

  // Set product location and product location info
  useEffect(() => {
    const productLocationName = getValues(name);
    if (productAgencyLocations && productAgencyLocations.length > 0) {
      // default the product location to file's location when it's blank
      if (productLocationName) {
        // validate that the location is in productAgencyLocations 
        const foundLocation = productAgencyLocations?.find(
          (location) => location.value === productLocationName
        );
        if (!foundLocation) {
          setValue(name, "");
        }
      }
      else {
        // check if it's only one location default to that location
        // otherwise defaul to file's location
        if (productAgencyLocations.length === 1) {
          setValue(name, productAgencyLocations[0].value, { shouldValidate: true });
        }
        else if (fileAgencyLocation && fileAgencyLocation.name) {
          // validate that file's location is in productAgencyLocation
          const foundLocation = productAgencyLocations?.find(
            (location) => location.value === fileAgencyLocation.name
          );
          if (foundLocation) {
            setValue(name, fileAgencyLocation.name, { shouldValidate: true });
          }
        }
      }

    }
    // set product location info
    const location = getCompanyStateLocation(getValues(name));
    if (location) {
      setProductLocationInfo(productType, schema, location, isRevising, orderStatusTypeCode);
    }
  }, [getValues(name), productAgencyLocations, fileAgencyLocation, state, isRevising]);

  return (
      <SelectField
        name={name}
        label={label}
        options={productAgencyLocations}
        onChange={handleOnChange}
        disabled={disabled}
        {...rest}
      />
  );
};

export default ProductAgencyLocationField;
