import { StoreActionApi } from "react-sweet-state";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";

export interface TimeZone {
  Common_UTC_Offset: string;
  Current_UTC_Offset: string;
  Display_Name: string;
  Is_Currently_DST: number;
  Name: string;
}

export interface ProfileSettings {
  userID: string;
  userNumber: number;
  displayName: string;
  stewartAccessUsername: string;
  userTimeZone: string;
  timeZones: TimeZone[];
  saAccount: any;
  userProfile: UserProfile;
  hasError: boolean;
  firstName?: string;
  lastName?: string;
  userName?: string;
  userTypeCode?: string;
  userEmailAddress?: string;
  userPhone?: string;
  userAgencyCount: number;
  hasGlobalAccess?: number;
  isStaffUser?: number;
}

export interface UserProfile {
  defaultPropertyType?: string;
  defaultPropertyState?: string;
  useAddressSuggestion: string;
  skipAgencySelection?: string;
  defaultAgencyID?: string;
}

interface State {
  profileSettings: ProfileSettings;
  isLoading: boolean;
  isSaveDone: boolean;
  error: string | null;
}

type StoreApi = StoreActionApi<State>;

const setLoading =
  (isLoadingFlag?: boolean) =>
  ({ setState }: StoreApi) => {
    if (!isLoadingFlag) {
      isLoadingFlag = true;
    }
    setState({ isLoading: isLoadingFlag });
  };

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const setProfileData =
  (profile: any) =>
  ({ setState }: StoreApi) => {
    if (!profile || profile.length < 2) {
      setState({
        isLoading: false,
        profileSettings: {
          userID: "",
          userNumber: 0,
          displayName: "",
          stewartAccessUsername: "",
          userTimeZone: "",
          userTypeCode: profile.UserTypeCode,
          timeZones: [],
          saAccount: null,
          userProfile: {
            defaultPropertyType: "",
            defaultPropertyState: "",
            useAddressSuggestion: "On",
            skipAgencySelection: "Yes",
            defaultAgencyID: "",
          },
          userAgencyCount: 0,
          userEmailAddress: "",
          userPhone: "",
          hasError: false,
          hasGlobalAccess: 0,
          isStaffUser: 0,
        },
        isSaveDone: true,
      });
    } else {
      const saAccounts = profile[0] ? profile[0].ExternalSystemAccounts : profile.stewartAccessObj;
      const stewartAccessObj = profile[0]
        ? saAccounts &&
          saAccounts.find(
            (account: any) => account.ExternalSystemTypeCode === "STEWARTACCESS" && account.IsPrimary === 1,
          )
        : profile.stewartAccessObj;

      const timeZones = profile[1] ? profile[1] : profile.timeZones;
      let values;

      if (profile[0]) {
        values = {
          userID: profile[0].UserID,
          userNumber: profile[0].UserNumber,
          displayName: profile[0].DisplayName,
          firstName: profile[0].FirstName,
          lastName: profile[0].LastName,
          userName: profile[0].UserName,
          userTypeCode: profile[0].UserTypeCode,
          stewartAccessUsername: stewartAccessObj && stewartAccessObj.ExternalUserName,
          userTimeZone: profile[0].UserTimeZone,
          userEmailAddress: profile[0].EmailAddress,
          userPhone: profile[0].Phone,
          timeZones: timeZones,
          saAccount: stewartAccessObj,
          hasError: profile[0].HasError,
          userProfile: {
            defaultPropertyType: profile[0].UserProfile.DefaultPropertyType ?? "RESIDENTIAL",
            defaultPropertyState: profile[0].UserProfile.DefaultPropertyState ?? "",
            useAddressSuggestion: profile[0].UserProfile.UseAddressSuggestion === 1 ? "On" : "Off",
            skipAgencySelection: profile[0].UserProfile.SkipAgencySelection === 1 ? "Yes" : "No",
            defaultAgencyID: profile[0].UserProfile.DefaultAgencyID ?? "",
          },
          userAgencyCount: profile[0].UserAgencyCount,
          hasGlobalAccess: profile[0].HasGlobalAccess ?? 0,
          isStaffUser: profile[0].IsStaffUser ?? 0,
        };
      } else {
        values = {
          userID: profile.userID,
          userNumber: profile.userNumber,
          displayName: profile.displayName,
          stewartAccessUsername: stewartAccessObj && stewartAccessObj.ExternalUserName,
          userTimeZone: profile.userTimeZone,
          timeZones: timeZones,
          userEmailAddress: profile.EmailAddress,
          userPhone: profile.Phone,
          saAccount: stewartAccessObj,
          hasError: false,
          userProfile: {
            defaultPropertyType: profile.userProfile.defaultPropertyType,
            defaultPropertyState: profile.userProfile.defaultPropertyState,
            useAddressSuggestion: profile.userProfile.useAddressSuggestion,
            skipAgencySelection: profile.userProfile.skipAgencySelection,
            defaultAgencyID: profile.UserProfile.defaultAgencyID,
          },
          userAgencyCount: profile.UserAgencyCount,
          hasGlobalAccess: profile.HasGlobalAccess ?? 0,
          isStaffUser: profile.IsStaffUser ?? 0,
        };
      }

      setState({
        isLoading: false,
        isSaveDone: true,
        profileSettings: values,
      });
    }
  };

const Store = createStore<State, any>({
  initialState: {
    profileSettings: {
      userID: "",
      userNumber: 0,
      displayName: "",
      stewartAccessUsername: "",
      userTimeZone: "",
      timeZones: [],
      saAccount: null,
      userProfile: {
        defaultPropertyType: "",
        defaultPropertyState: "",
        useAddressSuggestion: "On",
        skipAgencySelection: "Yes",
        defaultAgencyID: "",
      },
      hasError: false,
      userEmailAddress: "",
      userPhone: "",
      userAgencyCount: 0,
      hasGlobalAccess: 0,
      isStaffUser: 0,
    },
    isLoading: false,
    isSaveDone: false,
    error: null,
  },
  actions: {
    getProfileSettings:
      () =>
      async ({ dispatch }: StoreApi) => {
        try {
          dispatch(setLoading());
          //const [{UserNumber, DisplayName, UserTimeZone, ExternalSystemAccounts}, timeZones]: any = await Promise.all([axiosSecuredInstance.get("/user/getUser").then(resp=>resp.data), axiosSecuredInstance.get("/lookup/gettimezonelist").then(resp=>resp.data)]).finally(() => {
          const result: any = await Promise.all([
            axiosSecuredInstance.get("/user/getUser").then((resp) => resp.data),
            axiosSecuredInstance.get("/lookup/gettimezonelist").then((resp) => resp.data),
          ]).finally(() => {
            setLoading(false);
          });

          dispatch(setProfileData(result));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
    onSubmit:
      (values: any) =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isSaveDone: false });
        const primaryAccount = values.ExternalSystemAccounts.find((acct: any) => acct.IsPrimary);
        if (primaryAccount) values.saAccount = primaryAccount;

        return axiosSecuredInstance
          .post("/user/saveuser", {
            UserNumber: values.userNumber,
            DisplayName: values.displayName,
            UserTimeZone: values.userTimeZone,
            ExternalSystemAccounts: values.ExternalSystemAccounts,
            UserProfile: {
              DefaultPropertyType: values.userProfile.defaultPropertyType,
              DefaultPropertyState: values.userProfile.defaultPropertyState,
              UseAddressSuggestion: values.userProfile.useAddressSuggestion === "On" ? 1 : 0,
              SkipAgencySelection: values.userProfile.skipAgencySelection === "Yes" ? 1 : 0,
              DefaultAgencyID: values.userProfile.defaultAgencyID,
            },
          })
          .then(() => {})
          .finally(() => {})
          .catch((ex) => {
            console.log(ex);
            dispatch(
              setProfileData({
                userNumber: values.userNumber,
                displayName: values.displayName,
                stewartAccessObj: values.saAccount,
                userTimeZone: values.userTimeZone,
                timeZones: values.timeZones,
                userTypeCode: values.UserTypeCode,
                userEmailAddress: values.EmailAddress,
                userPhone: values.Phone,
                hasGlobalAccess: values.hasGlobalAccess,
                isStaffUser: values.isStaffUser,
                userProfile: {
                  defaultPropertyType: values.userProfile.defaultPropertyType,
                  defaultPropertyState: values.userProfile.defaultPropertyState,
                  useAddressSuggestion: values.userProfile.useAddressSuggestion,
                  skipAgencySelection: values.userProfile.skipAgencySelection,
                  defaultAgencyID: values.userProfile.defaultAgencyID,
                },
                hasError: true,
              }),
            );
          });
      },
    validate: (values: any) => async () => {
      const errors: any = {};

      if (values.displayName.trim() === "") {
        errors.displayName = "Enter the display name";
      }

      if (values.stewartAccessUsername.trim() === "") {
        errors.stewartAccessUsername = "Enter the stewart access display name";
      }

      if (values.userTimeZone.trim() === "") {
        errors.userTimeZone = "Select user timezone";
      }

      return errors;
    },
  },
  name: "userProfileSettings",
});

const hook = createHook(Store);
export const useProfileSettingsCache = () => {
  return hook();
};
