import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { DateType } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi
}
  from "react-sweet-state";
import {
  DateTypeCode,
  PageType,
} from "utils/data/enum";
import { AxiosRequestConfig, CancelTokenSource } from "axios";
import { SignatureImageFont } from "entities/UIModel/SignatureImageFont";
import { SignatureImageFormat } from "entities/UIModel/SignatureImageFormat";
import { SignatureImageSource } from "entities/UIModel/SignatureImageSource";
import { SignatureLevel } from "entities/UIModel/SignatureLevel";
import { ReportOptionType } from "entities/UIModel/ReportOptionType";

export interface KeyValuePair {
  key: string;
  value: string;
}

interface State {
  error: string | null;
  isLoading: boolean;
  isStatesLoading: boolean;

  //#region Keep in useLookup
  partyRoleTypes: Array<SelectFieldOption>;
  propertyTypes: Array<SelectFieldOption>;
  suffixes: Array<SelectFieldOption>;
  transactionTypes: Array<SelectFieldOption>;
  fileStatusTypes: Array<SelectFieldOption>;
  overrideReasonTypes: Array<SelectFieldOption>;
  signatureImageFontTypes: Array<SignatureImageFont>;
  signatureImageFormatTypes: Array<SignatureImageFormat>;
  signatureImageSourceTypes: Array<SignatureImageSource>;
  signatureLevelTypes: Array<SignatureLevel>;
  states: Array<SelectFieldOption>;
  reportOptionTypes: Array<ReportOptionType>;
  //#endregion

  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const setDefaultState =
  (params: Record<string, any>) =>
    ({ setState }: StoreApi) => {
      setState({ ...defaultState, ...params });
    };

const hasAny = (data: Array<any>) => !data || data.length <= 0;

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        dispatch(setError(error));
      }
    };

const setTransactionTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState({ transactionTypes: [] });
        return;
      }

      const mapPropTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.transactionTypeName,
          value: type.transactionTypeCode,
          selected: propTypes.length === 1,
        };
      });

      setState({ transactionTypes: mapPropTypes });
    };

const setPropertyTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState({ propertyTypes: [] });
        return;
      }

      const mapPropTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.propertyTypeName,
          value: type.propertyTypeCode,
          selected: propTypes.length === 1,
        };
      });

      setState({ propertyTypes: mapPropTypes });
    };

const setFileStatusTypes =
  (data: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(data)) {
        setState({ fileStatusTypes: [] });
        return;
      }

      const mappedFileStatusTypes: Array<SelectFieldOption> = data.map((type) => {
        return {
          text: type.fileStatusTypeName,
          value: type.fileStatusTypeCode,
          selected: data.length === 1,
        };
      });

      setState({ fileStatusTypes: mappedFileStatusTypes });
    };

const setPartyRoleTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState({ isLoading: false, partyRoleTypes: [] });
        return;
      }

      const mapPropTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.partyRoleTypeName,
          value: type.partyRoleTypeCode,
          selected: propTypes.length === 1,
        };
      });

      setState({ isLoading: false, partyRoleTypes: mapPropTypes });
    };

const setSuffixes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState({ isLoading: false, suffixes: [] });
        return;
      }

      const mapPropTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.suffixTypeName,
          value: type.suffixTypeCode,
          selected: propTypes.length === 1,
        };
      });

      setState({ isLoading: false, suffixes: mapPropTypes });
    };

const setStates =
  (data: Array<any>) =>
    ({ setState }: StoreApi) => {
      setState({
        states: data.map((state) => ({
          text: state.StateAbbr,
          value: state.StateCode,
        })),
      });
    };

const setDateTypes =
  (data: any, page: PageType) =>
    ({ setState }: StoreApi) => {
      if (!data || data.length === 0) {
        return [];
      }

      const dateTypes: DateType[] = data.map((x: any) => {
        let selected: boolean;
        switch (page) {
          case PageType.MyFilesPage:
            selected = x.dateTypeCode === DateTypeCode.Created;
            break;
          case PageType.ReportPayPage:
            selected = x.dateTypeCode === DateTypeCode.IssueDate;
            break;
          default:
            selected = false;
            break;
        }
        return {
          code: x.dateTypeCode,
          name: x.dateTypeName,
          selected: selected || data.length === 1
        };
      });
      return dateTypes;

    };

const setOverrideReasonTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState({ isLoading: false, overrideReasonTypes: [] });
        return;
      }

      const mapPropTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.overrideReasonTypeName,
          value: type.overrideReasonTypeCode,
          selected: false,
          desc: type.overrideReasonTypeDescription,
          disabled: type?.disabled ?? false,
        };
      });

      setState({ isLoading: false, overrideReasonTypes: mapPropTypes });
    };

const setSignatureImageFontTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      const mapped: SignatureImageFont[] = !propTypes ? [] :
        propTypes.map((type) => (
          {
            code: type.signatureImageFontTypeCode,
            name: type.signatureImageFontTypeName,
          }
        ));

      setState({ isLoading: false, signatureImageFontTypes: mapped });
    };

const setSignatureImageFormatTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      const mapped: SignatureImageFormat[] = !propTypes ? [] :
        propTypes.map((type) => (
          {
            code: type.signatureImageFormatTypeCode,
            name: type.signatureImageFormatTypeName,
          }
        ));

      setState({ isLoading: false, signatureImageFormatTypes: mapped });
    };

const setSignatureImageSourceTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      const mapped: SignatureImageSource[] = !propTypes ? [] :
        propTypes.map((type) => (
          {
            code: type.signatureImageSourceTypeCode,
            name: type.signatureImageSourceTypeName,
          }
        ));

      setState({ isLoading: false, signatureImageSourceTypes: mapped });
    };

const setSignatureLevelTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      const mapped: SignatureLevel[] = !propTypes ? [] :
        propTypes.map((type) => {
          const lowerName = String(type.signatureLevelTypeName).toLowerCase();
          return {
            code: type.signatureLevelTypeCode,
            name: type.signatureLevelTypeName,
            isUserOnly: lowerName.includes("user"),
            isAgencyWide: lowerName.includes("agency")
          };
        });

      setState({ isLoading: false, signatureLevelTypes: mapped });
    };

const setReportOptionTypes =
  (reportOptionTypes: any[]) =>
    ({ setState }: StoreApi) => {
      const mapped: ReportOptionType[] = !reportOptionTypes ? [] :
        reportOptionTypes.map((type) => {
          return {
            reportOptionTypeCode: type.reportOptionTypeCode,
            reportOptionTypeName: type.reportOptionTypeName,
          };
        });
      mapped.sort((a, b) => a.reportOptionTypeName.localeCompare(b.reportOptionTypeName));

      setState({ isLoading: false, reportOptionTypes: mapped });
    };

const defaultState: State = {
  error: null,
  isLoading: false,
  isStatesLoading: false,
  partyRoleTypes: [],
  propertyTypes: [],
  fileStatusTypes: [],
  suffixes: [],
  transactionTypes: [],
  states: [],
  overrideReasonTypes: [],
  signatureImageFontTypes: [],
  signatureImageFormatTypes: [],
  signatureImageSourceTypes: [],
  signatureLevelTypes: [],
  reportOptionTypes: [],
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getPropertyTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          return dispatch(
            getData("/lookup/getlist/propertytype", setPropertyTypes)
          );
        },
    getFileStatusTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          return dispatch(
            getData(
              "/lookup/getlist/FileStatusType?subType=UserSelected",
              setFileStatusTypes
            )
          );
        },
    getStates: (agencyId?: string) => async ({ dispatch, getState, setState }: StoreApi) => {
      const url = "/lookup/getstatelist?countryCodeNum=840";
      if (getState().isStatesLoading) return;

      setState({ isStatesLoading: true });
      await dispatch(getData(url, setStates));
      setState({ isStatesLoading: false });
    },
    getPartyRoleTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          return dispatch(
            getData("/lookup/getlist/PartyRoleType", setPartyRoleTypes)
          );
        },
    getSuffixTypes:
      () =>
        async ({ dispatch, getState }: StoreApi) => {
          const { suffixes } = getState();
          if (suffixes.length > 0) return suffixes;
          return dispatch(getData("/lookup/getlist/SuffixType", setSuffixes));
        },
    getTransactionTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          return dispatch(
            getData("/lookup/getlist/TransactionType", setTransactionTypes)
          );
        },
    getDateTypes:
      (page: PageType.MyFilesPage | PageType.PendingPayPage | PageType.ReportPayPage) =>
        async ({ dispatch }: StoreApi) => {
          try {
            const { data } = await axiosSecuredInstance.get<DateType[]>("/lookup/getList/datetype");
            let filteredData = data;
            if (page === PageType.MyFilesPage) {
              filteredData = data.filter(item => item.subType == "SearchDate");
            }
            return dispatch(setDateTypes(filteredData, page));
          } catch (error: any) {
            dispatch(setError(error));
          }
        }
    ,
    getOverrideReasonTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getList/overriderreasontype",
                setOverrideReasonTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    updateOverrideReasonTypes:
    (propTypes: Array<any>) =>
      async ({ dispatch }: StoreApi) => {
        try {
          dispatch(setOverrideReasonTypes(propTypes));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
    getSignatureImageFontTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getList/signatureimagefonttype",
                setSignatureImageFontTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    getSignatureImageFormatTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getList/signatureimageformatyype",
                setSignatureImageFormatTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    getSignatureImageSourceTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getList/signatureimagesourcetype",
                setSignatureImageSourceTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    getSignatureLevelTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getList/signatureleveltype",
                setSignatureLevelTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    getReportOptionTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          try {
            return dispatch(
              getData(
                "/lookup/getlist/reportoptiontype",
                setReportOptionTypes
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    resetLookups:
      (params: Record<string, any>) =>
        ({ dispatch }: StoreApi) => {
          dispatch(setDefaultState(params));
        },
  },
  name: "lookup",
});

const hook = createHook(Store);
export const useLookup = () => {
  return hook();
};
