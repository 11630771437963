import React, {
  useEffect,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  styled,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ActionButton from "controls/global/action-button";
import TextInputField from "controls/global/text-input-field";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import VoidReasonsField from "./VoidReasonsField";
import { ProductType } from "utils/data/enum";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import CheckIcon from '@mui/icons-material/Check';
import { padding } from "theme/defaultStyle";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type Props = {
  productType: string;
  coveredParty: string;
  serialNumber: string;
  effectiveDate: string;
  productIndex: number;
  isOpen: boolean;
  onSubmit: any;
  onCancel: any;
  submitButtonDisabled: boolean;
  defaultVoidReason: string;
};

const gridStyle = {
  paddingTop: padding.small2,
  paddingRight: padding.small2,
};

const voidGridStyle = {
  paddingTop: padding.xlarge,
  paddingRight: "25%",
  paddingLeft: "25%",
};

const CustomDialogContent = styled(DialogContent)({
  overflowY: "visible",
});

const VoidLetterForm = ({
  productType,
  coveredParty,
  serialNumber,
  effectiveDate,
  productIndex,
  isOpen,
  onSubmit,
  onCancel,
  submitButtonDisabled,
  defaultVoidReason,
}: Props) => {
  const classes = useDialogStyles({ type: "INFO" });
  const [{ voidReasons }, { getVoidReasons }] = useVoidReasons();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    getVoidReasons(ProductType.Cpl, defaultVoidReason);
  }, [defaultVoidReason]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullScreen={fullScreen}
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        Void {productType}
        <DialogTitleCloseButton onClick={() => onCancel(false)}/>
      </DialogTitle>
      <CustomDialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Covered Party"
              name={coveredParty}
              disabled={true}
              defaultValue={coveredParty}
            />
          </Grid>

          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Serial Number"
              name={serialNumber}
              disabled={true}
              defaultValue={serialNumber}
            />
          </Grid>

          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Effective Date"
              name={effectiveDate}
              disabled={true}
              defaultValue={effectiveDate}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={voidGridStyle}>
            <VoidReasonsField
              voidReasons={voidReasons[ProductType.Cpl]}
              productIndex={productIndex}
              productType={productType}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActionRoot,
        }}
      >
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default VoidLetterForm;
