import React from "react";

import * as UIModel from "entities/UIModel";
import { ProductAction } from "utils/data/enum";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  getCorrelationId,
  getErrorMessageByProductAction,
} from "pages/file/utils/helper";

function useCPLErrorHandler() {
  const [errorMessage, setErrorMessage] = React.useState<string | React.ReactNode>("");
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const [openErrorDialog, setOpenErrorDialog] = React.useState<boolean>(false);
  const { getValues } = useFormWrapper();

  const cpls: Array<UIModel.CPL> = getValues("cpls");

  const getErrorMessage = (
    currentActionResult: UIModel.ActionResult,
    currentProductIntegrationKey: string | undefined,
    currentProductAction: string | undefined
  ) => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.error &&
      currentActionResult.error.response &&
      currentActionResult.error.response.status !== 200
    ) {
      return currentActionResult.error.response.data &&
        currentActionResult.error.response.data.errorMessage
        ? currentActionResult.error.response.data.errorMessage
        : currentActionResult.error.response.statusText;
    }

    // Check for File level error
    const scfile = currentActionResult.scfile as UIModel.SCFile;
    if (!scfile) return null;
    if (scfile.errorMessage) {
      return getErrorMessageByProductAction(currentProductAction as string, scfile.errorMessage);
    }

    // Check for Product level error
    const cpl = scfile.cpls?.find(
      (cpl) =>
        cpl.integrationKey === currentProductIntegrationKey &&
        cpl.errorMessage &&
        (currentProductAction !== ProductAction.Void ||
          (currentProductAction === ProductAction.Void &&
            cpl.errorMessage?.trimStart().startsWith(`${ProductAction.Void}:`)))
    );
    if (cpl && cpl.errorMessage) {
      return getErrorMessageByProductAction(currentProductAction as string, cpl.errorMessage);
    }

    return null;
  };

  const getActionCorrelationHeaders = (
    currentActionResult: UIModel.ActionResult,
    currentProductIntegrationKey: string | undefined,
    currentProductAction: string | undefined
  ):any => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.error &&
      currentActionResult.error.response &&
      currentActionResult.error.response.status !== 200
    ) {
      return currentActionResult.error.response.headers;
    }

    // Check for File level error
    const scfile = currentActionResult.scfile as UIModel.SCFile;
    if (!scfile) return null;
    if (scfile.errorMessage) {
      return currentActionResult.headers;
    }

    // Check for Product level error
    const cpl = scfile.cpls?.find(
      (cpl) =>
        cpl.integrationKey === currentProductIntegrationKey &&
        cpl.errorMessage &&
        (currentProductAction !== ProductAction.Void ||
          (currentProductAction === ProductAction.Void &&
            cpl.errorMessage?.trimStart().startsWith(`${ProductAction.Void}:`)))
    );
    if (cpl && cpl.errorMessage) {
      return currentActionResult.headers;
    }

    return null;

  }

  const handleError = React.useCallback(
    (
      currentActionResult: UIModel.ActionResult,
      currentProductIntegrationKey: string | undefined,
      currentProductAction: string | undefined
    ) => {
      const errorMessage = getErrorMessage(
        currentActionResult,
        currentProductIntegrationKey,
        currentProductAction
      );
      if (!errorMessage) return;
      setErrorMessage(errorMessage);

      const correlationHeaders = getActionCorrelationHeaders(
        currentActionResult,
        currentProductIntegrationKey,
        currentProductAction
      )
      if (correlationHeaders) {
        const correlationId = getCorrelationId(correlationHeaders);
        setCorrelationId(correlationId);
      }
      
      setOpenErrorDialog(true);
    },
    []
  );

  const checkError = React.useCallback(
    (
      currentActionResult: UIModel.ActionResult,
      currentProductIntegrationKey: string | undefined,
      currentProductAction: string | undefined
    ) => {
      // check bad request error and file level error
      if (currentActionResult.error || currentActionResult.scfile?.errorMessage)
        return true;

      // check product level error
      const scfile = currentActionResult.scfile as UIModel.SCFile;
      if (!scfile) return false;

      const cplError = scfile.cpls?.find(
        (cpl) =>
          cpl.integrationKey === currentProductIntegrationKey &&
          cpl.errorMessage &&
          (currentProductAction !== ProductAction.Void ||
            (currentProductAction === ProductAction.Void &&
              cpl.errorMessage
                ?.trimStart()
                .startsWith(`${ProductAction.Void}:`)))
      );

      if (cplError) return true;

      return false;
    },
    []
  );

  const clearError = React.useCallback(() => {
    setErrorMessage("");
    cpls?.forEach((cpl: UIModel.CPL) => {
      cpl.errorMessage = "";
    });
  }, []);

  return {
    errorMessage,
    correlationId,
    setErrorMessage,
    setCorrelationId,
    openErrorDialog,
    checkError,
    handleError,
    setOpenErrorDialog,
    clearError,
  };
}

export default useCPLErrorHandler;
