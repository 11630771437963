import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ActionButton from "controls/global/action-button/ActionButton";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import {
  StepKeys,
  useWizardProvider
} from "pages/signatures/providers/manage-signature/WizardProvider";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { useSignatures } from "utils/context/SignaturesContext";

type Props = {
  activeStep: StepKeys;
};

export default function NextButton({
  activeStep,
}: Props) {
  const [disabled, setDisabled] = useState(true);
  const [{ cachedSignature },] = useSignatures();
  const nextRef = useRef<HTMLElement | null>(null);
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();
  const { dispatch: wizardDispatch } = useWizardProvider();
  const {
    state: { signature, mode, imageSource, isNameValid, isCheckingName },
  } = useSignatureProvider();

  const handleNext = () => {
    if (mode === "EDIT") {
      if (!signature.file && cachedSignature)
        signatureDispatch({
          type: "FILE_UPDATE",
          signatureFile: cachedSignature.signatureImageData,
          fontFamilyID: cachedSignature.signatureImageFontTypeCode,
          imageType: cachedSignature.signatureImageFormatTypeCode,
        });
    }
    wizardDispatch({ type: "NEXT", });
  };

  const listener = (event: KeyboardEvent) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      nextRef.current?.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return function cleanupListener() {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    let enable = false;

    if (activeStep === "MENU") {
      enable = !!imageSource;
    }
    else if (activeStep === "TYPE") {
      if (isNameValid && !isCheckingName && signature.name) {
        if (imageSource.toLowerCase().includes("type")) {
          enable = signature.fontFamilyID != "";
        }
        else {
          enable = !!signature.file;
        }
      }
    }

    setDisabled(!enable);
  }, [
    activeStep,
    imageSource,
    isNameValid,
    isCheckingName,
    signature.name,
    signature.file,
    signature.fontFamilyID,
  ])

  if (activeStep === "CONFIG") return null;
  return (
    <ActionButton
      variant="outlined"
      color="primary"
      onClick={handleNext}
      disabled={disabled}
      buttonRef={nextRef}
      endIcon={<ArrowForwardIcon />}
    >
      Next
    </ActionButton>
  );
}
