import TableCell from "@material-ui/core/TableCell";
import {
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  borderSize,
  colors,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.grey08,
  fontWeight: fontWeight.bold1,
  maxWidth: "170px",
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
});

const useStyles = makeStyles({
  totalDueUnderline: {
    borderBottom: `${borderSize.xlarge} solid ${colors.maroon01} !important`,
    display: "inline-block",
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  totalDueNoUnderline: {
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  normalPadding: {
    paddingBottom: padding.xsmall2,
    paddingTop: padding.small2,
  },
  bigPadding: {
    paddingBottom: padding.large1,
    paddingTop: padding.large1,
  },
});

type Props = {
  totalDueTotal: string;
  hasIssuedProducts: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
};

export default function TotalDueTotal({ totalDueTotal, hasIssuedProducts, hasFileLockedWithUpdateablePricingItem }: Props) {
  const classes = useStyles();

  const footerCellClassname = hasFileLockedWithUpdateablePricingItem
    ? classes.normalPadding
    : classes.bigPadding;

  const totalClassname = hasFileLockedWithUpdateablePricingItem
    ? classes.totalDueNoUnderline
    : classes.totalDueUnderline;


  return (
    <StyledTableFooterCell
      align="right"
      className={footerCellClassname}
    >
      {hasIssuedProducts &&
        <TooltipCell
          className={totalClassname}
          title={totalDueTotal}
        />
      }
    </StyledTableFooterCell>
  );
}
