import React from "react";

import MultiSelectComponent from "controls/global/multi-select-field/MultiSelectComponent";

interface Props {
  name: string;
  states: any[];
  labelPrefix?: string;
  isDisabled?: boolean;
}

const ReportStateField = ({ name, states, isDisabled = false }: Props) => {
  return (
    <>
      <MultiSelectComponent
        name={name}
        label={"State"}
        options={states}
        disabled={isDisabled}
      />
    </>
  );
};

export default ReportStateField;
