import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import CloseIcon from "@material-ui/icons/Close";
import { fontSize } from "theme/defaultStyle";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& div.MuiPaper-root": {
      width: "80%",
      height: "80%",
      maxWidth: "none",
      overflow: "hidden",
    },
  },
  appBarParentRoot: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontSize: fontSize.base,
    fontWeight: 600,
    lineHeight: 1.6,
    marginBottom: "0px",
    overflowWrap: "anywhere",
    margin: "0 35px",
  },
  pdfContainer: {
    width: "100%",
    height: "100%",
    display: "block",
    position: "relative",
  },
  diwWrapper: {
    width: "100%",
    height: "100%",
  },
}));

type EmbedViewerProps = {};

export type EmbedViewerRefType = {
  open: (title: string, src: string) => Promise<void>;
};

const EmbedViewer = (props: EmbedViewerProps, ref: Ref<EmbedViewerRefType>) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [titleSource, setTitleSource] = useState<string>();
  const [source, setSource] = useState<string>();
  const [blobUrlSource, setBlobUrlSource] = useState<string>();

  useImperativeHandle(ref, () => ({
    open: async (title, src) => {
      setTitleSource(title);
      setSource(src);
    },
  }));

  useEffect(() => {
    if (source) {
      fetch(source)
        .then((res) => res.blob())
        .then(URL.createObjectURL)
        .then((blobUrl) => {
          setBlobUrlSource(blobUrl);
          setOpen(true);
        });
    }
  }, [source]);

  function onClose(): void {
    setOpen(false);
    setTitleSource("");
    setSource("");
  }

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      classes={{ root: classes.dialogRoot }}
    >  <div className={classes.appBarParentRoot}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title}>
              {titleSource}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.pdfContainer}>
        <div className={classes.diwWrapper}>
          <embed src={`${blobUrlSource}#toolbar=0`} width="100%" height="100%" />
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef<EmbedViewerRefType, EmbedViewerProps>(EmbedViewer);
