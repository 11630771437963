import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";
import { AxiosRequestConfig, CancelTokenSource } from "axios";

export interface AgencyLocation extends SelectFieldOption {
  id: string;
  disabled?: boolean;
  locationUniqueID?: string;
}

interface State {
  agencyLocations: Array<AgencyLocation>;
  fileAgencyLocations: Array<AgencyLocation>;
  isRetrievingAgencyLocations: boolean;

  error: string | null;
  isLoading: boolean;
  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const hasAny = (data: Array<any>) => !data || data.length <= 0;

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        dispatch(setError(error));
      }
    };

const setAgencyLocations =
  (locations: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(locations)) {
        setState({ isRetrievingAgencyLocations: false, agencyLocations: [] });
        return;
      }

      var newAgencyLocations = locations.map((location) => ({
        text: location.locationDisplayName,
        value: location.locationDisplayName,
        id: location.companyID,
        disabled: location.isDisabled || false,
        locationUniqueID: location.locationUniqueID,
      }));

      setState({
        agencyLocations: newAgencyLocations,
        isRetrievingAgencyLocations: false,
      });
    };

const setFileAgencyLocations =
  (locations: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(locations)) {
        setState({ isRetrievingAgencyLocations: false, fileAgencyLocations: [] });
        return;
      }
      const fileLocations = locations.map((location) => ({
        text: location.locationDisplayName,
        value: location.locationDisplayName,
        id: location.companyID,
        disabled: location.isDisabled || false,
        locationUniqueID: location.locationUniqueID,
      }));
      setState({
        fileAgencyLocations: fileLocations,
        agencyLocations: fileLocations,
        isRetrievingAgencyLocations: false,
      });
    };

const defaultState: State = {
  agencyLocations: [],
  fileAgencyLocations: [],
  error: null,
  isLoading: false,
  isRetrievingAgencyLocations: false,
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getAgencyLocations:
      (
        agencyId: string,
        stateCode?: string,
        isRequestingForProduct?: boolean
      ) =>
        async ({ dispatch, setState }: StoreApi) => {
          if (!agencyId) return;
          let url = `/Company/GetLocationsByCompanyID?companyId=${agencyId}`;

          if (stateCode) {
            url = `/Company/GetLocationsByCompanyState?companyId=${agencyId}&state=${stateCode}`;
          }

          setState({ isRetrievingAgencyLocations: true });
          if (isRequestingForProduct)
            return dispatch(getData(url, setAgencyLocations));
          else {
            return dispatch(getData(url, setFileAgencyLocations));
          }
        },
    updateAgencyLocations:
      (locations: Array<any>) =>
        async ({ dispatch, setState }: StoreApi) => {
          dispatch(setFileAgencyLocations(locations));
        },
  },
  name: "lookup",
});

const hook = createHook(Store);
const useAgencyLocations = () => {
  return hook();
};
const useAgencyLocationsActions = createHook(Store, {
  selector: null,
});

export { useAgencyLocations, useAgencyLocationsActions };
