import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ActionButton from "controls/global/action-button";
import { useDialog } from "utils/context/DialogContext";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles";
import CheckIcon from '@mui/icons-material/Check';
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

type Props = {
  onCancel?: () => void;
  onSubmit?: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
};

const StewartDialog = ({
  onCancel,
  onSubmit,
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
}: Props) => {
  const classes = useDialogStyles({ type: 'INFO', width: 425 });
  const [{ dialogOpen, title, content, dialogActions }, { onModalSubmit, onModalCancel }] =
    useDialog();

  if (!dialogOpen) return null;
  return (
    <Dialog open={dialogOpen} fullWidth maxWidth="md" classes={{ root: classes.dialogRoot }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitleRoot }}>
        {title}
        <DialogTitleCloseButton onClick={() => {
          onModalCancel();
          onCancel?.();
        }}/>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {content}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot }}>
        {dialogActions ? dialogActions : (
          <>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={() => {
                onModalSubmit();
                onSubmit?.();
              }}
              startIcon={<CheckIcon />}
            >
              {submitButtonText}
            </ActionButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default StewartDialog;
