import {
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  styled,
} from '@material-ui/core';
import { axiosSecuredInstance } from "configurations/axiosConfig";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  gaps,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { SiteErrorType } from "utils/data/enum";
import {
  startNotifying,
  stopNotifying,
} from "utils/services/ContextProgressNotifierService";
import { LayoutBodyContext } from './LayoutBodyContext';
import Footer from "./components/Footer";
import Header from "./components/Header";
import { SiteErrorMessage } from "./components/SiteErrorMessage";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useLocation } from "react-router-dom";
import {
  isHelpCenterPage,
  isManageSignaturesPage,
  isProfileSettingsPage,
} from "utils/routes/pathValidator";

type Props = {
  children?: any; // ReactElement<string> | undefined; // any; //ReactNode;
};

const RootContainer = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  gap: gaps.large1,
  justifyContent: "space-between",
  padding: `0 ${padding.xlarge21}`,
  position: "sticky",
  width: "100%",
  zIndex: zIndex.high0,
  "&.show-global-access": {
    gap: gaps.zero,
  },
  [props.theme.breakpoints.down("sm")]: {
    padding: `0 ${padding.xsmall1}`,
  },
  "& > :first-child": {
    flexGrow: 0,
    flexShrink: 0,
    position: "sticky",
    top: 0,
  },
  "& > :last-child": {
    flex: "auto",
    paddingBottom: padding.small2,
  },
}));

const BodyContainer = styled("div")({
  // NOTE: Currently this element has the necessary flex-grow / flex-shrink rules set by the parent for clarity.
  display: "flex",
  flexDirection: "column",
  justifyContent: "inherit",
  gap: gaps.zero,
});

enum LoginStatus {
  LoggingIn,
  Valid,
  Invalid,
  TimedOut,
  OtherError
}

const PrivatePage = ({ children }: Props) => {
  const [isValidLogin, setIsValidLogin] = useState(false);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>();
  const [pageContent, setPageContent] = useState<JSX.Element | undefined>();
  const [loginProgressPercent, setLoginProgressPercent] = useState<number | undefined>();
  const bodyRef = useRef<HTMLDivElement>(null);
  const saveFileRef = useRef<HTMLButtonElement>();
  const saveFileChangeRef = useRef<HTMLButtonElement>();
  const isAuthenticated = useIsAuthenticated();
  const [{ globalAccessLoaded }] = useGlobalAccess();
  const { pathname } = useLocation();
  const [{ showChangeAgency }] = useGlobalAccess();

  const showGAAgency = (!(isManageSignaturesPage(pathname) ||
    isProfileSettingsPage(pathname) ||
    isHelpCenterPage(pathname))) &&
    showChangeAgency;

  const processLogin = useMemo(
    () => async (callback: (status: LoginStatus) => void) => {
      startNotifying("login", setLoginProgressPercent);
      //Call DB from here to ensure that the user is valid.
      let status = LoginStatus.LoggingIn;
      callback(status);

      try {
        const response = await axiosSecuredInstance.get("/user/processLogin",
          {
            timeout: 15000,
            timeoutErrorMessage: "TIMEOUT",
          });

        const data = response.data;
        if (data && data.LoginResult && data.LoginResult === 1) {
          status = LoginStatus.Valid;
        }
        else {
          status = LoginStatus.Invalid;
        }
      } catch (error: any) {
        if (String(error?.message).includes("TIMEOUT")) {
          status = LoginStatus.TimedOut;
        }
        else {
          status = LoginStatus.OtherError;
        }
      }
      finally {
        stopNotifying("login", setLoginProgressPercent);
      }

      callback(status);
    }, []);

  const getErrorType = (): SiteErrorType | undefined => {
    let errorType: SiteErrorType | undefined = undefined;

    if (loginStatus === LoginStatus.Invalid) {
      errorType = SiteErrorType.BadAccount;
    }
    else if (loginStatus === LoginStatus.OtherError || loginStatus === LoginStatus.TimedOut) {
      errorType = SiteErrorType.NetworkError;
    }

    return errorType;
  };

  useEffect(() => {
    const tryLogin = async () => {
      if (isAuthenticated)
        processLogin((status: LoginStatus) => {
          setLoginStatus(status);
        });
      else {
        setLoginStatus(LoginStatus.Invalid);
      }
    };

    tryLogin();
  }, [isAuthenticated, processLogin]);

  useEffect(() => {
    let controlToShow = undefined;
    if (loginStatus === LoginStatus.Valid && globalAccessLoaded) {
      controlToShow = React.cloneElement(children, {
        saveFileRef,
        saveFileChangeRef,
      });
    }
    setPageContent(controlToShow);
    setIsValidLogin(loginStatus === LoginStatus.Valid);
  }, [loginStatus, children, globalAccessLoaded]);

  return (
    <RootContainer
      id="root-container"
      className={showGAAgency ? "show-global-access" : ""}
    >
      <Header {...{ isValidLogin, saveFileRef, saveFileChangeRef }} />
      <LayoutBodyContext.Provider value={bodyRef}>
        <BodyContainer
          ref={bodyRef}
          id="body-container"
        >
          {pageContent
            ? <div>{pageContent}</div>
            : <SiteErrorMessage
              errorType={getErrorType()}
              progressPercent={loginProgressPercent}
            />
          }
          <Footer />
        </BodyContainer>
      </LayoutBodyContext.Provider>
    </RootContainer >
  );
};


export default PrivatePage;
