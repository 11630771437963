import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  getComparator,
  Order,
  stableSort,
} from "pages/myFiles/utils/filesTable";
import React, {
  createRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  defaultStyle,
  fontSize,
  gaps,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { useFileExport } from "utils/context/FileExportContext";
import CircularProgressWithLabel from "../circular-progress-with-label";
import StewartCheckbox from "../stewart-checkbox";
import StewartTableBodyEmpty from "./StewartTableBodyEmpty";
import StewartTableHeader from "./StewartTableHeader";

export interface IRowIdentifier {
  uniqueIdentifier: string;
}

export interface SelectionConfig<T> {
  enabled?: boolean;
  renderRowDetails?: (row: T) => ReactNode;
  selectedRows?: string[];
  isRowSelected?: (row: T) => boolean;
  onToggleRow?: (row: T) => void;
  onToggleAllRows?: () => void;
}

export interface DetailsViewConfig<T> {
  enabled?: boolean;
  renderRowDetails?: (row: T) => ReactNode;
}

export interface StewartTableColumn<T extends IRowIdentifier> {
  field: keyof T;
  name: string;
  width?: number;
  minWidth?: number;
  sticky?: boolean;
  left?: number;
  sortable?: boolean;
  resizable?: boolean;
  draggable?: boolean;
  position?: number;
  actionComponent?: (param?: T[]) => React.ReactNode;
  valueGetter?: (param: T) => React.ReactNode;
  classes?: string;
}

type Props<T extends IRowIdentifier> = {
  cols: StewartTableColumn<T>[];
  rows: T[];
  page: number;
  noRowsMessage: string[];
  hiddenColumns?: (keyof T)[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  padding?: string | number;
  footerComponent?: ReactElement;
  showActionRow?: boolean;
  useDbPagingSorting?: boolean;
  detailsViewConfig?: DetailsViewConfig<T>;
  selectionConfig?: SelectionConfig<T>;
  order: Order;
  orderBy?: keyof T;
  onRequestSort?: (property: keyof T) => void;
  onColumnResize: (property: keyof T, newWidth: number) => void;
  onColumnsModified?: (columns: StewartTableColumn<T>[]) => void;
};

type TableProps = {
  showActionRow?: boolean;
};

const CircularProgressContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: zIndex.positiveMax,
  "& .MuiCircularProgress-circleDeterminate": {
    transition: "stroke-dashoffset 0ms",
  }
});

const StyledPaper = styled(Box)({
  padding: padding.zero,
  "&.MuiTableContainer-root": {
    width: "calc(100% + 2px)", // Increase for table border width to prevent horizontal scrollbar.
    "& table:not(.details-table)": {
      width: "calc(100% - 2px)", // Reduce for table border width added above..
    },
  },
});

const StyledTable = styled(Table)(({ showActionRow }: TableProps) => ({
  borderCollapse: "separate",
  borderSpacing: 0,
  tableLayout: "fixed",
  "& .MuiTableCell-root": {
    fontSize: fontSize.large,
    "&.row-header-cell": {
      backgroundColor: colors.white,
      left: 0,
      paddingLeft: padding.large1,
      paddingRight: padding.zero,
      position: "sticky",
      width: "98px",
      zIndex: zIndex.positiveMin,
      "& .row-header-items": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: gaps.small1,
        "& .MuiSvgIcon-root": {
          color: colors.blue01,
          cursor: "pointer",
        },
        " & .MuiButtonBase-root": {
          padding: padding.small1_5,
        },
      },
    },
  },
  "& thead .MuiTableRow-root.header-actions": {
    height: "56px",
    "& th": {
      borderTop: defaultStyle.table.border,
      paddingRight: padding.small1,
      "&:first-child": {
        borderLeft: defaultStyle.table.border,
        borderTopLeftRadius: borderRadius.small,
      },
      "&:last-child": {
        borderRight: defaultStyle.table.border,
        borderTopRightRadius: borderRadius.small,
      },
    },
  },
  "& tbody .MuiTableRow-root": {
    "&:last-of-type": {
      "& td": {
        borderBottom: defaultStyle.table.border,
      },
      "& td:first-of-type": {
        borderBottomLeftRadius: borderRadius.small,
      },
      "& td:last-of-type": {
        borderBottomRightRadius: borderRadius.small,
      },
    },
  },
  "& [data-pos='sticky']": {
    backgroundColor: colors.white,
    left: 0,
    position: "sticky",
    zIndex: zIndex.positiveMin,
  },
  ...(!showActionRow && {
    "& tbody .MuiTableRow-root:first-of-type": {
      "& td": {
        borderTop: defaultStyle.table.border,
      },
      "& td:first-of-type": {
        borderTopLeftRadius: borderRadius.small,
      },
      "& td:last-of-type": {
        borderTopRightRadius: borderRadius.small,
      },
    }
  }),
  "& tr.detailsViewContainer-row": {
    "& td.detailsViewContainer-cell": {
      borderBottom: defaultStyle.table.border,
      borderLeft: defaultStyle.table.border,
      borderRight: defaultStyle.table.border,
      borderTop: "none",
      margin: margin.zero,
      padding: padding.zero,
      width: "100%",
    },
  },
}));

const StyledTableHead = styled(TableHead)({
  userSelect: "none",
  "& .header-labels": {
    height: "36px",
    "& th": {
      border: "none",
      paddingBottom: padding.zero,
      paddingLeft: padding.small1,
      paddingRight: padding.zero,
      paddingTop: padding.zero,
    },
  },
});

const StyledTableRow = styled(TableRow)({
  backgroundColor: colors.white,
  borderWidth: `${borderSize.xsmall} ${borderSize.xsmall} 0 ${borderSize.xsmall}`,
  "& .MuiTableCell-root": {
    paddingBottom: padding.small1,
    paddingLeft: padding.medium1,
    paddingRight: padding.medium1,
    paddingTop: padding.small1,
    "& > *": {
      lineHeight: "150%",
    },
    "& .MuiButtonBase-root": {
      padding: padding.zero,
    }
  },
  "&:hover": {
    backgroundColor: colors.grey10,
  },
  "& td:first-of-type": {
    borderLeft: defaultStyle.table.border,
    backgroundColor: colors.white,
  },
  "& td:last-of-type": {
    borderRight: defaultStyle.table.border,
  }, "&.expanded > td": {
    borderBottom: "none",
  },
});

export default function StewartTable<T extends IRowIdentifier>({
  cols,
  rows,
  rowsPerPage,
  page,
  noRowsMessage,
  hiddenColumns,
  loadingProgressPercent = undefined,
  order,
  orderBy,
  footerComponent,
  showActionRow = true,
  useDbPagingSorting = false,
  detailsViewConfig,
  selectionConfig,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
}: Props<T>) {
  const [columns, setColumns] = useState<StewartTableColumn<T>[]>([]);
  const [, { setFileData }] = useFileExport();
  const messages = ["Loading results..."];
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const tableRef = createRef<HTMLTableElement>();

  const handleRequestSort = (property: keyof T) => {
    if (onRequestSort) {
      onRequestSort(property);
    }
  };

  const handleColumnsModified = (sortedColumns: StewartTableColumn<T>[]) => {
    if (sortedColumns.length === cols.length) {
      setColumns(sortedColumns);
      onColumnsModified?.(sortedColumns);
    }
    else {
      const currentCols = new Set(sortedColumns.map((c) => c.field));
      const difference = cols.filter((c) => !currentCols.has(c.field));
      const acc = [...sortedColumns, ...difference];
      setColumns(acc);
      onColumnsModified?.(acc);
    }
  };

  const handleToggleSelectedAll = () => {
    selectionConfig?.onToggleAllRows?.();
  };

  const handleToggleSelected = (row: T) => {
    if (selectionConfig) {
      selectionConfig?.onToggleRow?.(row);
    }
  };

  const handleToggleShowDetailsAll = () => {
    const updatedRows = expandedRows.length
      ? []
      : rows.map(f => f.uniqueIdentifier);

    setExpandedRows(updatedRows);
  };

  const handleToggleShowDetails = (row: T) => {
    const updatedRows = expandedRows.includes(row.uniqueIdentifier)
      ? expandedRows.filter(i => i !== row.uniqueIdentifier)
      : [...expandedRows, row.uniqueIdentifier];

    setExpandedRows(updatedRows);
  };

  const isRowSelected = (row: T) => {
    return selectionConfig?.isRowSelected?.(row) === true;
  };

  const renderRowHeader = (row: T) => {
    if (!selectionConfig?.enabled && !detailsViewConfig?.enabled) {
      return null;
    }
    else {
      return (
        <TableCell
          className="row-header-cell"
          key="row-header-cell"
        >
          <div className="row-header-items">
            {detailsViewConfig?.enabled &&
              expandedRows.includes(row.uniqueIdentifier)
              ? <ExpandLess onClick={() => handleToggleShowDetails(row)} />
              : <ExpandMore onClick={() => handleToggleShowDetails(row)} />
            }
            {selectionConfig?.enabled &&
              <StewartCheckbox
                {...{ checked: isRowSelected(row) }}
                onChange={() => handleToggleSelected(row)}
              />
            }
          </div>
        </TableCell >
      );
    }
  };

  const shouldRenderDetails = (row: T) => {
    return detailsViewConfig?.enabled
      && detailsViewConfig?.renderRowDetails
      && expandedRows.includes(row.uniqueIdentifier);
  };

  const sortedRows = orderBy
    ? stableSort<T>(rows, getComparator(order, orderBy))
    : rows;

  const paginatedRows = useDbPagingSorting ? rows : sortedRows.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  const visibleCols =
    hiddenColumns && hiddenColumns.length > 0
      ? columns.filter((c) => !hiddenColumns.includes(c.field))
      : columns;

  const totalColSpan = visibleCols.length
    + (selectionConfig?.enabled || detailsViewConfig?.enabled ? 1 : 0);

  useEffect(() => {
    const updatedColumns = cols.map(column => {
      return {
        ...column,
        hidden: !!hiddenColumns?.includes(column.field),
      };
    });
    setFileData(updatedColumns, rows, orderBy, order);
  }, [columns, rows, order, orderBy]);

  useEffect(() => {
    setExpandedRows([]);
  }, [rows]);

  useEffect(() => {
    setColumns(cols);
  }, [cols]);

  if (visibleCols.length === 0) return null;

  return (
    <TableContainer component={StyledPaper}>
      <StyledTable
        id="filesTable"
        ref={tableRef}
        key={[showActionRow].toString()} // NOTE: This is to work around a material ui 4 bug (fixed in MUI5) with style not always updating when props change.
        showActionRow={showActionRow}
      >
        <StyledTableHead>
          <StewartTableHeader
            cols={visibleCols}
            paginatedRows={paginatedRows}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onColumnResize={onColumnResize}
            onColumnsModified={handleColumnsModified}
            showActionRow={showActionRow}
            useDetailsView={detailsViewConfig?.enabled}
            detailsViewButtonState={expandedRows.length ? "collapse" : "expand"}
            selectionConfig={selectionConfig}
            onDetailsViewToggle={handleToggleShowDetailsAll}
            onSelectAllToggle={handleToggleSelectedAll}
            tableRef={tableRef}
          />
        </StyledTableHead>
        {loadingProgressPercent !== undefined && rows.length === 0 ? (
          <StewartTableBodyEmpty
            messages={messages}
            colSpan={totalColSpan}
          />
        ) : rows.length === 0 ? (
          <StewartTableBodyEmpty
            messages={noRowsMessage}
            colSpan={totalColSpan}
          />
        ) : (
          <>
            <TableBody>
              {loadingProgressPercent &&
                <CircularProgressContainer>
                  <CircularProgressWithLabel value={loadingProgressPercent} />
                </CircularProgressContainer>
              }
              {paginatedRows.map((row) => (
                <>
                  <StyledTableRow
                    key={row.uniqueIdentifier}
                    className={expandedRows.includes(row.uniqueIdentifier) ? "expanded" : ""}
                  >
                    {/* {renderExpandCollapse(row)}
                    {renderSelected(row)} */}
                    {renderRowHeader(row)}
                    {visibleCols.map(
                      ({ valueGetter, field, sticky, left }) => (
                        <TableCell
                          key={String(field)}
                          data-pos={sticky && "sticky"}
                          style={{
                            left: sticky && left ? left : undefined,
                          }}
                        >
                          {(valueGetter ? valueGetter(row) : row[field]) as ReactNode}
                        </TableCell>
                      )
                    )}
                  </StyledTableRow>
                  {shouldRenderDetails(row) &&
                    <tr className="detailsViewContainer-row">
                      <td
                        colSpan={totalColSpan}
                        className="detailsViewContainer-cell"
                      >
                        {detailsViewConfig?.renderRowDetails?.(row)}
                      </td>
                    </tr>
                  }
                </>
              ))}
            </TableBody>
            {footerComponent && <TableFooter>{footerComponent}</TableFooter>}
          </>
        )}
      </StyledTable>
    </TableContainer>
  );
}
