import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  borderRadius,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { businessSolutionData } from "../data/businessSolutionsData";
import ActionButton from "controls/global/action-button/ActionButton";
import useTitle from "utils/custom-hooks/useTitle";

const StyledBoxContainer = styled(Box)({
  backgroundColor: colors.white,
  borderRadius: borderRadius.xlarge,
  fontFamily: fontFamily.primary,
  padding: padding.medium2,
  paddingLeft: padding.xlarge1,
  paddingBottom: padding.xlarge2,
  "& a": {
    color: colors.blue01,
    textDecoration: "none",
  },
});

const PrimaryHeading = styled(Typography)({
  color: colors.maroon02,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge6,
  fontWeight: fontWeight.bold2,
  lineHeight: lineHeight.xxlarge,
  padding: padding.xlarge,
  paddingLeft: padding.small1,
  textAlign: "left",
});

const ButtonStyles = styled(ActionButton)({
  paddingLeft: padding.small1,
});

const SecondaryHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
});

const ThirdHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
});

const ListContainer = styled("ul")({
  paddingLeft: padding.zero,
});

const ListItem = styled("li")({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "disc",
  marginBottom: margin.small2,
  marginLeft: margin.xlarge2,
});

const AdditionalListItem = styled("li")({
  display: "inline-block",
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "none",
  marginLeft: margin.small2,
  padding: padding.zero,
  verticalAlign: "top",
  width: "33.33%",
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: fontWeight.bold2,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const handleClick = (link: any) => {
  if (link) {
    try {
      const url = new URL(link);
      window.open(url.href, "_blank");
    } catch (error) {
      console.error("Invalid link:", link);
    }
  }
};

const handleClickCart = () => {
  window.open("https://www.securemailencryption.com/Portal/Cart", "_blank");
};

const StewartPreferredBusinessSolution = () => {
  const { item } = useParams();

  const selectedItem = businessSolutionData.find((obj) => obj.id === item);
  useTitle(selectedItem?.id || "Stewart Preferred");

  if (!selectedItem) {
    return <div>Item not found</div>;
  }

  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <StyledBoxContainer>
      <PrimaryHeading
        variant="h3"
        dangerouslySetInnerHTML={renderHTML(selectedItem.heading1)}
      />
      <SecondaryHeading
        dangerouslySetInnerHTML={renderHTML(selectedItem.heading2)}
      />
      {selectedItem.heading3 &&
        selectedItem.heading3.map((item, index) => (
          <ThirdHeading
            key={index}
            dangerouslySetInnerHTML={renderHTML(item)}
          />
        ))}
      <ListContainer>
        {selectedItem?.items?.map((item, i) => (
          <React.Fragment key={i}>
            {typeof item === "string" ? (
              item
            ) : (
              <>
                <SecondaryHeading
                  variant="h6"
                  dangerouslySetInnerHTML={renderHTML(item.title)}
                />
                {item?.description?.map((item: any, index: any) => (
                  <ListItem
                    key={index}
                    dangerouslySetInnerHTML={renderHTML(item)}
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </ListContainer>
      {selectedItem?.tableInfo && (
        <TableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                {selectedItem?.tableInfo?.columns?.map((column, index) => (
                  <BoldTableCell key={index}>{column} </BoldTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedItem.tableInfo.tiers?.map((item, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell>{item.courseName}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.discountedRate}</TableCell>
                  <TableCell>{item.memberPrice}</TableCell>
                  <TableCell>{item.SDiscountedRate}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
      {selectedItem?.addtionalInfo &&
        selectedItem?.addtionalInfo?.data.map((item, i) => (
          <ThirdHeading
            key={i}
            dangerouslySetInnerHTML={renderHTML(item)}
          />
        ))}
      {selectedItem?.additionalFooter && (
        <ListContainer>
          {selectedItem?.additionalFooter.map((item, i) => (
            <React.Fragment key={i}>
              {typeof item === "string" ? (
                item
              ) : (
                <>
                  <SecondaryHeading
                    variant="h6"
                    dangerouslySetInnerHTML={renderHTML(item.tittle)}
                  />
                  {item?.description?.map((item, index) =>
                    item == "Add To Cart" ? (
                      <ButtonStyles>
                        <ActionButton
                          color="primary"
                          variant="outlined"
                          onClick={handleClickCart}
                        >
                          {item}
                        </ActionButton>
                      </ButtonStyles>
                    ) : (
                      <AdditionalListItem
                        key={index}
                        dangerouslySetInnerHTML={renderHTML(item)}
                      />
                    ),
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </ListContainer>
      )}
      {typeof selectedItem?.buttons == "object" && (
        <ButtonStyles>
          <ActionButton
            color="primary"
            variant="outlined"
            onClick={() => handleClick(selectedItem?.buttons?.link)}
          >
            {selectedItem.buttons.text}
          </ActionButton>
        </ButtonStyles>
      )}
    </StyledBoxContainer>
  );
};

export default StewartPreferredBusinessSolution;
