import {
  IconButton,
  styled,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@mui/icons-material";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { Signature } from "entities/UIModel/Signature";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  iconSize,
  letterSpacing,
  padding,
} from "theme/defaultStyle";
import { useSignatures } from "utils/context/SignaturesContext";

interface Props {
  records: Signature[];
}

type DeleteDialog = {
  open: boolean;
  signatureId?: number;
  message?: string;
};

const NO_SIGNATURES_MESSAGE =
  "There are currently no signatures configured.";
const EDIT_DEFAULTED_SIGNATURES_MESSAGE =
  "This signature is defaulted by one or more users. Changing the configuration and/or signature image will affect other users.";
const DELETE_DEFAULTED_SIGNATURES_MESSAGE =
  "This signature is defaulted by one or more users. Are you sure you want to delete this signature?";
const DELETE_NON_DEFAULTED_SIGNATURES_MESSAGE =
  "Are you sure you want to delete this signature?";

const StyledTableBody = styled(TableBody)({
  "& tr.selected-row": {
    backgroundColor: colors.grey10,
  },
  "& tr td": {
    borderTop: defaultStyle.table.border,
    borderBottom: "none",
  },
  "& tr:first-child td": {
    borderTop: defaultStyle.table.border,
  },
  "& tr:last-child td": {
    borderBottom: defaultStyle.table.border,
  },
  "& tr td:first-child": {
    borderLeft: defaultStyle.table.border,
  },
  "& tr td:last-child": {
    borderRight: defaultStyle.table.border,
  },
  "& tr:first-child td:first-child": {
    borderTopLeftRadius: borderRadius.small,
  },
  "& tr:first-child td:last-child": {
    borderTopRightRadius: borderRadius.small,
  },
  "& tr:last-child td:first-child": {
    borderBottomLeftRadius: borderRadius.small,
  },
  "& tr:last-child td:last-child": {
    borderBottomRightRadius: borderRadius.small,
  },
});

const StyledTableCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  letterSpacing: letterSpacing.medium1,
  paddingTop: padding.small,
  paddingBottom: padding.small,
  paddingLeft: padding.medium1,
  paddingRight: padding.zero,
});

const StyledFileCell = styled(TableCell)({
  paddingBottom: padding.small,
  paddingLeft: padding.medium1,
  paddingRight: padding.medium1,
  paddingTop: padding.small,
  textAlign: "left",
  "& img": {
    height: "45px",
    maxWidth: "250px",
    objectFit: "contain",
  },
});

const EmptyCell = styled(StyledTableCell)({
  color: colors.grey13,
});

const ButtonTableCell = styled(TableCell)({
  paddingBottom: padding.medium1,
  paddingLeft: padding.zero,
  paddingRight: padding.zero,
  paddingTop: padding.medium1,
  textAlign: "center",
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    cursor: "pointer",
    fontSize: iconSize.medium,
  },
});

const SignaturesTableBody = ({ records }: Props) => {
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [editingSignature, setEditingSignature] = useState<Signature | null>(null);
  const [showEditWarningDialog, setShowEditWarningDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialog>({ open: false });
  const [disableConfirmationDialogYes, setDisableConfirmationDialogYes] = useState(false);
  const [{ isLoading }, { getSignature, deleteSignature }] = useSignatures();
  const { state: signatureState } = useSignatureProvider();
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();

  const handleRequestEdit = async (signature: Signature) => {
    setSelectedRowId(signature.signatureImageID);
    if (signature.isDefaulted) {
      setDisableConfirmationDialogYes(false);
      setShowEditWarningDialog(true);
    }
    else {
      handleConfirmEdit(signature.signatureImageID);
    }
  };

  const handleConfirmEdit = async (id?: number) => {
    setDisableConfirmationDialogYes(true);
    setShowEditWarningDialog(false);
    id = id ? id : selectedRowId;
    if (id) {
      // Open dialog before getting signature to give immediate feedback 
      // and have waiting in the dialog instead of on the page.
      signatureDispatch({
        type: "OPEN",
        mode: "EDIT",
      });

      const signature = await getSignature(id);
      setEditingSignature(signature);
    }
  };

  const handleCancelEdit = () => {
    setSelectedRowId(0);
    setShowEditWarningDialog(false);
  };

  const handleDelete = (signature: Signature) => {
    if (deleteDialog.open) return;

    setDisableConfirmationDialogYes(false);
    setSelectedRowId(signature.signatureImageID);
    setDeleteDialog({
      open: true,
      signatureId: signature.signatureImageID,
      message: signature.isDefaulted
        ? DELETE_DEFAULTED_SIGNATURES_MESSAGE
        : DELETE_NON_DEFAULTED_SIGNATURES_MESSAGE,
    });
  };

  const handleDeleteOk = async () => {
    if (!deleteDialog.signatureId)
      throw new Error("Delete Signature: Missing SignatureId.");

    setDisableConfirmationDialogYes(true);
    await deleteSignature(deleteDialog.signatureId);
    setSelectedRowId(0);
    setDeleteDialog({ open: false });
  };

  const handleDeleteCancel = () => {
    setSelectedRowId(0);
    setDeleteDialog({ open: false });
  };

  const getRowClassNames = (id: number) => {
    return selectedRowId === id ? "selected-row" : "";
  };

  useEffect(() => {
    if (editingSignature) {
      if (signatureState.open) {
        signatureDispatch({
          type: "OPEN",
          mode: "EDIT",
          signatureID: editingSignature.signatureImageID,
          signatureName: editingSignature.signatureImageName,
          signatureFile: editingSignature.signatureImageData,
          fontFamilyID: editingSignature.signatureImageFontTypeCode,
          imageType: editingSignature.signatureImageFormatTypeCode,
          imageSource: editingSignature.signatureImageSourceTypeCode,
        });
      }
      setEditingSignature(null);
    }
  }, [editingSignature]);

  useEffect(() => {
    if (!signatureState.open) {
      setSelectedRowId(0);
    }
  }, [signatureState]);

  if (isLoading && !records?.length)
    return (
      <StyledTableBody>
        <TableRow>
          <EmptyCell>Loading...</EmptyCell>
        </TableRow>
      </StyledTableBody>
    );

  if (!records || records.length === 0)
    return (
      <StyledTableBody>
        <TableRow>
          <EmptyCell colSpan={2}>{NO_SIGNATURES_MESSAGE}</EmptyCell>
        </TableRow>
      </StyledTableBody>
    );

  return (
    <>
      <StyledTableBody>
        {records.map((record: Signature) => (
          <TableRow
            key={record.signatureImageID}
            className={getRowClassNames(record.signatureImageID)}
          >
            <StyledTableCell>{record.signatureImageName}</StyledTableCell>
            <StyledFileCell>
              <img src={record.signatureImageData}></img>
            </StyledFileCell>
            <ButtonTableCell>
              <IconButton onClick={() => handleRequestEdit(record)}>
                <Edit />
              </IconButton>
            </ButtonTableCell>
            <ButtonTableCell>
              <IconButton onClick={() => handleDelete(record)}>
                <Delete />
              </IconButton>
            </ButtonTableCell>
          </TableRow>
        ))}
      </StyledTableBody>
      <ConfirmationDialog
        isOpen={deleteDialog.open}
        type={"ERROR"}
        confirmationMessage={deleteDialog.message}
        onYes={handleDeleteOk}
        onNo={handleDeleteCancel}
        disableYes={disableConfirmationDialogYes}
      />
      {showEditWarningDialog && (
        <ConfirmationDialog
          width={550}
          type={"ERROR"}
          confirmationMessage={EDIT_DEFAULTED_SIGNATURES_MESSAGE}
          isOpen={showEditWarningDialog}
          onYes={async () => await handleConfirmEdit()}
          onCancel={handleCancelEdit}
          disableYes={disableConfirmationDialogYes}
          singleActionButtonText={"Continue"}
          noActionButton={false}
          cancelActionButton={true}
        />
      )}
    </>
  );
};

export default SignaturesTableBody;
