import {
  styled,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  padding,
} from "theme/defaultStyle";

interface HeadCell {
  id: string;
  label: string;
  width: string;
}

const headCells: HeadCell[] = [
  { id: "Agency", label: "AGENCY", width: "237px" },
  { id: "State", label: "STATE", width: "58px" },
  { id: "AgencyLocation", label: "AGENCY LOCATION", width: "545px", },
  { id: "Level", label: "LEVEL", width: "94px" },
  { id: "SignatureName", label: "SIGNATURE NAME", width: "147px" },
  { id: "Edit", label: "EDIT", width: "46px" },
  { id: "Remove", label: "DELETE", width: "66px" },
];

const StyledTableRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    color: colors.grey08,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal2,
    letterSpacing: letterSpacing.medium2,
    lineHeight: lineHeight.large,
    paddingBottom: padding.zero,
    paddingLeft: padding.small1,
    paddingRight: padding.small1,
    paddingTop: padding.zero,
  },
});



function SignaturesTableHeader() {
  return (
    <>
      <colgroup>
        {headCells.map((cell) => (
          <col
            key={cell.id}
            width={cell.width}
          />
        ))}
      </colgroup>
      <TableHead>
        <StyledTableRow>
          {headCells.map((cell) => (
            <TableCell
              key={cell.id}
            >
              {cell.label}
            </TableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    </>
  );
}

export default SignaturesTableHeader;
