import React, 
{ 
  useEffect, 
  useState,
} from "react";
import { Box } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import Tooltip from "@mui/material/Tooltip";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  padding,  
} from "theme/defaultStyle";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import { 
  useLocation, 
  useNavigate, 
} from "react-router-dom";
import { 
  isCreateFilePage,
  isChangeAgencyPage,
} from "utils/routes/pathValidator";
import { useFiles } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface IProps {    
  selectedAgency?: string;    
  saveFileChangeRef?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "flex-end",
      backgroundColor: colors.grey01,
      borderRadius: borderRadius.small,
      color: colors.blue06,
      fontSize: fontSize.large,
      fontWeight: fontWeight.bold1,
      gap: gaps.xsmall2,
      margin: "auto",
      padding: padding.small1,
      textAlign: "right",
      width: "100%",
    },
    urlLink: {
      color: colors.blue01,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    urlLinkRemove: {
      color: colors.blue01,
      height: "20px !important",
      width: "20px !important",      
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  }),
);

const CHANGE_AGENCY_MESSAGE = "Are you sure you want to change agencies?";
const REMOVE_AGENCY_MESSAGE = "Are you sure you want to remove this agency?";
const CHOOSE_AGENCY = "Choose Agency";
const CHANGE_AGENCY = "ChangeAgency";
const REMOVE_AGENCY = "RemoveAgency";

const GlobalAccessAgency = ({    
  selectedAgency,  
  saveFileChangeRef,
}: IProps) => {
  const classes = useStyles();  
  const { redirect } = useNavRedirect();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, {setInvokingUrl}] = useFiles();  
  const [ { showChangeAgency }, { clearUserSelectedAgency, setShowChangeAgency }] = useGlobalAccess();
  const [ { profileSettings }] = useProfileSettingsCache();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [agencyLinkText, setAgencyLinkText] = useState(CHOOSE_AGENCY);
  const [confirmationMessage, setConfirmationMessage] = useState(CHANGE_AGENCY_MESSAGE);
  const [actionRequested, setActionRequested] = useState("");
  const changeAgencyPathname = "/";

  const handleDialogYes = async () => {
    setOpenConfirmationDialog(false);
    setInvokingUrl(pathname, "");  
    setActionRequested("");
    if(actionRequested === REMOVE_AGENCY) {
      clearUserSelectedAgency();
      if(pathname !== "/Home") {
        window.location.reload();
      }          
    }
    else {
      redirect(changeAgencyPathname, saveFileChangeRef);
    }
  };

  const handleDialogNo = () => {
    setInvokingUrl(pathname, "");  
    setOpenConfirmationDialog(false);    
    setActionRequested("");
  };
  
  const handleClick = (action: string) => {          
    setActionRequested(action);
    setInvokingUrl(pathname, action);
        
    if (isCreateFilePage(pathname)) {
        let redirectPath = changeAgencyPathname;
        if(action === REMOVE_AGENCY) {
          redirectPath = "/file";          
        }
        redirect(redirectPath, saveFileChangeRef);        
    }
    else if (pathname !== changeAgencyPathname && agencyLinkText !== CHOOSE_AGENCY) {            
      setConfirmationMessage(action === CHANGE_AGENCY ? CHANGE_AGENCY_MESSAGE : REMOVE_AGENCY_MESSAGE);      
      setOpenConfirmationDialog(true);  
    }
    else {
      navigate(changeAgencyPathname);
    }
  }

  useEffect(() => {
    if (selectedAgency) 
      setAgencyLinkText(CHANGE_AGENCY); 
    else 
      setAgencyLinkText(CHOOSE_AGENCY);
  }, [selectedAgency]);

  useEffect(() => {
    if(showChangeAgency &&
      profileSettings?.userProfile?.skipAgencySelection === "Yes") {
        setShowChangeAgency(false);
      }    
      
  }, [profileSettings, setShowChangeAgency, showChangeAgency, selectedAgency]);

  return (
    <>
      <Box className={classes.root}>        
        { selectedAgency }
        {"  "}
        { showChangeAgency && agencyLinkText === CHOOSE_AGENCY && !isChangeAgencyPage(pathname) &&
        <span 
          className={classes.urlLink} 
          onClick={() => handleClick(CHOOSE_AGENCY)}> 
          {agencyLinkText}
        </span>        
        }
        { agencyLinkText === CHANGE_AGENCY && !isChangeAgencyPage(pathname) &&
        <>
          <Tooltip title="Remove">
            <CloseIcon className={classes.urlLinkRemove} onClick={() => handleClick(REMOVE_AGENCY)}/>
          </Tooltip>
          <Tooltip title="Change">
            <SwapHorizOutlinedIcon className={classes.urlLinkRemove} onClick={() => handleClick(CHANGE_AGENCY)}/>
          </Tooltip>
        </>
        }
      </Box>         
      <ConfirmationDialog
        confirmationMessage={confirmationMessage}
        isOpen={openConfirmationDialog}
        onYes={handleDialogYes}
        onNo={handleDialogNo}
      />   
    </>
  );
};

export default GlobalAccessAgency;
