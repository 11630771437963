import {
  Icon,
  styled,
} from "@material-ui/core";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  fontSize,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import {
  DOMEventType,
  SessionStorageKeys,
} from "utils/data/enum";

type BannerProps = {
  onNavigate: (url: string) => void;
};

type MessageProps = {
  children: ReactNode;
};

const RELOAD_REQUIRED_MESSAGE = "This application has been updated and needs to be refreshed. Please save your work and then ";
const RELOAD_ACTION_MESSAGE = "click here to reload";

const Banner = styled("div")({
  alignItems: "center",
  backgroundImage: gradients.yellowGradient01,
  borderRadius: `0px 0px ${borderRadius.medium} ${borderRadius.medium}`,
  color: colors.white,
  display: "flex",
  flexDirection: "column",
  fontSize: `${fontSize.large}`,
  fontWeight: "bold",
  gap: gaps.small1,
  justifyContent: "center",
  marginTop: `-${borderRadius.medium}`,
  minHeight: "46px",
  padding: padding.medium1,
  paddingTop: padding.large2,
  "& .message-container": {
    display: "inline-flex",
    flexDirection: "row",
    gap: gaps.small1,
    "& .icon-container": {
      alignItems: "center",
      display: "flex",
      "& .MuiIcon-root": {
        fontSize: fontSize.xlarge0,
      },
    },
    "& .message": {
      textAlign: "center",
      "& .action": {
        color: colors.blue05,
        cursor: "pointer",
      },
    },
  },
});


const Message = ({ children }: MessageProps) => {
  return (
    <div className="message-container">
      <div className="icon-container">
        <Icon className="fa fa-exclamation-triangle" />
      </div>
      <div className="message">
        {children}
      </div>
    </div>
  );
};

const MessageBanner = ({ onNavigate }: BannerProps) => {
  const { featureConfig: { inMaintenanceMode, maintenanceMessage } } = useConfigContext();
  const [reloadRequired, setReloadRequired] = useState(false);

  const handleReload = () => {
    onNavigate("reload");
  };

  useEffect(() => {
    const checkReloadRequired = () => {
      const required = sessionStorage.getItem(SessionStorageKeys.APP_RELOAD_REQUIRED) === "true";
      setReloadRequired(required);
    };

    checkReloadRequired();
    window.addEventListener(DOMEventType.APP_RELOAD_REQUIRED, checkReloadRequired);

    return () => window.removeEventListener(DOMEventType.APP_RELOAD_REQUIRED, checkReloadRequired);
  }, []);

  return (
    <>
      {(inMaintenanceMode || reloadRequired) && (
        <Banner id="maintenance-banner">
          {inMaintenanceMode &&
            <Message>{maintenanceMessage}</Message>
          }
          {reloadRequired &&
            <Message>
              <>
                <span>{RELOAD_REQUIRED_MESSAGE}</span>
                <span
                  className="action"
                  onClick={handleReload}
                >
                  {RELOAD_ACTION_MESSAGE}
                </span>
              </>
            </Message>
          }
        </Banner>
      )}
    </>
  );
};

export default MessageBanner;
