import React, {
  useEffect,
  useState,
} from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from "theme/defaultStyle";

type Props = {
  names: string;
  maxLines?: number;
  separator?: string;
  joinSeparator?: string;
};

const MAX_LINES_DISPLAYED = 2;
const DEFAULT_SEPARATOR = "|";
const JOIN_SEPARATOR = "\n";

const useStyles = makeStyles({
  tooltip: {
    fontSize: fontSize.large,
    whiteSpace: "pre-line",
    "& .MuiTooltip-arrow": {
      color: "#fff",
    },
  },
  body: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "80%",
  },
});

function MultiLineCell({
  names,
  maxLines = MAX_LINES_DISPLAYED,
  separator = DEFAULT_SEPARATOR,
  joinSeparator = JOIN_SEPARATOR,
}: Props) {
  const classes = useStyles();
  const [nameList, setNameList] = useState<string[]>([]);
  const [bodyHtml, setBodyHtml] = useState<JSX.Element[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const list = names.split(separator);
    setNameList(list);
    setBodyHtml(
      list.slice(0, maxLines).map((n) => <Box key={n}>{n}</Box>)
    );
    setShowTooltip(list.length > maxLines);
  }, [names]);

  return showTooltip
    ? <Tooltip
      arrow
      title={
        <div className={classes.tooltip}>
          {nameList.join(joinSeparator)}
        </div>}
    >
      <Box className={classes.body}>
        {bodyHtml}
      </Box>
    </Tooltip>
    :
    <>
      {bodyHtml}
    </>;
}

export default MultiLineCell;
