/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from "@material-ui/core";
import { ZipCodeResponse } from "entities/UIModel";
import React, { useState } from "react";
import { useEsb } from "utils/context/EsbContext";
import { FullAddressProps } from "utils/interfaces/BaseAddress";
import AddressOneField from "./AddressOneField";
import AddressTwoField from "./AddressTwoField";
import CityField from "./CityField";
import CountyField from "./CountyField";
import StateField from "./StateField";
import ZipCodeField from "./ZipCodeField";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import AddressCheckboxes from "./AddressCheckboxes";
import { useWatch } from "react-hook-form";
import { usePricingActions } from "utils/context/PricingContext"

const FullAddress = ({
  schema,
  isPropertySection = true,
  showPropertyCheckbox = false,
  isDisabledAddress = false,
  isStatesDisabled = false,
  addressOneLabelPrefix = "",
  addressTwoLabelPrefix = "",
  cityLabelPrefix = "",
  stateLabelPrefix = "",
  zipCodeLabelPrefix = "",
  addressOneLabelSuffix = "",
  addressTwoLabelSuffix = "",
  cityLabelSuffix = "",
  stateLabelSuffix = "",
  zipCodeLabelSuffix = "",
  callbackIndex,
  showForeignAddressCheckbox = false,
  onForeignAddressChange,
  onBlur,
  setNotDefault,
  verifyAddressHandler
}: FullAddressProps) => {
  const { register, getValues, setValue } = useFormWrapper();
  const [, { getZipCode }] = useEsb();
  const [, { setPricingRecalculateRequired }] = usePricingActions();

  const stateFieldName = getNameString(`${schema}.state`);
  const cityFieldName = getNameString(`${schema}.city`);
  const zipCodeFieldName = getNameString(`${schema}.zipCode`);
  const addressOneFieldName = getNameString(`${schema}.addressOne`);
  const addressTwoFieldName = getNameString(`${schema}.addressTwo`);
  const countyFieldName = getNameString(`${schema}.county`);
  const taxCodeFieldName = getNameString(`${schema}.taxCode`);
  const isForeignAddressName = getNameString(`${schema}.isForeignAddress`);

  const [isForeignAddress] = useWatch({
    name: [isForeignAddressName],
  });

  const [currentZipcodeResults, setCurrentZipcodeResults] = useState<ZipCodeResponse>();

  const resetZipcodeData = () => {
    setCurrentZipcodeResults(undefined);
  };

  const getZipcodeData = async (zipCode: string, invokedExplicitly: boolean = false) => {
    if (isPropertySection && invokedExplicitly) {
      setValue(`${schema}.county`, {code: "",name: ""});
    }
    const zipCodeResult = await getZipCode(zipCode);
    if (!zipCodeResult) return;

    setCurrentZipcodeResults(zipCodeResult);
  };

  const handleAutocompleteSelected = async () => {
    const [zipCode] = getValues([zipCodeFieldName]);
    await getZipcodeData(zipCode);
  };

  const handleZipCodeChange = (value:string) => {
    setNotDefault && setNotDefault();
  }

  // This function to enable the calculate button for only TN when county field value change
  const enbaleCalculateButtonCheck = () => {
    if(setNotDefault) setNotDefault();
    const isPrimaryCounty = countyFieldName === 'properties.0.county';
    const isPrimaryStateTN = getValues('properties.0.state.abbr') === 'TN';
    if(isPrimaryStateTN && isPrimaryCounty) setPricingRecalculateRequired(true);
  }

  const mdAddressOne = isPropertySection ? 2 : 4;
  const mdAddressTwo = isPropertySection ? 2 : 3;

  return (
    <>
      <AddressCheckboxes
        schema={schema}
        showPropertyCheckbox={showPropertyCheckbox}
        showForeignAddressCheckbox={showForeignAddressCheckbox}
        callbackIndex={callbackIndex}
        isDisabledAddress={isDisabledAddress}
        onForeignAddressChange={onForeignAddressChange}
        onBlurHandler={onBlur}
      />
      {!isForeignAddress && (
        <>
          <Grid item xs={12} md={4} lg={mdAddressOne}>
            <AddressOneField
              setNotDefault={setNotDefault}
              onBlur={onBlur}
              name={addressOneFieldName}
              schema={schema}
              labelPrefix={addressOneLabelPrefix}
              isDisabled={isDisabledAddress}
              isPropertySection={isPropertySection}
              addressTwoFieldName={addressTwoFieldName}
              stateFieldName={stateFieldName}
              cityFieldName={cityFieldName}
              zipCodeFieldName={zipCodeFieldName}
              onAutocompleteSelected={handleAutocompleteSelected}
              verifyAddressHandler={verifyAddressHandler}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={mdAddressTwo}>
            <AddressTwoField
              name={addressTwoFieldName}
              label={`${addressTwoLabelPrefix} Address Line 2`}
              labelPrefix={addressTwoLabelPrefix}
              labelSuffix={addressTwoLabelSuffix}
              isDisabled={isDisabledAddress}
              onBlur={onBlur}
              onChange={setNotDefault}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <CityField
              name={cityFieldName}
              disabled={isDisabledAddress}
              onChange={setNotDefault}
              zipCodeResults={currentZipcodeResults}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={1}>
            <StateField
              name={stateFieldName}
              labelPrefix={stateLabelPrefix}
              isDisabled={isDisabledAddress || isStatesDisabled}
              isPropertySection={isPropertySection}
              autosetValue={false}
              zipCodeResults={currentZipcodeResults}
              resetZipcodeData={resetZipcodeData}
              setNoDefault={setNotDefault}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <ZipCodeField
              name={zipCodeFieldName}
              labelPrefix={zipCodeLabelPrefix}
              isDisabled={isDisabledAddress}
              isPropertySection={isPropertySection}
              getZipcodeData={getZipcodeData}
              onChange={handleZipCodeChange}
              onBlur={onBlur}
            />
          </Grid>

          {isPropertySection && (
            <Grid item xs={12} md={5} lg={3}>
              <CountyField
                name={countyFieldName}
                labelPrefix={cityLabelPrefix}
                isDisabled={isDisabledAddress}
                isPropertySection={isPropertySection}
                onChange={enbaleCalculateButtonCheck}
                zipCodeResults={currentZipcodeResults}
              />
            </Grid>
          )}
          <input type="hidden" {...register(`${taxCodeFieldName}` as const)} />
        </>
      )}
    </>
  );
};

export default FullAddress;
