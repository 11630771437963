export interface IColumn {
  field: string;
  name: string;
  width?: number;
  sticky?: boolean;
  sortable?: boolean;
  draggable?: boolean;
  hideable?: boolean;
  resizable?: boolean;
  hidden?: boolean;
  position?: number;
}

export class Column {
  public static isValid(column: any): boolean {
    if (!column) {
      return false;
    }

    return column.field && column.name;
  }
}
