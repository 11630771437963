import React from "react";
import { TableBody } from "@material-ui/core";
import { FileActivity } from "entities/UIModel";
import FileHistoryBodyRow from "./FileHistoryBodyRow";
import { Order, stableSort, getComparator } from "utils/sorting";

interface Props {
  order: Order;
  orderBy: keyof FileActivity;
  records: FileActivity[];
}

const FileHistoryBody = ({ order, orderBy, records }: Props) => {
  const noRecords = Boolean(records.length === 0);

  if (noRecords)
    return (
      <TableBody>
        <FileHistoryBodyRow />
      </TableBody>
    );

  return (
    <TableBody>
      {stableSort(records, getComparator(order, orderBy)).map((rec) => (
        <FileHistoryBodyRow key={rec.fileActivityID} record={rec} />
      ))}
    </TableBody>
  );
};

export default FileHistoryBody;
