import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type StoreApi = StoreActionApi<State>;
interface State {
  uniqueFileId: number;
  isFileUniqueLoading: boolean;
  isDuplicatedFile: boolean;
  prevFileNameValueState: number;
  showAlertDuplicatedFile: boolean;
  error: string;
}

const Store = createStore<State, any>({
  initialState: {
    uniqueFileId: 0,
    isFileUniqueLoading: false,
    isDuplicatedFile: false,
    prevFileNameValueState: Date.now(),
    showAlertDuplicatedFile: false,
    error: "",
  },
  actions: {
    getUniqueFileByAgencyClientFileId:
      (agencyId: string, clientFileId: string) =>
      async ({ setState }: StoreApi) => {
        try {
          setState({ isFileUniqueLoading: true, error: "" });

          const { data } = await axiosSecuredInstance.get(
            `/files/GetFileByAgencyClientFile/${agencyId}/${clientFileId}`
          );
          const fileId = data ? data.FileID : 0;

          setState({ isFileUniqueLoading: false, uniqueFileId: fileId });

          return fileId;
        } catch (error: any) {
          setState({ error });
        }
      },
    setIsDuplicatedFile:
      (value: boolean) =>
      ({ setState }: StoreApi) => {
        setState({ isDuplicatedFile: value });
      },
    setShowAlertDuplicatedFile:
      (openAlert: boolean) =>
      ({ setState }: StoreApi) => {
        setState({ showAlertDuplicatedFile: openAlert });
      },
    setPrevFileNameValueState:
      (timestamp: number) =>
      ({ setState }: StoreApi) => {
        setState({ prevFileNameValueState: timestamp });
      },
  },
  name: "file",
});

const hook = createHook(Store);
const useDuplicatedFileCheck = () => {
  return hook();
};
const useDuplicateFileCheckActions = createHook(Store, {
  selector: null,
});

export { useDuplicatedFileCheck, useDuplicateFileCheckActions };
