import {
  useLocation,
  useNavigate,
} from "react-router";
import { useNavigation } from "utils/context/NavigationContext";
import {
  isCreateFilePage,
  isProfileSettingsPage,
} from "utils/routes/pathValidator";

const useNavRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, { setNextUrl, navigateToNextUrl }] = useNavigation();

  const redirect = (url: string | null, saveFileChangeRef: any) => {
    setNextUrl(url);

    if (!isCreateFilePage(pathname) && !isProfileSettingsPage(pathname)) {
      navigateToNextUrl(navigate);
    }
    else {
      setTimeout(() => { //timeout is needed to give the React engine trime to update the navUrlHook
        if (saveFileChangeRef.current) {
          saveFileChangeRef.current.click();
        }
      }, 100);
    }
  };

  return { redirect };
};

export default useNavRedirect;
