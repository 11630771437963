import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from "clsx";
import ActionButton from "controls/global/action-button";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import React, {
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  lineHeight,
  padding,
} from "theme/defaultStyle";
import {
  DialogType,
  useDialogStyles,
} from "utils/custom-hooks/useDialogStyles";

const SUCCESSFUL_MESSAGE =
  "Your file(s) have been reported successfully.";
const FILE_UNSUCCESSFUL_TEXT =
  "You file could not be reported.";
const FILES_UNSUCCESSFUL_TEXT =
  "The following file(s) could not be reported:";
const CONTACT_TEXT =
  "Please try again or contact the Agency Support Center at (844) 835-1200.";

export type FileMapItem = {
  fileId: number;
  fileName: string;
};

type FilesReportedProps = {
  reportingResult: ReportingResult | undefined;
  fileMap?: FileMapItem[];
  isOpen: boolean;
  onContinue?: any;
  width?: number;
};

type ContentProps = {
  allSuccessful: boolean;
  failedFileMap: FileMapItem[] | undefined;
};

const StyledDialog = styled(Dialog)({
  "& .MuiDialogTitle-root .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.large3,
  },
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.small3,
  padding: `${padding.zero} ${padding.xlarge1}`,
  "& .successful": {
    textAlign: "center",
  },
  "& .unsuccessful": {
    textAlign: "left",
  },
  "& .inner-container": {
    display: "flex",
    flexDirection: "column",
    gap: gaps.small3,
    "& .file-list": {
      display: "flex",
      flexDirection: "column",
      gap: gaps.small1,
      maxHeight: "175px",
      maxWidth: "100%",
      overflow: "auto",
      whiteSpace: "nowrap",
      "&.list-border": {
        border: `${borderSize.xsmall} solid ${colors.grey09}`,
        borderRadius: borderRadius.small,
        paddingBottom: padding.small1,
        paddingLeft: padding.medium1,
        paddingRight: padding.medium1,
        paddingTop: padding.small1,
      },
    },
  },
});

const StyledLink = styled(Link)({
  color: colors.blue01,
  cursor: "pointer",
  fontSize: fontSize.large,
  fontWeight: fontWeight.bold2,
  lineHeight: lineHeight.large,
  textDecoration: "underline",
});

const FilesReportedContent = ({ allSuccessful, failedFileMap }: ContentProps) => {
  const showLinks = !allSuccessful && (failedFileMap?.length ?? 0) > 0;
  const fileMapBorder = (failedFileMap?.length ?? 0) > 5 ? "list-border" : "";

  return <ContentContainer>
    <div className={allSuccessful ? "successful" : "unsuccessful"}>
      {allSuccessful &&
        <div className="inner-container">
          {SUCCESSFUL_MESSAGE}
        </div>
      }
      {showLinks &&
        <div className="inner-container">
          <div>{FILES_UNSUCCESSFUL_TEXT}</div>
          <div className={clsx("file-list", fileMapBorder)}>
            {failedFileMap?.map((file) =>
              <StyledLink
                to={"/file"}
                state={{ fileID: file.fileId }}
                key={file.fileId}
              >
                {file.fileName}
              </StyledLink>
            )}
          </div>
          <div>{CONTACT_TEXT}</div>
        </div>
      }
      {!allSuccessful && !showLinks &&
        <div className="inner-container">
          <div>{FILE_UNSUCCESSFUL_TEXT}</div>
          <div>{CONTACT_TEXT}</div>
        </div>
      }
    </div>
  </ContentContainer>;
};

const ReportAndPayConfirmationDialog = ({
  reportingResult,
  fileMap = [],
  isOpen,
  onContinue,
  width = 500,
}: FilesReportedProps) => {
  const allSuccessful = !reportingResult?.failedFileIds?.length;
  const dialogType: DialogType = allSuccessful ? "SUCCESS" : "ERROR";
  const classes = useDialogStyles({ type: dialogType, width: width });
  const [open, setOpen] = useState(false);

  const failedFileMap = fileMap?.filter(f => reportingResult?.failedFileIds?.includes(f.fileId)) ?? [];

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <StyledDialog
      open={open}
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle
        classes={{ root: classes.dialogTitleRoot }}
      >
        {allSuccessful
          ? <CheckCircleIcon />
          : <WarningIcon />
        }
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <FilesReportedContent
          allSuccessful={allSuccessful}
          failedFileMap={failedFileMap}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot }}>
        <ActionButton
          variant="outlined"
          color="primary"
          onClick={onContinue}
        >
          Ok
        </ActionButton >
      </DialogActions>
    </StyledDialog >
  );
};

export default ReportAndPayConfirmationDialog;
