import React from 'react';

import usePricing from "utils/custom-hooks/usePricing";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import { useEndorsementErrorMessage } from "../../EndorsementErrorMessageProvider";
import { useFiles } from "utils/context/FilesContext";

function EndorsementErrorHandler() {
  const { isFileLocked } = useCreateFile();
  const { isFileLockedWithUpdateablePricingItem } = usePricing();
  const [{ initialValues, calculatedPricingProducts }] = useFiles();
  const { dispatch: endorsementErrorMessageDispatch } = useEndorsementErrorMessage();

  React.useEffect(() => {
    const pricingProducts = calculatedPricingProducts ? calculatedPricingProducts : initialValues.pricingProducts;
    // Deep clone the pricingProducts to avoid losing data after filtering 
    const clonePP = JSON.parse(JSON.stringify(pricingProducts))
    if (isFileLocked) {
      clonePP.forEach((product: any) => {
        if (product.productItems) {
          product.productItems = product.productItems.filter((item: any) => isFileLockedWithUpdateablePricingItem(item, isFileLocked));
        }
      });
    }
    endorsementErrorMessageDispatch({
      type: "INITIALIZE",
      products: clonePP
    });
    return () => {
      endorsementErrorMessageDispatch({ type: "RESET" });
    };
  }, [
    initialValues,
    calculatedPricingProducts,
    endorsementErrorMessageDispatch,
    isFileLocked
  ]);

  return <></>
}

export default EndorsementErrorHandler;