import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import ReportOnlyButton from "controls/global/report-only/ReportOnlyButton";
import { SCFile } from "entities/UIModel";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React, {
  useRef,
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDialog } from "utils/context/DialogContext";
import { useInitialValuesFiles } from "utils/context/FilesContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import {
  MapActionType,
  ReportAndPayAction,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

type Props = {
  disabled?: boolean;
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
  onReportingComplete: (reportingResult: ReportingResult) => void;
};

export const REPORT_ONLY_HELPER_TEXT =
  "By clicking <strong>Submit</strong>, the file will be reported and closed, and can be found in the Pending Payments section.";

export default function PricingReportOnly({
  disabled,
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
  onReportingComplete,
}: Props) {
  const [, { openDialog }] = useDialog();
  const [, { openProgressbar }] = useProgressbar();
  const { getValues } = useFormWrapper();
  const [{ initialValues }, { saveFile }] = useInitialValuesFiles();
  const [, { reportPricing }] = usePayment();
  const { isFileLocked, reloadFile } = useCreateFile();
  const { getPricingTotal, getPricingNewTotal } = usePricingCalculator();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const fileId = getValues("id");
  const reportingResultRef = useRef<ReportingResult>({});

  let totalDueTotal = "";
  if (isFileLocked) {
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);
    totalDueTotal = pricingNewTotal.newTotalTotalDue;
  } else {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    totalDueTotal = pricingTotal.totalDueTotal;
  }

  const handleReportOnlyClick = () => {
    openDialog({
      title: "Report Only",
      content: (
        <ReportContentMessage
          mainText={`Are you ready to report this file for ${totalDueTotal}?`}
          helperText={REPORT_ONLY_HELPER_TEXT}
        />
      ),
      onSubmitAction: handleDialogSubmit,
    });
  };

  const handleDialogSubmit = async () => {
    const requestId = uuidv4();

    openProgressbar({
      requestId,
      title: "Please wait while reporting your premiums...",
      onCloseAction: handleReportComplete,
    });

    const formValues: SCFile = getValues();

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );

    await saveFile(fileToSave, false);
    const result = await reportPricing([fileId], requestId, ReportAndPayAction.ReportOnly);
    reportingResultRef.current = result;
  };

  const handleReportComplete = async () => {
    openAutomaticProgressDialog();
    await reloadFile(fileId);
    closeAutomaticProgressDialog();

    onReportingComplete(reportingResultRef.current);
  };

  return (
    <ReportOnlyButton
      onClick={handleReportOnlyClick}
      disabled={disabled}
    />
  );
}
