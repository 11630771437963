import { createHook, createStore, StoreActionApi } from 'react-sweet-state';
import { AxiosRequestConfig } from 'axios';
import { axiosSecuredInstance } from '../../configurations/axiosConfig';

type StoreApi = StoreActionApi<State>;

export interface Translation {
  type: string;
  value: string;
  source1: string;
  source2: string;
  source3: string;
  target1: string;
}

interface State {
  error: string | null;
  translations?: Array<Translation>;
  isLoading: boolean;
}

const initialState: State = {
  error: null,
  translations: [],
  isLoading: false,
}

const fetchTranslations = (url: string, action: (param: Array<any>) => ({setState}: StoreApi) => any, config?: AxiosRequestConfig) =>
  async ({dispatch}: StoreApi) => {
    try {
      const {data} = await axiosSecuredInstance.get(url, config);
      dispatch(action(data));
      return data;
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

const setError = (error: string | null) => ({setState}: StoreApi) => {
  setState({error});
};

const hasAny = (data: Array<any>) => !data || data.length === 0;

const setTranslationList = (transList: Array<any>) => ({setState}: StoreApi) => {
  if (hasAny(transList)) {
    setState({isLoading: false, translations: []});
    return;
  }

  const mapPropTypes: Array<Translation> = transList.map((type) => {
    return {
      type: type.TranslationType,
      value: type.TranslationValue,
      source1: type.Source1,
      source2: type.Source2,
      source3: type.Source3,
      target1: type.Target1,
    };
  });

  setState({isLoading: false, translations: mapPropTypes});
};

const Store = createStore<State, any>({
  initialState: {...initialState},
  actions: {
    getTranslations:
      (translationType?: string, translationValue?: string) =>
        async ({dispatch, getState}: StoreApi) => {
          try {
            const {translations} = getState();
            if (translations && translations.length > 0) {
              const matchingTranslation = translations.find(
                (t) => t.type === translationType && t.value === translationValue
              );
              if (matchingTranslation) return;
            }
            return dispatch(
              fetchTranslations(
                `/lookup/GetTranslationList?translationType=${translationType}&translationValue=${translationValue}`,
                setTranslationList
              )
            );
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
  },
  name: "translation",
});


const hook = createHook(Store);
export const useTranslation = () => {
  return hook()
};
