import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { SCFile } from "entities/UIModel";
import { cloneDeep } from "lodash";
import {
  hasAdditionalParty,
  hasPendingProducts,
} from "pages/file/utils/helper";
import React, {
  useEffect,
  useRef,
} from "react";
import { useWatch } from "react-hook-form";
import { useAgencyLocationsActions } from "utils/context/AgencyLocationsContext";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import {
  useCompanyContext,
  useCompanyContextActions,
} from "utils/context/CompanyContext";
import { useDuplicateFileCheckActions } from "utils/context/DuplicatedFileCheckContext";
import { useFiles, useFilesActions } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useModalContext } from "utils/context/ModalContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useFocus from "utils/custom-hooks/useFocus";

type Props = {};

const name = "agency";
const AgencyField = (props: Props) => {
  const [idField, fileField, agencyField] = useWatch({
    name: ["id", "fileNameNumber", "agency"],
  });
  const { setValue, getValues, clearErrors, resetField } = useFormWrapper();
  const [, { resetNonFileSections, resetInitialValues }] = useFilesActions();
  const [{isReadOnly}] = useFiles();
  const [
    ,
    {
      getUniqueFileByAgencyClientFileId,
      setIsDuplicatedFile,
      setShowAlertDuplicatedFile,
    },
  ] = useDuplicateFileCheckActions();
  const [{ acceptedModal }, { setIsModalOpen, setMessage, resetDefaultValues }] = useModalContext();
  const [, { getAgencyLocations }] = useAgencyLocationsActions();
  const [{ agencies, error }] = useCompanyContext();
  const [, { getAgencies, updateAgencies }] = useCompanyContextActions();
  const [, { updateAgencyStates }] = useAgencyStates();
  const [{ selectedAgency }] = useGlobalAccess();    
  const { setFocusInputElement } = useFocus();
  const currentAgencyRef = useRef({});
  const [filteredAgencies, setFilteredAgencies] = React.useState<SelectFieldOption[]>([]);

  const agency = agencies.find((a) => a.value === agencyField.id);
      
  const checkIfDuplicatedFile = React.useCallback(
    async (agency: any) => {      
      let fileId = 0;
      if (agency?.value && fileField) {
        fileId = await getUniqueFileByAgencyClientFileId(agency.value, fileField);
      }      
      if (fileId === 0) {
        setIsDuplicatedFile(false);
      }

      if (fileId > 0) setShowAlertDuplicatedFile(true);
      else if (agency && agency.value !== "") {
        resetNonFileSections(0, fileField, agency);
        setIsDuplicatedFile(false);
        clearErrors(name);
      }
    },
    [
      agencies, 
      clearErrors, 
      fileField, 
      getUniqueFileByAgencyClientFileId, 
      resetNonFileSections, 
      setIsDuplicatedFile, 
      setShowAlertDuplicatedFile
    ]
  );

  const handleChange = (_: any, selectedAgency: any) => {

    const agency = buildAgency(selectedAgency);
    currentAgencyRef.current =  agency
        
    if (currentAgencyRef.current) {
      const additionalParties = getValues("additionalParties");
      const hasIssuedProducts = getValues("hasIssuedProducts");
      if ((
        hasPendingProducts(getValues() as SCFile) ||
        hasAdditionalParty(additionalParties)
      ) && !hasIssuedProducts ) {
        setMessage("By changing the Agency, any Additional Parties and pending products will be lost.");
        setIsModalOpen();
      } else {        
        setValue(name, agency);
        setFocusOnNextElement();        
      }
    } else {      
      setValue(name, agency); 
      setFocusOnNextElement();
    }
  };

  const handleOnBlur = async (e: any) => {    
    const selectedAgency = e.target.value;
    if (typeof getValues(name) == "string") { 
      setTimeout(() => {
        setValue(name, "");        
      }, 10);      
      return;
    }
    if((idField && idField > 0) || !selectedAgency || filteredAgencies?.length === 1) return;
    const agency = agencies.find((a) => a.text === selectedAgency);
    if (fileField) await checkIfDuplicatedFile(agency);
    getAgencyLocations(agency?.value);
    setValue("isFileDefault", false);
   
  };

  const setFocusOnNextElement = () => {    
    setTimeout(() => {
      setFocusInputElement("agencyLocation");
    }, 500);  
  };

  const buildAgency = (selectedAgencyOption: any) => {    
    return {
      id: selectedAgencyOption.value,
      name: selectedAgencyOption.text,
      activeContractID: selectedAgencyOption.activeContractID,
      legacyID: selectedAgencyOption.legacyID,
    };
  };
  
  useEffect(() => {    
    // User has GlobalAccessAgency Selected && Agency field is not populated yet
    // Then filterdown AgencyList to GA Selected Agency    
    if(selectedAgency&& selectedAgency[0]?.CompanyID && !isReadOnly) {
      if(agencies?.length > 0 && idField === 0 && !agencyField?.id) {
        const defaultAgency = agencies?.find((a) => a.value === selectedAgency[0]?.CompanyID);      
        if (defaultAgency) {              
              setValue(name, buildAgency(defaultAgency));            
              setFilteredAgencies([defaultAgency]);                  
        }        
      }      
    }
    else if(agencies?.length > 0 && idField === 0 && !agencyField?.id) {
      setFilteredAgencies(agencies);      
    }    
  }, 
  [
    selectedAgency, 
    setValue, 
    idField, 
    agencyField, 
    agencies, 
    filteredAgencies?.length,
    isReadOnly
  ]);

  React.useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  React.useEffect(() => {

    if (idField > 0 && agencyField?.id) {
      // Get agencyLocation from SCFile and add to agencies list
      const matchAgencyInList = agencies?.find((a) => a.value === agencyField.id);      
      if (!matchAgencyInList && isReadOnly) {        
        const newAgency = {
          value: agencyField?.id || "",
          text: agencyField?.name,
        };
        setFilteredAgencies([newAgency]);       
      }
      else if(matchAgencyInList) {
        setFilteredAgencies(
          [{
            value: matchAgencyInList?.value || "",
            text: matchAgencyInList?.text,
        }]);
      }
    }
  }, [idField, agencies, agencyField, updateAgencies, setValue, isReadOnly])
  //AUTO-SET AGENCY IF UNIQUE
  React.useEffect(() => {    
    if (
      idField > 0 ||
      !agencies ||
      agencies.length !== 1 ||
      agencyField.id !== ""
    )
      return;

    const agency = {
      id: agencies[0].value,
      name: agencies[0].text,
      activeContractID: agencies[0].activeContractID || "",
      legacyID: agencies[0].legacyID || "",
    };    
    setValue(name, agency);
    checkIfDuplicatedFile(agency);
  }, [idField, agencies, setValue, checkIfDuplicatedFile, agencyField.id]);

  //ACTIVECONTRACTID IS USED LATER IN THE APP
  //i.e. TO SHOW/HIDE THE PRODUCT/PRICING SECTIONS
  React.useEffect(() => {
    if (idField > 0 && agencyField.id !== "") {
      setValue(`${name}.activeContractID`, agency?.activeContractID ?? "");
      setValue(`${name}.legacyID`, agency?.legacyID ?? "");
    }
  }, [agency?.activeContractID, agency?.legacyID, agencyField.id, idField, setValue]);

  //LOAD AND AUTO-SELECT AGENCY FOR AN EXISTING FILE
  //WHEN NO AGENCIES ARE RETURNED FROM THE API
  React.useEffect(() => {
    if (idField > 0 && error && agencyField.id !== "") {
      getAgencies(agencyField);
    }
  }, [agencyField, error, getAgencies, idField]);

  // agency.activeContractID is reset to null after saved or any action by scFileCreator.ts
  React.useEffect(() => {
    if ((!agencyField?.activeContractID || !agencyField?.legacyID) && agencyField?.id) {
      const matchingAgency = agencies?.find((a: any) => a?.value === agencyField.id);
      setValue(`${name}.activeContractID`, matchingAgency?.activeContractID ?? "");
      setValue(`${name}.legacyID`, matchingAgency?.legacyID ?? "");
    }
  }, [agencyField?.activeContractID, agencyField?.legacyID, agencyField?.id, agencies, setValue]);

  const restoreFileInitial = () => {    
    const payload = cloneDeep({
      fileNameNumber: getValues("fileNameNumber"),
    });
    resetInitialValues();
    updateAgencyStates([]);    
    setTimeout(() => {
      setValue("fileNameNumber", payload.fileNameNumber)            
      resetField("properties");
    }, 500);
  }  

  useEffect(() => {
    if (acceptedModal) {
      restoreFileInitial();
      resetDefaultValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedModal]);  
  
  return (    
    <ComboBoxField
        label={"Agency"}
        options={filteredAgencies}
        {...{ name: name, disabled: Boolean(idField && agencyField.id) }}
        onChange={(_: any, selectedValue: any) => {
          handleChange(_, selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true} 
        controlTooltipOnMouseEvents = {!(idField && agencyField.id)} // Disregard tooltip in enabled mode. It is interfering with autocomplete popper
      />
  );
};

export default AgencyField;
