import { CancelTokenSource } from "axios";
import { axiosSecuredInstance, cancelToken } from "configurations/axiosConfig";
import ProductOption from "entities/UIModel/company/ProductOption";
import { StateProductConfig } from "entities/UIModel/company/StateProductConfig";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";

type State = {
  isLoadingStateProductConfig: boolean;
  stateProductConfig: StateProductConfig;
  axiosCancelToken?: CancelTokenSource;
};
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const getCompanyProductOptionsCachedKey = (
  companyId: string,
  state: string
) => {
  return `CompanyProductOptionsCachedKey-${companyId}~${state}`;
};

const setDefaultState =
  () =>
  ({ setState }: StoreApi) => {
    setState(defaultState);
  };

const setCompanyProductOptions =
  (data: any, companyId: string, state: string) =>
  ({ setState }: StoreApi) => {
    let productOptions: ProductOption[] = [];

    if (data && data.length > 0) {
      productOptions = data?.map((po: any) => {
        const option: ProductOption = {
          companyId: po.companyID,
          product: po.product,
          formType: po.formType,
          state: po.state,
          approvedAttorney: po.approvedAttorney,
          secondaryAgency: po.secondaryAgency,
          settlementCompany: po.settlementCompany,
          printAttorneyOnly: po.printAttorneyOnly,
          municipalCode: po.municipalCode,
          lenderSuccessorLanguage: po.lenderSuccessorLanguage,
          additionalCharge: po.additionalCharge,
          specialCharge: po.specialCharge,
          premiumTax: po.premiumTax,
          riskRate: po.riskRate,
          transCode: po.transCode,
          default: po.default,
        };

        return option;
      });
    }

    setState({
      isLoadingStateProductConfig: false,
      stateProductConfig: {
        companyId: companyId,
        stateAbbr: state,
        productOptions: productOptions,
      },
    });
  };

const actions = {
  getCompanyProductOptions:
    (companyId: string, state: string) =>
    async ({ setState, getState, dispatch }: StoreApi) => {
      try {
        if (!companyId || !state) return [];

        const cachedKey = getCompanyProductOptionsCachedKey(companyId, state);
        const cachedData = sessionStorage.getItem(cachedKey);

        if (cachedData) {
          dispatch(
            setCompanyProductOptions(JSON.parse(cachedData), companyId, state)
          );
        } else {
          const token = getState().axiosCancelToken;
          if (token)
            token.cancel("getProductOptions canceled due to new request");

          const newCancelToken = cancelToken.source();
          setState({ axiosCancelToken: newCancelToken });

          var url = `/Company/GetProductOptions?CompanyID=${companyId}&State=${state}`;

          setState({
            isLoadingStateProductConfig: true,
          });

          const { data } = await axiosSecuredInstance.get<any>(url, {
            cancelToken: newCancelToken.token,
          });

          sessionStorage.setItem(cachedKey, JSON.stringify(data));
          dispatch(setCompanyProductOptions(data, companyId, state));
        }
      } catch (error) {
        setState({
          isLoadingStateProductConfig: false,
        });
        console.error("ERROR: ProductOptions.", error);
        return [];
      }
    },
  resetCompanyProductOptions:
    () =>
    ({ dispatch }: StoreApi) => {
      dispatch(setDefaultState());
    },
};

const defaultState = {
  isLoadingStateProductConfig: false,
  stateProductConfig: {
    companyId: undefined,
    stateAbbr: undefined,
    productOptions: [],
  },
};

const Store = createStore<State, Actions>({
  initialState: defaultState,
  actions,
  name: "companyProductOptions",
});

const hook = createHook(Store);
export const useCompanyProductOptions = () => { return hook() };
