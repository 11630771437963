import SaveIcon from "@mui/icons-material/Save";
import ActionButton from "controls/global/action-button/ActionButton";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import { useSignatureProvider } from "pages/signatures/providers/manage-signature/SignatureProvider";
import { useWizardProvider } from "pages/signatures/providers/manage-signature/WizardProvider";
import React from "react";
import { useSignatures } from "utils/context/SignaturesContext";
import { v4 as uuidv4 } from "uuid";

type Props = {
  resetProviders?: () => void;
};

export default function SaveButton({ resetProviders }: Props) {
  const [
    { cachedSignature, isSaving },
    { saveSignature, getSignatures },
  ] = useSignatures();
  const {
    state: { activeStep },
  } = useWizardProvider();
  const {
    state: { signature, mode, imageSource, isNameValid, isCheckingName },
  } = useSignatureProvider();
  const {
    state: { nodes, hasSelectedNodes },
  } = useConfigurationProvider();

  const handleSave = async () => {
    if (!nodes)
      throw new Error(
        "Cannot save a configuration because the Signature Configuration is empty."
      );

    if (mode === "ADD") {
      await saveSignature({
        signatureImageID: 0,
        signatureImageName: signature.name.trim(),
        signatureImageDescription: "",
        signatureImageSourceTypeCode: imageSource,
        signatureImageFontTypeCode: signature.fontFamilyID,
        signatureImageFormatTypeCode: signature.imageType,
        signatureImageData: signature.file,
        signatureConfiguration: nodes,
        integrationKey: uuidv4(),
        isDefaulted: 0,
      });
    } else if (mode === "EDIT" && cachedSignature) {
      await saveSignature({
        ...cachedSignature,
        signatureImageName: signature.name.trim(),
        signatureImageData: signature.file,
        signatureImageFontTypeCode: signature.fontFamilyID,
        signatureImageFormatTypeCode: signature.imageType,
        signatureConfiguration: nodes,
        signatureImageSourceTypeCode: imageSource,
      });
    }

    getSignatures();
    resetProviders && resetProviders();
  };

  if (activeStep.key !== "CONFIG") return null;
  return (
    <ActionButton
      variant="outlined"
      color="primary"
      disabled={
        !signature.name ||
        !signature.file ||
        isSaving ||
        !isNameValid ||
        isCheckingName ||
        !hasSelectedNodes
      }
      onClick={handleSave}
      endIcon={<SaveIcon />}
    >
      Save
    </ActionButton>
  );
}
