import { styled } from "@material-ui/core";
import SelectField from "controls/global/select-field";
import React from "react";
import { useLookup } from "utils/context/LookupContext";

type Props = {
  isNewFile: boolean;
  noIssuedProducts: boolean;
  isOverride: boolean;
  overrideReasonError: boolean;
  isLocked: boolean;
  onChange: (event: React.ChangeEvent<any>) => void;
};

const StyledSelectField = styled(SelectField)({
  width: "287px",
});

export default function OverrideReasonField({
  isNewFile,
  noIssuedProducts,
  isOverride,
  overrideReasonError,
  isLocked,
  onChange,
}: Props) {
  const [{ overrideReasonTypes }] = useLookup();

  return (
    <StyledSelectField
      disabled={noIssuedProducts || isNewFile || Boolean(!isOverride) || isLocked}
      label="Override Reason"
      name="pricing.overrideReason"
      options={overrideReasonTypes}
      delaySearching={500}
      allowsDelete={false}
      showTooltipAlways
      onChange={onChange}
      highlightError={overrideReasonError}
    />
  );
}
