import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import StewartCheckbox from "controls/global/stewart-checkbox";
import { FileSearchCriteria } from "entities/ApiModel";
import React, {
  useState,
} from "react";
import { FileStatusType } from "utils/data/enum";
import { capitalize } from "utils/shared";

interface Props {
  name: keyof FileSearchCriteria;
  defaultValue: Array<FileStatusType>;
  onChange: (name: keyof FileSearchCriteria, value: string) => void;
}

const StyledSelect = styled(Select)({
  width: "auto",
  maxWidth: "100px",
});

const options = [
  { id: FileStatusType.Open, label: "Open" },
  { id: FileStatusType.Inactive, label: "Inactive" },
  { id: FileStatusType.ReportPending, label: "Reported/Pending" },
];

const SelectStatus = ({ name, defaultValue, onChange }: Props) => {
  const [selectedValues, setSelectedValues] =
    useState<FileStatusType[]>(defaultValue);

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const elValue = event.target.value as FileStatusType[];
    const selectedValues = elValue.length > 0 ?
      elValue.join(",")
      :
      [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending].join(",");

    setSelectedValues(elValue);
    onChange(name, selectedValues);
  };

  const getRenderValue = (selected: unknown) => {
    const statuses = (selected as FileStatusType[]).map((s) => {
      if (s === FileStatusType.ReportPending) return "Reported/Pending";
      return capitalize(s);
    });

    return statuses.join(", ");
  };

  return (
    <FormControl size="small">
      <StyledSelect
        name={name}
        disableUnderline
        fullWidth
        multiple
        variant="outlined"
        value={selectedValues}
        onChange={handleOnChange}
        renderValue={getRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.id}
            value={opt.id}
          >
            <StewartCheckbox checked={selectedValues.indexOf(opt.id) > -1} />
            <ListItemText primary={opt.label} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectStatus;
