import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  styled,
} from "@material-ui/core";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow/ScrollToTopArrow";
import SideNav from "controls/global/side-nav";
import {
  ActionType,
  useSideNavProvider,
} from "pages/file/SideNavProvider";
import validationSchema from "pages/file/utils/yup/schema/createFileValidationSchema";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useDuplicatedFileCheck } from "utils/context/DuplicatedFileCheckContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import {
  useFiles,
  useInitialValuesFiles,
} from "utils/context/FilesContext";
import { usePartyAdditionalParties } from "utils/context/PartyAdditionalPartyContext";
import { usePartyBuyerBorrowerActions } from "utils/context/PartyBuyerBorrowerContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import { usePartySellerActions } from "utils/context/PartySellerContext";
import { useLiabilityConfig } from "utils/context/PricingConfigContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import useTitle from "utils/custom-hooks/useTitle";
import FileInfo from "../file-info";
import FileSection from "../file-section";
import PartySection from "../parties-section/PartySection";
import PricingSection from "../pricing-section";
import ProductSection from "../product-section";
import PropertySection from "../property-section";
import EndorsementErrorHandler from "./EndorsementErrorHandler";
import SaveActions from "./SaveActions";
import SpyGlass from "./SpyGlass";

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
}

const Container = styled("div")((props) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: gaps.large1,
  position: "relative",
  [props.theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  "& > :first-child": {
    flexGrow: 0,
    flexShrink: 0,
  },
  "& > :last-child": {
    flex: "auto",
    gap: gaps.large1,
    overflowY: "auto",
    paddingBottom: padding.small1,
    paddingLeft: padding.small1,
    paddingRight: padding.small1,
  },
}));

function NewFileForm({ saveFileRef, saveFileChangeRef }: Props) {
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [{ initialValues }] = useInitialValuesFiles();
  const [{ isReadOnly }] = useFiles();
  const [
    { isDuplicatedFile },
    { setIsDuplicatedFile, setPrevFileNameValueState },
  ] = useDuplicatedFileCheck();
  const [, { setBuyerBorrowers }] = usePartyBuyerBorrowerActions();
  const [, { setSellers }] = usePartySellerActions();
  const [, { setLenders }] = usePartyLenderActions();
  const [, { setAdditionalParties }] = usePartyAdditionalParties();
  const [, { setFilePropertyState, resetPropertyState }] =
    useFilePropertyState();
  const { featureConfig: { enableSpyMode } } = useConfigContext();
  const [liabilityConfig] = useLiabilityConfig();
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const getResolver = useCallback(
    async (data: any, context: any, options: any) => {
      const ctx = { ...context, liabilityConfig, isDuplicatedFile };
      return yupResolver(validationSchema)(data, ctx, options);
    },
    [isDuplicatedFile, liabilityConfig]
  );
  const methods = useForm({
    resolver: !isReadOnly ? getResolver : undefined,
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });
  const { reset } = methods;
  const { redirect } = useNavRedirect();

  const handleNavigate = (url: string) => {
    redirect(url, saveFileChangeRef);
  };

  useTitle(
    `Stewart Connect - ${initialValues.fileNameNumber
      ? initialValues.fileNameNumber
      : "Create File"
    }`
  );

  useEffect(() => {
    setIsDuplicatedFile(false);
    setPrevFileNameValueState(Date.now());
  }, []);

  useEffect(() => {
    if (!initialValues) return;
    reset(initialValues);

    if (initialValues.buyerBorrowerParties)
      setBuyerBorrowers(initialValues.buyerBorrowerParties);
    if (initialValues.sellerParties) setSellers(initialValues.sellerParties);
    if (initialValues.lenderParties) setLenders(initialValues.lenderParties);
    if (initialValues.additionalParties)
      setAdditionalParties(initialValues.additionalParties);
    if (
      initialValues.properties &&
      initialValues.properties.length > 0 &&
      initialValues.properties[0].state?.code !== ""
    ) {
      setFilePropertyState(
        initialValues.properties[0].state.abbr,
        initialValues.properties[0].state.code
      );
    } else {
      resetPropertyState();
    }
  }, [reset, initialValues]);

  useEffect(() => {
    return () => {
      sideNavAction({ type: ActionType.RESET });
    };
  }, []);

  const filteredItems = Array.from(sideNavState.items.values()).filter(
    (item) => item.childRef !== undefined && item.childRef.current !== null
  );

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <Container>
          <SideNav
            scrollToTop={scrollToTop}
            includeIconOverflowPadding={false}
            navItems={filteredItems}
          />
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <FileSection onNavigate={handleNavigate} />
            <PropertySection />
            <PartySection />
            <ProductSection />
            <PricingSection onNavigate={handleNavigate} />
            <FileInfo />
            {enableSpyMode && <SpyGlass />}
            <ScrollToTopArrow
              refreshSize={[initialValues, filteredItems]}
              onScrollToTop={() => setScrollToTop(!scrollToTop)}
            />
          </Grid>
        </Container>
      </form>
      <EndorsementErrorHandler />
      <SaveActions
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
      />
    </FormProvider>
  );
}

export default NewFileForm;
