import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

const AutocompleteTextField = withStyles((theme) => ({
  root: (props: any) => {
    return {
      "& div.MuiAutocomplete-inputRoot":
      theme.custom.textField.inputOutlined,
      "& div.MuiAutocomplete-inputRoot.Mui-disabled":
      theme.custom.textField.inputOutlinedDisabled,
    };
  },
}))(TextField);

export default AutocompleteTextField;
