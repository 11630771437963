import { TextField, makeStyles } from "@material-ui/core";
import { default as React } from "react";
import { colors } from "theme/defaultStyle";

interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
  roundedCorner?: boolean;
  noBorder?: boolean;
  rest?: any;
  error?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: (props: any) => {
    let noBorderProps = {};
    if (props.noBorder) {
      noBorderProps = {
        borderColor: "transparent"
      }
    }
    return {
      "& div.MuiOutlinedInput-root": theme.custom.textField.inputOutlined,
      "& div.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": noBorderProps,
      "& div.MuiOutlinedInput-root path": { fill: colors.blue01 },
      "& div.MuiOutlinedInput-root.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
      "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
      "& div.Mui-error path": { fill: colors.red01 },
    };
  },
}));

const ComboBoxTextField = (props: Props) => {
  const { label, name, placeholder, rest, error } = props;
  const classes = useStyles(props);
  return (
    <TextField
      variant="outlined"
      {...{ label, name, placeholder, error }}
      {...rest}
      className={classes.root}
    />
  );
};

export default ComboBoxTextField;
