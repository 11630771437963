import React from 'react'

import * as UIModel from "entities/UIModel";
import { ProductAction, ProductType } from "utils/data/enum";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useDocument } from "utils/context/DocumentContext";

function usePDFDocumentsAAL() {
  const [pdfDocuments, setPdfDocuments] = React.useState<UIModel.PdfDocument[]>([]);
  const [openPdfViewer, setOpenPdfViewer] = React.useState<boolean>(false);
  const { getValues } = useFormWrapper();
  const [, { getDocumentUrl }] = useDocument();

  const getPDFDocuments = React.useCallback(async (
    currentActionResult: UIModel.ActionResult,
    currentProductIntegrationKey: String | undefined,
    currentProductAction: string | undefined,
    currentProductOriginalOrderID: number | undefined,
  ) => {
    let pdfDocuments: Array<UIModel.PdfDocument> = [];
    if (currentActionResult.scfile) {
      const scfile = currentActionResult.scfile as UIModel.SCFile;

      const aal = scfile.aALProducts?.find(
        (aal) =>
          (currentProductAction === ProductAction.Issue &&
            aal.integrationKey === currentProductIntegrationKey) ||
          (currentProductAction === ProductAction.Revise &&
            aal.originalOrderID === currentProductOriginalOrderID)
      );
      if (aal) {
        const documentId =
          aal.documents && aal.documents.length > 0
            ? Number(aal.documents[0].id)
            : 0;

        if (documentId > 0) {
          // get pdf url here
          const actionResult: UIModel.ActionResult = await getDocumentUrl(
            aal.fileID || 0,
            ProductType.Aal,
            aal.orderID || 0,
            documentId,
            0
          );

          if (actionResult && actionResult.pdfUrl) {
            const fileNameNumber = getValues("fileNameNumber");
            const pdfFilename = `${fileNameNumber} ${ProductType.Aal} ${aal.coveredParty} ${aal.productReferenceID}`;
            pdfDocuments.push({
              fileId: scfile.id || 0,
              productType: ProductType.Aal,
              orderId: aal.orderID || 0,
              documentId: documentId,
              pdfUrl: actionResult.pdfUrl,
              fileName: pdfFilename,
              showHidden: 0,
            });
          }
        }
      }
    }

    if (pdfDocuments.length === 0) return
    setPdfDocuments(pdfDocuments);
    setOpenPdfViewer(true);

  }, [getValues])


  return { getPDFDocuments, openPdfViewer, setOpenPdfViewer, pdfDocuments };
}

export default usePDFDocumentsAAL;
