import { CancelTokenSource } from "axios";
import { axiosSecuredInstance, cancelToken } from "configurations/axiosConfig";
import { AgencyStateRemittance } from "entities/UIModel/company/AgencyStateRemittance";
import { createHook, createStore, StoreActionApi } from "react-sweet-state";


type State = {
  agencyStateRemittance: AgencyStateRemittance | null;
  axiosCancelToken?: CancelTokenSource;  
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const getCachedKey = (companyId: string, stateCode:string) => {
  return `RemitanceCachedKey-${companyId}-${stateCode}`;
}

const setRemittance =
  (data: any) =>
  ({ setState }: StoreApi) => {
    let mappedRemittance: AgencyStateRemittance | null = null;

    if (data) {
      mappedRemittance = {
        identityKey: data.identityKey,
        agencyID: data.agencyID,
        agencyName: data.agencyName,
        stateName: data.stateName,
        stateAbbr: data.stateAbbr,
        stateCode: data.stateCode,
        methodOfPremiumCalculation: data.methodofPremiumCalculation,
        remitSplitType: data.remitSplitType,
        remitFlatPct: data.remitFlatPct,
        minRemitPerThousand: data.minRemitPerThousand,
        remitPerThousand: data.remitPerThousand,
        stdEndorsementSplitType: data.stdEndorsementSplitType,
        stdEndorsementRemitPct: data.stdEndorsementRemitPct,
        stdEndorsementRemitPerThousand: data.stdEndorsementRemitPerThousand,
        extraHazRiskEndorsementSplitType: data.extraHazRiskEndorsementSplitType,
        extraHazRiskEndorsementPct: data.extraHazRiskEndorsementPct,
        extraHazRiskEndorsementRemitPerThousand: data.extraHazRiskEndorsementRemitPerThousand,
        cplLenderFee: data.cplLenderFee,
        cplBuyerFee: data.cplBuyerFee,
        cplSellerFee: data.cplSellerFee,
        cplRemittancePct: data.cplRemittancePct,
        pastDueInterest: data.pastDueInterest,
        isNonStdRemit: data.isNonStdRemit,
      };
    }

    setState({
      agencyStateRemittance: mappedRemittance,
    });
  };

const actions = {
  getRemittance:
    (companyId:string, stateCode: string) =>
    async ({ dispatch, getState, setState }: StoreApi) => {
      const cachedKey = getCachedKey(companyId, stateCode);
      const cachedData = sessionStorage.getItem(cachedKey);

      if (cachedData) {
        dispatch(setRemittance(JSON.parse(cachedData)));
      } else {
        try {
          const token = getState().axiosCancelToken;
          if (token) token.cancel("getRemittance canceled due to new request");

          const newCancelToken = cancelToken.source();
          setState({ axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.get(
            `/Company/GetTerritoryRemitanceByCompanyState?CompanyID=${companyId}&StateCode=${stateCode}`,
            { cancelToken: newCancelToken.token }
          );
          sessionStorage.setItem(cachedKey, JSON.stringify(data));
          dispatch(setRemittance(data));
        } catch (error) {
          console.error("ERROR: companyRemittance.", error);
        }
      }
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    agencyStateRemittance: null,
  },
  actions,
  name: "companyRemittance",
});

const hook = createHook(Store);
export const useCompanyRemittance = () => { return hook() };
