import {
  Button,
  withStyles,
} from "@material-ui/core";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import React, {
  useState,
} from "react";
import {
  defaultStyle,
  fontWeight,
} from "theme/defaultStyle";
import useDidMountEffect from "utils/custom-hooks/useDidMountEffect";

const { control, button } = defaultStyle;

const StyledButton = withStyles(() => ({
  root: {
    borderRadius: button.borderRadius,
    fontSize: button.fontSize,
    fontWeight: fontWeight.normal2,
    height: button.height,
    paddingLeft: button.paddingLeft,
    paddingRight: button.paddingRight,
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  outlinedPrimary: {
    color: button.outlinePrimary.color,
    background: button.outlinePrimary.background,
    boxShadow: button.outlinePrimary.boxShadow,
    border: "none",
    "&:hover": {
      background: button.outlinePrimary.hover.background,
      border: "none",
      boxShadow: button.outlinePrimary.hover.boxShadow,
      color: button.outlinePrimary.hover.color,
    },
    "&:focus": {
      color: button.outlinePrimary.focus.color,
      border: "none",
      backgroundColor: button.outlinePrimary.focus.backgroundColor,
    },
  },
  outlinedSecondary: {
    color: button.outlineSecondary.color,
    backgroundColor: button.outlineSecondary.backgroundColor,
    boxShadow: button.outlineSecondary.boxShadow,
    border: `${control.borderSize} solid ${button.outlineSecondary.borderColor}`,
    "&:hover": {
      color: button.outlineSecondary.hover.color,
      border: `${control.borderSize} solid ${button.outlineSecondary.hover.borderColor}`,
      backgroundColor: button.outlineSecondary.hover.backgroundColor,
      "& svg": {
        color: button.outlineSecondary.hover.color,
      },
    },
    "&:focus": {
      color: button.outlineSecondary.focus.color,
      border: `${control.borderSize} solid ${button.outlineSecondary.focus.borderColor}`,
      backgroundColor: button.outlineSecondary.focus.backgroundColor,
      "& svg": {
        color: button.outlineSecondary.focus.color,
      },
    },
    "&:disabled": {
      color: button.outlineSecondary.disabled.color,
      backgroundColor: button.outlineSecondary.disabled.backgroundColor,
      border: `${control.borderSize} solid ${button.outlineSecondary.disabled.borderColor}`,
      opacity: 1,
    },
  },
}))(Button);

const ActionButton = ({ disableRipple, ...rest }: MuiButtonProps) => {
  // To temporarily workaround a material ui 4 bug,
  // we must disable ripple until the component is loaded.
  // That bug has been fixed in MUI 5 and this workaround
  // can be removed once we upgrade.
  const [disableRippleOverride, setDisableRippleOverride] = useState(true);

  useDidMountEffect(() => {
    setDisableRippleOverride(disableRipple ?? true);
  }, [disableRipple]);

  return <StyledButton
    disableRipple={disableRippleOverride}
    {...rest}
  />;
};

export default ActionButton;
