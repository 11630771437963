import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import { defaultStyle } from "theme/defaultStyle";
import ActionButton from "controls/global/action-button";
import CheckCircleIcon from "theme/icons/CheckCircleIcon";
import HelpIcon from "theme/icons/HelpIcon";
import { colors } from "theme/defaultStyle";

const { section } = defaultStyle;
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const useStyles = makeStyles({
  dialogRoot: {
    "& div.MuiDialog-paper": {
      overflowY: "revert",
    },
    "& div.MuiPaper-rounded": {
      borderRadius: section.borderRadius,
    },
    "& div.MuiDialog-paperFullWidth": {
      width: "500px",
    },
  },
  dialogTitleRoot: {
    color: "white",
    textAlign: "center",
    margin: "0 auto",
    padding: 0,
    fontSize: "24px",
    fontWeight: 700,
  },
  dialogActionRoot: {
    justifyContent: "center",
    paddingTop: "0px",
    paddingBottom: "24px",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.green} 0%, #006E49 100%)`,
    width: "90%",
    margin: "0 auto",
    borderRadius: "8px",
    height: "130px",
    marginTop: "-65px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  },
  dialogContent: {
    textAlign: "center",
    padding: "24px 0",
    margin: "0 auto",
    width: "90%",
    marginBottom: "24px",
  },
  dialogContentIcon: {
    margin: "32px",
  },
});
const buttonStyle = {
  width: "97px",
};
const SubmitTicketSuccessDialog = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        classes={{
          root: classes.dialogRoot,
        }}
      >
        <Box className={classes.dialogTitle}>
          <HelpIcon />
          <DialogTitle
            classes={{
              root: classes.dialogTitleRoot,
            }}
          >
            Thank You
          </DialogTitle>
        </Box>
        <Box className={classes.dialogContent}>
          <Box className={classes.dialogContentIcon}>
            <CheckCircleIcon />
          </Box>
          <Typography>
            Your Ticket has been submitted. <br /> Someone from Stewart&apos;s
            Agency Support Center will contact you shortly.{" "}
          </Typography>
        </Box>
        <DialogActions
          classes={{
            root: classes.dialogActionRoot,
          }}
        >
          <ActionButton

            variant="outlined"
            color="primary"
            onClick={onClose}
            style={buttonStyle}
          >
            Close
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SubmitTicketSuccessDialog;
