import ErrorMessage from "controls/global/error-message";
import TextInput from "./TextInput";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import React, { 
  ReactElement,
  } from "react";
import { 
  useFormContext, 
  Controller,
 } from "react-hook-form";

interface Props {
  label?: string | undefined;
  name: any;
  defaultValue?: any;
  type?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: any;
  className?: any;
  inputProps?: any;
  actionButton?: ReactElement;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  size?: "small" | "medium";
  style?: React.CSSProperties;
  errorClassName?:string;
}

const TextInputField = (props: Props) => {
  const { defaultValue, name, onBlur, onChange, disabled, style,errorClassName, ...others } = props;

  const { control } = useFormContext();

  const handleOnChange = (e: React.ChangeEvent<any>, onChangeFormHandler?: any) => {
    onChangeFormHandler && onChangeFormHandler(e);
    onChange && onChange(e);
  };

  const handleOnBlur = (e: React.ChangeEvent<any>, onBlurFormHandler: any) => {
    onBlurFormHandler();
    onBlur && onBlur(e);
  };
  const shouldFieldBeDisabled = useFieldDisabler(name);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { name, value, onChange, onBlur, ref }, fieldState }) => {
          return (
            <>
              <TextInput
                hasError={Boolean(fieldState.error)}
                onChange={(event: React.ChangeEvent<any>) => {
                  handleOnChange(event, onChange);
                }}
                disabled={disabled || shouldFieldBeDisabled}
                onBlur={(event: React.ChangeEvent<any>) => handleOnBlur(event, onBlur)}
                fieldRef={ref}
                size={others.size}
                {...{ value, name, ...others }}
              />
              {props.actionButton}
            </>
          );
        }}
      />
      <ErrorMessage
        name={props.name}
        style={style}
        className={errorClassName}
      />
    </>
  );
};

export default TextInputField;
