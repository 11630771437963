import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import ActionButton from "controls/global/action-button";
import React, { useEffect, useState } from "react";
import { colors } from "theme/defaultStyle";
import { useDialogStyles } from "utils/custom-hooks/useDialogStyles"
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  confirmationMessage: string | React.ReactNode;
  correlationId?: string;
  isOpen: boolean;
  onYes?: any;
  onNo?: any;
};

const ErrorDialog = ({ confirmationMessage, correlationId, isOpen, onYes }: Props) => {
  const classes = useDialogStyles({ type: 'ERROR', width: 450 });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!confirmationMessage) {
    return null;
  }

  return (
    <Dialog open={open} classes={{ root: classes.dialogRoot }}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <ReportProblemIcon style={{ fontSize: 40, color: colors.white }} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {confirmationMessage}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionRoot }}>
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          style={{ width: "80px" }}
          onClick={onYes}
          startIcon={<CheckIcon />}
        >
          OK
        </ActionButton>
      </DialogActions>
      {correlationId && (
        <div className={`${classes.supportId}`}>
          <span>Suport ID: {correlationId}</span>
          {/* <IconButton title="Send email to support" className={`fa fa-envelope ${classes.emailToIcon}`} onClick={(e) => { window.location.href = `mailto:CustomerCare@Stewart.com?subject=${correlationId}`; e.preventDefault(); }} /> */}
          <IconButton
            title="Copy to clipboard"
            className={`fa fa-clipboard ${classes.copyToClipboardIcon}`}
            onClick={() => navigator.clipboard.writeText(correlationId)}
          />
        </div>
      )}
    </Dialog>
  );
};

export default ErrorDialog;
