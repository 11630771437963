import { Endorsement as CompanyEndorsement } from "entities/UIModel/company/Endorsement";
import { Endorsement } from "entities/UIModel/Endorsement";
import { Jacket } from "entities/UIModel/Jacket";
import { isEqual } from 'lodash';
import React,
{
  PropsWithChildren,
  useEffect,
} from "react";
import {
  getCacheKey,
  toSelectFieldOption,
  useEndorsementState,
} from "utils/context/CompanyEndorsementContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusType,
  ProductType,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";
import EndorsementGrid from "../../common/endorsement-grid/EndorsementGrid";

type Props = {
  jacketIndex: number;
  jacket: Jacket;
  revisionModeActive: boolean;
  displayIssuedModeGrid: boolean;
  isRevisionMode: boolean;
  endorsementEditMode: boolean;
  revising: boolean;
  onVoidComplete?: () => void;
};

const areEqualEndorsements = (
  array1: Endorsement[] = [],
  array2: Endorsement[] = []
) => {
  if (Object.keys(array1).length !== Object.keys(array2).length) return false;
  if (array1.length === 0 && array2.length === 0) return true;

  const sortedArray1 = [...array1].sort((a, b) =>
    a.endorsementName > b.endorsementName
      ? 1
      : b.endorsementName > a.endorsementName
        ? -1
        : 0
  );
  const sortedArray2 = [...array2].sort((a, b) =>
    a.endorsementName > b.endorsementName
      ? 1
      : b.endorsementName > a.endorsementName
        ? -1
        : 0
  );

  const areEqual = sortedArray1.every((p, idx) => {
    if (p.endorsementID !== sortedArray2[idx].endorsementID) return false;
    if (p.isSFE !== sortedArray2[idx].isSFE) return false;
    if (
      p.effectiveDate?.getTime() !== sortedArray2[idx].effectiveDate?.getTime()
    )
      return false;
    if (p.isSelected !== sortedArray2[idx].isSelected) return false;
    if (p.endorsementName !== sortedArray2[idx].endorsementName) return false;
    if (p.altEndorsementID !== sortedArray2[idx].altEndorsementID) return false;
    if (p.endorsementVersion !== sortedArray2[idx].endorsementVersion)
      return false;
    if (p.opaIdentifier !== sortedArray2[idx].opaIdentifier) return false;
    if (
      p.endorsementStatusTypeCode !==
      sortedArray2[idx].endorsementStatusTypeCode
    )
      return false;
    if (p.productItemID !== sortedArray2[idx].productItemID) return false;
    if (p.filePricingDetailID !== sortedArray2[idx].filePricingDetailID)
      return false;

    return true;
  });

  return areEqual;
};

function calculateEndorsements(
  jacketEndorsements: Endorsement[] | undefined,
  formShortFormEndorsements: CompanyEndorsement[] | undefined
) {
  if (!formShortFormEndorsements) return jacketEndorsements;

  const currentNonShortFormEndorsements: Endorsement[] =
    jacketEndorsements?.filter(
      (endorsement: Endorsement) => !endorsement.isSFE
    ) || [];

  const currentShortFormEndorsements: Endorsement[] =
    jacketEndorsements?.filter(
      (endorsement: Endorsement) => endorsement.isSFE
    ) || [];

  const shortFormEndorsementsToAdd: Endorsement[] = formShortFormEndorsements
    ? formShortFormEndorsements.map((sfe: CompanyEndorsement) => {
      const existingSfe = currentShortFormEndorsements.find(
        (current: Endorsement) =>
          current.endorsementName === sfe.endorsementName
      );

      return {
        endorsementID: existingSfe ? existingSfe.endorsementID : 0,
        isSFE: true,
        effectiveDate: existingSfe ? existingSfe.effectiveDate : null,
        isSelected: existingSfe ? existingSfe.isSelected : false,
        endorsementName: sfe.endorsementName,
        altEndorsementID: sfe.endorsementID,
        endorsementVersion: sfe.endorsementVersion,
        opaIdentifier: sfe.opaIdentifier,
        integrationKey: existingSfe ? existingSfe.integrationKey : uuidv4(),
        endorsementStatusTypeCode: OrderStatusType.Pending,
        isValidationRequired: false,
        productItemID: existingSfe ? existingSfe.productItemID : undefined,
        filePricingDetailID: existingSfe
          ? existingSfe.filePricingDetailID
          : undefined,
      } as Endorsement;
    })
    : [];

  return [...shortFormEndorsementsToAdd, ...currentNonShortFormEndorsements];
}

const CombineEndorsementGrid = ({
  jacketIndex,
  jacket,
  revisionModeActive,
  displayIssuedModeGrid,
  isRevisionMode,
  endorsementEditMode,
  revising,
  onVoidComplete,
}: Props) => {
  const { isFileLocked } = useCreateFile();
  const { setValue, getValues } = useFormWrapper();
  const [{ nonShortFormEndorsements, shortFormEndorsements, formEndorsements }] =
    useEndorsementState(
      getCacheKey(
        getValues("agency.id"),
        jacket.formType,
        getValues("properties.0.state.abbr"),
        jacket.formID
      )
    );

  const hasSFE = jacket && jacket.endorsements?.some((e: Endorsement) => e.isSFE);
  const calculatedEndorsements = calculateEndorsements(jacket.endorsements, shortFormEndorsements);
  const areEqual = areEqualEndorsements(jacket.endorsements, calculatedEndorsements);

  const getFormEndorsements = (status: string) => {
    if (status === OrderStatusType.Issued) {
      return formEndorsements;
    }
    return nonShortFormEndorsements;
  };

  useEffect(() => {
    if (
      jacket.orderStatusTypeCode !== OrderStatusType.Pending ||
      !jacket.formID ||
      areEqual
    ) {
      return;
    }

    setValue(`jackets.${jacketIndex}.endorsements`, calculatedEndorsements);
  }, [
    areEqual,
    calculatedEndorsements,
    jacket.formID,
    jacket.orderStatusTypeCode,
    jacketIndex,
  ]);

  return (
    <EndorsementGrid
      name={`jackets.${jacketIndex}`}
      minDate={jacket ? jacket.effectiveDate : null}
      disabled={
        !jacket.formType ||
        jacket.orderStatusTypeCode === OrderStatusType.Voided ||
        (isFileLocked &&
          jacket.orderStatusTypeCode === OrderStatusType.Pending) ||
        revisionModeActive
      }
      endorsementOptions={toSelectFieldOption(getFormEndorsements(jacket.orderStatusTypeCode))}
      issuedMode={displayIssuedModeGrid}
      isIssueable={!isRevisionMode}
      editMode={endorsementEditMode}
      parentProductType={ProductType.Jacket}
      parentOrderID={jacket ? jacket.orderID || 0 : 0}
      parentProductIndex={jacketIndex}
      isRevising={revising}
      isTransCodeRequired={jacket.isTransCodeRequired}
      hasShortFormEndorsements={hasSFE}
      onVoidComplete={onVoidComplete}
    />
  );
}

export default React.memo(CombineEndorsementGrid, ((prevProps: Readonly<PropsWithChildren<Props>>, nextProps: Readonly<PropsWithChildren<Props>>) => {
  return isEqual(prevProps, nextProps);
}));
