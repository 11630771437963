import { IColumn } from "../entities/ApiModel/IColumn";

const getColumnConfig = <T>(columns: IColumn[], field: keyof T) => {
  const item = columns.find((g) => g.field === field);

  if (!item)
    return {
      name: "",
      width: 0,
      sticky: false,
      sortable: false,
      draggable: false,
      hideable: false,
      resizable: false,
      hidden: true,
      position: undefined,
    };

  return {
    name: item.name,
    width: item.width !== undefined ? item.width : 100,
    sticky: item.sticky !== undefined ? item.sticky : false,
    sortable: item.sortable !== undefined ? item.sortable : true,
    draggable: item.draggable !== undefined ? item.draggable : true,
    hideable: item.hideable !== undefined ? item.hideable : true,
    resizable: item.resizable !== undefined ? item.resizable : true,
    hidden: item.hidden !== undefined ? item.hidden : false,
    position: item.position !== undefined ? item.position : undefined,
  };
}

export { getColumnConfig };
