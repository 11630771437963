import {
  Box,
  Grid,
} from "@material-ui/core";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import FullAddress from "controls/global/full-address";
import TextInputField from "controls/global/text-input-field";
import {
  Agency,
  GeneralFileParty,
  Property,
  SavedContactResponse,
} from "entities/UIModel";
import React, {
  useCallback,
} from "react";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import { useSuccessorLanguages } from "utils/context/CompanySuccessorLanguageContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { IAddressComponent } from "utils/interfaces/BaseAddress";
import CompanyName from "../CompanyName";
import SuccessorLanguageField from "../SuccessorLanguageField";

interface ILenderProps extends IAddressComponent {
  index: number;
  party: GeneralFileParty;
  parentArrayName?: string;
  isAAL?: boolean;
  deleteParty?: (index: number) => void;
  agency: Agency;
  property: Property;
  additionalParties: GeneralFileParty[];
  fileNameNumber: string;
  hasIssuedProducts: boolean;
  isPopulatingExistingFile: boolean;
}

const getLenderDescription = (companyName?: string) =>
  companyName ? ` ${companyName}` : "";

const Lender = ({
  index,
  party,
  parentArrayName = "",
  isAAL,
  deleteParty,
  additionalParties,
  fileNameNumber,
  agency,
  property,
  hasIssuedProducts,
  isPopulatingExistingFile,
}: ILenderProps) => {
  const { getValues, setValue, register } = useFormWrapper();
  const [, { setLenders, updateZipCodeLender }] = usePartyLenderActions();
  const [{ successorLanguages }] = useSuccessorLanguages();

  const integrationKeyField = `${parentArrayName}.${index}.integrationKey`;
  const filePartyKeyField = `${parentArrayName}.${index}.filePartyKey`;
  const partyRoleTypeCodeField = `${parentArrayName}.${index}.partyRoleTypeCode`;
  const partyTypeCodeField = `${parentArrayName}.${index}.partyTypeCode`;
  const isFileDisabled = useFieldDisabler("LenderClearButton");

  const handleDeleteParty = () => {
    deleteParty && deleteParty(index);
  };

  const handleOnCompanyNameSelected = (
    index: number,
    value: SavedContactResponse | string
  ) => {
    if (value === null) return;

    if (typeof value === "string") {
      setValue(`${parentArrayName}.${index}.companyName`, value);
    } else {
      setValue(
        `${parentArrayName}.${index}.companyName`,
        value.ContactCompanyName
      );
      setValue(`${parentArrayName}.${index}.addressOne`, value.Address);
      setValue(`${parentArrayName}.${index}.addressTwo`, value.Address2);
      setValue(`${parentArrayName}.${index}.city`, value.City);
      setValue(`${parentArrayName}.${index}.zipCode`, value.Zip);
      setValue(
        `${parentArrayName}.${index}.individualAttorney`,
        value.ContactAdditionalName || ""
      );
      setValue(`${parentArrayName}.${index}.state`, {
        code: value.StateCode,
        abbr: value.StateAbbr,
      });
      setValue(`${parentArrayName}.${index}.phone`, value.Phone);
      setValue(`${parentArrayName}.${index}.mobile`, value.Mobile);
      setValue(`${parentArrayName}.${index}.fax`, value.Fax);
      setValue(`${parentArrayName}.${index}.email`, value.Email);
      setValue(`${parentArrayName}.${index}.migrationKey`, value.MigrationKey);
    }
  };

  const label = getLenderDescription(
    getValues(`${parentArrayName}.${index}.companyName`)
  );

  const updatePartyLenders = useCallback(() => {
    setLenders(getValues("lenderParties"));
  }, [getValues, setLenders]);

  return (
    <Box key={`lender-${index}`}>
      <CollapsableFormSection
        index={index}
        title={`Lender ${index + 1}`}
        label={label}
        showDelete={!isFileDisabled}
        onDelete={handleDeleteParty}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} xl={4}>
            <CompanyName
              name={`${parentArrayName}.${index}.companyName`}
              value={party.companyName}
              index={index}
              onChange={handleOnCompanyNameSelected}
              onBlur={updatePartyLenders}
            />
          </Grid>
          <Grid item xs={11} lg={5} xl={5}>
            <TextInputField
              label="Lender Contact"
              name={`${parentArrayName}.${index}.individualAttorney`}
            />
          </Grid>
            <CustomTooltip
              title={`Lender Contact will not appear on the ${isAAL ? "AAL" : "CPL"}.`}
              placement="top"
              aria-label="lender-contact-info"
              arrow
            >
              <StyledInfoIcon />
            </CustomTooltip>
          <Grid item xs={12} lg={2} xl={2}>
            <Box justifyContent="start" display="flex">
              <CheckboxInputField
                label="Save to contacts"
                name={`${parentArrayName}.${index}.saveToContacts`}
              />
            </Box>
          </Grid>
          <FullAddress
            schema={`${parentArrayName}.${index}`}
            isPropertySection={false}
            callbackIndex={index}
            onBlur={updatePartyLenders}
            verifyAddressHandler={(value: string) => {
              updateZipCodeLender(index, value);
            }}
          />
          <Grid item xs={12} lg={3} xl={3}>
            <TextInputField
              label="Loan Number"
              name={`${parentArrayName}.${index}.loanNumber`}
              onBlur={updatePartyLenders}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={6}>
            <SuccessorLanguageField
              name={`${parentArrayName}.${index}.successorLanguage`}
              succesorLanguages={successorLanguages}
              defaultValue={party.successorLanguage}
              onBlur={updatePartyLenders}
            />
          </Grid>
          <Grid item xs={12} lg={3} xl={3}>
            <TextInputField
              label="Attention To"
              name={`${parentArrayName}.${index}.attentionTo`}
              onBlur={updatePartyLenders}
            />
          </Grid>
          <input
            type="hidden"
            {...register(`${integrationKeyField}` as const)}
          />
          <input type="hidden" {...register(`${filePartyKeyField}` as const)} />
          <input
            type="hidden"
            {...register(`${partyRoleTypeCodeField}` as const)}
          />
          <input
            type="hidden"
            {...register(`${partyTypeCodeField}` as const)}
          />
        </Grid>
      </CollapsableFormSection>
    </Box>
  );
};

export default Lender;
