import { Cancel } from "@material-ui/icons";
import CheckIcon from '@mui/icons-material/Check';
import ActionButton from "controls/global/action-button";
import React from "react";
import BlockBoldIcon from "theme/icons/BlockBoldIcon";
import ReviseIcon from "theme/icons/ReviseIcon";
import ViewPrintIcon from "theme/icons/ViewPrintIcon";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import { OrderStatusType } from "utils/data/enum";

export enum ReviseAction {
  OpenRevise,
  PreviewRevise,
  SubmitRevise,
  CancelRevise,
}

interface ButtonsSectionProps {
  onIssueJacket?: any;
  onPreviewJacket?: any;
  onViewJacket?: any;
  onVoidJacket?: any;
  orderStatusTypeCode?: string;
  isDisabled: boolean;
  issueButtonDisabled: boolean;
  submitReviseDisabled: boolean;
  voidButtonDisabled: boolean;
  isRevisable: boolean;
  revising: boolean;
  endorsementEditMode: boolean;
  disableEndorsementSubmit: boolean;
  handleEndorsementGridCancel: () => void;
  handleEndorsementGridSubmit: () => void;
  onReviseAction: (action: ReviseAction) => void;
  productIndex: number;
}

const JacketButtonSection = ({
  isDisabled,
  issueButtonDisabled,
  submitReviseDisabled,
  voidButtonDisabled,
  isRevisable,
  revising,
  onIssueJacket,
  onPreviewJacket,
  onViewJacket,
  onVoidJacket,
  orderStatusTypeCode,
  endorsementEditMode,
  disableEndorsementSubmit,
  handleEndorsementGridCancel,
  handleEndorsementGridSubmit,
  onReviseAction,
  productIndex,
}: ButtonsSectionProps) => {
  const isViewVisible = orderStatusTypeCode === OrderStatusType.Issued;
  const isIssueVisible =
    !orderStatusTypeCode ||
    orderStatusTypeCode === OrderStatusType.Pending ||
    orderStatusTypeCode === OrderStatusType.Error;
  const isVoidVisible = orderStatusTypeCode === OrderStatusType.Issued;
  const isReviseButtonDisabled = useFieldDisabler("JACKETReviseButton");
  const isVoidButtonDisabled = useFieldDisabler("JACKETVoidButton");

  // Endorsement Submit & Cancel must come on only for PPE (Post Policy Endorsement)
  if (orderStatusTypeCode === OrderStatusType.Issued && endorsementEditMode)
    return (
      <>
          <ActionButton
            color="secondary"
            onClick={handleEndorsementGridCancel}
            startIcon={<Cancel />}
            variant="outlined"
          >
            Cancel
          </ActionButton>
          <ActionButton
            color="primary"
            disabled={disableEndorsementSubmit}
            onClick={handleEndorsementGridSubmit}
            startIcon={<CheckIcon />}
            variant="outlined"
          >
            Submit
          </ActionButton>
      </>
    );

  return (
    <>
      {isRevisable && !revising && (
          <ActionButton
            color="secondary"
            disabled={
              isDisabled || submitReviseDisabled || isReviseButtonDisabled
            }
            variant="outlined"
            startIcon={<ReviseIcon />}
            onClick={() => {
              onReviseAction(ReviseAction.OpenRevise);
            }}
          >
            Revise
          </ActionButton>
      )}

      {isRevisable && revising && (
        <>
            <ActionButton
              color="primary"
              disabled={isDisabled}
              variant="outlined"
              startIcon={<CheckIcon />}
              onClick={() => {
                onReviseAction(ReviseAction.SubmitRevise);
              }}
            >
              Submit Revision
            </ActionButton>
            <ActionButton
              color="secondary"
              disabled={isDisabled}
              variant="outlined"
              startIcon={<Cancel />}
              onClick={() => {
                onReviseAction(ReviseAction.CancelRevise);
              }}
            >
              Cancel
            </ActionButton>
        </>
      )}

      {isIssueVisible && (
          <ActionButton
            color="primary"
            disabled={isDisabled || issueButtonDisabled}
            variant="outlined"
            startIcon={<CheckIcon />}
            onClick={onIssueJacket}
            name={`issueJacket-${productIndex}`}
          >
            Issue
          </ActionButton>
      )}

      {isVoidVisible && !revising && (
          <ActionButton
            color="secondary"
            variant="outlined"
            startIcon={<BlockBoldIcon />}
            onClick={onVoidJacket}
            disabled={voidButtonDisabled || isVoidButtonDisabled}
          >
            Void
          </ActionButton>
      )}

      {isViewVisible && !revising && (
          <ActionButton
            color="primary"
            //disabled={isDisabled || submitReviseDisabled}
            onClick={onViewJacket}
            startIcon={<ViewPrintIcon />}
            variant="outlined"
          >
            View/Print
          </ActionButton>
      )}
    </>
  );
};

export default JacketButtonSection;
