import StewartTableFooterPaginator from 'controls/global/stewart-table/StewartTableFooterPaginator';
import styled from 'styled-components';
import TooltipCell from 'controls/global/stewart-table/TooltipCell';
import { CompanyLocationState as UiCompanyLocationState } from 'entities/UIModel/CompanyLocationState';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import StewartTable, {
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import {
  Box,
  Link,
} from "@material-ui/core";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useUser } from "utils/context/UserContext";
// import SelectStatus from "./SelectStatus";
// import {
//   AgencyStatus,
// } from "utils/data/enum"; // functionality will get reused

type GlobalAccessGridProps = {
  filteredCompanies: UiCompanyLocationState[];
  noRecordMessage: string[];
  setNoRecordsMessage: React.Dispatch<React.SetStateAction<string[]>>;
  page: number;
  setPage: (page: number) => void;
};

interface OrderByOptions {
  uniqueIdentifier: "uniqueIdentifier";
  companyName: "companyName";
  territoryStates: "territoryStates";
  legacyId: "legacyId";
  status: "status";
}

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
        border: 0,
        borderBottom: `1px solid ${colors.grey15}`,
        borderRadius: 0,
        "&:focus-within": {
          borderBottom: `2px solid ${colors.blue01}`,
        },
        "& fieldset": {
          border: 0,
        },
      },
    },
    "& tbody": {
      "& tr:hover td": {
        background: colors.grey10,
      },
      "& td:not(.emptyCell)": {
        maxWidth: "75px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey08}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero,
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
});

const StyledLink = styled(Link)({
  color: colors.blue01,
  cursor: "pointer",
  fontWeight: fontWeight.bold2,
  textDecoration: "underline",
});

const transformData = (data: UiCompanyLocationState[]) => {
  return data.map((company) => ({
    uniqueIdentifier: uuidv4(),
    companyName: company.companyName,
    territoryStates: company.territoryStates,
    legacyId: company.locationLegacyIDs,
    status: company.status,
  }));
};

// const DefaultStatuses = [AgencyStatus.Active, AgencyStatus.Inactive];

const GlobalAccessGrid: React.FC<GlobalAccessGridProps> = ({
  filteredCompanies,
  noRecordMessage,
  setNoRecordsMessage,
  setPage,
  page,
}) => {
  const [{ companyLocationStates }, { setUserSelectedAgency }] = useGlobalAccess();
  const [, { getRowsPerPageSetting, setRowsPerPageSetting, }] = useUser();
  // const [selectedStatus, setStatus] = useState("");

  // const filterDataByStatus = (status: string, value: string) => {
  //   setStatus(value);
  // };

  const columns: StewartTableColumn<any>[] = [
    {
      field: "companyName",
      name: "AGENCY NAME",
      sortable: true,
      width: 600,
      valueGetter: ({ companyName }: any) => (
        <TooltipCell title={companyName}>
          <StyledLink onMouseDown={(e) => handleOpenFile(e, companyName)}>{companyName}</StyledLink>
        </TooltipCell>
      ),
    },
    {
      field: "territoryStates",
      name: "TERRITORY STATE(S)",
      sortable: true,
      width: 200,
      valueGetter: ({ territoryStates }: any) => <TooltipCell title={territoryStates}>{territoryStates}</TooltipCell>,
    },
    {
      field: "legacyId",
      name: "LEGACY ID",
      sortable: true,
      width: 200,
      valueGetter: ({ legacyId }: any) => <TooltipCell title={legacyId}>{legacyId}</TooltipCell>,
    },
    // {
    //   field: "status",
    //   name: "STATUS",
    //   sortable: true,
    //   width: 100,
    //   actionComponent: () => (
    //     <SelectStatus
    //       name="status"
    //       defaultValue={DefaultStatuses}
    //       onChange={filterDataByStatus}
    //     />
    //   ),
    // }, // functionality will get reused
  ];
  // const [newFilteredList, setNewFilteredList] = useState<any>(); // functionality will get reused
  const [tableColumns, setTableColumns] = useState(columns);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const rows = transformData(newFilteredList ? newFilteredList : filteredCompanies); // functionality will get reused
  const rows = transformData(filteredCompanies);
  const navigate = useNavigate();
  const [selectedAgency, setSelectedAgency] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof OrderByOptions>("companyName");

  const handleColumnResize = (column: string, newWidth: number) => {
    const updatedColumns = tableColumns.map((col) => {
      if (col.field === column) {
        const minWidth = 100;
        const maxWidth = 400;
        const adjustedWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
        return { ...col, width: adjustedWidth };
      }
      return col;
    });
    setTableColumns(updatedColumns);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setRowsPerPageSetting(newRowsPerPage);
    setPage(1);
  };

  const handleRequestSort = (property: typeof orderBy) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOpenFile = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    companyName: any,
  ) => {
    setSelectedAgency(companyName);
    navigate("/Home");
  };

  useEffect(() => {
    if (selectedAgency)
      setUserSelectedAgency(companyLocationStates.filter((company) => company.CompanyName?.includes(selectedAgency)));
  }, [selectedAgency]);

  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  // useEffect(() => {
  //   if (selectedStatus !== "ACTIVE" && selectedStatus !== "INACTIVE") {
  //     setNewFilteredList(filteredCompanies);
  //   } else {
  //     setNewFilteredList(filteredCompanies.filter((company) => company.status?.toUpperCase() === selectedStatus));
  //   }
  //   setPage(1);
  // }, [selectedStatus, filteredCompanies]); // functionality will get reused

  return (
    <StyledTableContainer width={1}>
      <StewartTable
        cols={tableColumns}
        rows={rows}
        page={page}
        noRowsMessage={noRecordMessage}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        onColumnResize={handleColumnResize}
        showActionRow={false}
      />
      <StewartTableFooterPaginator
        totalRows={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTableContainer>
  );
};

export default GlobalAccessGrid;
