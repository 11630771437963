import React from "react";
import {
  IconButton,
  makeStyles,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Endorsement } from "entities/UIModel/Endorsement";
import { colors } from "theme/defaultStyle";
import {
  OrderStatusType,
  ProductType,
} from "utils/data/enum";
import { formatDateTime } from "utils/shared";
import useFormWrapper  from "utils/custom-hooks/useFormWrapper";
import DeleteIcon from '@material-ui/icons/Delete';
import EndorsementNameCell from "./EndorsementNameCell";
import EndorsementEffectiveDateCell from "./EndorsementEffectiveDateCell";
import EndorsementCheckboxCell from "./EndorsementCheckboxCell";
import EndorsementVoidCell from "./EndorsementVoidCell";
import EndorsementStatusCell from "./EndorsementStatusCell";

interface Props {
  name: string;
  index: number;
  minDate?: Date | null;
  endorsement: Endorsement;
  endorsementOptions?: SelectFieldOption[];
  issuedMode: boolean;
  editMode: boolean;
  isRevising?: boolean;
  handleOnRemove: (index: number) => void;
  voidable: boolean;
  showTransCode: boolean;
  hasShortFormEndorsements: boolean;
  disabled: boolean;
  parentOrderID: number;
  parentProductType: string;
  onEndorsementChange?: any;
  onRequestVoid: (endorsement: Endorsement) => void;
  parentProductIndex?: number;
}

const StyleCellText = styled(Typography)({
  fontSize: "18px",
  color: colors.grey07,
  padding: "10px 0px 5px 10px",
});

const useStyles = makeStyles({
  cell: {
    padding: "0px !important",
    borderRight: `1px solid ${colors.grey04}`,
  },
  endorsementGrid: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  disabledIconButton: {
    opacity: 0.5,
  },
});

const EndorsementGridRow = ({
  name,
  index,
  minDate,
  endorsement: currentEndorsement,
  endorsementOptions,
  issuedMode,
  editMode,
  isRevising = false,
  handleOnRemove,
  voidable,
  showTransCode,
  hasShortFormEndorsements,
  disabled,
  parentOrderID,
  parentProductType,
  onEndorsementChange,
  onRequestVoid,
  parentProductIndex,
}: Props) => {
  const classes = useStyles();

  const { setValue, watch, nameString } = useFormWrapper();

  // const currentEndorsement: Endorsement = watch(nameString(`${name}.${index}`), endorsement );
  watch(nameString(`${name}.${index}.endorsementName`));

  const isIssuedEndorsement =
    currentEndorsement.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Issued ||
    currentEndorsement.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Voided;

  const isActiveEndorsement =
    currentEndorsement.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Issued;

  const isEffectiveDateDisabled =
    isRevising && isActiveEndorsement
      ? false
      : isIssuedEndorsement ||
      (!currentEndorsement.isSFE && !currentEndorsement?.endorsementName) ||
      (currentEndorsement.isSFE && !currentEndorsement?.isSelected) ||
      disabled;

  // console.log(
  //   "index:",
  //   index,
  //   "isEffectiveDateDisabled:",
  //   isEffectiveDateDisabled,
  //   "currentEndorsement:",
  //   currentEndorsement,
  //   "disabled:", disabled,

  // );

  const handleShortFormChecked = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      setValue(`${name}.${index}.effectiveDate`, null);
      return;
    }

    if (!currentEndorsement.effectiveDate)
      setValue(`${name}.${index}.effectiveDate`, minDate);
  };

  const handleEndorsementChange = (
    _: React.ChangeEvent<{}>,
    selectedOption: SelectFieldOption
  ) => {
    // Default date only for Endorsement issued along with Jacket
    // Do not default it for PPE
    // Do not default for Standalone Endorsement
    if (
      !currentEndorsement.effectiveDate &&
      !issuedMode &&
      parentProductType !== ProductType.StandaloneEndorsement
    ) {
      setValue(`${name}.${index}.effectiveDate`, minDate);
    }

    if (
      currentEndorsement.endorsementStatusTypeCode !== OrderStatusType.Issued &&
      currentEndorsement.endorsementStatusTypeCode !== OrderStatusType.Voided
    ) {
      setValue(
        `${name}.${index}.altEndorsementID`,
        selectedOption.altEndorsementID || ""
      );
      setValue(
        `${name}.${index}.endorsementVersion`,
        selectedOption.endorsementVersion
      );
      setValue(
        `${name}.${index}.opaIdentifier`,
        selectedOption.opaIdentifier || ""
      );
    }

    onEndorsementChange && onEndorsementChange(parentProductIndex);
  };

  const handleRequestVoid = () => {
    onRequestVoid(currentEndorsement);
  };

  const showCheckboxColumn =
    !issuedMode && !isIssuedEndorsement && hasShortFormEndorsements;

  return (
    <>
      <TableRow
        selected={isIssuedEndorsement}
        className={classes.endorsementGrid}
      >
        <EndorsementCheckboxCell
          fullName={`${name}.${index}.isSelected`}
          showColumn={showCheckboxColumn}
          cellCss={classes.cell}
          isShortForm={currentEndorsement.isSFE}
          disabled={disabled}
          onChecked={handleShortFormChecked}
        />
        <EndorsementNameCell
          endorsement={currentEndorsement}
          fullName={`${name}.${index}.endorsementName`}
          cellCss={classes.cell}
          disabled={disabled}
          issuedMode={issuedMode}
          options={endorsementOptions || []}
          handleEndorsementChange={handleEndorsementChange}
        />
        <TableCell
          classes={{ sizeSmall: classes.cell }}
          hidden={!showTransCode}
        >
          <StyleCellText>
            {currentEndorsement.pricingDetail?.transCode}
          </StyleCellText>
        </TableCell>
        <TableCell>
          <EndorsementEffectiveDateCell
            pathToValidate={name}
            fullName={`${name}.${index}.effectiveDate`}
            disabled={isEffectiveDateDisabled}
          />
        </TableCell>
        <TableCell classes={{ sizeSmall: classes.cell }} hidden={!issuedMode}>
          <StyleCellText>
            {currentEndorsement.issueDateTime
              ? formatDateTime(currentEndorsement.issueDateTime, false)
              : ""}
          </StyleCellText>
        </TableCell>
        <EndorsementStatusCell
          hidden={!issuedMode}
          endorsementID={currentEndorsement.endorsementID}
          statusTypeCode={currentEndorsement.endorsementStatusTypeCode}
          voidDate={currentEndorsement.voidedDateTime}
        />
        <EndorsementVoidCell
          disabled={!voidable || disabled}
          hidden={!issuedMode}
          isIssued={isIssuedEndorsement}
          onRequestVoid={handleRequestVoid}
        />
        { editMode && (
          <TableCell
            classes={{ sizeSmall: classes.cell }}
            style={{ width: 60 }}
            hidden={!editMode}
            align="center"
          >
            {!isIssuedEndorsement && !currentEndorsement.isSFE ? (
              <IconButton
                classes={{
                  disabled: classes.disabledIconButton,
                }}
                disabled={disabled}
                aria-label="delete-endorsement"
                onClick={() => handleOnRemove(index)}
              >
                <DeleteIcon htmlColor={colors.blue01} />
              </IconButton>
            ) : null}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default EndorsementGridRow;
