import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as UIModel from "entities/UIModel";
import { Reports } from "entities/UIModel";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  gaps,
  zIndex,
} from "theme/defaultStyle";
import PdfTabPanel from "./PdfTabPanel";
import ShowPdf from "./ShowPdf";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& div.MuiPaper-root": {
      width: "80%",
      height: "80%",
      maxWidth: "none",
    },
  },
  appBarParentRoot: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontSize: fontSize.base,
    fontWeight: 600,
    lineHeight: 1.6,
    marginBottom: "0px",
  },
  tabs: {
    backgroundColor: "white",
    gap: gaps.xsmall1,
    height: "45px",
  },
  tab: {
    backgroundColor: colors.grey03,
    borderRadius: "16px 16px 0px 0px",
    height: "45px",
    maxWidth: "100%",
    opacity: "1",
    padding: "6px 20px",
  },
  indicator: {
    backgroundColor: theme.palette.background.default,
  },
  tabLabel: {
    fontSize: fontSize.base,
    textTransform: "none",
  },
  tabPanelRoot: {
    "& p.MuiTypography-root": {
      width: "100%",
      height: "100%",
    },
  },
  pdfContainer: {
    width: "100%",
    height: "100%",
    display: "block",
    position: "relative",
  },
  centerParent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    zIndex: zIndex.negativeMin,
  },
  centerContainer: {
    width: "60px",
    height: "60px",
    fontSize: "60px",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-30px",
    marginRight: 0,
    marginBottom: 0,
    marginLeft: "-30px",
    zIndex: zIndex.negativeMin,
  },
}));

type Props = {
  isOpen: boolean;
  onClose: React.MouseEventHandler<HTMLElement>;
  pdfDocuments: Array<UIModel.PdfDocument>;
  isReports?: boolean;
  reports?: Reports
};

const PdfViewer = ({ isOpen = false, onClose, pdfDocuments, isReports = false, reports }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(1);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (pdfDocuments.length > 0) {
      setValue(pdfDocuments[0].documentId);
    }
  }, [pdfDocuments]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const isSinglePdf = pdfDocuments.length === 1 ? true : false;
  const singlePdfFileName = isSinglePdf ? pdfDocuments[0].fileName : "";
  const isMultiplePdfs = pdfDocuments.length > 1 ? true : false;

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      classes={{ root: classes.dialogRoot }}
    >
      <div className={classes.appBarParentRoot}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title}>
              {singlePdfFileName}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      {isSinglePdf ? (
        <div id="singlePdfContainer" className={classes.pdfContainer}>
          <ShowPdf pdf={pdfDocuments[0]} isReports={isReports} reports={reports} />
        </div>
      ) : null}
      {isMultiplePdfs ? (
        <div id="multiPdfsViewer" className={classes.pdfContainer}>
          {/* <AppBar> */}
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              flexContainer: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            {pdfDocuments?.map((pdf: UIModel.PdfDocument) => (
              <Tab
                value={pdf.documentId}
                label={
                  <Typography className={classes.tabLabel}>
                    {pdf.fileName}
                  </Typography>
                }
                className={classes.tab}
                key={pdf.documentId}
              />
            ))}
          </Tabs>
          {/* </AppBar> */}
          {pdfDocuments?.map((pdf: UIModel.PdfDocument) => (
            <PdfTabPanel
              value={value}
              index={pdf.documentId}
              key={pdf.documentId}
            >
              <ShowPdf pdf={pdf} />
            </PdfTabPanel>
          ))}
        </div>
      ) : null}
    </Dialog>
  );
};

export default PdfViewer;
